/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useMemo } from 'react'

import * as XLSX from 'xlsx'
import Papa from 'papaparse'
import { useTable } from 'react-table'
import { Spinner } from '../Spinner'

export const Table = ({ columns, data }: { columns: any; data: any }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    })

  return (
    <table {...getTableProps()} className="w-full h-full dapp-table">
      <thead>
        {headerGroups.map((headerGroup: any, id1: number) => (
          <tr key={`table-row-${id1}`} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any, id2: number) => (
              <th
                key={`table-head-${id2}`}
                className={`m-0 p-2`}
                style={{
                  boxShadow: 'white -3px 0px 0px, rgb(233 233 231) 0px 1px 0px',
                }}
              >
                <div className="flex w-fit justify-between">
                  {column.render('Header')}
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row: any, id1: number) => {
          prepareRow(row)
          return (
            <tr key={`table-row-${id1}`} {...row.getRowProps()}>
              {row.cells.map((cell: any, id2: number) => {
                return (
                  <td
                    key={`table-data-${id2}`}
                    {...cell.getCellProps()}
                    className={`px-2 py-1 border-b ${
                      id2 !== row.cells.length - 1 ? 'border-r' : ''
                    } border-[rgb(238,238,237)]`}
                    style={{
                      fontSize: '14px',
                    }}
                  >
                    <div className="whitespace-nowrap">
                      {cell.render('Cell')}
                    </div>
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default function XLSXViewer({ url }: { url: string }) {
  const [csvData, setCsvData] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [workbook, setWorkbook] = useState<any>(null)
  const [worksheetNumber, setWorksheetNumber] = useState<number>(0)

  const importCSV = (csvString: string) => {
    setLoading(true)
    Papa.parse(csvString, {
      complete: (result) => {
        setCsvData(result.data)
        setLoading(false)
      },
    })
  }

  const columns = useMemo(
    () =>
      csvData.length &&
      csvData[0].map((d: any, id: number) => ({
        Header: (
          <div
            style={{
              fontWeight: '400',
              fontSize: '14px',
              color: 'rgba(55, 53, 47, 0.65)',
            }}
          >
            {d}
          </div>
        ),
        accessor: id.toString(),
      })),
    [csvData]
  )
  const data = useMemo(
    () => csvData.slice(1).map((d: any) => ({ ...d })),
    [csvData]
  )

  const fetchFile = async () => {
    try {
      setLoading(true)
      // const res = await axios.get(url)
      // if (res.status !== 200) return

      const response = await fetch(url)
      const blob = await response.blob()

      const reader = new FileReader()
      reader.readAsArrayBuffer(blob)
      reader.onload = (evt: any) => {
        const file = evt.target.result
        const wb = XLSX.read(file, { type: 'array' })
        setWorkbook(wb)
      }
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (workbook === null) return
    const worksheetName = workbook.SheetNames[worksheetNumber]
    const worksheet = workbook.Sheets[worksheetName]
    const data = XLSX.utils.sheet_to_csv(worksheet)
    importCSV(data)
  }, [workbook, worksheetNumber])

  useEffect(() => {
    fetchFile()
  }, [])

  return (
    <div className="w-full h-full relative">
      {loading && (
        <div className="w-full h-full flex flex-col justify-center">
          <Spinner />
        </div>
      )}
      <div className="w-full h-full pb-10 overflow-scroll no-scrollbar">
        {!loading && csvData.length > 0 && (
          <Table columns={columns} data={data} />
        )}
      </div>
      <div
        data-cy="xlsx-preview"
        className="absolute bottom-0 left-0 w-full bg-white overflow-auto no-scrollbar"
      >
        {workbook &&
          workbook.SheetNames.map((name: any, id: number) => (
            <div
              key={`workbook-sheet-${id}`}
              className={`inline w-fit px-4 border  cursor-pointer ${
                worksheetNumber === id ? 'bg-gray-400' : 'bg-gray-100'
              }`}
              onClick={() => setWorksheetNumber(id)}
            >
              {name}
            </div>
          ))}
      </div>
    </div>
  )
}
