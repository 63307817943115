import React from 'react'
import fontHighlighted from '../../assets/Webpages/font_highlighted.svg'
import font from '../../assets/Webpages/font.svg'
import bulletList from '../../assets/Webpages/format_list_bulleted.svg'
import leftAlign from '../../assets/Webpages/format_align_left.svg'
import code from '../../assets/Webpages/codeIcon.svg'
import imageIcon from '../../assets/Webpages/image_gray.svg'
import divider from '../../assets/Webpages/divider.svg'
import linkIcon from '../../assets/Webpages/linkIcon.svg'
import { Tooltip } from '@mui/material'

export default function WebPageToolbar({
  previewMode,
}: {
  previewMode: boolean
}) {
  return (
    <Tooltip title="Coming soon">
      <div
        className={`${
          previewMode && 'collapse'
        } shadow-md p-3 w-fit bg-white relative px-4 items-center rounded-lg h-16 flex gap-1cursor-not-allowed`}
      >
        <div className=" flex justify-center items-center  w-[40px] h-[40px] ">
          <img
            src={fontHighlighted}
            alt="highlight font"
            className="w-[20px] h-[20px]"
          />
        </div>
        <div className=" flex justify-center items-center gap-2 h-[25px] px-1  border box-border rounded-[8px]">
          <p className=" text-lg">-</p>
          <p className=" text-sm">14</p>
          <p className=" text-lg">+</p>
        </div>
        <div className=" flex justify-center items-center  w-[40px] h-[40px] ">
          <img src={font} alt="font" className="w-[20px] h-[20px]" />
        </div>
        <img src={divider} alt="divider" />
        <div className=" flex justify-center items-center  w-[40px] h-[40px] ">
          <img src={bulletList} alt="list" className="w-[20px] h-[20px]" />
        </div>
        <div className=" flex justify-center items-center  w-[40px] h-[40px] ">
          <img src={leftAlign} alt="align" className="w-[20px] h-[20px]" />
        </div>
        <img src={divider} alt="divider" />
        <div className=" flex justify-center items-center  w-[40px] h-[40px] ">
          <img src={code} alt="code" className="w-[20px] h-[20px]" />
        </div>
        <div className=" flex justify-center items-center  w-[40px] h-[40px] ">
          <img src={imageIcon} alt="image" className="w-[20px] h-[20px]" />
        </div>
        <div className=" flex justify-center items-center  w-[40px] h-[40px] ">
          <img src={linkIcon} alt="link" className="w-[20px] h-[20px]" />
        </div>
      </div>
    </Tooltip>
  )
}
