import { useMediaQuery } from '@mui/material'
import React, { Dispatch, SetStateAction } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { FileSource, FileTypeEnum } from '../../../types/enum/file.enum'
import CTAButton from '../../common/CTAButton'
import FillingLoader from '../../common/UploadBar/FillingLoader'
import { WALLET_CONNECT } from '../../../utils/constants'
import { useFileData } from '../../../store/files/hooks'
import { usePrivyHelper } from '../../../hooks/usePrivyHelper'

const DisconnectedWalletOverlay = ({
  handleMemberFilePreview,
  setIsConnectButtonClicked,
  gettingMemberKeys,
}: {
  handleMemberFilePreview?: () => void
  setIsConnectButtonClicked?: Dispatch<SetStateAction<boolean>>
  gettingMemberKeys?: boolean
}) => {
  const walletConnectBtn = document.getElementById(WALLET_CONNECT)
  const isMediaMax1025px = useMediaQuery('(max-width : 1025px)')
  const { address: contractAddress, fileId } = useParams()
  const walletAddress = usePrivyHelper().walletAddress
  const [urlParams] = useSearchParams()
  const chainId = parseInt(urlParams.get('chainId') || '')
  const fileData = useFileData(contractAddress as string, fileId)
  const navigate = useNavigate()
  const uiTextDisplay = () => {
    if (
      fileData?.metadata.source === FileSource.DPAGE &&
      (fileData.metadata.fileType === FileTypeEnum.PRIVATE ||
        fileData.metadata.fileType === FileTypeEnum.MEMBERS_PRIVATE)
    ) {
      return {
        heading: 'Private dPage!',
        p1: `This decentralised webpage is acessible to Members and Collaborators. Connect your wallet to access the dPage OR discover the owner's Fileverse.`,
      }
    }
    if (fileData?.metadata.fileType === FileTypeEnum.PRIVATE) {
      return {
        heading: 'Private File',
        p1: 'The file is accessible to Members and Collaborators of this Fileverse Portal. Connect your wallet to access the file OR discover the Portal! :)',
      }
    }
    if (fileData?.metadata.fileType === FileTypeEnum.MEMBERS_PRIVATE) {
      return {
        heading: 'Private File',
        p1: 'The file is accessible to Members and Collaborators of this Fileverse Portal. Connect your wallet to access the file OR discover the Portal! :)',
      }
    }
  }
  return (
    <div
      className={`${
        isMediaMax1025px ? 'absolute bottom-0 w-full' : 'max-w-[36rem]'
      } bg-white w-full  p-4 rounded-lg flex flex-col items-center justify-center `}
      data-cy="disconnectedWallet-popup"
    >
      <div className={` ${isMediaMax1025px ? 'w-full' : 'w-[90%]'}`}>
        <h6
          className={`font-bold text-xl ${!isMediaMax1025px && 'text-center'}`}
        >
          {`${uiTextDisplay()?.heading} <3`}
        </h6>
        <p className={`${!isMediaMax1025px && 'text-center'} `}>
          {uiTextDisplay()?.p1}
        </p>
        <div className="w-full flex justify-center mt-8">
          {isMediaMax1025px ? (
            <div className="flex flex-col  justify-between w-full">
              <CTAButton
                title="Discover Portal"
                backgroundColorClassName={'mb-2 bg-white border-black border-2'}
                onClick={() =>
                  navigate(`/${contractAddress}/member?chainId=${chainId}`)
                }
              />
              {gettingMemberKeys ? (
                <FillingLoader
                  fillingText="Sigin In..."
                  width="w-full"
                  height="h-10"
                />
              ) : (
                <CTAButton
                  onClick={() => {
                    if (!walletAddress) {
                      walletConnectBtn?.click()
                      setIsConnectButtonClicked &&
                        setIsConnectButtonClicked(true)
                    } else {
                      handleMemberFilePreview && handleMemberFilePreview()
                    }
                  }}
                  backgroundColorClassName={
                    'bg-black text-white border-black border-2'
                  }
                  title="Connect Wallet"
                />
              )}
            </div>
          ) : (
            <div
              className="flex flex-wrap  justify-between
           w-[80%]"
            >
              <CTAButton
                title="Discover Portal"
                onClick={() =>
                  navigate(`/${contractAddress}/member?chainId=${chainId}`)
                }
              />
              {gettingMemberKeys ? (
                <FillingLoader
                  fillingText="Sigin In..."
                  width="w-[140px]"
                  height="h-10"
                />
              ) : (
                <CTAButton
                  onClick={() => {
                    if (!walletAddress) {
                      walletConnectBtn?.click()
                      setIsConnectButtonClicked &&
                        setIsConnectButtonClicked(true)
                    } else {
                      handleMemberFilePreview && handleMemberFilePreview()
                    }
                  }}
                  backgroundColorClassName={
                    'bg-black text-white border-black border-2'
                  }
                  title="Connect Wallet"
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default DisconnectedWalletOverlay
