import React, { Dispatch, SetStateAction, useRef } from 'react'
import dropDownArrow from '../../assets/dropDownArrow.svg'
import filter_list from '../../assets/filter_list.svg'
import unfold_more from '../../assets/unfold_more.svg'
import unreadTick from '../../assets/unreadTick.svg'
import { Grow } from '@mui/material'
import useComponentVisibilty from '../../hooks/useVisibility'
import { useInvokerContracts } from '../../store/invoker/hooks'
import { useContract } from '../../store/contract/hooks'
import { useNotificationProvider } from '../../hooks/useNotificationProvider'
import { usePrivyHelper } from '../../hooks/usePrivyHelper'

const NotificationHeader = () => {
  const provider = useNotificationProvider()
  const invokerAddress = usePrivyHelper().walletAddress
  const portalList = useInvokerContracts(invokerAddress as string)
  const dropdownRef = useRef(null)
  const {
    ref: portalDropdownRef,
    isComponentVisible: isPortalDropdownVisible,
    setIsComponentVisible: setPortalDropdownVisibility,
  } = useComponentVisibilty(false)
  const {
    ref: unreadDropdownRef,
    isComponentVisible: isUnreadDropdown,
    setIsComponentVisible: setUnreadDropdown,
  } = useComponentVisibilty(false)
  const ref = useRef(null)
  const scroll = (
    element: HTMLDivElement | null,
    distance: number,
    step: number
  ) => {
    if (!element) return
    let scrollAmount = 0
    const slideTimer = setInterval(() => {
      element.scrollTop += step
      scrollAmount += Math.abs(step)
      if (scrollAmount >= distance) {
        clearInterval(slideTimer)
      }
    })
  }
  const isShowMarkAllAsRead = provider && provider.unreadNotifications > 0
  const scrollDiv = (direction: string) => {
    const distance = 50
    if (direction === 'up') {
      scroll(ref.current, distance, -10)
    } else {
      scroll(ref.current, distance, 10)
    }
  }

  return (
    <div className="w-full">
      <div className="w-full  relative rounded-lg rounded-b-none px-4 flex justify-between">
        <div className="flex relative p-2">
          <p className=" text-sm font-medium mr-1">Notifications</p>
          <div
            ref={unreadDropdownRef}
            className="text-sm cursor-pointer relative w-[5rem] outline-none text-[#777777] font-medium"
          >
            <div
              onClick={(e) => {
                e.stopPropagation()
                setUnreadDropdown(!isUnreadDropdown)
              }}
              className=" flex justify-center items-center"
            >
              <div className="flex items-center">
                <p className="text-center mr-1">
                  {provider?.queryParams.isRead === false ? 'Unread' : 'All'}
                </p>
                <img src={dropDownArrow} className="w-2" />
              </div>
            </div>
            <Grow in={isUnreadDropdown} appear={isUnreadDropdown}>
              <div
                className={`flex z-30 flex-col w-[5rem] border bg-white left-0 rounded-b-lg absolute top-7  shadow-2xl`}
              >
                <div className="max-h-[100px] border-t">
                  <div
                    onClick={(e) => {
                      e.stopPropagation()
                      provider?.setQueryParams({
                        ...provider?.queryParams,
                        isRead: null,
                      })
                    }}
                    className="flex  items-center"
                  >
                    <span className="hover:cursor-pointer hover:bg-[#F2F2F2] text-center text-sm w-full">
                      All
                    </span>
                  </div>
                  <div
                    onClick={(e) => {
                      e.stopPropagation()

                      provider?.setQueryParams({
                        ...provider?.queryParams,
                        isRead: false,
                      })
                    }}
                    className=" hover:bg-[#F2F2F2] flex items-center border-y"
                  >
                    <span className="hover:cursor-pointer text-center text-sm w-full">
                      Unread
                    </span>
                  </div>
                </div>
              </div>
            </Grow>
          </div>
        </div>
        <div ref={portalDropdownRef} className="flex justify-between">
          <div ref={dropdownRef} className="flex relative">
            <div
              onClick={(e) => {
                e.stopPropagation()
                setPortalDropdownVisibility(!isPortalDropdownVisible)
              }}
              className="flex gap-2 hover:bg-[#f2f2f2] cursor-pointer px-1 items-center"
            >
              <img src={filter_list} className="w-[18px]" />
              <p className=" text-sm font-medium">
                {provider?.selectedPortal.name || 'All portals'}
              </p>
              <img src={unfold_more} className="w-[18px]" />
            </div>
          </div>
          <Grow in={isPortalDropdownVisible} appear={isPortalDropdownVisible}>
            <div
              className={`flex z-30 flex-col w-[10rem] border bg-white right-[5px] rounded-b-lg absolute top-[39px]   shadow-2xl`}
            >
              <div
                onClick={() => scrollDiv('up')}
                className="w-full hover:bg-[#F2F2F2] flex py-1 border-b justify-center"
              >
                <img src={dropDownArrow} className=" rotate-180" />
              </div>
              <div
                ref={ref}
                className="max-h-[100px] no-scrollbar overflow-y-scroll"
              >
                <div
                  onClick={() => {
                    provider?.setSelectedPortal({
                      name: 'All portals',
                      address: '',
                    })
                    provider?.setQueryParams({
                      ...provider?.queryParams,
                      portalAddress: '',
                    })
                  }}
                  className="h-[34px] hover:bg-[#F2F2F2] flex items-center px-2 py-2 border-b"
                >
                  <span className="hover:cursor-pointer  text-center text-sm w-full">
                    All portals
                  </span>
                </div>
                {portalList.map((portalAddress) => (
                  <PortalCard
                    key={portalAddress}
                    contractAddress={portalAddress}
                    setPortalDropdownVisibility={setPortalDropdownVisibility}
                  />
                ))}
              </div>

              <div
                onClick={() => scrollDiv('down')}
                className="w-full hover:bg-[#F2F2F2] flex py-1 border-t justify-center"
              >
                <img src={dropDownArrow} className="" />
              </div>
            </div>
          </Grow>
        </div>
      </div>
      {isShowMarkAllAsRead && (
        <div
          onClick={() => provider?.markAllAsRead()}
          className="flex justify-center py-1 border-y gap-1 cursor-pointer"
        >
          <img src={unreadTick} />
          <p className="text-[#777777] hover:text-black">Mark all as read</p>
        </div>
      )}
    </div>
  )
}

export default NotificationHeader

export const PortalCard = ({
  contractAddress,
  setPortalDropdownVisibility,
}: {
  contractAddress: string
  setPortalDropdownVisibility: Dispatch<SetStateAction<boolean>>
}) => {
  const contract = useContract(contractAddress)
  const provider = useNotificationProvider()
  return (
    <div
      onClick={() => {
        provider?.setSelectedPortal({
          name: contract?.metadata?.name,
          address: contract?.contractAddress,
        })
        provider?.setQueryParams({
          ...provider?.queryParams,
          portalAddress: contract?.contractAddress,
        })
        setPortalDropdownVisibility(false)
      }}
      className="h-[34px] hover:bg-[#F2F2F2] hover:cursor-pointer flex items-center px-2 py-2 border-bottom border-b"
    >
      <span className=" text-center text-sm w-full">
        {contract?.metadata?.name}
      </span>
    </div>
  )
}
