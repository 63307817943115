/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  Avatar,
  IconButton,
  LucideIcon,
  useHoverAndLongPress,
} from '@fileverse/ui'
import cn from 'classnames'
import { useAppDispatch } from '../../../store/hooks'
import { uploadSingleFileToIpfs } from '../../../store/files/thunks'
import { useInvokerContractCredentials } from '../../../store/invoker/hooks'
import { ICredential } from '../../../types/interface/invoker.interface'
import { useParams, useSearchParams } from 'react-router-dom'
import { getPublicFileUrl } from '../../../utils/files/filePreviewHandlers'
import { usePublicPortalContext } from '../../../providers/PublicPortalProvider'
import { getHashedString } from '../../../utils/ethUtils'
import { heartbitCoreSDK } from '../utils/heartbitSDK'
import defaultAvatar from '../assets/default-avatar.svg'
import { FileMetadata, IPortalFile } from '../interfaces'
import HeartBitDialog from './HeartBit'
import { useDialogStore } from './HeartBit/utils'
import { usePrivyHelper } from '../../../hooks/usePrivyHelper'

export default function PortalAvatar() {
  const {
    avatarIPFSUrl,
    isViewMode,
    updateAvatarIPFSUrl,
    name: portalName,
    coverIPFSUrl,
  } = usePublicPortalContext()
  const imageInputRef = useRef<HTMLInputElement | null>(null)
  const { onMouseEnter, onMouseLeave, isActive } = useHoverAndLongPress({})
  const [isAvatarLoading, setIsAvatarLoading] = useState<boolean>(false)
  const [isMintedByUser, setIsMintedByUser] = useState<boolean>(false)
  const [avatarSrc, setAvatarSrc] = useState<string>(
    avatarIPFSUrl || defaultAvatar
  )

  const { address: contractAddress } = useParams()
  const [searchParams] = useSearchParams()
  const chainId = parseInt(searchParams.get('chainId') || '')
  const dispatch = useAppDispatch()
  const walletAddress = usePrivyHelper().walletAddress as string

  const credential = useInvokerContractCredentials(
    walletAddress as string,
    contractAddress as string
  ) as ICredential

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      if (event.target.files && event.target.files[0]) {
        setIsAvatarLoading(true)

        const uploadedAvatarIPFSHash = await dispatch(
          uploadSingleFileToIpfs({
            credential,
            file: event.target.files[0],
            fileName: `logo`,
            contractAddress: contractAddress as string,
            invoker: walletAddress as string,
            chainId,
          })
        ).unwrap()

        const fileUrl = await getPublicFileUrl(uploadedAvatarIPFSHash)
        updateAvatarIPFSUrl(fileUrl.ipfsUrl)
        setAvatarSrc(fileUrl.ipfsUrl)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsAvatarLoading(false)
      event.target.value = ''
    }
  }

  const getUserBalance = useCallback(async () => {
    if (!isViewMode || !walletAddress) return
    const hashedURL = getHashedString(window.location.href)
    const mintsByUser = await heartbitCoreSDK.getHeartBitByUser({
      hash: hashedURL,
      account: walletAddress as string,
    })

    setIsMintedByUser(mintsByUser > 0)
  }, [isViewMode, walletAddress])

  const mintPortal = async () => {
    if (isMintedByUser) return
    setDialogState({
      ...dialogState,
      itemId: 'portal',
    })
  }

  useEffect(() => {
    getUserBalance()
  }, [getUserBalance])

  const { setDialogState, dialogState } = useDialogStore()
  const fileInfo: IPortalFile = {
    id: 'portal',
    title: portalName,
    size: 'lg',
    metadata: {
      name: portalName,
      extension: 'jpeg',
      ipfsHash: coverIPFSUrl,
      mimeType: 'image',
      ogImageUrl: '',
    } as FileMetadata,
    liked: isMintedByUser,
    type: 'media',
  }

  const { itemId } = dialogState
  return (
    <div className="relative rounded-full w-fit pointer-events-auto">
      {isAvatarLoading && (
        <div className="absolute inset-0 color-utility-overlay flex justify-center items-center rounded-full">
          <LucideIcon
            name="LoaderCircle"
            size={'lg'}
            className="animate-spin"
            fill="transparent"
            stroke={'white'}
          />
        </div>
      )}
      <Avatar
        src={avatarSrc}
        size={'3xl'}
        bordered={'border'}
        className=""
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onError={() => setAvatarSrc(defaultAvatar)}
      />
      <input
        ref={imageInputRef}
        className="hidden absolute"
        type="file"
        accept="image/*"
        onChange={(e) => handleImageChange(e)}
      />
      {!isViewMode && (
        <>
          <IconButton
            icon="Pencil"
            variant="ghost"
            size="md"
            rounded
            elevation={3}
            className={cn(
              'w-11 h-11 absolute -bottom-1 left-1 !color-button-floating hover:!color-button-floating-hover opacity-0 transition-opacity duration-300',
              isActive && 'opacity-100'
            )}
            onClick={() => {
              imageInputRef?.current?.click()
            }}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          />

          <IconButton
            icon="Trash"
            variant="ghost"
            disabled={!avatarIPFSUrl}
            size="md"
            rounded
            elevation={3}
            className={cn(
              'w-11 h-11 absolute -bottom-1 right-1 !color-button-floating hover:!color-button-floating-hover disabled:!color-button-disabled opacity-0 transition-opacity duration-300',
              isActive && 'opacity-100'
            )}
            onClick={() => {
              updateAvatarIPFSUrl('')
              setAvatarSrc('')
            }}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          />
        </>
      )}
      {isViewMode && (
        <div
          className={cn(
            'absolute bottom-0 right-2 p-2 shadow-elevation-3 rounded-full cursor-pointer !color-button-floating color-border-default border-[0.5px]',
            { 'hover:!color-button-floating-hover': !isMintedByUser }
          )}
        >
          <LucideIcon
            name="Heart"
            size={'md'}
            fill={isMintedByUser ? 'red' : 'none'}
            stroke={isMintedByUser ? 'red' : 'black'}
            onClick={() => mintPortal()}
          />
        </div>
      )}
      {itemId === 'portal' ? (
        <HeartBitDialog
          file={fileInfo}
          isOpen={itemId === 'portal'}
          onLike={() => setIsMintedByUser(true)}
        />
      ) : null}
    </div>
  )
}
