import React from 'react'
import { useMediaQuery } from '@mui/material'
import Header from './Headers/MainHeader'
import Footer from './Footer'
interface WrapperProps {
  children: JSX.Element
  noFooter?: boolean
  isArweave?: boolean
}
const PageWrapper = ({ children, noFooter }: WrapperProps) => {
  const isMediaMax1025px = useMediaQuery('(max-width : 1025px)')
  return (
    <div
      className={`h-[100vh] flex flex-col ${
        !isMediaMax1025px && 'gap-2 min-h-[690px]'
      }`}
    >
      <div className="px-[40px] py-[16px]">
        <Header />
      </div>
      <div
        className={`relative h-full no-scrollbar flex flex-col justify-center w-full ${
          isMediaMax1025px ? 'p-2 ' : ''
        }`}
      >
        {children}
      </div>
      {!noFooter && (
        <div className="h-[10vh]">
          <Footer />
        </div>
      )}
    </div>
  )
}

export default PageWrapper
