/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  DataLockedKeyMaterial,
  IFileChatKeyPair,
  IFileMetaData,
} from '../../types/interface/file.interface'
import { getFileTags } from '../getApplicableFileTags'
import { getFileExtension } from '../fileUtils'
import { FileSource, FileTypeEnum } from '../../types/enum/file.enum'

export class FileMetadata implements IFileMetaData {
  public uploadedAt: number
  public name: string
  public size: string | number
  public extension: string
  public mimeType: string
  public version: number
  public fileType: FileTypeEnum
  public source: FileSource
  public owner: string
  public portalLock: null | DataLockedKeyMaterial
  public ownerLock: null | DataLockedKeyMaterial
  public memberLock: null | DataLockedKeyMaterial
  public tokenLock: null | DataLockedKeyMaterial
  public publicLock: {
    fileKey: string | null
    chatKey: IFileChatKeyPair
  } | null
  public tag: string[]
  public arweaveHash: string | undefined
  public editedAt: number | undefined
  public editedBy: string | undefined

  constructor(
    file: File,
    owner: string,
    fileSource: FileSource,
    fileType: FileTypeEnum,
    fileArgs = <any>{}
  ) {
    const { uploadedAt, orignalOwner } = fileArgs
    this.uploadedAt = uploadedAt ? uploadedAt : Math.floor(Date.now() / 1000)
    this.name = file.name
    this.size = file.size
    this.extension = getFileExtension(file.name)
    this.mimeType = file.type
    this.fileType = fileType
    this.source = fileSource
    this.version = 1
    this.owner = orignalOwner || owner // in case of edits don't change the orignal owner value
    this.portalLock = null
    this.ownerLock = null
    this.memberLock = null
    this.tokenLock = null
    this.publicLock = null
    this.tag = getFileTags(fileType, fileSource)
    if (uploadedAt) {
      this.editedAt = Math.floor(Date.now() / 1000)
      this.editedBy = fileArgs.editedBy
    }
    if (fileArgs.arweaveHash) this.arweaveHash = fileArgs.arweaveHash
  }
}

export class PluginFileMetadata extends FileMetadata {
  public coverIPFSHash: string
  public emoji: string
  public commentsEnabled: boolean
  public version: number

  constructor(
    file: File,
    owner: string,
    fileSource: FileSource,
    fileType: FileTypeEnum,
    fileArgs = <any>{}
  ) {
    super(file, owner, fileSource, fileType, fileArgs)
    this.coverIPFSHash = fileArgs.coverIPFSHash || ''
    this.emoji = fileArgs.emoji || ''
    this.commentsEnabled = fileArgs.commentsEnabled || false
    this.version = fileArgs.version || 1
  }
}
