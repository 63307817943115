import isNull from 'lodash/isNull'
import { ICredential } from '../../types/interface/invoker.interface'
import { Contract, JsonRpcSigner, Wallet } from 'ethers'
import * as CryptoJS from 'crypto-js'

import {
  IContract,
  IContractMetadata,
} from '../../types/interface/contract.interface'
import {
  getContractFile,
  getContractInfo,
  getContractMetadata,
} from '../../utils/contract'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { addInvokerContract, removeAgentKey } from '../invoker/reducer'
import {
  getContractNetwork,
  isValidContractNetwork,
} from '../../utils/networks/network'
import createGateApi from '../../api/gateapi/createGateApi'

import { fetchAllFiles } from '../files/thunks'
import { defaultNetwork } from '../../config/network-config'
import { waitForTransactionToBeMined } from '../../utils/transaction/transaction'

import {
  addContractReducer,
  updateContractLayoutFileId,
  updateContractMetadataState,
  updatePortalMetadataIPFSUrl,
} from './reducer'
import {
  removePortalAgent,
  setContractChainId,
  setPortalSignlessMode,
} from './reducer'
import { AppState } from '..'
import { storageUsageAPI } from '../../api/claimStorage/claimStorage'
import { sendMetaTx } from '../../utils/metaTransaction/metaTx'

import { getAgentSigner } from '../../utils/agentHandler'
import { decryptUsingRSAKey } from '../../utils/crypto'
import { isSafeApp, safeAppsSdk } from '../../utils/safeApp'

import { captureException, captureMessage } from '@sentry/react'
import { queryIndexDb } from '../middleware/utils'
import {
  addContractToIndexDbRecord,
  updateContractLayoutFileIdStateOnIndexedDb,
  updateContractMetadataStateOnIndexDb,
} from '../middleware/actionHandlers'
import { IContractDbRecord } from '../middleware/database'
import { getMemberKeysNode } from '../../hooks/useGunNode'
import { GunInstance } from '../../utils/instantiateGun'
import {
  getPublicPortalDataFromApi,
  getPublicPortalLayout,
  getPublicPortalLayoutFileIdOnChain,
} from '../../utils/publicPortalUtils'
import { addFile, editFile } from '../files/reducer'

interface IFetchContractPayload {
  contractAddress: string
  invokerAddress?: string
  chainId: number
  invokerCredential?: ICredential
  owner?: string
  publicLayoutFileId?: string
}

interface IFetchStorageContractPayload {
  contractAddress: string
  editSecret: string
  invoker: string
  chain: number
}
interface ITransactionPayload {
  connectedNetworkId: number | undefined
  browserSigner: JsonRpcSigner
  encodedFunction: string
  eventName: string
  walletAddress: string
  onTxSuccess: (hash?: string) => void
  onResolvedTxEvent: (event: unknown, hash: string) => void
  onTxFailedEvent: (reason: string) => void
  onTxFailure: (reason: string) => void
  contract: Contract
  provider: JsonRpcSigner
  isSignless?: boolean
  handleTransactionFromWallet: () => Promise<string>
  abi: unknown[]
  _ownerDecryptionKey?: string
  _ownerEncryptionKey?: string
  signMessageAsync?: (args: unknown) => Promise<`0x${string}`>
}

export const fetchAndSaveContract = createAsyncThunk(
  'contract/fetchAndAddContract',
  async (
    payload: IFetchContractPayload,
    { dispatch }
  ): Promise<IContract | undefined> => {
    const { contractAddress, invokerAddress, invokerCredential } = payload
    let chainId = payload.chainId

    const isValidChainId = await isValidContractNetwork(
      chainId as number,
      contractAddress as string
    )

    if (isNaN(chainId as number) || !isValidChainId) {
      const contractNetwork = await getContractNetwork(
        contractAddress as string
      )
      if (contractNetwork) {
        chainId = contractNetwork.chainId
      } else {
        throw new Error(
          'Address was not found on any of the supported networks'
        )
      }
    }

    const contractInfo = await getContractInfo(contractAddress, chainId)

    await dispatch(
      fetchAllFiles({
        contractAddress: contractAddress as string,
        chainId,
      })
    )
    if (contractInfo && invokerAddress) {
      await dispatch(
        addInvokerContract({
          contractAddress,
          invokerAddress: invokerAddress as string,
        })
      )
      if (invokerCredential?.editSecret) {
        await dispatch(
          fetchStorageUsage({
            contractAddress: contractAddress as string,
            editSecret: invokerCredential.editSecret,
            invoker: invokerAddress as string,
            chain: chainId,
          })
        )
      }
    }
    const publicLayoutFileId = payload.publicLayoutFileId
      ? payload.publicLayoutFileId
      : await getPublicPortalLayoutFileIdOnChain(contractAddress)
    return { ...contractInfo, publicLayoutFileId: publicLayoutFileId }
  }
)
export const fetchStorageUsage = createAsyncThunk(
  'contract/fetchStorageUsage',
  async (payload: IFetchStorageContractPayload) => {
    const { contractAddress, editSecret, invoker, chain } = payload
    const request = await storageUsageAPI({
      contractAddress,
      editSecret,
      invoker,
      chain,
    })
    const limit = Math.ceil(Number(request.data.storageLimit) / 1000000)
    const inUse = Math.ceil(Number(request.data.storageUse) / 1000000)
    const storage = {
      limit: limit,
      storageUsed: inUse,
      totalStorage:
        Math.round(
          (Math.min((inUse / limit) * 100, 100) + Number.EPSILON) * 100
        ) / 100,
      contractAddress,
    }
    return storage
  }
)
export const setUpMemberFileKey = createAsyncThunk(
  'contract/setMemberFileKey',
  async (payload: {
    contractAddress: string
    editSecretKey: string
    invokerAddress: string
    chainId: string | number
    memberDecryptionKey: string
    tokenId: number
  }) => {
    const gateParams = [
      `${payload.chainId}:erc1155:${defaultNetwork.memberTokenAddress}:${payload.tokenId}:1:*`,
    ]
    const createGateRequest = {
      params: gateParams,
      credentialEditSecret: payload.editSecretKey,
      contractAddress: payload.contractAddress,
      invoker: payload.invokerAddress,
      includeCollaborators: true,
      includeMembers: true,
      chain: Number(payload.chainId),
    }
    const createResponse = await createGateApi(createGateRequest)
    const { gateId: memberGateId, gateKey } = createResponse.data
    const encryptedMemberDecryptionKey = CryptoJS.AES.encrypt(
      JSON.stringify({ memberDecryptionKey: payload.memberDecryptionKey }),
      gateKey
    ).toString()

    const memberKeyNode = getMemberKeysNode(payload.contractAddress)

    await GunInstance.putGunNodeData(memberKeyNode, {
      memberGateId,
      memberGateKey: encryptedMemberDecryptionKey,
    })
  }
)

export const verifyPortalChainId = createAsyncThunk(
  'contract/verifyPortalChainId',
  async (
    payload: {
      chainId: number
      contractAddress: string
      contractChainId?: number
    },
    { dispatch }
  ) => {
    const { chainId, contractAddress } = payload
    if (payload?.contractChainId && chainId === payload.contractChainId) {
      return chainId
    } else {
      if (!payload?.contractChainId) {
        // get the network at which contract was deployed to
        const contractNetwork = await getContractNetwork(
          contractAddress as string
        )
        if (contractNetwork) {
          dispatch(
            setContractChainId({
              contractAddress,
              chainId: contractNetwork.chainId,
            })
          )
          return contractNetwork.chainId
        }
      } else {
        return payload.contractChainId
      }
    }
  }
)
export const loadPortalState = createAsyncThunk(
  'contract/loadPortalState',
  async (
    payload: {
      contractAddress: string
      invokerAddress: string
      chainId: number
      owner?: string
    },
    { getState, dispatch }
  ) => {
    const appState: AppState = getState() as AppState
    const { contractAddress, invokerAddress, chainId, owner } = payload
    const portal = appState.contract.contracts[contractAddress]
    if (!portal) {
      const newPortal = await dispatch(
        fetchAndSaveContract({
          contractAddress: contractAddress as string,
          invokerAddress: invokerAddress,
          chainId,
          owner,
        })
      ).unwrap()
      return newPortal && newPortal
    }
    return portal
  }
)

export const handleTransaction = createAsyncThunk(
  'contract/handleTransaction',
  async (payload: ITransactionPayload, { getState, dispatch }) => {
    const state: AppState = getState() as AppState
    const {
      connectedNetworkId,
      browserSigner,
      encodedFunction,
      walletAddress,
      onTxSuccess,
      onResolvedTxEvent,
      onTxFailedEvent,
      onTxFailure,
      eventName,
      handleTransactionFromWallet,
      contract,
      provider,
      abi,
      isSignless,
    } = payload
    let hash: string
    let signer: Wallet | JsonRpcSigner = browserSigner
    let address: string = walletAddress
    const contractAddress = contract.target.toString()
    let agentPassword = ''
    let _agentStorageId = ''
    if (isSignless) {
      const invokerState = state.invoker?.invokers[walletAddress]
      const decryptionKey =
        invokerState.serverKeys[contractAddress]?.portalDecryptionKey
      const encryptedKey = invokerState.serverKeys[contractAddress]?.agentKey
      if (!encryptedKey) {
        throw new Error(
          `Signless feature is turned on but user doesn't have the agent key for ${contractAddress}`
        )
      }
      if (!decryptionKey) {
        throw new Error(`Portal's decryption key not found`)
      }
      const key = await decryptUsingRSAKey(
        encryptedKey as string,
        decryptionKey
      )
      agentPassword = JSON.parse(key)
      _agentStorageId = contractAddress

      try {
        const agentSigner = await getAgentSigner(_agentStorageId, agentPassword)
        signer = agentSigner as unknown as Wallet
        address = agentSigner.address
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        console.log(e)
        captureException(e)
        dispatch(
          setPortalSignlessMode({
            contractAddress: contractAddress as string,
            enabled: false,
          })
        )
        dispatch(
          removeAgentKey({
            contractAddress: contractAddress as string,
            invokerAddress: walletAddress,
          })
        )
        dispatch(
          removePortalAgent({
            contractAddress: contractAddress as string,
          })
        )
        onTxFailure(e.message)
        return
      }
    }
    try {
      const _isSafeApp = await isSafeApp()
      if (
        defaultNetwork.gasless &&
        connectedNetworkId === defaultNetwork.chainId
      ) {
        if (_isSafeApp && !isSignless) {
          hash = await handleTransactionFromWallet()
          if (!hash) {
            onTxFailure('Failed to retrieve transaction hash')
            return
          }
        } else {
          try {
            const tx = await sendMetaTx(
              address,
              contract,
              encodedFunction,
              signer
            )
            if (tx.status === 'success') {
              hash = JSON.parse(tx.result).response.hash
            } else {
              onTxFailure('Transaction sent to the relayer was not successful')
              return
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (error: any) {
            captureException(error)
            onTxFailure(error?.message)
            return
          }
        }
      } else {
        hash = await handleTransactionFromWallet()
        if (!hash) {
          onTxFailure('Failed to retrieve transaction hash')
          return
        }
      }
      onTxSuccess(hash)
      if (_isSafeApp && !isSignless) {
        const txHash = new Promise((resolve: (value: string) => void) => {
          const interval = setInterval(async () => {
            const tx = await safeAppsSdk.txs.getBySafeTxHash(hash)
            if (tx.txHash) {
              resolve(tx.txHash)
              clearInterval(interval)
            }
          }, 10000)
        })
        hash = await txHash
      }
      const event = await waitForTransactionToBeMined(
        hash,
        eventName,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        provider as any,
        abi
      )
      if (!isNull(event)) {
        onResolvedTxEvent(event, hash)
      } else {
        onTxFailedEvent(`Unable to resolve the event ${eventName}`)
        captureMessage(`Unable to resolve the event ${eventName}`)
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      captureException(error)
      onTxFailure(error?.message)
    }
  }
)

export const addContractThunk = createAsyncThunk(
  'contract/addContract',
  async (payload: IContract, { dispatch }) => {
    const contractIdbState = await queryIndexDb(
      'contracts',
      payload.contractAddress
    )
    await addContractToIndexDbRecord(
      contractIdbState as IContractDbRecord,
      payload
    )
    dispatch(addContractReducer(payload))
  }
)

export const getPortalMetadataFromLastWorkingUrl = createAsyncThunk(
  'contract/getPortalMetadataFromLastWorkingUrl',
  async (
    payload: { contractAddress: string; skipCache?: boolean },
    { dispatch, getState }
  ): Promise<IContractMetadata> => {
    const state: AppState = getState() as AppState
    const portalMetadataLastWorkingUrl =
      state.contract.contracts[payload.contractAddress]
        ?.metadataLastWorkingIPFSUrl
    const result = await getContractMetadata(payload.contractAddress, {
      lastIPFSUrl: payload.skipCache ? '' : portalMetadataLastWorkingUrl,
    })
    dispatch(
      updateContractMetadataState({
        contractAddress: payload.contractAddress,
        metadata: result.data,
      })
    )

    dispatch(
      updatePortalMetadataIPFSUrl({
        contractAddress: payload.contractAddress,
        metadataLastWorkingIPFSUrl: result.lastWorkingUrl,
      })
    )

    return result.data
  }
)

export const updatePortalMetadata = createAsyncThunk(
  'contract/updatePortalMetadata',
  async (
    payload: {
      contractAddress: string
      metadata: IContractMetadata
    },
    { dispatch }
  ) => {
    const contractIdbState = await queryIndexDb(
      'contracts',
      payload.contractAddress
    )

    updateContractMetadataStateOnIndexDb(
      contractIdbState as IContractDbRecord,
      payload
    )

    dispatch(
      updateContractMetadataState({
        contractAddress: payload.contractAddress,
        metadata: payload.metadata,
      })
    )
  }
)

export const updateContractLayoutFileIdThunk = createAsyncThunk(
  'contract/updateContractLayoutFileId',
  async (
    payload: { contractAddress: string; layoutFileId: string },
    { dispatch }
  ) => {
    const contractIdbState = await queryIndexDb(
      'contracts',
      payload.contractAddress
    )

    await updateContractLayoutFileIdStateOnIndexedDb(
      contractIdbState as IContractDbRecord,
      payload
    )

    dispatch(
      updateContractLayoutFileId({
        contractAddress: payload.contractAddress,
        layoutFileId: payload.layoutFileId,
      })
    )
  }
)

// TODO: Refactor
export const fetchPublicPortalData = createAsyncThunk(
  'contract/fetchPublicPortalData',
  async (
    payload: {
      contractAddress: string
      address: string
      chainId: number
    },
    { dispatch, getState }
  ) => {
    const state: AppState = getState() as AppState
    const existingContract = state.contract.contracts[payload.contractAddress]
    const apiResponse = await getPublicPortalDataFromApi(
      payload.contractAddress
    )
    if (!existingContract) {
      const portalInfo = await dispatch(
        fetchAndSaveContract({
          contractAddress: payload.contractAddress,
          invokerAddress: payload.address,
          chainId: payload.chainId,
          publicLayoutFileId: apiResponse?.layoutFileId,
        })
      ).unwrap()
      if (!portalInfo) return

      const { publicLayoutFileId } = portalInfo

      if (apiResponse?.resolvedContent && !apiResponse.isIndexing)
        return apiResponse.resolvedContent

      return await getPublicPortalLayout(
        payload.contractAddress,
        publicLayoutFileId
      )
    }

    const result = await getContractMetadata(payload.contractAddress as string)

    await dispatch(
      updatePortalMetadata({
        contractAddress: payload.contractAddress,
        metadata: result.data,
      })
    )

    if (apiResponse?.resolvedContent && !apiResponse.isIndexing)
      return apiResponse.resolvedContent

    if (existingContract.publicLayoutFileId)
      return await getPublicPortalLayout(
        payload.contractAddress,
        existingContract.publicLayoutFileId
      )

    const publicLayoutFileId = apiResponse?.layoutFileId
      ? apiResponse.layoutFileId
      : await getPublicPortalLayoutFileIdOnChain(payload.contractAddress)

    if (publicLayoutFileId)
      await dispatch(
        updateContractLayoutFileIdThunk({
          contractAddress: payload.contractAddress,
          layoutFileId: publicLayoutFileId,
        })
      )

    return await getPublicPortalLayout(
      payload.contractAddress,
      publicLayoutFileId
    )
  }
)

export const setupFileData = createAsyncThunk(
  'contract/setupFileData',
  async (
    payload: {
      contractAddress: string
      chainId: number
      fileId: number
    },
    { dispatch, getState }
  ) => {
    const state = getState() as AppState
    const existingContract = state.contract.contracts[payload.contractAddress]

    if (!existingContract) {
      const { contractAddress } = payload
      let chainId = payload.chainId

      const isValidChainId = await isValidContractNetwork(
        chainId as number,
        contractAddress as string
      )

      if (isNaN(chainId as number) || !isValidChainId) {
        const contractNetwork = await getContractNetwork(
          contractAddress as string
        )
        if (contractNetwork) {
          chainId = contractNetwork.chainId
        } else {
          throw new Error(
            'Address was not found on any of the supported networks'
          )
        }
      }

      const contractInfo = await getContractInfo(contractAddress, chainId)
      dispatch(addContractReducer(contractInfo))
    }

    const fileDataFromSc = await getContractFile(
      payload.fileId,
      payload.contractAddress
    )
    if (!fileDataFromSc) throw new Error('File not found')

    const existingContractFiles = state.files.files[payload.contractAddress]
    const existingFileData = existingContractFiles?.[payload.fileId]

    if (existingFileData) {
      const existingFileEditedAt = existingFileData?.metadata?.editedAt || 0
      const latestEditedAt = fileDataFromSc?.metadata?.editedAt || 0
      if (latestEditedAt > existingFileEditedAt)
        dispatch(
          editFile({
            fileData: fileDataFromSc,
            contractAddress: payload.contractAddress,
          })
        )
    } else {
      dispatch(
        addFile({
          fileData: fileDataFromSc,
          contractAddress: payload.contractAddress,
        })
      )
    }

    return fileDataFromSc
  }
)
