import isEmpty from 'lodash/isEmpty'
import { getCollaboratorKeys } from './../../utils/contract'
import { DelegationSemantics } from 'ucans/attenuation'
import * as ucans from 'ucans'

interface Ability {
  namespace: string
  segments: string[]
}

const delegationSemantics: DelegationSemantics = {
  canDelegateResource(parentResource, childResource): boolean {
    if (parentResource.scheme !== childResource.scheme) {
      return false
    }
    return true
  },
  canDelegateAbility(parentAbility: Ability, childAbility: Ability): boolean {
    if (parentAbility.namespace !== childAbility.namespace) {
      return false
    }

    // Array equality
    if (parentAbility.segments.length !== childAbility.segments.length) {
      return false
    }
    return parentAbility.segments.reduce(
      (acc, v, i) => acc && childAbility.segments[i] === v,
      true as boolean
    )
  },
}

export const verifyKeyRequest = async (
  token: string,
  contractAddress: string,
  invokerAddress: string
): Promise<string | null> => {
  const memberDids = await getCollaboratorKeys(contractAddress, invokerAddress)
  const viewDid = memberDids.viewDid
  if (isEmpty(viewDid)) {
    return null
  }
  const verificationResult = await ucans.verify(token, {
    audience: process.env.REACT_APP_SERVICE_DID || '',
    requiredCapabilities: [
      {
        capability: {
          with: {
            scheme: 'key_sharing',
            hierPart: '',
          },
          can: {
            namespace: 'server_keys',
            segments: ['REQUEST'],
          },
        },
        rootIssuer: viewDid,
      },
    ],
    semantics: delegationSemantics,
  })
  if (verificationResult.ok) {
    return verificationResult.value[0].proof.ucan.payload.att[0].with.hierPart
  }
  return null
}

export const verifyServerKeyMessage = async (
  token: string,
  viewDid: string,
  contractAddress: string,
  invokerAddress: string
): Promise<string | null> => {
  const memberDids = await getCollaboratorKeys(contractAddress, invokerAddress)
  const publisherViewDid = memberDids.viewDid
  if (isEmpty(publisherViewDid)) {
    return null
  }
  const verificationResult = await ucans.verify(token, {
    audience: viewDid,
    requiredCapabilities: [
      {
        capability: {
          with: {
            scheme: 'key_sharing',
            hierPart: '',
          },
          can: {
            namespace: 'server_keys',
            segments: ['PUBLISH'],
          },
        },
        rootIssuer: publisherViewDid,
      },
    ],
    semantics: delegationSemantics,
  })
  if (verificationResult.ok) {
    return verificationResult.value[0].proof.ucan.payload.att[0].with.hierPart
  }
  return null
}
