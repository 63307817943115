import React from 'react'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import more from '../../assets/more-vertical.svg'
import upload from '../../assets/upload4.svg'
import { Tooltip } from '@mui/material'
import { DocStatus, SavingStatusUI } from './SavingStatusUI'
import { useContract } from '../../store/contract/hooks'
import { useParams } from 'react-router-dom'
import { checkIfOwnerOrCollaborator } from '../../utils/checkIfOwnerOrCollaborator'
import { Link2 } from 'lucide-react'
import cn from 'classnames'
import { useServerKeys } from '../../store/invoker/hooks'
import { usePrivyHelper } from '../../hooks/usePrivyHelper'

interface ITopRighUIProps {
  showTitlePopUp: () => void
  fileName: string
  publishCanvas: () => void
  downloadProject: () => void
  docStatus: DocStatus
  pluginType: string
  isCollaborator?: boolean
  invokerAddress: string
  isConnected?: boolean
  handleShareButton: () => void
}

export const yellowBtn = `bg-yellow w-fit border-[2px] border-black h-[40px] text-black`

export const TopRightUI = ({
  publishCanvas,
  fileName,
  showTitlePopUp,
  docStatus,
  pluginType,
  isCollaborator,
  handleShareButton,
}: ITopRighUIProps) => {
  return (
    <div
      className={`${
        isCollaborator ? 'z-20' : ''
      } flex justify-end items-center bg-white w-[400px]`}
    >
      <div className="p-3 h-16 overflow-y-hidden flex justify-center items-center gap-4">
        <SavingStatusUI docStatus={docStatus} />
        {pluginType === 'dDoc' &&
          (isCollaborator ? (
            <Tooltip
              title={`Share and live collaborate with anyone that has your ${pluginType} link.`}
              placement="top"
            >
              <button
                onClick={() => handleShareButton()}
                className={`bg-yellow min-w-[86.5px] border-[2px] border-black h-[40px] flex items-center px-3 justify-center box-border rounded-md`}
              >
                <Link2 color={'#000000'} size={18} className="mr-2" />
                <p className="text-base">Share</p>
              </button>
            </Tooltip>
          ) : (
            <button onClick={() => handleShareButton()} className={yellowBtn}>
              <img src={more} className="w-5" />
              <p className="text-base">More</p>
            </button>
          ))}
        {isCollaborator && (
          <Tooltip
            title={`Publish your final version and pick if it should be a Public or Private decentralised file.`}
            placement="top"
          >
            <button
              onClick={() => (!fileName ? showTitlePopUp() : publishCanvas())}
              className={yellowBtn}
            >
              <img src={upload} className="mr-2 w-4 h-4" />
              <p className="text-base">Publish</p>
            </button>
          </Tooltip>
        )}
      </div>
    </div>
  )
}
export const TopLeftUI = ({
  onBackButtonClick,
  setFileName,
  fileName,
  portalName,
  filePreviewPage,
  contractLogo,
}: {
  onBackButtonClick?: () => void
  setFileName?: (name: string) => void
  fileName: string
  portalName?: string
  filePreviewPage?: boolean
  contractLogo?: string
}) => {
  const { address: contractAddress } = useParams()
  const walletAddress = usePrivyHelper().walletAddress
  const contract = useContract(contractAddress as string)
  const serverKeys = useServerKeys(
    walletAddress as string,
    contractAddress as string
  )
  const isCollaborator = checkIfOwnerOrCollaborator(
    contract,
    walletAddress as string,
    serverKeys
  )
  return (
    <div className="flex items-center gap-4 max-w-[60vw] truncate lg:max-w-sm">
      <button
        onClick={onBackButtonClick}
        className="w-auto min-w-fit bg-transparent items-center relative flex justify-center"
      >
        {filePreviewPage && contractLogo ? (
          <img src={contractLogo} className="min-w-10 w-10 rounded-md mr-2" />
        ) : (
          <ArrowBackIosNewIcon className="w-6" />
        )}
      </button>
      {isCollaborator && !filePreviewPage ? (
        <div className="flex w-full justify-center items-center">
          <p className={cn('font-bold', portalName && 'min-w-[50%]')}>
            {portalName && portalName + ' / '}
          </p>
          <input
            className="custom-input w-full"
            type="text"
            placeholder="Untitled"
            value={fileName}
            onChange={(e) => setFileName && setFileName(e.target.value)}
          />
        </div>
      ) : (
        <p title={fileName} className="font-semibold truncate">
          {portalName && portalName + ' / '} {fileName}
        </p>
      )}
    </div>
  )
}
