/* eslint-disable @typescript-eslint/no-explicit-any */
import { useHeartBit, InternalHandlerRef } from '@fileverse/heartbit-react'
import { ConnectedWallet, usePrivy, useWallets } from '@privy-io/react-auth'
import React, {
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useDialogStore } from './utils'
import { HeartBitButtonPair } from './HeartBitButtonPair'
import { ConnectWithPrivy } from './ConnectWithPrivy'
import { SocialShareCTA } from './SocialShareCTA'
import { useReward } from 'react-rewards'
import { SiweMessage } from 'siwe'
import { getHashedString } from '@fileverse/heartbit-core'
import { useHeartBitContext } from './HeartBitProvider'
import sendNotifcation from '../../../../utils/notification'
import { defaultNetwork } from '../../../../config/network-config'
import { useSignMessage } from 'wagmi'

export const getSignatureAndMessage = async (
  eoaWallet: ConnectedWallet,
  signMsg: (message: any) => Promise<string>
) => {
  const messageObject = new SiweMessage({
    domain: window.location.host,
    address: eoaWallet.address as string,
    statement: `Thank you for liking this page!`,
    uri: window.location.origin,
    version: '1',
    chainId: defaultNetwork.chainId,
  })

  const message = messageObject.prepareMessage()

  const signature = await signMsg({ message })

  return { message, signature }
}

export const getStartTimeAndEndTimeSeconds = async (
  startTime: number,
  endTime: number
) => {
  const startTimeSeconds = Math.floor(startTime / 1000) || 0
  const endTimeSeconds = Math.floor(endTime / 1000) || 0

  return { startTimeSeconds, endTimeSeconds }
}

interface HeartBitCTAProps {
  onMintComplete: (startTime: number, endTime: number) => Promise<void>
  setMinitingInProcess?: React.Dispatch<SetStateAction<boolean>>
  tokenId: string
}

export const HeartBitSection = ({
  setMinitingInProcess,
  tokenId,
  onLike,
}: {
  setMinitingInProcess?: React.Dispatch<SetStateAction<boolean>>
  tokenId: string
  onLike: () => void
}) => {
  const [showShareCTA, setShowShareCTA] = useState(false)
  const toggleShareCTA = () => setShowShareCTA((prev) => !prev)

  const onMintComplete = async () => {
    toggleShareCTA()
    onLike()
  }

  const { reward } = useReward('emojiReward', 'emoji', {
    emoji: ['💛'],
    position: 'absolute',
  })

  useEffect(() => {
    let interval: NodeJS.Timeout
    let count = 0

    if (showShareCTA) {
      interval = setInterval(() => {
        reward()
        count++
        if (count >= 3) {
          clearInterval(interval)
        }
      }, 1000)
    }

    return () => {
      clearInterval(interval)
    }
  }, [showShareCTA])

  if (showShareCTA)
    return (
      <div className="w-full flex flex-col gap-2 justify-center items-center text-center">
        <p className="text-sm">
          Share this file OR share the whole Fileverse Portal as a powerful
          Frame on Farcaster to get more love 👀
        </p>
        <SocialShareCTA tokenId={tokenId} />
      </div>
    )

  return (
    <HeartBitCTA
      onMintComplete={onMintComplete}
      setMinitingInProcess={setMinitingInProcess}
      tokenId={tokenId}
    />
  )
}

const HeartBitCTA = (props: HeartBitCTAProps) => {
  const { onMintComplete, setMinitingInProcess, tokenId } = props
  const heartRef = useRef<InternalHandlerRef | null>(null)
  const { signMessageAsync } = useSignMessage()
  const { setDialogState, dialogState, resetDialogState } = useDialogStore()

  const { startTime = null, endTime = null } = dialogState

  const [isLoading, setLoading] = useState(false)

  const [showConnectCTA, setShowConnectCTA] = useState(false)

  const { user } = usePrivy()

  const { wallets } = useWallets()

  const eoaWallet =
    wallets.find((wallet) => wallet.walletClientType === 'privy') || wallets[0]

  const walletClientType = eoaWallet?.walletClientType

  const { mintHeartBit } = useHeartBit()
  const { getUserBalance } = useHeartBitContext()

  const onFinishMint = async () => {
    if (typeof heartRef.current?.onReset === 'function')
      heartRef.current.onReset()
    setDialogState({
      ...dialogState,
      startTime: null,
      endTime: null,
    })
    setLoading(false)
    await getUserBalance(tokenId, eoaWallet.address)
  }

  const mintWithInjectedWallet = useCallback(async () => {
    if (!startTime || !endTime || !eoaWallet || isLoading) return
    try {
      setLoading(true)

      const { message, signature } = await getSignatureAndMessage(
        eoaWallet,
        signMessageAsync
      )

      const { startTimeSeconds, endTimeSeconds } =
        await getStartTimeAndEndTimeSeconds(startTime, endTime)

      await mintHeartBit({
        startTime: startTimeSeconds,
        endTime: endTimeSeconds,
        hash: getHashedString(tokenId),
        message,
        signature,
      })
      await onMintComplete(startTime, endTime)
    } catch (error: any) {
      console.log(error)
      resetDialogState()
      sendNotifcation('Failed to mint heartbit', '', 'danger')
      setShowConnectCTA(false)
    } finally {
      onFinishMint()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endTime, eoaWallet, isLoading, startTime])

  const mintWithPrivyWallet = useCallback(async () => {
    if (!user || !startTime || !endTime || !eoaWallet || isLoading) return
    try {
      setLoading(true)

      const { message, signature } = await getSignatureAndMessage(
        eoaWallet,
        signMessageAsync
      )

      const { startTimeSeconds, endTimeSeconds } =
        await getStartTimeAndEndTimeSeconds(startTime, endTime)

      await mintHeartBit({
        startTime: startTimeSeconds,
        endTime: endTimeSeconds,
        hash: getHashedString(tokenId),
        message,
        signature,
      })
      await onMintComplete(startTime, endTime)
    } catch (error: any) {
      console.log(error)
      resetDialogState()
      sendNotifcation('Failed to mint heartbit', '', 'danger')
      setShowConnectCTA(false)
    } finally {
      onFinishMint()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endTime, startTime, user, eoaWallet, isLoading])

  useEffect(() => {
    if (walletClientType && walletClientType !== 'privy')
      mintWithInjectedWallet()
    else mintWithPrivyWallet()
  }, [mintWithInjectedWallet, mintWithPrivyWallet, walletClientType])

  if (showConnectCTA && !isLoading) return <ConnectWithPrivy />

  return (
    <HeartBitButtonPair
      heartBitUiRef={heartRef}
      isLoading={isLoading}
      toggleConnectCTA={() => setShowConnectCTA(true)}
      setMinitingInProcess={setMinitingInProcess}
    />
  )
}
