/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { SetStateAction, useEffect, useRef, useState } from 'react'
import * as Y from 'yjs'
import { AnyExtension, Editor, useEditor } from '@tiptap/react'
import { isAddress, JsonRpcSigner, Signer } from 'ethers'
import { ISEAPair } from 'gun'
import isEmpty from 'lodash/isEmpty'
import { OutputData } from '@editorjs/editorjs'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { IndexeddbPersistence } from 'y-indexeddb'

// Internal modules
import { useContract, usePortalSignlessMode } from '../../store/contract/hooks'
import { useAppDispatch } from '../../store/hooks'
import {
  useInvokerContractCredentials,
  useInvokerCredential,
  useServerKeys,
} from '../../store/invoker/hooks'
import {
  fetchAndSaveContract,
  fetchStorageUsage,
} from '../../store/contract/thunks'

// Components
import Collaboration from '@tiptap/extension-collaboration'
import EditorV1 from './Editor'
import Header from '../common/Headers/MainHeader'
import TiptapEditor from '../TipTap/TiptapEditor'
import WalletDisconnected from '../common/WalletDisconnected'
import { WebPageCover } from './WebPageCover'

// Utilities
import {
  SEA,
  createAndSaveDraftMetadata,
  editDraftName,
  getDraftMetadata,
  removeDraft,
  updatePluginMetadata,
} from '../../utils/collaboration/utils'
import { getISEAKeyPair, generateISEAKey } from '../../utils/libCrypto'
import { MakeFileFromObject } from '../../utils/makeFileFromObject'
import {
  IDocumentContent,
  postDocContent,
  postEditedPluginContent,
} from '../TipTap/useEditorHook'
import { resolveEnsName } from '../../utils/networks/ensResolvers'
import sendNotifcation from '../../utils/notification'

// Types
import { TiptapEditorProps } from '../../components/TipTap/props'
import { IDraft } from '../../types/interface/drafts.interface'
import {
  DocumentPageStatus,
  FileSource,
  FileTypeEnum,
} from '../../types/enum/file.enum'

// Enums
import { DocStatus } from '../ExcalidrawCanvas/SavingStatusUI'
import { defaultExtensions } from '../TipTap/extensions/defaultExtenstion'
import { getSlicedName } from '../../utils/string'
import axios from 'axios'
import { editFile } from '../../store/files/reducer'
import {
  UploadToContractPayload,
  createEditGate,
  uploadFileToIpfs,
} from '../../store/files/thunks'
import { INewFile, IFileMetaData } from '../../types/interface/file.interface'
import { TokenGateHashInfo } from '../../types/interface/token.interface'
import { getFileTags } from '../../utils/getApplicableFileTags'
import { getIPFSAsset } from '../../utils/getIPFSAsset'
import { useFileData } from '../../store/files/hooks'
import { createFile } from '../../utils/files/fileCreateHandler'

import { IDpageAsset, IPluginFileData } from '../../utils/collaboration/rtc'
import { useGunNodes } from '../../hooks/useGunNode'
import { toUint8Array } from 'js-base64'
import { decryptUsingSEAKey } from '../../utils/crypto'
import { WebPageMoreMenu } from './WebPageMoreMenu'
import PluginNavbar from '../Navbars/PluginNavbar'
import { checkIfOwnerOrCollaborator } from '../../utils/checkIfOwnerOrCollaborator'
import TiptapToolBar from '../TipTap/TiptapToolBar'
import WebPageToolbar from './WebPageToolbar'
import { useEthersSigner } from '../../hooks/clientToProvider'
import { GunInstance } from '../../utils/instantiateGun'
import { captureException } from '@sentry/react'
import { isTiptapContentEmpty } from '../../utils/files'
import { validateOrSwitchNetwork } from '../../utils/ethUtils'
import { AllowedChainId } from '../../config/wagmi-config'
import { editFileTrxCall } from '../../utils/transaction'
import { usePrivyHelper } from '../../hooks/usePrivyHelper'
import { AnimatedLoader } from '../../pages/PublicPortal/components/Loader'
import { DeletePopUp } from './DeletePopUp'

export const SingleWebPage = () => {
  const [collabDocPreview, setCollabDocPreview] = useState<string | undefined>()
  const [commentsEnabled, setCommentsEnabled] = useState<boolean>(true)
  const [indexEnabled, setIndexEnabled] = useState<boolean>(true)
  const [confirmDeleteModal, setConfirmDeleteModal] = useState<boolean>(false)
  const [cover, setCover] = useState<string>('')
  const [docStatus, setDocStatus] = useState<DocStatus>(DocStatus.SAVED)
  const [ydoc] = useState(new Y.Doc())
  const [draftRTCData, setDraftRTCData] = useState<IDraft>()
  const [editorData, setEditorData] = useState<OutputData>()
  const [files, setFiles] = useState<File[]>([])
  const [pageStatus, setPageStatus] = useState<DocumentPageStatus>(
    DocumentPageStatus.PENDING
  )
  const [previewMode, setPreviewMode] = useState<boolean>(false)
  const [rtcKey, setRtcKey] = useState<string>()
  const [settingCover, setSettingCover] = useState<boolean>(false)
  const [isPublishedPage, setIsPublishedPage] = useState<boolean>(false)
  const [tokenDetails, setTokenDetails] = useState<TokenGateHashInfo>()
  const [uploadInformation, setUploadInformation] =
    useState<UploadToContractPayload>()
  const [draftAssetData, setDraftAssetData] = useState<IDpageAsset>({
    name: '',
    emoji: '',
    coverIPFSHash: '',
    fileId: '',
  })

  const areAllFieldsEmpty = (data: IDpageAsset): boolean => {
    return Object.values(data).every((value) => value === '')
  }

  const handleBackButtonClick = async () => {
    if (isTiptapContentEmpty(editor) && areAllFieldsEmpty(draftAssetData)) {
      await removeDraft(
        getAuthenticatedPluginMetadataNode(rtcId as string),
        rtcId as string
      )
      navigate(`/${contractAddress}?chainId=${chainId}`)
      return
    }
    if (isCollaborator) {
      await editDraftName(
        draftAssetData.name,
        getAuthenticatedPluginMetadataNode(rtcId as string)
      )
      navigate(`/${contractAddress}/dPages?chainId=${chainId}`)
    } else {
      navigate(`/${contractAddress}/member?chainId=${chainId}`)
    }
  }

  const navigate = useNavigate()

  const { address: contractAddress } = useParams()
  const { rtcId } = useParams()

  const [urlParams, setUrlParam] = useSearchParams()
  const chainId = parseInt(urlParams.get('chainId') || '')
  const walletAddress = usePrivyHelper().walletAddress
  const documentKey = getISEAKeyPair(urlParams.get('key') as string)
  const contract = useContract(contractAddress as string)
  const [moreMenu, setMoreMenu] = useState<boolean>(false)
  const [draftName, setDraftName] = useState<string>(draftRTCData?.name!)
  const portalName = contract?.metadata?.name
  const invoker = walletAddress as string
  const {
    getEditedDpageContentNode,
    getAuthenticatedPluginMetadataNode,
    getAuthenticatedDocumentContentNode,
  } = useGunNodes()
  const newVersion = draftRTCData?.version === 2
  const serverKeys = useServerKeys(
    walletAddress as string,
    contractAddress as string
  )
  const dispatch = useAppDispatch()
  const authKey = getISEAKeyPair(serverKeys.portalGunKey)
  const isCollaborator = checkIfOwnerOrCollaborator(
    contract,
    invoker,
    serverKeys
  )
  const isLoading = usePrivyHelper().isLoading
  const loadEditedDpageContents = async (rtcId: string) => {
    const draftEditContentNode = getEditedDpageContentNode(rtcId)
    if (!draftEditContentNode) return

    const draftEditContent = await Promise.race([
      new Promise((resolve) => {
        draftEditContentNode.on((data: IPluginFileData) => {
          if (data) {
            resolve(data)
          }
        })
      }),
      new Promise((resolve) => setTimeout(() => resolve(undefined), 5000)),
    ])
    if (draftEditContent) {
      const rtcContent = (draftEditContent as IPluginFileData)?.content
      const decryptedData = await SEA.decrypt(
        rtcContent,
        getISEAKeyPair(urlParams.get('key') as string) as ISEAPair
      )
      const contents = toUint8Array(decryptedData)
      Y.applyUpdate(ydoc, contents)
      setIsPublishedPage(true)
    }
  }
  const dropdownRef = useRef<HTMLDivElement>(null)
  const [uploading, setUploading] = useState<boolean>(false)
  const fileSource = FileSource.DPAGE
  const fileInformation = useFileData(
    contractAddress as string,
    draftAssetData.fileId
  )
  const credential = useInvokerContractCredentials(
    walletAddress as string,
    contractAddress as string
  )
  const invokerCredential = useInvokerCredential(
    walletAddress as string,
    contractAddress as string
  )
  const contractSigner = useEthersSigner({ chainId })
  const isSignless = usePortalSignlessMode(contractAddress as string)
  const getDocContents = async ({
    rtcId,
  }: {
    authKey: ISEAPair
    rtcId: string
  }): Promise<IDocumentContent | undefined> => {
    try {
      const contentNode = getAuthenticatedDocumentContentNode(rtcId)
      const content = await Promise.race([
        new Promise((resolve) => {
          contentNode.on((doc: IDocumentContent) => {
            if (doc) {
              resolve(doc)
              contentNode.off()
            }
          })
        }),
        new Promise((resolve) =>
          setTimeout(() => {
            contentNode.off()
            resolve(undefined)
          }, 5000)
        ),
      ])
      return content as IDocumentContent
    } catch (error) {
      console.log(error)
    }
  }
  const initializeDocument = async () => {
    try {
      const docContentInfo = await getDocContents({
        authKey: documentKey as ISEAPair,
        rtcId: rtcId as string,
      })
      if (docContentInfo && !isEmpty(docContentInfo)) {
        const { content: docEncryptedContent } = docContentInfo

        const plainContent = await decryptUsingSEAKey(
          docEncryptedContent,
          documentKey!
        )
        const contents = toUint8Array(plainContent)
        Y.applyUpdate(ydoc, contents)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const createOrLoadDPage = async () => {
    if (isLoading) return
    if (!rtcId) {
      sendNotifcation(
        'Unable to load document',
        'Specify the rtc_id of the document that is to be viewed',
        'danger'
      )
      return
    }
    if (!urlParams.get('key')) {
      const rtcKey = await generateISEAKey()
      urlParams.set('key', rtcKey)
      setUrlParam(urlParams)
      return
    }
    if (!documentKey) {
      setPageStatus(DocumentPageStatus.READY)
      return
    }
    const draftMetadata = await getDraftMetadata(
      authKey as ISEAPair,
      contractAddress as string,
      rtcId
    )
    if (!isEmpty(draftMetadata)) {
      setDraftRTCData(draftMetadata as IDraft)
      setDraftName(draftMetadata.name)
      setDraftAssetData({
        name: draftMetadata.name,
        coverIPFSHash: draftMetadata.coverIPFSHash,
        emoji: draftMetadata.emoji,
        fileId: draftMetadata.fileId,
      })
      if (draftMetadata?.isEdited) {
        loadEditedDpageContents(draftMetadata.rtcId)
      } else {
        initializeDocument()
      }

      setPageStatus(DocumentPageStatus.READY)
    } else {
      const result = await createAndSaveDraftMetadata(
        authKey as ISEAPair,
        urlParams.get('key') as string,
        serverKeys,
        contractAddress as string,
        rtcId,
        walletAddress as string,
        FileSource.DPAGE,
        2
      )

      if (result.isCreated) {
        setPageStatus(DocumentPageStatus.READY)
        setDraftRTCData(result.metadata)
      } else {
        sendNotifcation(
          'Failed to create document',
          result.message,
          'danger',
          10000
        )
        navigate(-1)
      }
    }
  }

  const setUpDocumentPage = async () => {
    setPageStatus(DocumentPageStatus.PENDING)
    // verify contract ens name globally before rendering dpage
    if (!isAddress(contractAddress as string)) {
      const address = await resolveEnsName(contractAddress as string)
      if (address) {
        navigate(`/${address}/dPages/${rtcId}?chainId=${chainId}`)
      } else {
        sendNotifcation(
          'Failed to load portal',
          'The portal ens name provided couldnt be resolved. Try using the portal address instead',
          'danger',
          7000
        )
      }
      return
    }
    if (!walletAddress && !isLoading) {
      setPageStatus(DocumentPageStatus.DISCONNECTED)
      return
    }
    try {
      // this should be checked globally
      if (isEmpty(contract) && walletAddress) {
        setPageStatus(DocumentPageStatus.FETCHING_CONTRACT)

        await dispatch(
          fetchAndSaveContract({
            contractAddress: contractAddress as string,
            invokerAddress: walletAddress,
            chainId,
          })
        ).unwrap()
        setPageStatus(DocumentPageStatus.PENDING)
      }
      await createOrLoadDPage()
    } catch (error: any) {
      console.error(error)
      sendNotifcation(
        'Failed to render document',
        error.message,
        'danger',
        7000
      )
      setPageStatus(DocumentPageStatus.PENDING)
    }
  }

  const editor = useEditor({
    extensions: [
      ...(defaultExtensions as AnyExtension[]),
      Collaboration.configure({
        document: ydoc,
      }),
    ],
    editorProps: TiptapEditorProps,
    autofocus: 'start',
  })

  const initalizeUpdateHandler = () => {
    if (isEmpty(draftRTCData) || !urlParams.get('key')) return

    const documentKey = getISEAKeyPair(
      urlParams.get('key') as string
    ) as ISEAPair
    const updateHandler = () => {
      if (!draftRTCData?.isEdited) {
        postDocContent({
          contractAddress: contractAddress as string,
          documentKey,
          rtcId: rtcId as string,
          setDocStatus: (status) => setDocStatus(status),
          ydoc,
        })
      } else {
        const draftEditContentNode = getEditedDpageContentNode(rtcId as string)
        if (!draftEditContentNode) return
        postEditedPluginContent({
          documentKey,
          contentNode: draftEditContentNode,
          rtcId: rtcId as string,
          setDocStatus: () => null,
          ydoc,
        })
      }
    }

    ydoc.on('update', updateHandler)
  }

  const getCollabDocPreview = async () => {
    const html = editor?.getHTML()
    return html
  }

  const publishWebPage = async () => {
    if (settingCover) {
      sendNotifcation(
        'Waiting for cover',
        'You can publish once your cover is updated',
        'warning'
      )
      return
    }
    const { name, coverIPFSHash, emoji } = draftAssetData
    const data = {
      webpageName: name,
      coverIPFSHash,
      emoji,
      editorData,
      source: 'fileverse_dPage',
      commentsEnabled,
      rtcId,
      rtcData: draftRTCData,
      rtcKey,
      version: 0,
    }
    const file = MakeFileFromObject(data, `${name || 'untitled'}`)
    setFiles([file])
  }

  const deleteEmptyDpageDraft = async (e: BeforeUnloadEvent) => {
    if (docStatus === DocStatus.SAVING) e.preventDefault()
    if (isTiptapContentEmpty(editor)) {
      await removeDraft(
        getAuthenticatedPluginMetadataNode(rtcId as string),
        rtcId as string
      )
    }

    return null
  }

  const setFileTokenInfo = async () => {
    const fileInfo = await getIPFSAsset({
      ipfsHash: fileInformation?.gateIPFSHash as string,
    })
    setTokenDetails(fileInfo?.data)
  }

  const handleFileEditedEvent = async (
    fileId: number,
    uploadInfo: UploadToContractPayload
  ) => {
    const updateFile: INewFile = {
      ...(fileInformation as INewFile),
      metadataIPFSHash: uploadInfo?.metadataIpfsHash,
      contentIPFSHash: uploadInfo?.contentIpfsHash,
      metadata: uploadInfo?.metadata as IFileMetaData,
      cache: { metadataIPFSUrl: '', contentIPFSUrl: '' },
    }
    dispatch(
      editFile({
        contractAddress: contractAddress as string,
        fileData: updateFile,
      })
    )
    setUploading(false)
    //remove draft from edit node
    try {
      const draftEditContentNode = getEditedDpageContentNode(
        draftRTCData?.rtcId as string
      )

      if (!draftEditContentNode) return
      await GunInstance.putGunNodeData(draftEditContentNode, null)
      updatePluginMetadata(
        { isPublished: true, fileId: fileId.toString() },
        getAuthenticatedPluginMetadataNode(draftRTCData?.rtcId as string)
      )
    } catch (err) {
      console.log(err)
      captureException(err)
    }
    navigate(`/${contractAddress}/file/${fileId}?chainId=${chainId}`)
  }

  const signUploadTransaction = async (x?: UploadToContractPayload) => {
    const uploadInfo = x || uploadInformation
    try {
      const editedFileId = await editFileTrxCall(
        {
          contractAddress: contractAddress as string,
          fileId: fileInformation?.fileId as number,
          walletAddress: walletAddress as string,
          contentIpfsHash: uploadInfo?.contentIpfsHash as string,
          metadataIpfsHash: uploadInfo?.metadataIpfsHash as string,
          gateIpfsHash: uploadInfo?.gateIpfsHash as string,
          fileType: uploadInfo?.fileType as FileTypeEnum,
        },
        isSignless
      )
      handleFileEditedEvent(editedFileId, uploadInfo as UploadToContractPayload)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      sendNotifcation('Signing failed', error?.message, 'danger')
      setUploading(false)
    }
  }

  const handleFileUpload = async (type: FileTypeEnum, files: File[]) => {
    try {
      await validateOrSwitchNetwork(chainId as AllowedChainId)
      setUploading(true)
      sendNotifcation(
        'Your dPages is being updated',
        'Kindly do not refresh, go back or edit',
        'info',
        10000
      )
      let gateIpfsHash = ''
      let gateKey = ''

      if (type === FileTypeEnum.GATED && credential && tokenDetails) {
        const includeCollaborators = true
        const payload = {
          tokenParam: tokenDetails?.params[0],
          credential,
          domainContractAddress: contractAddress as string,
          invoker: walletAddress as string,
          includeCollaborators,
          name: tokenDetails?.name,
          amount: tokenDetails?.amount as number,
          tokenChainName: tokenDetails?.chain,
          chainId,
        }
        const { key, hash } = await dispatch(createEditGate(payload)).unwrap()
        gateIpfsHash = hash
        gateKey = key
      }
      const { coverIPFSHash, emoji } = draftAssetData

      const { contentFile, metadata: newFileMetadata } = await createFile(
        walletAddress as string,
        type,
        files,
        serverKeys,
        fileSource,
        gateKey,
        coverIPFSHash,
        emoji,
        commentsEnabled
      )
      const oldFileMetaData = fileInformation?.metadata!
      const updatedMetadata = {
        ...newFileMetadata,
        version: oldFileMetaData.version,
        portalLock: {
          ...newFileMetadata.portalLock,
          lockedChatKey: oldFileMetaData.portalLock?.lockedChatKey,
        },
        ownerLock: {
          ...newFileMetadata.ownerLock,
          lockedChatKey: oldFileMetaData?.ownerLock?.lockedChatKey,
        },
        memberLock: {
          ...newFileMetadata.memberLock,
          lockedChatKey: oldFileMetaData?.memberLock?.lockedChatKey,
        },
        tokenLock: oldFileMetaData.tokenLock,
        publicLock: oldFileMetaData.publicLock,
        isEdited: true,
      } as IFileMetaData
      const updatedMetadataFile = MakeFileFromObject(
        updatedMetadata,
        'metadata'
      )

      const cancelToken = axios.CancelToken.source()

      const fileTags = getFileTags(type, fileSource)
      if (credential) {
        const { contentIpfsHash, metadataIpfsHash } = await dispatch(
          uploadFileToIpfs({
            credential,
            cancelToken,
            contentFile: contentFile,
            metadataFile: updatedMetadataFile,
            contractAddress: contractAddress as string,
            invoker: walletAddress as string,
            chainId,
            fileTags,
          })
        ).unwrap()
        await dispatch(
          fetchStorageUsage({
            contractAddress: contractAddress as string,
            editSecret: invokerCredential.editSecret,
            invoker: walletAddress as string,
            chain: chainId,
          })
        )

        await signUploadTransaction({
          contractAddress: contractAddress as string,
          contractSigner: contractSigner as Signer,
          contentIpfsHash,
          metadataIpfsHash,
          fileType: type,
          gateIpfsHash: gateIpfsHash,
          metadata: updatedMetadata,
        })

        setUploadInformation({
          contractAddress: contractAddress as string,
          contractSigner: contractSigner as Signer,
          contentIpfsHash,
          metadataIpfsHash,
          fileType: type,
          metadata: updatedMetadata,
          gateIpfsHash,
        })
      } else {
        sendNotifcation('Invalid Credentials', '', 'danger')
        setUploading(false)
        return
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.log(error)
      sendNotifcation(error?.message, '', 'danger')
      setUploading(false)
    }
  }

  const publishWebPageV2 = async () => {
    if (settingCover) {
      sendNotifcation(
        'Waiting for cover',
        'You can publish once your cover is updated',
        'warning'
      )
      return
    }

    const editorData = editor?.getJSON()
    const { name, coverIPFSHash, emoji } = draftAssetData

    const data = {
      webpageName: name,
      coverIPFSHash,
      emoji,
      editorData,
      source: 'fileverse_dPage',
      commentsEnabled,
      indexEnabled,
      rtcId,
      rtcData: draftRTCData,
      rtcKey,
      version: 2,
    }

    const html = await getCollabDocPreview()
    setCollabDocPreview(html)
    const file = MakeFileFromObject(data, `${name || 'untitled'}`)
    if (isPublishedPage && draftRTCData?.fileId) {
      handleFileUpload(FileTypeEnum.PUBLIC, [file])
    } else {
      setFiles([file])
    }
  }

  useEffect(() => {
    // sync changes to indexedb only when document is a fresh document
    if (draftRTCData && !draftRTCData.isEdited) {
      new IndexeddbPersistence(rtcId as string, ydoc)
    }
    initalizeUpdateHandler()
  }, [draftRTCData])

  useEffect(() => {
    setUpDocumentPage()
    if (isPublishedPage) {
      if (fileInformation?.metadata.fileType === FileTypeEnum.GATED)
        setFileTokenInfo()
    }
  }, [walletAddress, contractAddress, urlParams, isLoading])

  useEffect(() => {
    if (files.length > 0) {
      navigate(`/${contractAddress}?chainId=${chainId}`, {
        state: {
          file: files,
          source: FileSource.DPAGE,
          collabDocPreview: collabDocPreview,
          version: 2,
          coverIPFSHash: draftAssetData.coverIPFSHash,
          emoji: draftAssetData.emoji,
          rtcId,
          rtcData: draftRTCData,
        },
      })
    }
  }, [files])

  useEffect(() => {
    if (draftAssetData.name !== 'Untitled')
      document.title = `${getSlicedName(
        draftAssetData.name,
        10
      )} | dPages | Fileverse`
    return () => {
      document.title = 'Fileverse'
    }
  }, [draftAssetData.name])

  useEffect(() => {
    const key = urlParams.get('key')
    setRtcKey(key!)
  }, [urlParams])

  useEffect(() => {
    window.addEventListener('beforeunload', deleteEmptyDpageDraft)
    return () => {
      window.removeEventListener('beforeunload', deleteEmptyDpageDraft)
    }
  }, [editor])

  const onTitleChange = async (draftName: string) => {
    setDocStatus(DocStatus.SAVING)
    setDraftName(draftName)
    setDraftAssetData((prev) => {
      return { ...prev, name: draftName }
    })
    const authNode = getAuthenticatedPluginMetadataNode(rtcId as string)
    if (!authNode) return
    const nameNode = authNode.get('name')
    await GunInstance.putGunNodeData(nameNode, draftName)
    setDocStatus(DocStatus.SAVED)
  }

  return (
    <div
      data-cy="single-webpage"
      className="h-full flex flex-col gap-6 overflow-scroll no-scrollbar"
    >
      {pageStatus !== DocumentPageStatus.DISCONNECTED ? (
        <div className="h-full flex flex-col overflow-scroll no-scrollbar">
          {(pageStatus === DocumentPageStatus.PENDING ||
            pageStatus === DocumentPageStatus.FETCHING_CONTRACT) && (
            <div className="w-screen h-screen flex flex-col gap-4 justify-center items-center">
              <AnimatedLoader />

              {pageStatus === DocumentPageStatus.FETCHING_CONTRACT && (
                <p className="text-xl mt-8">Fetching portal details ... </p>
              )}
            </div>
          )}
          {pageStatus === DocumentPageStatus.READY && (
            <div className="w-full h-full">
              <div className="h-fit relative bg-[#ffffff]">
                {moreMenu && (
                  <WebPageMoreMenu
                    confirmDeleteModal={confirmDeleteModal}
                    setConfirmDeleteModal={setConfirmDeleteModal}
                    indexEnabled={indexEnabled}
                    commentsEnabled={commentsEnabled}
                    setCommentsEnabled={setCommentsEnabled}
                    setIndexEnabled={setIndexEnabled}
                    isDPage={true}
                    fileDownloadName={draftAssetData.name}
                    filePreviewPage={false}
                    moreMenuRef={dropdownRef}
                    isOwner={isCollaborator}
                    rtcId={rtcId}
                  />
                )}
                <PluginNavbar
                  isTitleFieldEnabled={!previewMode}
                  dropdownRef={dropdownRef}
                  docStatus={docStatus}
                  onLiveCollaborationTrigger={() => null}
                  pluginTool={
                    newVersion ? (
                      <TiptapToolBar editor={editor as Editor} />
                    ) : (
                      <WebPageToolbar previewMode={previewMode} />
                    )
                  }
                  portalName={portalName}
                  pluginTitle={draftName}
                  isPreviewMode={previewMode}
                  setPreviewMode={setPreviewMode}
                  backButtonAction={handleBackButtonClick}
                  isUserACollaborator={isCollaborator}
                  isEditMode={false}
                  setEditMode={() => null}
                  setIsEditCancelFlag={() => null}
                  isPublishLoading={false} // fix the name to be isLoading
                  isCommentsEnabled={false}
                  toggleCommentVisibility={() => null}
                  toggleQrModalVisibility={() => null}
                  isCommentsVisible={false}
                  toggleMoreMenuVisibility={() => setMoreMenu((prev) => !prev)}
                  toggleProvenaceModalVisibility={() => null}
                  commentCount={0}
                  publishPlugin={newVersion ? publishWebPageV2 : publishWebPage}
                  isPublished={false}
                  setPluginTitle={onTitleChange}
                  isCollaborating={false}
                  collaborators={null}
                  collaborationDisabled={true}
                />
              </div>
              <main className="mt-[68px] h-full w-full rounded-[8px] flex flex-col justify-start items-center gap-2">
                <WebPageCover
                  cover={cover}
                  setCover={setCover}
                  previewMode={previewMode}
                  emoji={draftAssetData.emoji}
                  authKey={authKey}
                  rtcData={draftRTCData as IDraft}
                  rtcId={rtcId}
                  webpageName={draftName}
                  setDraftName={setDraftName}
                  setSettingCover={setSettingCover}
                  settingCover={settingCover}
                  setDraftAssetData={setDraftAssetData}
                  coverIPFSHash={draftAssetData.coverIPFSHash}
                />

                {newVersion ? (
                  <TiptapEditor
                    editor={editor as Editor}
                    isDisabled={previewMode}
                    hasToC={indexEnabled}
                  />
                ) : (
                  <EditorV1
                    isDisabled={previewMode}
                    setEditorData={
                      setEditorData as React.Dispatch<
                        SetStateAction<OutputData>
                      >
                    }
                    documentKey={urlParams.get('key') as string}
                  />
                )}
              </main>
              <DeletePopUp
                isOpen={confirmDeleteModal}
                setIsOpen={setConfirmDeleteModal}
                rtcId={rtcId}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="h-[100vh] min-h-fit">
          <Header />
          <div className="h-[90vh] flex flex-col justify-center w-full p-4">
            <WalletDisconnected collabDoc />
          </div>
        </div>
      )}
    </div>
  )
}
