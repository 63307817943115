import React from 'react'
import cn from 'classnames'

export const DashboardTourModal = ({
  setCurrentStep,
  width,
  currentStep,
  icon,
  title,
  desc,
}: {
  setCurrentStep: () => void
  width: string
  currentStep: number
  icon: string
  title: string
  desc: string
}) => {
  return (
    <div
      className={cn(
        'w-[332px] min-h-[328px] h-fit py-2 bg-white shadow flex justify-center items-center rounded-xl'
      )}
    >
      <div className="w-[276px] min-h-[273px] h-fit py-2 flex-col justify-start items-start gap-4 inline-flex">
        <div className="w-12 h-12 justify-start items-start gap-2.5 inline-flex">
          <div className="w-12 h-12 bg-yellow rounded-2xl shadow flex justify-center items-center">
            <img src={icon} className=" w-6 h-6" />
          </div>
        </div>
        <div className="flex-col justify-start items-start gap-2.5 flex">
          <div className="text-heading-md">{title}</div>
          <div className="w-72 text-body-sm color-text-secondary">{desc}</div>
        </div>
        <div className="w-[276px] grow shrink basis-0 flex-col justify-start items-start gap-0.5 flex">
          <div className="w-10 text-helper-text-sm ">
            {currentStep + 1}
            <span className=" color-text-secondary">/4</span>
          </div>
          <div className="w-[100%] h-2.5 relative color-bg-secondary rounded-full">
            <div
              className={`w-[${width}%] h-2.5 left-0 top-0 absolute bg-yellow rounded-full border border-yellow`}
            />
          </div>
        </div>
        <div
          onClick={setCurrentStep}
          className="h-10 px-8 p-3.5 cursor-pointer self-end bg-zinc-900 rounded justify-center items-center gap-2.5 inline-flex"
        >
          <div className="text-white text-base font-semibold leading-tight">
            {currentStep === 3 ? 'Done' : 'Next'}
          </div>
        </div>
      </div>
    </div>
  )
}
