/* eslint-disable @typescript-eslint/no-explicit-any */
import StarterKit from '@tiptap/starter-kit'
import TiptapLink from '@tiptap/extension-link'
import Placeholder from '@tiptap/extension-placeholder'
import TiptapUnderline from '@tiptap/extension-underline'
import TextStyle from '@tiptap/extension-text-style'
import { Color } from '@tiptap/extension-color'
import TaskItem from '@tiptap/extension-task-item'
import TaskList from '@tiptap/extension-task-list'
import Highlight from '@tiptap/extension-highlight'
import { InputRule } from '@tiptap/core'
import FontFamily from '@tiptap/extension-font-family'
import TextAlign from '@tiptap/extension-text-align'
import DropCursor from '@tiptap/extension-dropcursor'
import GapCursor from '@tiptap/extension-gapcursor'
import SlashCommand from '../slashCommand'
import { uploadFn } from '../utils/upload-images'
import { EmbeddedTweet } from './twitterEmbed'
import { Iframe } from './Iframe'
import { ResizableMedia } from './resizableMedia'
import { Document } from './Document'
import { DBlock } from './dBlock'
import { TrailingNode } from './trailingNode'
import { SuperchargedTableExtensions } from './supercharged-table'
import { actionButton } from './actionButton'
import CustomKeymap from './custom-keymap'
import { NodeType } from '@tiptap/pm/model'
import { TableOfContentNode } from './TableOfContentNode'
import TableOfContent from '@tiptap-pro/extension-table-of-content'
import ColumnExtension from './MultiColumn'
import { HorizontalRule } from './HorizontalRule'
import { LinkToPage } from './LinkToPage'
import { LinkButton } from './LinkToPage/LinkButton'

export const defaultExtensions = [
  FontFamily.configure({
    types: ['textStyle'],
  }),
  StarterKit.configure({
    strike: {
      HTMLAttributes: {
        class: 'select-text pointer-events-auto',
      },
    },
    bold: {
      HTMLAttributes: {
        class: 'select-text pointer-events-auto',
      },
    },
    italic: {
      HTMLAttributes: {
        class: 'select-text pointer-events-auto',
      },
    },
    heading: {
      HTMLAttributes: {
        class: 'select-text pointer-events-auto',
      },
    },
    paragraph: {
      HTMLAttributes: {
        class: 'select-text pointer-events-auto',
      },
    },
    bulletList: {
      HTMLAttributes: {
        class:
          'flex flex-col items-start list-disc list-outside space-y-4 ml-6',
      },
    },
    orderedList: {
      HTMLAttributes: {
        class:
          'flex flex-col items-start list-decimal list-outside space-y-4 ml-6',
      },
    },
    listItem: {
      HTMLAttributes: {
        class: 'leading-normal',
      },
    },
    blockquote: {
      HTMLAttributes: {
        class:
          'rounded-[12px] color-bg-secondary p-5 font-serif italic text-black',
      },
    },
    codeBlock: {
      HTMLAttributes: {
        class:
          'rounded-[12px] bg-transparent border-[1px] color-border-default p-5 font-mono font-medium text-black',
      },
      exitOnArrowDown: true,
    },
    code: {
      HTMLAttributes: {
        class:
          'rounded color-bg-secondary px-2 py-1 font-mono font-medium text-black text-sm',
        spellcheck: 'false',
      },
    },
    history: false,
    gapcursor: false,
    dropcursor: false,
    document: false,
    horizontalRule: false,
  }),
  TextAlign.configure({
    types: ['heading', 'paragraph'],
  }),
  HorizontalRule,
  TiptapLink.configure({
    HTMLAttributes: {
      class:
        'text-blue-500 font-bold transition-colors cursor-pointer select-text pointer-events-auto',
    },
    openOnClick: true,
  }),
  Placeholder.configure({
    placeholder: () => '',
    includeChildren: true,
    showOnlyCurrent: true,
  }),
  Highlight.configure({ multicolor: true }),
  SlashCommand,
  LinkToPage,
  TiptapUnderline.configure({
    HTMLAttributes: {
      class: 'select-text pointer-events-auto',
    },
  }),
  TextStyle.configure({
    HTMLAttributes: {
      class: 'select-text pointer-events-auto',
    },
  }),
  Color,
  TaskList.configure({
    HTMLAttributes: {
      class: 'not-prose space-y-4 ml-2',
    },
  }),
  TaskItem.configure({
    HTMLAttributes: {
      class: 'flex items-start',
    },
    nested: true,
  }),
  DropCursor.configure({
    width: 3,
    color: '#d1d5db',
  }),
  GapCursor,
  DBlock,
  TrailingNode,
  Document,
  ...SuperchargedTableExtensions,
  ResizableMedia.configure({
    uploadFn: uploadFn,
  }),
  CustomKeymap,
  Iframe,
  EmbeddedTweet,
  actionButton.extend({
    addInputRules() {
      return [
        createInputRule(/^(?:twt|###|___\s|\*\*\*\s)$/, 'twitter', this.type),
        createInputRule(/^(?:ytb|@@@|___\s|\*\*\*\s)$/, 'iframe', this.type),
      ]
    },
  }),
  LinkButton,
  TableOfContent,
  TableOfContentNode,
  ColumnExtension,
]

export const createInputRule = (
  pattern: RegExp,
  data: string,
  type: NodeType
) => {
  return new InputRule({
    find: pattern,
    handler: ({ state, range, commands }) => {
      const attributes = {
        data,
      }

      const { tr } = state
      const start = range.from
      const end = range.to

      tr.insert(start - 1, type.create(attributes)).delete(
        tr.mapping.map(start),
        tr.mapping.map(end)
      )

      // focus on the node that was just created
      commands.focus(end - 1)
    },
  })
}
