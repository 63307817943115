/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-constant-condition */
import React, {
  useState,
  memo,
  useRef,
  useEffect,
  Dispatch,
  createRef,
} from 'react'
import { MakeFileFromObject } from '../../utils/makeFileFromObject'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import useVisibility from '../../hooks/useVisibility'
import { Popup } from '../Popup/PopUp'
import CTAButton from '../common/CTAButton'
import { NonDeletedExcalidrawElement } from '@fileverse/whiteboard/types/element/types'
import { ISEAPair } from 'gun'
import {
  editDraftName,
  getDraftMetadata,
  updatePluginMetadata,
} from '../../utils/collaboration/utils'
import { IDraft } from '../../types/interface/drafts.interface'
import { ExcalidrawApp, exportToBlob, SHAPES } from '@fileverse/whiteboard'
import {
  ExcalidrawImperativeAPI,
  BinaryFiles,
} from '@fileverse/whiteboard/types/types'
import { useContract } from '../../store/contract/hooks'
import { checkIfOwnerOrCollaborator } from '../../utils/checkIfOwnerOrCollaborator'
import { DocStatus } from './SavingStatusUI'
import PluginNavbar from '../Navbars/PluginNavbar'
import { useGunNodes } from '../../hooks/useGunNode'
import { renderWhiteboardToolbar } from '../FileViewers/WhiteboardFileViewer'
import useWhiteboardKeyCodeHandler from '../../hooks/useWhiteboardKeyCodeHandler'
import LiveCollaborationPopup from '../TipTap/LiveCollaborationPopup'
import { useServerKeys } from '../../store/invoker/hooks'
import { getISEAKeyPair } from '../../utils/libCrypto'
import sendNotifcation from '../../utils/notification'
import { useLocalStorage } from 'usehooks-ts'
import { ConnectToDocument } from '../../pages/CollaborativeDocument'
import { WebPageMoreMenu } from '../WebPages/WebPageMoreMenu'
import { useEthersSigner } from '../../hooks/clientToProvider'
import { SESSION_HOST_FLAG } from '../../utils/constants'
import {
  GunInstance,
  gunInstance,
  seaInstance,
} from '../../utils/instantiateGun'
import { usePrivyHelper } from '../../hooks/usePrivyHelper'
import { DeletePopUp } from '../WebPages/DeletePopUp'

export interface ICanvasProps {
  setSelectFiles: React.Dispatch<File[]>
  setWhiteboardPreview: React.Dispatch<React.SetStateAction<string | undefined>>
  authKey: ISEAPair
  rtcData: IDraft
}

const Canvas = ({
  setSelectFiles,
  setWhiteboardPreview,
  rtcData,
}: ICanvasProps) => {
  const [excalidrawAPI, setExcalidrawAPI] =
    useState<ExcalidrawImperativeAPI | null>(null)
  const [draftName, setDraftName] = useState<string>(rtcData?.name || '')
  const [confirmDeleteModal, setConfirmDeleteModal] = useState<boolean>(false)
  const { address: contractAddress, rtcId } = useParams()
  const [projectPreview, setProjectPreview] = useState<string>('')
  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams()

  const walletAddress = usePrivyHelper().walletAddress
  const chainId = parseInt(searchParams.get('chainId') || '')
  const contractSigner = useEthersSigner({ chainId })
  const contract = useContract(contractAddress as string)
  const liveCollaborationModalRef = useRef(null)
  const toolbarRefs = useRef(SHAPES.map(() => React.createRef()))
  const freeHandToolRef = useRef(React.createRef())
  const [activeTool, setActiveTool] = useState<string>('')
  const [isWhiteboardDisabled, setIsWhiteboardDisabled] = useState(false)
  const portalName = contract?.metadata?.name
  const { getAuthenticatedPluginMetadataNode } = useGunNodes()
  const serverKeys = useServerKeys(
    walletAddress as string,
    contractAddress as string
  )
  const authKey = getISEAKeyPair(serverKeys?.portalGunKey)
  const isCollaborator = checkIfOwnerOrCollaborator(
    contract,
    walletAddress as string,
    serverKeys
  )

  const onTitleChange = async (draftName: string) => {
    setDraftName(draftName)
    const authNode = getAuthenticatedPluginMetadataNode(rtcId as string)
    if (!authNode) return
    const nameNode = authNode.get('name')
    await GunInstance.putGunNodeData(nameNode, draftName)
  }

  const publishCanvas = async (
    excalidrawAPI: ExcalidrawImperativeAPI | null
  ) => {
    if (!excalidrawAPI?.getSceneElements() || !excalidrawAPI?.getFiles()) return
    if (!contractAddress || !contractSigner) return
    if (!draftName) {
      setExcalidrawAPI(excalidrawAPI)
      showTitlePopUp(excalidrawAPI)
      return
    }
    editDraftName(
      draftName,
      getAuthenticatedPluginMetadataNode(rtcId as string)
    )

    const excalidrawCanvas = {
      type: 'excalidraw',
      version: 2,
      source: 'fileverse',
      elements: excalidrawAPI?.getSceneElements(),
      appState: { ...excalidrawAPI?.getAppState(), selectedElementIds: {} },
      files: excalidrawAPI?.getFiles(),
    }

    const fileObj = {
      file: excalidrawCanvas,
      source: 'fileverse-excalidraw',
      version: 0,
    }
    const file = MakeFileFromObject(fileObj, `${draftName || 'Untitled'}`)
    const url = await getWhiteboardPreview(excalidrawAPI)
    setWhiteboardPreview(url)
    setSelectFiles([file])
  }
  const handleBackButtonClick = async () => {
    if (isCollaborationActive) {
      handleDisconnection()
    }
    if (!isCollaborator) {
      navigate(`/${contractAddress}/member?chainId=${chainId}`, {
        replace: true,
      })
      return
    }
    if (draftName) {
      editDraftName(
        draftName,
        getAuthenticatedPluginMetadataNode(rtcId as string)
      )
    }

    navigate(`/${contractAddress}?chainId=${chainId}`, {
      replace: true,
    })
  }

  const getWhiteboardPreview = async (
    excalidrawAPI: ExcalidrawImperativeAPI | null
  ) => {
    const data = await exportToBlob({
      elements:
        excalidrawAPI?.getSceneElements() as NonDeletedExcalidrawElement[],
      appState: excalidrawAPI?.getAppState(),
      files: excalidrawAPI?.getFiles() as BinaryFiles,
    })
    const url = URL.createObjectURL(data)
    return url
  }
  const showTitlePopUp = async (excalidrawAPI: ExcalidrawImperativeAPI) => {
    const url = await getWhiteboardPreview(excalidrawAPI)
    setProjectPreview(url)
    setTitlePopUp(true)
    setWhiteboardPreview(url)
  }
  const {
    ref: titlePopUpDiv,
    isComponentVisible: titlePopUp,
    setIsComponentVisible: setTitlePopUp,
  } = useVisibility(false)

  const renderTool = () => {
    const freeHandToolHandler = async () => {
      await (freeHandToolRef as any).current.click()
      setActiveTool(excalidrawAPI?.getAppState().activeTool.type as string)
    }
    const onShapeClick = async (index: number) => {
      await (toolbarRefs.current[index] as any).current.click()
      setActiveTool(excalidrawAPI?.getAppState().activeTool.type as string)
    }
    return renderWhiteboardToolbar({
      freeHandToolHandler,
      activeTool,
      onShapeClick,
    })
  }

  const collabRef = useRef(null)

  const {
    isComponentVisible: isOpen,
    ref,
    setIsComponentVisible,
  } = useVisibility(false)
  const triggerLiveCollaboration = () => {
    if (isWhiteboardDisabled && !isCollaborator) {
      navigate(`/${contractAddress}/member?chainId=${chainId}`, {
        replace: true,
      })
    }
    if (isCollaborator || isCollaborationActive) {
      setIsComponentVisible(true)
    }
  }

  useEffect(() => {
    if (!excalidrawAPI) return
    setActiveTool(excalidrawAPI?.getAppState().activeTool.type as string)
  }, [excalidrawAPI])
  const [isPreviewMode, setPreviewMode] = useState(false)
  const [savedStatus] = useState(DocStatus.SAVED)
  const [showDropDownMenu, setShowDropdownMenu] = useState(false)
  const excalidrawAppRef = createRef()
  const dropdownRef = useRef<HTMLDivElement>(null)
  const [collaborators, setCollaborators] = useState([])
  const [commentsEnabled, setCommentsEnabled] = useState<boolean>(true)
  const openDownloadModal = () => {
    if (excalidrawAppRef.current) {
      ;(excalidrawAppRef.current as any).openDownloadModal()
    }
  }
  useWhiteboardKeyCodeHandler({ setActiveTool })
  const isOwner = !!(
    authKey &&
    rtcData?.owner &&
    rtcData?.owner === walletAddress
  )
  const [urlParams] = useSearchParams()

  const [isCollaborationActive, setIsCollaborationActive] = useState(
    urlParams.get('collab') === 'true'
  )

  const setCollaboratorsState = (clientsMap: Map<any, any>) => {
    const clients = Array.from(clientsMap.values())

    setCollaborators(clients as any)
  }

  const listenForNewCollaborators = (clientsMap: Map<any, any>) => {
    ;(collabRef.current as any)?.webrtcProvider?.awareness.on('update', () => {
      setCollaboratorsState(clientsMap)
    })
  }

  const initalizeLiveCollaboratorsState = () => {
    const collabApi: any = collabRef.current
    const webrtcProvider = collabApi?.webrtcProvider
    if (!webrtcProvider) {
      handleDisconnection()
      sendNotifcation(
        'Cannot start collaboration. Please try again',
        '',
        'danger'
      )
      return
    }
    if (!isCollaborator) {
      const ymap = collabApi.yMap
      if (ymap.get(SESSION_HOST_FLAG) === null) {
        navigate(`/${contractAddress}/member?chainId=${chainId}`, {
          replace: true,
        })
        sendNotifcation('Session already ended by the host', '', 'danger')
        return
      } else {
        // keep observing ymap to know when to disconnect user
        const observer = () => {
          if (ymap.get(SESSION_HOST_FLAG) === null) {
            handleDisconnection()
            sendNotifcation('Session ended', '', 'danger')
            setIsWhiteboardDisabled(true)
            ymap.unobserve(observer)
          }
        }
        ymap.observe(observer)
      }
    }
    const clientsMap = webrtcProvider.awareness.getStates()
    setCollaboratorsState(clientsMap)
    listenForNewCollaborators(clientsMap)
  }

  useEffect(() => {
    if (collabRef.current && isCollaborationActive) {
      handleConnection()
    } else if (!isCollaborationActive && !isCollaborator) {
      navigate(`/${contractAddress}/member?chainId=${chainId}`, {
        replace: true,
      })
      sendNotifcation('This Session is not active', '', 'danger')
    }
  }, [collabRef.current])

  const [username, setUserName] = useLocalStorage('username', '')

  useEffect(() => {
    if (!username && walletAddress) {
      setUserName(walletAddress)
    }
  }, [walletAddress])
  const handleDisconnection = async () => {
    const collabApi = collabRef.current as any
    if (!collabApi) return
    const ymap = collabApi.yMap
    if (ymap.get(SESSION_HOST_FLAG) === walletAddress) {
      await updatePluginMetadata(
        { collaborationStatus: JSON.stringify({ isActive: false, by: null }) },
        getAuthenticatedPluginMetadataNode(rtcId as string, authKey)
      )
      ymap.set(SESSION_HOST_FLAG, null)
    }
    collabApi?.stopCollaboration(false)
    setIsCollaborationActive(false)
  }

  const listenOnPluginNodeForDisconnection = async () => {
    if (!isCollaborator) return
    const pluginNode = getAuthenticatedPluginMetadataNode(
      rtcId as string,
      authKey
    )
    if (!pluginNode) return
    let isSubscribed = true
    pluginNode.on(async (data: IDraft) => {
      if (!isSubscribed) return
      if (data && data.collaborationStatus) {
        const status = JSON.parse(data.collaborationStatus)
        if (!status.isActive) {
          handleDisconnection()
          setIsComponentVisible(true)
          sendNotifcation('Session ended', '', 'danger')
          isSubscribed = false
          await pluginNode.off()
        }
      }
    })
  }

  const registerUserAsSessionHost = async (collabApi: any) => {
    const ymap = collabApi?.yMap
    const draftMetadata = await getDraftMetadata(
      authKey as ISEAPair,
      contractAddress as string,
      rtcId as string
    )

    const collaborationStatus =
      draftMetadata?.collaborationStatus &&
      JSON.parse(draftMetadata?.collaborationStatus)

    if (!collaborationStatus?.isActive && isCollaborator) {
      await updatePluginMetadata(
        {
          collaborationStatus: JSON.stringify({
            isActive: true,
            by: walletAddress as string,
          }),
        },
        getAuthenticatedPluginMetadataNode(rtcId as string, authKey)
      )
      ymap.set(SESSION_HOST_FLAG, walletAddress)
    } else if (
      collaborationStatus?.isActive &&
      collaborationStatus.by === walletAddress
    ) {
      ymap.set(SESSION_HOST_FLAG, walletAddress)
    } else if (isCollaborator) {
      listenOnPluginNodeForDisconnection()
    }
  }

  const handleConnection = async () => {
    try {
      const collabApi: any = collabRef.current
      collabApi.setUsername(username)
      await collabApi?.activateCollaboration()
      if (collabApi?.webrtcProvider) {
        initalizeLiveCollaboratorsState()
        isCollaborator && registerUserAsSessionHost(collabApi)
        searchParams.set('collab', 'true')
        setSearchParams(searchParams)
      }
      setIsComponentVisible(false)
      setIsCollaborationActive(true)
    } catch (error) {
      console.log('FAILED TO ACTIVATE COLLABORATION', error)
    }
  }

  if (!username && !walletAddress) {
    return <ConnectToDocument onJoinDoc={() => null} />
  }

  return (
    <div className="h-screen w-screen bg-white flex">
      <div className="h-full w-full relative rounded-md">
        <div className="h-[68px] relative bg-[#ffffff]">
          {showDropDownMenu && (
            <WebPageMoreMenu
              confirmDeleteModal={confirmDeleteModal}
              setConfirmDeleteModal={setConfirmDeleteModal}
              indexEnabled={false}
              commentsEnabled={commentsEnabled}
              setCommentsEnabled={setCommentsEnabled}
              setIndexEnabled={() => null}
              fileDownloadName={draftName}
              filePreviewPage={false}
              downloadWhiteboard={openDownloadModal}
              isWhiteboard={true}
              moreMenuRef={dropdownRef}
              isOwner={isCollaborator}
              rtcId={rtcId}
            />
          )}
          <PluginNavbar
            isTitleFieldEnabled={!isPreviewMode}
            dropdownRef={dropdownRef}
            docStatus={savedStatus}
            onLiveCollaborationTrigger={triggerLiveCollaboration}
            pluginTool={isWhiteboardDisabled ? <></> : renderTool()}
            portalName={portalName}
            setPluginTitle={onTitleChange}
            pluginTitle={draftName}
            isPreviewMode={isPreviewMode}
            setPreviewMode={setPreviewMode}
            backButtonAction={handleBackButtonClick}
            isUserACollaborator={isCollaborator}
            isEditMode={false}
            setEditMode={() => null}
            setIsEditCancelFlag={() => null}
            isPublishLoading={false} // fix the name to be isLoading
            isCommentsEnabled={false}
            toggleCommentVisibility={() => null}
            toggleQrModalVisibility={() => null}
            isCommentsVisible={false}
            toggleMoreMenuVisibility={() =>
              setShowDropdownMenu(!showDropDownMenu)
            }
            toggleProvenaceModalVisibility={() => null}
            commentCount={0}
            publishPlugin={() => publishCanvas(excalidrawAPI)}
            isPublished={false}
            isCollaborating={isCollaborationActive || isWhiteboardDisabled}
            collaborators={collaborators}
          />
        </div>

        <div className="h-[92vh]">
          <ExcalidrawApp
            viewModeEnabled={isPreviewMode || isWhiteboardDisabled}
            toolbarRefs={toolbarRefs}
            excalidrawAppRef={excalidrawAppRef as any}
            freeHandToolRef={freeHandToolRef}
            liveCollaborationModalRef={liveCollaborationModalRef}
            onSceneLoaded={(api: ExcalidrawImperativeAPI) => {
              setExcalidrawAPI(api)
            }}
            collabRef={collabRef}
            gunInstance={gunInstance}
            seaInstance={seaInstance}
            onChangeEventCallback={() => {
              if (activeTool !== excalidrawAPI?.getAppState().activeTool.type) {
                setActiveTool(
                  excalidrawAPI?.getAppState().activeTool.type as string
                )
              }
            }}
          />
        </div>
      </div>
      {titlePopUp && (
        <AddPluginTitlePopUp
          contentUrl={projectPreview}
          publishContent={() => publishCanvas(excalidrawAPI)}
          pluginTitle={draftName}
          popupRef={titlePopUpDiv}
          isOpen={titlePopUp}
          setPluginTitle={setDraftName}
          setPopup={setTitlePopUp}
        />
      )}

      {isOpen && (
        <LiveCollaborationPopup
          isConnected={isCollaborationActive}
          popupRef={ref}
          connect={handleConnection}
          collaborators={collaborators}
          disconnect={handleDisconnection}
          isOpen={isOpen}
          closePopup={() => setIsComponentVisible(false)}
          isDocOwner={isOwner}
          docType="whiteboard"
        />
      )}
      <DeletePopUp
        isOpen={confirmDeleteModal}
        setIsOpen={setConfirmDeleteModal}
        rtcId={rtcId}
      />
    </div>
  )
}

export default memo(Canvas)

export const AddPluginTitlePopUp = ({
  popupRef,
  isOpen,
  pluginTitle,
  setPluginTitle,
  publishContent,
  contentUrl,
  setPopup,
}: {
  popupRef: React.RefObject<HTMLDivElement>
  isOpen: boolean
  pluginTitle: string
  setPluginTitle: (title: string) => void
  publishContent: () => void
  contentUrl: string
  setPopup: Dispatch<React.SetStateAction<boolean>>
}) => {
  return (
    <Popup isOpen={isOpen} width={'450px'}>
      <div ref={popupRef} className="bg-white rounded-lg p-4">
        <h6 className="font-semibold">Add name</h6>
        <input
          className="border w-full mt-2 rounded-lg p-2"
          type="text"
          placeholder="Untitled"
          value={pluginTitle}
          onChange={(e) => {
            setPluginTitle(e.target.value)
          }}
        />
        <div className="h-60 overflow-scroll no-scrollbar w-full mt-2 border">
          <img src={contentUrl} className="object-cover" />
        </div>
        <div className="flex justify-between w-full mt-4">
          <CTAButton
            onClick={() => {
              setPopup(false)
            }}
            title="Close"
          />
          <CTAButton
            isDisabled={!pluginTitle}
            onClick={publishContent}
            backgroundColorClassName={
              'bg-black text-white border-black border-2'
            }
            title="Next"
          />
        </div>
      </div>
    </Popup>
  )
}
