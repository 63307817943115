import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  DocumentTypeExtensions,
  ImageTypeExtensions,
  ThreeDTypeExtensions,
  AudioTypeExtensions,
  VideoTypeExtensions,
} from '../../constants'
import { useServerKeys } from '../../store/invoker/hooks'
import { FileSource, FileTypeEnum } from '../../types/enum/file.enum'
import {
  DataLockedKeyMaterial,
  IFileMetaData,
} from '../../types/interface/file.interface'
import {
  getPrivateFilePreview,
  getPublicFileUrl,
} from '../../utils/files/filePreviewHandlers'
import { getIPFSAsset } from '../../utils/getIPFSAsset'
import { verifyUserFileAccess } from '../../utils/files/encryption/utils'
import { NonDeletedExcalidrawElement } from '@fileverse/whiteboard/types/element/types'
import { exportToBlob } from '@fileverse/whiteboard'
import { BinaryFiles } from '@fileverse/whiteboard/types/types'
import { Document, Page } from 'react-pdf'
import {
  PenLine,
  File,
  Box,
  FileVideo,
  FileText,
  FileImage,
  PanelsTopLeft,
  Paperclip,
  FileMusic,
} from 'lucide-react'
import { usePrivyHelper } from '../../hooks/usePrivyHelper'
import Spinner from '../Spinner'

export const FilePreviewOnCard = ({
  // mimeType,
  contentIPFSHash,
  extension,
  fileMetadata,
  isMember,
}: {
  mimeType: string
  contentIPFSHash: string
  extension: string
  fileMetadata: IFileMetaData
  isMember?: boolean
}) => {
  const [imagePreview, setImagePreview] = useState<string>()
  const [pdfPreview, setPdfPreview] = useState<string>()
  const { address: contractAddress } = useParams()
  const walletAddress = usePrivyHelper().walletAddress
  const invokerAddress = walletAddress as string
  const serverKeys = useServerKeys(invokerAddress, contractAddress as string)
  const userAccess = verifyUserFileAccess(fileMetadata, serverKeys, isMember)

  const isPublic = fileMetadata.fileType == FileTypeEnum.PUBLIC

  const getImage = async (contentIPFSHash: string) => {
    if (isPublic) {
      const image = await getIPFSAsset({
        ipfsHash: contentIPFSHash,
        options: { responseType: 'blob' },
      })
      const imageURL = URL.createObjectURL(image?.data)
      setImagePreview(imageURL)
    } else if (userAccess) {
      try {
        const unlockedFileData = await getPrivateFilePreview(
          fileMetadata.portalLock as DataLockedKeyMaterial,
          contentIPFSHash,
          serverKeys
        )

        setImagePreview(unlockedFileData?.decryptedFileUrl)
      } catch (err) {
        setImagePreview('')
      }
    } else {
      setImagePreview('')
    }
  }

  useEffect(() => {
    if (fileMetadata.source === FileSource.EXCALIDRAW) {
      ;(async () => {
        try {
          const result = await getPublicFileUrl(contentIPFSHash)
          const fileData = await (await fetch(result.downloadUrl)).text()
          const file = JSON.parse(fileData)
          if (file.file.appState) {
            const data = await exportToBlob({
              elements: file.file.elements as NonDeletedExcalidrawElement[],
              appState: file.file.appState,
              files: file.file.files as BinaryFiles,
            })
            const url = URL.createObjectURL(data)
            setImagePreview(url)
          }
        } catch (error) {
          console.log(error)
        }
      })()
    }
  }, [])

  useEffect(() => {
    if (fileMetadata.extension.includes('pdf')) {
      ;(async () => {
        const result = await getPublicFileUrl(contentIPFSHash)
        setPdfPreview(result.downloadUrl)
      })()
    }
  }, [])

  useEffect(() => {
    ImageTypeExtensions.includes(extension) && getImage(contentIPFSHash)
  }, [])

  useEffect(() => {
    fileMetadata.source === FileSource.DPAGE &&
      getImage(fileMetadata.coverIPFSHash as string)
  }, [])

  if (AudioTypeExtensions.includes(extension))
    return (
      <div className={`m-auto text-[#A1AAB1]`}>
        <FileMusic size={64} />
      </div>
    )

  if (VideoTypeExtensions.includes(extension)) {
    return (
      <div className={`m-auto text-[#A1AAB1]`}>
        <FileVideo size={64} />
      </div>
    )
  }

  if (fileMetadata.extension.includes('pdf') && isPublic) {
    return (
      <Document
        loading={
          <div className="flex justify-center items-center w-full h-full">
            <Spinner />
          </div>
        }
        noData={
          <div className="flex justify-center items-center w-full h-full text-[#A1AAB1]">
            <Paperclip size={64} />
          </div>
        }
        file={pdfPreview}
      >
        <div className="h-full py-3 w-full max-w-[200px]">
          <Page
            width={200}
            key={`page_1`}
            pageNumber={1}
            renderAnnotationLayer={true}
          />
        </div>
      </Document>
    )
  }
  if (fileMetadata.source === FileSource.EXCALIDRAW) {
    return (
      <div className="h-full flex items-center justify-center w-full">
        {imagePreview ? (
          <img
            src={imagePreview}
            alt={`${fileMetadata.name}`}
            className={`w-full h-full object-cover`}
          />
        ) : (
          <div className="m-auto text-[#A1AAB1]">
            <PenLine size={64} />
          </div>
        )}
      </div>
    )
  }
  if (ImageTypeExtensions.includes(extension)) {
    return (
      <div className="h-full flex items-center justify-center w-full">
        {imagePreview ? (
          <img
            src={imagePreview}
            alt={`${fileMetadata.name}`}
            className={`w-full h-full object-cover`}
          />
        ) : (
          <div className="m-auto text-[#A1AAB1]">
            <FileImage size={64} />
          </div>
        )}
      </div>
    )
  }
  if (DocumentTypeExtensions.includes(extension)) {
    return (
      <div className="h-full flex items-center justify-center w-full">
        {imagePreview ? (
          <img
            src={imagePreview}
            alt={`${fileMetadata.name}`}
            className={`w-full h-full object-cover`}
          />
        ) : (
          <div className="m-auto text-[#A1AAB1]">
            <Paperclip size={64} />
          </div>
        )}
      </div>
    )
  }
  if (ThreeDTypeExtensions.includes(extension)) {
    return (
      <div className="m-auto text-[#A1AAB1]">
        <Box size={64} />
      </div>
    )
  }
  if (fileMetadata.source === FileSource.COLLAB_DOCUMENT) {
    return (
      <div className="m-auto text-[#A1AAB1] ">
        <FileText size={64} />
      </div>
    )
  }
  if (fileMetadata.source === FileSource.DPAGE) {
    return (
      <div className="h-full flex items-center justify-center w-full">
        {imagePreview ? (
          <img
            src={imagePreview}
            alt={`${fileMetadata.name}`}
            className={`w-full h-full object-cover`}
          />
        ) : (
          <div className="m-auto text-[#A1AAB1]">
            <PanelsTopLeft size={64} />
          </div>
        )}
      </div>
    )
  }

  return (
    <div className="m-auto text-[#A1AAB1]">
      <File size={64} />
    </div>
  )
}
