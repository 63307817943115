/* eslint-disable @typescript-eslint/no-explicit-any */
import { FileSource, FileTypeEnum } from '../../types/enum/file.enum'

import { IServerKey } from '../../types/interface/invoker.interface'
import { TokenGateHashInfo } from '../../types/interface/token.interface'
import { createLock } from '../files/encryption/createLock'
import encryptFile from '../files/encryption/encryptFile'
import { instantiateSEA } from '../instantiateGun'
import { MakeFileFromObject } from '../makeFileFromObject'
import { FileMetadata, PluginFileMetadata } from './metadataClass'
const SEA = instantiateSEA()

interface IInitializerOptions {
  file: File
  owner: string
  fileArgs: any
}

const initializeFileMetadataBySource = (
  options: IInitializerOptions,
  fileType: FileTypeEnum
) => {
  const { file, owner, fileArgs = <any>{} } = options
  const fileSource = fileArgs.source || FileSource.USER_FILE_SYSTEM

  switch (fileSource) {
    case FileSource.DPAGE:
    case FileSource.COLLAB_DOCUMENT:
    case FileSource.EXCALIDRAW:
    case FileSource.NOTES:
      return new PluginFileMetadata(file, owner, fileSource, fileType, fileArgs)
    default:
      return new FileMetadata(file, owner, fileSource, fileType, fileArgs)
  }
}

export class FileMetadataFactory {
  static async getPublicFileMetadata(
    file: File,
    owner: string,
    fileArgs = <any>{}
  ) {
    const fileChatKeyPair = await SEA.pair()
    const fileMetadata = initializeFileMetadataBySource(
      { file, owner, fileArgs },
      FileTypeEnum.PUBLIC
    )
    fileMetadata.publicLock = {
      fileKey: null,
      chatKey: fileChatKeyPair,
    }
    return {
      contentFile: file,
      metadata: JSON.parse(JSON.stringify(fileMetadata)),
      metadataFile: MakeFileFromObject(
        fileMetadata,
        `${file.name}.metadata.json`
      ),
      fileTags: fileMetadata.tag,
    }
  }

  static async getCollaboratorFileMetadata(
    file: File,
    owner: string,
    serverKeys: IServerKey,
    fileArgs = <any>{}
  ) {
    const fileChatKeyPair = await SEA.pair()
    const { decryptionObject: fileKey, encryptedFile } = await encryptFile(file)
    const dataKeyMaterial = {
      fileKey,
      fileChatKeyPair,
    }
    const portalLock = await createLock(
      serverKeys.portalEncryptionKey,
      dataKeyMaterial
    )

    const ownerLock = await createLock(
      serverKeys.ownerEncryptionKey,
      dataKeyMaterial
    )

    const fileMetadata = initializeFileMetadataBySource(
      { file, owner, fileArgs },
      FileTypeEnum.PRIVATE
    )
    fileMetadata.portalLock = portalLock
    fileMetadata.ownerLock = ownerLock

    return {
      contentFile: encryptedFile,
      metadataFile: MakeFileFromObject(
        fileMetadata,
        `${file.name}.metadata.json`
      ),
      metadata: JSON.parse(JSON.stringify(fileMetadata)),
      fileTags: fileMetadata.tag,
    }
  }

  static async getMemberFileMetadata(
    file: File,
    owner: string,
    serverKeys: IServerKey,
    fileArgs = <any>{}
  ) {
    const fileChatKeyPair = await SEA.pair()

    const { decryptionObject: fileKey, encryptedFile } = await encryptFile(file)
    const dataKeyMaterial = {
      fileKey,
      fileChatKeyPair,
    }

    const memberLock = await createLock(
      serverKeys.memberEncryptionKey,
      dataKeyMaterial
    )
    const portalLock = await createLock(
      serverKeys.portalEncryptionKey,
      dataKeyMaterial
    )
    const ownerLock = await createLock(
      serverKeys.ownerEncryptionKey,
      dataKeyMaterial
    )

    const fileMetadata = initializeFileMetadataBySource(
      { file, owner, fileArgs },
      FileTypeEnum.MEMBERS_PRIVATE
    )

    fileMetadata.memberLock = memberLock
    fileMetadata.portalLock = portalLock
    fileMetadata.ownerLock = ownerLock

    return {
      contentFile: encryptedFile,
      metadataFile: MakeFileFromObject(
        fileMetadata,
        `${file.name}.metadata.json`
      ),
      metadata: JSON.parse(JSON.stringify(fileMetadata)),
      fileTags: fileMetadata.tag,
    }
  }

  static async getGatedFileMetadata(
    file: File,
    owner: string,
    serverKeys: IServerKey,
    gateKey: string,
    fileArgs = <any>{}
  ) {
    const fileChatKeyPair = await SEA.pair()

    const { decryptionObject: fileKey, encryptedFile } = await encryptFile(file)
    const dataKeyMaterial = {
      fileKey,
      fileChatKeyPair,
    }
    const portalLock = await createLock(
      serverKeys.portalEncryptionKey,
      dataKeyMaterial
    )
    const ownerLock = await createLock(
      serverKeys.ownerEncryptionKey,
      dataKeyMaterial
    )
    const tokenLock = await createLock(
      gateKey,
      dataKeyMaterial,
      FileTypeEnum.GATED
    )

    const fileMetadata = initializeFileMetadataBySource(
      { file, owner, fileArgs },
      FileTypeEnum.GATED
    )

    fileMetadata.portalLock = portalLock
    fileMetadata.ownerLock = ownerLock
    fileMetadata.tokenLock = tokenLock

    return {
      contentFile: encryptedFile,
      metadataFile: MakeFileFromObject(
        fileMetadata,
        `${file.name}.metadata.json`
      ),
      metadata: fileMetadata,
      fileTags: fileMetadata.tag,
    }
  }

  static getGateFile(gateFile: TokenGateHashInfo) {
    return MakeFileFromObject(gateFile, 'gate.json')
  }
}
