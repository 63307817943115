/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import styles from './like.module.scss'
import cn from 'classnames'
import { SiweMessage } from 'siwe'
import { useSignMessage } from 'wagmi'
import { Tooltip } from '@mui/material'
import Lottie from 'lottie-react'
import confettie from '../../../assets/Webpages/confettie.json'
import { mintHeart } from '../../../api/heartApi/mintHeart'
import { getBlockNumber, getHashedString } from '../../../utils/ethUtils'

import { getReadOnlyHeartMinterContract } from '../../../utils/contract'
import Spinner from '../../Spinner'
import noop from 'lodash/noop'
import sendNotifcation from '../../../utils/notification'
import { useEthersSigner } from '../../../hooks/clientToProvider'
import { defaultNetwork } from '../../../config/network-config'
import { usePrivy } from '@privy-io/react-auth'
import { usePrivyHelper } from '../../../hooks/usePrivyHelper'

export const Like = ({
  mobileView,
  disabled,
}: {
  mobileView: boolean
  disabled?: boolean
}) => {
  const [isAnimating, setIsAnimating] = useState(false)
  const [count, setCount] = useState(0)
  const [intervalID, setIntervalID] = useState<NodeJS.Timeout>()
  const [isSigning, setIsSigning] = useState<boolean>(false)
  const walletAddress = usePrivyHelper().walletAddress as string
  const { signMessageAsync } = useSignMessage()
  const chainId = defaultNetwork.chainId
  const signer = useEthersSigner({ chainId })
  const [startBlock, setStartBlock] = useState<number>(0)
  const [hasMinted, setHasMinted] = useState<boolean>(false)
  const [totalMint, setTotalMint] = useState<number>(0)
  const [currentBalance, setCurrentBalance] = useState<number>(0)
  const [currentMintCount, setCurrentMintCount] = useState<number>(0)
  const [fetchingTotalMints, setFetchingTotalMints] = useState<boolean>(true)
  const [minted, setMinted] = useState<boolean>(hasMinted || isSigning)
  const { connectOrCreateWallet } = usePrivy()

  const getUserBalance = async () => {
    const hashedURL = getHashedString(window.location.href)

    const heartMinterContract = getReadOnlyHeartMinterContract()
    const tokenId = await heartMinterContract.urlTokenMap(hashedURL)
    const totalSupply = await heartMinterContract.totalSupply(tokenId)
    setTotalMint(Number(totalSupply))

    const userCurrentBalance = await heartMinterContract.balanceOf(
      walletAddress,
      tokenId
    )
    const currentBalanceNum = Number(userCurrentBalance)
    setCurrentBalance(currentBalanceNum)
    setFetchingTotalMints(false)
  }

  const getTotalSupply = async () => {
    const hashedURL = getHashedString(window.location.href)

    const heartMinterContract = getReadOnlyHeartMinterContract()
    const tokenId = await heartMinterContract.urlTokenMap(hashedURL)
    const totalSupply = await heartMinterContract.totalSupply(tokenId)
    setCurrentMintCount(0)
    setTotalMint(Number(totalSupply))
    if (!walletAddress) setFetchingTotalMints(false)
  }

  const getMintCount = () => {
    if (isSigning) return `${currentMintCount}%`
    if (count > 0) return `${count}%`
    if (hasMinted && currentMintCount > 0) return `${currentMintCount}%`
    if (hasMinted) return formatMint(totalMint)
    return formatMint(totalMint)
  }

  const signRequest = async () => {
    if (!signer) return
    try {
      const messageObject = new SiweMessage({
        domain: window.location.host,
        address: walletAddress,
        statement: `You just supported this author with your time onchain and collected their dPage! Thanks <3`,
        uri: window.location.origin,
        version: '1',
      })
      const signature = await signMessageAsync({
        message: messageObject.prepareMessage(),
      })
      const message = messageObject.prepareMessage()
      await mintHeart({
        message,
        signature,
        signer,
        // Need to fix this logic
        startBlock: startBlock > 0 ? startBlock : await getBlockNumber(),
        urlString: getHashedString(window.location.href),
        rawUrl: window.location.href,
      })
      setHasMinted(true)
      setIsSigning(false)
      sendNotifcation(
        'Minted this dPage',
        'You just supported this author with your time onchain and collected their dPage! Thanks <3',
        'success'
      )
    } catch (error: any) {
      console.log(error)
      setIsSigning(false)
      setIsAnimating(false)
      sendNotifcation(
        'An error occurred while minting',
        error.message,
        'danger'
      )
    }
  }

  const handleMouseDown = () => {
    if (disabled) return
    if (!walletAddress) {
      connectOrCreateWallet()
      return
    }
    setIsAnimating(true)
    const incrementInterval = (6 * 1000) / 100
    let currentCount = count
    const increment = () => {
      if (currentCount < 100 && !isSigning) {
        setCount(currentCount + 1)
        currentCount += 1
      } else if (currentCount === 100) {
        setCurrentMintCount(currentCount)
        setIsSigning(true)
        clearInterval(intervalId)
        setTimeout(() => {
          signRequest()
          currentCount = 0
          setCount(0)
        }, 1750)
      }
    }
    const intervalId = setInterval(increment, incrementInterval)
    setIntervalID(intervalId)
  }

  const handleMouseLeave = () => {
    if (disabled) return
    if (!isSigning) {
      setIsAnimating(false)
      setIsSigning(true)
      setCurrentMintCount(count)
      signRequest()
      clearInterval(intervalID)
      setCount(0)
    }
  }

  const formatMint = (input: number): string => {
    if (input < 1000) return input.toString()
    if (input < 10000)
      return input.toString().slice(0, 1) + ',' + input.toString().slice(1)
    if (input < 1000000) {
      if (input % 1000 === 0) return input / 1000 + 'K'
      return (input / 1000).toFixed(1) + 'K'
    }
    if (input % 1000000 === 0) return input / 1000000 + 'M'
    return (input / 1000000).toFixed(1) + 'M'
  }

  const getHeartFill = (percentage: number): string => {
    const range = Math.floor(percentage / 10)
    const fills = [
      'ten',
      'twenty',
      'thirty',
      'forty',
      'fifty',
      'sixty',
      'seventy',
      'eighty',
      'ninety',
      'hundred',
      'hundred',
    ]
    return fills[range] || 'ten'
  }

  useEffect(() => {
    if (count === 1) getBlockNumber().then((block) => setStartBlock(block))
  }, [count])

  useEffect(() => {
    if (signer && walletAddress) {
      getUserBalance()
    }
  }, [signer, walletAddress])

  useEffect(() => {
    getTotalSupply()
  }, [])

  useEffect(() => {
    setTimeout(() => {
      if (hasMinted) {
        getTotalSupply()
      }
    }, 10000)
  }, [hasMinted])

  useEffect(() => {
    setHasMinted(currentBalance > 0)
  }, [currentBalance])

  useEffect(() => {
    setMinted(hasMinted || isSigning)
  }, [hasMinted, isSigning])

  if (fetchingTotalMints) return <Spinner width={4} height={4} />
  return (
    <div className={cn({ '-ml-5': mobileView }, 'flex items-center pr-2')}>
      <Tooltip
        arrow
        classes={{ tooltip: '-mt-3 bg-black text-white', arrow: 'text-black' }}
        placement="bottom"
        title={
          minted
            ? `Thanks for sending onchain love! 💛`
            : 'Keep holding to send onchain love 💛'
        }
      >
        <div
          className={cn(
            {
              'self-center w-fit': mobileView,
            },
            'relative z-0'
          )}
          onMouseUp={!minted ? handleMouseLeave : noop}
          onMouseDown={!minted ? handleMouseDown : noop}
          onTouchStart={!minted ? handleMouseDown : noop}
          onTouchEnd={!minted ? handleMouseLeave : noop}
        >
          <div className="absolute z-10 scale-150">
            {count === 100 && <Lottie loop={false} animationData={confettie} />}
          </div>
          <div className={styles.frame}>
            <div
              className={cn(
                styles.heart,
                !minted ? (isAnimating ? styles.animate : styles.beat) : '',
                hasMinted &&
                  (currentBalance > 60 ? styles.hundred : styles.fifty),
                minted && styles[getHeartFill(currentMintCount)]
              )}
            />
          </div>
        </div>
      </Tooltip>
      <span
        className={cn({
          '-ml-5': mobileView,
          '-ml-3': !mobileView,
        })}
      >
        {!disabled && getMintCount()}
      </span>
    </div>
  )
}
