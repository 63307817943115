/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Avatar,
  ButtonGroup,
  cn,
  IconButton,
  LucideIcon,
  Toggle,
} from '@fileverse/ui'
import copyToClipboard from 'copy-to-clipboard'
import React, {
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import sendNotifcation, {
  clearAllNotification,
} from '../../../utils/notification'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { usePublicPortalContext } from '../../../providers/PublicPortalProvider'
import { WebPageCommentSection } from '../../../components/WebPages/WebPageCommentSection'
import {
  IFileChatKeyPair,
  INewFile,
} from '../../../types/interface/file.interface'
import { getContractFile } from '../../../utils/contract'
import { unsafe_getIdbContractByAddress } from '../../../store/middleware/utils'
import {
  useContractChainId,
  usePortalMetadata,
  usePublicLayoutFileId,
} from '../../../store/contract/hooks'
// import { cloneDeep, isEqual } from 'lodash'
import { Button } from './Button'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from './Dialog'
import { DialogClose } from '@radix-ui/react-dialog'
import { AnimatePresence, motion } from 'framer-motion'
import { useMediaQuery, useOnClickOutside } from 'usehooks-ts'
import { useInvokerStatus } from '../../../store/invoker/hooks'
import { InvokerStatus } from '../../../types'
import { IContractMetadata } from '../../../types/interface/contract.interface'
import {
  getPublicPortalLayoutFileIdOnChain,
  updatePortalMetadata,
} from '../../../utils/publicPortalUtils'
import {
  addCollaboratorToStore,
  updateContractMetadataState,
  updatePortalMetadataIPFSUrl,
} from '../../../store/contract/reducer'
import { useAppDispatch } from '../../../store/hooks'
import farcasterLogo from '../assets/farcasterlogo.png'
import heyLogo from '../../../assets/hey.svg'
import { Tooltip } from './Tooltip'
import { FRAME_URL } from '../../../config'
import { useMediaMax1025px } from '../../../hooks/useMediaQueryHook'
import {
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerClose,
} from './Drawer'
import { usePrivyHelper } from '../../../hooks/usePrivyHelper'
import { DynamicModal } from './DynamicModal'
import { generateKeyPairs, generateServerKeys } from '../../../utils/keys'
import {
  registerCollaboratorTrx,
  removeCollaboratorKeysTrx,
} from '../../../utils/transaction'
import {
  parseTrxEventLogs,
  waitForTransactionReceipt,
} from '../../../utils/transaction/trxUtils'
import {
  ContractType,
  PortalContractEventEnum,
} from '../../../utils/transaction/interface'
import { createMessageAPI } from '../../../api/message/messageAPI'
import { MessageTopicEnum } from '../../../types/enum/message.enum'
import { addKeys } from '../../../store/keySharing/reducer'
import { addInvokerCredentialsThunk } from '../../../store/invoker/thunks'
import {
  SwitchNetworkRejected,
  RelayerError,
  ResolveEventError,
} from '../../../utils/errors'
import { useRegisterCollaborator } from '../../../hooks/useRegisterCollaborator'
import cuteLogo from '../assets/logo-cute.svg'

export const shareAnimationConfig = {
  initial: { opacity: 0, y: -20 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -20 },
  transition: {
    type: 'spring',
    damping: 30,
    stiffness: 300,
    duration: 0.2,
  },
}

enum COLLABORATE_STATE {
  IDLE = 'IDLE',
  WALLET_NOT_CONNECTED = 'WALLET_NOT_CONNECTED',
  NOT_A_COLLABORATOR = 'NOT_A_COLLABORATOR',
  IS_A_COLLABORATOR = 'IS_A_COLLABORATOR',
}

export default function PublicPortalNavbar() {
  const navigate = useNavigate()
  const { address: contractAddress } = useParams()
  const [showSharePopup, setShowSharePopup] = useState<boolean>(false)
  const [searchParams] = useSearchParams()
  const chainId = parseInt(searchParams.get('chainId') || '')
  const filePath = `/${contractAddress}?chainId=${chainId}`
  const [showComment, setShowComment] = useState(false)
  const [portalFileData, setPortalData] = useState<INewFile | undefined>()
  const shareRef = useRef<HTMLDivElement>(null)
  const publicLayoutFileId = usePublicLayoutFileId(
    (contractAddress as string) || ''
  )
  const isMediaMax1025px = useMediaMax1025px()

  useOnClickOutside(shareRef, () => setShowSharePopup(false))

  const handleShareClick = (event: any) => {
    if (shareRef.current && !shareRef.current.contains(event.target)) {
      setShowSharePopup(false)
    } else {
      setShowSharePopup(!showSharePopup)
    }
  }

  const {
    name,
    isViewMode,
    layoutSaved,
    avatarIPFSUrl,
    setShowConfirmModal,
    commentsEnabled,
    setCommentsMetadata,
  } = usePublicPortalContext()

  const fetchPortalFileData = useCallback(async () => {
    let id = publicLayoutFileId
    if (!id) {
      const contract = await unsafe_getIdbContractByAddress(
        contractAddress as string
      )
      if (!contract || !contract?.publicLayoutFileId) {
        id = await getPublicPortalLayoutFileIdOnChain(contractAddress as string)
      } else {
        id = contract?.publicLayoutFileId
      }
    }
    if (id) {
      const publicLayoutFileData = await getContractFile(
        Number(id),
        contractAddress as string
      )
      setPortalData(publicLayoutFileData)
    }
  }, [publicLayoutFileId, contractAddress])

  const onBackNavigate = () => {
    // check if the sections are changed
    if (!layoutSaved) {
      setShowConfirmModal(true)
    } else {
      navigate(filePath)
    }
  }

  useEffect(() => {
    fetchPortalFileData()
  }, [publicLayoutFileId])

  return (
    <nav className="h-[46px] w-full flex justify-between items-center px-4 lg:px-8 py-2">
      <div className="left-section flex justify-center items-center gap-3 cursor-pointer text-body-sm-bold">
        {!isViewMode ? (
          <div onClick={onBackNavigate}>
            <ButtonGroup className="flex gap-2 items-center justify-center hover:color-button-secondary-hover py-1 px-2 rounded">
              <LucideIcon name="ChevronLeft" size="sm" fill="none" />
              Back
            </ButtonGroup>
          </div>
        ) : (
          <div
            onClick={() => navigate('/contracts')}
            className="flex gap-2 justify-center items-center"
          >
            <Avatar size={'md'} src={avatarIPFSUrl} />
            <p className="text-body-sm-bold">{name}</p>
          </div>
        )}
      </div>
      <div className="right-section text-body-sm-bold">
        <ButtonGroup className="space-x-0 justify-center items-center">
          <EnablePublicPortalToggler />
          <Collaborate />
          <div className="relative cursor-pointer">
            <div
              className="flex items-center justify-center hover:color-button-secondary-hover lg:px-2 rounded"
              onClick={handleShareClick}
            >
              <IconButton
                icon="Share2"
                variant="ghost"
                size={isMediaMax1025px ? 'md' : 'sm'}
              />
              <p className="hidden lg:block">Share</p>
            </div>

            <AnimatePresence>
              {showSharePopup && (
                <motion.div
                  ref={shareRef}
                  key="shareDropDown"
                  {...shareAnimationConfig}
                  className="absolute z-10 top-10 -right-3 "
                >
                  <ShareDropDown filePath={window.location.href} />
                </motion.div>
              )}
            </AnimatePresence>
          </div>
          <div
            onClick={() => setShowComment(!showComment)}
            className={cn(
              'flex items-center justify-center cursor-pointer hover:color-button-secondary-hover lg:px-2 rounded',
              !commentsEnabled && 'cursor-not-allowed color-text-disabled'
            )}
          >
            <IconButton
              icon="Comment"
              variant="ghost"
              size={isMediaMax1025px ? 'md' : 'sm'}
              className={cn('', {
                'hover:text-muted cursor-not-allowed': !commentsEnabled,
              })}
            />
            <p className="hidden lg:block"> Comment</p>
          </div>
        </ButtonGroup>
        <WebPageCommentSection
          show={commentsEnabled && showComment}
          setShow={setShowComment}
          chatKey={
            portalFileData?.metadata.publicLock?.chatKey as IFileChatKeyPair
          }
          fileData={portalFileData as INewFile}
          setCommentMetadata={setCommentsMetadata}
          isViewMode={isViewMode}
        />
      </div>
    </nav>
  )
}

const Collaborate = () => {
  const contractAddress = useParams().address as string
  const { walletAddress } = usePrivyHelper()
  const invokerStatus = useInvokerStatus(
    walletAddress as string,
    contractAddress
  )
  const {
    shouldRegister: showRegisterPopup,
    isRegistrationCompleted,
    retryCollaborationFlow,
  } = useRegisterCollaborator(
    contractAddress as string,
    walletAddress as string
  )
  const isMediaMax1025px = useMediaMax1025px()

  const [showVerifyPopup, setShowVerifyPopup] = useState<boolean>(false)

  if (
    invokerStatus === InvokerStatus.Owner ||
    invokerStatus === InvokerStatus.Collaborator
  )
    return

  return (
    <>
      <div
        className="flex gap-1 justify-center items-center cursor-pointer hover:color-button-secondary-hover rounded px-[10px] py-[6px]"
        onClick={() => setShowVerifyPopup(true)}
      >
        <LucideIcon
          name="UserRoundPlus"
          fill="None"
          stroke="Black"
          size={isMediaMax1025px ? 'md' : 'sm'}
        />
        {!isMediaMax1025px && <p> Collaborate</p>}
      </div>
      {showVerifyPopup && (
        <VerifyCollaborator
          showRegisterPopup={showRegisterPopup}
          isRegistrationCompleted={isRegistrationCompleted}
          setShowPopup={setShowVerifyPopup}
          retryCollaborationFlow={retryCollaborationFlow}
        />
      )}
    </>
  )
}

const VerifyCollaborator = ({
  setShowPopup,
  isRegistrationCompleted,
  showRegisterPopup,
  retryCollaborationFlow,
}: {
  setShowPopup: React.Dispatch<SetStateAction<boolean>>
  isRegistrationCompleted: boolean
  showRegisterPopup: boolean
  retryCollaborationFlow: boolean
}) => {
  const { connectInjectedWallet, walletAddress } = usePrivyHelper()
  const navigate = useNavigate()

  const [collaborateState, setCollaborateState] = useState<COLLABORATE_STATE>(
    COLLABORATE_STATE.IDLE
  )
  const [showVerifyPopup, setShowVerifyPopup] = useState<boolean>(true)

  const handleVerifyCollaborator = () => {
    if (!walletAddress) {
      setShowVerifyPopup(true)
      setCollaborateState(COLLABORATE_STATE.WALLET_NOT_CONNECTED)
      return
    }
    if (showRegisterPopup || isRegistrationCompleted) {
      setCollaborateState(COLLABORATE_STATE.IS_A_COLLABORATOR)
      return
    }
    setCollaborateState(COLLABORATE_STATE.NOT_A_COLLABORATOR)
    setShowVerifyPopup(true)
  }

  useEffect(() => {
    handleVerifyCollaborator()
  }, [walletAddress, showRegisterPopup])

  useEffect(() => {
    if (!showVerifyPopup) setShowPopup(false)
  }, [showVerifyPopup])

  return (
    <>
      {collaborateState === COLLABORATE_STATE.WALLET_NOT_CONNECTED && (
        <DynamicModal
          className="max-w-[525px]"
          open={showVerifyPopup}
          onOpenChange={setShowVerifyPopup}
          title={'Verify if you have access'}
          content={
            <div className="w-full flex flex-col justify-center items-center gap-6 px-6">
              <p className="text-body-md-bold text-center">
                You&apos;re just one step away from full access to this
                Fileverse Portal.
              </p>
              <div className="w-full flex flex-col justify-center items-center gap-6">
                <div className="w-full flex gap-3 items-center">
                  <div className="min-w-[44px] h-[44px] bg-yellow flex items-center justify-center rounded">
                    <LucideIcon
                      name="VenetianMask"
                      fill="None"
                      stroke="Black"
                    />
                  </div>
                  <p className="text-body-sm">
                    Access the private side of this Portal to live collaborate
                    with other collaborators.
                  </p>
                </div>
                <div className="w-full flex gap-3 items-center">
                  <div className="min-w-[44px] h-[44px] bg-yellow flex items-center justify-center rounded">
                    <LucideIcon name="Wand" fill="None" stroke="Black" />
                  </div>
                  <p className="text-body-sm">
                    Collaborators can create decentralised Docs, Whiteboards,
                    Onchain web pages, and much more!.
                  </p>
                </div>
                <div className="w-full flex gap-3 items-center">
                  <div className="min-w-[44px] h-[44px] bg-yellow flex items-center justify-center rounded">
                    <LucideIcon name="Gift" fill="None" stroke="Black" />
                  </div>
                  <p className="text-body-sm">
                    Collaborators get access to the Private Beta and can create
                    their own Fileverse Portal &lt;3
                  </p>
                </div>
              </div>
              <div className="flex gap-4">
                <Button
                  variant={'ghost'}
                  onClick={() => setShowVerifyPopup(false)}
                >
                  Cancel
                </Button>
                <Button onClick={connectInjectedWallet}>Connect Wallet</Button>
              </div>
            </div>
          }
        />
      )}
      {collaborateState === COLLABORATE_STATE.NOT_A_COLLABORATOR && (
        <DynamicModal
          className="max-w-[315px]"
          open={showVerifyPopup}
          onOpenChange={setShowVerifyPopup}
          title={'Oh no!'}
          content={
            <div className="flex flex-col justify-center items-center text-center gap-6">
              <img
                src={cuteLogo}
                alt="fileverse"
                className="w-[75px] h-auto object-scale-down"
              />
              <p>
                Sorry, you’re not a Collaborator on this Fileverse Portal.
                Explore this Portal or{' '}
                <span>
                  <a
                    href="https://twitter.com/intent/tweet?text=Hello%20%40fileverse%2C%20I%27m%20looking%20to%20get%20access%20to%20your%20private%20beta.%20Let%20me%20in!&url="
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline"
                  >
                    reach out to us
                  </a>
                </span>{' '}
                for access.
              </p>
            </div>
          }
          primaryAction={{
            label: 'Back to Portal',
            onClick: () => navigate('/contracts'),
          }}
          secondaryAction={{
            label: 'Cancel',
            onClick: () => setShowVerifyPopup(false),
          }}
        />
      )}
      {collaborateState === COLLABORATE_STATE.IS_A_COLLABORATOR && (
        <RegisterAsCollaborator
          setShowPopup={setShowPopup}
          retryCollaborationFlow={retryCollaborationFlow}
          isRegistrationCompleted={isRegistrationCompleted}
        />
      )}
    </>
  )
}

const RegisterAsCollaborator = ({
  setShowPopup,
  isRegistrationCompleted,
  retryCollaborationFlow,
}: {
  setShowPopup: React.Dispatch<SetStateAction<boolean>>
  isRegistrationCompleted: boolean
  retryCollaborationFlow: boolean
}) => {
  const [showRegisterPopup, setShowRegisterPopup] = useState<boolean>(true)

  const [registering, setRegistering] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const { address: contractAddress } = useParams()

  const walletAddress = usePrivyHelper().walletAddress
  const chainId = useContractChainId(contractAddress as string)
  const navigate = useNavigate()

  const removePreviousCredential = async () => {
    const hash = await removeCollaboratorKeysTrx(
      contractAddress as string,
      walletAddress as string
    )
    const { logs: removeLog } = await waitForTransactionReceipt(hash)
    parseTrxEventLogs(
      removeLog,
      ContractType.Portal,
      PortalContractEventEnum.REMOVED_COLLABORATOR_KEYS
    )
    // temp fix: wait 5sec to avoid nonce mismatch error
    await new Promise((resolve) => {
      setTimeout(() => resolve(null), 5000)
    })
    return
  }

  const handleRegistration = async () => {
    if (isRegistrationCompleted) {
      navigate(`/${contractAddress}/settings?chainId=${chainId}`)
      return
    }
    setRegistering(true)
    if (retryCollaborationFlow) {
      await removePreviousCredential()
    }
    try {
      const collaboratorKeys = await generateKeyPairs()

      const trxHash = await registerCollaboratorTrx(
        contractAddress as string,
        walletAddress as string,
        {
          viewDID: collaboratorKeys.viewDID,
          editDID: collaboratorKeys.editDID,
        }
      )
      const { logs } = await waitForTransactionReceipt(trxHash)

      parseTrxEventLogs(
        logs,
        ContractType.Portal,
        PortalContractEventEnum.REGISTERED_COLLABORATOR_KEYS
      )

      const { encryptionKey, decryptionKey, token } = await generateServerKeys(
        collaboratorKeys.viewSecret
      )

      await createMessageAPI({
        contractAddress: contractAddress as string,
        invokerAddress: walletAddress as string,
        content: token,
        topic: MessageTopicEnum.KEY_REQUEST,
        forAddress: '*',
        chain: chainId as number,
        credential: {
          ...collaboratorKeys,
          contractAddress: contractAddress as string,
        },
      })

      dispatch(
        addCollaboratorToStore({
          contractAddress: contractAddress as string,
          collaboratorAddress: walletAddress as string,
        })
      )
      dispatch(
        addKeys({
          contractAddress: contractAddress as string,
          privateKey: decryptionKey,
          publicKey: encryptionKey,
        })
      )
      await dispatch(
        addInvokerCredentialsThunk({
          credential: {
            ...collaboratorKeys,
            contractAddress: contractAddress as string,
          },
          invokerAddress: walletAddress as string,
        })
      )
      sendNotifcation('Registration Successfull !!', '', 'success')
      navigate(`/${contractAddress}/settings?chainId=${chainId}`)
    } catch (error) {
      if (error instanceof SwitchNetworkRejected)
        sendNotifcation(
          'Switch network rejected',
          'You need to switch to the correct network to register as a collaborator',
          'danger'
        )
      else if (error instanceof RelayerError)
        sendNotifcation(
          'Relayer error',
          'Something went wrong. Please try again',
          'danger'
        )
      else if (error instanceof ResolveEventError)
        sendNotifcation(
          'Resolve event error',
          'Something went wrong. Please try again',
          'danger'
        )
      else
        sendNotifcation(
          'Registration failed',
          'Something went wrong. Please try again',
          'danger'
        )
      console.log(error)
    } finally {
      setRegistering(false)
    }
  }

  useEffect(() => {
    if (!showRegisterPopup) setShowPopup(false)
  }, [showRegisterPopup])

  return (
    <DynamicModal
      className="max-w-[525px]"
      open={showRegisterPopup}
      onOpenChange={setShowRegisterPopup}
      title={'Confirm collaboration request'}
      description="Please confirm below to join this Portal as a collaborator."
      primaryAction={{
        label: retryCollaborationFlow ? 'Retry' : 'Confirm',
        isLoading: registering,
        onClick: handleRegistration,
      }}
      secondaryAction={{
        label: 'Cancel',
        onClick: () => setShowRegisterPopup(false),
      }}
    />
  )
}

const EnablePublicPortalToggler = () => {
  const [isLoading, setLoading] = useState(false)

  const contractAddress = useParams().address as string
  const walletAddress = usePrivyHelper().walletAddress as string
  const invokerStatus = useInvokerStatus(walletAddress, contractAddress)
  const portalMetadata = usePortalMetadata(contractAddress)
  const dispatch = useAppDispatch()

  const onEnablePublicPortalToggle = async (isOn: boolean) => {
    try {
      setLoading(true)
      sendNotifcation(
        `${isOn ? 'Enabling' : 'Disabling'} public portal`,
        `Please wait while we ${
          isOn ? 'enable' : 'disable'
        } your public portal`,
        'info',
        250000
      )
      const updatedMetadata = {
        ...portalMetadata,
        isPublicPortalEnabled: isOn,
      } as IContractMetadata

      await updatePortalMetadata(
        updatedMetadata,
        contractAddress,
        walletAddress
      )
      dispatch(
        updateContractMetadataState({
          metadata: updatedMetadata,
          contractAddress: contractAddress as string,
        })
      )
      dispatch(
        updatePortalMetadataIPFSUrl({
          contractAddress: contractAddress as string,
          metadataLastWorkingIPFSUrl: '',
        })
      )
      clearAllNotification()
      sendNotifcation(
        `${isOn ? 'Enabled' : 'Disabled'} public portal`,
        `Your public portal has been ${isOn ? 'enabled' : 'disabled'}`,
        'success'
      )
    } catch (err) {
      clearAllNotification()

      sendNotifcation(
        `Failed to ${isOn ? 'enable' : 'disable'} public portal`,
        `An error occurred while ${
          isOn ? 'enabling' : 'disable'
        } public portal`,
        'danger'
      )
    } finally {
      setLoading(false)
    }
  }

  if (invokerStatus !== InvokerStatus.Owner) return null
  return (
    <Tooltip
      text="Enable public view of your Portal"
      position="bottom"
      className="!rounded-[4px] text-helper-text-sm"
    >
      <div className="lg:flex gap-2 items-center justify-center pr-2 hidden access-control-tour">
        <p>Disable Portal</p>
        <Toggle
          disabled={isLoading}
          className="cursor-pointer"
          checked={portalMetadata?.isPublicPortalEnabled}
          onCheckedChange={onEnablePublicPortalToggle}
        />
        <p>Enable Portal</p>
      </div>
    </Tooltip>
  )
}

export const ConfirmationModal = ({
  open,
  onOpenChange,
}: {
  open: boolean
  onOpenChange: (open: boolean) => void
}) => {
  const navigate = useNavigate()
  const { address: contractAddress } = useParams()
  const [searchParams] = useSearchParams()
  const chainId = parseInt(searchParams.get('chainId') || '')
  const filePath = `/${contractAddress}?chainId=${chainId}`
  const isMobile = useMediaQuery('(max-width: 768px)')

  if (isMobile) {
    return (
      <Drawer open={open} onOpenChange={onOpenChange}>
        <DrawerContent className="max-w-xl">
          <DrawerHeader className="px-6">
            <DrawerTitle className="text-left">Did you forget?</DrawerTitle>
          </DrawerHeader>
          <div className="flex w-full h-full border-t-[1px] color-border-default p-5">
            You have unsaved changes. Are you sure you want to leave this page?
          </div>
          <DrawerFooter className="p-5 gap-2">
            <DrawerClose asChild>
              <Button variant="secondary">Cancel</Button>
            </DrawerClose>
            <Button
              onClick={() => {
                navigate(filePath)
              }}
            >
              Leave
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    )
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-xl">
        <DialogHeader className="px-6 pt-6">
          <DialogTitle>Did you forget?</DialogTitle>
        </DialogHeader>
        <div className="flex w-full h-full border-t-[1px] color-border-default p-5">
          You have unsaved changes. Are you sure you want to leave this page?
        </div>
        <DialogFooter className="p-5 gap-2">
          <DialogClose asChild>
            <Button variant="secondary">Cancel</Button>
          </DialogClose>
          <Button
            onClick={() => {
              navigate(filePath)
            }}
          >
            Leave
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export const ShareDropDown = ({ filePath }: { filePath: string }) => {
  const contractAddress = useParams().address as string

  return (
    <div className="text-body-sm color-bg-default color-border-default shadow-elevation-4 flex flex-col gap-1 p-1 rounded-[8px] border-[0.5px] ">
      <a
        href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
          filePath
        )}`}
        target="_blank"
        rel="noopener noreferrer"
        className="w-[184px] flex gap-2 items-center justify-start hover:color-bg-default-hover rounded-[4px] h-[40px] cursor-pointer"
      >
        <IconButton icon="X" variant="ghost" size="sm" /> X
      </a>
      <div
        onClick={() => {
          copyToClipboard(`${FRAME_URL}${contractAddress}`)
          sendNotifcation(
            'Frame copied. Now simply paste it on Warpcast for the Onchain magic to happen!',
            '',
            'info'
          )
        }}
        className="w-[184px] flex gap-[6px] items-center justify-start hover:color-bg-default-hover rounded-[4px] h-[40px] cursor-pointer"
      >
        <div className="p-1">
          <img src={farcasterLogo} alt="farcaster" className="w-6" />
        </div>
        <p className="text-[#7C65C1] font-bold">Frame on Farcaster</p>
      </div>
      <a
        href={`https://lenster.xyz/?text=Checkout%20my%20Portal&url=${encodeURIComponent(
          window.location.href
        )}`}
        target="_blank"
        rel="noopener noreferrer"
        className="w-[184px] flex gap-[6px] items-center justify-start hover:color-bg-default-hover rounded-[4px] h-[40px] cursor-pointer"
      >
        <div className="p-1">
          <img src={heyLogo} alt="hey" className="w-6" />
        </div>
        <p>Hey</p>
      </a>
      <div
        onClick={() => {
          copyToClipboard(filePath)
          sendNotifcation('Link copied !!', '', 'warning')
        }}
        className="w-[184px] flex gap-2 items-center justify-start hover:color-bg-default-hover rounded-[4px] h-[40px] cursor-pointer"
      >
        <IconButton icon="Link" variant="ghost" size="sm" />
        Copy Link
      </div>
    </div>
  )
}

export const RecentActionButton = ({
  onCopy,
  onDelete,
}: {
  onCopy: () => void
  onDelete: () => void
}) => {
  return (
    <div className="text-body-sm color-bg-default color-border-default shadow-elevation-4 flex flex-col gap-1 p-2 rounded-[8px] border-[0.5px]">
      <div
        onClick={onCopy}
        className="w-[184px] flex gap-2 items-center justify-start hover:color-bg-default-hover rounded-[4px] h-[40px] cursor-pointer"
      >
        <div className="w-[36px] h-[36px] hover:bg-[#F2F4F5] rounded-[4px]">
          <IconButton size="md" icon="Link" variant="ghost" />
        </div>{' '}
        Copy link
      </div>
      <div
        onClick={onDelete}
        className="w-[184px] flex gap-2 items-center justify-start hover:color-bg-default-hover rounded-[4px] h-[40px] cursor-pointer color-text-danger"
      >
        <div className="w-[36px] h-[36px] hover:bg-[#F2F4F5] rounded-[4px]">
          <IconButton size="md" icon="Trash" variant="ghost" />
        </div>
        Delete
      </div>
    </div>
  )
}
