import { Contract, JsonRpcSigner, Wallet } from 'ethers'
import contractAbi from '../contract/portalAbi.json'
import forwarderAbi from '../contract/forwarderAbi.json'
import { defaultNetwork } from '../config/network-config'

export const instantiatePortalContract = (
  contractAddress: string,
  signer: JsonRpcSigner
) => {
  return new Contract(contractAddress, contractAbi, signer)
}

export const instantiateForwarderContract = (
  signer: JsonRpcSigner | Wallet
) => {
  const forwarderAddress = defaultNetwork.forwarder
  return new Contract(forwarderAddress as string, forwarderAbi, signer)
}
