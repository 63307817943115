import { createSlice } from '@reduxjs/toolkit'
import { IPageState, IPageStatus } from './interfaces'

const initialState: IPageState = {
  status: IPageStatus.LOADING,
}

const pageSlice = createSlice({
  name: 'page-status',
  initialState,
  reducers: {
    setPageStatus: (state: IPageState, action) => {
      state.status = action.payload
    },
  },
})

export const { setPageStatus } = pageSlice.actions

export default pageSlice.reducer
