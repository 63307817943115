/* eslint-disable @typescript-eslint/no-explicit-any */
import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

const DIALOG_STORE_KEY = 'fileverse.portal.hearbit.dialog_store'

interface AppStore {
  dialogState: any
  setDialogState: (state: any) => void
  resetDialogState: () => void
}

export const useDialogStore = create(
  persist(
    (set) => ({
      dialogState: {},
      setDialogState: (state: any) => set({ dialogState: state }),
      resetDialogState: () => set({ dialogState: {} }),
    }),
    {
      name: DIALOG_STORE_KEY,
      partialize: (state: AppStore) => ({
        dialogState: state.dialogState,
      }),
    }
  )
)

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}
export const formatCounter = (count: number) => {
  if (count < 1000) {
    return count.toString()
  }
  if (count >= 1000 && count < 1000000) {
    return `${(count / 1000).toFixed(1)}K`
  }
  if (count >= 1000000 && count < 1000000000) {
    return `${(count / 1000000).toFixed(1)}M`
  }
  if (count >= 1000000000) {
    return `${(count / 1000000000).toFixed(1)}B`
  }
  return
}
