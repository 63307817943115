/* eslint-disable @typescript-eslint/no-explicit-any */
import { EditorState, Plugin, PluginKey } from '@tiptap/pm/state'
import { Decoration, DecorationSet, EditorView } from '@tiptap/pm/view'
import sendNotifcation from '../../../utils/notification'

const uploadKey = new PluginKey('upload-image')

const UploadImagesPlugin = () =>
  new Plugin({
    key: uploadKey,
    state: {
      init() {
        return DecorationSet.empty
      },
      apply(tr, set) {
        set = set.map(tr.mapping, tr.doc)
        // See if the transaction adds or removes any placeholders
        const action = tr.getMeta(this)
        if (action && action.add) {
          const { id, pos, src } = action.add

          const placeholder = document.createElement('div')
          placeholder.setAttribute('class', 'img-placeholder')
          const image = document.createElement('img')
          image.setAttribute('class', 'rounded-lg border border-stone-200')
          image.src = src
          placeholder.appendChild(image)
          const deco = Decoration.widget(pos + 1, placeholder, {
            id,
          })
          set = set.add(tr.doc, [deco])
        } else if (action && action.remove) {
          set = set.remove(
            set.find(
              undefined,
              undefined,
              (spec: any) => spec.id == action.remove.id
            )
          )
        }
        return set
      },
    },
    props: {
      decorations(state) {
        return this.getState(state)
      },
    },
  })

export default UploadImagesPlugin

function findPlaceholder(state: EditorState, id: any) {
  const decos = uploadKey.getState(state)
  const found = decos.find(null, null, (spec: any) => spec.id == id)
  return found.length ? found[0].from : null
}

export function startImageUpload(file: File, view: EditorView, pos: number) {
  // check if the file is an image
  if (!file.type.includes('image/')) {
    sendNotifcation('Failed to upload image', 'File is not an image', 'danger')
    return

    // check if the file size is less than 1MB
  } else if (file.size > 1024 * 1024) {
    sendNotifcation(
      'Failed to upload image',
      'Image should be less than 1 MB',
      'danger'
    )
    return
  }

  // A fresh object to act as the ID for this upload
  const id = {}

  // Replace the selection with a placeholder
  const tr = view.state.tr
  if (!tr.selection.empty) tr.deleteSelection()

  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => {
    tr.setMeta(uploadKey, {
      add: {
        id,
        pos,
        src: reader.result,
      },
    })
    view.dispatch(tr)
  }

  handleImageUpload(file).then((src) => {
    const { schema } = view.state

    const pos = findPlaceholder(view.state, id)
    // If the content around the placeholder has been deleted, drop
    // the image
    if (pos == null) return

    // Otherwise, insert it at the placeholder's position, and remove
    // the placeholder

    const imageSrc = typeof src === 'object' ? reader.result : src

    const node = schema.nodes.resizableMedia.create({
      src: imageSrc,
      'media-type': 'img',
    })
    const transaction = view.state.tr
      .replaceWith(pos - 2, pos + node.nodeSize, node)
      .setMeta(uploadKey, { remove: { id } })
    view.dispatch(transaction)
  })
}

export const handleImageUpload = (file: File) => {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
  })
}

export const uploadFn = async (image: File) => {
  // Read image data and create a File object, then return the string URL of the uploaded image
  const reader = new FileReader()
  reader.readAsDataURL(image)

  // check if image is too large for upload (> 1 MB), then throw error
  if (image.size > 1024 * 1024) {
    reader.abort()
    sendNotifcation(
      'Failed to upload image',
      'Image should be less than 1 MB',
      'danger'
    )
    throw new Error('Image too large')
  }

  // convert image to base64
  const base64Image = await new Promise((resolve) => {
    reader.onload = () => {
      resolve(reader.result as string)
    }
  })
  return base64Image as string
}
