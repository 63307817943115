/* eslint-disable @typescript-eslint/no-explicit-any */
import { PortalContractEventEnum } from '../utils/transaction/interface'

import { getPortalContract } from '../utils/contract'

export const getPortalCreationInfo = async (contractAddress: string) => {
  const portalContract = getPortalContract(contractAddress)
  const ownershipTransferred = await portalContract.queryFilter(
    PortalContractEventEnum.OWNERSHIP_TRANSFERRED
  )
  const portalCreation = ownershipTransferred[0]
  const block = await portalCreation.getBlock()
  const timestamp = block.timestamp
  return { timestamp, blockNumber: block.number }
}
