import { IKeyShaingState } from './interface'
import { createSlice } from '@reduxjs/toolkit'

const initialState: IKeyShaingState = {
  keys: {},
}

const keySharingSlice = createSlice({
  name: 'keySharing',
  initialState,
  reducers: {
    addKeys(state, action): void {
      state.keys[action.payload.contractAddress] = {
        privateKey: action.payload.privateKey,
        publicKey: action.payload.publicKey,
      }
    },
  },
})

export const { addKeys } = keySharingSlice.actions

export default keySharingSlice.reducer
