/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { LucideIcon } from '@fileverse/ui'
import cn from 'classnames'
import { Tooltip } from '../../pages/PublicPortal/components/Tooltip'

export default function CreateFolderCard({
  title,
  icon,
  width,
  backgroundColor,
}: {
  title: string
  icon: string
  width?: string
  view?: string
  backgroundColor?: string
}) {
  return (
    <Tooltip text="Coming soon" position="bottom">
      <div
        data-cy="folder"
        className={cn(
          'relative aspect-[3/2.2] rounded-lg p-3 space-y-3 transition-colors duration-200 ease-in-out cursor-not-allowed',
          width || 'w-56',
          backgroundColor || 'color-bg-secondary'
        )}
      >
        <div className="flex h-[15%] items-center">
          <div className="bg-white w-[30%] rounded-t-lg absolute h-[15%] right-3 top-6">
            <div className="w-8 h-8 -translate-x-[0.15rem] -translate-y-[0.85rem] bg-white transform -rotate-[60deg] origin-bottom-right" />
          </div>
        </div>
        <div className="bg-white h-[85%] rounded-tr-none rounded-lg flex flex-col items-center justify-center relative">
          <LucideIcon
            name={icon as any}
            size="md"
            fill={'#F2F4F5'}
            stroke={'#A1AAB1'}
            className=""
          />
          <h6 className="color-text-secondary text-heading-xsm">{title}</h6>
        </div>
      </div>
    </Tooltip>
  )
}
