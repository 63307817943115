import { useEnsProvider } from './provider'

export const resolveEnsName = async (name: string) => {
  try {
    const provider = useEnsProvider()

    const ensAddress = await provider.resolveName(name)

    return ensAddress && ensAddress
  } catch (err) {
    console.log('No Address associated with this ENS name')
  }
}

export const resolveEnsAddress = async (address: string) => {
  const provider = useEnsProvider()

  const ensName = await provider.lookupAddress(address)

  return ensName && ensName
}
