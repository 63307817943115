/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unescaped-entities */
import React, { forwardRef, useCallback, useEffect, useState } from 'react'
import { useSortedFiles } from '../../../../../store/files/hooks'
import { useParams } from 'react-router-dom'
import styles from './index.module.scss'
import { ChevronDown } from 'lucide-react'
import * as dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import {
  DropdownProvider,
  useDropdown,
} from '../../../../../providers/DropdownContext'
import cn from 'classnames'

import { FileItem, SortByOptions, EmptyState, SortOptions } from '../common'
import { useAppDispatch } from '../../../../../store/hooks'
import { fetchAllFiles } from '../../../../../store/files/thunks'
import { defaultNetwork } from '../../../../../config/network-config'

dayjs.extend(relativeTime)

const NO_RECENT_FILES =
  "You don't have any files or documents yet. Start by creating or uploading something!"

export const RecentSection = () => {
  const { address: contractAddress } = useParams()
  const [sortBy, setSortBy] = useState<SortOptions>(SortOptions.Created)
  const allFiles = useSortedFiles(contractAddress as string, sortBy)
  const dispatch = useAppDispatch()

  const fetchFiles = useCallback(async () => {
    await dispatch(
      fetchAllFiles({
        contractAddress: contractAddress as string,
        chainId: defaultNetwork.chainId,
      })
    )
  }, [contractAddress, dispatch])

  useEffect(() => {
    fetchFiles()
  }, [fetchFiles])

  const onSortByChange = (value: SortOptions) => {
    setSortBy(value)
  }
  const fileItems = allFiles.map((file) => {
    return <FileItem file={file} key={file.fileId} sortedBy={sortBy} />
  })

  const noFiles = allFiles.length === 0
  return (
    <div
      className={cn(
        'flex w-[100%] h-full flex-col overflow-hidden sm:overflow-x-auto gap-[16px]',
        { 'pb-16': allFiles.length < 2 }
      )}
    >
      <div className="flex justify-between">
        <div className="font-medium text-lg">Recent</div>
        <DropdownProvider onItemClick={onSortByChange}>
          <RecentFileSortBy sortBy={sortBy} isHidden={noFiles} />
        </DropdownProvider>
      </div>
      <div className="flex-col overflow-y-auto scrollbar-none min-h-[50vh]">
        {noFiles ? <EmptyState text={NO_RECENT_FILES} /> : fileItems}
      </div>
    </div>
  )
}

const RecentFileSortBy = forwardRef(function RecentFileSortBy(props: any, ref) {
  const { toggle } = useDropdown()
  const { sortBy, isHidden } = props
  return (
    <button
      ref={ref as React.Ref<HTMLButtonElement> | undefined}
      className={cn(
        'flex gap-[4px] relative text-[14px] items-center',
        isHidden && 'hidden'
      )}
      onClick={toggle}
    >
      <div>Sort by {sortBy}</div>
      <ChevronDown size="14px" />
      <SortByDropdownItems />
    </button>
  )
})

const SortByDropdownItems = () => {
  const { isOpen, onItemClick } = useDropdown()
  const onClick = (value: string) => {
    if (onItemClick) onItemClick(value)
  }
  return (
    <div className={cn(styles.dropdown_items_wrapper, isOpen && styles.show)}>
      {SortByOptions.map((option) => {
        const { disabled = false } = option
        const label = disabled ? `${option.label} (Coming Soon)` : option.label
        return (
          <div
            className={cn(
              'flex gap-2 items-center px-[12px] py-[8px] rounded-[4px] cursor-pointer hover:bg-[#F2F4F5]',
              disabled &&
                'pointer-events-none cursor-not-allowed color-text-disabled'
            )}
            key={option.label}
            onClick={() => (disabled ? null : onClick(option.value))}
          >
            {label}
          </div>
        )
      })}
    </div>
  )
}
