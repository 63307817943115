import React, { useState } from 'react'
import bold from '../../assets/bold.svg'
import underline from '../../assets/underline.svg'
import italic from '../../assets/italic.svg'
import strike from '../../assets/strike.svg'
import bulletpoint from '../../assets/bulletpoint.svg'
import order from '../../assets/order.svg'
import image from '../../assets/image-icon.svg'
import link from '../../assets/link.svg'
import code from '../../assets/code.svg'
import tick from '../../assets/tick.svg'
import leftTick from '../../assets/leftTick.svg'
import rightTick from '../../assets/rightTick.svg'
import leftAlign from '../../assets/leftAlign.svg'
import rightAlign from '../../assets/rightAlign.svg'
import centerAlign from '../../assets/centerAlign.svg'
import normalAlign from '../../assets/normalAlign.svg'

const Toolbar = () => {
  const [showMore, setShowMore] = useState(false)
  return (
    <div className="flex h-full w-[25%]" id="toolbar">
      <div className="w-full">
        <div className="bg-[#ffffff] mr-8 border  rounded-lg  w-80 p-4">
          <div>
            <h6 className="font-semibold text-lg">Style</h6>
            <div className="mt-2 flex justify-between">
              <button
                className="ql-size border mr-2 font-semibold text-sm px-4 py-1 rounded-md  bg-[#F1F1F1]"
                value="huge"
              >
                Title
              </button>
              <button
                className="ql-size border mr-2 font-semibold text-sm px-4 py-1 rounded-md  bg-[#F1F1F1]"
                value="large"
              >
                Subtitle
              </button>
              <button
                className="ql-size border font-semibold text-sm px-4 py-1 rounded-md  bg-[#F1F1F1]"
                value=""
              >
                Paragraph
              </button>
            </div>
          </div>
          <div>
            <h6 className="font-semibold text-lg mt-4">Font</h6>
            <div className="mt-2 justify-between w-full flex">
              <button
                className="ql-font border w-20   px-4 py-1 rounded-md  bg-[#F1F1F1]"
                value=""
              >
                <h6 className="font-semibold text-md">Aa</h6>
                <h6 className="text-xs text-[#777777]">San Serif</h6>
              </button>
              <button
                className="ql-font border w-20   px-4 py-1 rounded-md  bg-[#F1F1F1]"
                value="serif"
              >
                <h6 className="font-semibold text-md">Ss</h6>
                <h6 className="text-sm text-[#777777]">Serif</h6>
              </button>
              <button
                className="ql-font border  w-20   px-4 py-1 rounded-md  bg-[#F1F1F1]"
                value="monospace"
              >
                <h6 className="font-semibold text-md">00</h6>
                <h6 className="text-sm text-[#777777]">Mono</h6>
              </button>
            </div>
            <div className="mt-4 w-full flex rounded-lg">
              <button className="ql-bold border items-center w-[25%] rounded-l-md flex justify-center px-4 py-2   bg-[#F1F1F1]">
                <img src={bold} />
              </button>
              <button className="ql-italic border items-center w-[25%] px-4 flex justify-center py-2   bg-[#F1F1F1]">
                <img src={italic} />
              </button>
              <button className="ql-strike border items-center w-[25%] px-4 flex justify-center py-2   bg-[#F1F1F1]">
                <img src={strike} />
              </button>
              <button className="ql-underline border items-center w-[25%] px-4 rounded-r-md flex justify-center py-2  bg-[#F1F1F1]">
                <img src={underline} />
              </button>
            </div>
            <div className="mt-2 w-full  flex rounded-lg">
              <select className="ql-color w-[25%] px-4"></select>
              <select className="ql-background w-[25%] px-4"></select>
              <button
                className="ql-list border w-[25%] px-4 flex items-center justify-center py-2   bg-[#F1F1F1]"
                value="bullet"
              >
                <img src={bulletpoint} />
              </button>
              <button
                className="ql-list border w-[25%] px-4 rounded-r-md items-center flex justify-center py-2  bg-[#F1F1F1]"
                value="ordered"
              >
                <img src={order} />
              </button>
            </div>
          </div>
          {/* {showMore && ( */}
          <div className={showMore ? '' : 'collapse absolute top-0'}>
            <h6 className="font-semibold text-lg mt-4">Add</h6>
            <div className="mt-2 w-full  flex rounded-lg">
              <button className="ql-image border w-full rounded-l-md flex items-center justify-center px-4 py-2   bg-[#F1F1F1]">
                <img src={image} />
              </button>
              <button className="ql-link border w-full px-4 flex items-center  justify-center py-2   bg-[#F1F1F1]">
                <img src={link} />
              </button>
              <button className="ql-code-block border rounded-r-md w-full px-4 flex items-center justify-center py-2   bg-[#F1F1F1]">
                <img src={code} />
              </button>
            </div>
            <div className="mt-2 w-full  flex rounded-lg">
              <button
                className="ql-list border w-full rounded-l-md flex items-center justify-center px-4 py-2   bg-[#F1F1F1]"
                value="ordered"
              >
                <img src={order} />
              </button>
              <button
                className="ql-list border w-full px-4 flex items-center  justify-center py-2   bg-[#F1F1F1]"
                value="bullet"
              >
                <img src={bulletpoint} />
              </button>
              <button
                className="ql-list border rounded-r-md w-full px-4 flex items-center justify-center py-2   bg-[#F1F1F1]"
                value="check"
              >
                <img src={tick} />
              </button>
            </div>
            <div className="mt-2 w-full  flex rounded-lg">
              <div className="flex mr-2">
                <button
                  className="ql-indent border w-full rounded-l-md flex items-center justify-center px-4 py-2   bg-[#F1F1F1]"
                  value="-1"
                >
                  <img src={leftTick} />
                </button>
                <button
                  className="ql-indent border w-full rounded-r-md  px-4 flex items-center  justify-center py-2   bg-[#F1F1F1]"
                  value="+1"
                >
                  <img src={rightTick} />
                </button>
              </div>
              <div className="flex w-full">
                <button
                  className="ql-align border rounded-l-md  w-full px-3 flex items-center justify-center py-2   bg-[#F1F1F1]"
                  value=""
                >
                  <img src={leftAlign} />
                </button>
                <button
                  className="ql-align border  w-full px-3 flex items-center justify-center py-2   bg-[#F1F1F1]"
                  value="center"
                >
                  <img src={centerAlign} />
                </button>
                <button
                  className="ql-align border w-full px-3 flex items-center justify-center py-2   bg-[#F1F1F1]"
                  value="right"
                >
                  <img src={rightAlign} />
                </button>
                <button
                  className="ql-align border rounded-r-md w-full px-3 flex items-center justify-center py-2   bg-[#F1F1F1]"
                  value="justify"
                >
                  <img src={normalAlign} />
                </button>
              </div>
            </div>
          </div>

          <div className="flex justify-center items-center p-2 mt-2 ">
            {showMore ? (
              <p
                onClick={() => setShowMore(false)}
                className=" text-[#007AFF] font-bold text-sm cursor-pointer"
              >
                See less
              </p>
            ) : (
              <p
                onClick={() => setShowMore(true)}
                className=" text-[#007AFF] font-bold text-sm cursor-pointer"
              >
                See more
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Toolbar
