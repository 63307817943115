/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import Quill from 'quill'
import uuid from 'react-uuid'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useContract } from '../../store/contract/hooks'
import {
  BindYtextToQuill,
  QuillInstance,
} from '../../utils/collaboration/quill'
import { checkIfOwnerOrCollaborator } from '../../utils/checkIfOwnerOrCollaborator'
import * as Y from 'yjs'
import useVisibility from '../../hooks/useVisibility'
import CTAButton from '../../components/common/CTAButton'
import { Popup } from '../../components/Popup/PopUp'
import Toolbar from '../../components/QuillEditor/Toolbar'
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html'
import { getISEAKeyPair } from '../../utils/libCrypto'
import { ISEAPair } from 'gun'
import {
  applyUpdatesFromGunNode,
  handleRemotePeerCursorPosition,
  handleUserCursorPosition,
  saveYdocUpdates,
} from '../../utils/collaboration/rtc'
import { IDraft } from '../../types/interface/drafts.interface'
import { MakeFileFromObject } from '../../utils/makeFileFromObject'
import { editDraftName, removeDraft } from '../../utils/collaboration/utils'
import {
  TopLeftUI,
  TopRightUI,
} from '../../components/ExcalidrawCanvas/CanvasCustomUI'
import { DocStatus } from '../../components/ExcalidrawCanvas/SavingStatusUI'
import { useGunNodes } from '../../hooks/useGunNode'
import { useServerKeys } from '../../store/invoker/hooks'
import { useEthersSigner } from '../../hooks/clientToProvider'
import { GunInstance } from '../../utils/instantiateGun'
import { usePrivyHelper } from '../../hooks/usePrivyHelper'

let id = uuid()

const QuillEditor = ({
  setFiles,
  documentKey,
  setCollabDocPreview,
  rtcData,
}: {
  setFiles: React.Dispatch<File[]>
  documentKey: string
  authKey: ISEAPair
  rtcData: IDraft
  setCollabDocPreview: React.Dispatch<React.SetStateAction<string | undefined>>
}) => {
  const [searchParams] = useSearchParams()
  const chainId = parseInt(searchParams.get('chainId') || '')
  const walletAddress = usePrivyHelper().walletAddress
  const invoker = walletAddress as string
  const { address: contractAddress, rtcId } = useParams()

  const [quillState, setQuillState] = useState<Quill>()
  let quill: Quill | undefined
  const key = documentKey
  const navigate = useNavigate()
  const contractSigner = useEthersSigner({ chainId })
  const [draftName, setDraftName] = useState<string>(rtcData?.name || '')
  const contract = useContract(contractAddress as string)
  const serverKeys = useServerKeys(
    walletAddress as string,
    contractAddress as string
  )
  const isCollaborator = checkIfOwnerOrCollaborator(
    contract,
    invoker,
    serverKeys
  )
  const [docStatus, setDocStatus] = useState<DocStatus>(DocStatus.SAVED)
  const rtcKey = getISEAKeyPair(key) // key gotten from the URL
  const [urlParams, setUrlParam] = useSearchParams()
  const { getDocumentCursorNodeV1, getDocumentContentNodeV1 } = useGunNodes()

  const draftCursorNode = getDocumentCursorNodeV1(rtcId as string) // cursor state
  const draftContentNode = getDocumentContentNodeV1(rtcId as string)

  const ydoc = new Y.Doc()
  const ytext = ydoc.getText('fileverse/collab-doc')
  const { getAuthenticatedPluginMetadataNode } = useGunNodes()

  const initializeEditor = async () => {
    quill = QuillInstance()
    BindYtextToQuill(ytext, quill)
    //call saveYdocUpdates & applyUpdatesFromGunNode simultaneously for Whiteboard on onChange function/prop
    saveYdocUpdates(
      ydoc,
      quill as Quill,
      draftContentNode,
      rtcKey as ISEAPair,
      id,
      setDocStatus
    )
    applyUpdatesFromGunNode(
      ydoc,
      quill as Quill,
      draftContentNode,
      rtcKey as ISEAPair,
      id
    )
    handleUserCursorPosition(quill as Quill, draftCursorNode, invoker)
    handleRemotePeerCursorPosition(draftCursorNode, quill as Quill, invoker)
    setQuillState(quill)
    document
      .querySelector('#toolbar')
      ?.addEventListener('mousedown', function (event) {
        event.preventDefault()
        event.stopPropagation()
      })

    if (!urlParams.get('version')) {
      urlParams.set('version', '1')
      setUrlParam(urlParams)
    }
  }
  useEffect(() => {
    initializeEditor()
    return () => {
      id = uuid()
    }
  }, [])

  const setCursor = async () => {
    try {
      const node = draftCursorNode.get(invoker)
      await GunInstance.putGunNodeData(node, {
        user: invoker,
        cursorPosition: null,
      })
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    setCursor()
    window.addEventListener('beforeunload', setCursor)
    return () => window.removeEventListener('beforeunload', setCursor)
  }, [])
  const {
    ref: titlePopUpDiv,
    isComponentVisible: titlePopUp,
    setIsComponentVisible: setTitlePopUp,
  } = useVisibility(false)

  const getCollabDocPreview = async () => {
    const content = quillState?.getContents()
    const converter = content && new QuillDeltaToHtmlConverter(content.ops)
    const html = converter?.convert()
    return html
  }

  const showTitlePopUp = async () => {
    const html = await getCollabDocPreview()
    const preview = document.querySelector('#preview')!
    preview.innerHTML = html || ''
    setCollabDocPreview(html)
    setTitlePopUp(true)
  }

  const publishDocument = async () => {
    if (!contractAddress || !contractSigner) return
    const documentFile = {
      file: quillState?.getContents(),
      source: 'fileverse_document',
      version: 0,
    }
    editDraftName(
      draftName,
      getAuthenticatedPluginMetadataNode(rtcId as string)
    )
    const html = await getCollabDocPreview()
    setCollabDocPreview(html)

    const file = MakeFileFromObject(documentFile, `${draftName || 'Untitled'}`)
    setFiles([file])
  }
  const downloadProject = async () => {
    const documentFile = {
      file: quillState?.getContents(),
      source: 'fileverse_document',
      version: 0,
    }
    const file =
      'text/json;charset=utf-8,' +
      encodeURIComponent(JSON.stringify(documentFile))
    const a = document.createElement('a')
    a.href = 'data:' + file
    a.download = `${draftName || 'Untitled'}`
    a.click()
  }
  return (
    <div className="h-full w-screen">
      <div className="w-[95%] h-full">
        <div className="h-full w-[100%]">
          <div className="h-full w-[100%]">
            {isCollaborator && (
              <div className={` flex items-center h-11 w-full justify-between`}>
                <TopLeftUI
                  fileName={draftName}
                  setFileName={(name: string) => setDraftName(name)}
                  onBackButtonClick={async () => {
                    if (quillState?.getText().trim().length === 0) {
                      removeDraft(
                        getAuthenticatedPluginMetadataNode(rtcId as string),
                        rtcId as string
                      )
                      navigate(`/${contractAddress}?chainId=${chainId}`)
                      return
                    }
                    editDraftName(
                      draftName,
                      getAuthenticatedPluginMetadataNode(rtcId as string)
                    )
                  }}
                />
                <TopRightUI
                  publishCanvas={publishDocument}
                  fileName={draftName}
                  showTitlePopUp={showTitlePopUp}
                  downloadProject={downloadProject}
                  docStatus={docStatus}
                  pluginType={'dDoc'}
                  invokerAddress={invoker}
                  isCollaborator={isCollaborator}
                  handleShareButton={() => null}
                />
              </div>
            )}

            <div
              className={`flex ${
                isCollaborator ? 'h-[92%]' : 'h-[100%]'
              } w-[100%] pt-4`}
            >
              <Toolbar />
              <div className="max-w-[70%] w-full h-full flex justify-center">
                <div className="w-full h-full p-4 bg-white shadow-lg ml-4">
                  <div
                    id="editor"
                    className="overflow-y-scroll w-[85%] no-scrollbar  border-none h-full"
                  ></div>
                </div>
              </div>
            </div>
            <Popup isOpen={titlePopUp} width={'450px'}>
              <div ref={titlePopUpDiv} className="bg-white rounded-lg p-4">
                <h6 className="font-semibold">Add name</h6>
                <input
                  className="border w-full mt-2 rounded-lg p-2"
                  type="text"
                  placeholder="Untitled"
                  value={draftName}
                  onChange={(e) => setDraftName(e.target.value)}
                />
                <div
                  id="preview"
                  className="h-60 p-4 overflow-scroll no-scrollbar w-full mt-2 border"
                ></div>
                <div className="flex justify-between w-full mt-4">
                  <CTAButton
                    onClick={() => {
                      setTitlePopUp(false)
                    }}
                    title="Close"
                  />
                  <CTAButton
                    isDisabled={!draftName}
                    onClick={() => publishDocument()}
                    backgroundColorClassName={
                      'bg-black text-white border-black border-2'
                    }
                    title="Next"
                  />
                </div>
              </div>
            </Popup>
          </div>
        </div>
      </div>
    </div>
  )
}

export default QuillEditor
