/* eslint-disable @typescript-eslint/no-explicit-any */
// import { ConnectButton } from '@rainbow-me/rainbowkit'
import React, {
  Dispatch,
  forwardRef,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import {
  useAllContract,
  useContract,
  useContractCollaborators,
} from '../../store/contract/hooks'
import isEmpty from 'lodash/isEmpty'
import {
  useInvokerContractCredentials,
  useInvokerContracts,
} from '../../store/invoker/hooks'
import PrimaryButton from './PrimaryButton'
import AccountTab from './AccountTab'
import AboutDropDown from './AboutDropDown'
import { useInvokerOwnedContracts } from '../../store/invoker/utils'
import { updateAddress } from '../../store/account/reducer'
import { useAppDispatch } from '../../store/hooks'
import NotificationCenter from '../Notification/NotificationCenter'

import { useNotificationProvider } from '../../hooks/useNotificationProvider'
import { useMediaQuery } from '@mui/material'
import avatar from '../../assets/avatar.png'

import { AppWindow, PowerOff } from 'lucide-react'
import { Avatar, IconButton } from '@fileverse/ui'
import { DropdownProvider, useDropdown } from '../../providers/DropdownContext'
import cn from 'classnames'
import styles from './AccountSection.module.scss'
import NotificationProvider from '../Notification/NotificationProvider'
import { usePrivy } from '@privy-io/react-auth'
import { Tooltip } from '../../pages/PublicPortal/components/Tooltip'
import { usePrivyHelper } from '../../hooks/usePrivyHelper'

const AccountSection = ({
  setRegisterCollaboratorPopupVisibility,
  setNonCollaboratorPopupVisibility,
  isUserOnPortalDashboard,
  setAccountDropDown,
  accountDropDown,
  setAboutDropDown,
  aboutDropDown,
  handleDisconnectWallet,
}: {
  setRegisterCollaboratorPopupVisibility: Dispatch<SetStateAction<boolean>>
  setNonCollaboratorPopupVisibility: Dispatch<SetStateAction<boolean>>
  isUserOnPortalDashboard: boolean
  setAccountDropDown: Dispatch<SetStateAction<boolean>>
  accountDropDown: boolean
  setAboutDropDown: Dispatch<SetStateAction<boolean>>
  aboutDropDown: boolean
  handleDisconnectWallet: () => void
}) => {
  const isCreatePortalOrWelcomePage =
    window.location.hash.includes('create') ||
    window.location.hash.includes('welcome')
  const { address: contractAddress } = useParams()
  const connectButton = document.getElementById('wallet-connect-btn')
  const path = window.location.hash.substring(45)
  const contract = useContract((contractAddress as string) || '')
  const { walletAddress } = usePrivyHelper()
  const [isUserToRedirectToPortalPage, setUserRedirectionToPortalPage] =
    useState<boolean>(false)
  const invokerContracts = useInvokerContracts(walletAddress as string)
  const allPortals = useAllContract()
  const isMediaMax1025px = useMediaQuery('(max-width : 1025px)')
  const [registerCollaboratorAsyncPopup, setRegisterCollaboratorAsyncPopup] =
    useState(false)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const portalCollaborators = useContractCollaborators(
    contractAddress as string
  )
  const credential = useInvokerContractCredentials(
    walletAddress as string,
    contractAddress as string
  )

  const handleForCollaboratorsClick = () => {
    if (portalCollaborators.includes(walletAddress as string)) {
      isEmpty(credential)
        ? setRegisterCollaboratorPopupVisibility(true)
        : navigate(`/${contractAddress}?chainId=${contract?.chainId}`)
    } else {
      if (walletAddress) {
        setNonCollaboratorPopupVisibility(true)
      } else {
        connectButton?.click()
        setRegisterCollaboratorAsyncPopup(true)
      }
    }
  }

  useEffect(() => {
    if (walletAddress && isUserToRedirectToPortalPage) {
      const userOwnedPortal = useInvokerOwnedContracts(
        invokerContracts,
        allPortals,
        walletAddress as string
      )
      if (userOwnedPortal.length > 0) {
        navigate(`/contracts`)
        setUserRedirectionToPortalPage(false)
      } else {
        navigate(`/create`)
        setUserRedirectionToPortalPage(false)
      }
      dispatch(updateAddress({ address: walletAddress }))
    }
    if (registerCollaboratorAsyncPopup && walletAddress) {
      handleForCollaboratorsClick()
      setRegisterCollaboratorAsyncPopup(false)
    }
  }, [walletAddress])

  return (
    <div className="flex items-center relative ml-auto">
      {accountDropDown && (
        <AccountTab
          handleDisconnectWallet={handleDisconnectWallet}
          setAccountDropDown={setAccountDropDown}
          signerAddress={walletAddress as string}
        />
      )}
      {aboutDropDown && (
        <AboutDropDown
          setIsNavigateToPortals={setUserRedirectionToPortalPage}
        />
      )}
      <div className="flex items-center relative md:mr-0 justify-end gap-[4px]">
        {isUserOnPortalDashboard && !isMediaMax1025px && (
          <NotificationProvider>
            <DropdownProvider>
              <NotificationButton />
            </DropdownProvider>
          </NotificationProvider>
        )}
        {path.includes('member') && (
          <button
            onClick={handleForCollaboratorsClick}
            className="border-2 border-black bg-white  px-4 py-1 rounded-lg"
            data-cy="for-collaborator"
          >
            For Collaborators
          </button>
        )}

        {!isCreatePortalOrWelcomePage && (
          <DropdownProvider>
            <AccountButton
              onAboutClick={() => setAboutDropDown(!aboutDropDown)}
            />
          </DropdownProvider>
        )}
      </div>
    </div>
  )
}

const NotificationButton = forwardRef(function NotificationButton(props, ref) {
  const provider = useNotificationProvider()
  const unreadNotifications = provider?.unreadNotifications
  const { isOpen, toggle, triggerRef } = useDropdown()

  return (
    <div
      onClick={toggle}
      className="w-[40px] h-[40px] relative hover:cursor-pointer hover:bg-[#F2F4F5] rounded-[4px] flex items-center justify-center"
      ref={triggerRef as React.Ref<HTMLDivElement> | undefined}
    >
      <Tooltip
        text="Notifications"
        position="left"
        className="!rounded-[4px] text-helper-text-sm"
      >
        <IconButton variant="ghost" icon="Notification" />
      </Tooltip>
      <div
        className={`${
          !unreadNotifications ? 'hidden' : 'visible'
        } w-[20px] h-[20px] p-2 text-xs shadow-lg font-medium bg-red text-white flex justify-center items-center absolute top-[-2px] right-[-5px] rounded-full text-nowrap`}
      >
        {unreadNotifications}
      </div>
      {isOpen && (
        <div
          className="absolute right-11 top-[10px] z-50"
          ref={ref as React.Ref<HTMLDivElement> | undefined}
        >
          <NotificationCenter />
        </div>
      )}
    </div>
  )
})

const OptionsCss =
  'flex gap-2 items-center px-[12px] py-[8px] cursor-pointer hover:bg-[#F2F4F5] text-[14px]'

const AccountButtonDropdown = () => {
  const { isOpen } = useDropdown()

  const navigate = useNavigate()
  const { onDisconnectWallet } = usePrivyHelper()

  const handleDisconnectWallet = async () => {
    await onDisconnectWallet()
    navigate('/create')
  }
  return (
    <div className={cn(styles.dropdown_items_wrapper, isOpen && styles.show)}>
      <NavLink
        className={cn(OptionsCss)}
        to="https://fileverse.io/portal"
        target="_blank"
      >
        <img className="w-[24px] h-[24px]" src={avatar} />
        <span>About Fileverse</span>
      </NavLink>
      <NavLink className={cn(OptionsCss)} to="/contracts">
        <AppWindow size="24px" />
        <span>My Portals</span>
      </NavLink>
      <div
        onClick={handleDisconnectWallet}
        className={cn(OptionsCss, 'text-[red]')}
      >
        <PowerOff size="24px" />
        <span>Disconnect</span>
      </div>
    </div>
  )
}

export default AccountSection

export const AccountButton = forwardRef(function AccountButton(
  {
    onAboutClick,
  }: {
    onAboutClick: () => void
  },
  ref
) {
  const account = usePrivyHelper().walletAddress as string
  const { toggle } = useDropdown()
  const { connectOrCreateWallet } = usePrivy()
  if (account) {
    return (
      <button
        ref={ref as React.Ref<HTMLButtonElement> | undefined}
        onClick={toggle}
        data-cy="account-section"
      >
        <Avatar size="lg" variant="square" />
        <AccountButtonDropdown />
      </button>
    )
  }

  return (
    <>
      <button
        onClick={connectOrCreateWallet}
        type="button"
        className="hidden"
      />
      <PrimaryButton onClick={() => onAboutClick()} title="About" />
    </>
  )
})
