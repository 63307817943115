import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../store/hooks'
import FileIcon from '../../assets/add_files_black.svg'
import {
  useActiveContractAddress,
  useContract,
} from '../../store/contract/hooks'
import { setActiveContractAddress } from '../../store/contract/reducer'
import arrowForward from '../../assets/arrowForward.svg'
import peopleIcon from '../../assets/peopleIcon.svg'
import { getLogoUrl } from '../../utils/getLogoUrl'
import { usePortalFilesCount } from '../../store/files/hooks'
import styles from './index.module.scss'
import cn from 'classnames'
import { useServerKeys } from '../../store/invoker/hooks'
import { checkIfOwnerOrCollaborator } from '../../utils/checkIfOwnerOrCollaborator'
interface ContractCardProps {
  invokerAddress: string
  contractAddress: string
}

const DEFAULT_IMAGE = '/logo192.png'

export const ContractCard = ({
  invokerAddress,
  contractAddress,
}: ContractCardProps) => {
  const dispatch = useAppDispatch()
  const activeContractAddress = useActiveContractAddress()
  const filesCount = usePortalFilesCount(contractAddress as string)
  const contract = useContract(contractAddress)
  const isOwner = contract?.owner === invokerAddress
  const [contractLogo, setContractLogo] = useState<string | undefined>('')
  const serverKeys = useServerKeys(
    invokerAddress as string,
    contractAddress as string
  )
  const isCollaborator = checkIfOwnerOrCollaborator(
    contract,
    invokerAddress,
    serverKeys
  )
  const isMember = contract?.members.some((member) => {
    return Number(member.invokerAddress) === Number(invokerAddress)
  })
  const navigate = useNavigate()
  const switchOrganization = async () => {
    dispatch(setActiveContractAddress(contractAddress))
    navigate(`/${contractAddress}?chainId=${contract?.chainId}`)
  }

  const permissionTag = () => {
    const permissionTagColor =
      isOwner || isCollaborator
        ? 'bg-yellow'
        : isMember
        ? 'bg-[#00FFFF]'
        : 'bg-[#AAAAAA]'
    const permissionTagText = isOwner
      ? 'Owner'
      : isCollaborator
      ? 'Collaborator'
      : isMember
      ? 'Member'
      : 'Visitor'
    return (
      <span
        className={`${permissionTagColor} text-xs px-4 py-1 rounded-lg flex justify-center items-center`}
      >
        {permissionTagText}
      </span>
    )
  }
  const getLogo = async () => {
    const fileUrl = await getLogoUrl(contract?.metadata.logo)
    if (fileUrl) {
      setContractLogo(fileUrl)
    }
  }
  useEffect(() => {
    getLogo()
  }, [contract])

  return (
    <div
      data-cy="portal-card"
      className={`border-[1.5px] rounded-xl  h-[20rem] flex flex-col cursor-pointer  ${
        activeContractAddress === contractAddress
          ? 'bg-[#FFF9CE] border-[#FFDF0A]'
          : 'color-border-default'
      }`}
      onClick={switchOrganization}
    >
      <div className="h-full w-full p-4 flex flex-col gap-[.4rem]">
        <div className={styles.contract_card_head}>
          <ContractImg contractLogo={contractLogo} />
          <div className="h-full flex items-center ml-2 ">
            {' '}
            {permissionTag()}
          </div>
          <img src={arrowForward} className={styles.arrow_img} />
        </div>
        <div className="w-full h-full flex flex-col">
          <div className="font-semibold text-[#000000] text-xl">
            {contract?.metadata.name || 'Fileverse'}
          </div>
          <div className="flex text-xs mt-[.4rem]">
            <div className="text-[#777777] mr-1">Contract:</div>
            <div className="font-semibold text-[#000000] truncate">
              {contractAddress}
            </div>
          </div>
          <div className="flex mt-auto h-[45px]">
            <div className=" w-[40%] border-[1px] border-[#AAAAAA] rounded-lg flex px-4 py-2 items-center justify-center">
              <img src={peopleIcon} />
              <div className="ml-2 font-semibold text-sm text-[#333333] truncate">
                {contract?.members.length} Members
              </div>
            </div>
            <div className="w-[30%] ml-4 border-[1px] px-2 border-[#AAAAAA] rounded-lg flex  items-center justify-center">
              <img src={FileIcon} className="w-4" />
              <div className="ml-2 font-semibold text-sm text-[#333333] truncate">
                {filesCount} files
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const ContractImg = ({
  contractLogo,
}: {
  contractLogo: string | undefined
}) => {
  const [imgSrc, setImgSrc] = useState<string | undefined>(DEFAULT_IMAGE)
  const onImageLoadError = () => setImgSrc(DEFAULT_IMAGE)

  useEffect(() => {
    if (contractLogo) {
      setImgSrc(contractLogo)
    }
  }, [contractLogo])
  return (
    <div className="h-full flex items-center">
      <img
        src={imgSrc}
        onError={onImageLoadError}
        alt=""
        className={cn('rounded-lg object-cover w-[6rem]', styles.contract_logo)}
      />
    </div>
  )
}
