import React, { useEffect, useState } from 'react'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'

import {
  useContractChainId,
  useContractMembers,
} from '../../../store/contract/hooks'
import { savePortalMemberList } from '../../../store/contract/reducer'
import { getPortalMembers } from '../../../api/member/getPortalMembers'
import { useAppDispatch } from '../../../store/hooks'
// import ShortcutsAndPlugins from '../../../components/ShortcutsAndPlugins'
import { FileStateInLocation } from '../../../components/DashboardLayout'
import { WarningModal } from '../../../components/WarningModal'
import { clearTaskQueue } from '../../../store/taskQueue'
// import { useQueueStatus } from '../../../store/taskQueue/hooks'
// import { QueueStatus } from '../../../store/taskQueue/reducer'
import { HomeUploadDropZone } from '../../../components'
import { CreateSection, FileSearchResult, RecentSection } from './components'
import { useFileSearchValue } from '../../../store/fileSearch/hooks'

import { PortalActionBar } from './components'
import { usePrivyHelper } from '../../../hooks/usePrivyHelper'

export const Home = () => {
  const { address: contractAddress } = useParams()
  const walletAddress = usePrivyHelper().walletAddress
  const portalMembers = useContractMembers(contractAddress as string)
  const contractChainId = useContractChainId(contractAddress as string)
  const [showWarningModal, setShowWarningModal] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pendingNavigationUrl, _setPendingNavigationUrl] = useState<string>('')
  const navigate = useNavigate()
  const fileSearchValue = useFileSearchValue()

  // const queueStatus = useQueueStatus()

  const onCloseModal = () => {
    setShowWarningModal(false)
  }

  const onConfirm = () => {
    dispatch(clearTaskQueue())
    navigate(pendingNavigationUrl)
    setShowWarningModal(false)
  }

  // const onBeforeNavigate = (navigateTo: string) => {
  //   if (queueStatus === QueueStatus.Idle) navigate(navigateTo)
  //   else {
  //     setShowWarningModal(true)
  //     setPendingNavigationUrl(navigateTo)
  //   }
  // }

  const [pendingUploadedFileState, setPendingUploadedFileState] =
    useOutletContext<FileStateInLocation>()
  const dispatch = useAppDispatch()

  const queryPortalMembers = async () => {
    const memberQuery = await getPortalMembers({
      contractAddress: contractAddress as string,
      contractChainId: contractChainId as number,
      invokerAddress: walletAddress as string,
    })
    if (memberQuery.data.members.length !== portalMembers.length) {
      dispatch(
        savePortalMemberList({
          contractAddress: contractAddress as string,
          members: memberQuery.data.members,
        })
      )
    }
  }

  useEffect(() => {
    queryPortalMembers()
    return () => {
      if (pendingUploadedFileState) {
        setPendingUploadedFileState(undefined)
      }
    }
  }, [])

  return (
    <div
      data-cy="home-section"
      className="w-full h-[100%] flex flex-col gap-[32px] min-h-[650px] max-h-[88vh] "
    >
      {fileSearchValue.length < 3 ? <HomePage /> : <FileSearchResult />}
      {showWarningModal && (
        <WarningModal
          isOpen={showWarningModal}
          onClose={onCloseModal}
          onConfirm={onConfirm}
        />
      )}
    </div>
  )
}

const HomePage = () => {
  return (
    <div className="flex flex-col gap-[32px]">
      <PortalActionBar />
      <HomeUploadDropZone />
      <CreateSection />
      <RecentSection />
    </div>
  )
}
