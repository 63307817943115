/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'

import {
  cn,
  formatCounter,
  Card,
  CardContent,
  CardFooter,
  CardProps,
  TextAreaField,
  ButtonGroup,
  Button,
  Icon,
} from '@fileverse/ui'
import { usePublicPortalContext } from '../../../../providers/PublicPortalProvider'
import { motion } from 'framer-motion'
import { animationConfig } from '../CardControlWrapper'

export const TextBlockCard = ({
  type,
  size,
  text,
  likeCount,
  canShare,
  classNames,
  id,
  noFooter,
  onLike,
}: CardProps & { noFooter?: boolean; onLike?: (e: any) => void }) => {
  const { onUpdateTextFileContent, isViewMode } = usePublicPortalContext()

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault()
    onUpdateTextFileContent(id as string, e.target.value)
  }
  return (
    <Card
      type={type}
      size={size}
      className={cn('flex flex-col justify-between gap-2', classNames)}
    >
      <motion.div
        className="w-full h-full transition-opacity absolute top-0 left-0 p-5"
        layout="position"
        {...animationConfig}
      >
        <motion.div
          layout="position"
          className="transition-opacity w-full h-[calc(100%-1rem)]"
          {...animationConfig}
        >
          <CardContent className={cn('h-full rounded-lg w-full p-0')}>
            <TextAreaField
              className={cn(
                'w-full h-full bg-transparent focus:outline-none focus:ring-0 focus:shadow-none resize-none border-none shadow-none p-0 text-body-sm color-text-default overflow-hidden hover:overflow-y-auto transition-opacity duration-200 ease-in-out'
              )}
              placeholder="Add notes"
              value={text}
              onChange={handleTextChange}
              readOnly={isViewMode}
            />
          </CardContent>
        </motion.div>
        {!noFooter && (
          <CardFooter className="justify-start h-4">
            <motion.div
              layout="position"
              className="flex justify-between items-center w-full transition-opacity"
              {...animationConfig}
            >
              <ButtonGroup className="space-x-0 gap-3">
                <Button
                  onClick={onLike}
                  variant="ghost"
                  size="md"
                  className="min-w-fit p-0"
                >
                  <Icon name="Heart" size="sm" fill="black" className="mr-1" />
                  <span className="max-w-fit">
                    {likeCount && likeCount >= 1
                      ? formatCounter(likeCount)
                      : 'Like'}
                  </span>
                </Button>
                {canShare && (
                  <Button
                    variant="ghost"
                    size="md"
                    className="min-w-fit p-0 inline-flex lg:hidden"
                  >
                    <Icon
                      name="Share2"
                      size="sm"
                      fill="black"
                      className="mr-1"
                    />
                    Share
                  </Button>
                )}
              </ButtonGroup>
            </motion.div>
          </CardFooter>
        )}
      </motion.div>
    </Card>
  )
}
