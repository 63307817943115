import React, { Dispatch, SetStateAction } from 'react'
import { PrivateMobileHeader, PublicMobileHeader } from './DynamicMobileHeader'
import RegisterCollaboratorPopup from '../../Popup/RegisterCollaboratorPopup'
import RegularMobileHeader from './RegularMobileHeader'
import useHeaderHook from '../../../hooks/useHeaderHook'

const MainMobileHeader = ({
  isPortalSectionOpen,
  setPortalSectionVisibility,
  isPrivate,
}: {
  isPortalSectionOpen?: boolean
  setPortalSectionVisibility?: Dispatch<SetStateAction<boolean>>
  isPrivate?: boolean
}) => {
  const hashPath = window.location.hash
  const isManifestoPage =
    hashPath.substring(hashPath.indexOf('/')) === '/manifesto'

  const {
    isRegisterCollaboratorPopupVisible,
    registerCollaboratorRef,
    handleDisconnectWallet,
    setRegisterCollaboratorPopup,
  } = useHeaderHook()

  if (isManifestoPage) {
    return <RegularMobileHeader />
  }

  return (
    <div className="w-full">
      {isPrivate ? (
        <PrivateMobileHeader handleDisconnectWallet={handleDisconnectWallet} />
      ) : (
        <PublicMobileHeader
          isPortalSectionOpen={!!isPortalSectionOpen}
          setPortalSectionVisibility={setPortalSectionVisibility}
        />
      )}

      {isRegisterCollaboratorPopupVisible && (
        <RegisterCollaboratorPopup
          isPopUpVisible={isRegisterCollaboratorPopupVisible}
          collaboratorPopUpRef={registerCollaboratorRef}
          setPopupVisibility={setRegisterCollaboratorPopup}
        />
      )}
    </div>
  )
}

export default MainMobileHeader
