/* eslint-disable react/prop-types */
import React from 'react'
import cn from 'classnames'

export default function FileLoadingCard({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div className="w-full border-b border-[#e8ebec] " {...props}>
      <div
        className={cn(
          'animate-pulse rounded-md bg-black/10 py-3 my-4',
          className
        )}
      />
    </div>
  )
}
