/* eslint-disable no-constant-condition */
import { Tooltip, useMediaQuery } from '@mui/material'
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'
import task_alt from '../../assets/task_alt.svg'
import flare from '../../assets/flare.svg'
import lockedIcon from '../../assets/lockedIcon.svg'
import whiteFlare from '../../assets/white_flare.svg'
import coin from '../../assets/coin.png'
import { taskIcon } from './TasksModal'
import { ITask, TaskID } from './TasksProvider'
import uuid from 'react-uuid'
import cn from 'classnames'
import styles from './Tasks.module.scss'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { useTasksContext } from '../../hooks/useTasksContext'

const TasksAccordian = ({
  isCompleted,
  tasks,
  shouldAnimateShadow,
  tasksHeaderText,
  lockedDescription,
}: {
  isCompleted: boolean
  tasks: ITask[]
  shouldAnimateShadow: boolean
  tasksHeaderText: string
  lockedDescription: string
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const isMediaMax1025px = useMediaQuery('(max-width : 1025px)')

  useEffect(() => {
    if (isCompleted && isOpen) {
      setIsOpen(false)
    }
  }, [])
  return (
    <div
      className={cn('w-[100%]', 'relative', 'group', {
        'mt-4': isMediaMax1025px,
        'object-scale-down': true,
      })}
      id="accordion-collapse"
      data-accordion="collpase"
    >
      <div className="relative bg-white rounded-lg">
        <TaskAccordianHeader
          isCategoryCompleted={isCompleted}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          tasksHeaderText={tasksHeaderText}
          shouldAnimateShadow={shouldAnimateShadow}
        />
        {isOpen && (
          <TaskAccordianBody
            tasks={tasks}
            isActiveCategory={shouldAnimateShadow}
            lockedDescription={lockedDescription}
          />
        )}
      </div>
    </div>
  )
}

export default TasksAccordian

export const TaskAccordianHeader = ({
  setIsOpen,
  isOpen,
  isCategoryCompleted,
  shouldAnimateShadow,
  tasksHeaderText,
}: {
  setIsOpen: Dispatch<SetStateAction<boolean>>
  isOpen: boolean
  isCategoryCompleted: boolean
  shouldAnimateShadow: boolean
  tasksHeaderText: string
}) => {
  const isMediaMax1025px = useMediaQuery('(max-width : 1025px)')
  return (
    <h2
      id="accordion-open-heading-1"
      className={` ${
        !isOpen &&
        !isCategoryCompleted &&
        shouldAnimateShadow &&
        'animate-shadow'
      } rounded-lg `}
    >
      <button
        onClick={() => !isCategoryCompleted && setIsOpen(!isOpen)}
        type="button"
        className={cn(
          'flex',
          { 'rounded-t-lg': isOpen, 'rounded-lg': !isOpen },
          'items-center justify-between w-full p-5 font-medium text-left text-[#000000] border',
          { 'text-sm': isMediaMax1025px },
          'bg-[#F1F1F1]'
        )}
        data-accordion-target="#accordion-open-body-1"
        aria-expanded="true"
        aria-controls="accordion-open-body-1"
      >
        {isCategoryCompleted ? (
          <div className="w-full justify-center flex">
            <div className="flex gap-4 items-center">
              <h2 className="text-[#1BAC2A]">Completed</h2>
              <img src={task_alt} />
            </div>
          </div>
        ) : (
          <>
            <span className="flex text-[#6B7280] text-base font-medium items-center">
              {tasksHeaderText}
            </span>
            <svg
              data-accordion-icon
              className="w-3 h-3 rotate-180 shrink-0"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 5 5 1 1 5"
              />
            </svg>
          </>
        )}
      </button>
    </h2>
  )
}
export const TaskAccordianBody = ({
  tasks,
  isActiveCategory,
  lockedDescription,
}: {
  tasks: ITask[]
  isActiveCategory: boolean
  lockedDescription: string
}) => {
  const getActiveIndex = (_tasks: ITask[]) => {
    for (const taskIndex in _tasks) {
      if (!_tasks[taskIndex].completed) {
        return taskIndex
      }
    }
  }
  const activeIndex = getActiveIndex(tasks)

  const [totalHeight, setTotalHeight] = useState(0)
  const parentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (parentRef.current) {
      const children = parentRef.current.children
      let height = 0
      for (let i = 0; i < children.length / 2; i++) {
        const child = children[i] as HTMLElement
        height += child.offsetHeight
      }
      setTotalHeight(height)
    }
  }, [])
  return (
    <div
      className="w-full"
      id="accordion-open-body-1"
      aria-labelledby="accordion-open-heading-1"
    >
      <div
        ref={parentRef}
        style={{ height: `${totalHeight + 200}px` }}
        className={cn(styles.fakemasonry)}
      >
        {tasks.map((task, id) => {
          return (
            <TaskCard
              key={task.taskId}
              task={task}
              isActiveCategory={isActiveCategory}
              isActive={activeIndex === id.toString()}
              lockedDescription={lockedDescription}
            />
          )
        })}
      </div>
    </div>
  )
}

export const TaskCard = ({
  task,
  isActive,
  isActiveCategory,
  lockedDescription,
}: {
  task: ITask
  isActive: boolean
  isActiveCategory: boolean
  lockedDescription: string
}) => {
  const { completed, taskId, name, points, category } = task
  const [searchParams] = useSearchParams()
  const taskState = useTasksContext()
  const chainId = parseInt(searchParams.get('chainId') || '')

  const navigate = useNavigate()

  const getRedirectionLink = () => {
    const rtcId = uuid()
    const { address: contractAddress } = useParams()
    switch (taskId) {
      case TaskID.CREATE_PUBLISH_3_DPAGES: {
        return `/${contractAddress}/dPages?chainId=${chainId}&task=${task?.taskId}`
      }
      case TaskID.PRIVATE_KEY_DOWNLOAD: {
        return `/${contractAddress}/settings?chainId=${chainId}`
      }
      case TaskID.PUBLISH_PRIVATE_WHITEBOARD: {
        return `/${contractAddress}/whiteboard/${rtcId}?chainId=${chainId}`
      }
      case TaskID.PUBLISH_10_PUBLIC_WHITEBOARDS: {
        return `/${contractAddress}/whiteboard/${rtcId}?chainId=${chainId}`
      }
      case TaskID.PUBLISH_PUBLIC_DPAGE: {
        return `/${contractAddress}/dPages?chainId=${chainId}&task=${task?.taskId}`
      }
      case TaskID.UPLOAD_PRIVATE_FILE: {
        return `/${contractAddress}?chainId=${chainId}`
      }
      case TaskID.UPLOAD_PUBLIC_FILE: {
        return `/${contractAddress}?chainId=${chainId}`
      }
      case TaskID.CHAT_ON_FILE: {
        return `/${contractAddress}/files?chainId=${chainId}`
      }
      case TaskID.COMMENT_ON_10_FILES: {
        return `/${contractAddress}/files?chainId=${chainId}`
      }
      case TaskID.INVITE_PORTAL_COLLABORATOR: {
        return `/${contractAddress}/people?chainId=${chainId}`
      }
      case TaskID.TRY_LIVE_COLLABORATION: {
        return `/${contractAddress}/document/${rtcId}?chainId=${chainId}&version=2`
      }
      case TaskID.PUBLISH_PUBLIC_DDOC: {
        return `/${contractAddress}/document/${rtcId}?chainId=${chainId}&version=2`
      }
      case TaskID.CREATE_PUBLISH_3_DDOCS: {
        return `/${contractAddress}/document/${rtcId}?chainId=${chainId}&version=2`
      }
      case TaskID.PUBLISH_10_PUBLIC_DDOCS: {
        return `/${contractAddress}/document/${rtcId}?chainId=${chainId}&version=2`
      }
      case TaskID.PARTICIPATE_PORTAL_CHAT: {
        return `/${contractAddress}?chainId=${chainId}`
      }
      case TaskID.SHARE_TWITTER: {
        return 'https://twitter.com/intent/tweet?url=https%3A%2F%2Fportal.fileverse.io%2F%23%2F0x624DEF970137c7B55a143b52B48f3C8f9161604d%2Ffile%2F18%3FchainId%3D100&text=I%20am%20exploring%20onchain%20some%20of%20@fileverse%20collaboration%20tools.%20Here%20is%20a%20decentralised%20web%20page%20called%20dPages%21'
      }
      case TaskID.SHARE_LENSTER: {
        return 'https://lenster.xyz/?text=I%20am%20exploring%20onchain%20some%20of%20@fileverse%20collaboration%20tools.%20Here%20is%20a%20decentralised%20web%20page%20called%20dPages%21&url=https%3A%2F%2Fportal.fileverse.io%2F%23%2F0x624DEF970137c7B55a143b52B48f3C8f9161604d%2Ffile%2F18%3FchainId%3D100'
      }
      case TaskID.EDIT_PUBLIC_PORTAL: {
        return `/${contractAddress}/member?chainId=${chainId}`
      }
      default: {
        return ''
      }
    }
  }
  const link = getRedirectionLink()
  const handleVerifyTask = () => {
    if (completed || category !== 'ONCHAIN') return
    taskState?.verifyTask({ taskId, isOnchainTask: true, name })
    taskState?.setShowTaskList(false)
    setTimeout(() => taskState?.setShowTaskList(true), 5000)
  }
  const handleStartTask = () => {
    const externalTask = [TaskID.SHARE_TWITTER, TaskID.SHARE_LENSTER]

    if (category === 'ONCHAIN') {
      handleVerifyTask()
      return
    }
    if (!link) return
    if (externalTask.includes(taskId)) {
      window.open(link, '_blank')
      setTimeout(() => taskState?.markTaskAsDone({ taskId, name }), 5000)
      taskState?.setShowTaskList(false)
    } else {
      taskState?.setShowTaskList(false)
      navigate(link, { replace: true })
    }
  }
  const handleCardClick = () => {
    if (category !== 'ONCHAIN' && isActiveCategory) {
      handleStartTask()
    }
  }

  return (
    <div
      className={cn(
        { 'animate-shadow': isActive && isActiveCategory },
        { 'border-[#1BAC2A]': task.completed && isActiveCategory },
        'relative border rounded-lg flex flex-col gap-4 p-4',
        styles.item
      )}
    >
      <div
        onClick={handleCardClick}
        className={cn(
          {
            'cursor-pointer': category !== 'ONCHAIN',
          },
          {
            'cursor-pointer': isActiveCategory,
          },
          'w-full'
        )}
      >
        <div className="w-full flex justify-center pt-6">
          {isActiveCategory && isActive && (
            <img src={coin} className="animate-bounce w-[80px]" />
          )}
        </div>

        <div className="flex justify-center items-center gap-2">
          <img src={taskIcon[task.taskId]} className="w-[41px]" />
          <p className="font-bold">{task.name}</p>
        </div>
      </div>

      <div className="flex w-full justify-between">
        <div
          className={`flex rounded-[4px] justify-evenly ${
            !isActiveCategory || !task.completed
              ? 'border border-yellow'
              : 'bg-yellow'
          } px-4 py-1 gap-2 `}
        >
          <div className="font-bold">+{points}</div>
          {!isActiveCategory || !task.completed ? (
            <img src={flare} className="w-[22px]" />
          ) : (
            <img src={whiteFlare} className="w-[22px]" />
          )}
        </div>
        {!isActiveCategory || !task.completed ? (
          <Tooltip
            title={!isActiveCategory && lockedDescription}
            placement="bottom"
          >
            <button
              disabled={!isActiveCategory}
              onClick={() => handleStartTask()}
              className={`px-4 ${
                !isActiveCategory && 'cursor-not-allowed'
              } py-1 bg-yellow flex items-center gap-2 rounded-[4px] font-medium`}
            >
              <span>Start</span>
              {!isActiveCategory && <img src={lockedIcon} />}
            </button>
          </Tooltip>
        ) : (
          <div className="flex gap-4 items-center">
            <h2 className="text-[#1BAC2A]">Completed</h2>
            <img src={task_alt} />
          </div>
        )}
      </div>
    </div>
  )
}
