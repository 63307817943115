/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'

export const Checkbox = ({
  checked,
  onCheckedChange,
}: {
  checked?: boolean
  onCheckedChange: (e?: any) => void
}) => {
  return (
    <div className="flex w-fit">
      <input
        className="relative cursor-pointer peer shrink-0 appearance-none peer h-[18px] w-[18px] rounded-sm border-2 border-primary checked:bg-black checked:border-0 checked:text-primary-foreground shadow focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-black disabled:cursor-not-allowed disabled:opacity-50"
        type="checkbox"
        onChange={onCheckedChange}
        checked={checked}
      />
      <svg
        className="absolute h-[18px] w-[18px] pointer-events-none hidden peer-checked:block stroke-white outline-none p-[2px]"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <polyline points="20 6 9 17 4 12"></polyline>
      </svg>
    </div>
  )
}
