/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import Spinner from '../Spinner'
import { Tooltip } from '@mui/material'

interface ButtonType {
  title: string
  icon?: any
  iconStyles?: string
  onClick?: (event?: any) => void
  isDisabled?: boolean
  isLoading?: boolean
  backgroundColorClassName?: string
  tooltip?: string
}
const PrimaryButton = ({
  title,
  icon,
  isDisabled,
  iconStyles,
  onClick,
  isLoading,
  backgroundColorClassName,
  tooltip,
}: ButtonType) => {
  return (
    <Tooltip title={tooltip}>
      <button
        onClick={(event) => {
          if (onClick && !isLoading) onClick(event)
        }}
        disabled={isDisabled}
        className={`${
          isDisabled
            ? 'opacity-50 bg-gray-400 cursor-not-allowed'
            : `${
                backgroundColorClassName
                  ? backgroundColorClassName
                  : 'bg-black text-white'
              }`
        } flex items-center font-bold min-w-[100px] justify-center box-border px-6 py-2 h-10 rounded-md`}
      >
        {isLoading ? (
          <Spinner />
        ) : (
          <div className="flex gap-2 justify-center items-center">
            {icon && (
              <img className={`${iconStyles}`} src={icon} alt="button icon" />
            )}
            {title}
          </div>
        )}
      </button>
    </Tooltip>
  )
}

export default PrimaryButton
