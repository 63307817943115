/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import ProcessDoneIcon from '../../assets/check3.svg'
import FillingLoader from './UploadBar/FillingLoader'
import { useMediaQuery } from '@mui/material'
import cn from 'classnames'

const ActionButton = ({
  onclick,
  title,
  isLoading,
  icon,
  loadingText,
  actionSuccess,
  isDisabled,
  dataCy,
  buttonColor,
  customFillerWidth,
}: {
  onclick: any
  title: string
  isLoading?: boolean
  icon?: any
  loadingText?: string
  actionSuccess?: boolean
  isDisabled?: boolean
  dataCy?: string
  buttonColor?: string
  customFillerWidth?: string
}) => {
  const isMediaMax1025px = useMediaQuery('(max-width : 1025px)')
  if (isLoading) {
    return (
      <FillingLoader
        fillingText={`${loadingText || 'Loading'}...`}
        width={cn({
          customFillerWidth: customFillerWidth,
          'max-w-[100px]': isMediaMax1025px && !customFillerWidth,
          'min-w-[174px]': !isMediaMax1025px && !customFillerWidth,
        })}
        height="h-[45px]"
      />
    )
  }
  return (
    <div
      className={` h-[45px] ${
        isMediaMax1025px ? 'min-w-[100px]' : 'min-w-[174px]'
      }`}
    >
      {actionSuccess ? (
        <div className="w-full flex justify-center items-center h-full">
          <img src={ProcessDoneIcon} className="scale-[80%]" />
        </div>
      ) : (
        <button
          data-cy={dataCy}
          className={`flex w-full  ${
            isDisabled
              ? 'bg-[#D9D9D9] text-[#000000] cursor-not-allowed'
              : buttonColor
              ? `${buttonColor} cursor-pointer`
              : 'bg-black text-white cursor-pointer'
          } ease-in duration-200 ${
            !isDisabled && 'hover:scale-[1.03]'
          } items-center justify-center p-4 rounded-lg h-11`}
          onClick={() => {
            !isDisabled && onclick()
          }}
        >
          {icon && <img src={icon} alt="Copy" className="mr-4 w-5" />}
          <h6 className=" font-normal">{title}</h6>
        </button>
      )}
    </div>
  )
}

export default ActionButton

// export const SmallActionButton = ({
//   onclick,
//   title,
//   isLoading,
//   icon,
//   loadingText,
//   actionSuccess,
//   isDisabled,
//   dataCy,
//   buttonColor,
// }: {
//   onclick: any
//   title: string
//   isLoading?: boolean
//   icon?: any
//   loadingText?: string
//   actionSuccess?: boolean
//   isDisabled?: boolean
//   dataCy?: string
//   buttonColor?: string
// }) => {
//   if (isLoading) {
//     return (
//       <div>
//         <ButtonLoader text={`${loadingText || 'Loading'}...`} />
//       </div>
//     )
//   }
//   return (
//     <div className="min-w-[120px]">
//       <button
//         data-cy={dataCy}
//         className={`flex w-full  ${
//           isDisabled || actionSuccess
//             ? 'bg-[#D9D9D9] text-[#000000] cursor-not-allowed'
//             : buttonColor
//             ? `${buttonColor} cursor-pointer`
//             : 'bg-black text-white cursor-pointer'
//         } ease-in duration-200 ${
//           !isDisabled && 'hover:scale-[1.03]'
//         } items-center justify-center py-2 rounded-[12px]`}
//         onClick={() => {
//           !isDisabled && !actionSuccess && onclick()
//         }}
//       >
//         {icon && <img src={icon} alt="Copy" className="mr-4 w-5" />}
//         <h6 className=" text-[15px] font-normal">{title}</h6>
//       </button>
//     </div>
//   )
// }
