/* eslint-disable @typescript-eslint/no-explicit-any */
import * as ucans from 'ucans'
import {
  generateISEAKey,
  generateRSAKeyPair,
  generateSHA256Hash,
} from './libCrypto'
import { convertTypedArrayToString } from './string'
import { SecretFile } from '../types/interface/file.interface'
import { IKeyVerifiers } from '../types/interface/ikeyverifiers'
import { getPortalContract, getRegistryContract } from './contract'
import { PortalContractEventEnum } from './transaction/interface'
import { InvalidPortalAddressError } from './errors'

const MAX_ALLOWED_ATTEMPS = 6

import { getDefaultProvider } from './ethUtils'
import { getPortalCreationInfo } from '../hooks/usePortalCreationTimeStampHook'
import { GNOSIS_MAX_QUERY_RANGE } from './constants'

export const generateInvokerCredentials = async () => {
  const editKeyPair = await ucans.EdKeypair.create({ exportable: true })
  const viewKeyPair = await ucans.EdKeypair.create({ exportable: true })
  const editSecret = await editKeyPair.export()
  const viewSecret = await viewKeyPair.export()
  const editDid = editKeyPair.did()
  const viewDid = viewKeyPair.did()

  const serverKeys = await generateRSAKeyPair() // Generate RSA public and private keys
  const memberKeys = await generateRSAKeyPair()
  const ownerKeys = await generateRSAKeyPair()

  // export RSA public key
  const collaboratorPublicKey = await window.crypto.subtle.exportKey(
    'spki',
    serverKeys.publicKey
  )
  const memberPublicKey = await window.crypto.subtle.exportKey(
    'spki',
    memberKeys.publicKey
  )
  const ownerPublicKey = await window.crypto.subtle.exportKey(
    'spki',
    ownerKeys.publicKey
  )

  // export RSA private key
  const collaboratorPrivateKey = await window.crypto.subtle.exportKey(
    'pkcs8',
    serverKeys.privateKey
  )
  const memberPrivateKey = await window.crypto.subtle.exportKey(
    'pkcs8',
    memberKeys.privateKey
  )
  const ownerPrivateKey = await window.crypto.subtle.exportKey(
    'pkcs8',
    ownerKeys.privateKey
  )

  // Convert both keys to string
  const collaboratorPrivateKeyString = convertTypedArrayToString(
    new Uint8Array(collaboratorPrivateKey)
  )
  const collaboratorPublicKeyString = convertTypedArrayToString(
    new Uint8Array(collaboratorPublicKey)
  )
  const memberPrivateKeyString = convertTypedArrayToString(
    new Uint8Array(memberPrivateKey)
  )
  const memberPublicKeyString = convertTypedArrayToString(
    new Uint8Array(memberPublicKey)
  )
  const ownerPublicKeyString = convertTypedArrayToString(
    new Uint8Array(ownerPublicKey)
  )
  const ownerPrivateKeyString = convertTypedArrayToString(
    new Uint8Array(ownerPrivateKey)
  )
  const portalGunKey = await generateISEAKey()

  // Save converted keys as secret-file data
  return {
    viewSecret,
    editSecret,
    portalEncryptionKey: collaboratorPublicKeyString,
    portalDecryptionKey: collaboratorPrivateKeyString,
    memberEncryptionKey: memberPublicKeyString,
    memberDecryptionKey: memberPrivateKeyString,
    ownerEncryptionKey: ownerPublicKeyString,
    ownerDecryptionKey: ownerPrivateKeyString,
    editDID: editDid,
    viewDID: viewDid,
    editKeyPair,
    portalGunKey,
  }
}

export const generateSecretFileContent = async (
  chainId: number,
  owner: string
) => {
  const credentials = await generateInvokerCredentials()
  return {
    chainId,
    owner,
    ...credentials,
    agentKey: '',
    agentAddress: '',
  }
}

export const generatePortalKeysVerifiers = async (
  secretFileContent: SecretFile
): Promise<IKeyVerifiers> => {
  const portalEncryptionKeyVerifier = await generateSHA256Hash(
    secretFileContent.portalEncryptionKey
  )
  const portalDecryptionKeyVerifier = await generateSHA256Hash(
    secretFileContent.portalDecryptionKey
  )
  const memberEncryptionKeyVerifer = await generateSHA256Hash(
    secretFileContent.memberEncryptionKey
  )
  const memberDecryptionKeyVerifer = await generateSHA256Hash(
    secretFileContent.memberDecryptionKey
  )

  return {
    portalEncryptionKeyVerifier,
    portalDecryptionKeyVerifier,
    memberEncryptionKeyVerifer,
    memberDecryptionKeyVerifer,
  }
}

export const generatePortalData = async (chainId: number, owner: string) => {
  const secretFileContent = await generateSecretFileContent(chainId, owner)
  const keyVerifiers = await generatePortalKeysVerifiers(secretFileContent)
  return {
    secretFileContent,
    keyVerifiers,
  }
}

export function sleep(ms = 1000): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const getEditedFilesEventFromContract = async (
  portalAddress: string,
  fromBlock?: string | number
) => {
  const portalContract = getPortalContract(portalAddress)
  if (fromBlock)
    return await portalContract.queryFilter(
      PortalContractEventEnum.EDITED_FILE,
      fromBlock,
      'latest'
    )

  const provider = getDefaultProvider()

  const { blockNumber: contractBlockNumber } = await getPortalCreationInfo(
    portalAddress
  )
  const latestBlockNumber = await provider.getBlockNumber()
  const blockaRangeDifference = latestBlockNumber - contractBlockNumber

  const isBlockRangeGreaterThanMax =
    blockaRangeDifference > GNOSIS_MAX_QUERY_RANGE
  if (!isBlockRangeGreaterThanMax) {
    const events = await portalContract.queryFilter(
      PortalContractEventEnum.EDITED_FILE,
      contractBlockNumber,
      latestBlockNumber
    )

    return events
  }
  let endBlock = latestBlockNumber
  while (endBlock >= contractBlockNumber) {
    let startBlock = endBlock - GNOSIS_MAX_QUERY_RANGE
    if (startBlock <= contractBlockNumber) {
      startBlock = contractBlockNumber
    }
    const events = await portalContract.queryFilter(
      PortalContractEventEnum.EDITED_FILE,
      startBlock,
      endBlock
    )
    if (events.length > 0) {
      return events
    }
    endBlock = startBlock - 1
  }
}

export const getLatestEditedFiles = (allUpdates: Array<any>) => {
  const groupById = allUpdates.reduce((acc: Record<number, any>, cur: any) => {
    const fileIdNum = Number(cur?.args?.fileId)

    if (!fileIdNum && fileIdNum !== 0) return acc

    acc[fileIdNum] = cur
    return acc
  }, {})
  return Object.values(groupById)
}

export const getMostRecentUpdatedFilesFromContract = async (
  portalAddress: string,
  fromBlock?: string | number
) => {
  const editedFiles = await getEditedFilesEventFromContract(
    portalAddress,
    fromBlock
  )
  return getLatestEditedFiles(editedFiles || [])
}

export const getNewPortalInfo = async (portalAddress: string) => {
  const registryContract = getRegistryContract()
  let currentAttempt = 0

  while (currentAttempt < MAX_ALLOWED_ATTEMPS) {
    const portalInfo = await registryContract.portalInfo(portalAddress, {
      blockTag: 'latest',
    })
    const { tokenId } = portalInfo

    if (isNaN(Number(tokenId))) await sleep(5000)
    else return portalInfo

    currentAttempt++
  }

  throw new InvalidPortalAddressError(
    `Could not find portal info for portal address ${portalAddress}`
  )
}
