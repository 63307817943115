import React, { SetStateAction } from 'react'
import { ViewKeyModal } from '../onboarding/ViewKeyModal'
import { useInvokerCredentialsAndKeys } from '../../store/invoker/hooks'
import { useParams } from 'react-router-dom'
import useComponentVisibilty from '../../hooks/useVisibility'
import { downloadKeyFile } from '../../utils/dowloadSecretKey'
import { TaskID } from '../Tasks/TasksProvider'
import { useTasksContext } from '../../hooks/useTasksContext'
import { useMediaMax1025px } from '../../hooks/useMediaQueryHook'
import cn from 'classnames'
import styles from './KeysBanner.module.scss'
import { useContract } from '../../store/contract/hooks'
import { ButtonGroup } from '@fileverse/ui'
import { Button } from '../../pages/PublicPortal/components/Button'
import { usePrivyHelper } from '../../hooks/usePrivyHelper'

export default function KeysBanner({
  setShowKeysBanner,
}: {
  setShowKeysBanner: React.Dispatch<SetStateAction<boolean>>
}) {
  const urlParams = useParams()
  const walletAddress = usePrivyHelper().walletAddress
  const tasksContext = useTasksContext()
  const contractAddress = urlParams.address as string
  const portal = useContract(contractAddress as string)
  const invokerAddress = walletAddress || ''
  const secretFileData = useInvokerCredentialsAndKeys(
    invokerAddress,
    contractAddress as string
  )
  const {
    isComponentVisible: isSecretScreenActive,
    setIsComponentVisible: setSecretScreenActive,
  } = useComponentVisibilty(false)
  const isMediaMax1025px = useMediaMax1025px()

  const handleKeysDownload = () => {
    downloadKeyFile({
      secretFileData,
      contractAddress,
      portalName: portal.metadata.name,
    })
    if (!tasksContext?.isDownloadedKeys) {
      tasksContext?.markTaskAsDone({
        taskId: TaskID.PRIVATE_KEY_DOWNLOAD,
        name: 'Download your private keys',
      })
    }
    setShowKeysBanner(false)
  }
  return (
    <>
      <div
        className={cn(
          styles.banner,
          'color-bg-brand-light flex justify-center items-center px-4'
        )}
      >
        <div
          className={cn(` flex justify-center items-center  flex-wrap`, {
            'gap-1': isMediaMax1025px,
            'gap-3': !isMediaMax1025px,
          })}
        >
          <p
            className={cn('text-heading-xsm ', {
              'text-[12px]': isMediaMax1025px,
            })}
          >
            Important: Download your backup keys!
          </p>
          <ButtonGroup>
            <Button
              variant="secondary"
              className={cn('bg-transparent', {
                'text-[12px]  h-[25px] min-h-[25px]  w-[60px] min-w-[59px]':
                  isMediaMax1025px,
                'text-body-sm-bold  h-[30px] min-h-[30px]  w-[80px] min-w-[79px]':
                  !isMediaMax1025px,
              })}
              onClick={() => setSecretScreenActive(true)}
            >
              View
            </Button>
            <Button
              className={cn('color-text-inverse', {
                'text-[12px] h-[25px] min-h-[25px]  w-[70px] min-w-[70px]':
                  isMediaMax1025px,
                'text-body-sm-bold h-[30px] min-h-[30px] w-[90px] min-w-[90px]':
                  !isMediaMax1025px,
              })}
              onClick={handleKeysDownload}
            >
              Download
            </Button>
          </ButtonGroup>
        </div>
      </div>

      <ViewKeyModal
        fileData={secretFileData}
        setSecretScreenActive={setSecretScreenActive}
        isSecretScreenActive={isSecretScreenActive}
      />
    </>
  )
}
