/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from 'react'
import defaultCover from '../../assets/webpagescover.png'
import { decryptUsingAESKey, decryptUsingRSAKey } from '../../utils/crypto'
import sendNotifcation from '../../utils/notification'
import { NavLink, useSearchParams } from 'react-router-dom'
import { useServerKeys } from '../../store/invoker/hooks'
import { getUserDraftPermission } from '../../utils/collaboration/utils'
import { IDraft } from '../../types/interface/drafts.interface'
import { FileTypeEnum } from '../../types/enum/file.enum'
import { useCoverImage } from '../../hooks/useCoverImage'
import { getSlicedName } from '../../utils/string'
import { INewFile } from '../../types/interface/file.interface'
import { usePrivyHelper } from '../../hooks/usePrivyHelper'

const FileTypeTextMap = {
  [FileTypeEnum.GATED]: 'Gated',
  [FileTypeEnum.MEMBERS_PRIVATE]: 'Members',
  [FileTypeEnum.PRIVATE]: 'Collaborators',
  [FileTypeEnum.PUBLIC]: 'Public',
}

export const getDate = (timestamp: number, uploadedFile?: boolean) => {
  const time = uploadedFile ? timestamp * 1000 : timestamp
  const fullDate = new Date(time)
  const wordDate = fullDate.toDateString()
  const date = wordDate.split(' ')
  return `${date[2]} ${date[1]} ${date[3]}`
}

export const WebpagesDraftCard = ({
  draft,
  contractAddress,
  uploadedFileData,
  shadow,
  editedFiles,
}: {
  draft?: IDraft
  contractAddress: string
  uploadedFileData?: INewFile
  shadow?: boolean
  editedFiles?: string[]
}) => {
  const [coverImage, setCoverImage] = useState<string>('')
  const [urlParams] = useSearchParams()
  const chainId = parseInt(urlParams.get('chainId') || '')

  if (uploadedFileData) {
    const uploadedFilePath = `/${contractAddress}/file/${uploadedFileData.fileId}?chainId=${chainId}`
    const name = uploadedFileData?.metadata?.name
    const emoji = uploadedFileData?.metadata?.emoji
    const isDraft = editedFiles?.includes(uploadedFileData.fileId?.toString())
    const fileTypeText = FileTypeTextMap[uploadedFileData.metadata.fileType]

    useCoverImage({
      ipfsHash: uploadedFileData.metadata.coverIPFSHash,
      setCoverImage,
    })

    return (
      <NavLink to={uploadedFilePath} className={`w-[320px] h-[253px]`}>
        <div
          className={`cursor-pointer w-[320px] h-[253px] border border-[#E2E2E2] rounded-[8px] flex flex-col ${
            shadow && 'drop-shadow-md hover:scale-[1.03] ease-in duration-200'
          } `}
        >
          <img
            src={coverImage || defaultCover}
            alt="cover"
            className="object-cover w-auto h-[70%] rounded-[8px] rounded-b-none"
          />
          <div className="flex justify-between items-center mx-4 my-auto">
            <div className="flex flex-col gap-2 justify-evenly">
              <p className="m-0 font-semibold">
                {emoji && emoji}
                {'  '}
                {getSlicedName(name, 15)}
              </p>
              <small className="text-[#777777]">
                Last updated:{' '}
                {getDate(uploadedFileData.metadata.uploadedAt, true)}
              </small>
            </div>
            <div className="flex flex-col gap-1 items-end">
              <small className="bg-[#FFF9CE] rounded-full border border-[#e2e2e2] px-2 py-1">
                {fileTypeText}
              </small>
              {isDraft && (
                <small className=" w-fit bg-[#DB0000] text-[#ffffff] rounded-full border border-[#e2e2e2] px-2 py-1">
                  Draft
                </small>
              )}
            </div>
          </div>
        </div>
      </NavLink>
    )
  }

  const walletAddress = usePrivyHelper().walletAddress
  const serverKeys = useServerKeys(walletAddress as string, contractAddress)
  const [docKey, setDocKey] = useState<string>('')

  const {
    rtcId,
    name,
    portalLock,
    ownerLock,
    coverIPFSHash,
    timestamp,
    emoji,
  } = draft!
  const userDraftPermission = getUserDraftPermission(
    draft!,
    serverKeys,
    walletAddress as string
  )
  const lockedKey = userDraftPermission.owner ? ownerLock : portalLock
  const decryptionKey = userDraftPermission.owner
    ? serverKeys.ownerEncryptionKey
    : serverKeys.portalEncryptionKey

  const filePath = `/${contractAddress}/dPages/${rtcId}?chainId=${chainId}&key=${docKey}`

  useEffect(() => {
    ;(async () => {
      try {
        const key = await decryptUsingAESKey(lockedKey, decryptionKey)
        setDocKey(key)
      } catch (e) {
        /**
         * we try to decrypt draft(v1) key saved in gun using rsa decryption key
         */
        try {
          const rsaDecryptionKey = userDraftPermission.owner
            ? serverKeys.ownerDecryptionKey
            : serverKeys.portalDecryptionKey
          const key = await decryptUsingRSAKey(lockedKey, rsaDecryptionKey)
          setDocKey(JSON.parse(key))
        } catch (e) {
          sendNotifcation('Failed to decrypt draft key', '', 'danger')
        }
      }
    })()
  }, [])
  useCoverImage({
    ipfsHash: coverIPFSHash,
    setCoverImage,
  })

  return (
    <NavLink to={filePath} className={'w-[320px] h-[275px]'}>
      <div className="cursor-pointer w-full h-full border border-[#E2E2E2] rounded-[8px] flex flex-col">
        <img
          src={coverImage || defaultCover}
          alt="cover"
          className="object-cover w-auto h-[70%] rounded-[8px] rounded-b-none"
        />
        <div className="flex justify-between items-center mx-4 my-auto">
          <div className="flex flex-col gap-2 justify-evenly">
            <p className="m-0 font-semibold">
              {emoji && emoji}
              {'  '}
              {getSlicedName(name, 15)}
            </p>
            <small className="text-[#777777]">
              {timestamp && `Last updated: ${getDate(timestamp)}`}
            </small>
          </div>
          <small className="bg-[#DB0000] text-[#ffffff] rounded-full border border-[#e2e2e2] px-2 py-1">
            Draft
          </small>
        </div>
      </div>
    </NavLink>
  )
}
