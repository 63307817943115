import React from 'react'
import {
  EditorAlignment,
  EditorFontFamily,
  EditorList,
  fonts,
  LinkPopup,
  TextColor,
  TextHeading,
  TextHighlighter,
  useEditorToolbar,
} from './EditorUtils'
import { Editor } from '@tiptap/react'
import { IEditorTool } from '../../hooks/useVisibility'
import { ChevronDown } from 'lucide-react'
import cn from 'classnames'
import { Button } from '../../pages/PublicPortal/components/Button'
import { Divider } from '@fileverse/ui'
import { Tooltip } from '@mui/material'
// import { Tooltip } from '../../pages/PublicPortal/components/Tooltip'

const TiptapToolBar = ({ editor }: { editor: Editor }) => {
  const { toolRef, toolVisibilty, setToolVisibility, toolbar } =
    useEditorToolbar({
      editor: editor,
    })
  return (
    <div className="w-fit bg-transparent px-4 items-center h-16 flex gap-1 justify-center">
      <Button
        variant="ghost"
        className="bg-transparent gap-1 min-w-[55px]"
        onClick={() => setToolVisibility(IEditorTool.FONT_FAMILY)}
      >
        <span className="text-sm">
          {fonts.find((font) =>
            editor?.isActive('textStyle', { fontFamily: font.value })
          )?.title || 'Font'}
        </span>
        <ChevronDown size={16} />
      </Button>
      <Divider direction="vertical" size="lg" />
      <Button
        variant="ghost"
        className="bg-transparent gap-1 min-w-[55px]"
        onClick={() => setToolVisibility(IEditorTool.HEADING)}
      >
        <span className="text-sm">
          {editor?.isActive('heading', { level: 1 })
            ? 'Heading 1'
            : editor?.isActive('heading', { level: 2 })
            ? 'Heading 2'
            : editor?.isActive('heading', { level: 3 })
            ? 'Heading 3'
            : 'Text'}
        </span>
        <ChevronDown size={16} />
      </Button>
      <Divider direction="vertical" size="lg" />
      {toolbar.map((tool, index: number) => {
        if (tool) {
          return (
            <Tooltip
              placement="bottom"
              title={tool.title}
              key={tool.title}
              classes={{
                tooltip: 'bg-black text-white',
                arrow: 'text-black',
              }}
              arrow
            >
              <span
                onClick={() => tool.onClick()}
                className={cn(
                  'rounded w-8 h-8 p-1 flex cursor-pointer justify-center items-center',
                  tool.isActive
                    ? 'color-bg-brand'
                    : 'hover:color-button-secondary-hover'
                )}
              >
                {tool.icon}
              </span>
            </Tooltip>
          )
        } else {
          return <Divider key={index} direction="vertical" size="lg" />
        }
      })}
      {toolVisibilty === IEditorTool.FONT_FAMILY && (
        <EditorFontFamily
          setToolVisibility={setToolVisibility}
          editor={editor as Editor}
          elementRef={toolRef}
        />
      )}
      {toolVisibilty === IEditorTool.HEADING && (
        <TextHeading
          setVisibility={setToolVisibility}
          editor={editor as Editor}
          elementRef={toolRef}
        />
      )}
      {toolVisibilty === IEditorTool.TEXT_COLOR && (
        <TextColor
          setVisibility={setToolVisibility}
          editor={editor as Editor}
          elementRef={toolRef}
        />
      )}
      {toolVisibilty === IEditorTool.HIGHLIGHT && (
        <TextHighlighter
          setVisibility={setToolVisibility}
          editor={editor as Editor}
          elementRef={toolRef}
        />
      )}
      {toolVisibilty === IEditorTool.ALIGNMENT && (
        <EditorAlignment
          setToolVisibility={setToolVisibility}
          editor={editor as Editor}
          elementRef={toolRef}
        />
      )}
      {toolVisibilty === IEditorTool.LIST && (
        <EditorList
          setToolVisibility={setToolVisibility}
          editor={editor as Editor}
          elementRef={toolRef}
        />
      )}
      {toolVisibilty === IEditorTool.LINK && (
        <LinkPopup
          setToolVisibility={setToolVisibility}
          editor={editor as Editor}
          elementRef={toolRef}
        />
      )}
    </div>
  )
}

export default TiptapToolBar
