/* eslint-disable @typescript-eslint/no-explicit-any */
import gnosisIcon from '../assets/gnosis_icon.svg'
import ethereumLogo from '../assets/etherumLogo.svg'
import { ISupportedNetworks } from '../types/interface/config'
import { getNetworkId } from '../utils/networks/getNetworkId'
import { defineChain } from 'viem'
import { RPC_URL_MAP, SupportedNetworks } from '../constants'

export const networksConfig: ISupportedNetworks = {
  100: {
    name: 'Gnosis',
    chainId: 100,
    networkId: '0x64',
    forwarder: '0x3A0E542D1c1Ac4B7593b00d262424f28456b7FD2',
    registry: '0xCad72Ff216A5192bc2DB7412aca6C400d23Fc444',
    memberTokenAddress: '0x58318a309d762a28E0a40BB383c283DdD60bAa3C',
    heartMinterAddress: '0x5FeEf4DEDD03bd5368b72CfC2857168B10d930aa',
    logo: gnosisIcon,
    gasless: true,
    paymasterAddress: '',
    rpcUrl: RPC_URL_MAP[SupportedNetworks.Gnosis],
    autotask: 'https://p6u2zvdg5e.execute-api.eu-west-2.amazonaws.com/',
    networkMetadata: {
      chainId: '0x64',
      chainName: 'Gnosis Chain',
      nativeCurrency: {
        name: 'xDAI',
        symbol: 'xDAI',
        decimals: 18,
      },
      rpcUrls: [
        'https://rpc.gnosischain.com',
        'https://rpc.ankr.com/gnosis',
        'https://gnosischain-rpc.gateway.pokt.network',
        'https://gnosis-mainnet.public.blastapi.io',
        'wss://rpc.gnosischain.com/wss',
      ],
      blockExplorerUrls: ['https://gnosisscan.io/'],
    },
  },
  11155111: {
    name: 'Sepolia',
    chainId: 11155111,
    networkId: '0xaa36a7',
    forwarder: '0x31470b3126DD7cee5ee7591C2cb5142A68F57120',
    registry: '0x92e0bb8CFD97da712F366A350ff2B7A7873A62A2',
    memberTokenAddress: '0x104bddFD72777AD6c38D5FFE3ceAca20d3920b80',
    heartMinterAddress: '0x22DC4599B5e772aEB0BDAFE12e45FfB9B22653b9',
    logo: ethereumLogo,
    gasless: true,
    paymasterAddress: '',
    rpcUrl: RPC_URL_MAP[SupportedNetworks.Sepolia],
    autotask: 'https://dev-fileverse-relayer-15a18d7549d9.herokuapp.com/',
  },
  534351: {
    name: 'Scroll Sepolia',
    chainId: 534351,
    networkId: '0x8274f',
    forwarder: '0x9a52c2213602b11601c3a5f8d5b653438d68961b',
    registry: '0x30e4299192270de9cfea520e1e20732c2e27c745',
    memberTokenAddress: '0x359dad39f1667a3e3bc134a8138432ca651f1102',
    logo: ethereumLogo,
    gasless: false,
    paymasterAddress: '',
    rpcUrl: RPC_URL_MAP[SupportedNetworks.ScrollSepolia],
    autotask: 'https://5f4xjb3bdi.execute-api.eu-west-2.amazonaws.com/',
  },
}

const networkId = getNetworkId(process.env.REACT_APP_DEFAULT_NETWORK as string)
export const defaultNetwork = networksConfig[networkId]

type TChainConfigMap = {
  [key: number]: {
    chainId: string
    chainName: string
    rpcUrls: string[]
    blockExplorerUrls: string[]
    nativeCurrency: {
      name?: string
      symbol: string
      decimals: number
    }
  }
}

export const CHAIN_CONFIG_MAP: TChainConfigMap = {
  80001: {
    chainId: '0x13881',
    chainName: 'Matic Mumbai',
    rpcUrls: ['https://rpc-mumbai.maticvigil.com'],
    blockExplorerUrls: ['https://mumbai.polygonscan.com'],
    nativeCurrency: {
      symbol: 'MATIC',
      decimals: 18,
    },
  },
  137: {
    chainId: '0x89',
    chainName: 'Polygon',
    rpcUrls: ['https://polygon-rpc.com '],
    blockExplorerUrls: ['https://polygonscan.com/'],
    nativeCurrency: {
      symbol: 'MATIC',
      decimals: 18,
    },
  },
  100: {
    chainId: '0x64',
    chainName: 'Gnosis',
    rpcUrls: ['https://rpc.gnosischain.com'],
    nativeCurrency: {
      name: 'XDAI',
      symbol: 'XDAI',
      decimals: 18,
    },
    blockExplorerUrls: ['https://gnosisscan.io'],
  },
}

export const sepoliaChain = defineChain({
  id: 11155111,
  name: 'Sepolia',
  nativeCurrency: { name: 'Sepolia Ether', symbol: 'ETH', decimals: 18 },
  rpcUrls: {
    default: {
      http: [RPC_URL_MAP[SupportedNetworks.Sepolia]],
    },
  },
  blockExplorers: {
    default: {
      name: 'Etherscan',
      url: 'https://sepolia.etherscan.io',
      apiUrl: 'https://api-sepolia.etherscan.io/api',
    },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 751532,
    },
    ensRegistry: { address: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e' },
    ensUniversalResolver: {
      address: '0xc8Af999e38273D658BE1b921b88A9Ddf005769cC',
      blockCreated: 5_317_080,
    },
  },
  testnet: true,
})

export const gnosisChain = defineChain({
  id: 100,
  name: 'Gnosis',
  nativeCurrency: {
    decimals: 18,
    name: 'Gnosis',
    symbol: 'xDAI',
  },
  rpcUrls: {
    default: {
      http: [
        RPC_URL_MAP[SupportedNetworks.Gnosis],
        'https://rpc.gnosischain.com',
        'https://rpc.ankr.com/gnosis',
      ],
      webSocket: ['wss://rpc.gnosischain.com/wss'],
    },
  },
  blockExplorers: {
    default: {
      name: 'Gnosisscan',
      url: 'https://gnosisscan.io',
      apiUrl: 'https://api.gnosisscan.io/api',
    },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 21022491,
    },
  },
  testnet: false,
})
