/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react'
import { cva } from 'class-variance-authority'

import { ButtonProps, cn, Icon, LucideIcon } from '@fileverse/ui'

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:color-button-disabled',
  {
    variants: {
      variant: {
        default:
          'color-button-default text-primary-foreground hover:color-button-default-hover',
        danger:
          'color-button-danger text-danger-foreground hover:color-button-danger-hover',
        secondary:
          'color-button-secondary text-secondary-foreground hover:color-button-secondary-hover',
        ghost:
          'hover:color-button-secondary-hover hover:text-accent-foreground',
      },
      size: {
        sm: 'h-[30px] px-3 min-w-[108px]',
        md: 'h-9 px-4 py-2 min-w-[129px]',
        lg: 'h-10 px-8 min-w-[145px]',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'md',
    },
  }
)

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      toggleLeftIcon,
      toggleRightIcon,
      leftIcon,
      rightIcon,
      onClick,
      isLoading = false,
      elevation,
      ...props
    },
    ref
  ) => {
    return (
      <button
        className={cn(buttonVariants({ variant, size, className }), {
          'shadow-elevation-1': elevation === 1,
          'shadow-elevation-2': elevation === 2,
          'shadow-elevation-3': elevation === 3,
          'shadow-elevation-4': elevation === 4,
        })}
        ref={ref}
        onClick={onClick}
        {...props}
      >
        {isLoading ? (
          <LucideIcon
            name="LoaderCircle"
            size={
              size === 'sm'
                ? 'sm'
                : size === 'md'
                ? 'md'
                : size === 'lg'
                ? 'lg'
                : 'md'
            }
            className="animate-spin"
            fill="transparent"
            stroke="currentColor"
          />
        ) : (
          <>
            {toggleLeftIcon && (
              <span className="start-adornment">
                <Icon
                  size={size === 'sm' ? 'sm' : 'md'}
                  name={leftIcon as any}
                  fill={
                    variant === 'default' || variant === 'danger'
                      ? 'white'
                      : 'currentColor'
                  }
                  className="mr-2"
                />
              </span>
            )}
            {props.children}
            {toggleRightIcon && (
              <span className="end-adornment">
                <Icon
                  size={size === 'sm' ? 'sm' : 'md'}
                  name={rightIcon as any}
                  fill={
                    variant === 'default' || variant === 'danger'
                      ? 'white'
                      : 'currentColor'
                  }
                  className="ml-2"
                />
              </span>
            )}
          </>
        )}
      </button>
    )
  }
)
Button.displayName = 'Button'
