import React from 'react'
import './FillingLoader.css'

const FillingLoader = ({
  fillingText,
  width,
  height,
}: {
  fillingText: string
  width?: string
  height?: string
}) => {
  return (
    <div
      className={`relative px-4 ${width} ${height}  bg-[#D9D9D9] 
      rounded-md`}
    >
      <div className="bg-black absolute inset-0 rounded-lg h-full progress" />
      <div
        className="relative text-[#ffffff] h-full 
      flex items-center justify-center font-bold"
      >
        {fillingText}
      </div>
    </div>
  )
}

export default FillingLoader
