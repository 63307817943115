/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createRef, useEffect, useRef, useState } from 'react'
import PluginNavbar from '../Navbars/PluginNavbar'
import { Excalidraw, SHAPES } from '@fileverse/whiteboard'
import {
  IFileChatKeyPair,
  INewFile,
} from '../../types/interface/file.interface'
import cn from 'classnames'
import {
  MousePointer,
  Square,
  Diamond,
  Circle,
  MoveRight,
  Minus,
  ALargeSmall,
  Pencil,
  Hand,
  Eraser,
} from 'lucide-react'
import { DocStatus } from '../ExcalidrawCanvas/SavingStatusUI'
import { IExcalidrawCanvasInfo, IPluginFile } from './ExcalidrawViewer'
import { MakeFileFromObject } from '../../utils/makeFileFromObject'
import { useFileData } from '../../store/files/hooks'
import { ExcalidrawImperativeAPI } from '@fileverse/whiteboard/types/types'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useContract } from '../../store/contract/hooks'
import { checkIfOwnerOrCollaborator } from '../../utils/checkIfOwnerOrCollaborator'
import Spinner from '../Spinner'
import isEmpty from 'lodash/isEmpty'
import { WebPageCommentSection } from '../WebPages/WebPageCommentSection'
import { QRModal } from '../Popup/QRModal'
import useQRCode from '../../hooks/useQRCode'
import WebPageProofPublish from '../WebPages/WebPageProofPublish'
import { WebPageMoreMenu } from '../WebPages/WebPageMoreMenu'
import useWhiteboardKeyCodeHandler from '../../hooks/useWhiteboardKeyCodeHandler'
import { useServerKeys } from '../../store/invoker/hooks'
import { usePrivyHelper } from '../../hooks/usePrivyHelper'
import { DeletePopUp } from '../WebPages/DeletePopUp'

const WhiteboardFileViewer = ({
  downloadUrl,
  fileChatKey,
}: {
  downloadUrl: string
  fileChatKey: IFileChatKeyPair
}) => {
  const toolbarRefs = useRef(SHAPES.map(() => React.createRef()))
  const excalidrawAppRef = createRef()
  const freeHandToolRef = useRef(React.createRef())
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [file, setFile] = useState<any>({})
  const [isEditing, setIsEditing] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const [showDropDownMenu, setShowDropdownMenu] = useState(false)
  const [confirmDeleteModal, setConfirmDeleteModal] = useState<boolean>(false)
  const [isPreviewMode, setPreviewMode] = useState(false)
  const { address: contractAddress, fileId } = useParams()
  const fileData = useFileData(contractAddress as string, fileId)
  const [urlParams] = useSearchParams()
  const chainId = parseInt(urlParams.get('chainId') || '')
  const [excalidrawAPI, setExcalidrawAPI] =
    useState<ExcalidrawImperativeAPI | null>(null)
  const walletAddress = usePrivyHelper().walletAddress
  const [activeTool, setActiveTool] = useState<string>('')
  const [commentsEnabled, setCommentsEnabled] = useState<boolean>(true)
  const loadFileContent = async () => {
    const whiteboardFileData = await (await fetch(downloadUrl)).text()
    const fileJson = JSON.parse(whiteboardFileData)
    setFile(fileJson)
    if (
      Object.prototype.hasOwnProperty.call(
        fileData?.metadata,
        'commentsEnabled'
      )
    ) {
      setCommentsEnabled(fileJson?.commentsEnabled)
    } else {
      setCommentsEnabled(true)
    }
  }
  const docName = fileData?.metadata.name.split('.')[0]
  const [draftTitle, setDraftTitle] = useState<string>(docName || '')
  const openDownloadModal = () => {
    if (excalidrawAppRef.current) {
      ;(excalidrawAppRef.current as any).openDownloadModal()
    }
  }

  const publishCanvas = () => {
    const excalidrawCanvas: IExcalidrawCanvasInfo = {
      type: 'excalidraw',
      version: 2,
      source: 'fileverse',
      elements: excalidrawAPI?.getSceneElements()!,
      appState: { ...excalidrawAPI?.getAppState()!, selectedElementIds: {} },
      files: excalidrawAPI?.getFiles()!,
    }

    const fileObj: IPluginFile = {
      file: excalidrawCanvas,
      source: 'fileverse-excalidraw',
      version: 0,
      commentsEnabled: commentsEnabled,
    }
    const file = MakeFileFromObject(fileObj, `${draftTitle}`)
    return file
  }
  useEffect(() => {
    loadFileContent()
  }, [])
  useEffect(() => {
    if (!excalidrawAPI) return
    setActiveTool(excalidrawAPI?.getAppState().activeTool.type as string)
  }, [excalidrawAPI])
  const contract = useContract(contractAddress as string)
  const serverKeys = useServerKeys(
    walletAddress as string,
    contractAddress as string
  )
  const isCollaborator = checkIfOwnerOrCollaborator(
    contract,
    walletAddress as string,
    serverKeys
  )
  const copyFileURL = `${window.origin}${window.location.pathname}#/${contractAddress}/file/${fileId}?chainId=${chainId}`
  const [showComments, setShowComments] = useState<boolean>(false)
  const [commentsAvailable, setCommentsAvailable] = useState({
    available: true,
    count: 0,
  })

  const navigate = useNavigate()

  const cancelEditedContents = () => {
    const prevFile = { ...file }
    setFile({})
    // temporary solution to render the excalidraw component when a user cancel edits
    setTimeout(() => {
      setFile(prevFile)
    }, 0)
  }
  const toggleCommentsVisibility = () => {
    setShowComments(!showComments)
  }

  const { qrRef, isQrVisible, setIsQrVisible } = useQRCode(false)
  const [isProofOfPublishingVisibile, setProofOfPublishingVisibility] =
    useState<boolean>(false)

  const renderTool = () => {
    const freeHandToolHandler = async () => {
      await (freeHandToolRef as any).current.click()
      setActiveTool(excalidrawAPI?.getAppState().activeTool.type as string)
    }
    const onShapeClick = async (index: number) => {
      await (toolbarRefs.current[index] as any).current.click()
      setActiveTool(excalidrawAPI?.getAppState().activeTool.type as string)
    }
    return renderWhiteboardToolbar({
      freeHandToolHandler,
      activeTool,
      onShapeClick,
    })
  }

  useWhiteboardKeyCodeHandler({ setActiveTool })

  return (
    <div className="h-[100vh] w-[100vw]">
      <div className="h-full w-full rounded-md">
        <div className="h-[8vh] w-full  relative bg-[#ffffff]">
          {showDropDownMenu && (
            <WebPageMoreMenu
              confirmDeleteModal={confirmDeleteModal}
              setConfirmDeleteModal={setConfirmDeleteModal}
              setIsEditing={setIsEditing}
              fileDownloadName={fileData?.metadata.name}
              filePreviewPage={!isEditing}
              setProofPublishOpen={setProofOfPublishingVisibility}
              setIsQrVisible={setIsQrVisible}
              isOwner={isCollaborator}
              isDownloadEnabled={true}
              isWhiteboard={true}
              downloadWhiteboard={openDownloadModal}
              commentsEnabled={commentsEnabled}
              setCommentsEnabled={setCommentsEnabled}
              pluginName="whiteboard"
              moreMenuRef={dropdownRef}
            />
          )}
          <PluginNavbar
            isTitleFieldEnabled={isEditing}
            dropdownRef={dropdownRef}
            docStatus={DocStatus.SAVED}
            onLiveCollaborationTrigger={() => null}
            pluginTool={renderTool()}
            portalName={contract?.metadata.name as string}
            setPluginTitle={setDraftTitle}
            pluginTitle={draftTitle}
            isPreviewMode={isPreviewMode}
            setPreviewMode={setPreviewMode}
            backButtonAction={() =>
              navigate(`/${contractAddress}?chainId=${chainId}`)
            }
            isUserACollaborator={isCollaborator}
            isEditMode={isEditing}
            setEditMode={setIsEditing}
            isCommentsVisible={showComments}
            toggleQrModalVisibility={() => setIsQrVisible(!isQrVisible)}
            isPublished={!isEditing}
            setIsEditCancelFlag={cancelEditedContents}
            isPublishLoading={false} // fix the name to be isLoading
            isCommentsEnabled={commentsEnabled}
            toggleCommentVisibility={toggleCommentsVisibility}
            toggleMoreMenuVisibility={() =>
              setShowDropdownMenu(!showDropDownMenu)
            }
            commentCount={commentsAvailable.count}
            publishPlugin={() => null}
            toggleProvenaceModalVisibility={() =>
              setProofOfPublishingVisibility(!isProofOfPublishingVisibile)
            }
            isCollaborating={false}
            collaborators={null}
            createEditedFile={publishCanvas}
            isWhiteboard={true}
            collaborationDisabled={true}
          />
        </div>

        <div className="h-[92vh] w-full">
          {isEmpty(file) ? (
            <div className="w-[100vw] h-full flex justify-center items-center">
              <Spinner />
            </div>
          ) : (
            <Excalidraw
              ref={(api: ExcalidrawImperativeAPI) => setExcalidrawAPI(api)}
              toolbarRefs={toolbarRefs}
              excalidrawAppRef={excalidrawAppRef as any}
              freeHandToolRef={freeHandToolRef}
              viewModeEnabled={!isEditing || isPreviewMode}
              initialData={{
                elements: file?.file?.elements,
                appState: {
                  ...file?.file?.appState,
                  collaborators: [],
                  selectedElementIds: {},
                },
              }}
              onChange={() => {
                if (
                  activeTool !== excalidrawAPI?.getAppState().activeTool.type
                ) {
                  setActiveTool(
                    excalidrawAPI?.getAppState().activeTool.type as string
                  )
                }
              }}
            />
          )}
        </div>
        <WebPageCommentSection
          show={showComments}
          setShow={setShowComments}
          chatKey={fileChatKey}
          fileData={fileData as INewFile}
          setCommentMetadata={(data) =>
            setCommentsAvailable({ ...commentsAvailable, ...data })
          }
          isViewMode={!isCollaborator}
        />
        <WebPageProofPublish
          fileData={fileData as INewFile}
          proofPublishOpen={isProofOfPublishingVisibile}
          setProofPublishOpen={setProofOfPublishingVisibility}
        />

        <QRModal
          isQrVisible={isQrVisible}
          copyFileURL={copyFileURL}
          qrRef={qrRef}
          setIsQrVisible={setIsQrVisible}
        />
        <DeletePopUp
          isOpen={confirmDeleteModal}
          setIsOpen={setConfirmDeleteModal}
        />
      </div>
    </div>
  )
}

export default WhiteboardFileViewer

export const renderToolIcon = (
  key: string,
  activeTool: string
): JSX.Element | undefined => {
  switch (key) {
    case 'selection': {
      return (
        <MousePointer
          className={cn('  w-4', {
            'fill-black': activeTool === key,
          })}
        />
      )
    }
    case 'rectangle': {
      return (
        <Square
          className={cn('  w-4', {
            'fill-black': activeTool === key,
          })}
        />
      )
    }
    case 'diamond': {
      return (
        <Diamond
          className={cn('  w-4', {
            'fill-black': activeTool === key,
          })}
        />
      )
    }
    case 'ellipse': {
      return <Circle className={cn('  w-4')} />
    }
    case 'arrow': {
      return (
        <MoveRight
          className={cn('  w-4', {
            'fill-black': activeTool === key,
          })}
        />
      )
    }
    case 'line': {
      return (
        <Minus
          className={cn('  w-4', {
            'fill-black': activeTool === key,
          })}
        />
      )
    }
    case 'freedraw': {
      return <Pencil className={cn('  w-4')} />
    }
    case 'text': {
      return <ALargeSmall className={cn('  w-4')} />
    }
    case 'image': {
      return (
        <MousePointer
          className={cn('  w-4', {
            'fill-black': activeTool === key,
          })}
        />
      )
    }
    case 'eraser': {
      return <Eraser className={cn('  w-4')} />
    }
  }
}

export const renderWhiteboardToolbar = ({
  freeHandToolHandler,
  activeTool,
  onShapeClick,
}: {
  freeHandToolHandler: () => void
  activeTool: string
  onShapeClick: (shapeIndex: number) => void
}) => {
  return (
    <div className="flex justify-center w-full gap-3 p-4">
      <button
        onClick={freeHandToolHandler}
        className={cn(' p-2 px-3 rounded-lg', {
          'bg-yellow': activeTool === 'hand',
          'hover:bg-[#EEEEEE]': activeTool !== 'hand',
        })}
      >
        <Hand
          className={cn('  w-4', {
            'fill-black': activeTool === 'hand',
          })}
        />
      </button>
      {SHAPES.map((tool, index) => {
        if (tool.value === 'image') return
        return (
          <button
            key={index}
            onClick={() => onShapeClick(index)}
            className={cn(' p-2 px-3 relative rounded-lg', {
              'bg-yellow': activeTool === tool.value,
              'hover:bg-[#EEEEEE]': activeTool !== tool.value,
            })}
          >
            {renderToolIcon(tool.value, activeTool)}
            <div className=" absolute bottom-1 right-1">
              <p className=" text-[8px]">{tool.numericKey}</p>
            </div>
          </button>
        )
      })}
    </div>
  )
}
