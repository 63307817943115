/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Like } from './Mint/Like'
import BlackButton from '../common/BlackButton'
import cn from 'classnames'
import { WebPageMoreMenu } from './WebPageMoreMenu'
import AccountTab from '../common/AccountTab'
import useHeaderHook from '../../hooks/useHeaderHook'
import { usePrivyHelper } from '../../hooks/usePrivyHelper'
import { MessageSquareText, Ellipsis } from 'lucide-react'

interface WebPageBottomUIProps {
  filePreviewPage: boolean
  isCollaborator?: boolean
  commentsAvailable?: {
    available: boolean
    count: number
  }
  connectButtonRef?: React.RefObject<HTMLButtonElement>
  commentsEnabled: boolean
  showComments: boolean
  setShowComments?: Dispatch<SetStateAction<boolean>>
  setDownloadPopup?: Dispatch<SetStateAction<boolean>>
  setCommentsEnabled?: Dispatch<SetStateAction<boolean>>
  setProofPublishOpen?: Dispatch<SetStateAction<boolean>>
  setIsQrVisible: Dispatch<SetStateAction<boolean>>
  downloadUrl?: string
  fileDownloadName?: string
  toggleMobileSideMenu?: () => void
}

export const WebPageBottomUI = ({
  filePreviewPage,
  isCollaborator,
  commentsAvailable,
  commentsEnabled,
  showComments,
  setShowComments,
  setDownloadPopup,
  setCommentsEnabled,
  setProofPublishOpen,
  setIsQrVisible,
  toggleMobileSideMenu,
  downloadUrl,
  fileDownloadName,
}: WebPageBottomUIProps) => {
  const walletAddress = usePrivyHelper().walletAddress as string
  const [moreMenu, setMoreMenu] = useState<boolean>(false)
  const menuRef = useRef<HTMLInputElement>(null)

  const [accountDropDown, setAccountDropDown] = useState<boolean>(false)

  const { handleDisconnectWallet } = useHeaderHook()
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setMoreMenu(false)
        setAccountDropDown(false)
      }
    }

    if (moreMenu || accountDropDown) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [moreMenu, setMoreMenu, setAccountDropDown, accountDropDown])

  return (
    <div
      ref={menuRef}
      className="fixed bottom-0 z-10 w-full max-w-full mb-2 self-center flex justify-center"
    >
      <nav
        data-cy="bottom-navbar"
        id="bottom-navbar"
        className="flex justify-center bg-transparent relative w-full"
      >
        {moreMenu && (
          <WebPageMoreMenu
            confirmDeleteModal={false}
            setConfirmDeleteModal={() => null}
            downloadUrl={downloadUrl}
            fileDownloadName={fileDownloadName}
            filePreviewPage={filePreviewPage}
            commentsEnabled={commentsEnabled}
            setDownloadPopup={setDownloadPopup}
            setCommentsEnabled={setCommentsEnabled}
            setProofPublishOpen={setProofPublishOpen}
            setIsQrVisible={setIsQrVisible}
            isBottomNav
            pluginName="dPage"
            moreMenuRef={menuRef}
            isOwner={isCollaborator}
          />
        )}
        <div className="bg-white shadow-elevation-3 p-3 rounded-[12px] h-[52px] overflow-y-hidden no-scrollbar flex justify-center items-center gap-3">
          {/* Like */}
          <Like mobileView={true} />
          {/* Comments */}
          <div className={cn('flex gap-1')}>
            <MessageSquareText
              size={20}
              className={cn(
                'cursor-pointer',
                !commentsEnabled && 'cursor-not-allowed pointer-events-none'
              )}
              onClick={() => setShowComments?.(!showComments)}
            />
            {commentsAvailable?.count !== 0 && commentsAvailable?.count}
          </div>
          {/* Share */}
          <div className="relative flex justify-center items-center">
            <Ellipsis
              size={20}
              className="cursor-pointer"
              onClick={() => setMoreMenu(!moreMenu)}
            />
          </div>

          {/* Try dPage - Hide if Owner or Collaborator */}

          <BlackButton
            customCSS="min-w-fit"
            title="Try Fileverse"
            onClick={toggleMobileSideMenu}
          />
        </div>
        <div className="relative bottom-[calc(100%+18vh)]">
          {accountDropDown && (
            <AccountTab
              handleDisconnectWallet={handleDisconnectWallet}
              setAccountDropDown={setAccountDropDown}
              isCollaborator={isCollaborator}
              signerAddress={walletAddress}
            />
          )}
        </div>
      </nav>
    </div>
  )
}
