/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMediaQuery } from '@mui/material'
import React, { Dispatch, SetStateAction } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { useSafeAppStatus } from '../../store/common/hooks'
import { isOriginGnosisBlockscout } from '../../utils/isOriginGnosisBlockscout'
import { usePrivyHelper } from '../../hooks/usePrivyHelper'

const AboutDropDown = ({
  setIsNavigateToPortals,
}: {
  setIsNavigateToPortals: Dispatch<SetStateAction<boolean>>
}) => {
  const { address: contractAddress } = useParams()
  const navigate = useNavigate()
  const walletAddress = usePrivyHelper().walletAddress
  const isMediaMax1025px = useMediaQuery('(max-width : 1025px)')
  const isSafeApp = useSafeAppStatus()
  const navTarget = isSafeApp || isOriginGnosisBlockscout ? '_self' : '_blank'
  return (
    <div
      className={`flex flex-col w-[10rem] right-0 absolute ${
        isMediaMax1025px ? 'top-[5rem]' : 'top-[3rem]'
      } z-10 bg-white  border-fade border rounded-lg shadow-lg`}
    >
      <NavLink to={`/manifesto`}>
        <div className="h-[34px] flex items-center  mx-[12px] border-fade border-bottom border-b px-2 py-2">
          <span className="hover:cursor-pointer ">FAQ</span>
        </div>
      </NavLink>

      <div
        onClick={() => {
          if (!walletAddress) {
            setIsNavigateToPortals(true)
            document.getElementById('wallet-connect-btn')?.click()
          } else {
            navigate(`/${contractAddress}/contracts`)
          }
        }}
        className="h-[34px] flex items-center mx-[12px] px-2 py-2 border-fade border-bottom border-b"
      >
        <span className="hover:cursor-pointer">My Portals</span>
      </div>
      <NavLink target={navTarget} to={`/create`}>
        <div className="h-[34px] flex items-center mx-[12px] px-2 py-2 mb-2">
          <span className="hover:cursor-pointer">Create Portal</span>
        </div>
      </NavLink>
    </div>
  )
}

export default AboutDropDown
