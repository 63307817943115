import React from 'react'
import smileLogo from '../../assets/logo-smile.svg'
import Loader from '../../../../components/common/Loader'
import loader from '../../assets/smile-anim.json'

export const AnimatedLoader = ({ text = 'Loading...' }: { text?: string }) => {
  return (
    <div className="flex flex-col items-center justify-center h-full gap-4">
      <Loader className="w-[151px]" animationFile={loader} />
      <p className="text-heading-sm-bold">{text}</p>
    </div>
  )
}

export const StaticLoader = ({ text = 'Loading...' }: { text?: string }) => {
  return (
    <div className="flex flex-col items-center justify-center h-full gap-4">
      <img src={smileLogo} alt="fileverse logo" />
      <p className="text-heading-sm-bold">{text}</p>
    </div>
  )
}
