import React, { Dispatch, SetStateAction } from 'react'
import PrimaryButton from '../common/PrimaryButton'
import Sad from '../../assets/sad.svg'
import { Popup } from './PopUp'

const NonCollaboratorPopup = ({
  isPopUpActive,
  popupref,
  setPopupActive,
}: {
  isPopUpActive: boolean
  popupref: React.RefObject<HTMLDivElement>
  setPopupActive: Dispatch<SetStateAction<boolean>>
}) => {
  return (
    <Popup isOpen={isPopUpActive} width={'25rem'} height={'21rem'}>
      <div
        data-cy="non-collaborator-pop-up"
        ref={popupref}
        className=" h-full bg-white w-full p-2 rounded-lg flex justify-center items-center"
      >
        <div className=" h-full flex flex-col justify-between">
          <div className="w-full">
            <p className=" text-center text-2xl font-medium">OnO</p>
          </div>
          <div className=" flex items-center w-full flex-col justify-between">
            <img src={Sad} className=" w-28" />
            <h6 className="font-medium mt-4 text-center">
              You&apos;re not a Collaborator of this Fileverse Portal. You can
              always create your own Portal if you want &lt;3.
            </h6>
          </div>

          <div className="justify-center flex items-center">
            <PrimaryButton onClick={() => setPopupActive(false)} title="Okay" />
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default NonCollaboratorPopup
