import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import {
  Outlet,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import Header from '../common/Headers/MainHeader'
import {
  getPortalMetadataFromLastWorkingUrl,
  loadPortalState,
  verifyPortalChainId,
} from '../../store/contract/thunks'
import { useAppDispatch } from '../../store/hooks'
import {
  setActiveContractAddress,
  setPortalAgent,
  setPortalSignlessMode,
} from '../../store/contract/reducer'
import {
  useContract,
  useContractChainId,
  usePortalSignlessMode,
} from '../../store/contract/hooks'
import {
  addInvokerContract,
  removeInvokerServerKeys,
  setInvokerAgentKey,
} from '../../store/invoker/reducer'
import {
  useAgentKey,
  useInvokerContractCredentials,
  useServerKeys,
} from '../../store/invoker/hooks'
import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'
import { getMessagesAPI, processMessageAPI } from '../../api/message/messageAPI'
import { getContractKeySharingKeys } from '../../store/keySharing/hooks'

import DashboardSideBar from '../PortalSideBar/DashboardSideBar'
import {
  verifyKeyRequest,
  verifyServerKeyMessage,
} from '../../store/keySharing/utils'
import { ContractPermissionEnum } from '../../types/enum/contract.enum'
import { MessageTopicEnum } from '../../types/enum/message.enum'
import { ICredential } from '../../types/interface/invoker.interface'
import { IMessage } from '../../types/interface/message.interface'
import { decryptServerKeys, generateRSAKeyPair } from '../../utils/libCrypto'
import { publishServerKeys } from '../../utils/messages/keySharing'
import Loader from '../common/Loader'
import sendNotifcation from '../../utils/notification'
import useComponentVisibilty from '../../hooks/useVisibility'
import { searchKeyVersionAndVerifyPortalKeys } from '../../utils/messages/keyVerifiers'

import { IContract } from '../../types/interface/contract.interface'
import PortalLoader from '../../assets/loader/JSON_portal_loader.json'
import { convertTypedArrayToString, toLowerCase } from '../../utils/string'
import { isAddress } from 'ethers'
import { resolveEnsName } from '../../utils/networks/ensResolvers'
import { FileStateFromOutlet } from '../../types/interface/file.interface'
import { hasAgent } from '../../utils/agentHandler'
import { requestServerKeys } from '../../store/keySharing/thunks'
import EmptyPortalKeysPopup from '../Popup/EmptyPortalKeysPopup'
import { useMediaQuery } from '@mui/material'
import MainMobileHeader from '../common/Headers/MainMobileHeader'
import KeysBanner from '../common/KeysBanner'
import { useTasksContext } from '../../hooks/useTasksContext'
import { addInvokerServerKeysThunk } from '../../store/invoker/thunks'
import cn from 'classnames'
import { useGunNodes } from '../../hooks/useGunNode'
import styles from './index.module.scss'
import { useTour } from '@reactour/tour'
import { usePrivyHelper } from '../../hooks/usePrivyHelper'

let recievedKeysNotification = false
const idTracker = new Map()
const DASHBOARD_TOUR_VIEWED = 'fileverse_dashboard_tour_viewed'

export type FileStateInLocation = [
  FileStateFromOutlet,
  Dispatch<SetStateAction<FileStateFromOutlet | undefined>>
]

export const DashboardLayout = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const chainId = parseInt(searchParams.get('chainId') || '')
  const [ready, setReady] = useState<boolean>(false)
  const [enabledSignless, setEnableSignless] = useState<boolean>(false)
  const { address: contractAddress } = useParams()
  const dispatch = useAppDispatch()

  const privyHelper = usePrivyHelper()
  const { isLoading } = privyHelper
  const invokerAddress = privyHelper.walletAddress as string
  const portal = useContract(contractAddress as string)
  const isMediaMax1023px = useMediaQuery('(max-width : 1023px)')
  const serverKeys = useServerKeys(invokerAddress, contractAddress as string)
  const credential = useInvokerContractCredentials(
    invokerAddress,
    contractAddress as string
  )
  const location = useLocation()
  const tasksContext = useTasksContext()
  const isHomePage = location.pathname === `/${contractAddress}`
  const hasNotDownloadedKeys = !tasksContext?.isDownloadedKeys
  const [showKeysBanner, setShowKeysBanner] = useState<boolean>(false)
  const [pendingUploadedFileState, setPendingUploadedFileState] = useState<
    FileStateFromOutlet | undefined
  >(location.state)
  const keySharingKeys = getContractKeySharingKeys(contractAddress as string)
  const contractChainId = useContractChainId(contractAddress as string)
  const agentKey = useAgentKey(invokerAddress, contractAddress as string)

  const { getAuthenticatedSignlessKeyNode } = useGunNodes()

  const {
    portalDecryptionKey,
    portalEncryptionKey,
    memberDecryptionKey,
    memberEncryptionKey,
  } = serverKeys

  const isMissingPortalKeys =
    !portalDecryptionKey ||
    !portalEncryptionKey ||
    !memberDecryptionKey ||
    !memberEncryptionKey
  const isSignless = usePortalSignlessMode(contractAddress as string)
  const {
    isComponentVisible: isNewCollaborator,
    setIsComponentVisible: setNewCollaborator,
  } = useComponentVisibilty(false)

  const navigate = useNavigate()
  const { setIsOpen } = useTour()
  const triggerTour = localStorage.getItem(DASHBOARD_TOUR_VIEWED)
  const tour = searchParams.get('tour') || ''

  const handlePageDisplay = async (
    chainId: number,
    contract: IContract,
    contractChainId?: number
  ) => {
    const verifiedChainId = await dispatch(
      verifyPortalChainId({
        chainId,
        contractAddress: contractAddress as string,
        contractChainId,
      })
    ).unwrap()

    if (!verifiedChainId) {
      sendNotifcation(
        'Failed to load portal',
        'We are unable to verify this portal chain id. The contract address specified was not found in any of our supported networks',
        'danger',
        7000
      )
      return
    }
    dispatch(
      addInvokerContract({
        contractAddress: contractAddress as string,
        invokerAddress: invokerAddress,
      })
    )

    if (searchParams.get('chainId') !== verifiedChainId?.toString()) {
      searchParams.set('chainId', verifiedChainId?.toString() as string)
      setSearchParams(searchParams)
    }
    if (!invokerAddress) {
      navigate(`/${contractAddress}/member?chainId=${verifiedChainId}`)
    } else if (
      contract.collaborators.includes(invokerAddress) &&
      !isEmpty(credential)
    ) {
      setReady(true)
    } else {
      navigate(`/${contractAddress}/member?chainId=${verifiedChainId}`)
    }
  }
  const renderPage = async (contractAddress: string) => {
    try {
      const portalState = await dispatch(
        loadPortalState({
          contractAddress,
          invokerAddress,
          chainId,
        })
      ).unwrap()
      if (portalState) {
        handlePageDisplay(chainId, portalState, portalState.chainId)
      } else {
        sendNotifcation('Unable to resolve portal', '', 'danger')
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.log(error)
      sendNotifcation('Unable to resolve portal', error.message, 'danger', 7000)
    }
  }

  const broadcastPortalKey = async (message: IMessage) => {
    try {
      if (
        portalEncryptionKey &&
        portalDecryptionKey &&
        memberDecryptionKey &&
        memberEncryptionKey
      ) {
        const messageContent = await verifyKeyRequest(
          message.content,
          contractAddress as string,
          message.invokerAddress
        )
        if (isNull(messageContent)) return
        const level = ContractPermissionEnum.COLLABORATOR
        await publishServerKeys(
          level,
          messageContent,
          serverKeys,
          invokerAddress,
          credential as ICredential,
          message.invokerAddress,
          contractChainId as number
        )
        sendNotifcation(
          'Published Keys',
          `Portal keys has been published for ${message.invokerAddress}`,
          'success',
          10000
        )
        await processMessageAPI({
          contractAddress: contractAddress as string,
          invokerAddress,
          credential: credential as ICredential,
          messageId: message._id,
          chain: contractChainId as number,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }
  const consumePortalKeys = async (
    message: IMessage,
    interval?: NodeJS.Timeout
  ) => {
    try {
      const messageContent = await verifyServerKeyMessage(
        message.content,
        (credential as ICredential).viewDID,
        contractAddress as string,
        message.invokerAddress
      )
      if (isNull(messageContent)) return
      if (isNull(keySharingKeys) || isEmpty(keySharingKeys)) return
      const content = JSON.parse(messageContent)
      const _serverKeys = await decryptServerKeys(content, keySharingKeys)
      const isVerified = await searchKeyVersionAndVerifyPortalKeys(
        _serverKeys,
        contractAddress as string,
        chainId
      )
      if (isVerified) {
        interval && clearInterval(interval)
        const ownerKeys = await generateRSAKeyPair()
        const ownerPublicKey = await window.crypto.subtle.exportKey(
          'spki',
          ownerKeys.publicKey
        )
        const ownerPrivateKey = await window.crypto.subtle.exportKey(
          'pkcs8',
          ownerKeys.privateKey
        )

        if (!recievedKeysNotification) {
          recievedKeysNotification = true
          await dispatch(
            addInvokerServerKeysThunk({
              agentKey,
              invokerAddress,
              contractAddress: contractAddress as string,
              ..._serverKeys,
              ownerEncryptionKey: convertTypedArrayToString(
                new Uint8Array(ownerPublicKey)
              ),
              ownerDecryptionKey: convertTypedArrayToString(
                new Uint8Array(ownerPrivateKey)
              ),
            })
          )
          setNewCollaborator(false)
          sendNotifcation(
            `Recieved Keys`,
            `You have just recieved portal keys from ${message.invokerAddress}`,
            'success',
            10000
          )
        }
        await processMessageAPI({
          contractAddress: contractAddress as string,
          invokerAddress,
          credential: credential as ICredential,
          messageId: message._id,
          chain: contractChainId as number,
        })
      } else {
        dispatch(
          requestServerKeys({
            invokerAddress,
            credential: credential as ICredential,
            contractAddress: contractAddress as string,
            chainId,
            keySharingKeys,
          })
        )
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.log(error)
    }
  }

  const processMessage = async (
    message: IMessage,
    interval?: NodeJS.Timeout
  ) => {
    if (message.topic === MessageTopicEnum.KEY_REQUEST) {
      broadcastPortalKey(message)
      return
    }
    const isBroadcastedKey = [
      MessageTopicEnum.SERVER_DECRYPTION_KEY,
      MessageTopicEnum.SERVER_ENCRYPTION_KEY,
    ].includes(message.topic)

    if (isBroadcastedKey) {
      consumePortalKeys(message, interval)
    }
  }

  const fetchAndHandleMessages = async (interval?: NodeJS.Timeout) => {
    if (isEmpty(credential)) return
    const { data } = await getMessagesAPI({
      contractAddress: contractAddress as string,
      credential: credential as ICredential,
      invokerAddress,
      chain: chainId,
    })
    const { messages = [] } = data
    for (const message of messages) {
      if (toLowerCase(message.contractAddress) === toLowerCase(contractAddress))
        await processMessage(message, interval)
    }
  }
  const enableSignlessFeature = () => {
    if (portal.owner !== invokerAddress) {
      dispatch(
        setPortalSignlessMode({
          contractAddress: contractAddress as string,
          enabled: true,
        })
      )
      sendNotifcation(
        'Signless feature has been activated',
        'The owner of this portal has enabled the signless feature, transactions henceforth will be signless',
        'success',
        10000
      )
    }
  }
  const disableSignlessFeature = () => {
    dispatch(
      setPortalSignlessMode({
        contractAddress: contractAddress as string,
        enabled: false,
      })
    )
    sendNotifcation(
      'Signless feature has been deactivated',
      'User does not have the agent key',
      'danger',
      10000
    )
  }
  const checkAndEnableSignlessFeature = async () => {
    if (isSignless && agentKey) return
    const portalHasAgent = await hasAgent(contractAddress as string)
    if (portalHasAgent) {
      if (invokerAddress && portalHasAgent && !agentKey) {
        const signlessKeyNode = getAuthenticatedSignlessKeyNode()
        await signlessKeyNode.on(
          (
            data: { key: string; agent: string; createdAt: string },
            id: string
          ) => {
            const agentKey = data?.key
            const agentAddress = data?.agent
            if (agentKey && idTracker.get(id) !== id) {
              idTracker.set(id, id)
              setEnableSignless(true)
              dispatch(
                setInvokerAgentKey({
                  invokerAddress: invokerAddress as string,
                  agentKey: agentKey,
                  contractAddress: contractAddress as string,
                })
              )
              dispatch(
                setPortalAgent({
                  contractAddress: contractAddress as string,
                  agentAddress,
                })
              )
            }
            signlessKeyNode.off()
          }
        )
        return
      }
    }

    if (!agentKey && isSignless) {
      disableSignlessFeature()
      return
    }
  }
  const verifyPortalKeys = async () => {
    if (!isMissingPortalKeys && invokerAddress) {
      const isVerified = await searchKeyVersionAndVerifyPortalKeys(
        serverKeys,
        contractAddress as string,
        chainId
      )
      if (!isVerified) {
        dispatch(
          removeInvokerServerKeys({
            contractAddress: contractAddress as string,
            invokerAddress: invokerAddress as string,
          })
        )
        if (!isEmpty(credential)) {
          dispatch(
            requestServerKeys({
              invokerAddress,
              credential,
              contractAddress: contractAddress as string,
              chainId,
              keySharingKeys,
            })
          )
          setNewCollaborator(true)
        }
      }
    }
  }

  useEffect(() => {
    ;(async () => {
      if (isLoading) return
      dispatch(setActiveContractAddress(contractAddress as string))
      dispatch(
        getPortalMetadataFromLastWorkingUrl({
          contractAddress: contractAddress as string,
          skipCache: true,
        })
      )
      if (!portal) {
        if (!isAddress(contractAddress as string)) {
          const address = await resolveEnsName(contractAddress as string)
          if (address) {
            navigate(`/${address}?chainId=${chainId}`)
          } else {
            sendNotifcation(
              'Failed to load portal',
              'The portal ens name provided couldnt be resolved. Try using the portal address instead',
              'danger',
              7000
            )
            navigate(`/404`)
          }
        } else {
          renderPage(contractAddress as string)
        }
      } else {
        handlePageDisplay(chainId, portal, contractChainId)
      }
    })()
  }, [invokerAddress, contractAddress, isLoading])

  useEffect(() => {
    setNewCollaborator(isMissingPortalKeys)
    !isMissingPortalKeys && verifyPortalKeys()
  }, [invokerAddress])

  useEffect(() => {
    if (hasNotDownloadedKeys && isHomePage) {
      //req: need to show banner after deplay of 5 secs
      const timeoutId = setTimeout(() => {
        setShowKeysBanner(true)
      }, 5000)
      return () => clearTimeout(timeoutId)
    } else {
      setShowKeysBanner(false)
    }
  }, [hasNotDownloadedKeys, isHomePage])

  useEffect(() => {
    if (!ready) return
    if (!triggerTour || tour) {
      setIsOpen(true)
      localStorage.setItem(DASHBOARD_TOUR_VIEWED, 'true')
    }
  }, [triggerTour, ready])

  useEffect(() => {
    // condition added to make sure user is fully a collaborator before activating signless feature
    if (portalDecryptionKey) checkAndEnableSignlessFeature()
  }, [invokerAddress, portalDecryptionKey, agentKey])

  useEffect(() => {
    if (enabledSignless) enableSignlessFeature()
  }, [enabledSignless])

  useEffect(() => {
    if (!isNewCollaborator) {
      // fetch once to publish keys
      fetchAndHandleMessages()
      return
    }
    // keep fetching messages to see the who published portal keys
    const interval = setInterval(async () => {
      await fetchAndHandleMessages(interval)
    }, 5000)
    return () => {
      clearInterval(interval)
    }
  }, [isNewCollaborator])

  if (isLoading || !ready) {
    return (
      <div className="min-h-screen h-screen flex">
        <div className="w-full flex justify-center">
          <Loader animationFile={PortalLoader} />
        </div>
      </div>
    )
  }

  return (
    <div
      className="h-[100vh] overflow-hidden no-scrollbar"
      data-cy="portal-dashboard"
    >
      {showKeysBanner && <KeysBanner setShowKeysBanner={setShowKeysBanner} />}
      <div
        className={cn(
          'flex w-full ease-in-out duration-1000',
          showKeysBanner ? 'h-[calc(100%-65px)]' : 'h-full'
        )}
      >
        {!isMediaMax1023px && (
          <DashboardSideBar keysBannerVisible={showKeysBanner} />
        )}

        <div
          className={cn(
            'h-[100%]  rounded-md rounded-b-none',
            isMediaMax1023px ? 'w-[100%]' : 'flex-grow',
            styles.dashboard_content
          )}
        >
          <div className={cn(styles.dasboard_main_wrapper)}>
            {isMediaMax1023px ? (
              <div className={styles.header_wrapper}>
                <MainMobileHeader isPrivate={true} />
              </div>
            ) : (
              <div className={styles.header_wrapper}>
                <Header />
              </div>
            )}
            <div
              className={cn(styles.dashboard_main_container, 'no-scrollbar')}
            >
              <Outlet
                context={[
                  pendingUploadedFileState,
                  setPendingUploadedFileState,
                  isHomePage,
                ]}
              />
            </div>
          </div>
        </div>
      </div>

      <EmptyPortalKeysPopup
        isOpen={isNewCollaborator}
        fetchAndHandleMessages={fetchAndHandleMessages}
      />
    </div>
  )
}
