import { INewFile } from '../types/interface/file.interface'
import { IServerKey } from '../types/interface/invoker.interface'

export const getUserFilePermission = (
  account: string,
  fileData: INewFile,
  serverKeys: IServerKey
) => {
  const permission = {
    owner: false,
    collaborator: false,
    member: false,
  }

  if (account === fileData.metadata.owner && serverKeys.ownerDecryptionKey) {
    permission.owner = true
    permission.collaborator = true
  }
  if (serverKeys.portalDecryptionKey) {
    permission.collaborator = true
  }
  if (serverKeys.memberDecryptionKey) {
    permission.member = true
  }

  return permission
}
