import React, { SetStateAction } from 'react'
import { updateLegacyDocWarning } from '../../store/portalUpdateLogs/reducer'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { DynamicModal } from '../../pages/PublicPortal/components/DynamicModal'

export default function LegacyDocWarning({
  openWarningPopup,
  setOpenWarningPopup,
  publish,
}: {
  openWarningPopup: boolean
  setOpenWarningPopup: React.Dispatch<SetStateAction<boolean>>
  publish: () => void
}) {
  const { address: contractAddress } = useParams()
  const dispatch = useDispatch()
  return (
    <DynamicModal
      className="max-w-[600px]"
      open={openWarningPopup}
      onOpenChange={setOpenWarningPopup}
      title="Warning!"
      description="This is a legacy dDoc, if you exit the editing view you will lose the changes you’ve made, You can publish it to avoid that!"
      content={
        <div className="flex gap-4">
          <input
            type="checkbox"
            onChange={(e) => {
              dispatch(
                updateLegacyDocWarning({
                  contractAddress,
                  legacyDocWarningState: e.target.checked,
                })
              )
            }}
          />
          <p className="text-sm">Don’t show this again</p>
        </div>
      }
      primaryAction={{
        label: 'Publish',
        onClick: () => {
          setOpenWarningPopup(false)
          publish()
        },
        className: 'w-full md:w-auto',
      }}
      secondaryAction={{
        label: 'Cancel',
        onClick: () => setOpenWarningPopup(false),
        className: 'w-full md:w-auto',
      }}
    />
  )
}
