/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { SetStateAction, createRef, useRef, useState } from 'react'
import PrimaryButton from '../common/PrimaryButton'
import WhiteButton from '../common/WhiteButton'
import { Excalidraw, SHAPES, exportToBlob } from '@fileverse/whiteboard'
import {
  AppState,
  BinaryFiles,
  ExcalidrawImperativeAPI,
} from '@fileverse/whiteboard/types/types'
import { NonDeletedExcalidrawElement } from '@fileverse/whiteboard/types/element/types'
import { useParams } from 'react-router-dom'
import { useWalletClient } from 'wagmi'
import { MakeFileFromObject } from '../../utils/makeFileFromObject'
import { FileSource } from '../../types/enum/file.enum'
import { EditStage } from '../PreviewScreen/FileEdit'

export interface IExcalidrawCanvasInfo {
  type: string
  version: number
  source: string
  elements: readonly NonDeletedExcalidrawElement[]
  appState: AppState
  files: BinaryFiles
}
export interface IPluginFile {
  file: IExcalidrawCanvasInfo | Record<any, any>
  source: 'fileverse-excalidraw' | 'fileverse_document'
  version: number
  commentsEnabled: boolean
}
export default function ExcalidrawViewer({
  file,
  isEditing,
  setEditFile,
  setFileSource,
  fileData,
  selectFiles,
  setEditStage,
  setWhiteboardPreview,
}: {
  file: any
  isEditing?: boolean
  setEditFile?: React.Dispatch<SetStateAction<boolean>>
  setFileSource?: React.Dispatch<SetStateAction<FileSource>>
  fileData?: any
  selectFiles?: (files: File[]) => Promise<void>
  setEditStage?: React.Dispatch<SetStateAction<EditStage>>
  setWhiteboardPreview?: React.Dispatch<SetStateAction<string>>
}) {
  const [excalidrawAPI, setExcalidrawAPI] =
    useState<ExcalidrawImperativeAPI | null>(null)

  const { address: contractAddress } = useParams()
  // TODO: get contract signer from wallet
  const { data: contractSigner } = useWalletClient()

  const getWhiteboardPreview = async () => {
    const data = await exportToBlob({
      elements:
        excalidrawAPI?.getSceneElements() as NonDeletedExcalidrawElement[],
      appState: excalidrawAPI?.getAppState(),
      files: excalidrawAPI?.getFiles() as BinaryFiles,
    })
    const url = URL.createObjectURL(data)
    return url
  }

  const publishCanvas = async () => {
    if (!excalidrawAPI?.getSceneElements() || !excalidrawAPI?.getFiles()) return
    if (!contractAddress || !contractSigner) return

    const excalidrawCanvas: IExcalidrawCanvasInfo = {
      type: 'excalidraw',
      version: 2,
      source: 'fileverse',
      elements: excalidrawAPI?.getSceneElements(),
      appState: { ...excalidrawAPI?.getAppState(), selectedElementIds: {} },
      files: excalidrawAPI?.getFiles(),
    }

    const fileObj: IPluginFile = {
      file: excalidrawCanvas,
      source: 'fileverse-excalidraw',
      version: 0,
      commentsEnabled: true,
    }
    const file = MakeFileFromObject(fileObj, fileData?.metadata?.name)
    const url = await getWhiteboardPreview()

    if (setWhiteboardPreview) setWhiteboardPreview(url)
    if (setFileSource) setFileSource(FileSource.EXCALIDRAW)
    if (setEditStage) setEditStage(EditStage.CONFIRM_FILE)

    selectFiles && selectFiles([file])
  }
  const toolbarRefs = useRef(SHAPES.map(() => React.createRef()))
  const excalidrawAppRef = createRef()
  const freeHandToolRef = useRef(React.createRef())
  return (
    <div className="p-2 h-full overflow-auto rounded-[8px] box-border border border-[#E2E2E2]">
      <div data-cy="whiteboard-viewer" className="h-[90%]">
        <Excalidraw
          ref={(api: ExcalidrawImperativeAPI) => setExcalidrawAPI(api)}
          viewModeEnabled={!isEditing}
          initialData={{
            elements: file.elements,
            appState: {
              ...file.appState,
              collaborators: [],
              selectedElementIds: {},
            },
          }}
          toolbarRefs={toolbarRefs}
          excalidrawAppRef={excalidrawAppRef as any}
          freeHandToolRef={freeHandToolRef}
          UIOptions={{ canvasActions: { export: false } }}
        />
      </div>
      {isEditing && (
        <div className="h-[10%] flex justify-evenly items-center">
          <PrimaryButton title="Publish" onClick={() => publishCanvas()} />
          <WhiteButton
            title="Back"
            onClick={() => {
              if (setEditFile) setEditFile(false)
            }}
          />
        </div>
      )}
    </div>
  )
}
