import React, { SetStateAction, useState } from 'react'
import { deleteFile } from '../../store/files/reducer'

import { useAppDispatch } from '../../store/hooks'
import sendNotifcation from '../../utils/notification'
import { usePortalSignlessMode } from '../../store/contract/hooks'

import { useNavigate, useParams } from 'react-router-dom'
import { useAccount } from 'wagmi'
import { removeDraft } from '../../utils/collaboration/utils'
import { useGunNodes } from '../../hooks/useGunNode'
import { deleteFileTrxCall } from '../../utils/transaction'
import { usePrivyHelper } from '../../hooks/usePrivyHelper'
import { DynamicModal } from '../../pages/PublicPortal/components/DynamicModal'
import { CircleHelp } from 'lucide-react'

export const DeletePopUp = ({
  isOpen,
  setIsOpen,
  rtcId,
}: {
  isOpen: boolean
  setIsOpen: React.Dispatch<SetStateAction<boolean>>
  rtcId?: string
}) => {
  const { address: contractAddress, fileId } = useParams()
  const [isDeleting, setIsDeleting] = useState<boolean>(false)

  const isSignless = usePortalSignlessMode(contractAddress as string)
  const { chain: activeChain } = useAccount()
  const walletAddress = usePrivyHelper().walletAddress

  const { getAuthenticatedPluginMetadataNode } = useGunNodes()
  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const handleFileDeletedEvent = async (fileId: number) => {
    dispatch(
      deleteFile({
        contractAddress: contractAddress as string,
        fileId,
      })
    )

    setIsDeleting(false)
    setIsOpen(false)
  }

  const handleDeleteFile = async () => {
    try {
      setIsDeleting(true)

      const deletedFileId = await deleteFileTrxCall(
        {
          contractAddress: contractAddress as string,
          fileId: Number(fileId),
          walletAddress: walletAddress as string,
        },
        isSignless
      )

      handleFileDeletedEvent(deletedFileId)
      sendNotifcation('File deleted successfully', '', 'success')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setIsDeleting(false)
      setIsOpen(false)
      sendNotifcation('Signing failed', `${error?.message}`, 'danger')
    } finally {
      setIsDeleting(false)
      setIsOpen(false)
      navigate(`/${contractAddress}?chainId=${activeChain?.id}`)
    }
  }

  const deleteDraft = async () => {
    setIsDeleting(true)
    await removeDraft(
      getAuthenticatedPluginMetadataNode(rtcId as string),
      rtcId as string,
      () => {
        setIsDeleting(false)
        navigate(`/${contractAddress}?chainId=${activeChain?.id}`)
      }
    )
    sendNotifcation('Draft deleted successfully', '', 'success')
  }

  const deleteFunction = () => {
    if (rtcId) return deleteDraft()
    return handleDeleteFile()
  }

  return (
    <DynamicModal
      className="max-w-[600px]"
      open={isOpen}
      onOpenChange={(isOpen) => setIsOpen(isOpen)}
      disableOutsideClick={isDeleting}
      title="Delete confirmation"
      content={
        <div className="flex gap-2 items-start w-full h-full">
          <span className="w-6">
            <CircleHelp size={24} className="color-text-secondary" />
          </span>
          <span className="text-body-sm">
            Are you sure you want to delete this file?
          </span>
        </div>
      }
      primaryAction={{
        label: 'Delete',
        onClick: deleteFunction,
        variant: 'danger',
        isLoading: isDeleting,
        className: 'w-full md:w-auto',
      }}
      secondaryAction={{
        label: 'Cancel',
        onClick: () => setIsOpen(false),
        className: 'w-full md:w-auto',
      }}
    />
  )
}
