import React, { useState } from 'react'
import dropDownArrow from '../../assets/dropDownArrow.svg'

export const FAQs = () => {
  const [openedTab, setOpenedTab] = useState<string[]>([])
  const FAQHeading = ({ question }: { question: string }) => {
    return (
      <div
        onClick={() => {
          if (openedTab.includes(question)) {
            setOpenedTab((prev) => {
              return prev.filter((i) => i !== question)
            })
          } else {
            setOpenedTab((prev) => {
              return [...prev, question]
            })
          }
        }}
        className={`border-t flex items-center  border-black p-2 ${
          openedTab.includes(question) && 'bg-black text-white'
        }  `}
      >
        <h2 className="w-full cursor-pointer">{question}</h2>
        <img
          alt="arrow"
          src={dropDownArrow}
          className={` ${
            openedTab.includes(question) && 'rotate-180 changeWhite'
          }`}
        />
      </div>
    )
  }
  return (
    <div className={`w-full mt-4`}>
      <div>
        <FAQHeading question="What is a Portal?" />
        {openedTab.includes('What is a Portal?') && (
          <div className="p-4">
            <p>
              <span className="font-semibold">Non-technical answer:</span>
              <span className="ml-2">
                <a
                  href="https://fileverse.io/portal"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="underline"
                >
                  {' '}
                  Fileverse Portal{' '}
                </a>
                is a web3 alternative to Google Workspace, Notion, or any
                centralized collaboration platform. It is customizable, private
                by design, and focuses on the healthier side of the internet
                where users are not the product.
              </span>
            </p>
            <p className="mt-2">
              <span className="font-semibold">Technical answer:</span>
              <span className="ml-2">
                Each Portal is a self-deployed file management and collaboration
                smart contract. It’s yours and no one else’s. It acts as your
                content hash directory and allows you to set access permissions
                for your files, folders and Portal.
              </span>
            </p>
            <p className="mt-2">
              <span className="">
                The interface/UI is also hosted IPFS, making the whole
                collaboration dApp available to you without requiring our
                servers.
              </span>
            </p>
          </div>
        )}
      </div>
      <div>
        <FAQHeading question="What can I do with a Fileverse Portal?" />
        {openedTab.includes('What can I do with a Fileverse Portal?') && (
          <div className="p-4">
            <p>From your Portal, you can:</p>
            <ul className="ml-6">
              <li>
                <span className="font-semibold">Store</span>, manage, and share
                any multimedia file: images, 3D files, music, video, Zip files,
                PDF, GIFs, etc.
              </li>
              <li>
                <span className="font-semibold">Publish</span> decentralized
                websites, blogs, technical docs, notes, personal
                wikis/databases, and more!
              </li>
              <li>
                <span className="font-semibold">Collaborate</span> with anyone
                via: live documents, whiteboards, and more.
              </li>
              <li>
                <span className="font-semibold">Chat</span> privately with
                everyone with Chat Spaces.
              </li>
              <li>
                <span className="font-semibold">Share</span> the Public side of
                your Portal, for anyone to visit and see what public content you
                have created or uploaded.
              </li>
              <li>
                <span className="font-semibold">Create</span> as many Portals as
                you want, for different needs, different communities, different
                teams, different types of content.
              </li>
              <li>
                <span className="font-semibold">See</span> on-chain analytics of
                people that became “members” of your Portal. Members receive a
                custom NFT of your Portal when they decide to join. We call
                these Portal Tokens and are unique to each Portal.
              </li>
            </ul>
          </div>
        )}
      </div>
      <div>
        <FAQHeading question="For whom was Fileverse Portal built for?" />
        {openedTab.includes('For whom was Fileverse Portal built for?') && (
          <div className="p-4 flex flex-col gap-1">
            <p>
              Onchain individuals and communities deserve onchain tools that
              share the same values.
            </p>
            <p>
              Fileverse is for you, dear anon, who wants a useful, everyday
              onchain tool, that offers a stress-free crypto experience, for
              file sharing, collaboration, communication, and much more!
            </p>
            <p>
              This first version of Portal is for anyone that wants a
              privacy-first and no central point of failure alternative to
              centralized collaboration apps like Google Drive/Workspace,
              Notion, and Microsoft Office.
            </p>
            <p>
              It allows you to create your own ideal collaboration tool and
              workspace. You will be able to pick and choose different
              collaboration or productivity plugins to tailor your portal to
              your needs.
            </p>
          </div>
        )}
      </div>
      <div>
        <FAQHeading question="Where is my data stored?" />
        {openedTab.includes('Where is my data stored?') && (
          <div className="p-4 flex flex-col gap-1">
            <p>
              By default, all the data you add on your Fileverse Portal is
              stored on a peer-to-peer network and file system called{' '}
              <a
                href="https://ipfs.tech/"
                target="_blank"
                rel="noreferrer"
                className="underline"
              >
                IPFS
              </a>
              . You have the option as well to store files permanently via{' '}
              <a
                href="https://www.arweave.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Arweave
              </a>{' '}
              .We are also using one of the most powerful and privacy-enhancing
              decentralised database:{' '}
              <a
                href="https://gun.eco/"
                target="_blank"
                rel="noreferrer"
                className="underline"
              >
                https://gun.eco/
              </a>
            </p>
          </div>
        )}
      </div>
      <div>
        <FAQHeading question="What is kept onchain?" />
        {openedTab.includes('What is kept onchain?') && (
          <div className="p-4 flex flex-col gap-1">
            <p>
              Whenever you upload a file, you sign a transaction to update your
              Portal smart contract with a content hash of your file. You can
              make that hash either publicly accessible or completely private
              and encrypted. The more you interact with your Portal, the more
              your smart contract grows. Your onchain Portal is key for removing
              the dependence on any centralised server and to make your onchain
              life easier with token-gating, etc.
            </p>
          </div>
        )}
      </div>
      <div>
        <FAQHeading question="How does Portal encrypt my data?" />
        {openedTab.includes('How does Portal encrypt my data?') && (
          <div className="p-4 flex flex-col gap-1">
            <p>
              In just two clicks you can decide who you want to give file access
              to: your collaborators, your NFT community, your members, anyone
              with the link to the file, etc. It’s that easy.
            </p>
          </div>
        )}
      </div>
      <div>
        <FAQHeading question="Is Fileverse open source?" />
        {openedTab.includes('Is Fileverse open source?') && (
          <div className="p-4 flex flex-col gap-1">
            <p>
              Yes, Fileverse started as an open source project on Gitcoin and
              received support from over 14,000 contributors! We built this in
              the open, with your feedback and your needs in mind. Here is our
              initial tool,{' '}
              <a
                href="https://beta.fileverse.io/"
                target="_blank"
                rel="noreferrer"
                className="underline"
              >
                Fileverse Solo
              </a>{' '}
              , and another key{' '}
              <a
                href="https://beta.fileverse.io/ipfs"
                target="_blank"
                rel="noreferrer"
                className="underline"
              >
                tool
              </a>{' '}
              we’ve built with our community.
            </p>
            <p>
              In just two clicks you can decide who you want to give file access
              to: your collaborators, your NFT community, your members, anyone
              with the link to the file, etc. It’s that easy.
            </p>
          </div>
        )}
      </div>
      <div>
        <FAQHeading question="Has Fileverse Portal been audited?" />
        {openedTab.includes('Has Fileverse Portal been audited?') && (
          <div className="p-4 flex flex-col gap-1">
            <p>
              Since Fileverse Portal is a file management & collaboration smart
              contract, we have decided to receive an audit by an independent
              super cool team:{' '}
              <a
                href="https://nethermind.io/"
                target="_blank"
                rel="noreferrer"
                className="underline"
              >
                Nethermind
              </a>{' '}
              . The audit has been successfully completed, we’ll share more here
              and on our{' '}
              <a
                href="https://twitter.com/fileverse"
                target="_blank"
                rel="noreferrer"
                className="underline"
              >
                Twitter
              </a>{' '}
              !
            </p>
          </div>
        )}
      </div>
    </div>
  )
}
