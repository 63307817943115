/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState } from 'react'
import { useSearchParams, useParams, useNavigate } from 'react-router-dom'
import { useSignMessage } from 'wagmi'
import { useContractChainId } from '../../store/contract/hooks'
import { setupFileData } from '../../store/contract/thunks'
import { useFileData } from '../../store/files/hooks'
import { useAppDispatch } from '../../store/hooks'
import { useServerKeys } from '../../store/invoker/hooks'
import { setInvokerMembersDecryptionKey } from '../../store/invoker/reducer'
import {
  FileSource,
  FileStatus,
  FileTypeEnum,
} from '../../types/enum/file.enum'
import {
  IFileChatKeyPair,
  IFileURLCache,
  INewFile,
} from '../../types/interface/file.interface'
import {
  handleMemberPrivateFile as getMemberPrivateFileAccess,
  handleTokenGatedFile as getTokenGateFileAccess,
  handleCollaboratorPrivateFile as getCollaboratorPrivateFileAccess,
  getPublicFileUrl,
  IFileAccess,
} from '../../utils/files/filePreviewHandlers'
import sendNotifcation from '../../utils/notification'
import { cacheFileIPFSUrl } from '../../store/files/reducer'
import { useEthersSigner } from '../../hooks/clientToProvider'
import { isAddress } from 'viem'
import { resolveEnsName } from '../../utils/networks/ensResolvers'
import { usePrivyHelper } from '../../hooks/usePrivyHelper'

const useFilePreviewHelper = () => {
  const walletAddress = usePrivyHelper().walletAddress as string
  const [urlParams] = useSearchParams()
  const chainId = parseInt(urlParams.get('chainId') || '')
  const { address: contractAddress, fileId } = useParams()
  const signer = useEthersSigner({ chainId })
  const [downloadUrl, setDownloadUrl] = useState('')
  const [isRecallFileData, setRecallFileData] = useState<boolean>(false)
  const [fileStatus, setFileStatus] = useState<FileStatus>(FileStatus.PENDING)
  const [fileChatKey, setFileChatKey] = useState<IFileChatKeyPair>()
  const [gettingMemberKeys, setGettingMemberKeys] = useState(false)
  const fileData = useFileData(contractAddress as string, fileId)
  const isDPage = fileData?.metadata?.source === FileSource.DPAGE
  const isFile = fileData?.metadata?.source === FileSource.USER_FILE_SYSTEM
  const isWhiteboard = fileData?.metadata?.source === FileSource.EXCALIDRAW
  const isDDoc = fileData?.metadata?.source === FileSource.COLLAB_DOCUMENT
  const isArweave = fileData?.metadata?.source === FileSource.ARWEAVE
  const [isRecallPreviewFile, setRecallPreviewFile] = useState<boolean>(false)
  const { signMessageAsync } = useSignMessage()
  const contractChainId = useContractChainId(contractAddress as string)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const serverKeys = useServerKeys(
    walletAddress as string,
    contractAddress as string
  )

  const cacheFileUrl = (cache: Partial<IFileURLCache>, fileId: number) => {
    dispatch(
      cacheFileIPFSUrl({
        contractAddress: contractAddress as string,
        fileId,
        cache,
      })
    )
  }
  const loadFileContent = (fileAccess: IFileAccess | undefined) => {
    if (fileAccess) {
      const { fileUrl, chatKey } = fileAccess
      setDownloadUrl(fileUrl)
      setFileChatKey(chatKey)
      gettingMemberKeys && setGettingMemberKeys(false)
      setTimeout(() => setFileStatus(FileStatus.READY), 2000)
    } else {
      gettingMemberKeys && setGettingMemberKeys(false)
      setFileStatus(FileStatus.LOCKED)
    }
  }
  const viewMemberPrivateFile = async (fileData: INewFile) => {
    try {
      const fileAccess = await getMemberPrivateFileAccess(
        walletAddress as string,
        fileData,
        serverKeys,
        contractAddress as string,
        chainId,
        signMessageAsync
      )
      if (fileAccess?.decryptionKey) {
        dispatch(
          setInvokerMembersDecryptionKey({
            invoker: walletAddress as string,
            contractAddress: contractAddress as string,
            memberDecryptionKey: fileAccess.decryptionKey,
          })
        )
      }
      cacheFileUrl(
        { contentIPFSUrl: fileAccess?.ipfsUrl },
        parseInt(fileId || '')
      )
      loadFileContent(fileAccess)
    } catch (error: any) {
      setFileStatus(FileStatus.LOCKED)
      sendNotifcation('Failed to view Member file', error?.message, 'danger')
    }
  }
  const handleMemberPrivateFile = async (
    fileData: INewFile,
    walletAddress: string
  ) => {
    if (fileData) {
      if (!walletAddress) {
        setFileStatus(FileStatus.LOCKED)
        return
      }
      try {
        if (signer) {
          await viewMemberPrivateFile(fileData)
        } else {
          setRecallPreviewFile(true)
        }
      } catch (error: any) {
        setFileStatus(FileStatus.LOCKED)
        sendNotifcation('Failed to preview file', `${error}`, 'danger')
      }
    }
  }
  const viewTokenGatedFile = async (fileData: INewFile) => {
    const fileAccess = await getTokenGateFileAccess(
      walletAddress as string,
      fileData,
      serverKeys,
      contractAddress as string,
      chainId,
      signMessageAsync
    )
    cacheFileUrl(
      { contentIPFSUrl: fileAccess?.ipfsUrl },
      parseInt(fileId || '')
    )
    loadFileContent(fileAccess)
  }
  const handleTokenGatedFile = async (
    fileData: INewFile,
    walletAddress: string
  ) => {
    if (fileData) {
      try {
        if (walletAddress) {
          if (signer) {
            await viewTokenGatedFile(fileData)
          } else {
            // we add a flag to recall viewGatedFile when signer is available
            setRecallPreviewFile(true)
          }
        } else {
          setFileStatus(FileStatus.DISCONNECTED)
        }
      } catch (error: any) {
        setFileStatus(FileStatus.LOCKED)
        sendNotifcation('Failed to preview file', `${error}`, 'danger')
      }
    }
  }
  const handleCollaboratorPrivateFile = async (
    fileData: INewFile,
    walletAddress: string
  ) => {
    try {
      if (fileData) {
        const fileAccess = await getCollaboratorPrivateFileAccess(
          walletAddress as string,
          fileData,
          serverKeys
        )
        cacheFileUrl(
          { contentIPFSUrl: fileAccess?.ipfsUrl },
          parseInt(fileId || '')
        )
        loadFileContent(fileAccess)
        return
      }
    } catch (error: any) {
      console.error(error)
      setFileStatus(FileStatus.LOCKED)
      sendNotifcation('Failed to load file', error?.message, 'danger')
    }
  }
  const handlePublicFile = async (fileData: INewFile) => {
    try {
      const result = await getPublicFileUrl(
        fileData.contentIPFSHash,
        fileData.cache?.contentIPFSUrl
      )
      setFileChatKey(fileData.metadata.publicLock?.chatKey as IFileChatKeyPair)
      setDownloadUrl(result.downloadUrl)
      setTimeout(() => setFileStatus(FileStatus.READY), 2000)
      cacheFileUrl({ contentIPFSUrl: result?.ipfsUrl }, parseInt(fileId || ''))
    } catch (error: any) {
      setFileStatus(FileStatus.ERROR)
      sendNotifcation('Failed to load file', error?.message, 'danger')
    }
  }
  const handleFile = async (fileData: INewFile, walletAddress: string) => {
    try {
      setFileStatus(FileStatus.PENDING)
      if (fileData.metadata.isDeleted) {
        sendNotifcation('This file is deleted by the Owner', '', 'danger', 5000)
        navigate('/404')
        return
      } else if (fileData.metadata.fileType === FileTypeEnum.MEMBERS_PRIVATE) {
        await handleMemberPrivateFile(fileData, walletAddress)
        return
      } else if (fileData.metadata.fileType === FileTypeEnum.GATED) {
        await handleTokenGatedFile(fileData, walletAddress)
        return
      } else if (fileData.metadata.fileType === FileTypeEnum.PRIVATE) {
        await handleCollaboratorPrivateFile(fileData, walletAddress)
        return
      } else if (fileData.metadata.fileType === FileTypeEnum.PUBLIC) {
        await handlePublicFile(fileData)
      }
    } catch (error) {
      setFileStatus(FileStatus.ERROR)
    }
  }

  const handleFileSetup = useCallback(async () => {
    try {
      setFileStatus(FileStatus.PENDING)
      if (isNaN(Number(fileId))) {
        sendNotifcation('Invalid file Id', 'File Id is not a number', 'danger')
        navigate('/404')
        return
      }
      if (!isAddress(contractAddress as string)) {
        const address = await resolveEnsName(contractAddress as string)
        if (!address)
          throw new Error('No Address associated with this ENS name')
        navigate(`/${address}/file/${fileId}?chainId=${chainId}`)
      }

      const latestFileData = await dispatch(
        setupFileData({
          contractAddress: contractAddress as string,
          fileId: Number(fileId),
          chainId,
        })
      ).unwrap()

      if (latestFileData?.metadata?.isDeleted)
        throw new Error('File is deleted by the owner')

      await handleFile(latestFileData, walletAddress)
    } catch (error: any) {
      console.error(error)
      sendNotifcation('Failed to load file', error.message, 'danger')
      navigate('/404')
    }
  }, [
    contractAddress,
    fileId,
    chainId,
    walletAddress,
    fileData?.metadata?.editedAt,
  ])

  useEffect(() => {
    handleFileSetup()
  }, [handleFileSetup])

  useEffect(() => {
    if (signer && isRecallPreviewFile && fileData) {
      // recall all file handlers that was prevented to run due to the absence of signer
      if (fileData.metadata.fileType === FileTypeEnum.GATED) {
        handleTokenGatedFile(fileData, walletAddress)
      } else if (fileData.metadata.fileType === FileTypeEnum.MEMBERS_PRIVATE) {
        handleMemberPrivateFile(fileData, walletAddress)
      }
      setRecallPreviewFile(false)
    }
  }, [signer, isRecallPreviewFile, walletAddress])

  return {
    fileStatus,
    setFileStatus,
    downloadUrl,
    signer,
    setRecallFileData,
    fileChatKey,
    viewTokenGatedFile,
    isRecallFileData,
    gettingMemberKeys,
    contractChainId,
    isDPage,
    isFile,
    isWhiteboard,
    isDDoc,
    isArweave,
  }
}

export default useFilePreviewHelper
