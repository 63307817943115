import React from 'react'
import { PublicPortalTourModal } from '../../components/PublicPortalTourModal'
import { PopoverContentProps } from '@reactour/tour'

const TOUR_DESCRIPTION_MAP = {
  TOOLBAR: (
    <p>
      Browse and add <b>files</b>, create <b>sections</b>, add text <b>notes</b>
      , paste any <b>links!</b> Click update to publish the public view of your
      Portal.
    </p>
  ),
  SECTION: (
    <p>
      Rename any title section, click & hold the section to move it to another
      part of your Portal.
    </p>
  ),
  CARD: (
    <p>
      Change the card size and layout. Press and hold the card to move it to a
      different part of your Portal.
    </p>
  ),
  ACCESS_CONTROL: (
    <p>
      This is to enable/disable the public visibility of your Portal. Every
      Portal also has a public side, you control how it looks and what it shows.
    </p>
  ),
}

const publicPortalSteps = [
  {
    selector: '.floating-toolbar-tour',
    content: ({ setCurrentStep, currentStep }: PopoverContentProps) => (
      <PublicPortalTourModal
        icon="LayoutDashboard"
        setCurrentStep={() => setCurrentStep(currentStep + 1)}
        currentStep={currentStep}
        title="Customise your Portal"
        desc={TOUR_DESCRIPTION_MAP.TOOLBAR}
      />
    ),
  },
  {
    selector: '.section-tour',
    content: ({ setCurrentStep, currentStep }: PopoverContentProps) => (
      <PublicPortalTourModal
        icon="LayoutPanelTop"
        currentStep={currentStep}
        setCurrentStep={() => setCurrentStep(currentStep + 1)}
        title="Create sections"
        desc={TOUR_DESCRIPTION_MAP.SECTION}
      />
    ),
  },
  {
    selector: '.card-tour',
    content: ({ setCurrentStep, currentStep }: PopoverContentProps) => (
      <PublicPortalTourModal
        icon="Scaling"
        currentStep={currentStep}
        setCurrentStep={() => setCurrentStep(currentStep + 1)}
        title="Card settings"
        desc={TOUR_DESCRIPTION_MAP.CARD}
      />
    ),
  },
  {
    selector: '.access-control-tour',
    content: ({ setCurrentStep, currentStep }: PopoverContentProps) => (
      <PublicPortalTourModal
        icon="Lock"
        currentStep={currentStep}
        setCurrentStep={() => setCurrentStep(currentStep + 1)}
        title="Access control"
        desc={TOUR_DESCRIPTION_MAP.ACCESS_CONTROL}
      />
    ),
  },
]

export default publicPortalSteps
