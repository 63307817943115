const formatTime = (timestamp: number) => {
  const date = new Date(timestamp * 1000)
  const now = new Date()
  const hours = date.getHours()
  const minutes = date.getMinutes()
  const amOrPm = hours >= 12 ? 'PM' : 'AM'

  const timeString = `${hours > 12 ? hours - 12 : hours}:${minutes
    .toString()
    .padStart(2, '0')} ${amOrPm}`

  if (date.toDateString() === now.toDateString()) {
    // If it's today, show "Today at ..."
    return `Today at ${timeString}`
  } else {
    // Otherwise, show the full date and time
    const month = date.toLocaleString('default', { month: 'short' })
    const day = date.getDate()
    return `${month} ${day} at ${timeString}`
  }
}
export default formatTime
