/* eslint-disable @typescript-eslint/no-explicit-any */
import { Base64 } from 'base64-string'
import { ISEAPair } from 'gun'
import { FileSource } from '../../types/enum/file.enum'
import { IDraft } from '../../types/interface/drafts.interface'
import { IServerKey } from '../../types/interface/invoker.interface'
import { encryptUsingAESKey } from '../crypto'
import { GunInstance, instantiateSEA } from '../instantiateGun'
import sendNotifcation from '../notification'
import { captureMessage, captureException } from '@sentry/react'
import isEmpty from 'lodash/isEmpty'
import { getPluginMetadataNode } from '../../hooks/useGunNode'

interface IResolvedKey {
  seaKeyPair: ISEAPair
  roomKey: string
}
export interface IDraftCreationResult {
  isCreated: boolean
  message: string
  metadata: IDraft
}
export const SEA = instantiateSEA()

export const createAndSaveDraftMetadata = async (
  authKey: ISEAPair,
  key: string,
  serverKeys: IServerKey,
  contractAddress: string,
  rtcId: string,
  ownerAddress: string,
  type: FileSource,
  version = 0
): Promise<IDraftCreationResult> => {
  const portalLock = encryptUsingAESKey(key, serverKeys.portalEncryptionKey)
  const ownerLock = encryptUsingAESKey(key, serverKeys.ownerEncryptionKey)
  const portalMetadataNode = getPluginMetadataNode(
    authKey,
    contractAddress,
    rtcId
  )

  const result = await new Promise((resolve) => {
    portalMetadataNode.put(
      {
        rtcId,
        name: '',
        portalLock,
        ownerLock,
        owner: ownerAddress,
        type,
        version,
        isPublished: false,
      },
      (ack: any) => {
        if (!ack?.err) {
          resolve({
            isCreated: true,
            message: 'success',
            metadata: {
              rtcId,
              name: '',
              portalLock,
              ownerLock,
              owner: ownerAddress,
              type,
              version,
            },
          })
        } else {
          resolve({
            isCreated: false,
            message: ack?.err,
            metadata: {},
          })
        }
      }
    )
  })
  return result as IDraftCreationResult
}

export const getContractDraftsFromGunDB = async (
  authKey: ISEAPair,
  contractAddress: string
) => {
  if (!authKey) return
  const idTracker: string[] = []
  const drafts: IDraft[] = []
  const portalDraftsNode = GunInstance.getAuthenticatedUserNode(
    authKey as ISEAPair
  ).get(`${contractAddress}/rtc`)

  await Promise.race([
    new Promise(() => {
      portalDraftsNode.map().on((data: IDraft, id: string) => {
        if (!idTracker.includes(id) && data) {
          idTracker.push(id)
          drafts.push(data)
        }
      })
    }),
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(undefined)
      }, 5000)
    }),
  ])
  portalDraftsNode.off()
  return drafts
}

export const getUserDraftPermission = (
  draft: IDraft,
  serverKeys: IServerKey,
  account: string
) => {
  const permission = {
    owner: false,
    collaborator: false,
  }
  if (draft.owner === account && serverKeys.ownerDecryptionKey) {
    permission.owner = true
  }
  if (serverKeys.portalDecryptionKey) {
    permission.collaborator = true
  }
  return permission
}

export const removeDraft = async (
  authenticatedMetadataNode: any,
  rtcId: string,
  callback?: (rtcId?: string) => void
) => {
  const req = indexedDB.deleteDatabase(rtcId)
  req.onerror = () => {
    sendNotifcation(
      'Something weird happened',
      'Draft had trouble being deleted from indexdb',
      'danger'
    )
    callback && callback()
  }
  req.onsuccess = async () => {
    await authenticatedMetadataNode.put(null, (ack: any) => {
      if (ack?.err) {
        sendNotifcation('Failed to remove draft from gun', '', 'danger')
        callback && callback()
      } else {
        callback && callback(rtcId)
      }
    })
  }
}

export const editDraftName = async (
  name: string,
  authenticatedMetadataNode: any
) => {
  await authenticatedMetadataNode.get('name').put(name, (ack: any) => {
    if (ack?.err) {
      sendNotifcation('Failed to save document title', ack?.err, 'danger')
    }
  })
}
export const getDraftMetadata = async (
  authKey: ISEAPair,
  contractAddress: string,
  rtcId: string
): Promise<IDraft | undefined> => {
  const portalDraftMetaDataNode = getPluginMetadataNode(
    authKey,
    contractAddress,
    rtcId
  )
  const draftMetadata = await new Promise((resolve) => {
    portalDraftMetaDataNode.once(
      (data: IDraft) => {
        if (!data)
          captureMessage(
            `Failed to get draft metadata for ${rtcId} and ${contractAddress}`
          )
        resolve(data)
      },
      // wait for 2 seconds else resolve anyway
      { wait: 2000 }
    )
  })
  return draftMetadata as IDraft
}
export const resolveCollabRoomKey = (key: string): IResolvedKey | ISEAPair => {
  const enc = new Base64()
  const b64 = enc.decode(key)
  const roomKeyMaterial = JSON.parse(b64)
  return roomKeyMaterial
}
export const getLinkFormatedUrl = (link: string) => {
  const formatedLink = link.replace('/#', '')
  return formatedLink
}
export const getRoomInfoFromLink = (link: string) => {
  const formatedLink = getLinkFormatedUrl(link)
  const url = new URL(formatedLink)

  const urlSearchParams = url.searchParams
  const roomId = url.pathname
    .substring(url.pathname.lastIndexOf('/'))
    .replace('/', '')
  const isCollaborating = urlSearchParams.get('collab')
  const rtcKey = urlSearchParams.get('key')
  const path = url.pathname.substring(1, url.pathname.length)
  const contractAddress = path.substring(0, path.indexOf('/'))
  return { roomId, isCollaborating, rtcKey, contractAddress }
}

export const getOrCreateDdocsMetadata = async (
  authKey: ISEAPair,
  contractAddress: string,
  rtcId: string,
  serverKeys: IServerKey,
  rtcKey: string,
  ownerAddress: string
) => {
  const draftMetadata = await getDraftMetadata(authKey, contractAddress, rtcId)
  if (draftMetadata && !isEmpty(draftMetadata)) return draftMetadata
  const { isCreated, metadata, message } = await createAndSaveDraftMetadata(
    authKey,
    rtcKey,
    serverKeys,
    contractAddress,
    rtcId,
    ownerAddress,
    FileSource.COLLAB_DOCUMENT,
    2
  )
  if (isCreated) return metadata
  captureMessage(`Failed to create draft metadata for ${rtcId}`)
  captureException(message)
  throw new Error('Failed to create draft metadata')
}

export const updatePluginMetadata = async (
  newMetadata: Partial<IDraft>,
  authenticatedMetadataNode: any
) => {
  const entries = Object.entries(newMetadata)

  for (const [key, value] of entries) {
    await authenticatedMetadataNode.get(key).put(value)
  }
}
