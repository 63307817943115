import * as React from 'react'
import * as TooltipPrimitives from '@radix-ui/react-tooltip'

import cn from 'classnames'
import { cva, VariantProps } from 'class-variance-authority'
import { motion } from 'framer-motion'

const TooltipProvider = TooltipPrimitives.Provider

const TooltipRoot = TooltipPrimitives.Root

const TooltipTrigger = TooltipPrimitives.Trigger

const TooltipArrow = TooltipPrimitives.Arrow

const TooltipPortal = TooltipPrimitives.Portal

const TooltipContent = TooltipPrimitives.Content

const tooltipVariants = cva('', {
  variants: {
    position: {
      bottom: '-my-1',
      top: '-my-1',
      right: '-mx-1',
      left: '-mx-1',
    },
    beakAlignment: {
      start: 'start',
      center: 'center',
      end: 'end',
    },
    defaultVariants: {
      variant: 'bottom',
      beakPosition: 'center',
    },
  },
})

const positionAnimations = {
  top: {
    initial: { opacity: 0 },
    animate: { opacity: 1, y: -12 },
    exit: { opacity: 0, y: 0, transition: { ease: 'easeOut', duration: 0.2 } },
  },
  bottom: {
    initial: { opacity: 0 },
    animate: { opacity: 1, y: 5 },
    exit: { opacity: 0, y: 0, transition: { ease: 'easeOut', duration: 0.2 } },
  },
  left: {
    initial: { opacity: 0 },
    animate: { opacity: 1, x: -5 },
    exit: { opacity: 0, x: 0, transition: { ease: 'easeOut', duration: 0.2 } },
  },
  right: {
    initial: { opacity: 0 },
    animate: { opacity: 1, x: 5 },
    exit: { opacity: 0, x: 0, transition: { ease: 'easeOut', duration: 0.2 } },
  },
}

export interface TooltipProps
  extends React.HTMLAttributes<HTMLElement>,
    VariantProps<typeof tooltipVariants> {
  text: string
}

export const Tooltip = React.forwardRef<HTMLElement, TooltipProps>(
  ({ children, className, text, position, beakAlignment, ...props }) => {
    const beakPosition = ({
      position,
      beakAlignment,
    }: VariantProps<typeof tooltipVariants>) => {
      const verticle = position === 'bottom' || position === 'top'
      const horizontal = position === 'right' || position === 'left'

      if (verticle && beakAlignment === 'start') return '[&>span]:!left-[10%]'
      if (horizontal && beakAlignment === 'start') return '[&>span]:!top-[20%]'
      if (verticle && beakAlignment === 'end') return '[&>span]:!left-[80%]'
      if (horizontal && beakAlignment === 'end') return '[&>span]:!top-[60%]'
      if (horizontal && beakAlignment === 'center') return 'my-0 -mx-1'
      return '-my-1'
    }

    return (
      <TooltipProvider>
        <TooltipRoot>
          <TooltipTrigger data-testid="tooltip-trigger">
            {children}
          </TooltipTrigger>
          <TooltipPortal>
            <motion.div
              initial={positionAnimations[position || 'top'].initial}
              animate={positionAnimations[position || 'top'].animate}
              exit={positionAnimations[position || 'top'].exit}
              transition={{
                duration: 0.2,
                ease: 'easeInOut',
              }}
              className={cn({
                ['hidden']: !text,
              })}
            >
              <TooltipContent
                sideOffset={5}
                className={cn(
                  'max-w-sm min-h-[2vh] overflow-hidden rounded color-bg-default-inverse p-2 text-helper-text-sm color-text-inverse text-center z-[99]',
                  tooltipVariants({ position, className }),
                  beakPosition({ position, beakAlignment }),
                  className
                )}
                {...props}
                side={position || 'top'}
                align={beakAlignment || 'center'}
              >
                <p>{text}</p>
                <TooltipArrow width={18} height={9} />
              </TooltipContent>
            </motion.div>
          </TooltipPortal>
        </TooltipRoot>
      </TooltipProvider>
    )
  }
)

Tooltip.displayName = 'Tooltip'
