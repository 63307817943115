/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useState } from 'react'
import CarouselBanner from './CarouselBanner'
import PortalDeploymentSteps from './PortalDeploymentSteps'
import LogoSection from '../common/LogoSection'
import { ButtonGroup, Divider } from '@fileverse/ui'
import { Button } from '../../pages/PublicPortal/components/Button'
import { usePrivyHelper } from '../../hooks/usePrivyHelper'
import cn from 'classnames'
import Spinner from '../Spinner'
import { useSafeAppStatus } from '../../store/common/hooks'
import useMediaQuery from '../../hooks/useMediaQuery'

export default function Onboarding() {
  const [showVideo, toggleVideo] = useState<boolean>(false)
  const isMaxHeight800px = useMediaQuery('(max-height : 800px)')

  return (
    <div className="w-full h-screen flex flex-col lg:flex-row relative items-center justify-between lg:justify-center gap-0">
      <div className="block lg:hidden">
        <LogoSection />
      </div>
      <CarouselBanner showVideo={showVideo} />
      <div
        className={cn(
          'flex-1 h-full w-full lg:py-0 px-4 flex flex-col items-center justify-center',
          showVideo ? 'pb-0 pt-6' : 'py-6',
          isMaxHeight800px ? 'lg:h-[85vh]' : 'lg:h-[750px]'
        )}
      >
        <div className="flex flex-col items-center justify-between h-full w-full text-center mx-auto">
          {/* Logo */}
          <div className="hidden lg:block">
            <LogoSection />
          </div>
          <CTASection toggleVideoPlayer={() => toggleVideo(true)} />
          <div />
        </div>
      </div>
    </div>
  )
}

const CTASection = memo(function CTASection({
  toggleVideoPlayer,
}: {
  toggleVideoPlayer: () => void
}) {
  const isDisconnected = usePrivyHelper().isDisconnected

  return isDisconnected ? (
    <SignupSection />
  ) : (
    <div className="w-full text-left">
      <PortalDeploymentSteps toggleVideoPlayer={toggleVideoPlayer} />
    </div>
  )
})

export const SignupSection = () => {
  const { isLoading, loginWithSocials, connectInjectedWallet } =
    usePrivyHelper()
  const isSafeApp = useSafeAppStatus()
  if (isLoading) return <Spinner />
  return (
    <div className="space-y-6 w-full">
      <div className="space-y-2">
        {/* Heading Title */}
        <h1 className="text-heading-xlg-bold color-text-default">
          Create your Fileverse account
        </h1>
        {/* Description Paragraph */}
        <p className="color-text-secondary text-body-sm text-center">
          Collaborate Onchain, experience a better Internet. <br />
          Decentralised, encrypted, modular.
        </p>
      </div>
      {/* Button Group */}
      <ButtonGroup className="space-x-0 relative flex-col gap-4 w-full max-w-[448px] mx-auto">
        <Button className="w-full" onClick={connectInjectedWallet}>
          Connect wallet
        </Button>
        {isSafeApp ? null : (
          <>
            <Divider className="w-full " />
            <span className="absolute top-1/2 left-1/2 -transalte-x-1/2 -translate-y-1/2 w-8 bg-white color-text-secondary text-body-sm-bold">
              or
            </span>
            <Button
              onClick={loginWithSocials}
              variant="secondary"
              className="w-full "
            >
              Sign up with social
            </Button>
          </>
        )}
      </ButtonGroup>
    </div>
  )
}
