/* eslint-disable @typescript-eslint/no-explicit-any */
import { ICredential } from './../../types/interface/invoker.interface'
import { IFileMetaData } from '../../types/interface/file.interface'
import { getContractFile, getContractFileCount } from '../../utils/contract'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { AppState } from '..'
import { isAddress, Signer } from 'ethers'
import { FileTypeEnum } from '../../types/enum/file.enum'
import { uploadFileAPI } from '../../api/fileAPI/uploadFile'
import createGateApi from '../../api/gateapi/createGateApi'
import { TokenGateHashInfo } from '../../types/interface/token.interface'
import { MakeFileFromObject } from '../../utils/makeFileFromObject'
import isEmpty from 'lodash/isEmpty'
import sendNotifcation from '../../utils/notification'
import { verifyPortalChainId } from '../contract/thunks'
import { resolveEnsName } from '../../utils/networks/ensResolvers'
import { IAddFilePayload, addFile } from './reducer'
interface FetchAllFilesRequest {
  contractAddress: string
  chainId: number
  fetchLatest?: boolean
}

interface UploadToIpfsPayload {
  cancelToken: any
  credential: ICredential
  contractAddress: string
  invoker: string
  contentFile: File
  metadataFile: File
  uploadProgressHandler?: (progressEvent: any) => void
  chainId: number
  fileTags: any
}
interface UploadFilePayload {
  credential: ICredential
  contractAddress: string
  invoker: string
  file: File
  fileName: string
  chainId: number
}

export interface UploadToContractPayload {
  contractAddress: string
  contractSigner: Signer
  contentIpfsHash: string
  metadataIpfsHash: string
  fileType: FileTypeEnum
  handleFileAddedEvent?: (
    event: any,
    hash: string,
    uploadInfo: UploadToContractPayload
  ) => Promise<void>
  metadata?: IFileMetaData
  gateIpfsHash?: string
  confirmTx?: () => void
}

export interface CreateEditGatePayload {
  tokenParam: string
  credential: ICredential
  domainContractAddress: string
  invoker: string
  includeCollaborators: boolean
  name: string
  amount: number
  tokenChainName: string
  chainId: number
}

export const fetchFile = createAsyncThunk(
  'newFile/fetchFile',
  async (payload: {
    fileId: number
    contractAddress: string
    chainId: number
    totalFilesOnContract?: number
  }): Promise<IAddFilePayload> => {
    const { fileId, contractAddress } = payload
    const file = await getContractFile(
      fileId,
      contractAddress,
      payload.totalFilesOnContract
    )
    if (isEmpty(file)) throw new Error('File not found')
    return {
      fileData: file,
      contractAddress: contractAddress,
    }
  }
)

export const fetchAllFiles = createAsyncThunk(
  'files/fetchAllFiles',
  async (payload: FetchAllFilesRequest, { getState, dispatch }) => {
    const state: AppState = getState() as AppState
    const contractFiles = state.files.files[payload.contractAddress] || {}
    const fileCount = await getContractFileCount(payload.contractAddress)
    const { fetchLatest = false } = payload
    // fetch the details of files which are not present in store
    for (let i = 0; i < fileCount; i++) {
      if (i in contractFiles && !fetchLatest) continue
      dispatch(
        fetchFile({
          fileId: i,
          contractAddress: payload.contractAddress,
          chainId: payload.chainId,
          totalFilesOnContract: fileCount,
        })
      )
    }
  }
)

export const uploadFileToIpfs = createAsyncThunk(
  'files/uploadFileToIpfs',
  async (
    payload: UploadToIpfsPayload
  ): Promise<{ contentIpfsHash: string; metadataIpfsHash: string }> => {
    const {
      credential,
      contractAddress,
      chainId,
      invoker,
      contentFile,
      metadataFile,
      fileTags,
    } = payload
    const contentUploadRequest = {
      credential,
      file: contentFile,
      name: contentFile.name,
      config: {
        onUploadProgress: payload.uploadProgressHandler,
        cancelToken: payload.cancelToken.token,
      },
      contractAddress,
      invoker,
      chain: chainId,
    }
    const metadataUploadRequest = {
      credential,
      file: metadataFile,
      name: metadataFile.name,
      contractAddress,
      invoker,
      chain: chainId,
    }

    const [contentResponse, metadataResponse] = await Promise.all([
      uploadFileAPI(contentUploadRequest),
      uploadFileAPI(metadataUploadRequest, fileTags),
    ])
    const contentIpfsHash = contentResponse.data.ipfsHash
    const metadataIpfsHash = metadataResponse.data.ipfsHash
    return {
      contentIpfsHash,
      metadataIpfsHash,
    }
  }
)
export const uploadSingleFileToIpfs = createAsyncThunk(
  'singleFile/uploadToIpfs',
  async (payload: UploadFilePayload): Promise<string> => {
    const { credential, file, fileName, chainId, contractAddress, invoker } =
      payload
    const fileUploadRequest = {
      credential,
      file,
      name: fileName,
      contractAddress,
      invoker: invoker,
      chain: chainId,
    }
    const contentIpfsHash = await uploadFileAPI(fileUploadRequest)
    return contentIpfsHash.data.ipfsHash
  }
)
/**
 * TODO: Remove createEditGate and use createGate as the only function to create gate
 */
export const createEditGate = createAsyncThunk(
  'files/createEditGate',
  async (
    payload: CreateEditGatePayload,
    { dispatch }
  ): Promise<{ hash: string; key: string }> => {
    const {
      tokenParam,
      credential,
      domainContractAddress,
      invoker,
      includeCollaborators,
      amount,
      name,
      tokenChainName,
      chainId,
    } = payload
    const [contractAddress] = tokenParam.split(':')
    const param = [tokenParam]
    const createGateRequest = {
      params: param,
      credentialEditSecret: credential.editSecret,
      contractAddress: domainContractAddress,
      invoker,
      includeCollaborators,
      chain: chainId,
    }
    const createResponse = await createGateApi(createGateRequest)
    const { gateId, gateKey, params } = createResponse.data
    const gate: TokenGateHashInfo = {
      gateId,
      params,
      image: createResponse.data?.image || '',
      name: createResponse.data?.name || name || '',
      address: contractAddress,
      amount: amount,
      chain: tokenChainName,
    }
    const gateFile = MakeFileFromObject(gate, 'gate.json')
    const gateIpfsHash = await dispatch(
      uploadSingleFileToIpfs({
        credential,
        file: gateFile,
        fileName: 'gate.json',
        contractAddress: domainContractAddress,
        invoker,
        chainId,
      })
    ).unwrap()
    return { hash: gateIpfsHash, key: gateKey }
  }
)
export const fetchAndSaveFile = createAsyncThunk(
  'file/fetchAndSaveFile',
  async (
    payload: {
      contractAddress: string
      chainId: number
      onResolvedContractENSname: (address: string) => void
      onError: () => void
      onInvalidFile: () => void
      fileId: string
    },
    { dispatch }
  ) => {
    try {
      const portalAddress = payload.contractAddress
      if (!isAddress(portalAddress as string)) {
        const address = await resolveEnsName(payload.contractAddress as string)
        if (address) {
          payload.onResolvedContractENSname(address)
        } else {
          sendNotifcation(
            'Failed to load portal',
            'The portal ens name provided couldnt be resolved. Try using the portal address instead',
            'danger',
            7000
          )
        }
        return
      }
      const verifiedChainId = await dispatch(
        verifyPortalChainId({
          chainId: payload.chainId,
          contractAddress: portalAddress as string,
        })
      ).unwrap()
      if (!verifiedChainId) {
        sendNotifcation(
          'Failed to load file',
          'We are unable to verify this portal chain id. The contract address specified was not found in any of our supported networks',
          'danger',
          7000
        )
        return
      }
      const file = await getContractFile(
        parseInt(payload.fileId as string),
        payload.contractAddress as string
      )
      if (file) {
        if (!file.metadata.isDeleted) {
          dispatch(
            addFile({
              contractAddress: payload.contractAddress,
              fileData: file,
            })
          )
        } else {
          payload.onInvalidFile()
        }
      } else {
        payload.onInvalidFile()
        sendNotifcation('Failed to load file', 'File not found', 'danger', 7000)
      }
    } catch (error: any) {
      payload.onError()
      sendNotifcation('Failed to load file', error.message, 'danger', 7000)
    }
  }
)

export const getFileData = createAsyncThunk(
  'file/getFile',
  async (
    payload: { contractAddress: string; fileId: number },
    { getState }
  ) => {
    const appState: AppState = getState() as AppState
    const { contractAddress, fileId } = payload

    const file = appState.files.files?.[contractAddress]?.[fileId]

    return file
  }
)
