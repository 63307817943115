import { Agent } from '@fileverse/fileverse-agent'
import { getAppDefaultProvider } from './contract'
import { decryptUsingRSAKey, encryptUsingRSAKey } from './crypto'
import { ISEAPair } from 'gun'
import {
  getInvokerStateFromIndexDb,
  queryIdbContractByAddress,
  queryIdbInvokerByAddress,
} from '../store/middleware/utils'
import { MissingAgentKeyError, MissingPortalDecryptionKeyError } from './errors'
import { getSignlessKeyNode } from '../hooks/useGunNode'
import isEmpty from 'lodash/isEmpty'
import { gunInstance, GunInstance } from './instantiateGun'
import { Wallet } from 'ethers'

export const agent = new Agent(gunInstance)
export const hasAgent = async (contractAddress: string) => {
  const _hasAgent = await agent.hasAgent(contractAddress)

  return _hasAgent
}

export const createAgent = async (contractAddress: string, key: string) => {
  const result = await agent.createAgent({
    password: key,
    address: contractAddress,
  })
  return result
}
export const encryptAndStoreAgentKey = async (
  data: string,
  encryptionKey: string,
  contractAddress: string,
  agentAddress: string,
  authKey: ISEAPair
) => {
  const encryptedKey = await encryptUsingRSAKey(data, encryptionKey)
  const signlessKeyNode = getSignlessKeyNode(authKey, contractAddress)
  const agentInfo = {
    key: encryptedKey,
    agent: agentAddress,
    createdAt: Date.now(),
  }
  await GunInstance.putGunNodeData(signlessKeyNode, agentInfo)
  return encryptedKey
}

export const getAgentSigner = async (contractAddress: string, key: string) => {
  const _hasAgent = await hasAgent(contractAddress)

  if (_hasAgent) {
    const provider = getAppDefaultProvider()
    const wallet = await agent.getAgent({
      address: contractAddress,
      password: key,
    })
    const v6Wallet = new Wallet(wallet.privateKey, provider)

    return v6Wallet
  } else {
    throw new Error(`agent was not found for ${contractAddress}`)
  }
}

export const updateAgentStorageAddress = async (
  newAddress: string,
  prevAddress: string
) => {
  const result = await agent.updateAgentStorageAddress({
    newAddress,
    prevAddress,
  })
  return result
}

export const getAgentWallet = async (
  walletAddress: string,
  portalAddress: string
) => {
  const invokerState = await getInvokerStateFromIndexDb()
  const currentInvoker = invokerState.invokers[walletAddress] || {}
  const serverKeys = currentInvoker.serverKeys[portalAddress] || {}
  const { portalDecryptionKey, agentKey } = serverKeys

  if (!agentKey)
    throw new MissingAgentKeyError(
      `Agent Key is Missing for user: ${walletAddress} and portal: ${portalAddress}`
    )
  if (!portalDecryptionKey)
    throw new MissingPortalDecryptionKeyError(
      `Portal Decryption Key is Missing for user: ${walletAddress} and portal: ${portalAddress}`
    )

  const agentPassword = JSON.parse(
    await decryptUsingRSAKey(agentKey, portalDecryptionKey)
  )

  return await getAgentSigner(portalAddress, agentPassword)
}

export const isSignlessEnabled = async (
  portalAddress: string,
  walletAddress: string
) => {
  const invokerState = await queryIdbInvokerByAddress(walletAddress)
  const contractState = await queryIdbContractByAddress(portalAddress)
  const {
    enabled_signless = false,
    agentAddress,
    collaborators,
    owner,
  } = contractState
  const { credentials = {}, serverKeys = {} } = invokerState
  const credential = credentials[portalAddress]

  if (
    enabled_signless ||
    (agentAddress && collaborators.includes(agentAddress))
  )
    return true

  if (walletAddress === owner && !agentAddress && !isEmpty(credential))
    return false

  const { agentKey } = serverKeys[portalAddress] || {}
  if (!agentAddress && !agentKey) return false

  return true
}
