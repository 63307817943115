import { useEffect } from 'react'
import { getCoverFromIPFS } from '../utils/getIPFSAsset'

interface UseCoverImageProps {
  ipfsHash: string | undefined
  setCoverImage: React.Dispatch<React.SetStateAction<string>>
}

export const useCoverImage = ({
  ipfsHash,
  setCoverImage,
}: UseCoverImageProps) => {
  useEffect(() => {
    const fetchCover = async () => {
      if (ipfsHash) {
        try {
          const imageURL = await getCoverFromIPFS(ipfsHash)
          setCoverImage(imageURL)
        } catch (error) {
          console.error('Failed to get cover from IPFS:', error)
        }
      }
    }
    fetchCover()
  }, [ipfsHash, setCoverImage])
}
