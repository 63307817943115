/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
// import { ConnectButton } from '@rainbow-me/rainbowkit'
import CTAButton from './CTAButton'
import { useContract } from '../../store/contract/hooks'
import { Tooltip, useMediaQuery } from '@mui/material'
import { useLocalStorage } from 'usehooks-ts'
import { isAddress } from 'ethers'
import { usePrivy } from '@privy-io/react-auth'
import { usePrivyHelper } from '../../hooks/usePrivyHelper'
import { Button } from '@fileverse/ui'

const WalletDisconnected = ({
  collabDoc,
  joinDoc,
}: {
  collabDoc?: boolean
  joinDoc?: () => void
}) => {
  const connectButtonRef = useRef<HTMLButtonElement>(null)
  const navigate = useNavigate()
  const { address: contractAddress } = useParams()
  const contract = useContract((contractAddress as string) || '')
  const isMediaMax1025px = useMediaQuery('(max-width : 1025px)')
  const { connectOrCreateWallet } = usePrivy()

  return (
    <div
      className={`${
        isMediaMax1025px && 'flex justify-center items-center'
      } w-full h-full`}
    >
      <div
        className={`h-full ${
          isMediaMax1025px && ' rounded-lg border-[#E2E2E2]'
        } w-full flex justify-center items-center`}
      >
        <div
          className={` ${
            !isMediaMax1025px && 'border-[2px] border-[#E2E2E2]'
          }  px-12 py-6 rounded-lg flex flex-col gap-4`}
        >
          <div
            className="text-center font-semibold 
         text-[#000000] text-2xl"
          >
            {collabDoc
              ? `Collaborate with ${contract?.metadata?.name} <3 `
              : 'Wallet Not Connected'}
          </div>
          <div className="w-full flex justify-center">
            <div
              className={`text-center ${
                !collabDoc && 'max-w-[500px]'
              }  font-medium text-base text-[#777777]`}
            >
              {collabDoc ? (
                <p>
                  Connect wallet or Join directly to start collaborating on this{' '}
                  <br />
                  decentralised and end-to end-encrypted document!
                </p>
              ) : (
                'To redirect you to the relevant page, please connect your wallet. You can also create a new Portal!'
              )}
            </div>
          </div>

          <div className=" mt-4 w-full flex justify-center">
            {collabDoc && joinDoc ? (
              <AddUsernameOrConnectWallet
                joinDoc={joinDoc}
                connectButtonRef={connectButtonRef}
              />
            ) : (
              <div
                className={` ${
                  isMediaMax1025px
                    ? 'flex flex-col gap-4'
                    : 'flex gap-32 flex-wrap justify-between'
                }`}
              >
                <div className="flex items-center">
                  <>
                    {!isMediaMax1025px ? (
                      <CTAButton
                        id="wallet-connect-btn"
                        btnref={connectButtonRef}
                        title="View my Portals"
                        onClick={connectOrCreateWallet}
                      />
                    ) : (
                      <button
                        onClick={connectOrCreateWallet}
                        className={`flex w-[253px] bg-white border-black border-2 font-extrabold justify-center  items-center box-border px-6 py-2 h-10 rounded-md`}
                      >
                        <div className="flex gap-2 w-full h-full items-center">
                          <p className="text-center w-full">
                            {'View my Portals'}
                          </p>
                        </div>
                      </button>
                    )}
                  </>
                </div>

                <div className="flex items-center">
                  {!isMediaMax1025px ? (
                    <CTAButton
                      btnref={connectButtonRef}
                      id="wallet-connect-btn"
                      title="Create new Portal"
                      onClick={() => {
                        navigate('/create')
                      }}
                      backgroundColorClassName={
                        'bg-black text-white border-black border-2'
                      }
                    />
                  ) : (
                    <button
                      onClick={() => {
                        navigate('/create')
                      }}
                      className={`flex w-[253px] bg-black text-white border-black border-2 font-extrabold justify-center  items-center box-border px-6 py-2 h-10 rounded-md`}
                    >
                      <div className="flex gap-2 w-full h-full items-center">
                        <p className="text-center w-full">
                          {'Create new Portal'}
                        </p>
                      </div>
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
export default WalletDisconnected

const AddUsernameOrConnectWallet = ({
  joinDoc,
  connectButtonRef,
}: {
  joinDoc: (username?: string) => void
  connectButtonRef: React.RefObject<HTMLButtonElement>
}) => {
  const t = localStorage.getItem('username')
  const y = t ? JSON.parse(t) : ''
  const [username, setUserName] = useState(y)
  const [oldUserName, saveUsernameToLS] = useLocalStorage('username', '')
  const { loginWithSocials, connectInjectedWallet } = usePrivyHelper()

  return (
    <div className="flex gap-4 flex-wrap justify-center items-center">
      <div className="flex items-center">
        <input
          type="text"
          name="collaborator-name"
          id="collaborator-name"
          className="box-border border p-2  rounded-bl-lg rounded-tl-lg"
          placeholder="Enter your name"
          defaultValue={oldUserName}
          onChange={(e) => setUserName(e.target.value)}
        />
        <Tooltip
          title="Join this dDoc anonymously. You can connect your wallet later to show your ENS!"
          placement="bottom"
        >
          <span>
            <button
              className={` text-white font-bold flex items-center justify-center box-border py-2 px-6 h-full rounded-tr-lg rounded-br-lg ${
                username.trim()
                  ? 'bg-black'
                  : 'opacity-50 bg-gray-400 cursor-not-allowed'
              }`}
              onClick={() => {
                if (!isAddress(username)) {
                  saveUsernameToLS(username)
                }
                joinDoc(username)
              }}
              disabled={username.trim() === ''}
            >
              Join
            </button>
          </span>
        </Tooltip>
      </div>

      <div className="font-bold text-xl">OR</div>

      <div className="flex items-center">
        <Tooltip title="Connect your wallet to show your 0x or ENS while collaborating live on this dDoc!">
          <div className="flex gap-[10px]">
            <Button
              id="wallet-connect-btn"
              btnref={connectButtonRef}
              title="Connect wallet"
              backgroundColorClassName={
                'bg-black text-white border-black border-2'
              }
              onClick={connectInjectedWallet}
            >
              Connect Wallet
            </Button>
            <Button
              id="wallet-connect-btn"
              btnref={connectButtonRef}
              title="Social Login"
              onClick={loginWithSocials}
            >
              Social Login
            </Button>
          </div>
        </Tooltip>
      </div>
    </div>
  )
}
