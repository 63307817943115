import axios from 'axios'
import { generateToken } from '../utils'

const rejectPortalInviteAPI = async ({
  contractAddress,
  editSecret,
  invoker,
  chain,
  notificationId,
}: {
  contractAddress: string
  editSecret?: string
  invoker: string
  chain: number
  portalAddress?: string
  notificationId: string
}) => {
  if (!editSecret) return
  const token = await generateToken(contractAddress, editSecret || '')
  const URL = `${process.env.REACT_APP_COORDINATOR_URL}/notification/reject`
  return axios.post(
    URL,
    {
      notificationId,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        invoker,
        contract: contractAddress,
        chain,
      },
    }
  )
}
export default rejectPortalInviteAPI
