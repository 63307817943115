import { createSlice } from '@reduxjs/toolkit'

interface PortalArweaveConfig {
  disableWarning: Record<string, boolean>
}

const initialState: PortalArweaveConfig = {
  disableWarning: {},
}

const portalArweaveConfigSlice = createSlice({
  name: 'portalArweaveConfig',
  initialState,
  reducers: {
    updatePortalArweaveConfigState(state: PortalArweaveConfig, action): void {
      const { contractAddress, isDisabled } = action.payload
      if (!contractAddress || !isDisabled) return
      state.disableWarning = {
        [contractAddress]: isDisabled,
      }
    },
  },
})

export const { updatePortalArweaveConfigState } =
  portalArweaveConfigSlice.actions

export default portalArweaveConfigSlice.reducer
