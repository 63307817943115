import { getCollaboratorKeys } from './../contract'
import { ContractPermissionEnum } from './../../types/enum/contract.enum'
import { createMessageAPI } from '../../api/message/messageAPI'
import { MessageTopicEnum } from '../../types/enum/message.enum'
import {
  ICredential,
  IServerKey,
} from '../../types/interface/invoker.interface'
import { encryptServerKeys } from '../libCrypto'
import * as ucans from 'ucans'
import isNull from 'lodash/isNull'

// export const requestServerKeys = async (
//   invokerAddress: string,
//   credential: ICredential,
//   chain: number
// ) => {
//   const keyExchangeKeyPair = await generateRSAKeyPair()
//   const publicKey = await window.crypto.subtle.exportKey(
//     'spki',
//     keyExchangeKeyPair.publicKey
//   )
//   const privateKey = await window.crypto.subtle.exportKey(
//     'pkcs8',
//     keyExchangeKeyPair.privateKey
//   )
//   const encryptionKey = convertTypedArrayToString(new Uint8Array(publicKey))
//   const decryptionKey = convertTypedArrayToString(new Uint8Array(privateKey))

//   localStorage.setItem('keyExchangeEncryptionKey', encryptionKey)
//   localStorage.setItem('keyExchangeDecryptionkey', decryptionKey)

//   const requestPayload = {
//     contractAddress: credential.contractAddress,
//     credential,
//     invokerAddress,
//     topic: MessageTopicEnum.KEY_REQUEST,
//     content: encryptionKey,
//     forAddress: '*',
//     chain,
//   }

//   await createMessageAPI(requestPayload)
// }

export const publishServerKeys = async (
  level: ContractPermissionEnum,
  messageEncryptionKeyPem: string,
  serverKeys: IServerKey,
  invokerAddress: string,
  credential: ICredential,
  forAddress: string,
  chain: number
) => {
  const ucanKeyPair = ucans.EdKeypair.fromSecretKey(credential.viewSecret)
  const memberDids = await getCollaboratorKeys(
    credential.contractAddress,
    forAddress
  )
  const viewDid = memberDids.viewDid
  if (isNull(viewDid)) return
  const encryptedServerKeyOptions = await encryptServerKeys(
    messageEncryptionKeyPem,
    serverKeys
  )

  const contentPayload = {
    key: encryptedServerKeyOptions.key,
    iv: encryptedServerKeyOptions.iv,
    encryptedPortalDecryptionKey:
      encryptedServerKeyOptions.encryptedPortalDecryptionKey,
    encryptedPortalEncryptionKey:
      encryptedServerKeyOptions.encryptedPortalEncryptionKey,
    encryptedMemberEncryptionKey:
      encryptedServerKeyOptions.encryptedMemberEncryptionKey,
    encryptedMemberDecryptionKey:
      encryptedServerKeyOptions.encryptedMemberDecryptionKey,
    encryptedPortalGunKey: encryptedServerKeyOptions.encryptedPortalGunKey,
  }

  const ucan = await ucans.build({
    audience: viewDid,
    issuer: ucanKeyPair,
    lifetimeInSeconds: 7 * 86400,
    capabilities: [
      {
        with: {
          scheme: 'key_sharing',
          hierPart: JSON.stringify(contentPayload),
        },
        can: {
          namespace: 'server_keys',
          segments: ['PUBLISH'],
        },
      },
    ],
  })
  const token = ucans.encode(ucan)
  const publisherAddress = invokerAddress

  await createMessageAPI({
    credential: credential as ICredential,
    contractAddress: credential.contractAddress,
    invokerAddress: publisherAddress,
    topic: MessageTopicEnum.SERVER_ENCRYPTION_KEY,
    content: token,
    forAddress: forAddress,
    chain,
  })
}
