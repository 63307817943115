import SafeAppsSDK, { TransactionStatus } from '@safe-global/safe-apps-sdk'

export const safeAppsSdk = new SafeAppsSDK({
  allowedDomains: [/app.safe.global$/],
  debug: false,
})

export const isSafeApp = async () => {
  // check if we're in an iframe
  if (window?.parent === window) {
    return false
  }

  const safe = await Promise.race([
    safeAppsSdk.safe.getInfo(),
    new Promise<undefined>((resolve) => setTimeout(resolve, 200)),
  ])

  return !!safe
}

export const getSafeOwners = async () => {
  const info = await safeAppsSdk.safe.getInfo()
  return info.owners
}

function sleep(ms = 1000): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const waitForSafeAppTx = async (safeTxHash: string) => {
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const txData = await safeAppsSdk.txs.getBySafeTxHash(safeTxHash)
    if (
      txData.txStatus === TransactionStatus.AWAITING_CONFIRMATIONS ||
      txData.txStatus === TransactionStatus.AWAITING_EXECUTION
    )
      await sleep(5000)
    else return txData.txHash
  }
}

export const handleSafeAppTransaction = async (
  encodedFunction: string,
  toAddress: string
) => {
  const { safeTxHash } = await safeAppsSdk.txs.send({
    txs: [
      {
        to: toAddress,
        value: '0',
        data: encodedFunction,
      },
    ],
  })
  const txHash = await waitForSafeAppTx(safeTxHash)
  return txHash
}
