import {
  IInvoker,
  ICredentialInvoker,
  IServerKey,
} from '../../../types/interface/invoker.interface'
import {
  ISetAgentKeyPayload,
  IRemoveAgentKeyPayload,
  IAddContractToInvokerList,
  IAddServerKeysPayload,
  ISetMemberKeyPayload,
} from '../../invoker/reducer'
import { modifyInvokerIndexDbState, addInvokerIndexDbState } from '../utils'

const defaultServerKeys: IServerKey = {
  portalDecryptionKey: '',
  portalEncryptionKey: '',
  portalGunKey: '',
  ownerDecryptionKey: '',
  ownerEncryptionKey: '',
  agentKey: '',
  memberDecryptionKey: '',
  memberEncryptionKey: '',
}

export const addInvokerCredentialsToIndexedDb = async (
  invokerCurrentState: IInvoker | undefined,
  payload: ICredentialInvoker
) => {
  const { credential, invokerAddress } = payload

  if (invokerCurrentState) {
    const previousPortalCredentials = invokerCurrentState.credentials
    const modification = {
      credentials: {
        ...previousPortalCredentials,
        [credential.contractAddress]: payload.credential,
      },
    }
    await modifyInvokerIndexDbState(invokerAddress, modification)
  } else {
    const state = {
      address: payload.invokerAddress,
      contracts: [credential.contractAddress],
      serverKeys: {},
      credentials: {
        [credential.contractAddress]: payload.credential,
      },
    }
    await addInvokerIndexDbState(state)
  }
}

export const addInvokerAgentKeysToIndexedDb = (
  invokerState: IInvoker | undefined,
  payload: ISetAgentKeyPayload
) => {
  const { invokerAddress, agentKey, contractAddress } = payload

  if (!invokerState) {
    const newInvokerState = {
      address: invokerAddress,
      contracts: [contractAddress],
      serverKeys: {
        [contractAddress]: {
          ...defaultServerKeys,
          agentKey,
        },
      },
      credentials: {},
    }
    addInvokerIndexDbState(newInvokerState)
    return
  }
  const previousPortalServerKeys = invokerState.serverKeys
  const portalServerKeys = previousPortalServerKeys[contractAddress]
  const modification = {
    serverKeys: {
      ...previousPortalServerKeys,
      [contractAddress]: {
        ...portalServerKeys,
        agentKey,
      },
    },
  }
  modifyInvokerIndexDbState(invokerAddress, modification)
}

export const removeInvokerAgentKeyFromIndexDb = (
  invokerState: IInvoker | undefined,
  payload: IRemoveAgentKeyPayload
) => {
  if (!invokerState) return

  const { invokerAddress, contractAddress } = payload
  const previousPortalServerKeys = invokerState.serverKeys
  const portalServerKeys = previousPortalServerKeys[contractAddress]

  const modification = {
    serverKeys: {
      ...previousPortalServerKeys,
      [contractAddress]: {
        ...portalServerKeys,
        agentKey: '',
      },
    },
  }

  modifyInvokerIndexDbState(invokerAddress, modification)
}

export const addContractToInvokerContractListOnIndexeddb = (
  invokerState: IInvoker | undefined,
  payload: IAddContractToInvokerList
) => {
  const { invokerAddress, contractAddress } = payload
  if (invokerState) {
    const invokerPreviousContracts = invokerState.contracts
    const newContractsList = new Set([
      ...invokerPreviousContracts,
      contractAddress,
    ])
    const modification = {
      contracts: [...newContractsList],
    }
    modifyInvokerIndexDbState(invokerAddress, modification)
  } else {
    const newState = {
      address: payload.invokerAddress,
      contracts: [contractAddress],
      serverKeys: {},
      credentials: {},
    }
    addInvokerIndexDbState(newState)
  }
}

export const addInvokerServerKeysToIndexDb = async (
  invokerState: IInvoker | undefined,
  payload: IAddServerKeysPayload
) => {
  const { invokerAddress, contractAddress, ...serverKeys } = payload
  if (invokerState) {
    const previousInvokerServerKeys = invokerState.serverKeys
    const modification = {
      serverKeys: {
        ...previousInvokerServerKeys,
        [contractAddress]: serverKeys as IServerKey,
      },
    }
    await modifyInvokerIndexDbState(invokerAddress, modification)
  } else {
    const newState = {
      address: invokerAddress,
      contracts: [contractAddress],
      serverKeys: {
        [contractAddress]: serverKeys,
      },
      credentials: {},
    }

    await addInvokerIndexDbState(newState)
  }
}

export const updateInvokerMemeberDecryptionKey = (
  invokerState: IInvoker | undefined,
  payload: ISetMemberKeyPayload
) => {
  const { contractAddress, invoker, memberDecryptionKey } = payload
  if (invokerState) {
    const previousInvokerServerKeys = invokerState.serverKeys
    const serverKeys = previousInvokerServerKeys[contractAddress]
    const modification = {
      serverKeys: {
        ...previousInvokerServerKeys,
        [contractAddress]: {
          ...serverKeys,
          memberDecryptionKey,
        },
      },
    }
    modifyInvokerIndexDbState(invoker, modification)
  } else {
    const newState = {
      address: payload.invoker,
      contracts: [contractAddress],
      serverKeys: {
        [contractAddress]: {
          ...defaultServerKeys,
          memberDecryptionKey,
        },
      },
      credentials: {},
    }

    addInvokerIndexDbState(newState)
  }
}
