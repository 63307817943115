import React from 'react'
import ReactPlayer from 'react-player'

interface VideoPlayerProps {
  url: string
  mimetype?: string
}

export default function VideoPlayer({ url }: VideoPlayerProps) {
  return (
    <div className="w-full h-full relative flex flex-col justify-center items-center">
      <ReactPlayer
        url={url}
        loop={true}
        controls={true}
        className="react-player absolute top-0 left-0"
        width="100%"
        height="100%"
      />
    </div>
  )
}
