/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios'
import qs from 'query-string'
import * as CryptoJS from 'crypto-js'
import { isSafeApp } from '../../utils/safeApp'
import { GNOSIS_SAFE_APP_URL } from '../../utils/constants'

interface WhiteListRequest {
  invoker: string
  code: string
  origin: string
}

const checkWhitelistAPI = async ({
  invoker,
  code,
  origin,
}: WhiteListRequest) => {
  const URL = `${process.env.REACT_APP_GATE_URL}/account/check-whitelist`
  const params: any = {}
  const _isSafeApp = await isSafeApp()
  if (_isSafeApp) {
    origin = GNOSIS_SAFE_APP_URL
  }
  if (origin) {
    const url = new window.URL(origin)
    const originHash = CryptoJS.MD5(url.hostname).toString()
    params.origin = originHash
  }
  if (code) params.code = code
  const config = {
    params,
    paramsSerializer: (param: qs.StringifiableRecord) => {
      return qs.stringify(param)
    },
    headers: {
      invoker: invoker,
    },
  }
  return axios.get(URL, config)
}
export default checkWhitelistAPI
