import React from 'react'
import { useParams } from 'react-router-dom'
import { useContract } from '../../../../../store/contract/hooks'
import { PublicPortalActions } from './PublicPortalActions'

export const PortalActionBar = () => {
  const { address: contractAddress } = useParams()
  const contract = useContract((contractAddress as string) || '')

  return (
    <div className="hidden lg:flex items-center justify-between gap-[10px]">
      <div className="text-heading-xlg-bold truncate">
        {contract?.metadata?.name}
      </div>
      <PublicPortalActions />
    </div>
  )
}
