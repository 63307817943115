import { useMediaQuery } from '@mui/material'
import React from 'react'
import MainLogo from '../../assets/mainLogo.png'

const Footer = () => {
  const isMediaMax1025px = useMediaQuery('(max-width : 1025px)')
  return (
    <div
      className={`w-full h-full ${
        isMediaMax1025px ? 'p-1' : 'p-2'
      } flex items-center justify-center`}
    >
      <div
        className={`flex 
items-center font-normal ${
          isMediaMax1025px ? 'flex-col gap-1 p-1' : 'px-2'
        } w-[29rem]  h-full bg-[#F8F8F8] rounded-xl	text-[#777777]`}
      >
        <img
          src={MainLogo}
          className={` ${isMediaMax1025px ? 'w-8 h-8' : 'w-8 h-8'} `}
        />
        <div
          className={`  ${
            isMediaMax1025px ? ' text-center' : 'pl-2 text-left'
          } text-[#777777]  text-xs`}
        >
          Fileverse is a trustless collaboration dApp supported by 14,000+
          Gitcoin & Ethereum donors as well as leading protocols, investors &
          angels 💛
        </div>
      </div>
    </div>
  )
}

export default Footer
