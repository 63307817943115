import React from 'react'
import { Divider, LayoutModes } from '@fileverse/ui'
import cuteLogo from '../assets/logo-cute.svg'
import { PortalSection } from './PortalSection'
import { ISectionsProps } from '../interfaces'

const sections: ISectionsProps[] = [
  {
    id: '1',
    title: 'Learn more about Fileverse and online collaboration',
    layout: LayoutModes.CARD,
    files: [
      {
        id: '1',
        title: 'Onboarding',
        size: 'md',
        type: 'dPage',
        likeCount: 0,
        metadata: {
          fileId: 0,
          ipfsHash: 'QmTYJaBg6gDipehV62UVoDjeFjYXuDenLjMwueZXbGz7eo',
          ogImageUrl: '',
          name: 'Onboarding',
          mimeType: 'application/json',
          extension: 'dummy',
          link: 'https://portal.fileverse.io/#/0x1a8a0f8cd87A991D90A7d64C9E331b105a070f14/file/0?chainId=100',
        },
      },
      {
        id: '2',
        title: 'dPage - Decentralised Notion Alternative',
        size: 'md',
        type: 'dPage',
        likeCount: 0,
        metadata: {
          fileId: 11,
          ipfsHash: 'QmdfT1EGCa6GDSHeJLghZfzgndfuXmf7gPYSmzkSCs11at',
          ogImageUrl: '',
          name: 'dPage - Decentralised Notion Alternative',
          mimeType: 'application/json',
          extension: 'dummy',
          link: 'https://portal.fileverse.io/#/0x1a8a0f8cd87A991D90A7d64C9E331b105a070f14/file/11?chainId=100',
        },
      },
      {
        id: '3',
        title: 'Fileverse Stack',
        size: 'md',
        type: 'dPage',
        likeCount: 0,
        metadata: {
          fileId: 12,
          ipfsHash: 'QmRfA3skFVM2UMniBT19irH4kgw5KTW5nNVM1xSVDA5tLf',
          ogImageUrl: '',
          name: 'Fileverse Stack',
          mimeType: 'application/json',
          extension: 'dummy',
          link: 'https://portal.fileverse.io/#/0x1a8a0f8cd87A991D90A7d64C9E331b105a070f14/file/12?chainId=100',
        },
      },
    ],
    position: 0,
  },
]

export default function PublicEmptyState() {
  return (
    <>
      <div className="w-full h-full flex-col relative flex justify-center items-center py-[48px] px-8 text-center lg:text-left">
        <div className="flex flex-col lg:flex-row gap-6 justify-center items-center">
          <img src={cuteLogo} alt="fileverse logo" />
          <div className="space-y-4">
            <div className="space-y-1">
              <h4 className="text-heading-lg-bold">
                Portal owner has not published content yet :(
              </h4>
              {/* <p className="text-body-text-sm color-text-secondary">
                Subscribe so you never miss the latest updates
              </p> */}
            </div>
          </div>
        </div>
      </div>
      <Divider className="w-[calc(100%-2rem)] lg:w-[calc(80%-4rem)]" />
      {/* Fake Section */}
      <div className="w-full lg:w-[80%] h-full px-4">
        {sections.map((section, index) => (
          <div
            key={section.id || index}
            onClick={() => null}
            className="relative select-none"
            style={{ minHeight: '334px' }}
          >
            <PortalSection
              section={section}
              sectionPos={index}
              disableSwitching
            />
          </div>
        ))}
      </div>
    </>
  )
}
