export enum NotificationType {
  NEW_FILE_ADDED = 'addFile',
  NEW_COLLABORATOR_JOINED = 'collaboratorJoin',
  EDITED_FILE = 'editFile',
  DELETED_FILE = 'deleteFile',
  COLLABORATOR_INVITE = 'collaboratorInvite',
  DPAGE_COMMENT = 'dPageComment',
  FILE_MESSAGE = 'fileMessage',
  WHITEBOARD_EDIT = 'whiteboardEdit',
  DPAGE_EDIT = 'dPageEdit',
  DPAGE_PUBLISH = 'dPagePublish',
  DDOC_PUBLISH = 'dDocPublish',
  DDOC_EDIT = 'dDocEdit',
  WHITEBOARD_PUBLISH = 'whiteboardPublish',
  COLLABORATOR_REMOVE = 'collaboratorRemove',
}
