/* eslint-disable react/jsx-no-undef */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react'
import { FileStatus } from '../../types/enum/file.enum'
import useFilePreviewHelper from './useFilePreviewHelper'
import FileDisconnectedScreen from './Overlays/PrivateFileAuthenicationOverLay'
import FilePageReadyState from './FilePageReadyState'
import { IFileChatKeyPair } from '../../types/interface/file.interface'
import cn from 'classnames'
import { AnimatedLoader } from '../../pages/PublicPortal/components/Loader'

const FilePageMobileView = () => {
  const {
    fileChatKey,
    fileStatus,
    downloadUrl,
    viewTokenGatedFile,
    isDDoc,
    isDPage,
  } = useFilePreviewHelper()

  return (
    <div
      className={` ${
        fileStatus !== FileStatus.READY ? 'h-screen' : 'min-h-screen'
      } w-full`}
    >
      <div
        className={cn(
          isDPage || isDDoc
            ? 'h-auto'
            : 'h-screen overflow-scroll no-scrollbar',
          'w-full'
        )}
      >
        <div
          className={cn('w-full', {
            'h-full': isDPage || isDDoc || fileStatus === FileStatus.LOCKED,
          })}
        >
          {fileStatus === FileStatus.PENDING && (
            <div className="w-full h-screen flex items-center justify-center">
              <AnimatedLoader />
            </div>
          )}
          {fileStatus === FileStatus.ERROR && (
            <div className="w-full flex justify-center">
              Oops! an error occured.
            </div>
          )}
          {(fileStatus === FileStatus.DISCONNECTED ||
            fileStatus === FileStatus.LOCKED) && (
            <FileDisconnectedScreen
              gatedFilePreviewHandler={viewTokenGatedFile}
            />
          )}
          {fileStatus === FileStatus.READY && (
            <div className="w-full h-full">
              <FilePageReadyState
                downloadUrl={downloadUrl}
                fileChatKey={fileChatKey as IFileChatKeyPair}
                viewTokenGatedFile={viewTokenGatedFile}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default FilePageMobileView
