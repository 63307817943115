import React, { Dispatch, SetStateAction, useRef } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { ClaimPerksSection } from '../PortalSideBar/DashboardSideBar'
import { ChevronRight } from 'lucide-react'
import { IconButton } from '@fileverse/ui'
import { SearchFileTextField } from './Headers/MainHeader'
import cn from 'classnames'
import { useEscapeKey } from '../../hooks/useEscapeKey'
import { useOnClickOutside } from 'usehooks-ts'
import { useFileSearchValue } from '../../store/fileSearch/hooks'
import Spinner from '../Spinner'
import { FileSearchResult } from '../../pages/domain/Home/components'
import { usePrivyHelper } from '../../hooks/usePrivyHelper'

export const DisconnectedWalletSideMenu = ({
  sideMenu,
  setSideMenu,
}: {
  sideMenu: boolean
  setSideMenu: Dispatch<SetStateAction<boolean>>
}) => {
  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, () => setSideMenu(false))
  useEscapeKey(() => setSideMenu(false))

  const { connectInjectedWallet, loginWithSocials } = usePrivyHelper()
  return (
    <>
      <div
        ref={ref}
        data-cy="mobile-side-menu"
        className={cn(
          'py-4 overflow-y-auto no-scrollbar z-[99] shadow w-[82vw] lg:w-1/3 max-w-md h-screen fixed top-0 bg-[#ffffff] duration-300 transition-all rounded-s-lg p-5',
          {
            'right-0': sideMenu,
            '-right-full': !sideMenu,
          }
        )}
      >
        <div className="h-[85vh] lg:h-full flex flex-col">
          <div className="w-full flex justify-between items-center mb-6 cursor-pointer">
            <IconButton
              onClick={() => setSideMenu(false)}
              icon="Close"
              size="md"
              variant="ghost"
            />
          </div>
          <div>
            <div className="border-b-[1px] color-border-default flex items-center hover:bg-yellow w-full">
              <a
                href={'https://about.fileverse.io/'}
                target={'_blank'}
                rel="noreferrer"
                className="w-full"
              >
                <div className="h-full w-full flex items-center py-4 px-2">
                  <div className="hover:cursor-pointer text-heading-md color-text-default flex justify-between w-full">
                    {'About'}
                    <ChevronRight />
                  </div>
                </div>
              </a>
            </div>
            <div
              onClick={connectInjectedWallet}
              className="border-b-[1px] color-border-default flex items-center hover:bg-yellow w-full"
            >
              <div className="h-full w-full flex items-center py-4 px-2">
                <div className="hover:cursor-pointer text-heading-md color-text-default flex justify-between w-full">
                  {'Connect'}
                </div>
              </div>
            </div>
            <div
              onClick={loginWithSocials}
              className="border-b-[1px] color-border-default flex items-center hover:bg-yellow w-full"
            >
              <div className="h-full w-full flex items-center py-4 px-2">
                <div className="hover:cursor-pointer text-heading-md color-text-default flex justify-between w-full">
                  Social login
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={cn(
          'fixed inset-0 z-50 bg-black/80 duration-300 transition-opacity pointer-events-none',
          !sideMenu ? 'opacity-0 ' : 'opacity-100'
        )}
      />
    </>
  )
}

export const ConnectedWalletSideMenu = ({
  sideMenu,
  setSideMenu,
  handleDisconnectWallet,
  isCollaborator,
}: {
  sideMenu: boolean
  setSideMenu: Dispatch<SetStateAction<boolean>>
  handleDisconnectWallet: () => void
  walletAddress: string
  isCollaborator: boolean
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()
  const contractAddress = useParams().address as string
  const [urlParams] = useSearchParams()
  const chainId = parseInt(urlParams.get('chainId') || '')

  useOnClickOutside(ref, () => setSideMenu(false))

  useEscapeKey(() => setSideMenu(false))

  return (
    <>
      <div
        ref={ref}
        data-cy="mobile-side-menu"
        className={cn(
          'py-4 overflow-y-auto no-scrollbar z-[99] shadow w-[82vw] lg:w-1/3 max-w-md h-screen fixed top-0 bg-[#ffffff] duration-300 transition-all rounded-s-lg p-5',
          {
            'right-0': sideMenu,
            '-right-full': !sideMenu,
          }
        )}
      >
        <div className="h-[85vh] lg:h-full flex flex-col justify-between">
          <div>
            <div className="w-full flex justify-between items-center mb-6 cursor-pointer">
              <IconButton
                onClick={() => setSideMenu(false)}
                icon="Close"
                size="md"
                variant="ghost"
              />
            </div>
            <div>
              <SideMenuCard
                title="Home"
                onClick={() =>
                  navigate(`/${contractAddress}?chainId=${chainId}`)
                }
              />
              <SideMenuCard
                title="My Portals"
                onClick={() => navigate('/contracts')}
              />
              <SideMenuCard
                title="My Files"
                onClick={() =>
                  navigate(`/${contractAddress}/files?chainId=${chainId}`)
                }
              />
              <SideMenuCard
                title={
                  isCollaborator ? 'Edit Public Portal' : 'View Public Portal'
                }
                onClick={() =>
                  navigate(`/${contractAddress}/member?chainId=${chainId}`)
                }
              />
              <div
                data-cy="mobile-disconnect-btn"
                className="hover:cursor-pointer w-full px-2 py-4 flex text-heading-md color-text-danger items-start"
                onClick={() => {
                  handleDisconnectWallet()
                }}
              >
                <span>{'Disconnect'}</span>
              </div>
            </div>
          </div>
          {isCollaborator && <ClaimPerksSection />}
        </div>
      </div>
      <div
        className={cn(
          'fixed inset-0 z-50 bg-black/80 duration-300 transition-opacity pointer-events-none',
          !sideMenu ? 'opacity-0 ' : 'opacity-100'
        )}
      />
    </>
  )
}

const SideMenuCard = ({
  title,
  onClick,
}: {
  title: string
  onClick: () => void
}) => {
  return (
    <div
      onClick={() => onClick()}
      className="border-b-[1px] color-border-default flex items-center hover:bg-yellow w-full"
    >
      <div className="h-full w-full flex items-center py-4 px-2">
        <div className="hover:cursor-pointer text-heading-md color-text-default flex justify-between w-full">
          {title}
          <ChevronRight />
        </div>
      </div>
    </div>
  )
}

export const SearchFileSideMenu = ({
  open,
  onOpenChange,
}: {
  open: boolean
  onOpenChange: Dispatch<SetStateAction<boolean>>
}) => {
  const searchFileRef = useRef<HTMLDivElement>(null)
  const fileSearchValue = useFileSearchValue()

  useOnClickOutside(searchFileRef, () => onOpenChange(false))

  useEscapeKey(() => onOpenChange(false))

  return (
    <>
      <div
        ref={searchFileRef}
        className={cn(
          'py-4 overflow-y-auto no-scrollbar z-[99] shadow w-full lg:w-1/3 max-w-md h-screen fixed top-0 bg-[#ffffff] duration-300 transition-all',
          {
            'right-0': open,
            '-right-full': !open,
          }
        )}
      >
        <div className="w-full flex justify-between items-center px-4 gap-2">
          <SearchFileTextField />

          <IconButton
            onClick={() => onOpenChange(false)}
            icon="Close"
            size="md"
            variant="ghost"
          />
        </div>

        <div className="w-full flex flex-col justify-center items-center px-4 gap-2 mt-4">
          {fileSearchValue.length < 3 && fileSearchValue.length > 0 ? (
            <Spinner />
          ) : (
            <FileSearchResult />
          )}
        </div>
      </div>
      <div
        className={cn(
          'fixed inset-0 z-50 bg-black/80 duration-300 transition-opacity pointer-events-none',
          !open ? 'opacity-0 ' : 'opacity-100'
        )}
      />
    </>
  )
}
