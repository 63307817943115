import React from 'react'
import { useMediaQuery } from '@mui/material'
import MainWrapper from '../components/common/MainWrapper'

export default function AcceptableUsePolicy() {
  const isMedia1025px = useMediaQuery('(max-width : 1025px)')
  return (
    <MainWrapper>
      <div className={`w-full flex justify-center h-full`}>
        <div
          className={`rounded-lg ${
            isMedia1025px ? 'w-full' : 'w-[50%]'
          }   color-border-default border shadow-lg overflow-auto styled-scrollbar p-8`}
        >
          <div className="font-bold mb-4 text-lg">Acceptable Use Policy</div>
          <div className="mb-6 text-gray-600">
            We created Fileverse to give people a privacy-preserving and
            customizable collaboration platform. We’re committed to making our
            platform safe, secure, and welcoming. This document outlines some
            common-sense rules all users must adhere to during their time on
            Fileverse.
            <br />
            <br />
            You are entirely responsible for all data you upload or cause to be
            uploaded using the Services (“Content”) - and you’re also
            responsible for any harm resulting from that Content. For your own
            protection and the protection of our community, you may not use our
            Site or the Services, or enable anyone else to use the Site or the
            Services, in a manner that:
            <br />
            <br />
          </div>
          <div className="mb-6 text-gray-600">
            <ul className="list-disc">
              <li className="ml-8">
                violates or infringes someone else’s rights of privacy,
                publicity, copyright, trademark, or other intellectual property
                right
              </li>
              <li className="ml-8">
                is illegal, threatening, intimidating, harassing, hateful,
                defamatory, obscene, or instigate or encourage conduct that
                would be illegal, or otherwise inappropriate, including
                promoting violent crimes or encouraging or supporting the
                coordination of violent or harmful acts (including, for example,
                through encouraging or advocating for any form of self-harm);
              </li>
              <li className="ml-8">
                endangers or threatens to endanger the safety or well-being of
                any individual;
              </li>{' '}
              <li className="ml-8">
                bullies, abuses, intimidates, harasses, or defames any one or
                more Fileverse users or other individuals or groups of
                individuals;
              </li>{' '}
              <li className="ml-8">
                collects or shares, or enables the collection or sharing, of any
                personal information without consent, including contact
                information, addresses, or payment information;
              </li>{' '}
              <li className="ml-8">
                interferes with the integrity or performance of the Site or the
                Services, or with the work or privacy of other users, whether by
                use of malicious code, unauthorized access, the creation of
                inauthentic accounts, or otherwise;
              </li>
              <li className="ml-8">
                scrapes content hosted on our Site or through the Services
                without prior Fileverse written authorization;
              </li>
              <li className="ml-8">
                create accounts to access our Services through unauthorized or
                automated means;
              </li>
              <li className="ml-8">
                results in spamming, phishing, or any other means of “tricking”
                users into sharing, becoming a collaborator on, or changing the
                permissions for any document or resource;
              </li>
              <li className="ml-8">
                reveals your belonging to a criminal or terrorist organizations
                or any individual or group that provides support or resources to
                such organizations.
              </li>
            </ul>
            <br />
            <br />
            In order to use the Services you must be 13 years of age or older.
            If you are 13 or older but under 18 years of age, you must have your
            parent or legal guardian’s permission to use Fileverse and to accept
            the terms of this TOS.
            <br />
            <br />
            Fileverse takes enforcement of the standards of conduct outlined
            above very seriously. Should violations of any of the standards come
            to our attention, we may take action to limit or remove offending
            users or content. If you ever feel unsafe on Fileverse, or believe
            that another user is abusing their privileges, you can report any
            relevant behavior or content to{' '}
            <a
              href="mailto:hello@fileverse.io"
              target="_blank"
              rel="noreferrer"
              className="text-blue-600"
            >
              hello@fileverse.io
            </a>
            <br />
            <br />
            <br />
            <br />
            Keeping Our Users Safe
            <br />
            <br />
            Fileverse takes enforcement of the standards of conduct outlined
            above very seriously. Should violations of any of the standards come
            to our attention, we may take action to limit or remove offending
            users or content. If you ever feel unsafe on Fileverse, or believe
            that another user is abusing their privileges, you can report any
            relevant behavior or content to{' '}
            <a
              href="mailto:hello@fileverse.io"
              target="_blank"
              rel="noreferrer"
              className="text-blue-600"
            >
              hello@fileverse.io
            </a>
          </div>
        </div>
      </div>
    </MainWrapper>
  )
}
