export const BLOCKSCOUT_ORIGINS = ['https://gnosis.blockscout.com/']

function _isOriginGnosisBlockscout() {
  try {
    // check if we're in an iframe
    if (window.parent !== window) {
      const parentOrigin = document.referrer
      return BLOCKSCOUT_ORIGINS.includes(parentOrigin)
    }
  } catch (error) {
    console.error('Error checking origin:', error)
    return false
  }
  return false // Assume false if not in an iframe or any other conditions are not met
}
export const isOriginGnosisBlockscout = _isOriginGnosisBlockscout()
