/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'
import { NavLink, useSearchParams } from 'react-router-dom'
import copy from 'copy-to-clipboard'
import sendNotifcation from '../../utils/notification'
import useComponentVisibilty from '../../hooks/useVisibility'
import { FileSource } from '../../types/enum/file.enum'
import { decryptUsingAESKey, decryptUsingRSAKey } from '../../utils/crypto'
import { useServerKeys } from '../../store/invoker/hooks'
import { IDraft } from '../../types/interface/drafts.interface'
import { removeDraft } from '../../utils/collaboration/utils'
import FileLoadingCard from './FileLoadingCard'
import { useContract } from '../../store/contract/hooks'
import { useGunNodes } from '../../hooks/useGunNode'
import { usePrivyHelper } from '../../hooks/usePrivyHelper'
import { DynamicModal } from '../../pages/PublicPortal/components/DynamicModal'
import cn from 'classnames'
import {
  EllipsisVertical,
  Pen,
  PenLine,
  File,
  PanelsTopLeft,
  CircleHelp,
} from 'lucide-react'
import { ButtonGroup, Tag } from '@fileverse/ui'
import {
  CopyButton,
  DeleteButton,
} from '../../pages/domain/Home/components/common/FileItem'
import { useOutsideClick } from '../../hooks/useOutsideClickDetector'
import { RecentActionButton } from '../../pages/PublicPortal/components/PublicPortalNavbar'
import { useMediaQuery } from 'usehooks-ts'
import { useSafeAppStatus } from '../../store/common/hooks'
import { isOriginGnosisBlockscout } from '../../utils/isOriginGnosisBlockscout'

const DraftListCard = ({
  contractAddress,
  draft,
  setDrafts,
}: {
  contractAddress: string
  draft: IDraft
  setDrafts: Dispatch<SetStateAction<Array<IDraft>>>
}) => {
  const walletAddress = usePrivyHelper().walletAddress
  const serverKeys = useServerKeys(walletAddress as string, contractAddress)
  const [docKey, setDocKey] = useState<string>('')
  const [searchParams] = useSearchParams()
  const contract = useContract(contractAddress as string)
  const chainId =
    contract?.chainId || parseInt(searchParams.get('chainId') || '')
  const isSafeApp = useSafeAppStatus()
  const navTarget = isSafeApp || isOriginGnosisBlockscout ? '_self' : '_blank'
  const rtcId = draft?.rtcId
  const docVersion = draft?.version === 2 ? '2' : '1'
  const lockedKey = draft?.portalLock
  const decryptionKey = serverKeys.portalEncryptionKey
  const { getAuthenticatedPluginMetadataNode } = useGunNodes()
  const [isHovering, toggleHovering] = useState(false)
  const [isDropDownVisible, setDropdownVisible] = useState(false)
  const dropdownRef = useRef(null)
  const isMaxWidth1024 = useMediaQuery('(max-width: 1024px)')
  useOutsideClick([dropdownRef], () => setDropdownVisible(false))

  const filePath = () => {
    if (draft?.type === FileSource.EXCALIDRAW) {
      return `/${contractAddress}/whiteboard/${rtcId}?key=${docKey}&chainId=${chainId}`
    }
    if (draft?.type === FileSource.COLLAB_DOCUMENT) {
      return `/${contractAddress}/document/${rtcId}?key=${docKey}&version=${docVersion}&chainId=${chainId}`
    }
    if (draft?.type === FileSource.DPAGE) {
      return `/${contractAddress}/dPages/${rtcId}?key=${docKey}&chainId=${chainId}`
    }
    return ''
  }

  const {
    isComponentVisible: confirmDeleteModal,
    setIsComponentVisible: setConfirmDeleteModal,
  } = useComponentVisibilty(false)

  useEffect(() => {
    ;(async () => {
      try {
        const key = await decryptUsingAESKey(lockedKey, decryptionKey)
        setDocKey(key)
      } catch (e) {
        /**
         * we try to decrypt draft(v1) key saved in gun using rsa decryption key
         */
        try {
          const rsaDecryptionKey = serverKeys.portalDecryptionKey
          const key = await decryptUsingRSAKey(lockedKey, rsaDecryptionKey)
          setDocKey(JSON.parse(key))
        } catch (e: any) {
          console.log(e?.message)
        }
      }
    })()
  }, [])

  const copyToClipboard = () => {
    copy(`${window.location.origin}/#${filePath()}`)
    sendNotifcation('Copied to clipboard!', '', 'warning')
  }
  const getDraftFileIcon = () => {
    if (draft?.type === FileSource.EXCALIDRAW) {
      return <PenLine size={16} />
    }
    if (draft?.type === FileSource.COLLAB_DOCUMENT) {
      return <File size={16} />
    }
    if (draft?.type === FileSource.DPAGE) {
      return <PanelsTopLeft size={16} />
    }
  }
  const deleteDraftCallbackFn = (draftRtcId?: string) => {
    if (!draftRtcId) {
      return
    }
    setDrafts((prev) => {
      const newState = prev.filter((e) => e?.rtcId !== draftRtcId)
      return newState
    })
  }

  return docKey && rtcId ? (
    <>
      <NavLink
        className={cn(
          'w-full hover:bg-[#f8f9fa] cursor-pointer border-b border-[#e8ebec] flex py-[12px] px-[16px]'
        )}
        to={filePath()}
        target={navTarget}
        onMouseOver={() => toggleHovering(true)}
        onMouseLeave={() => toggleHovering(false)}
      >
        <div className="flex w-full items-center gap-[16px]">
          <div className="rounded color-bg-brand p-1">
            <>{getDraftFileIcon()}</>
          </div>
          <div className="flex w-full flex-col">
            <div className="text-body-sm-bold max-w-md w-full truncate flex gap-2 items-center">
              <p>{draft?.name || 'Untitled'}</p>
              {draft.isPublished && (
                <Tag color="gray" className="rounded">
                  Published
                </Tag>
              )}
            </div>
          </div>
        </div>

        <div
          className={cn(
            'flex items-center',
            isMaxWidth1024 ? 'gap-4' : 'gap-[64px]'
          )}
        >
          <div className="text-[12px] text-[#77818A] text-nowrap flex gap-[4px] items-center min-w-fit">
            <Pen size="16px" />
            Draft
          </div>

          {!isMaxWidth1024 ? (
            <div className="min-w-[80px] mb-[-2px]">
              {isHovering && (
                <ButtonGroup className="gap-[8px]">
                  <CopyButton
                    textToCopy={`${window.location.origin}/#${filePath()}`}
                  />
                  <DeleteButton
                    onClick={(
                      e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                    ) => {
                      e.stopPropagation()
                      e.preventDefault()
                      setConfirmDeleteModal(true)
                    }}
                  />
                </ButtonGroup>
              )}
            </div>
          ) : (
            <div
              ref={dropdownRef}
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
              }}
              className="w-[36px] relative flex items-center justify-center h-[36px] hover:bg-[#F2F4F5] rounded-[4px]"
            >
              <EllipsisVertical
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  setDropdownVisible(!isDropDownVisible)
                }}
              />
              {isDropDownVisible && (
                <div className="absolute z-10 top-10 right-0">
                  <RecentActionButton
                    onDelete={() => setConfirmDeleteModal(true)}
                    onCopy={() => {
                      copyToClipboard()
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </NavLink>
      <DynamicModal
        className="max-w-[600px]"
        open={confirmDeleteModal}
        onOpenChange={setConfirmDeleteModal}
        title="Delete confirmation"
        content={
          <div className="flex gap-2 items-start w-full h-full">
            <span className="w-6">
              <CircleHelp size={24} className="color-text-secondary" />
            </span>
            <span className="text-body-sm">
              Are you sure you want to delete this draft?
            </span>
          </div>
        }
        primaryAction={{
          label: 'Delete',
          onClick: async () => {
            await removeDraft(
              getAuthenticatedPluginMetadataNode(rtcId),
              rtcId,
              deleteDraftCallbackFn
            )
            setConfirmDeleteModal(false)
          },
          variant: 'danger',
          className: 'w-full md:w-auto',
        }}
        secondaryAction={{
          label: 'Cancel',
          onClick: () => setConfirmDeleteModal(false),
          className: 'w-full md:w-auto',
        }}
      />
    </>
  ) : (
    <FileLoadingCard className="w-full lg:w-[435px]" />
  )
}

export default DraftListCard
