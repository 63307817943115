import React from 'react'

// import { useConnectWallet, usePrivy } from '@privy-io/react-auth'

// import { useDialogStore } from './utils'
import { Button } from '@fileverse/ui'
import { usePrivyHelper } from '../../../../hooks/usePrivyHelper'

export const ConnectWithPrivy = () => {
  const { loginWithSocials, connectInjectedWallet } = usePrivyHelper()

  return (
    <div className=" w-full">
      <p className="text-sm font-medium text-center mb-[8px]">
        Sign & Mint your Love
      </p>
      <p className=" text-xs text-[#77818A] leading-[16px] text-center">
        We’ll now send your onchain love and you’ll also receive some little
        nft-venir!
      </p>
      <div className="flex mt-4 flex-col justify-center gap-2">
        <Button onClick={connectInjectedWallet} variant="secondary">
          Connect wallet
        </Button>
        <div className="text-center leading-[20px] font-normal text-sm text-[#77818A]">
          Or
        </div>
        <Button onClick={loginWithSocials}>Sign in with socials</Button>
      </div>
    </div>
  )
}
