import { convertStringToTypedArray } from '../string'

enum KeyTypes {
  RSA = 'RSA',
  AES = 'AES',
}

const keyAlgoritms = {
  [KeyTypes.AES]: 'AES-GCM',
  [KeyTypes.RSA]: {
    name: 'RSA-OAEP',
    hash: 'SHA-256',
  },
}

const importKey = (
  pemContent: string,
  format: 'pkcs8' | 'raw' | 'spki',
  keyType: KeyTypes,
  keyUsage: KeyUsage[]
): Promise<CryptoKey> => {
  const uint8Content = convertStringToTypedArray(pemContent)
  return window.crypto.subtle.importKey(
    format,
    uint8Content.buffer,
    keyAlgoritms[keyType],
    false,
    keyUsage
  )
}

export const importRSADecryptionKey = (
  pemContent: string
): Promise<CryptoKey> => {
  return importKey(pemContent, 'pkcs8', KeyTypes.RSA, ['decrypt'])
}

export const importRSAEncryptionKey = (
  pemContent: string
): Promise<CryptoKey> => {
  return importKey(pemContent, 'spki', KeyTypes.RSA, ['encrypt'])
}
