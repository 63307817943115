export const validateOpenSeaUrl = (url: string) => {
  const baseRegex = /^https:\/\/opensea\.io\/assets\//
  const supportedChainRegex = /^(matic|ethereum)$/
  const completeRegex =
    /^https:\/\/opensea\.io\/assets\/(matic|ethereum)\/0x[a-fA-F0-9]{40}\/\d+\/?$/

  if (!baseRegex.test(url)) {
    throw new Error('Not a valid OpenSea token/NFT link')
  }

  const chainMatch = url.match(/^https:\/\/opensea\.io\/assets\/([^/]+)\//)
  if (chainMatch && !supportedChainRegex.test(chainMatch[1])) {
    throw new Error('We currently do not support this chain')
  }

  if (!completeRegex.test(url)) {
    throw new Error('Invalid OpenSea URL')
  }

  const match = url.match(/0x[a-fA-F0-9]{40}\/(\d+)/)
  if (match) {
    const tokenAddress = match[0].split('/')[0]
    const tokenId = match[1]
    return { tokenAddress, tokenId }
  } else {
    throw new Error('Invalid OpenSea URL')
  }
}
