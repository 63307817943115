import React, { SyntheticEvent } from 'react'
import UploadIcon from '../../assets/upload3.svg'
import useMediaQuery from '../../hooks/useMediaQuery'
import { EditStage } from './FileEdit'
import FileView from './FileView'
import { IFileMetaData } from '../../types/interface/file.interface'
import { DropzoneInputProps } from 'react-dropzone'

interface EditDropZoneProps {
  isDragActive: boolean
  fileInputClick: () => void
  getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T
  onAllFileSelectClick: (e: SyntheticEvent) => void
  inputFilesRef: React.RefObject<HTMLInputElement>
  selectFiles: (files: File[]) => Promise<void>
  setEditFile: React.Dispatch<React.SetStateAction<boolean>>
  setEditStage: React.Dispatch<React.SetStateAction<EditStage>>
  setNewFileName?: React.Dispatch<React.SetStateAction<string>>
  downloadUrl: string
  metadata?: IFileMetaData
}
export default function EditDropZone({
  isDragActive,
  fileInputClick,
  getInputProps,
  onAllFileSelectClick,
  inputFilesRef,
  selectFiles,
  setEditFile,
  setNewFileName,
  setEditStage,
  downloadUrl,
  metadata,
}: EditDropZoneProps) {
  const isMediaMax570px = useMediaQuery('(max-width : 570px)')

  return (
    <div className="w-full h-full flex items-center">
      <div className="w-[40%] h-full">
        <div
          className={`w-full h-full flex flex-col items-center justify-center gap-4 ${
            isDragActive
              ? 'inset-0 grid place-content-center bg-white opacity-60 rounded-lg'
              : ''
          }`}
          onClick={fileInputClick}
        >
          <h4 className="text-2xl font-bold">
            Replace your file, keep the same link!
          </h4>
          <input {...getInputProps()} />
          <div className="border-dashed border-neutral-300 rounded-md border-2 w-full max-w-md h-[40vh]">
            <div className="py-4 cursor-pointer h-full flex flex-col items-center justify-center">
              <div className="px-8 py-7 my-3 w-fit mx-auto rounded-full bg-yellow">
                <img
                  src={UploadIcon}
                  alt="Upload Files"
                  className={`${isMediaMax570px ? 'h-8' : 'h-12'} `}
                />
              </div>
              <div
                className="text-2xl	font-medium text-center "
                onClick={onAllFileSelectClick}
              >
                <input
                  multiple={true}
                  type={'file'}
                  ref={inputFilesRef}
                  accept="*"
                  className="hidden"
                  onChange={(e) => {
                    const files = Array.from(e.target.files as ArrayLike<File>)
                    setEditStage(EditStage.CONFIRM_FILE)
                    setEditFile(true)
                    setNewFileName && setNewFileName(files[0].name)
                    return selectFiles(files)
                  }}
                />
                Drag and drop files or &nbsp;
                <span className="underline">Browse</span>
              </div>
            </div>

            <div className="grid grid-cols-3 w-[45%] m-auto"></div>
          </div>
        </div>
      </div>
      <div className="w-[60%] h-full self-start flex flex-col shadow-xl">
        <div className="h-full w-full object-scale-down">
          <FileView
            downloadUrl={downloadUrl}
            metadata={metadata}
            isEditing={true}
            viewTokenGatedFile={null}
          />
        </div>
      </div>
    </div>
  )
}
