import React from 'react'
import Footer from './Footer'
import { CopyrightFooter } from './CopyrightFooter'
import MainMobileHeader from './Headers/MainMobileHeader'
import MainHeader from './Headers/MainHeader'
import { useMediaQuery } from '@mui/material'

const MainWrapper = ({
  children,
  createPage,
  useMainHeader,
  noFooter,
}: {
  children: JSX.Element
  createPage?: boolean
  useMainHeader?: boolean
  noFooter?: boolean
}) => {
  const isMediaMax1025px = useMediaQuery('(max-width : 1025px)')
  return (
    <div className="min-h-[100vh] overflow-scroll w-full flex justify-between no-scrollbar relative flex-col items-center  overflow-x-hidden">
      {isMediaMax1025px && !useMainHeader ? (
        <MainMobileHeader />
      ) : (
        <MainHeader />
      )}
      <div className="w-full min-h-[90vh]">{children}</div>
      {isMediaMax1025px || noFooter ? (
        <></>
      ) : createPage ? (
        <CopyrightFooter />
      ) : (
        <div className="min-h-[10vh] w-full text-center justify-center  flex items-center font-normal text-sm	text-[#777777]">
          <Footer />
        </div>
      )}
    </div>
  )
}

export default MainWrapper
