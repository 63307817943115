import React from 'react'
import copyToClipboard from 'copy-to-clipboard'

import { useParams, useSearchParams } from 'react-router-dom'
import { DynamicModal } from '../../pages/PublicPortal/components/DynamicModal'
import { Button } from '../../pages/PublicPortal/components/Button'
import { LucideIcon } from '@fileverse/ui'
import { ChevronRight, Clock, MessageSquareText } from 'lucide-react'
import sendNotifcation from '../../utils/notification'

const EmptyPortalKeysPopup = ({
  isOpen,
  fetchAndHandleMessages,
}: {
  isOpen: boolean
  fetchAndHandleMessages: (interval?: NodeJS.Timeout) => void
}) => {
  const { address: contractAddress } = useParams()
  const [searchParams] = useSearchParams()
  const chainId = parseInt(searchParams.get('chainId') || '')
  const copyFileURL = `${window.origin}${window.location.pathname}#/${contractAddress}/?chainId=${chainId}`
  return (
    <>
      <DynamicModal
        className="max-w-[525px]"
        open={isOpen}
        onOpenChange={() =>
          sendNotifcation(
            'This modal will automatically close once you receive your keys',
            '',
            'info'
          )
        }
        title={'Yay, almost done!'}
        content={
          <div className="w-full flex flex-col justify-center items-center gap-8 px-6">
            <p className="text-body-md-bold text-center">
              {`You're now registered as a Collaborator. You'll have full access
              once the Portal owner connects to the Portal.`}
            </p>
            <div className="w-full flex justify-between items-center text-center relative">
              <div className="w-[50%] flex flex-col gap-3 justify-center items-center">
                <MessageSquareText size={42} />
                <p className="text-body-sm">
                  Inform the owner that you have accepted the collaboration
                </p>
              </div>
              <span className="absolute top-[30%] left-[55%] transform -translate-x-1/2 -translate-y-1/2">
                <ChevronRight size={52} stroke="#A1AAB1" />
              </span>
              <div className="w-[40%] flex flex-col gap-3 justify-center items-center">
                <Clock size={42} />
                <p className="text-body-sm">
                  Wait for approval and refresh this page
                </p>
              </div>
            </div>
            <div className="w-full flex justify-center items-center gap-4">
              <Button
                variant={'secondary'}
                onClick={() => {
                  copyToClipboard(copyFileURL)
                  sendNotifcation('Link copied!', '', 'success')
                }}
                className="flex gap-2"
              >
                <LucideIcon name="Copy" fill="none" size="sm" />
                Copy Link
              </Button>
              <Button className="flex gap-2" onClick={fetchAndHandleMessages}>
                <LucideIcon
                  name="RefreshCw"
                  fill="none"
                  stroke="white"
                  size="sm"
                />
                Refresh
              </Button>
            </div>
          </div>
        }
      />
    </>
  )
}

export default EmptyPortalKeysPopup
