export const formatAddress = (address: string) => {
  if (!address) return ''
  return address.slice(0, 5) + '...' + address.slice(address.length - 5)
}

export const convertAddress = (address: string) => {
  // Convert wallet address string and return a unique number based on the string ranging from 0 to 1
  if (!address) return 0
  let hash = 0
  for (let i = 0; i < address.length; i++) {
    hash = address.charCodeAt(i) + ((hash << 5) - hash)
    hash = hash & hash
  }
  return Math.abs(hash) / Math.pow(2, 32)
}
