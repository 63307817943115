/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { SetStateAction, useEffect, useState } from 'react'
import { Signer } from 'ethers'

import { editFile } from '../../store/files/reducer'
import { UploadToContractPayload } from '../../store/files/thunks'
import { FileSource, FileTypeEnum } from '../../types/enum/file.enum'
import { INewFile, IFileMetaData } from '../../types/interface/file.interface'
import { MakeFileFromObject } from '../../utils/makeFileFromObject'
import sendNotifcation, { clearAllNotification } from '../../utils/notification'
import { useParams, useSearchParams } from 'react-router-dom'
import { usePortalSignlessMode } from '../../store/contract/hooks'
import { useFileData } from '../../store/files/hooks'
import { useAppDispatch } from '../../store/hooks'
import {
  useInvokerContractCredentials,
  useServerKeys,
} from '../../store/invoker/hooks'
import { TokenGateHashInfo } from '../../types/interface/token.interface'
import { TIPFSResponse } from '../TaskRunnerWidget/types'
import { uploadSingleFileToIPFS, uploadToIPFS } from '../../utils/uploadUtills'
import { editFileTrxCall } from '../../utils/transaction'
import { generateFileMetadataByType } from '../../utils/files'
import { getIPFSAsset } from '../../utils/getIPFSAsset'
import { useGunNodes } from '../../hooks/useGunNode'
import { ISEAPair } from 'gun'
import { useEthersSigner } from '../../hooks/clientToProvider'
import { captureException } from '@sentry/react'
import { GunInstance } from '../../utils/instantiateGun'
import { validateOrSwitchNetwork } from '../../utils/ethUtils'
import { AllowedChainId } from '../../config/wagmi-config'
import { usePrivyHelper } from '../../hooks/usePrivyHelper'
import { Button } from '../../pages/PublicPortal/components/Button'
import { Tooltip } from '@mui/material'

interface PublishEditsProps {
  fileName: string
  commentsEnabled: boolean
  rtcId: string
  rtcKey: ISEAPair
  isDisabled?: boolean
  setIsEditing: React.Dispatch<SetStateAction<boolean>>
  publishPlugin: () => File
  dPageInfo?: {
    emoji: string
    coverIPFSHash: string
  }
}

export default function PublishEdits({
  fileName,
  commentsEnabled,
  rtcId,
  rtcKey,
  isDisabled,
  setIsEditing,
  publishPlugin,
  dPageInfo,
}: PublishEditsProps) {
  const [tokenDetails, setTokenDetails] = useState<TokenGateHashInfo>()

  const { address: contractAddress, fileId } = useParams()
  const [urlParams] = useSearchParams()
  const walletAddress = usePrivyHelper().walletAddress
  const credential = useInvokerContractCredentials(
    walletAddress as string,
    contractAddress as string
  )
  const isSignless = usePortalSignlessMode(contractAddress as string)
  const serverKeys = useServerKeys(
    walletAddress as string,
    contractAddress as string
  )
  const fileInformation = useFileData(contractAddress as string, fileId)
  const dispatch = useAppDispatch()
  const chainId = parseInt(urlParams.get('chainId') || '')
  const contractSigner = useEthersSigner({ chainId })
  const [isPublishing, setIsPublishing] = useState<boolean>(false)
  const { metadata } = fileInformation as INewFile
  const {
    getEditedDpageContentNode,
    getEditedDpageAssetsNode,
    getEditedDdocAssetNode,
    getAuthenticatedDocumentContentNode,
  } = useGunNodes()

  const handlePublishEdit = async () => {
    if (isDisabled) {
      sendNotifcation(
        'Waiting for cover',
        'You can publish once your cover is updated',
        'warning'
      )
      return
    }
    setIsPublishing(true)
    const file = publishPlugin()
    if (file) {
      handleFileUpload(fileInformation?.metadata.fileType as FileTypeEnum, [
        file,
      ])
    } else {
      handleMetadataUpload(fileInformation?.metadata.fileType as FileTypeEnum)
    }
  }

  const handleMetadataUpload = async (type: FileTypeEnum) => {
    try {
      sendNotifcation(
        'Publishing your edits',
        'Do not close, refresh or edit',
        'info',
        20000
      )

      const oldFileMetaData = fileInformation?.metadata as IFileMetaData
      const updatedMetadata = {
        ...oldFileMetaData,
        name: fileName,
        commentsEnabled: commentsEnabled,
        editedAt: Math.floor(Date.now() / 1000),
      } as IFileMetaData

      const updatedMetadataFile = MakeFileFromObject(
        updatedMetadata,
        'metadata'
      )
      if (credential) {
        const updatedMetadataIPFSHash = await uploadSingleFileToIPFS({
          contractAddress,
          invoker: walletAddress as string,
          contentFile: updatedMetadataFile,
          fileName: `${fileName}.metadata.json`,
        })
        await signUploadTransaction({
          contractAddress: contractAddress as string,
          contractSigner: contractSigner as Signer,
          contentIpfsHash: fileInformation?.contentIPFSHash as string,
          metadataIpfsHash: updatedMetadataIPFSHash,
          fileType: type,
          gateIpfsHash: fileInformation?.gateIPFSHash,
          metadata: updatedMetadata,
        })
        handleFileEditedEvent({
          contractAddress: contractAddress as string,
          contractSigner: contractSigner as Signer,
          contentIpfsHash: fileInformation?.contentIPFSHash as string,
          metadataIpfsHash: updatedMetadataIPFSHash,
          fileType: type,
          gateIpfsHash: fileInformation?.gateIPFSHash,
          metadata: updatedMetadata,
        })
      } else {
        sendNotifcation('Invalid Credentials', '', 'danger')
        setIsPublishing(false)
        return
      }
    } catch (error: any) {
      console.log(error)
      sendNotifcation(error?.message, '', 'danger')
      setIsPublishing(false)
    }
  }

  const handleFileEditedEvent = async (uploadInfo: UploadToContractPayload) => {
    const updateFile: INewFile = {
      ...(fileInformation as INewFile),
      metadataIPFSHash: uploadInfo?.metadataIpfsHash,
      contentIPFSHash: uploadInfo?.contentIpfsHash,
      metadata: uploadInfo?.metadata as IFileMetaData,
      cache: { metadataIPFSUrl: '', contentIPFSUrl: '' },
    }
    dispatch(
      editFile({
        contractAddress: contractAddress as string,
        fileData: updateFile,
      })
    )
    if (rtcId) {
      const { source } = metadata
      try {
        if (source === FileSource.DPAGE) {
          const draftEditAssetNode = getEditedDpageAssetsNode(rtcId as string)
          const draftEditContentNode = getEditedDpageContentNode(
            rtcId as string
          )
          if (draftEditContentNode)
            await GunInstance.putGunNodeData(draftEditContentNode, null)
          if (draftEditAssetNode)
            await GunInstance.putGunNodeData(draftEditAssetNode, null)
        }
        if (source === FileSource.COLLAB_DOCUMENT) {
          const draftEditAssetNode = getEditedDdocAssetNode(rtcId, rtcKey)
          const draftEditContentNode = getAuthenticatedDocumentContentNode(
            rtcId,
            rtcKey
          )
          if (draftEditContentNode)
            await GunInstance.putGunNodeData(draftEditContentNode, null)
          if (draftEditAssetNode)
            await GunInstance.putGunNodeData(draftEditAssetNode, null)
        }
      } catch (err) {
        console.log(err)
        captureException(`Failed to clean up draft for source ${source}`)
      }
    }

    setIsEditing(false)
  }

  const signUploadTransaction = async (x: UploadToContractPayload) => {
    const {
      contractAddress,
      contentIpfsHash,
      metadataIpfsHash,
      fileType,
      gateIpfsHash,
      metadata,
    } = x
    try {
      await editFileTrxCall(
        {
          fileId: Number(fileId),
          contractAddress: contractAddress as string,
          contentIpfsHash,
          metadataIpfsHash,
          walletAddress: walletAddress as string,
          fileType: fileType as FileTypeEnum,
          gateIpfsHash: gateIpfsHash as string,
        },
        isSignless
      )
      await handleFileEditedEvent({
        contractAddress: contractAddress as string,
        contractSigner: contractSigner as Signer,
        contentIpfsHash,
        metadataIpfsHash,
        fileType,
        gateIpfsHash: gateIpfsHash,
        metadata,
      })
      clearAllNotification()
    } catch (error: any) {
      sendNotifcation('Signing failed', error?.message, 'danger')
      setIsPublishing(false)
    }
  }

  const handleFileUpload = async (type: FileTypeEnum, files: File[]) => {
    try {
      await validateOrSwitchNetwork(chainId as AllowedChainId)
      setIsPublishing(true)
      sendNotifcation(
        'Publishing your edits',
        'Do not close, refresh or edit',
        'info',
        50000
      )
      const generatedFile = await generateFileMetadataByType(
        walletAddress as string,
        files[0],
        type,
        serverKeys,
        contractAddress as string,
        {
          source: fileInformation?.metadata.source,
          version: fileInformation?.metadata.version,
          tokenDetails,
          isEdited: true,
          uploadedAt: fileInformation?.metadata?.uploadedAt,
          orignalOwner: fileInformation?.metadata?.owner,
          editedBy: walletAddress,
          ...dPageInfo,
        }
      )

      const { contentFile, metadata: newFileMetadata, fileTags } = generatedFile
      const { gateIpfsHash = '' } = { ...generatedFile }
      const oldFileMetaData = fileInformation?.metadata as IFileMetaData
      const updatedMetadata = {
        ...newFileMetadata,
        name: fileName,
        commentsEnabled: commentsEnabled,
        version: oldFileMetaData.version,
        portalLock: {
          ...newFileMetadata.portalLock,
          lockedChatKey: oldFileMetaData.portalLock?.lockedChatKey,
        },
        ownerLock: {
          ...newFileMetadata.ownerLock,
          lockedChatKey: oldFileMetaData?.ownerLock?.lockedChatKey,
        },
        memberLock: {
          ...newFileMetadata.memberLock,
          lockedChatKey: oldFileMetaData?.memberLock?.lockedChatKey,
        },
        tokenLock: newFileMetadata.tokenLock,
        publicLock: oldFileMetaData.publicLock,
        isEdited: true,
      }

      const updatedMetadataFile = MakeFileFromObject(
        updatedMetadata,
        'metadata'
      )

      if (credential) {
        const { contentIpfsHash, metadataIpfsHash }: TIPFSResponse =
          await uploadToIPFS({
            contractAddress,
            invoker: walletAddress as string,
            metadataFile: updatedMetadataFile,
            contentFile: contentFile,
            fileTags: fileTags,
          })

        await signUploadTransaction({
          contractAddress: contractAddress as string,
          contractSigner: contractSigner as Signer,
          contentIpfsHash,
          metadataIpfsHash,
          fileType: type,
          gateIpfsHash: gateIpfsHash,
          metadata: updatedMetadata,
        })
      } else {
        sendNotifcation('Invalid Credentials', '', 'danger')
        setIsPublishing(false)
        return
      }
    } catch (error: any) {
      console.log(error)
      sendNotifcation(error?.message, '', 'danger')
      setIsPublishing(false)
    }
  }
  const setFileTokenInfo = async () => {
    const fileInfo = await getIPFSAsset({
      ipfsHash: fileInformation?.gateIPFSHash!,
    })
    setTokenDetails(fileInfo?.data)
  }

  useEffect(() => {
    if (fileInformation?.metadata.fileType === FileTypeEnum.GATED) {
      setFileTokenInfo()
    }
  }, [])

  const text =
    'Publish online your final version & pick if it should be a Public or Private'

  return (
    <Tooltip
      title={text}
      classes={{
        tooltip: 'bg-black text-white',
        arrow: 'text-black',
      }}
      arrow
      placement="bottom"
    >
      <Button
        onClick={handlePublishEdit}
        isLoading={isPublishing}
        disabled={isDisabled || isPublishing}
        className="min-w-[80px] h-[36px]"
      >
        Publish
      </Button>
    </Tooltip>
  )
}
