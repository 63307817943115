import { IContract } from '../../types/interface/contract.interface'

export function useInvokerOwnedContracts(
  invokerContracts: string[],
  allPortals: Record<string, IContract>,
  accountAddress: string
): string[] {
  if (!accountAddress) return []
  const portals: string[] = []
  invokerContracts.forEach((portal) => {
    if (allPortals[portal]?.owner === accountAddress) {
      return portals.push(portal)
    }
  })
  return portals
}
