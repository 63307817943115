import { Contract, JsonRpcProvider, JsonRpcSigner, Wallet } from 'ethers'
import {
  MetaTxInfo,
  MetaTxRequest,
} from '../../types/interface/metaTx.interface'
import { signTypedData, SignTypedDataVersion } from '@metamask/eth-sig-util'
import { instantiateForwarderContract } from '../instantiateContract'
import { defaultNetwork } from '../../config/network-config'
import { RelayerError } from '../errors'

const EIP712Domain = [
  { name: 'name', type: 'string' },
  { name: 'version', type: 'string' },
  { name: 'chainId', type: 'uint256' },
  { name: 'verifyingContract', type: 'address' },
]

const ForwardRequest = [
  { name: 'from', type: 'address' },
  { name: 'to', type: 'address' },
  { name: 'value', type: 'uint256' },
  { name: 'gas', type: 'uint256' },
  { name: 'nonce', type: 'uint256' },
  { name: 'data', type: 'bytes' },
  { name: 'validUntilTime', type: 'uint256' },
]

export function getMetaTxTypeData(chainId: number, verifyingContract: string) {
  return {
    types: {
      EIP712Domain,
      ForwardRequest,
    },
    domain: {
      name: 'FileverseMetaTxForwarder',
      version: '1.0',
      chainId,
      verifyingContract,
    },
    primaryType: 'ForwardRequest',
  }
}

export async function _signTypedData(
  signer: string | JsonRpcProvider,
  from: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any
) {
  // If signer is a private key, use it to sign
  if (typeof signer === 'string') {
    const privateKey = Buffer.from(signer.replace(/^0x/, ''), 'hex')
    return signTypedData({
      privateKey,
      data,
      version: SignTypedDataVersion.V4,
    })
  }

  // Otherwise, send the signTypedData RPC call
  // Note that hardhatvm and metamask require different EIP712 input

  const isHardhat = data.domain.chainId == 31337
  const [method, argData] = isHardhat
    ? ['eth_signTypedData', data]
    : ['eth_signTypedData_v4', JSON.stringify(data)]
  return await signer.send(method, [from, argData])
}

export async function buildRequest(
  forwarder: Contract,
  info: MetaTxInfo
): Promise<MetaTxRequest> {
  const nonce = await forwarder
    .getNonce(info.from)
    .then((nonce: number) => nonce.toString())
  return {
    value: 0,
    gas: 3576281,
    nonce: nonce,
    validUntilTime: 0,
    ...info,
  }
}

export async function buildTypedData(
  forwarder: Contract,
  request: MetaTxRequest
) {
  const chainId: number = await forwarder.runner?.provider
    ?.getNetwork()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .then((n: any) => n.chainId)
  const typeData = getMetaTxTypeData(
    Number(chainId),
    forwarder.target.toString()
  )
  return { ...typeData, message: request }
}

export async function signMetaTxRequest(
  signer: string | JsonRpcProvider,
  forwarder: Contract,
  info: MetaTxInfo
) {
  const request = await buildRequest(forwarder, info)
  const messageToSign = await buildTypedData(forwarder, request)
  const signature = await _signTypedData(signer, info.from, messageToSign)
  return { signature, request }
}
export const sendMetaTx = async (
  invokerAddress: string,
  contract: Contract,
  encodedFunction: string,
  signer: Wallet | JsonRpcSigner
) => {
  const url = defaultNetwork.autotask
  if (!url) throw new Error(`Missing relayer url`)
  try {
    const forwarder = instantiateForwarderContract(signer)
    const from = invokerAddress

    const to = contract.target.toString()
    const txSigner = (signer as Wallet)?.privateKey
      ? (signer as Wallet).privateKey
      : (signer.provider as JsonRpcProvider)

    const request = await signMetaTxRequest(txSigner, forwarder, {
      to,
      from,
      data: encodedFunction,
    })
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(request),
      headers: { 'Content-Type': 'application/json' },
    })
    if (response.status !== 200)
      throw new Error(`Relayer responded with status code ${response.status}`, {
        cause: request,
      })
    const responseJson = await response.json()
    return responseJson
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    throw new RelayerError(error?.message || 'Request send to relayer failed', {
      cause: error?.cause,
    })
  }
}
