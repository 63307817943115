/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo } from 'react'
import {
  FileType,
  ellipseFileName,
  formatFileSize,
  getFileType,
} from '../../../../../utils/fileUtils'
import styles from './index.module.scss'
import {
  File,
  FileMusic,
  FileSpreadsheet,
  FileText,
  FileImage,
  PanelsTopLeft,
  FileVideo,
  InfoIcon,
  PenLine,
} from 'lucide-react'
import cn from 'classnames'
import { withTooltip } from '../../../../withTooltip'

export const FILE_ICON_MAP = {
  [FileType.Image]: <FileImage size={16} />,
  [FileType.Video]: <FileVideo size={16} />,
  [FileType.PDF]: <FileText size={16} />,
  [FileType.Sheet]: <FileSpreadsheet size={16} />,
  [FileType.Document]: <FileText size={16} />,
  [FileType.DPage]: <PanelsTopLeft size={16} />,
  [FileType.DDoc]: <FileText size={16} />,
  [FileType.Whiteboard]: <PenLine size={16} />,
  [FileType.Other]: <File size={16} />,
  [FileType.Audio]: <FileMusic size={16} />,
}

export const FileListItem = memo(function FileListItem({
  file,
  fileArgs,
  errorText,
}: {
  file: File
  fileArgs: any
  errorText: null | string
}) {
  const { name, size } = file
  const fileType = getFileType(name, fileArgs)
  const formattedFileSize = formatFileSize(size, 1)
  const ellipsedName = ellipseFileName(name)
  const hasError = errorText !== null
  return (
    <div className={cn(styles.file_item, { [styles.has_error]: hasError })}>
      <div className={styles.file_icon_and_name}>
        <div className="rounded color-bg-brand p-1">
          <div className={styles.file_icon}>{FILE_ICON_MAP[fileType]}</div>
        </div>
        <div
          className={cn('color-text-default text-body-sm', styles.file_name)}
        >
          {ellipsedName}
        </div>
      </div>
      <FileSizeIndicator size={formattedFileSize} errorText={errorText} />
    </div>
  )
})

const FileSizeIndicator = ({
  size,
  errorText,
}: {
  size: string
  errorText: null | string
}) => {
  if (errorText) {
    const InfoToolTip = withTooltip(InfoIcon, errorText)
    return (
      <div className={styles.file_info_icon}>
        <InfoToolTip />
      </div>
    )
  }
  return (
    <div className={cn('color-text-secondary text-body-sm', styles.file_size)}>
      {size}
    </div>
  )
}
