/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import decryptFile from './decryption/decryptFile'
import { getIPFSAsset } from '../getIPFSAsset'
import { IServerKey } from '../../types/interface/invoker.interface'
import { openLock } from './decryption/openLock'
import {
  DataLockedKeyMaterial,
  INewFile,
} from '../../types/interface/file.interface'
import { ISEAPair } from 'gun'
import { getUserFilePermission } from '../getUserPortalPermision'
import {
  getAndVerifyMemberKeysAndUnlockFile,
  unlockFile,
  verifyAccountAssetAndUnlockFile,
} from './decryption/utils'
import isEmpty from 'lodash/isEmpty'
import sendNotifcation from '../notification'

export interface IFileAccess {
  fileUrl: string
  chatKey: ISEAPair
  decryptionKey?: string
  ipfsUrl: string
}

export const getPublicFileUrl = async (
  contentIPFSHash: string,
  ipfsUrl?: string
) => {
  const content: any = await getIPFSAsset({
    ipfsHash: contentIPFSHash,
    lastIPFSUrl: ipfsUrl,
    options: { responseType: 'blob' },
  })
  return {
    downloadUrl: URL.createObjectURL(content?.data),
    ipfsUrl: content.url,
  }
}
export const getPrivateFilePreview = async (
  portalLock: DataLockedKeyMaterial,
  contentIPFSHash: string,
  serverKeys: IServerKey
) => {
  const unlockedFileData = await unlockFile(
    serverKeys.portalDecryptionKey,
    portalLock,
    contentIPFSHash
  )
  if (unlockedFileData) {
    return {
      decryptedFileUrl: unlockedFileData.file,
      chatKey: unlockedFileData.chatKey,
    }
  }
}

export const handleCollaboratorPrivateFile = async (
  account: string,
  fileData: INewFile,
  serverKeys: IServerKey
): Promise<IFileAccess | undefined> => {
  if (!fileData) {
    return
  }

  const userPermission = getUserFilePermission(account, fileData, serverKeys)
  if (userPermission.owner && !fileData?.metadata?.isEdited) {
    try {
      const unlockedFileData = await unlockFile(
        serverKeys.ownerDecryptionKey,
        fileData.metadata.ownerLock,
        fileData.contentIPFSHash,
        fileData.cache?.contentIPFSUrl
      )
      if (unlockedFileData) {
        return {
          fileUrl: unlockedFileData.file,
          chatKey: unlockedFileData.chatKey,
          ipfsUrl: unlockedFileData.ipfsUrl,
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  if (userPermission.collaborator) {
    try {
      const unlockedFileData = await unlockFile(
        serverKeys.portalDecryptionKey,
        fileData.metadata.portalLock,
        fileData.contentIPFSHash,
        fileData.cache?.contentIPFSUrl
      )
      if (unlockedFileData) {
        return {
          fileUrl: unlockedFileData.file,
          chatKey: unlockedFileData.chatKey,
          ipfsUrl: unlockedFileData.ipfsUrl,
        }
      }
    } catch (error) {
      throw new Error('File access denied')
    }
  }
  return
}

export const handleMemberPrivateFile = async (
  account: string,
  fileData: INewFile,
  serverKeys: IServerKey,
  contractAddress: string,
  chainId: number,
  signMessageAsync: (args?: any) => Promise<`0x${string}`>
): Promise<IFileAccess | undefined> => {
  try {
    if (!account || !fileData) {
      return
    }
    const userPermission = getUserFilePermission(account, fileData, serverKeys)
    if (userPermission.owner && !fileData?.metadata?.isEdited) {
      try {
        const unlockedFileData = await unlockFile(
          serverKeys.ownerDecryptionKey,
          fileData.metadata.ownerLock,
          fileData.contentIPFSHash,
          fileData.cache?.contentIPFSUrl
        )
        if (unlockedFileData) {
          return {
            fileUrl: unlockedFileData.file,
            chatKey: unlockedFileData.chatKey,
            ipfsUrl: unlockedFileData.ipfsUrl,
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
    if (userPermission.collaborator) {
      try {
        const unlockedFileData = await unlockFile(
          serverKeys.portalDecryptionKey,
          fileData.metadata.portalLock,
          fileData.contentIPFSHash,
          fileData.cache?.contentIPFSUrl
        )
        if (unlockedFileData) {
          return {
            fileUrl: unlockedFileData.file,
            chatKey: unlockedFileData.chatKey,
            ipfsUrl: unlockedFileData.ipfsUrl,
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
    if (userPermission.member) {
      try {
        const unlockedFileData = await unlockFile(
          serverKeys.memberDecryptionKey,
          fileData.metadata.memberLock,
          fileData.contentIPFSHash,
          fileData.cache?.contentIPFSUrl
        )
        if (unlockedFileData) {
          return {
            fileUrl: unlockedFileData.file,
            chatKey: unlockedFileData.chatKey,
            ipfsUrl: unlockedFileData.ipfsUrl,
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
    const fileAccess = await getAndVerifyMemberKeysAndUnlockFile(
      contractAddress,
      account,
      signMessageAsync,
      chainId,
      fileData.metadata.memberLock,
      fileData.contentIPFSHash
    )
    return fileAccess
  } catch (error: any) {
    sendNotifcation('Failed to view member file', error?.message, 'danger')
    return
  }
}

export const handleTokenGatedFile = async (
  account: string,
  fileData: INewFile,
  serverKeys: IServerKey,
  contractAddress: string,
  chainId: number,
  signMessageAsync: (args?: any) => Promise<`0x${string}`>
): Promise<IFileAccess | undefined> => {
  if (isEmpty(fileData)) {
    return
  }
  const userPermission = getUserFilePermission(account, fileData, serverKeys)
  if (userPermission.owner && !fileData?.metadata?.isEdited) {
    try {
      const unLockedKeys = await openLock(
        serverKeys.ownerDecryptionKey,
        fileData.metadata.ownerLock
      )
      if (unLockedKeys) {
        const result = await decryptFile(
          unLockedKeys.fileKey,
          fileData.contentIPFSHash,
          fileData.cache?.contentIPFSUrl
        )
        return {
          fileUrl: result.downloadUrl,
          chatKey: unLockedKeys.chatKey,
          ipfsUrl: result.ipfsUrl as string,
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  if (userPermission.collaborator) {
    try {
      const unLockedKeys = await openLock(
        serverKeys.portalDecryptionKey,
        fileData.metadata.portalLock
      )
      if (unLockedKeys) {
        const result = await decryptFile(
          unLockedKeys.fileKey,
          fileData.contentIPFSHash,
          fileData.cache?.contentIPFSUrl
        )
        return {
          fileUrl: result.downloadUrl,
          chatKey: unLockedKeys.chatKey,
          ipfsUrl: result.ipfsUrl as string,
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  const fileAccess = await verifyAccountAssetAndUnlockFile(
    contractAddress,
    account,
    signMessageAsync,
    chainId,
    fileData.metadata.tokenLock,
    fileData.contentIPFSHash,
    fileData.gateIPFSHash
  )
  return fileAccess
}
