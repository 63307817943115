/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import {
  cn,
  formatCounter,
  Card,
  CardHeader,
  CardDescription,
  CardTitle,
  CardContent,
  CardFooter,
  Icon,
  ButtonGroup,
  Button,
  LucideIcon,
} from '@fileverse/ui'
import { CardItemProps } from '../PortalCardItem'
import { getPublicFileUrl } from '../../../../utils/files/filePreviewHandlers'
import { exportToBlob } from '@fileverse/whiteboard'
import { NonDeletedExcalidrawElement } from '@fileverse/whiteboard/types/element/types'
import { BinaryFiles } from '@fileverse/whiteboard/types/types'
import { getIPFSAsset } from '../../../../utils/getIPFSAsset'
import { animationConfig } from '../CardControlWrapper'
import { motion } from 'framer-motion'

export const PortalCard = ({
  title,
  size,
  type,
  likeCount,
  classNames,
  canShare = true,
  metadata,
  noFooter,
  onLike,
}: CardItemProps & { noFooter?: boolean; onLike?: (e: any) => void }) => {
  const [imgSrc, setImgSrc] = useState<string | null>(null)

  useEffect(() => {
    ;(async () => {
      try {
        const result = await getPublicFileUrl(metadata.ipfsHash)
        const fileData = await (await fetch(result.downloadUrl)).text()
        const file = JSON.parse(fileData)
        if (type === 'whiteboard') {
          if (file.file.appState) {
            const data = await exportToBlob({
              elements: file.file.elements as NonDeletedExcalidrawElement[],
              appState: file.file.appState,
              files: file.file.files as BinaryFiles,
            })
            const url = URL.createObjectURL(data)
            setImgSrc(url)
          }
        }
        if (type === 'dPage') {
          const image = await getIPFSAsset({
            ipfsHash: file.coverIPFSHash,
            options: { responseType: 'blob' },
          })
          const imageURL = URL.createObjectURL(image?.data)
          setImgSrc(imageURL)
        }
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])

  return (
    <Card size={size} className={cn('', classNames)}>
      <motion.div
        className="w-full h-full transition-opacity absolute top-0 left-0 p-5"
        layout="position"
        {...animationConfig}
      >
        <div
          className={cn(
            'flex justify-between h-[calc(100%-1rem)] pb-2',
            size !== 'md' ? 'flex-col' : 'flex-row',
            size !== 'sm' && 'gap-2'
          )}
        >
          <CardHeader
            className={cn({
              'flex-[0.8]': size === 'md',
            })}
          >
            <div className="flex flex-row justify-between">
              <div className="rounded-sm color-bg-brand p-1">
                <LucideIcon
                  name={
                    type === 'dDoc'
                      ? 'FileText'
                      : type === 'dPage'
                      ? 'PanelsTopLeft'
                      : 'PenLine'
                  }
                  size="sm"
                  fill="transparent"
                />
              </div>
              {size === 'sm' ? <CardDescription>{type}</CardDescription> : null}
            </div>
            <CardTitle
              className="line-clamp-5 text-heading-xsm-bold"
              title={title}
            >
              {title}
            </CardTitle>
          </CardHeader>
          <motion.div
            layout="position"
            className={cn(
              'transition-opacity',
              size !== 'lg'
                ? 'max-w-[60%] min-w-[60%]'
                : 'w-full h-full xl:h-[80%]'
            )}
            {...animationConfig}
          >
            <CardContent
              className={cn('rounded-sm color-bg-tertiary p-0 h-full')}
            >
              {size !== 'sm' ? (
                <div className="relative h-full">
                  {imgSrc ? (
                    <img
                      src={imgSrc}
                      alt="Placeholder"
                      className="object-cover aspect-video w-full h-full rounded-sm"
                      loading="lazy"
                    />
                  ) : (
                    <CardDescription className="text-center flex flex-col justify-center items-center h-full">
                      <span className="rounded-sm color-bg-brand p-1">
                        <LucideIcon
                          name={
                            type === 'dDoc'
                              ? 'FileText'
                              : type === 'dPage'
                              ? 'PanelsTopLeft'
                              : 'PenLine'
                          }
                          size="lg"
                          fill="transparent"
                        />
                      </span>
                    </CardDescription>
                  )}
                </div>
              ) : null}
            </CardContent>
          </motion.div>
        </div>
        {!noFooter && (
          <CardFooter className="justify-between h-4">
            <motion.div
              layout="position"
              className="flex justify-between items-center w-full transition-opacity"
              {...animationConfig}
            >
              <ButtonGroup className="space-x-0 gap-3">
                <Button
                  onClick={onLike}
                  variant="ghost"
                  size="md"
                  className="min-w-fit p-0"
                >
                  <Icon name="Heart" size="sm" fill="black" className="mr-1" />
                  <span className="max-w-fit">
                    {likeCount && likeCount >= 1
                      ? formatCounter(likeCount)
                      : 'Like'}
                  </span>
                </Button>
                {canShare && (
                  <Button
                    variant="ghost"
                    size="md"
                    className="min-w-fit p-0 inline-flex lg:hidden"
                  >
                    <Icon
                      name="Share2"
                      size="sm"
                      fill="black"
                      className="mr-1"
                    />
                    Share
                  </Button>
                )}
              </ButtonGroup>
              {size !== 'sm' ? (
                <CardDescription className="text-muted-foreground">
                  {type}
                </CardDescription>
              ) : null}
            </motion.div>
          </CardFooter>
        )}
      </motion.div>
    </Card>
  )
}
