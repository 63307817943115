import axios from 'axios'

interface LogAnalyticsRequest {
  contract: string
  invoker?: string
  data: {
    eventName: string
    fileId: number | string
    ipfsHash?: string
    tags: string[]
  }

  chain: number
}

export const logAnalyticsAPI = async (request: LogAnalyticsRequest) => {
  return axios.post(
    `${process.env.REACT_APP_STORAGE_URL}/log/create`,
    request.data,
    {
      headers: {
        contract: request.contract,
        invoker: request.invoker || '',
        chain: request.chain,
      },
    }
  )
}
