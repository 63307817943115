import React, { useEffect, useState } from 'react'
import { useMediaQuery } from '@mui/material'
import mainLogo from '../../../assets/mainLogo.svg'
import { TokenGateHashInfo } from '../../../types/interface/token.interface'
import { getIPFSAsset } from '../../../utils/getIPFSAsset'
import ActionButton from '../../Buttons/ActionButton'
import { useParams } from 'react-router-dom'
import { useFileData } from '../../../store/files/hooks'
import isEmpty from 'lodash/isEmpty'
import { INewFile } from '../../../types/interface/file.interface'
import ConnectedWallet from './ConnectedWalletOverlay'
import { usePrivy } from '@privy-io/react-auth'
import { usePrivyHelper } from '../../../hooks/usePrivyHelper'
// import { useConnectModal } from '@rainbow-me/rainbowkit'
const TokenGatedFileOverLay = ({
  handleGatedFilePreview,
}: {
  handleGatedFilePreview: (fileDate: INewFile) => Promise<void>
}) => {
  const isMediaMax1025px = useMediaQuery('(max-width : 1025px)')
  const walletAddress = usePrivyHelper().walletAddress
  const [tokenInfo, setTokenInfo] = useState<TokenGateHashInfo>()
  const { address: contractAddress, fileId } = useParams()
  const fileData = useFileData(contractAddress as string, fileId)
  const [isVerifyingAccountAsset, setGettingTokenGatedKeys] = useState(false)
  const [flagToPreviewGatedFile, setFlagToPreviewGatedFile] = useState(false)
  const [hasVerifiedAsset, setHasVerifedAsset] = useState(false)
  const { connectOrCreateWallet } = usePrivy()
  useEffect(() => {
    ;(async () => {
      if (isEmpty(fileData)) return
      const fileInfo = await getIPFSAsset({
        ipfsHash: fileData.gateIPFSHash,
      })
      setTokenInfo(fileInfo?.data)
    })()
  }, [])
  useEffect(() => {
    if (flagToPreviewGatedFile) {
      handleGatedFilePreview(fileData as INewFile)
    }
  }, [flagToPreviewGatedFile])

  const verify = async () => {
    try {
      if (!walletAddress) {
        connectOrCreateWallet()
        // set flag to preview file after connecting wallet
        setFlagToPreviewGatedFile(true)
      } else {
        setGettingTokenGatedKeys(true)
        await handleGatedFilePreview(fileData as INewFile)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setGettingTokenGatedKeys(false)
      setHasVerifedAsset(true)
    }
  }
  return hasVerifiedAsset ? (
    <ConnectedWallet />
  ) : (
    <div
      className={`${
        isMediaMax1025px ? 'absolute bottom-0 w-full p-2' : 'max-w-[35rem] p-4'
      } bg-white w-full   rounded-lg flex flex-col items-center justify-center `}
      data-cy="gated-file-popup"
    >
      <div className="w-full">
        <h6 className={`font-bold  text-xl text-center `}>Token Gated File</h6>
        {isMediaMax1025px ? (
          <div className="w-full">
            <div className="w-full mb-2 flex justify-center">
              <img src={mainLogo} className=" w-20" />
            </div>
            <div className="flex flex-col justify-center">
              <ul className="list-disc list-inside">
                <li className="font-bold">
                  Connect your wallet that has the token &quot;{' '}
                  <>{tokenInfo?.name}</>
                  &quot;
                </li>
                <li className="font-bold">
                  {' '}
                  Enjoy the private file you have recieved
                </li>
              </ul>
            </div>
          </div>
        ) : (
          <div className="flex items-center">
            <img src={mainLogo} className="mr-4 w-16" />
            <div className="flex flex-col justify-center">
              <h6>
                Step 1: Connect your wallet that has the token &quot;
                <>{tokenInfo?.name}</>
                &quot;
              </h6>
              <h6>Step 2: Enjoy the private file you have recieved</h6>
            </div>
          </div>
        )}

        <div className="border p-4 rounded-lg  w-full mt-4">
          <h6 className="font-semibold text-lg">Token Details</h6>
          <div>
            <div className="flex mt-4 my-2 w-full">
              <h6 className=" text-[#777777] w-[15%] min-w-[70px]">Token :</h6>
              <p className="font-semibold truncate">{tokenInfo?.name}</p>
            </div>
            <div className="flex my-2 w-full">
              <h6 className=" text-[#777777] w-[15%] min-w-[70px] bg-yelo">
                Address :
              </h6>
              <p className="font-semibold truncate w-32">
                {tokenInfo?.address}
              </p>
            </div>
            <div className="flex my-2 w-full">
              <h6 className=" text-[#777777] w-[15%] min-w-[70px]">Chain : </h6>
              <p className="font-semibold w-32">{tokenInfo?.chain}</p>
            </div>
            <div className="flex my-2 w-full">
              <h6 className=" text-[#777777] w-[15%] min-w-[70px]">
                Amount :{' '}
              </h6>
              <p className="font-semibold w-32">{tokenInfo?.amount}</p>
            </div>
          </div>
        </div>
        <div className="w-full mt-2 flex justify-center">
          <ActionButton
            title={`${
              walletAddress ? 'Verify Account Asset' : 'Connect Wallet'
            }`}
            loadingText="Verifying Account Asset"
            isLoading={isVerifyingAccountAsset}
            cyId="verify-account-assets"
            onclick={verify}
          />
        </div>
      </div>
    </div>
  )
}

export default TokenGatedFileOverLay
