import React from 'react'
import Toolbar from '../Toolbar'
import { motion, useAnimation } from 'framer-motion'
import { usePublicPortalContext } from '../../../../providers/PublicPortalProvider'
import FilesPickerModal from '../FilesPickerModal'
import { DynamicModal } from '../DynamicModal'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
export const PublicPortalToolbar = () => {
  const navigate = useNavigate()
  const { address: contractAddress } = useParams()
  const [searchParams] = useSearchParams()
  const chainId = parseInt(searchParams.get('chainId') || '')
  const filePath = `/${contractAddress}?chainId=${chainId}`
  const toolbarRef = React.useRef<HTMLDivElement>(null)
  const controls = useAnimation()
  const {
    addSection,
    addTextFile,
    isViewMode,
    onSaveLayout,
    showConfirmModal,
    setShowConfirmModal,
    showAddFilesModal,
    setShowAddFilesModal,
    activeSectionIdx,
  } = usePublicPortalContext()

  // useEffect(() => {
  //   const handleMouseMove = (e: any) => {
  //     if (
  //       toolbarRef.current &&
  //       e.clientY > toolbarRef.current.getBoundingClientRect().top
  //     ) {
  //       controls.start('visible')
  //     } else {
  //       controls.start('hidden')
  //     }
  //   }
  //   window.addEventListener('mousemove', handleMouseMove)
  //   return () => {
  //     window.removeEventListener('mousemove', handleMouseMove)
  //   }
  // }, [controls])

  if (isViewMode) return null

  return (
    <>
      <motion.div
        className="fixed z-10 scale-[0.92] lg:scale-100 bottom-5 left-1/2 transform -translate-x-1/2"
        ref={toolbarRef}
        initial="visible"
        animate={controls}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
      >
        <Toolbar
          onAddSection={addSection}
          onAddText={() => addTextFile(activeSectionIdx)}
          onAddFiles={() => setShowAddFilesModal(true)}
          onUpdate={onSaveLayout}
        />
      </motion.div>
      {showAddFilesModal && (
        <FilesPickerModal
          open={showAddFilesModal}
          onOpenChange={setShowAddFilesModal}
        />
      )}
      {showConfirmModal && (
        <DynamicModal
          className="max-w-[600px]"
          open={showConfirmModal}
          onOpenChange={setShowConfirmModal}
          title="Did you forget?"
          description="You have unsaved changes. Are you sure you want to leave this page?"
          primaryAction={{
            label: 'Leave',
            onClick: () => {
              setShowConfirmModal(false)
              navigate(filePath)
            },
            className: 'w-full md:w-auto',
          }}
          secondaryAction={{
            label: 'Stay',
            onClick: () => setShowConfirmModal(false),
            className: 'w-full md:w-auto',
          }}
        />
      )}
    </>
  )
}
