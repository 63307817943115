/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from 'react'
import Folders from '../../components/Files/Folders'
import AllFiles from '../../components/Files/AllFiles'
import { useSearchParams, useParams } from 'react-router-dom'
import Drafts from '../../components/Files/Drafts'
import { useMediaQuery } from '@mui/material'
import usePortalEditFileEvent from '../../hooks/usePortalEditFileEvent'
import cn from 'classnames'
import {
  Tabs,
  TabsList,
  TabsTrigger,
  TabsContent,
  LucideIcon,
} from '@fileverse/ui'
import { useFileSearchValue } from '../../store/fileSearch/hooks'
import { FileSearchResult } from './Home/components/FileSearchResult'

const getDirectoryIcon = (directory: string) => {
  switch (directory) {
    case 'Images':
      return 'FileImage'
    case 'Audios':
      return 'FileMusic'
    case 'Videos':
      return 'FileVideo'
    case 'Documents':
      return 'File'
    case 'Whiteboards':
      return 'PenLine'
    case 'Notes':
      return 'List'
    case 'dPages':
      return 'PanelsTopLeft'
    case '3D':
      return 'Box'
    default:
      return 'File'
  }
}

export default function Files() {
  const [urlParams, setUrlParams] = useSearchParams()
  const [tab, setTab] = useState('files')
  const directory = urlParams.get('folder')
  const isMediaMax1025px = useMediaQuery('(max-width : 1025px)')
  const { address: contractAddress } = useParams()
  const fileSearchValue = useFileSearchValue()

  usePortalEditFileEvent(contractAddress as string)
  useEffect(() => {
    setTab(urlParams.get('tab') || 'files')
  }, [urlParams])
  return (
    <div
      data-cy="files-section"
      className={cn(
        'min-h-[88vh] relative h-full w-full max-h-[88vh] overflow-auto no-scrollbar'
      )}
    >
      {fileSearchValue.length < 3 ? (
        <div className="flex h-[50px] w-full">
          <Tabs className="w-full" defaultValue="files">
            <TabsList className="w-full justify-start">
              {directory && (
                <>
                  <TabsTrigger
                    value={'folders'}
                    onClick={() => {
                      urlParams.set('tab', 'folders')
                      urlParams.delete('folder')
                      setUrlParams(urlParams)
                    }}
                    className="gap-1 text-body-md-bold color-text-default border-b-0"
                  >
                    <LucideIcon name="Folder" size="sm" fill="transparent" />
                    Folders
                  </TabsTrigger>
                  <div className="flex h-full justify-center cursor-pointer gap-2">
                    <div className="flex items-center justify-center">
                      <LucideIcon
                        name="ChevronRight"
                        size="sm"
                        fill="transparent"
                      />
                    </div>

                    <div className="flex items-center justify-center border-b-2 border-black gap-1 py-1 px-4">
                      <LucideIcon
                        name={getDirectoryIcon(directory)}
                        size="sm"
                        fill="transparent"
                      />
                      <div className="text-body-md-bold color-text-default text-sm">
                        {directory}
                      </div>
                    </div>
                  </div>
                </>
              )}

              {!directory && (
                <>
                  <TabsTrigger
                    value={'files'}
                    onClick={() => {
                      urlParams.set('tab', 'files')
                      setUrlParams(urlParams)
                    }}
                    className="w-full lg:w-auto gap-1 text-body-md-bold color-text-default"
                  >
                    <LucideIcon name="File" size="sm" fill="transparent" />
                    All Files
                  </TabsTrigger>
                  <TabsTrigger
                    value={'folders'}
                    onClick={() => {
                      urlParams.set('tab', 'folders')
                      urlParams.delete('folder')
                      setUrlParams(urlParams)
                    }}
                    className="w-full lg:w-auto gap-1 text-body-md-bold color-text-default"
                  >
                    <LucideIcon name="Folder" size="sm" fill="transparent" />
                    Folders
                  </TabsTrigger>
                  {!isMediaMax1025px && (
                    <TabsTrigger
                      value={'drafts'}
                      onClick={() => {
                        urlParams.set('tab', 'drafts')
                        setUrlParams(urlParams)
                      }}
                      className="gap-1 text-body-md-bold color-text-default"
                    >
                      <LucideIcon name="Pencil" size="sm" fill="transparent" />
                      Drafts
                    </TabsTrigger>
                  )}
                </>
              )}
            </TabsList>
            <TabsContent value={tab} className="w-full">
              <div className="w-full h-full">
                {tab === 'folders' && <Folders />}
                {tab === 'files' && <AllFiles />}
                {tab === 'drafts' && <Drafts />}
              </div>
            </TabsContent>
          </Tabs>
        </div>
      ) : (
        <FileSearchResult />
      )}
    </div>
  )
}
