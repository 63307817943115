// import { ConnectButton } from '@rainbow-me/rainbowkit'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useVisibility from '../../../hooks/useVisibility'
import { updateAddress } from '../../../store/account/reducer'
import { useAllContract } from '../../../store/contract/hooks'
import { useAppDispatch } from '../../../store/hooks'
import { useInvokerContracts } from '../../../store/invoker/hooks'
import { useInvokerOwnedContracts } from '../../../store/invoker/utils'
import AboutDropDown from '../AboutDropDown'
import PrimaryButton from '../PrimaryButton'
import contractLogo from '../../../assets/mainLogo.svg'
import { DisconnectedWalletSideMenu } from '../MobileSideMenu'
import { usePrivy } from '@privy-io/react-auth'
import { usePrivyHelper } from '../../../hooks/usePrivyHelper'
import { LucideIcon } from '@fileverse/ui'

const RegularMobileHeader = () => {
  const walletAddress = usePrivyHelper().walletAddress
  const invokerContracts = useInvokerContracts(walletAddress as string)
  const allPortals = useAllContract()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [sideMenu, setSideMenu] = useState(false)
  const hamburgerbar = useRef(null)
  const [isNavigateToPortals, setIsNavigateToPortals] = useState<boolean>(false)
  const { connectOrCreateWallet } = usePrivy()

  const {
    ref: aboutButtonRef,
    isComponentVisible: aboutDropDown,
    setIsComponentVisible: setAboutDropDown,
  } = useVisibility(false)
  useEffect(() => {
    if (walletAddress && isNavigateToPortals) {
      const userOwnedPortal = useInvokerOwnedContracts(
        invokerContracts,
        allPortals,
        walletAddress as string
      )
      if (userOwnedPortal.length > 0) {
        navigate(`/contracts`)
        setIsNavigateToPortals(false)
      } else {
        navigate(`/create`)
        setIsNavigateToPortals(false)
      }
      dispatch(updateAddress({ address: walletAddress }))
    }
  }, [walletAddress])
  return (
    <>
      <div
        ref={aboutButtonRef}
        className="h-[10vh] w-full max-h-[100px] flex justify-between items-center py-2 drop-shadow-md"
      >
        <div className="h-full flex items-center">
          <img
            src={contractLogo}
            alt="logo"
            className="object-cover h-[3rem] rounded-lg mr-4"
          />
          <div className="flex flex-col max-w-[7rem]">
            <div className="font-bold truncate">Fileverse</div>
          </div>
        </div>
        {aboutDropDown && (
          <AboutDropDown setIsNavigateToPortals={setIsNavigateToPortals} />
        )}

        <div className="w-[50%] flex items-center justify-end">
          {(() => {
            if (walletAddress) {
              return (
                <div
                  data-cy="hamburger-icon"
                  ref={hamburgerbar}
                  onClick={() => {
                    document
                      .getElementById('memberMobileView')
                      ?.classList.remove('overflow-scroll')
                    setSideMenu(!sideMenu)
                  }}
                  className=""
                >
                  <LucideIcon name="Menu" className="w-9 h-9" />
                </div>
              )
            }
            return (
              <>
                <button
                  id="wallet-connect-btn"
                  onClick={connectOrCreateWallet}
                  type="button"
                  className="hidden"
                  data-cy="Wallet-connect"
                />
                <PrimaryButton
                  onClick={() => setAboutDropDown(!aboutDropDown)}
                  title="About"
                />
              </>
            )
          })()}
        </div>
      </div>
      <DisconnectedWalletSideMenu
        sideMenu={sideMenu}
        setSideMenu={setSideMenu}
      />
    </>
  )
}

export default RegularMobileHeader
