/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createMemberPrivateFile,
  createTokenGatedFile,
  createPrivateFile,
} from './encryption/utils'
import { FileSource, FileTypeEnum } from '../../types/enum/file.enum'
import { IFileMetaData } from '../../types/interface/file.interface'
import { IServerKey } from '../../types/interface/invoker.interface'
import { instantiateSEA } from '../instantiateGun'
import { MakeFileFromObject } from '../makeFileFromObject'
import getFileExtension from './getFileExtension'
import { getFileTags } from '../getApplicableFileTags'
const SEA = instantiateSEA()
interface CreatedFile {
  contentFile: File
  metadataFile: File
  metadata: IFileMetaData
}
export const createFile = async (
  owner: string,
  fileType: FileTypeEnum,
  files: any,
  serverKeys: IServerKey,
  fileSource: FileSource,
  gateKey?: string,
  coverIPFSHash?: string,
  emoji?: string,
  commentsEnabled?: boolean,
  version = 1,
  arweaveHash?: string
): Promise<CreatedFile> => {
  const fileChatKeyPair = await SEA.pair()
  switch (fileType) {
    case FileTypeEnum.PRIVATE: {
      try {
        const encryptionResult = await createPrivateFile(
          files[0],
          serverKeys,
          fileChatKeyPair,
          fileSource,
          owner,
          coverIPFSHash,
          commentsEnabled,
          emoji,
          version,
          arweaveHash
        )
        return {
          contentFile: encryptionResult.encryptedFile,
          metadataFile: encryptionResult.metadataFile,
          metadata: encryptionResult.metadata,
        }
      } catch (error) {
        console.log(error)
        throw new Error('Unable to create collaborators private file')
      }
    }
    case FileTypeEnum.GATED: {
      try {
        const encryptionResult = await createTokenGatedFile(
          files[0],
          gateKey as string,
          serverKeys,
          fileChatKeyPair,
          fileSource,
          owner,
          coverIPFSHash,
          commentsEnabled,
          emoji,
          version,
          arweaveHash
        )
        return {
          contentFile: encryptionResult.encryptedFile,
          metadataFile: encryptionResult.metadataFile,
          metadata: encryptionResult.metadata,
        }
      } catch (error) {
        console.log(error)
        throw new Error('Unable to create token gated file')
      }
    }
    case FileTypeEnum.MEMBERS_PRIVATE: {
      try {
        const encryptionResult = await createMemberPrivateFile(
          files[0],
          serverKeys,
          fileChatKeyPair,
          fileSource,
          owner,
          coverIPFSHash,
          commentsEnabled,
          emoji,
          version,
          arweaveHash
        )
        return {
          contentFile: encryptionResult.encryptedFile,
          metadataFile: encryptionResult.metadataFile,
          metadata: encryptionResult.metadata,
        }
      } catch (error) {
        console.log(error)
        throw new Error('Unable to create member private file')
      }
    }
    default: {
      const metadataFilename = `${files[0].name}.metadata.json`
      const currentTime = Math.floor(Date.now() / 1000)
      // Check if the FILESOURCE is ARWEAVE
      if (fileSource === FileSource.ARWEAVE && !arweaveHash) {
        throw new Error('Arweave hash not found')
      }
      const metadata = {
        name: files[0].name,
        size: files[0].size,
        mimeType: files[0].type,
        extension: getFileExtension(files[0].name) as string,
        fileType: FileTypeEnum.PUBLIC,
        source: fileSource,
        tokenLock: null,
        ownerLock: null,
        portalLock: null,
        memberLock: null,
        publicLock: {
          fileKey: null,
          chatKey: fileChatKeyPair,
        },
        owner,
        uploadedAt: currentTime,
        coverIPFSHash,
        commentsEnabled,
        emoji,
        version,
        tag: getFileTags(FileTypeEnum.PUBLIC, fileSource),
        arweaveHash,
      }
      return {
        contentFile: files[0],
        metadata,
        metadataFile: MakeFileFromObject(metadata, metadataFilename),
      }
    }
  }
}
