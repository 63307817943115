import React from 'react'
import { DynamicModal } from '../../pages/PublicPortal/components/DynamicModal'

interface IModalProps {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
}

export const WarningModal = (props: IModalProps) => {
  const { isOpen, onClose, onConfirm } = props
  return (
    <DynamicModal
      className="max-w-[600px]"
      open={isOpen}
      onOpenChange={onClose}
      title="Warning"
      description="Are you sure you want to cancel the remaining files being uploaded?"
      primaryAction={{
        label: 'Yes',
        onClick: onConfirm,
        variant: 'danger',
        className: 'w-full md:w-auto',
      }}
      secondaryAction={{
        label: 'No',
        onClick: onClose,
        className: 'w-full md:w-auto',
      }}
    />
  )
}
