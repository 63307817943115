import { AppState } from '..'
import { useAppSelector } from '../hooks'

export const usePortalLatestBlockNumber = (contractAddress: string) => {
  const latestBlock = useAppSelector((state: AppState) => {
    return state.portalUpdateLogs.updates[contractAddress] || ''
  })
  return latestBlock
}

export const usePortalCreationTimestamp = (contractAddress: string) => {
  const creationBlock = useAppSelector((state: AppState) => {
    if (state.portalUpdateLogs.creation) {
      return state.portalUpdateLogs.creation[contractAddress]
    }
    return ''
  })
  return creationBlock
}

export const useLegacyDocWarning = (contractAddress: string) => {
  const creationBlock = useAppSelector((state: AppState) => {
    if (state.portalUpdateLogs.legacyDocWarning) {
      return state.portalUpdateLogs.legacyDocWarning[contractAddress]
    }
    return ''
  })
  return creationBlock
}

export const useSettingSignal = (walletAddress: string) => {
  const settingSignalState = useAppSelector((state: AppState) => {
    if (state.portalUpdateLogs.settingSignal) {
      return state.portalUpdateLogs.settingSignal[walletAddress]
    }
    return ''
  })
  return settingSignalState
}
