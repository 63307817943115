/* eslint-disable @typescript-eslint/no-explicit-any */
import { RefObject, useEffect } from 'react'

export const useOutsideClick = (
  refs: RefObject<any>[],
  callback: () => void
) => {
  useEffect(() => {
    // Function to be called when clicking outside
    function handleClickOutside(event: MouseEvent) {
      const isOutside = refs.every((ref) => {
        return ref.current && !ref.current.contains(event.target as Node)
      })

      if (isOutside) {
        callback()
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [refs, callback])
}
