import React, { useRef, SyntheticEvent, useState, useEffect } from 'react'
import EditDropZone from './EditDropZone'
import { useDropzone } from 'react-dropzone'
import { FileSource, FileStatus } from '../../types/enum/file.enum'
import { JsonRpcSigner } from 'ethers'
import ExcalidrawViewer, { IPluginFile } from '../FileViewers/ExcalidrawViewer'
import { INewFile } from '../../types/interface/file.interface'
import { FileReplacementViewer } from '../FileUploadSection/UploadedFilePreview'
import FileView from './FileView'

interface FileEditProps {
  setEditFile: React.Dispatch<React.SetStateAction<boolean>>
  fileData: INewFile
  contractSigner: JsonRpcSigner
  setRecallFileData: React.Dispatch<React.SetStateAction<boolean>>
  setFileStatus: React.Dispatch<React.SetStateAction<FileStatus>>
  downloadUrl: string
  files: (File & { previewURL: string })[]
  setFiles: React.Dispatch<
    React.SetStateAction<(File & { previewURL: string; fileName: string })[]>
  >
  setNewFileName?: React.Dispatch<React.SetStateAction<string>>
}
export enum EditStage {
  SELECT_FILE,
  CONFIRM_FILE,
  UPLOADING,
}
export const FileEdit = ({
  setEditFile,
  fileData,
  downloadUrl,
  files,
  setFiles,
  setNewFileName,
}: FileEditProps) => {
  const [editStage, setEditStage] = useState<EditStage>(EditStage.SELECT_FILE)
  const { getInputProps, isDragActive } = useDropzone()
  const inputFilesRef = useRef<HTMLInputElement>(null)
  const [pluginFileContent, setPluginFileContent] = useState<IPluginFile>()

  const fileInputClick = () => {
    if (inputFilesRef.current) {
      inputFilesRef.current.click()
    }
  }
  const onAllFileSelectClick = (e: SyntheticEvent) => {
    e.stopPropagation()
    inputFilesRef.current?.click()
  }

  const selectFiles = async (files: File[]) => {
    setFiles(
      files.map((file: File) => {
        return Object.assign(file, {
          previewURL: URL.createObjectURL(file),
          fileName: file.name,
        })
      })
    )
    window.history.replaceState({}, document.title)
  }

  const getEditScreen = () => {
    if (pluginFileContent && pluginFileContent.source) {
      if (pluginFileContent.source === 'fileverse-excalidraw') {
        return (
          <ExcalidrawViewer
            isEditing={true}
            file={pluginFileContent.file}
            setEditFile={setEditFile}
            fileData={fileData}
            selectFiles={selectFiles}
            setEditStage={setEditStage}
          />
        )
      }
    } else {
      return (
        <EditDropZone
          getInputProps={getInputProps}
          isDragActive={isDragActive}
          fileInputClick={fileInputClick}
          onAllFileSelectClick={onAllFileSelectClick}
          inputFilesRef={inputFilesRef}
          selectFiles={selectFiles}
          setNewFileName={setNewFileName}
          setEditFile={setEditFile}
          setEditStage={setEditStage}
          downloadUrl={downloadUrl}
          metadata={fileData.metadata}
        />
      )
    }
  }

  const _setPluginFileContent = async () => {
    const fileData = await (await fetch(downloadUrl as string)).text()
    setPluginFileContent(JSON.parse(fileData) as IPluginFile)
  }

  useEffect(() => {
    if (
      fileData?.metadata?.source === FileSource.COLLAB_DOCUMENT ||
      fileData?.metadata?.source === FileSource.EXCALIDRAW
    ) {
      _setPluginFileContent()
    }
  }, [downloadUrl])

  return (
    <div className="h-full relative w-full rounded-[8px] box-border">
      {editStage === EditStage.CONFIRM_FILE && (
        <FileEditConFirmationScreen
          files={files}
          fileData={fileData}
          downloadUrl={downloadUrl}
        />
      )}
      {editStage === EditStage.SELECT_FILE && getEditScreen()}
    </div>
  )
}

export const FileEditConFirmationScreen = ({
  files,
  fileData,
  downloadUrl,
}: {
  fileData: INewFile
  downloadUrl?: string
  files: (File & {
    previewURL: string
  })[]
}) => {
  return (
    <div className="w-full h-full flex justify-center items-center">
      <div
        className="h-full w-full flex items-center relative"
        onClick={(e) => e.stopPropagation()}
      >
        <div className={`w-[40%] h-full flex justify-center items-center`}>
          <FileReplacementViewer file={files[0]} />
        </div>
        <div className="w-[60%] h-full self-start flex flex-col shadow-xl">
          <div className="h-full w-full object-scale-down">
            <FileView
              downloadUrl={downloadUrl as string}
              metadata={fileData.metadata}
              isEditing={true}
              viewTokenGatedFile={null}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
