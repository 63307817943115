import React, { BaseSyntheticEvent } from 'react'
import cn from 'classnames'
import { Shield, Users2 } from 'lucide-react'
import styles from './index.module.scss'
import { ButtonType } from '../common'
import { Button } from '../../../../../pages/PublicPortal/components/Button'

export const SelectVisibilityStep = ({
  onButtonClick,
  isLoading,
  isDisabled,
}: {
  onButtonClick: (e: BaseSyntheticEvent) => void
  isLoading: boolean
  isDisabled: boolean
}) => {
  return (
    <div className={cn(styles.visiblity_step_container, 'justify-center')}>
      <div className={cn(styles.visiblity_step_desc_container)}>
        <div className="text-heading-lg-bold color-text-default">
          Visibility
        </div>
        <div className="text-body-sm color-text-default text-center">
          Select visibility of your file, your files are end to end encrypted
          and safely stored in IPFS system
        </div>
      </div>
      <div className={cn(styles.visiblity_cta, 'mt-4')}>
        <Button
          disabled={isLoading || isDisabled}
          id={ButtonType.Private}
          onClick={onButtonClick}
          className="w-[145px] h-[45px] gap-2"
        >
          <Shield size={20} />
          <span>Private</span>
        </Button>
        <Button
          disabled={isLoading || isDisabled}
          id={ButtonType.Public}
          onClick={onButtonClick}
          className="w-[145px] h-[45px] gap-2"
        >
          <Users2 size={20} />
          <span>Public</span>
        </Button>
      </div>
    </div>
  )
}
