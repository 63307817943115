import axios from 'axios'
import { SUBGRAPH_URL } from '../../config'

export const getCollaboratorsInfo = async (
  portalAddress: string,
  collaboratorsList: string[]
) => {
  const formattedArrayString = JSON.stringify(collaboratorsList)

  const query = `{
  addedCollaborators(
    where: {portalAddress: "${portalAddress}", account_in: ${formattedArrayString}}
  ) {
    account
    blockTimestamp
  }
}`

  const response = await axios.post(
    SUBGRAPH_URL,
    {
      query,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )

  return response.data
}
