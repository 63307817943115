import isEmpty from 'lodash/isEmpty'
import { addContractIndexDbState, modifyContractIndexedDbState } from '../utils'
import {
  ISetCollaboratorsPayload,
  ISetContractStoragePayload,
  IUpdateAgentPayload,
  IUpdateCollaboratorListPayload,
  IUpdateContractChainIdPayload,
  IUpdateMemberPayload,
  IUpdateMetadataIPFSUrlPayload,
  IUpdateMetadataPayload,
} from '../../contract/reducer'
import {
  IAddFilePayload,
  ICacheFileIPFSURL,
  IDeleteFilePayload,
} from '../../files/reducer'
import { IContractDbRecord } from '../database'
import { IContract } from '../../../types/interface/contract.interface'
import { INewFile } from '../../../types/interface/file.interface'

export const addContractToIndexDbRecord = async (
  contractState: IContractDbRecord | undefined,
  payload: IContract
) => {
  if (!isEmpty(contractState)) return
  await addContractIndexDbState(payload)
}

export const updateContractLayoutFileIdStateOnIndexedDb = async (
  state: IContractDbRecord,
  payload: { contractAddress: string; layoutFileId: string }
) => {
  if (!state || isEmpty(state)) return

  const { contractAddress, layoutFileId } = payload

  await modifyContractIndexedDbState(contractAddress, {
    publicLayoutFileId: layoutFileId,
  })
}

export const updateContractSignlessStateOnIndexedDb = (
  state: IContractDbRecord,
  payload: { contractAddress: string; enabled: boolean }
) => {
  if (!state || isEmpty(state)) return

  const { contractAddress, enabled } = payload

  modifyContractIndexedDbState(contractAddress, { enabled_signless: enabled })
}
export const updateContractMetadataStateOnIndexDb = (
  state: IContractDbRecord,
  payload: IUpdateMetadataPayload
) => {
  if (!state || isEmpty(state)) return

  const { contractAddress, metadata } = payload
  modifyContractIndexedDbState(contractAddress, { metadata })
}
export const updateContractMetadataIPFSUrlOnIndexDb = (
  state: IContractDbRecord,
  payload: IUpdateMetadataIPFSUrlPayload
) => {
  if (!state || isEmpty(state)) return
  const { contractAddress, metadataLastWorkingIPFSUrl } = payload
  modifyContractIndexedDbState(contractAddress, { metadataLastWorkingIPFSUrl })
}

export const updatePortalAgentOnIndexDb = (
  state: IContractDbRecord,
  payload: IUpdateAgentPayload
) => {
  if (!state || isEmpty(state)) return
  const { contractAddress, agentAddress } = payload
  modifyContractIndexedDbState(contractAddress, { agentAddress })
}

export const removePortalAgentOnIndexedDb = (
  state: IContract,
  payload: { contractAddress: string }
) => {
  if (!state || isEmpty(state)) return
  const { contractAddress } = payload
  modifyContractIndexedDbState(contractAddress, { agentAddress: '' })
}

export const addMemberToContractStateOnIndexDb = (
  state: IContract,
  payload: { contractAddress: string; invokerAddress: string }
) => {
  if (!state || isEmpty(state)) return
  const { contractAddress, invokerAddress } = payload
  const previousMembersList = state.members
  const isInvokerPresentInList = previousMembersList.some(
    (data) => data.invokerAddress === invokerAddress
  )
  if (isInvokerPresentInList) return
  const updatedMemberList = [...previousMembersList, { invokerAddress }]
  modifyContractIndexedDbState(contractAddress, { members: updatedMemberList })
}
export const updateContractMemberStateOnIndexDb = (
  state: IContract,
  payload: IUpdateMemberPayload
) => {
  if (!state || isEmpty(state)) return

  const { contractAddress, members } = payload
  modifyContractIndexedDbState(contractAddress, { members })
}

export const addCollaboratorToContractStateOnIndexDb = (
  state: IContract,
  payload: IUpdateCollaboratorListPayload
) => {
  if (!state || isEmpty(state)) return
  const { contractAddress, collaboratorAddress } = payload
  const previousCollaboratorList = state.collaborators
  const isInvokerPresentInList = previousCollaboratorList.some(
    (address) => address === collaboratorAddress
  )
  if (isInvokerPresentInList) return
  const updatedCollaboratorList = [
    ...previousCollaboratorList,
    collaboratorAddress,
  ]
  modifyContractIndexedDbState(contractAddress, {
    collaborators: updatedCollaboratorList,
  })
}
export const removeContractCollaboratorOnIndexDb = (
  state: IContractDbRecord,
  payload: IUpdateCollaboratorListPayload
) => {
  if (!state || isEmpty(state)) return
  const { contractAddress, collaboratorAddress } = payload
  const previousCollaboratorList = state.collaborators
  const updatedCollaboratorList = previousCollaboratorList.filter(
    (c) => c !== collaboratorAddress
  )
  modifyContractIndexedDbState(contractAddress, {
    collaborators: updatedCollaboratorList,
  })
}
export const updateContractChainIdOnIndexDb = (
  state: IContract,
  payload: IUpdateContractChainIdPayload
) => {
  if (!state || isEmpty(state)) return
  const { contractAddress, chainId } = payload
  modifyContractIndexedDbState(contractAddress, {
    chainId,
  })
}
export const updateContractCollaboratorStateOnIndexDb = (
  state: IContractDbRecord,
  payload: ISetCollaboratorsPayload
) => {
  if (!state || isEmpty(state)) return

  const { contractAddress, collaborators } = payload
  modifyContractIndexedDbState(contractAddress, { collaborators })
}

export const updatePortalStorageOnIndexDb = (
  state: IContractDbRecord,
  payload: ISetContractStoragePayload
) => {
  if (!state || isEmpty(state)) return

  const { storage } = payload
  const { contractAddress, ...otherInfo } = storage
  modifyContractIndexedDbState(contractAddress, { storage: otherInfo })
}

export const addFileToContractStateOnIndexedDb = (
  state: IContractDbRecord,
  payload: IAddFilePayload
) => {
  if (!state || isEmpty(state)) return

  const { contractAddress, fileData } = payload
  if (fileData?.metadata?.isDeleted) return
  const { fileId } = fileData
  const previousFileState = state.files
  modifyContractIndexedDbState(contractAddress, {
    files: { ...previousFileState, [fileId]: fileData },
  })
}
export const setContractFilesOnIOndexDb = async (
  state: IContractDbRecord,
  payload: { contractAddress: string; record: Record<number, INewFile> }
) => {
  if (!state || isEmpty(state)) return

  const { contractAddress, record } = payload

  await modifyContractIndexedDbState(contractAddress, { files: record })
}
export const deleteFileFromContractStateOnIndexedDb = (
  state: IContractDbRecord,
  payload: IDeleteFilePayload
) => {
  if (!state || isEmpty(state)) return

  const { contractAddress, fileId } = payload
  const previousFileState = state.files
  if (!isEmpty(previousFileState[fileId])) {
    delete previousFileState[fileId]
    modifyContractIndexedDbState(contractAddress, {
      files: { ...previousFileState },
    })
  }
}
export const cacheFileIPFSUrlOnIndexedDb = async (
  state: IContractDbRecord,
  payload: ICacheFileIPFSURL
) => {
  if (!state || isEmpty(state)) return
  const { contractAddress, fileId, cache } = payload
  const fileRecord = { ...state.files }
  const file = fileRecord[fileId]
  if (!file) return
  file.cache = file.cache || { metadataIPFSUrl: '', contentIPFSUrl: '' }
  if (cache.metadataIPFSUrl) {
    file.cache.metadataIPFSUrl = cache.metadataIPFSUrl
  }
  if (cache.contentIPFSUrl) {
    file.cache.contentIPFSUrl = cache.contentIPFSUrl
  }
  await modifyContractIndexedDbState(contractAddress, {
    files: { ...fileRecord },
  })
}
