import axios from 'axios'
import { generateToken } from '../utils'
import { TaskID } from '../../components/Tasks/TasksProvider'

export const postCompletedTask = async ({
  contractAddress,
  editSecret,
  invoker,
  chainId,
  taskId,
  name,
}: {
  contractAddress: string
  editSecret: string
  invoker: string
  chainId: number
  taskId: TaskID
  name: string
}) => {
  const token = await generateToken(contractAddress, editSecret || '')
  const t = await axios.post(
    `${process.env.REACT_APP_STORAGE_URL}/task/complete`,
    {
      taskId,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        contract: contractAddress,
        invoker,
        chain: chainId,
      },
    }
  )
  return { ...t.data, taskId, name }
}
