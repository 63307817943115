/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useCallback, useEffect, useLayoutEffect, useRef } from 'react'
import { Editor as CoreEditor } from '@tiptap/core'
import { useState } from 'react'
import {
  TableOfContentData,
  TableOfContentDataItem,
  TableOfContentStorage,
} from '@tiptap-pro/extension-table-of-content'
import cn from 'classnames'
import { TextSelection } from 'prosemirror-state'

export type TableOfContentsProps = {
  editor: CoreEditor
  activeIndex?: number | null
  setActiveIndex?: React.Dispatch<React.SetStateAction<number | null>>
  activeHeadingId?: string | null
  setActiveHeadingId?: React.Dispatch<React.SetStateAction<string | null>>
  setLastActiveIndex?: React.Dispatch<React.SetStateAction<number | null>>
  setShowMobileIndexing?: React.Dispatch<React.SetStateAction<boolean>>
  isManualScroll?: boolean
  isExpanded?: boolean
}

type OrganizedHeading = {
  heading: TableOfContentDataItem
  subheadings: TableOfContentDataItem[]
}

const organizeHeadings = (content: TableOfContentData): OrganizedHeading[] => {
  const organizedHeadings: OrganizedHeading[] = []
  let currentHeading: OrganizedHeading | null = null

  content?.forEach((item) => {
    if (item.level === 1) {
      if (currentHeading) {
        organizedHeadings.push(currentHeading)
      }
      currentHeading = { heading: item, subheadings: [] }
    } else if (currentHeading) {
      currentHeading.subheadings.push(item)
    }
  })

  if (currentHeading) {
    organizedHeadings.push(currentHeading)
  }

  return organizedHeadings
}

export const TableOfContents = ({
  editor,
  activeIndex,
  setActiveIndex,
  setLastActiveIndex,
  activeHeadingId,
  setActiveHeadingId,
  // setShowMobileIndexing,
  isManualScroll,
  isExpanded,
}: TableOfContentsProps) => {
  const [data, setData] = useState<TableOfContentStorage | null>(null)
  const headingRefs = useRef<HTMLElement[]>([])
  const [activeSubIndex, setActiveSubIndex] = useState<string | null>(null)

  const organizedHeadings = organizeHeadings(data?.content ?? [])

  const filteredHeadings = organizedHeadings
    .filter((item) => item.heading.textContent !== '')
    .map((item) => ({
      ...item,
      subheadings: item.subheadings.filter(
        (subItem) => subItem.textContent !== ''
      ),
    }))

  const addToRefs = (el: HTMLElement | null) => {
    if (el && !headingRefs.current.includes(el)) {
      headingRefs.current.push(el)
    }
  }

  const handleScroll = useCallback(() => {
    const scrollPos = window.scrollY

    // Scroll at the end of the page, set the last item as active
    if (
      window.innerHeight + window.scrollY >=
      document.body.offsetHeight - 100
    ) {
      setActiveIndex && setActiveIndex(filteredHeadings.length - 1)
      return
    } else if (scrollPos === 0) {
      // Scroll at the top of the page, set the first item as active
      setActiveIndex && setActiveIndex(0)
      // setActiveHeadingId(filteredHeadings[0].heading.id)
      return
    }

    headingRefs.current.forEach((ref, index) => {
      // Get the id of the heading from the href of the Table of Contents item
      const id = ref.getAttribute('href')?.slice(1)
      // Get the corresponding heading in the editor
      const heading = document.querySelector(
        `[data-toc-id="${id}"]`
      ) as HTMLElement

      if (heading) {
        const rect = heading.getBoundingClientRect()
        const top = rect.top + scrollPos
        const bottom = top + rect.height

        if (
          top <= scrollPos + 100 &&
          bottom >= scrollPos - -50 &&
          !isManualScroll
        ) {
          const headings = filteredHeadings.find(
            (item) => item.heading.id === id
          )

          if (headings) {
            setActiveIndex && setActiveIndex(index)
            setActiveHeadingId && setActiveHeadingId(id as string)
          }
          setActiveSubIndex(id as string)
        }
      }
    })
  }, [setActiveIndex, isManualScroll, filteredHeadings, setActiveSubIndex])

  const onItemClick = (
    e: React.MouseEvent<HTMLAnchorElement>,
    id: string,
    subIndex?: string
  ) => {
    try {
      e.preventDefault()
      e.stopPropagation()

      if (editor) {
        const element = editor.view.dom.querySelector(`[data-toc-id="${id}"`)

        // scroll the element into the visible area of the browser window
        element &&
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          })

        const pos = editor.view.posAtDOM(element!, 0)

        // set focus
        const tr = editor.view.state.tr

        tr.setSelection(new TextSelection(tr.doc.resolve(pos)))

        editor.view.dispatch(tr)

        editor.view.focus()
      }
    } catch (error) {
      console.log(error)
    } finally {
      setActiveIndex?.(
        filteredHeadings.findIndex((item) => item.heading.id === id)
      )
      setActiveSubIndex(
        subIndex
          ? subIndex
          : filteredHeadings.find((item) => item.heading.id === id)
              ?.subheadings[0]?.id ?? null
      )
      // setShowMobileIndexing && setShowMobileIndexing(false)
    }
  }

  const handler = ({ editor: currentEditor }: { editor: CoreEditor }) => {
    setData({ ...currentEditor?.extensionStorage?.tableOfContent })
  }

  useLayoutEffect(() => {
    handler({ editor })

    if (editor) {
      editor.on('update', handler)
      editor.on('selectionUpdate', handler)
    }

    return () => {
      editor && editor.off('update', handler)
      editor && editor.off('selectionUpdate', handler)
    }
  }, [editor])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  useEffect(() => {
    filteredHeadings &&
      setLastActiveIndex &&
      setLastActiveIndex(filteredHeadings.length - 1)
  }, [filteredHeadings, setLastActiveIndex])

  return (
    <div className="flex flex-col gap-1">
      {filteredHeadings.map((item, index) => (
        <div key={item.heading.id}>
          <a
            ref={addToRefs}
            href={`#${item.heading.id}`}
            style={{ marginLeft: `${1 * item.heading.level - 1}rem` }}
            onClick={(e) => onItemClick(e, item.heading.id)}
            className={cn(
              'block font-medium py-1 pl-[10px] pr-1 text-xs hover:text-neutral-800 transition-all truncate w-full rounded-none cursor-pointer',
              index === activeIndex &&
                'text-neutral-800 border-l-[3px] border-neutral-800',
              index !== activeIndex && 'text-neutral-400'
            )}
            title={item.heading.textContent}
          >
            {item.heading.textContent}
          </a>
          {(item.heading.id === activeHeadingId || isExpanded) &&
            item.subheadings.map((subItem) => (
              <a
                ref={addToRefs}
                key={subItem.id}
                href={`#${subItem.id}`}
                style={{ marginLeft: `${1 * subItem.level - 1}rem` }}
                onClick={(e) => onItemClick(e, subItem.id, subItem.id)}
                className={cn(
                  'block font-medium py-1 pl-[10px] pr-1 text-[11px] hover:text-neutral-800 transition-all truncate w-full rounded-none cursor-pointer',
                  subItem.id === activeSubIndex &&
                    'text-neutral-800 border-l-[3px] border-neutral-800',
                  subItem.id !== activeSubIndex && 'text-neutral-400'
                )}
                title={subItem.textContent}
              >
                {subItem.textContent}
              </a>
            ))}
        </div>
      ))}
    </div>
  )
}

TableOfContents.displayName = 'TableOfContents'
