/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  BaseSyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import styles from './index.module.scss'
import cn from 'classnames'
import { ChevronDown } from 'lucide-react'

interface ITextInputProps {
  placeholder?: string
  customCss?: string
  onChange?: (e: BaseSyntheticEvent) => void
  type?: string
  value?: string | number
}

export type TOptions = {
  value: string
  label: string
}
interface ISelectInputProps {
  options: TOptions[]
  onChange: (value: string) => void
  placeholder?: string
  defaultValue?: string
}

export const TextInput = (props: ITextInputProps) => {
  const { customCss, ...restProps } = props
  return (
    <input
      className={cn(styles.text_input, customCss)}
      type="text"
      {...restProps}
    />
  )
}

export const SelectInput = (props: ISelectInputProps) => {
  const { options, onChange, placeholder = 'Select', defaultValue } = props
  const [showMenu, setShowMenu] = useState(false)
  const [selectedValue, setSelectedValue] = useState<null | string>(null)

  const inputRef = useRef(null)

  const handler = useCallback((e: any) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    if (inputRef.current && !inputRef.current.contains(e.target)) {
      setShowMenu(false)
    }
  }, [])

  useEffect(() => {
    if (!defaultValue) return
    setSelectedValue(defaultValue)
  }, [defaultValue])

  useEffect(() => {
    window.addEventListener('click', handler)
    return () => {
      window.removeEventListener('click', handler)
    }
  }, [handler])

  const toggleSelect = () => {
    setShowMenu(!showMenu)
  }

  const onOptionClick = (value: string) => {
    setSelectedValue(value)
    onChange(value)
  }

  const optionItems = options.map((option) => {
    return (
      <div
        onClick={() => onOptionClick(option.value)}
        key={option.value}
        className={styles.select_option_item}
      >
        {option.label}
      </div>
    )
  })
  return (
    <div className={styles.select_input_wrapper}>
      <div
        ref={inputRef}
        onClick={toggleSelect}
        className={styles.select_input}
      >
        <div className={styles.select_input_value}>
          {selectedValue || placeholder}
        </div>
        <div
          className={cn(styles.select_toggler, { [styles.is_open]: showMenu })}
        >
          <ChevronDown />
        </div>
      </div>

      <div
        className={cn(styles.select_options_wrapper, {
          [styles.is_open]: showMenu,
        })}
      >
        {optionItems}
      </div>
    </div>
  )
}
