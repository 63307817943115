import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { defaultNetwork } from '../../../../../config/network-config'

import styles from './index.module.scss'
import cn from 'classnames'
import { Button } from '@fileverse/ui'
import { ActivateSignlessModal } from '../../../../../components/UploadComponents/ActivateSignlessModal'
import { isSignlessEnabled } from '../../../../../utils/agentHandler'
import { Tooltip } from '../../../../PublicPortal/components/Tooltip'
import { usePrivyHelper } from '../../../../../hooks/usePrivyHelper'
import { useContract } from '../../../../../store/contract/hooks'

export const PublicPortalActions = () => {
  const { address: contractAddress } = useParams()
  const { address: portalAddress = '' } = useParams()
  const walletAddress = usePrivyHelper().walletAddress as string
  const contract = useContract(contractAddress as string)

  const navigate = useNavigate()
  const [showSignlessModal, setShowSignlessModal] = useState(false)
  const onEditPublicPortal = async () => {
    if (
      !(await isSignlessEnabled(portalAddress, walletAddress)) &&
      walletAddress === contract.owner
    ) {
      setShowSignlessModal(true)
      return
    }
    navigate(`/${contractAddress}/member?chainId=${defaultNetwork.chainId}`)
  }

  const onCompleteSignless = () => {
    setShowSignlessModal(false)
    navigate(`/${contractAddress}/member?chainId=${defaultNetwork.chainId}`)
  }

  return (
    <>
      <Tooltip
        text="Customise the public view of your Portal, try it out <3"
        position="left"
        className=" rounded-[4px] text-helper-text-sm"
      >
        <Button
          onClick={onEditPublicPortal}
          className={cn(
            'rounded-[4px] edit-public-portal',
            styles.public_portal_cta
          )}
          size="lg"
        >
          Edit public view
        </Button>
      </Tooltip>

      {showSignlessModal && (
        <ActivateSignlessModal
          isOpen={showSignlessModal}
          onCompleteSignless={onCompleteSignless}
        />
      )}
    </>
  )
}
