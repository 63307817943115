/* eslint-disable @typescript-eslint/no-explicit-any */
import isEmpty from 'lodash/isEmpty'
import { searchKeyVersionAndVerifyPortalKeys } from '../messages/keyVerifiers'

export const verifyPortalKeys = async (payload: {
  chainId: number
  contractAddress: string
  invokerAddress: string
  portalEncryptionKey: string
  portalDecryptionKey: string
  memberEncryptionKey: string
  memberDecryptionKey: string
  owner: string
  portal: any
}) => {
  const {
    chainId,
    memberEncryptionKey,
    memberDecryptionKey,
    portalEncryptionKey,
    portalDecryptionKey,
    contractAddress,
    portal,
  } = payload

  if (!isEmpty(portal)) {
    const finalChainId = chainId
    const serverKeys = {
      portalEncryptionKey,
      portalDecryptionKey,
      memberEncryptionKey,
      memberDecryptionKey,
    }
    const isVerified = await searchKeyVersionAndVerifyPortalKeys(
      serverKeys,
      contractAddress as string,
      finalChainId
    )
    if (isVerified) return true
  } else {
    return false
  }
}
