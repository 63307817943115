import React, { useState, useLayoutEffect, useEffect } from 'react'
import { TableOfContents } from './TableOfContents'
import cn from 'classnames'
import { Editor } from '@tiptap/react'
import useComponentVisibilty from '../../hooks/useVisibility'
import { useMediaQuery } from '@mui/material'
import { List } from 'lucide-react'
import { Button } from '../../pages/PublicPortal/components/Button'

type Props = {
  editor: Editor
  hasToC: boolean
}

export const SidebarMenu = ({ editor, hasToC }: Props) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const [isFixed, setIsFixed] = useState<boolean>(false)
  const [activeIndex, setActiveIndex] = useState<number | null>(0)
  const [lastActiveIndex, setLastActiveIndex] = useState<number | null>(null)
  const [isManualScroll, setIsManualScroll] = useState<boolean>(false)
  const [activeHeadingId, setActiveHeadingId] = useState<string | null>(null)

  const isMediaMax1280px = useMediaQuery('(max-width:1280px)')
  const {
    ref: mobileTOCRef,
    isComponentVisible: showMobileIndexing,
    setIsComponentVisible: setShowMobileIndexing,
  } = useComponentVisibilty(false)

  const buttonStyle = cn(
    'text-neutral-400 block font-medium py-1 pl-[10px] pr-1 text-[11px] hover:text-neutral-800 transition-all truncate w-full rounded-none cursor-pointer'
  )

  const shouldHideToC =
    editor?.extensionStorage?.tableOfContent?.content?.length < 2

  const expandHandler = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault()
    e.stopPropagation()
    setIsExpanded(!isExpanded)
    setActiveHeadingId(null)
  }

  const backToTopHandler = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault()
    e.stopPropagation()
    setIsManualScroll(true)
    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
    setActiveIndex(0)
    setIsExpanded(false)
    setActiveHeadingId(null)
    // setShowMobileIndexing(false)
  }

  const goToBottomHandler = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault()
    e.stopPropagation()
    setIsManualScroll(true)
    window.scroll({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    })
    setActiveIndex(lastActiveIndex)
    // setShowMobileIndexing(false)
  }

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight - 600) {
        setIsFixed(true)
      } else {
        setIsFixed(false)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  useEffect(() => {
    if (isManualScroll) {
      const timer = setTimeout(() => {
        setIsManualScroll(false)
      }, 0)

      return () => clearTimeout(timer)
    }
  }, [isManualScroll])

  const DesktopTOC = () => {
    return (
      <div
        className={cn(
          'flex flex-col left-[calc(48vw-640px)] 2xl:left-[calc(42vw-640px)] 3xl:left-[calc(36vw-640px)]',
          (!hasToC || shouldHideToC) && 'hidden',
          !isFixed && 'absolute top-4',
          isFixed && 'fixed top-20 '
        )}
      >
        <div
          className={cn(
            'max-w-[260px] w-[12vw] 2xl:w-[15vw] hidden xl:block px-6 overflow-hidden transition-all hover:overflow-y-auto',
            !isExpanded && 'max-h-fit',
            isExpanded && 'max-h-[calc(100vh-12rem)]'
          )}
        >
          <TableOfContents
            editor={editor}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            setLastActiveIndex={setLastActiveIndex}
            isManualScroll={isManualScroll}
            isExpanded={isExpanded}
            activeHeadingId={activeHeadingId}
            setActiveHeadingId={setActiveHeadingId}
          />
        </div>
        <div className="w-[12vw] 2xl:w-[15vw] hidden xl:block p-6">
          <a onClick={expandHandler} className={buttonStyle}>
            {isExpanded ? 'Collapse all' : 'Expand all'}
          </a>
          <a onClick={backToTopHandler} className={buttonStyle}>
            Back to top
          </a>
          <a onClick={goToBottomHandler} className={buttonStyle}>
            Go to bottom
          </a>
        </div>
      </div>
    )
  }

  const MobileTOC = () => {
    return (
      <div
        ref={mobileTOCRef}
        className={cn(
          'fixed w-screen z-10',
          (!hasToC || shouldHideToC) && 'hidden'
        )}
      >
        <div className="absolute top-[-20rem] -left-1">
          <Button
            variant="ghost"
            className={cn(
              'min-w-fit px-2 ',
              showMobileIndexing ? 'color-bg-secondary-hover' : 'bg-white'
            )}
            elevation={4}
            onClick={() => setShowMobileIndexing(!showMobileIndexing)}
          >
            <List />
          </Button>
        </div>
        {showMobileIndexing && (
          <div
            className={cn(
              'flex flex-col absolute top-[-20rem] left-[2.5rem] rounded bg-white shadow-elevation-4 border-[1px] color-border-secondary p-3'
            )}
          >
            <div
              className={cn(
                'w-[10rem] overflow-x-hidden transition-all overflow-y-auto',
                !isExpanded && 'max-h-[20vh]',
                isExpanded && 'max-h-[50vh]'
              )}
            >
              <TableOfContents
                editor={editor}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
                setLastActiveIndex={setLastActiveIndex}
                isManualScroll={isManualScroll}
                isExpanded={isExpanded}
                setShowMobileIndexing={setShowMobileIndexing}
                activeHeadingId={activeHeadingId}
                setActiveHeadingId={setActiveHeadingId}
              />
            </div>
            <div className="w-[10rem] pt-6">
              <a onClick={expandHandler} className={buttonStyle}>
                {isExpanded ? 'Collapse all' : 'Expand all'}
              </a>
              <a onClick={backToTopHandler} className={buttonStyle}>
                Back to top
              </a>
              <a onClick={goToBottomHandler} className={buttonStyle}>
                Go to bottom
              </a>
            </div>
          </div>
        )}
      </div>
    )
  }

  return !isMediaMax1280px ? DesktopTOC() : MobileTOC()
}
