import React from 'react'
import { useNavigate } from 'react-router-dom'
import { defaultNetwork } from '../../../config/network-config'
import { useCreatePortal } from '../../../providers'
import { Button } from '../../../pages/PublicPortal/components/Button'

export const AccessPortalButton = () => {
  const navigate = useNavigate()
  const { portalAddress: contractAddress } = useCreatePortal()

  return (
    <Button
      onClick={() =>
        navigate(`/${contractAddress}?chainId=${defaultNetwork.chainId}`, {
          state: {
            newPortal: true,
          },
        })
      }
    >
      Open Your Portal
    </Button>
  )
}
