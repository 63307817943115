/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react'
import ExcalidrawViewer from './ExcalidrawViewer'
import JSONViewer from './JSONViewer'

const TextFileViewer = ({
  url,
  isEditing,
}: {
  url: string
  isEditing?: boolean
}) => {
  const [file, setFile] = useState<any>({})
  useEffect(() => {
    ;(async () => {
      const fileData = await (await fetch(url)).text()
      setFile(JSON.parse(fileData))
    })()
  }, [])
  if (file && file?.source && file.source === 'fileverse-excalidraw')
    return <ExcalidrawViewer file={file.file} isEditing={isEditing} />

  return (
    <div data-cy="json-viewer">
      <JSONViewer file={file} />
    </div>
  )
}

export default TextFileViewer
