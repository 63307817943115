import React from 'react'
import SocialButton from './SocialButton'
import { useMediaQuery } from '@mui/material'
import Twitter from '../../assets/TwitterWhite.svg'
import Github from '../../assets/githubWhite.svg'
import Radicle from '../../assets/radicleWhite.svg'
import Gitcoin from '../../assets/gitcoinWhite.svg'
import email from '../../assets/mailWhite.svg'

export const Socials = () => {
  const isMediaMax1025px = useMediaQuery('(max-width: 1025px)')
  return (
    <div>
      <div className="p-4 mt-8 w-full">
        <h2
          className={`font-bold ${
            isMediaMax1025px ? 'text-2xl' : 'text-3xl'
          } text-center`}
        >
          Where to find us?
        </h2>
        <div className="w-full flex justify-center mt-2 mb-8">
          <p className="text-center w-[22rem]">
            Follow the socials below to connect with the community and earn with
            others
          </p>
        </div>

        <div className="w-full flex justify-center  mt-4 items-center">
          <div className={`flex flex-wrap w-full items-center justify-center`}>
            <SocialButton
              icon={Twitter}
              title={'Twitter'}
              iconStyles={'w-5'}
              linkTo={'https://twitter.com/fileverse'}
            />
            <SocialButton
              icon={Github}
              title={'Github'}
              iconStyles={'w-5'}
              linkTo={'https://github.com/fileverse'}
            />
            <SocialButton
              icon={Radicle}
              title={'Radicle'}
              iconStyles={'w-5'}
              linkTo={
                'https://app.radicle.xyz/seeds/willow.radicle.garden/rad:git:hnrkj6egqcozks7f7n5dx3766e7m4z5tag8cy/tree'
              }
            />
            <SocialButton
              icon={Gitcoin}
              title={'Gitcoin'}
              iconStyles={'w-5'}
              linkTo={
                'https://gitcoin.co/grants/4846/fileverseio-file-sharing-between-blockchain-addre'
              }
            />
            <SocialButton
              icon={email}
              title={'Email'}
              iconStyles={'w-5'}
              linkTo={'mailto:hello@fileverse.io'}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
