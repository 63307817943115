/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState } from 'react'
import backArrow from '../assets/backArrow.svg'
import upload from '../assets/newUpload.svg'
import uploadInactive from '../assets/newUploadInactive.svg'
import folderInactive from '../assets/FolderInactive.svg'
import folderActice from '../assets/FolderActive.svg'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import Accordian from '../components/common/Accordian'

import { accordianData } from '../utils/arweaveAccordianData'
import AllFiles from '../components/Files/AllFiles'
import cn from 'classnames'
import Arweave from '../assets/arweave.svg'

import { ArweaveUploadZone } from '../components'

const PermanentStorage = () => {
  const navigate = useNavigate()
  const { address: contractAddress } = useParams()
  const [urlParams] = useSearchParams()
  const chainId = parseInt(urlParams.get('chainId') || '')
  const [isUploadZone, setIsUploadZone] = useState<boolean>(true)

  const [openIndex, setOpenIndex] = useState<number>(0)

  return (
    <div className="h-screen flex flex-col overflow-scroll no-scrollbar">
      <div
        id="navbar"
        className="w-[100%] flex justify-between items-center p-8"
      >
        <div className="flex gap-4 items-center">
          <button
            onClick={() => navigate(`/${contractAddress}?chainId=${chainId}`)}
            className="p-2 mr-2 bg-[#FAFAFA] border border-[#E2E2E2] items-center relative flex justify-center rounded-[8px]"
          >
            <img src={backArrow} className="w-3" />
          </button>
          <p className="font-bold text-lg">Permanent Storage</p>
        </div>
      </div>
      <main className="w-screen h-[80%] flex flex-col lg:flex-row gap-6 justify-center px-4 lg:px-0">
        <section
          id="Accordian"
          className="w-full lg:w-[25%] order-2 lg:order-1 flex flex-col gap-3 h-[full] overflow-scroll no-scrollbar"
        >
          {accordianData.map((item, index) => (
            <Accordian
              openIndex={openIndex}
              index={index}
              setOpenIndex={setOpenIndex}
              customHeaderBg="bg-[#ffffff]"
              header={item.header}
              body={item.body}
              key={Math.random()}
            />
          ))}
        </section>
        <section
          id="upload-zone"
          className={cn(
            'w-full lg:w-[65%] order-1 lg:order-2 min-h-[50vh] lg:max-h-[unset] rounded-xl border-2',
            !isUploadZone && 'max-h-[50vh]'
          )}
        >
          <div className="h-[60px] border-b">
            <div
              className={`border-black text-[#8B959E] flex h-full justify-center cursor-pointer gap-6`}
            >
              <div
                onClick={() => setIsUploadZone(true)}
                className={cn(
                  'cursor-pointer flex flex-row items-end justify-center gap-2 px-2 pb-4',
                  isUploadZone && 'border-b-2 border-black  text-black'
                )}
              >
                <img src={isUploadZone ? upload : uploadInactive} />
                <div>Upload</div>
              </div>
              <div
                onClick={() => setIsUploadZone(false)}
                className={cn(
                  'cursor-pointer flex flex-row items-end justify-center gap-2 px-2 pb-4',
                  !isUploadZone && 'border-b-2 border-black  text-black'
                )}
              >
                <img src={isUploadZone ? folderInactive : folderActice} />
                <div>PermaFiles</div>
              </div>
            </div>
          </div>
          <div
            className={cn('lg:h-[90%] flex justify-center items-center', {
              ['h-[calc(100%-60px)]']: !isUploadZone,
            })}
          >
            {isUploadZone ? (
              <ArweaveUploadZone />
            ) : (
              <div className="w-full h-full p-5 overflow-scroll no-scrollbar">
                <AllFiles isPermaFile={true} />
              </div>
            )}
          </div>
        </section>
      </main>
      <footer className="h-[10%] flex justify-center items-center gap-2">
        <img
          src={Arweave}
          className="w-6"
          style={{
            filter:
              'invert(100%) sepia(16%) saturate(7463%) hue-rotate(222deg) brightness(119%) contrast(115%)',
          }}
        />
        <p>Powered by Arweave ❤️</p>
      </footer>
    </div>
  )
}

export default PermanentStorage
