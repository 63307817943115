import React, { useState } from 'react'
import cn from 'classnames'
import { getAgentSignatureMessage } from '../../../utils/messages'
import { requestSignature } from '../../../utils/messages/requestSignature'
import { useSignMessage } from 'wagmi'
import { useParams } from 'react-router-dom'
import {
  createAgent,
  encryptAndStoreAgentKey,
} from '../../../utils/agentHandler'
import { ISEAPair } from 'gun'
import {
  useInvokerCredentialsAndKeys,
  usePortalAgentAddress,
} from '../../../store/invoker/hooks'
import { getISEAKeyPair } from '../../../utils/libCrypto'
import { useAppDispatch } from '../../../store/hooks'
import {
  addCollaboratorToStore,
  setPortalAgent,
  setPortalSignlessMode,
} from '../../../store/contract/reducer'
import { setInvokerAgentKey } from '../../../store/invoker/reducer'
import sendNotifcation from '../../../utils/notification'
import { addCollaboratorCall } from '../../../utils/transaction'
import { Step } from '../../onboarding/PortalDeploymentSteps'
import { StepContent, StepItem } from '../../onboarding/StepItem'
import { Button } from '../../../pages/PublicPortal/components/Button'

import { usePrivyHelper } from '../../../hooks/usePrivyHelper'
import { DynamicModal } from '../../../pages/PublicPortal/components/DynamicModal'

enum SignlesSteps {
  Activate = 'Activate',
  Confirm = 'Confirm',
}

const MODAL_CONFIG = {
  [SignlesSteps.Activate]: {
    title: 'Activate Signless',
    desc: 'By activating signless you are creating an onchain agent that will be doing the signing for Portal-related actions/transactions on your behalf.',
    ctaText: 'Activate',
  },
  [SignlesSteps.Confirm]: {
    title: 'Confirm Signless',
    desc: 'By confirming this transaction, you are adding a signing agent as a collaborator to your Portal.',
    ctaText: 'Confirm',
  },
}

interface IActivateSignlessModalProps {
  isOpen: boolean
  onCompleteSignless: (open: boolean) => void
}

export const ActivateSignlessModal = (props: IActivateSignlessModalProps) => {
  const { isOpen, onCompleteSignless } = props
  const [activeStep, setActiveStep] = useState(SignlesSteps.Activate)
  const [isLoading, setLoading] = useState(false)
  const dispatch = useAppDispatch()
  const walletAddress = usePrivyHelper().walletAddress
  const { signMessageAsync } = useSignMessage()
  const { address: portalAddress } = useParams()
  const agentAddress = usePortalAgentAddress(portalAddress as string)
  const [steps, setSteps] = useState<Step[]>([
    {
      name: MODAL_CONFIG[SignlesSteps.Activate].title,
      isComplete: false,
    },
    {
      name: MODAL_CONFIG[SignlesSteps.Confirm].title,
      isComplete: false,
    },
  ])

  const { portalGunKey, portalEncryptionKey } = useInvokerCredentialsAndKeys(
    walletAddress as string,
    portalAddress as string
  )

  const handleActivation = async () => {
    try {
      setLoading(true)
      const messageToSign = getAgentSignatureMessage()
      const { signature } = await requestSignature(
        walletAddress as string,
        messageToSign,
        signMessageAsync
      )
      const { status, agentAddress } = await createAgent(
        portalAddress as string,
        signature
      )
      if (status !== 'success' || !agentAddress)
        throw new Error('Failed to create agent')
      const agentKey = await encryptAndStoreAgentKey(
        signature,
        portalEncryptionKey,
        portalAddress as string,
        agentAddress,
        getISEAKeyPair(portalGunKey) as ISEAPair
      )
      dispatch(
        setPortalAgent({
          contractAddress: portalAddress as string,
          agentAddress: agentAddress,
        })
      )
      dispatch(
        setInvokerAgentKey({
          invokerAddress: walletAddress as string,
          agentKey: agentKey,
          contractAddress: portalAddress as string,
        })
      )
      setActiveStep(SignlesSteps.Confirm)
      setSteps((prevSteps) =>
        prevSteps.map((step, index) =>
          index === 0 ? { ...step, isComplete: true } : step
        )
      )
    } catch (err) {
      console.log(err)
      sendNotifcation('Failed to create agent', '', 'danger')
    } finally {
      setLoading(false)
    }
  }

  const handleConfimation = async () => {
    try {
      setLoading(true)

      await addCollaboratorCall({
        walletAddress: walletAddress as string,
        contractAddress: portalAddress as string,
        collaboratorAddress: agentAddress,
      })

      dispatch(
        addCollaboratorToStore({
          contractAddress: portalAddress as string,
          collaboratorAddress: agentAddress,
        })
      )
      dispatch(
        setPortalSignlessMode({
          contractAddress: portalAddress as string,
          enabled: true,
        })
      )
      setSteps((prevSteps) =>
        prevSteps.map((step, index) =>
          index === 1 ? { ...step, isComplete: true } : step
        )
      )
      onCompleteSignless(false)
      sendNotifcation(
        'Signless mode successfully activated',
        `You have successfully added ${agentAddress} as a signing agent of this Portal.`,
        'success'
      )
    } catch (err) {
      console.error(err)
      sendNotifcation(
        'Failed to add collaborator',
        'Please try again',
        'danger'
      )
    } finally {
      setLoading(false)
    }
  }

  const onButtonClick = () => {
    if (activeStep === SignlesSteps.Activate) handleActivation()
    else handleConfimation()
  }

  return (
    <DynamicModal
      className="max-w-[440px]"
      open={isOpen}
      onOpenChange={onCompleteSignless}
      disableOutsideClick={true}
      title="Go Signless"
      description="Activate signless in just two steps to never sign transactions on Fileverse again!"
      content={
        <div className="flex flex-col w-full h-full">
          <ol
            role="list"
            className={cn(
              'overflow-hidden mx-auto max-w-[450px] space-y-6 px-4'
            )}
          >
            {steps.map((step, stepIndex) => (
              <SignlessSteps
                key={stepIndex}
                step={step}
                stepIndex={stepIndex}
                stepsLength={steps.length}
                deployStatus={activeStep}
                onButtonClick={onButtonClick}
                isLoading={isLoading}
              />
            ))}
          </ol>
        </div>
      }
    />
  )
}

const SignlessSteps = ({
  step,
  stepIndex,
  stepsLength,
  deployStatus,
  isLoading,
  onButtonClick,
}: {
  step: Step
  stepIndex: number
  stepsLength: number
  deployStatus: SignlesSteps
  isLoading: boolean
  onButtonClick: () => void
}) => {
  const { name, isComplete, nextOptional } = step
  const position = stepIndex + 1
  const displayStepComponent = (index: number) => {
    const stepKey = index === 0 ? SignlesSteps.Activate : SignlesSteps.Confirm
    return (
      <SignlessStep
        isComplete={isComplete}
        deployStatus={deployStatus}
        onButtonClick={onButtonClick}
        stepKey={stepKey}
        isLoading={isLoading}
      />
    )
  }

  return (
    <li key={name} className="relative mr-0">
      <StepItem
        isComplete={isComplete}
        isLastItem={position === stepsLength}
        position={position}
        isCurrent={
          deployStatus === SignlesSteps.Activate
            ? stepIndex === 0
            : stepIndex === 1
        }
        title={name}
        nextOptional={nextOptional}
      >
        {displayStepComponent(stepIndex)}
      </StepItem>
    </li>
  )
}

const SignlessStep = ({
  isComplete,
  deployStatus,
  onButtonClick,
  stepKey,
  isLoading,
}: {
  isComplete: boolean
  deployStatus: SignlesSteps
  onButtonClick: () => void
  stepKey: SignlesSteps
  isLoading: boolean
}) => {
  const { title, desc, ctaText } = MODAL_CONFIG[stepKey]
  const isCurrent = deployStatus === stepKey
  return (
    <StepContent title={title} isComplete={isComplete} isCurrent={isCurrent}>
      <div className="flex flex-col gap-3 pt-2 pb-4">
        <p className="text-sm-body color-text-secondary">{desc}</p>
        <Button
          className="min-w-[85px] w-[85px]"
          onClick={onButtonClick}
          isLoading={isLoading}
          disabled={isLoading}
        >
          {ctaText}
        </Button>
      </div>
    </StepContent>
  )
}
