import { SHAPES } from '@fileverse/whiteboard'
import isEmpty from 'lodash/isEmpty'
import { Dispatch, SetStateAction, useEffect } from 'react'

const useWhiteboardKeyCodeHandler = ({
  setActiveTool,
}: {
  setActiveTool: Dispatch<SetStateAction<string>>
}) => {
  const handleKeyDown = (event: KeyboardEvent) => {
    if (Number(event.key) >= 0 && Number(event.key) < 9) {
      const tool = SHAPES.find(
        (v) => Number(v.numericKey) === Number(event.key)
      )
      if (isEmpty(tool)) return
      setActiveTool((prev) => {
        if (prev === 'text') return prev
        return tool?.value
      })
    }
  }
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])
  return null
}

export default useWhiteboardKeyCodeHandler
