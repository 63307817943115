import { getDefaultProvider } from 'ethers'
import { networksConfig } from '../../config/network-config'
import { INetwork } from '../../types/interface/config'
export const isValidSmartContract = async (
  contractAddress: string,
  rpcUrl: string
) => {
  if (!rpcUrl) return false
  try {
    const provider = getDefaultProvider(rpcUrl)
    const code = await provider.getCode(contractAddress)
    return code !== '0x'
  } catch (error) {
    return false
  }
}

export const isValidContractNetwork = async (
  chainId: number,
  contractAddress: string
) => {
  // check if chainid matches any of the supported networks
  const chainIdMap = networksConfig[chainId] as INetwork | undefined
  if (!chainIdMap) return false

  // if it matches verify if contract was deployed on the network

  return await isValidSmartContract(contractAddress, chainIdMap.rpcUrl)
}

export const getContractNetwork = async (
  contractAddress: string
): Promise<INetwork | null> => {
  const arrayOfSupportedNetworks: Array<INetwork> =
    Object.values(networksConfig)
  for (let i = 0; i < arrayOfSupportedNetworks.length; i++) {
    const supportedNetwork = arrayOfSupportedNetworks[i]
    const contractNetwork = await isValidSmartContract(
      contractAddress as string,
      supportedNetwork.rpcUrl
    )
    if (contractNetwork) {
      return supportedNetwork
    }
  }
  return null
}
