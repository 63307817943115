/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { forwardRef, useEffect } from 'react'
import {
  NavLink,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'

import { YellowLinearProgress } from './ClaimStorage'
import { useTasksContext } from '../../hooks/useTasksContext'
import TasksModal from '../Tasks/TasksModal'
import { Slide, useMediaQuery } from '@mui/material'

import { rankImage } from '../../constants'
import { PointRank } from '../../types/enum/pointRank.enum'
import sendNotifcation from '../../utils/notification'
import {
  Home,
  File,
  UsersRound,
  BarChart2,
  Settings2,
  Plus,
  ChevronDown,
} from 'lucide-react'

import isEmpty from 'lodash/isEmpty'
import { useContract } from '../../store/contract/hooks'
import cn from 'classnames'
import { useInvokerContracts } from '../../store/invoker/hooks'
import styles from './index.module.scss'
import { DropdownProvider, useDropdown } from '../../providers/DropdownContext'
import { useAppDispatch } from '../../store/hooks'
import { setActiveContractAddress } from '../../store/contract/reducer'
import { IContract } from '../../types/interface/contract.interface'
import { defaultNetwork } from '../../config/network-config'
import { Button } from '@fileverse/ui'
import { getLogoUrl } from '../../utils/getLogoUrl'
import { Tooltip } from '../../pages/PublicPortal/components/Tooltip'
import { usePrivyHelper } from '../../hooks/usePrivyHelper'

type NavItem = {
  title: string
  link: string
  icon: React.ReactNode
  locked: boolean
  customClass?: string
}

const navList: NavItem[] = [
  {
    title: 'Home',
    link: '',
    icon: <Home size="18px" />,
    locked: false,
  },
  {
    title: 'Files',
    link: `/files`,
    icon: <File size="18px" />,
    locked: false,
  },
  {
    title: 'Collaborators',
    link: `/people`,
    icon: <UsersRound size="18px" />,
    locked: false,
  },
  {
    title: 'Analytics',
    link: `/analytics`,
    icon: <BarChart2 size="18px" />,
    locked: false,
  },
  {
    title: 'Settings',
    link: `/settings`,
    icon: <Settings2 size="18px" />,
    locked: false,
    customClass: 'settings-tour',
  },
]

const DashboardSideBar = ({
  keysBannerVisible,
}: {
  keysBannerVisible: boolean
}) => {
  const { address } = useParams()
  const [searchParams] = useSearchParams()
  const chainId = parseInt(searchParams.get('chainId') || '')

  return (
    <div
      className={cn(
        'min-w-[240px] h-[100vh] p-3 border-r-[1px] border-[#E8EBEC] ease-in-out duration-1000 flex flex-col',
        keysBannerVisible && 'h-[calc(100vh-64px)]'
      )}
    >
      <DropdownProvider>
        <SidebarHead />
      </DropdownProvider>
      <div className="flex flex-col mt-[16px]">
        {navList.map((item) => {
          const link = `/${address}` + item.link + `?chainId=${chainId}`
          return (
            <NavLink key={item.title} to={link} data-cy={item.title} end>
              {({ isActive }) => (
                <div
                  className={cn(
                    'w-full flex px-[12px] py-[11px] items-center gap-[12px] rounded-[4px]',
                    {
                      ['bg-yellow']: isActive,
                      ['hover:bg-[#F2F4F5]']: !isActive,
                    }
                  )}
                >
                  {item.icon}
                  <span className="text-[14px] font-medium">{item.title}</span>
                </div>
              )}
            </NavLink>
          )
        })}
      </div>
      <div className="mt-auto">
        <ClaimPerksSection />
      </div>
    </div>
  )
}

const SidebarHead = forwardRef(function SidebarHead(props, ref) {
  const { toggle } = useDropdown()
  const onClick = () => {
    toggle()
  }
  return (
    <div
      ref={ref as React.Ref<HTMLDivElement> | undefined}
      {...props}
      onClick={onClick}
      className="flex gap-2 items-center justify-between px-[12px] py-[8px] relative cursor-pointer hover:bg-[#F2F4F5] rounded-[4px]"
    >
      <NameAndLogo />
      <SidebarHeadActionButton />
      <PortalDropdown />
    </div>
  )
})

const PortalDropdown = () => {
  const address = usePrivyHelper().walletAddress
  const contracts = useInvokerContracts(address as string)
  const { isOpen } = useDropdown()
  const contractDropdown = contracts.map((contract) => {
    return <PortalSelectItem key={contract} contractAddress={contract} />
  })
  return (
    <div className={cn(styles.dropdown_items_wrapper, isOpen && styles.show)}>
      {contractDropdown}
    </div>
  )
}

const isValid = (contract: IContract, userAddress: string) =>
  contract?.chainId === defaultNetwork.chainId &&
  (userAddress === contract.owner ||
    contract.collaborators.includes(userAddress))

const PortalSelectItem = (props: { contractAddress: string }) => {
  const { contractAddress } = props
  const address = usePrivyHelper().walletAddress as string
  const { address: currentAddress } = useParams()
  const contract = useContract(contractAddress)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const onClick = async () => {
    dispatch(setActiveContractAddress(contractAddress))
    navigate(`/${contractAddress}?chainId=${contract?.chainId}`)
  }
  const canRender = isValid(contract, address)
  const isActive = contractAddress === currentAddress

  if (!canRender) return null
  return (
    <div
      onClick={onClick}
      className={cn(
        'flex gap-2 items-center px-[12px] py-[8px] cursor-pointer rounded-[4px] hover:bg-[#F2F4F5]',
        isActive && 'text-[#A1AAB1]'
      )}
    >
      <CustomImage
        imgSrc={contract?.metadata?.logo}
        alt="logo"
        className="w-[24px] h-[24px] rounded-[4px]"
      />
      <h6 className="text-[14px] truncate">{contract?.metadata?.name}</h6>
    </div>
  )
}

const DEFAULT_IMAGE = '/logo192.png'

const NameAndLogo = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const chainId = parseInt(searchParams.get('chainId') || '')
  const { address: contractAddress } = useParams()
  const contract = useContract((contractAddress as string) || '')
  const { logo } = contract?.metadata || {}

  return (
    <div className="flex gap-2 items-center justify-center">
      <CustomImage
        imgSrc={logo}
        alt="logo"
        className="w-[24px] h-[24px] rounded-[4px]"
        onClick={() => navigate(`/${contractAddress}?chainId=${chainId}`)}
      />
      <h6 className="text-[14px] font-medium truncate max-w-[100px]">
        {contract?.metadata?.name}
      </h6>
    </div>
  )
}

const CustomImage = ({
  alt,
  imgSrc,
  className,
  onClick,
}: {
  alt?: string
  imgSrc: string
  className?: string
  onClick?: () => void
}) => {
  const [hasError, setHasError] = React.useState(false)
  const [logoUrl, setLogoUrl] = React.useState<string>(DEFAULT_IMAGE)

  useEffect(() => {
    async function fetchlogoUrl() {
      const url = await getLogoUrl(imgSrc)
      if (url) setLogoUrl(url)
    }
    fetchlogoUrl()
  }, [imgSrc])

  return (
    <img
      src={hasError ? DEFAULT_IMAGE : logoUrl}
      onError={() => setHasError(true)}
      className={className}
      alt={alt}
      onClick={onClick}
    />
  )
}

const SidebarHeadActionButton = () => {
  const navigate = useNavigate()
  const onClick = (e: MouseEvent) => {
    e.stopPropagation()
    navigate('/create')
  }
  return (
    <div className="flex gap-[12px] items-center">
      <CreatePortalIcon onClick={onClick} className="z-50 mb-[-2px]" />
      <ChevronDown size="16px" />
    </div>
  )
}

export default DashboardSideBar

export const CreatePortalIcon = (props: {
  onClick: (e: any) => void
  className: string
}) => {
  return (
    <Tooltip text="Create Portal">
      <div onClick={props.onClick} className={props.className}>
        <Plus size="16px" />
      </div>
    </Tooltip>
  )
}

export const ClaimPerksSection = () => {
  const isMediaMax1025px = useMediaQuery('(max-width : 1025px)')
  const taskState = useTasksContext()

  if (!taskState || isEmpty(taskState)) {
    return <></>
  }
  const { contractStorage, setShowTaskList, taskInfo } = taskState

  const onClaimPerksClick = () => {
    if (isMediaMax1025px) {
      sendNotifcation(
        'Try the desktop version!',
        'Some Fileverse features are not available on mobile yet, try desktop for all the latest updates! ',
        'warning',
        10000
      )
      return
    }

    setShowTaskList(true)
  }
  return (
    <>
      <Slide in={true} direction="up">
        <div className="flex-none w-full flex justify-center ">
          <div className="w-full flex flex-col gap-4 items-center">
            <div className="flex w-full justify-center">
              {!isEmpty(contractStorage) && (
                <div className=" w-full max-w-[250px] flex flex-col">
                  <YellowLinearProgress
                    variant="determinate"
                    value={contractStorage?.totalStorage || 0}
                  />
                  <div className="flex w-full justify-between mt-2">
                    <h6 className="text-xs text-[#77818A] font-medium">{`${contractStorage?.storageUsed}MB of ${contractStorage?.limit}MB`}</h6>
                    <h6 className="text-xs text-[#77818A] font-medium">{`${contractStorage?.totalStorage}% full`}</h6>
                  </div>
                </div>
              )}
            </div>

            <div className="w-full max-w-[250px] justify-between flex items-center claim-perks-tour">
              <Tooltip
                text="Up to 1GB of free storage for you <3"
                position="right"
              >
                <Button
                  id="cliam-perks-btn"
                  onClick={onClaimPerksClick}
                  variant="secondary"
                  size="lg"
                  className="border-black border-2 rounded-[4px] flex-1"
                >
                  Claim perks
                </Button>
              </Tooltip>

              <img
                src={rankImage[taskInfo?.rank || PointRank.EXPLORER]}
                className="w-[50px]"
              />
            </div>
          </div>
        </div>
      </Slide>
      {taskState?.isShowTaskList && <TasksModal />}
    </>
  )
}
