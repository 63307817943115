import React from 'react'
import cn from 'classnames'
import { cva, VariantProps } from 'class-variance-authority'
import { Icons } from './icons'

const iconVariants = cva('object-contain', {
  variants: {
    size: {
      sm: 'w-4',
      md: 'w-6',
      lg: 'w-8',
    },
  },
  defaultVariants: {
    size: 'md',
  },
})

// Define the props for individual icons, including common SVG props
interface SvgProps extends React.SVGProps<SVGSVGElement> {
  fill?: string
  stroke?: string
}

// Define the props for the Icon component, including the icon name
export interface IconProps extends SvgProps, VariantProps<typeof iconVariants> {
  name: keyof typeof Icons
  className?: string
}

// The Icon component
export const Icon = ({
  name,
  size,
  fill,
  stroke,
  className,
  ...props
}: IconProps) => {
  const SvgComponent = Icons[name]
  if (!SvgComponent) {
    console.error(`Icon "${name}" not found`)
    return null
  }

  const convertedSize =
    size === 'sm' ? 16 : size === 'md' ? 24 : size === 'lg' ? 32 : 24

  const svgProps = {
    name,
    width: convertedSize,
    height: convertedSize,
    fill,
    stroke,
    className: cn(iconVariants({ size }), className),
    ...props,
  }

  return <SvgComponent {...svgProps} />
}

Icon.displayName = 'Icon'
