import React, { useEffect, useRef, useState } from 'react'
import { NodeViewProps } from '@tiptap/core'
import { NodeViewWrapper } from '@tiptap/react'
import XIcon from '@mui/icons-material/X'
import YouTubeIcon from '@mui/icons-material/YouTube'
import ExploreIcon from '@mui/icons-material/Explore'
import { useEditingContext } from '../../../../hooks/useEditingContext'
import {
  InputAdornment,
  TextField,
  TextFieldProps,
  styled,
} from '@mui/material'
import sendNotifcation from '../../../../utils/notification'
import { debounce } from '../../utils/debounce'
import { OutlinedInputProps } from '@mui/material/OutlinedInput'

export const ActionButtonNodeView = ({
  node,
  editor,
  getPos,
}: NodeViewProps) => {
  const [inputValue, setInputValue] = useState<string>('')
  const isPreview = useEditingContext()
  const twitterUrls = ['https://twitter.com', 'https://x.com']

  const renderIcon = () => {
    switch (node.attrs.data) {
      case 'twitter':
        return <XIcon fontSize="small" />
      case 'iframe':
        return <YouTubeIcon fontSize="small" />
      default:
        return <ExploreIcon fontSize="small" />
    }
  }

  const renderTitle = () => {
    switch (node.attrs.data) {
      case 'twitter':
        return 'Embed a Twitter tweet'
      case 'iframe':
        return 'Embed a video'
      default:
        return 'Embed an URL'
    }
  }

  const iframeRender = () => {
    if (!inputValue) {
      sendNotifcation('Did you forget?', 'Please enter a URL', 'warning')
      return
    }

    let formattedUrl = inputValue

    if (inputValue.includes('<iframe')) {
      const matches = inputValue.match(/src="([^"]*)"/)
      if (matches && matches.length > 0) {
        formattedUrl = matches[1]
      }
    } else {
      switch (true) {
        case /youtu\.?be(?:\.com)?\/(?:.*v(?:\/|=)|(?:.*\/)?)([a-zA-Z0-9-_]+)/.test(
          inputValue
        ): {
          const matches = inputValue.match(
            /youtu\.?be(?:\.com)?\/(?:.*v(?:\/|=)|(?:.*\/)?)([a-zA-Z0-9-_]+)/
          )
          if (matches && matches.length > 0) {
            formattedUrl = `https://www.youtube.com/embed/${matches[1]}`
          }
          break
        }
        case /vimeo\.com\/([a-zA-Z0-9-_]+)/.test(inputValue): {
          const matches = inputValue.match(/vimeo\.com\/([a-zA-Z0-9-_]+)/)
          if (matches && matches.length > 0) {
            formattedUrl = `https://player.vimeo.com/video/${matches[1]}`
          }
          break
        }
        default: {
          sendNotifcation('Invalid URL', 'Please enter a valid URL', 'danger')
          return
        }
      }
    }

    const width = 640
    const height = 360

    const pos = getPos()
    const to = pos + node.nodeSize

    formattedUrl &&
      editor
        ?.chain()
        .focus(pos)
        .deleteRange({ from: pos, to })
        .setIframe({ src: formattedUrl, width, height })
        .run()
  }

  const twitterRender = () => {
    if (!inputValue) {
      sendNotifcation('Did you forget?', 'Please enter a URL', 'warning')
      return
    }

    let filteredTweetId = inputValue

    const isValidUrl = twitterUrls.some((url) => inputValue.includes(url))
    const matches = inputValue.match(/\/status\/([0-9]*)/)
    const isValidTweetId = matches && matches.length > 0

    if (isValidUrl && isValidTweetId) {
      filteredTweetId = matches[1]
    } else {
      sendNotifcation(
        'Invalid URL',
        'Please enter a valid X (Twitter) URL',
        'danger'
      )
      return
    }

    const pos = getPos()
    const to = pos + node.nodeSize

    filteredTweetId &&
      editor
        ?.chain()
        .focus(pos)
        .deleteRange({ from: pos, to })
        .setTweetEmbed({ tweetId: filteredTweetId })
        .run()
  }

  const handleSave = () => {
    switch (node.attrs.data) {
      case 'twitter':
        twitterRender()
        break
      default:
        iframeRender()
        break
    }
  }

  const debouncedHandleSave = debounce(handleSave, 500)

  useEffect(() => {
    if (inputValue) {
      debouncedHandleSave()
    }
  }, [inputValue])

  useEffect(() => {
    editor?.chain().focus()
  }, [])

  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    inputRef.current?.focus()
  }, [])

  const CustomTextField = styled((props: TextFieldProps) => (
    <TextField
      InputProps={{ disableUnderline: true } as Partial<OutlinedInputProps>}
      {...props}
    />
  ))(() => ({
    '& input:valid + fieldset': {
      border: 'none',
    },
    '& input:invalid + fieldset': {
      border: 'none',
    },
    '& input:valid:focus + fieldset': {
      border: 'none',
    },
  }))

  return (
    <NodeViewWrapper
      as="div"
      className="flex gap-2 group w-full relative justify-center items-start"
    >
      {!isPreview && (
        <CustomTextField
          inputRef={inputRef}
          value={inputValue}
          placeholder={renderTitle()}
          onChange={(e) => setInputValue(e.target.value)}
          onClick={(e) => e.stopPropagation()}
          classes={{
            root: 'color-bg-secondary rounded w-full hover:color-bg-secondary-hover cursor-pointer transition-all ease-in-out',
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment disablePointerEvents position="start">
                {renderIcon()}
              </InputAdornment>
            ),
          }}
        />
      )}
    </NodeViewWrapper>
  )
}
