/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from 'react'
import Papa from 'papaparse'
import { useTable, useSortBy } from 'react-table'

import { Spinner } from '../Spinner'

export const Table = ({ columns, data }: { columns: any; data: any }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useSortBy
    )

  return (
    <table className="w-full dapp-table" {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup: any, id1: number) => (
          <tr key={`table-row-${id1}`} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any, id2: number) => (
              <th
                key={`table-head-${id2}`}
                {...column.getHeaderProps(column.getSortByToggleProps())}
                className={`m-0 p-2 cursor-pointer`}
                style={{
                  boxShadow: 'white -3px 0px 0px, rgb(233 233 231) 0px 1px 0px',
                }}
              >
                <div className="flex w-fit justify-between">
                  {column.render('Header')}
                  <div className="ml-2">
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ' '}
                  </div>
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row: any, id1: number) => {
          prepareRow(row)
          return (
            <tr key={`table-row-${id1}`} {...row.getRowProps()}>
              {row.cells.map((cell: any, id2: number) => {
                return (
                  <td
                    key={`table-data-${id2}`}
                    {...cell.getCellProps()}
                    className={`px-2 py-1 border-b ${
                      id2 !== row.cells.length - 1 ? 'border-r' : ''
                    } border-[rgb(238,238,237)]`}
                    style={{
                      fontSize: '14px',
                    }}
                  >
                    <div className="whitespace-nowrap">
                      {cell.render('Cell')}
                    </div>
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default function CSVViewer({ url }: { url: string }) {
  const [csvData, setCsvData] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const importCSV = (csvString: string) => {
    setLoading(true)
    Papa.parse(csvString, {
      complete: (result: any) => {
        setCsvData(result.data)
        setLoading(false)
      },
    })
  }

  const getCSVData = async () => {
    try {
      const res = await (await fetch(url)).text()
      importCSV(res)
    } catch (err) {
      console.log(err)
    }
  }

  const columns = useMemo(
    () =>
      csvData.length &&
      csvData[0].map((d: any, id: number) => ({
        Header: (
          <div
            style={{
              fontWeight: '400',
              fontSize: '14px',
              color: 'rgba(55, 53, 47, 0.65)',
            }}
          >
            {d}
          </div>
        ),
        accessor: id.toString(),
      })),
    [csvData]
  )
  const data = useMemo(
    () => csvData.slice(1).map((d: any) => ({ ...d })),
    [csvData]
  )

  useEffect(() => {
    getCSVData()
  }, [])

  return (
    <div
      data-cy="csv-preview"
      className="w-full h-full overflow-auto p-4 no-scrollbar relative"
    >
      {loading ? (
        <div className="w-full h-full flex flex-col justify-center">
          <Spinner />
        </div>
      ) : (
        <>{csvData.length > 0 && <Table columns={columns} data={data} />}</>
      )}
    </div>
  )
}
