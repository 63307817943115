import { isSafeApp } from '../../utils/safeApp'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { setSafeApp } from './reducer'

export const checkSafeApp = createAsyncThunk(
  'commonStore/checkSafeApp',
  async (_, { dispatch }) => {
    const isSafe = await isSafeApp()
    dispatch(setSafeApp(isSafe))
  }
)
