import React, { useEffect, useState } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { Home } from './pages/domain'
import FilePage from './pages/FilePage'
import Chat from './pages/domain/Chat'
import Analytics from './pages/domain/Analytics'
import Setting from './pages/domain/Setting'
import Files from './pages/domain/Files'
import Contracts from './pages/Portals'
import People from './pages/domain/People'
import CollaborativeDocument from './pages/CollaborativeDocument'
import { useInvokerContracts } from './store/invoker/hooks'
import Manifesto from './pages/Manifesto'
import PrivacyPolicy from './pages/PrivacyPolicy'
import TermsCondition from './pages/TermsConditions'
import { CollaborativeWhiteboard } from './pages/CollaborativeWhiteboard'
import AcceptableUsePolicy from './pages/AcceptableUsePolicy'
import { Claim } from './pages/Claim'
import ResetPage from './pages/ResetPage'

import { SingleWebPage } from './components/WebPages/SingleWebPage'
import { WebPages } from './pages/WebPages'
import Onboarding from './components/onboarding/Onboarding'
import { withErrorBoundary } from '@sentry/react'
import FallbackPage, { ERROR_TYPE } from './pages/FallbackPage'
import PermanentStorage from './pages/PermenentStorage'
import PortalDashboard from './components/common/PortalDashboard'
import { useAppDispatch } from './store/hooks'
import { checkSafeApp } from './store/common/thunk'

import { useQueueStatus, useTaskQueue } from './store/taskQueue/hooks'
import { clearTaskQueue } from './store/taskQueue'
import { QueueStatus } from './store/taskQueue/reducer'
import { TaskRunnerWidget } from './components'
import { WarningModal } from './components/WarningModal'
import LandingPage from './components/onboarding/LandingPage'
import PublicPortal from './pages/PublicPortal'
import { usePrivyHelper } from './hooks/usePrivyHelper'

const pageNotFound = ERROR_TYPE.PAGE_NOT_FOUND
const runtimeError = ERROR_TYPE.RUNTIME_ERROR

function App() {
  const walletAddress = usePrivyHelper().walletAddress
  const [showWarningModal, setShowWarningModal] = useState(false)
  const invokerContracts = useInvokerContracts(walletAddress as string)

  const dispatch = useAppDispatch()

  const checkSafeAppStatus = async () => {
    await dispatch(checkSafeApp())
  }

  useEffect(() => {
    // setWorker()
    checkSafeAppStatus()
  }, [])

  const queueStatus = useQueueStatus()

  const { argsQueue } = useTaskQueue()

  const onCloseModal = () => {
    setShowWarningModal(false)
  }
  const onClearQueue = () => {
    dispatch(clearTaskQueue())
    setShowWarningModal(false)
  }

  const onCloseQueue = () => {
    if (queueStatus === QueueStatus.Idle) onClearQueue()
    else setShowWarningModal(true)
  }

  const loadRunner = argsQueue.length > 0
  // if (!isConnected) return null
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            invokerContracts.length > 0 ? (
              <Navigate to={`/contracts`} replace={true} />
            ) : (
              <Navigate to={'/create'} replace={true} />
            )
          }
        />
        <Route path=":address" element={<PortalDashboard />}>
          <Route index element={<Home />} />
          <Route path="chat" element={<Chat />} />
          <Route path="people" element={<People />} />
          <Route path="analytics" element={<Analytics />} />
          <Route path="settings" element={<Setting />} />
          <Route path="files/*" element={<Files />} />
        </Route>
        <Route
          path="/:address/document/:rtcId"
          element={<CollaborativeDocument />}
        />
        <Route
          path="/:address/whiteboard/:rtcId"
          element={<CollaborativeWhiteboard />}
        />
        <Route path="/:address/storage" element={<PermanentStorage />} />
        <Route path="/:address/dPages" element={<WebPages />} />
        <Route path="/:address/dPages/:rtcId" element={<SingleWebPage />} />
        <Route path="/:address/file/:fileId" element={<FilePage />} />
        <Route path="/:address/member" element={<PublicPortal />} />
        <Route path="/contracts" element={<Contracts />} />
        <Route path="/:address/force-reset" element={<ResetPage />} />
        <Route path="/manifesto" element={<Manifesto />} />
        <Route
          path="/acceptable-use-policy"
          element={<AcceptableUsePolicy />}
        />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-condition" element={<TermsCondition />} />
        <Route path="/claim" element={<Claim />} />
        <Route path="/welcome" element={<LandingPage />} />
        <Route path="/create" element={<Onboarding />} />
        <Route
          path="/404"
          element={<FallbackPage errorType={pageNotFound} />}
        />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
      {loadRunner && <TaskRunnerWidget onCloseQueue={onCloseQueue} />}
      {showWarningModal && (
        <WarningModal
          isOpen={showWarningModal}
          onClose={onCloseModal}
          onConfirm={onClearQueue}
        />
      )}
    </>
  )
}

export default withErrorBoundary(App, {
  fallback: <FallbackPage errorType={runtimeError} />,
})
