import React, { useState } from 'react'
import { Spinner } from '../Spinner'

interface AudioPlayerProps {
  url: string
  mimetype: string
}

export default function AudioPlayer({ url, mimetype }: AudioPlayerProps) {
  const [showAudio, setShowAudio] = useState('hidden')

  const onLoad = () => {
    setShowAudio('')
  }

  return (
    <>
      <div
        data-cy="audio-preview"
        className="w-full h-full p-4 flex flex-col justify-center items-center relative"
      >
        {showAudio === 'hidden' && <Spinner />}
        <audio
          controls
          className={`${showAudio} w-5/6 lg:w-3/4 max-w-screen-md`}
          onLoadedData={onLoad}
        >
          <source src={url} type={mimetype} />
          Your browser does not support the audio element.
        </audio>
      </div>
    </>
  )
}
