import React from 'react'
import { DashboardTourModal } from '../../components/DashboardTourModal'
import { PopoverContentProps } from '@reactour/tour'
import settingIcon from '../../assets/SettingIcon.svg'
import uploadIcon from '../../assets/upload3.svg'
import whiteboardIcon from '../../assets/whiteboard_black.svg'
import { rankImage } from '../../constants'
import { PointRank } from '../../types/enum/pointRank.enum'

const TOUR_DESCRIPTION_MAP = {
  UPLOAD_STORE:
    'Upload any multimedia file and choose how to share it: private & encrypted; public & searchable; or token-gated.',
  WORKSPACE:
    'Sketch and collaborate on private whiteboards; create and co-edit encrypted documents; and publish your decentralised webpages!',
  CLAIM_PERKS:
    'In your decentralised journey, you will learn and win future perks. Explore Fileverse, level-up for extra storage and more <3',
  EDIT_PUBLIC_PORTAL:
    'Your Portal has a private and a public side. Edit the public view by adding a new GIF banner, name, and much more.',
}

const dashboardTourSteps = [
  {
    selector: '.upload-zone-tour',
    content: ({ setCurrentStep, currentStep }: PopoverContentProps) => (
      <DashboardTourModal
        icon={uploadIcon}
        width="25"
        setCurrentStep={() => setCurrentStep(currentStep + 1)}
        currentStep={currentStep}
        title="Upload & Store Files"
        desc={TOUR_DESCRIPTION_MAP.UPLOAD_STORE}
      />
    ),
  },
  {
    selector: '.plugins-tour',
    content: ({ setCurrentStep, currentStep }: PopoverContentProps) => (
      <DashboardTourModal
        icon={whiteboardIcon}
        currentStep={currentStep}
        width="50"
        setCurrentStep={() => setCurrentStep(currentStep + 1)}
        title="Your Customised Workspace"
        desc={TOUR_DESCRIPTION_MAP.WORKSPACE}
      />
    ),
  },
  {
    selector: '.claim-perks-tour',
    content: ({ setCurrentStep, currentStep }: PopoverContentProps) => (
      <DashboardTourModal
        icon={rankImage[PointRank.EXPLORER]}
        currentStep={currentStep}
        width="75"
        setCurrentStep={() => setCurrentStep(currentStep + 1)}
        title="Claim Storage & Perks"
        desc={TOUR_DESCRIPTION_MAP.CLAIM_PERKS}
      />
    ),
  },
  {
    selector: '.edit-public-portal',
    content: ({ setCurrentStep, currentStep }: PopoverContentProps) => (
      <DashboardTourModal
        icon={settingIcon}
        currentStep={currentStep}
        width="100"
        setCurrentStep={() => setCurrentStep(currentStep + 1)}
        title="Customise Your Public Portal"
        desc={TOUR_DESCRIPTION_MAP.EDIT_PUBLIC_PORTAL}
      />
    ),
  },
]

export default dashboardTourSteps
