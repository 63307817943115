/* eslint-disable @typescript-eslint/no-explicit-any */
import { WebIrys } from '@irys/sdk'
import { ARWEAVE_CONFIG } from '../arweave-config'
import { getBrowserProvider } from './ethUtils'
import { ArweaveUploadError } from './errors'

export const isOnCorrectNetwork = async (correctNetworkId: number) => {
  // const provider = await getEthersProvider()
  // const network = await provider.getNetwork()
  // return network.chainId === correctNetworkId
  console.log(correctNetworkId)
  return true
}

const getWebIrys = async () => {
  // BrowserProvider
  const provider = await getBrowserProvider()
  const { token, url } = ARWEAVE_CONFIG

  // Create a wallet object
  const wallet = { name: 'ethersv6', provider: provider }
  // Use the wallet object
  const webIrys = new WebIrys({ url, token, wallet })
  await webIrys.ready()

  return webIrys
}

export const GATEWAY_BASE = 'https://arweave.net/'

export const uploadFileToArweave = async (file: File) => {
  try {
    const irys = await getWebIrys()
    const receipt = await irys.uploadFile(file)
    return receipt.id
  } catch (err) {
    console.error(err)
    throw new ArweaveUploadError('Failed to upload file to Arweave')
  }
}
