/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  MutableRefObject,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useLocation, useParams } from 'react-router-dom'
import cn from 'classnames'
import { checkIfOwnerOrCollaborator } from '../../utils/checkIfOwnerOrCollaborator'
import { useContract } from '../../store/contract/hooks'
import CommonMobileSideMenu from './CommonMobileSideMenu'
import { useServerKeys } from '../../store/invoker/hooks'
import { useMediaMax1025px } from '../../hooks/useMediaQueryHook'
import { usePrivyHelper } from '../../hooks/usePrivyHelper'
import { ChevronRight, ChevronLeft } from 'lucide-react'
interface NavbarProps {
  children?: ReactNode
  centerSection?: ReactNode
  leftSection?: ReactNode
  rightSection?: ReactNode
  bottomSection?: ReactNode
  isEditing?: boolean
  sideMenuTogglerRef: MutableRefObject<null>
}

export const usePluginPath = () => {
  const location = useLocation()
  const pathParts = location.pathname.split('/').filter(Boolean)
  return pathParts[1]
}

const NavbarHOC = ({
  children,
  leftSection,
  centerSection,
  rightSection,
  bottomSection,
  isEditing,
  sideMenuTogglerRef,
}: NavbarProps) => {
  const { address: contractAddress } = useParams()
  const contract = useContract(contractAddress as string)
  const walletAddress = usePrivyHelper().walletAddress
  const serverKeys = useServerKeys(
    walletAddress as string,
    contractAddress as string
  )
  const isOwnerOrCollaborator = checkIfOwnerOrCollaborator(
    contract,
    walletAddress as string,
    serverKeys
  )
  const plugin = usePluginPath()
  const isDPages = plugin === 'dPages'
  const isPublic = plugin === 'file'
  const isMediaMax1024px = useMediaMax1025px()
  const [scrollX, setscrollX] = useState(0)
  const [scrollEnd, setscrollEnd] = useState(false)
  const toolDiv: any = useRef(null)
  const scrollCheck = (element: HTMLDivElement | null) => {
    if (!element) return
    setscrollX(element.scrollLeft)
    if (
      Math.floor(element.scrollWidth - element.scrollLeft) <=
      element.offsetWidth
    ) {
      setscrollEnd(true)
    } else {
      setscrollEnd(false)
    }
  }
  const sideScroll = (
    element: HTMLDivElement | null,
    distance: number,
    step: number
  ) => {
    if (!element) return
    let scrollAmount = 0
    const slideTimer = setInterval(() => {
      element.scrollLeft += step
      scrollAmount += Math.abs(step)
      if (scrollAmount >= distance) {
        clearInterval(slideTimer)
      }
    })
  }
  const scrollDiv = (direction: string) => {
    const distance = 430
    if (direction === 'forward') {
      sideScroll(toolDiv.current, distance, 10)
    } else {
      sideScroll(toolDiv.current, distance, -10)
    }
  }
  useEffect(() => {
    scrollCheck(toolDiv.current)
  }, [])
  return (
    <div
      className={cn('relative z-20', {
        'relative w-full h-full': isDPages,
      })}
    >
      {/* Here goes Navbar layout */}
      <nav
        data-cy="top-navbar"
        className={cn(
          'h-[68px] w-full flex justify-between items-center px-5 py-4 fixed bg-white top-0 z-20 shadow-elevation-1',
          {
            'mb-[2vh]': !isDPages && !isPublic,
          }
        )}
      >
        {/* Left Navigation */}
        <div className={cn('w-1/2 md:w-[20%] no-scrollbar')}>{leftSection}</div>
        {/* Center Toolkits */}
        <div
          className={cn(
            'flex justify-center relative',
            isMediaMax1024px ? 'w-[60%]' : 'w-[50%]'
          )}
        >
          <div
            ref={toolDiv}
            onScroll={() => scrollCheck(toolDiv.current)}
            className="overflow-x-scroll w-full no-scrollbar"
          >
            <div className="min-w-max flex items-center  justify-center">
              {scrollX !== 0 && (
                <ChevronLeft
                  size={20}
                  onClick={() => scrollDiv('back')}
                  className=" cursor-pointer backdrop-blur-md bg-white z-[10] left-0 absolute"
                />
              )}
              {centerSection}
              {!scrollEnd &&
                !toolDiv?.current?.scrollWidth <=
                  toolDiv?.current?.clientWidth &&
                isMediaMax1024px && (
                  <ChevronRight
                    size={20}
                    onClick={() => scrollDiv('forward')}
                    className="cursor-pointer backdrop-blur-md bg-white z-[10] right-0 absolute"
                  />
                )}
            </div>
          </div>
        </div>

        {/* Right Utilities */}
        <div className={cn(isMediaMax1024px ? 'w-[20%]' : 'w-[30%')}>
          <div className={cn('hidden border-b-green-600 lg:flex justify-end')}>
            {rightSection}
          </div>
          <div className={cn('flex lg:hidden justify-end')}>
            <CommonMobileSideMenu
              ref={sideMenuTogglerRef}
              isCollaborator={isOwnerOrCollaborator}
              walletAddress={walletAddress as string}
            />
          </div>
        </div>
      </nav>
      <main className={cn({ hidden: isEditing })}>{children}</main>
      {isPublic && <>{bottomSection}</>}
    </div>
  )
}

export default NavbarHOC
