/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useMemo, useState } from 'react'
import { safeAppsSdk } from '../../utils/safeApp'
import { updateAddress } from '../../store/account/reducer'
import {
  useConnectWallet,
  useLogin,
  usePrivy,
  useWallets,
} from '@privy-io/react-auth'
import { useSafeAppStatus } from '../../store/common/hooks'
import { useAppDispatch } from '../../store/hooks'
import { useSetActiveWallet } from '@privy-io/wagmi'
import { useLocalAccountAddress } from '../../store/account/hook'
import { useAccount, useDisconnect } from 'wagmi'
import { AnimatedLoader } from '../../pages/PublicPortal/components/Loader'

interface PrivyHelperContextType {
  isLoading: boolean
  connectorType: string | undefined
  walletAddress: string | undefined | null
  isDisconnected: boolean
  walletClientType: string | undefined
  loginWithSocials: () => void
  onDisconnectWallet: () => void
  connectInjectedWallet: () => void
}

export const PrivyHelperContext =
  React.createContext<PrivyHelperContextType | null>(null)

export const PrivyHelperProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const { ready: walletsReady, wallets } = useWallets()
  const { ready: privyReady, logout, authenticated } = usePrivy()
  const localAccountAddress = useLocalAccountAddress()
  const { setActiveWallet } = useSetActiveWallet()
  const [activeWalletAddress, setActiveWalletAddress] = useState<null | string>(
    null
  )

  const account = useAccount().address as string
  const { isConnecting } = useAccount()
  const { disconnect, connectors } = useDisconnect()

  const dispatch = useAppDispatch()

  const isSafeApp = useSafeAppStatus()

  const { login } = useLogin({
    onComplete: async (user) => {
      const { wallet } = user
      const { address } = wallet || {}
      if (address) {
        dispatch(updateAddress({ address: address }))
        if (isSafeApp) {
          const safeAddress = (await safeAppsSdk.safe.getInfo()).safeAddress
          setActiveWalletAddress(safeAddress)
        } else {
          setActiveWalletAddress(address)
          await setActiveWallet(wallets[0])
        }
      }
    },
  })

  const { connectWallet } = useConnectWallet({
    onSuccess: async (wallet) => {
      const { address } = wallet
      if (address) {
        dispatch(updateAddress({ address: address }))
        if (isSafeApp) {
          const safeAddress = (await safeAppsSdk.safe.getInfo()).safeAddress
          setActiveWalletAddress(safeAddress)
        } else {
          setActiveWalletAddress(address)
          await setActiveWallet(wallets[0])
        }
      }
    },
  })

  const preConnectCleanup = useCallback(async () => {
    dispatch(updateAddress({ address: null }))
  }, [dispatch])

  const onPrivySocialLogin = useCallback(async () => {
    // debugger
    await preConnectCleanup()
    login()
  }, [login, preConnectCleanup])

  const onPrivyConnectWallet = useCallback(async () => {
    await preConnectCleanup()
    connectWallet()
  }, [connectWallet, preConnectCleanup])

  const onDisconnectWallet = useCallback(() => {
    try {
      // wagmi disconnect
      connectors.forEach((connector) => {
        disconnect({ connector })
      })
      // privy disconnect
      wallets[0]?.disconnect()
      if (authenticated) logout()

      // clear account Redux/LS
      dispatch(updateAddress({ address: null }))
    } catch (err) {
      console.error(err)
    }
  }, [connectors, wallets, authenticated, logout, dispatch, disconnect])

  const walletAddress = useMemo(() => {
    return isSafeApp ? activeWalletAddress : wallets[0]?.address
  }, [isSafeApp, activeWalletAddress, wallets])

  const isLoading = useMemo(() => {
    return !privyReady || !walletsReady || isConnecting
  }, [walletsReady, privyReady, isConnecting])

  const isDisconnected = useMemo(() => {
    return (!isLoading && !walletAddress) || !localAccountAddress || !account
  }, [isLoading, walletAddress, localAccountAddress, account])

  const contextValue = useMemo(() => {
    return {
      isLoading,
      walletAddress,
      isDisconnected,
      connectorType: wallets[0]?.connectorType,
      walletClientType: wallets[0]?.walletClientType,
      onDisconnectWallet,
      loginWithSocials: onPrivySocialLogin,
      connectInjectedWallet: onPrivyConnectWallet,
    }
  }, [
    wallets,
    isLoading,
    walletAddress,
    isDisconnected,
    onPrivySocialLogin,
    onDisconnectWallet,
    onPrivyConnectWallet,
  ])

  if (isLoading)
    return (
      <div className="w-[100vw] h-[100vh] flex items-center justify-center">
        <AnimatedLoader />
      </div>
    )
  return (
    <PrivyHelperContext.Provider value={contextValue}>
      {children}
    </PrivyHelperContext.Provider>
  )
}
