import { getReadOnlyContract } from '../contract'
import { generateSHA256Hash } from '../libCrypto'
import portalAbi from '../../contract/portalAbi.json'
interface IKeyVerification {
  keys: {
    portalEncryptionKey: string
    portalDecryptionKey: string
    memberEncryptionKey: string
    memberDecryptionKey: string
  }
  contractAddress: string
  chainId: number
  version: number
}
interface IMemberDecryptionVerification {
  keys: {
    memberDecryptionKey: string
  }
  contractAddress: string
  chainId: number
}
export const verifyPortalKeysFromPortalContract = async ({
  keys,
  contractAddress,
  version,
}: IKeyVerification) => {
  const {
    portalEncryptionKey,
    portalDecryptionKey,
    memberEncryptionKey,
    memberDecryptionKey,
  } = keys
  const portalContract = getReadOnlyContract(contractAddress, portalAbi)
  const keyVerifiersObject = await portalContract.keyVerifiers(version)
  const keyVerifiers = [
    keyVerifiersObject.portalEncryptionKeyVerifier,
    keyVerifiersObject.portalDecryptionKeyVerifier,
    keyVerifiersObject.memberEncryptionKeyVerifier,
    keyVerifiersObject.memberDecryptionKeyVerifier,
  ]
  if (
    keyVerifiers.includes(
      '0x0000000000000000000000000000000000000000000000000000000000000000'
    )
  ) {
    return null
  }
  const portalEncryptionKeyHash = await generateSHA256Hash(portalEncryptionKey)
  const portalDecryptionKeyHash = await generateSHA256Hash(portalDecryptionKey)
  const memberEncryptionKeyHash = await generateSHA256Hash(memberEncryptionKey)
  const memberDecryptionKeyHash = await generateSHA256Hash(memberDecryptionKey)
  const keysInSHA256 = [
    portalEncryptionKeyHash,
    portalDecryptionKeyHash,
    memberEncryptionKeyHash,
    memberDecryptionKeyHash,
  ]
  for (let i = 0; i < keysInSHA256.length; i++) {
    if (keysInSHA256[i] !== keyVerifiers[i]) {
      return false
    }
  }
  return true
}
export const verifyPortalMembersKeysFromPortalContract = async ({
  keys,
  contractAddress,
}: IMemberDecryptionVerification) => {
  const { memberDecryptionKey } = keys
  const portalContract = getReadOnlyContract(contractAddress, portalAbi)
  const keyVerifiersObject = await portalContract.keyVerifiers(0)
  const memberDecryptionKeyHash = await generateSHA256Hash(memberDecryptionKey)
  const memberDecryptionKeyVerifier =
    keyVerifiersObject.memberDecryptionKeyVerifier
  return memberDecryptionKeyHash === memberDecryptionKeyVerifier
}

export const searchKeyVersionAndVerifyPortalKeys = async (
  serverKeys: {
    portalEncryptionKey: string
    portalDecryptionKey: string
    memberEncryptionKey: string
    memberDecryptionKey: string
  },
  contractAddress: string,
  chainId: number
) => {
  let isVerified = false
  let version: number | null = 0
  while (version !== null) {
    const x = await verifyPortalKeysFromPortalContract({
      keys: serverKeys,
      contractAddress: contractAddress as string,
      chainId,
      version,
    })

    if (x === null) {
      return isVerified
    }
    isVerified = x
    version += 1
  }
}
