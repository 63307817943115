import axios from 'axios'
import { generateToken } from '../utils'
import { NotifcationQueryParams } from '../../components/Notification/NotificationCenter'

export const getNotification = async ({
  contractAddress,
  editSecret,
  chainId,
  walletAddress,
  queryParams,
}: {
  contractAddress: string
  editSecret: string
  walletAddress: string
  chainId: number
  queryParams: NotifcationQueryParams
}) => {
  if (!editSecret) {
    return null
  }
  if (!queryParams.portalAddress) {
    delete queryParams['portalAddress']
  }

  if (queryParams.isRead !== false) {
    delete queryParams['isRead']
  }
  const token = await generateToken(contractAddress as string, editSecret)
  const res = await axios.get(
    `${process.env.REACT_APP_COORDINATOR_URL}/notification`,
    {
      params: queryParams,
      headers: {
        Authorization: `Bearer ${token}`,
        contract: contractAddress as string,
        invoker: walletAddress || '',
        chain: chainId,
      },
    }
  )
  return res.data
}
export const getTotalUnreadNotifications = async ({
  contractAddress,
  editSecret,
  chainId,
  walletAddress,
}: {
  contractAddress: string
  editSecret: string
  walletAddress: string
  chainId: number
}) => {
  if (!editSecret) {
    return null
  }
  const token = await generateToken(contractAddress as string, editSecret)
  const res = await axios.get(
    `${process.env.REACT_APP_COORDINATOR_URL}/notification/unread`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        contract: contractAddress as string,
        invoker: walletAddress || '',
        chain: chainId,
      },
    }
  )
  return res.data?.unreadCnt
}
