import React, { LegacyRef } from 'react'
import QRCode from 'react-qr-code'
import { DynamicModal } from '../../pages/PublicPortal/components/DynamicModal'

interface QRModalProps {
  isQrVisible: boolean
  copyFileURL: string
  qrRef: LegacyRef<HTMLDivElement> | undefined
  setIsQrVisible: React.Dispatch<React.SetStateAction<boolean>>
}

export const QRModal = ({
  isQrVisible,
  copyFileURL,
  // qrRef,
  setIsQrVisible,
}: QRModalProps) => {
  return (
    <DynamicModal
      open={isQrVisible}
      onOpenChange={setIsQrVisible}
      className="w-full max-w-[600px]"
      title="Scan QR Code"
      description="Scan QR Code from Mobile"
      content={
        <div className="flex w-full p-2 justify-center">
          <div className="border-2 p-2 rounded-md">
            <QRCode size={115} value={copyFileURL} />
          </div>
        </div>
      }
    />
  )
}
