import React, { BaseSyntheticEvent, forwardRef } from 'react'
import cn from 'classnames'
import styles from './index.module.scss'
import { LucideIcon } from '@fileverse/ui'

export interface ICustomButtonProps {
  onClick: (event: BaseSyntheticEvent) => void
  children: React.ReactNode
  customCss?: string
  type?: 'primary' | 'secondary'
  loadingText?: string
  isLoading?: boolean
  isDisabled?: boolean
  [key: string]: unknown
}

export const CustomButton = forwardRef<HTMLButtonElement, ICustomButtonProps>(
  function Button(props, ref) {
    const {
      onClick,
      children,
      type = 'primary',
      customCss = '',
      isLoading = false,
      isDisabled = false,
      // loadingText = '',
      ...rest
    } = props
    const onButtonClick = (event: BaseSyntheticEvent) => {
      if (isDisabled || isLoading) return
      onClick(event)
    }

    const { className = '', ...restBtnProps } = rest
    return (
      <button
        ref={ref}
        onClick={onButtonClick}
        className={cn(
          styles.custom_button,
          styles[type],
          customCss,
          className as string,
          {
            [styles.loading]: isLoading,
          }
        )}
        disabled={isDisabled}
        {...restBtnProps}
      >
        {isLoading ? (
          <LucideIcon
            name="LoaderCircle"
            size="md"
            fill="transparent"
            stroke="white"
            className="animate-spin"
          />
        ) : (
          children
        )}
      </button>
    )
  }
)
