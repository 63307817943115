/* eslint-disable @typescript-eslint/no-explicit-any */
import { getDecryptedIPFSAsset } from '../../getIPFSAsset'
import { getPenumbra } from '../getPenumbra'

const decryptFile = async (
  decryptionInfo: any,
  contentIpfsHash: string,
  ipfsUrl?: string
): Promise<{ downloadUrl: string; ipfsUrl: string }> => {
  const penumbra = await getPenumbra()
  try {
    const result = await getDecryptedIPFSAsset({
      ipfsHash: contentIpfsHash,
      decryptionInfo,
      ipfsUrl,
    })
    const { data: downloadUrl } = await penumbra.getTextOrURI(result?.data)[0]
    return { downloadUrl, ipfsUrl: result?.ipfsUrl as string }
  } catch (error: any) {
    if (!ipfsUrl) throw new Error(error)
    // try again without the cached IPFS url
    return decryptFile(decryptionInfo, contentIpfsHash)
  }
}
export default decryptFile
