import React, { useCallback, useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import store from '../../store'
import {
  IPreloadedData,
  addContractIndexDbState,
  addInvokerIndexDbState,
  getPortalStateFromIndexDb,
  queryIndexDb,
} from '../../store/middleware/utils'
import isEmpty from 'lodash/isEmpty'
import { IInvokerState } from '../../store/invoker/interfaces'
import { INewContractState } from '../../store/contract/interfaces'
import { INewFiles } from '../../store/files/interface'
import { setContractFilesOnIOndexDb } from '../../store/middleware/actionHandlers'
import { IContractDbRecord } from '../../store/middleware/database'
import { AnimatedLoader } from '../../pages/PublicPortal/components/Loader'

const LS_KEY_MAP = {
  invoker: 'fileverse.invoker',
  contract: 'fileverse.contract',
  files: 'fileverse.files',
}

const postMigrationCleanup = (storageKey: string) => {
  localStorage.removeItem(storageKey)
}

const IndexedDbProvider = ({ children }: { children: JSX.Element }) => {
  const [preloadedState, setPreloadedState] = useState<IPreloadedData | null>(
    null
  )

  const loadIndexedDbState = useCallback(async () => {
    const state = await getPortalStateFromIndexDb()
    setPreloadedState(state)
  }, [])

  const migrateInvokerStateToIndexDb = async () => {
    const invokerState: IInvokerState = JSON.parse(
      localStorage.getItem(LS_KEY_MAP.invoker) as string
    )

    if (isEmpty(invokerState?.invokers)) return

    const state = invokerState.invokers
    for (const address in state) {
      if (!address) continue
      const data = state[address]
      await addInvokerIndexDbState(data)
    }

    postMigrationCleanup(LS_KEY_MAP.invoker)
  }

  const migrateContractStateToIndexDb = async () => {
    const contractState: INewContractState = JSON.parse(
      localStorage.getItem(LS_KEY_MAP.contract) as string
    )

    if (isEmpty(contractState?.contracts)) return

    const state = contractState.contracts
    for (const contractAddress in state) {
      if (!contractAddress) continue
      const data = state[contractAddress]
      await addContractIndexDbState(data)
    }

    postMigrationCleanup(LS_KEY_MAP.contract)
  }

  const migrateFileStateToIndexDb = async () => {
    const fileState: INewFiles = JSON.parse(
      localStorage.getItem(LS_KEY_MAP.files) as string
    )

    if (isEmpty(fileState?.files)) return

    const state = fileState.files
    for (const contractAddress in state) {
      if (!contractAddress) continue
      const contractState = await queryIndexDb('contracts', contractAddress)
      const data = state[contractAddress]
      await setContractFilesOnIOndexDb(contractState as IContractDbRecord, {
        contractAddress,
        record: data,
      })
    }

    postMigrationCleanup(LS_KEY_MAP.files)
  }

  const setUpStore = async () => {
    /**
     * migrate all current state data from local storage prior to loading the indexedDB state
     * this ensures the avoidance of loading an empty state before successfully migrating the states to indexedDB
     */
    await migrateContractStateToIndexDb()
    await migrateInvokerStateToIndexDb()
    await migrateFileStateToIndexDb()
    await loadIndexedDbState()
  }

  useEffect(() => {
    setUpStore()
  }, [])

  return preloadedState ? (
    <Provider store={store(preloadedState)}>{children}</Provider>
  ) : (
    <div className=" w-[100vw] h-[100vh] flex items-center justify-center ">
      <AnimatedLoader />
    </div>
  )
}

export default IndexedDbProvider
