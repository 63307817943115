import { getPublicFileUrl } from './files/filePreviewHandlers'

export const getLogoUrl = async (logoUrl: string): Promise<string> => {
  let fileUrl: string
  if (logoUrl && logoUrl.substring(0, 4) !== 'http') {
    fileUrl = (await getPublicFileUrl(logoUrl)).downloadUrl
    return fileUrl
  } else {
    return logoUrl
  }
}
