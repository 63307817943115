import { ISEAPair } from 'gun'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useServerKeys } from '../../store/invoker/hooks'
import { IDraft } from '../../types/interface/drafts.interface'
import { getContractDraftsFromGunDB } from '../../utils/collaboration/utils'
import { getISEAKeyPair } from '../../utils/libCrypto'
import DraftListCard from './DraftListCard'
import Spinner from '../Spinner'
import { usePrivyHelper } from '../../hooks/usePrivyHelper'

const Drafts = () => {
  const { address: contractAddress } = useParams()
  const walletAddress = usePrivyHelper().walletAddress
  const serverKeys = useServerKeys(
    walletAddress as string,
    contractAddress as string
  )
  const authKey = getISEAKeyPair(serverKeys.portalGunKey)
  const [drafts, setDrafts] = useState<Array<IDraft>>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const getDrafts = async () => {
    setIsLoading(true)
    const allDrafts = await getContractDraftsFromGunDB(
      authKey as ISEAPair,
      contractAddress as string
    )
    const unpublishedDrafts = allDrafts?.filter(
      (item) =>
        item &&
        Object.prototype.hasOwnProperty.call(item, 'isPublished') &&
        item.isPublished !== true
    )
    setDrafts(unpublishedDrafts || [])
    setIsLoading(false)
  }

  useEffect(() => {
    getDrafts()
  }, [])

  //make the cart dynamic for 2 types of file
  return (
    <div data-cy="drafts-tab" className="h-[70vh] w-full">
      {isLoading ? (
        <div className="w-full h-full flex flex-col gap-2 justify-center items-center">
          <Spinner />
          <p className="color-text-default text-body-sm-bold">
            Getting your drafts...
          </p>
        </div>
      ) : drafts.length > 0 ? (
        <div className="w-full h-full overflow-y-scroll no-scrollbar">
          {drafts.map((draft: IDraft, id: number) => (
            <div key={`file-${id}`} className="w-full">
              <DraftListCard
                contractAddress={contractAddress as string}
                draft={draft}
                setDrafts={setDrafts}
              />
            </div>
          ))}
        </div>
      ) : (
        <div className="flex flex-col h-full w-full text-center justify-center">
          <div className="text-heading-md">No drafts to show</div>
        </div>
      )}
    </div>
  )
}

export default Drafts
