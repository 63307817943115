import React, { useEffect, useState } from 'react'
import { Fade, Tooltip } from '@mui/material'
import Spinner from '../Spinner'
import ProcessDoneIcon from '../../assets/check3.svg'
import GoToIcon from '../../assets/gotoIcon.svg'
import { useSearchParams } from 'react-router-dom'
import { getChainBlockExplorer } from '../../utils/getChainExplorerUrl'

export const TxnHashPreview = ({
  TXNhash,
  txnConfirmation,
}: {
  TXNhash: string
  txnConfirmation?: boolean
}) => {
  const [urlSearchParams] = useSearchParams()
  const [explorerLink, setExplorerLink] = useState<string>()
  useEffect(() => {
    const explorerUrl = getChainBlockExplorer(
      parseInt(urlSearchParams.get('chainId') || '')
    )
    const link = `${explorerUrl}/tx/${TXNhash}`
    setExplorerLink(link)
  }, [])
  return (
    <>
      <div className="flex justify-center items-center">
        <Tooltip
          title="Waiting for transaction to be mined"
          placement="top-start"
        >
          <div className="w-full p-2 border border-[#DEDEDE] rounded-md flex">
            <input
              readOnly
              value={TXNhash}
              className="w-full focus:outline-none"
            />

            <a
              target="_blank"
              rel="noreferrer"
              href={explorerLink}
              className="rounded-md flex ml-4 items-center justify-center"
            >
              <img src={GoToIcon} alt="copy icon" className="mr-1 w-4" />
            </a>
          </div>
        </Tooltip>
        {txnConfirmation ? (
          <div className="flex justify-center ml-4 items-center">
            <Fade in={txnConfirmation}>
              <img src={ProcessDoneIcon} className="scale-[80%]" />
            </Fade>
          </div>
        ) : (
          <div className="flex justify-center ml-4 items-center">
            <Spinner />
          </div>
        )}
      </div>
    </>
  )
}
