import React from 'react'
import Spinner from '../Spinner'
import cn from 'classnames'
import { Tooltip } from '@mui/material'

const WhiteButton = ({
  isDisabled,
  onClick,
  title,
  isLoading,
  customCSS,
  tooltip,
}: {
  isDisabled?: boolean
  onClick?: () => void
  title: string
  isLoading?: boolean
  customCSS?: string
  tooltip?: string
}) => {
  return (
    <Tooltip arrow={true} title={tooltip ? tooltip : ''}>
      <button
        disabled={isDisabled}
        onClick={() => {
          !isLoading && onClick && onClick()
        }}
        className={cn(
          'rounded-md flex items-center justify-center border-black',
          customCSS ? customCSS : 'py-2 h-10 w-[100px] hover:bg-gray-50',
          isDisabled ? 'opacity-50 border cursor-not-allowed' : 'border-2'
        )}
      >
        {isLoading ? <Spinner /> : title}
      </button>
    </Tooltip>
  )
}

export default WhiteButton
