import axios from 'axios'
import qs from 'query-string'
import { generateToken } from '../utils'

interface GetTokensRequest {
  network: string
  contractAddress: string
  credentialEditSecret: string | undefined
  invokerAddress: string
  searchTerm?: string
  chain: number
  tokenDetails?: {
    address: string
    id: string
  }
}

const nftAPI = async ({
  network,
  contractAddress,
  credentialEditSecret,
  invokerAddress,
  searchTerm,
  chain,
  tokenDetails,
}: GetTokensRequest) => {
  const URL = `${process.env.REACT_APP_GATE_URL}/account/nfts`
  let params
  if (searchTerm && network) params = { search: searchTerm, chain: network }

  if (tokenDetails?.address && tokenDetails.id && network)
    params = {
      tokenAddress: tokenDetails.address,
      tokenId: tokenDetails.id,
      chain: network,
    }

  if (!searchTerm && !tokenDetails?.address && !tokenDetails?.id && network)
    params = { chain: network }

  const token = await generateToken(contractAddress, credentialEditSecret || '')
  const { data } = await axios.get(URL, {
    params,
    paramsSerializer: (param: qs.StringifiableRecord) => {
      return qs.stringify(param)
    },
    headers: {
      Authorization: `Bearer ${token}`,
      invoker: invokerAddress,
      contract: contractAddress,
      chain,
    },
  })
  return data.nft
}
export default nftAPI
