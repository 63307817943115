import React from 'react'
import { Editor } from '@tiptap/core'
import cn from 'classnames'
import { Baseline, Check } from 'lucide-react'
import { Dispatch, FC, SetStateAction } from 'react'
import { Button } from '../../pages/PublicPortal/components/Button'

export interface BubbleColorMenuItem {
  name: string
  color: string
}

interface ColorSelectorProps {
  editor: Editor
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

export const ColorSelector: FC<ColorSelectorProps> = ({
  editor,
  isOpen,
  setIsOpen,
}) => {
  const items: BubbleColorMenuItem[] = [
    {
      name: 'Default',
      color: '#000000',
    },
    {
      name: 'Purple',
      color: '#9333EA',
    },
    {
      name: 'Red',
      color: '#E00000',
    },
    {
      name: 'Blue',
      color: '#2563EB',
    },
    {
      name: 'Green',
      color: '#008A00',
    },
    {
      name: 'Orange',
      color: '#FFA500',
    },
    {
      name: 'Pink',
      color: '#BA4081',
    },
    {
      name: 'Gray',
      color: '#A8A29E',
    },
  ]

  const activeItem = items.find(({ color }) =>
    editor.isActive('textStyle', { color })
  )

  return (
    <div className="">
      <Button
        variant="ghost"
        className="min-w-fit w-8 h-8 px-0"
        onClick={() => setIsOpen(!isOpen)}
      >
        <Baseline size={20} style={{ color: activeItem?.color || '#000000' }} />
      </Button>

      {isOpen && (
        <section className="fixed top-full z-[99999] mt-1 flex w-48 flex-col overflow-hidden rounded border border-stone-200 bg-white p-1 shadow-xl animate-in fade-in slide-in-from-top-1">
          {items.map(({ name, color }, index) => (
            <button
              key={index}
              onClick={() => {
                editor.chain().focus().setColor(color).run()
                setIsOpen(false)
              }}
              className={cn(
                'flex items-center justify-between rounded-sm px-2 py-1 text-sm text-stone-600 hover:bg-stone-100',
                {
                  'text-blue-600': editor.isActive('textStyle', { color }),
                }
              )}
            >
              <div className="flex items-center space-x-2">
                <div
                  className="rounded-sm border border-stone-200 px-1 py-px font-medium"
                  style={{ color }}
                >
                  A
                </div>
                <span>{name}</span>
              </div>
              {editor.isActive('textStyle', { color }) && <Check size={16} />}
            </button>
          ))}
        </section>
      )}
    </div>
  )
}
