/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react'
import getFileExtension from '../../utils/files/getFileExtension'
import Logo from '../../assets/unsupportedLogo.svg'
import { Document, Page } from 'react-pdf'
import AudioPlayer from '../FileViewers/AudioPlayer'
import VideoPlayer from '../FileViewers/VideoPlayer'

export const renderFilePreview = (
  file: File & {
    previewURL: string
  },
  displayUrl: string
): JSX.Element => {
  const getFileIcon = (mimeType: string) => {
    if (mimeType.includes('audio')) {
      return (
        <div data-cy="audio-viewer" className="w-full h-full">
          <AudioPlayer url={displayUrl} mimetype={mimeType} />
        </div>
      )
    }

    if (mimeType.includes('video')) {
      return (
        <div
          data-cy="video-viewer"
          className="w-full h-full flex justify-center items-center"
        >
          <VideoPlayer url={displayUrl} mimetype={mimeType} />
        </div>
      )
    }

    if (mimeType.includes('image')) {
      return (
        <div className="w-full h-full p-4 flex justify-center items-center">
          <img
            data-cy="image-viewer"
            src={displayUrl}
            className="rounded-md h-auto w-3/4 object-scale-down shadow-md"
          />
        </div>
      )
    }
    if (mimeType.includes('pdf')) {
      return (
        <Document file={displayUrl}>
          <div className="object-scale-down  h-full w-full">
            <Page
              className={
                'h-full w-full flex justify-center flex-col items-center'
              }
              width={350}
              key={`page_1`}
              pageNumber={1}
              renderAnnotationLayer={true}
            />
          </div>
        </Document>
      )
    }

    return (
      <div className="flex flex-col justify-center items-center gap-4">
        <img src={Logo} className="h-[12rem]" />
        <p className="text-base font-bold text-center">
          Sorry! Preview for this kind of files is not supported yet!
        </p>
      </div>
    )
  }
  if (file.type.includes('image')) {
    return <img src={displayUrl} className="object-scale-down h-full w-full" />
  }
  if (getFileExtension(file.name)?.includes('pdf')) {
    return (
      <div className="overflow-scroll no-scrollbar w-full h-full">
        <Document file={file.previewURL}>
          <div className="object-scale-down  h-full w-full">
            <Page
              className={
                'h-full w-full flex justify-center flex-col items-center'
              }
              width={220}
              key={`page_1`}
              pageNumber={1}
              renderAnnotationLayer={true}
            />
          </div>
        </Document>
      </div>
    )
  }
  return getFileIcon(file.type)
}

export const FileReplacementViewer = ({
  file,
}: {
  file: File & {
    previewURL: string
  }
}) => {
  const previewInfo = {
    displayUrl: URL.createObjectURL(file),
    type: file.type,
    extension: getFileExtension(file.name) as string,
  }
  return (
    <div
      className={`h-full w-full flex flex-col justify-center items-center px-4 gap-4`}
    >
      <h4 className="text-2xl font-bold">
        Replace your file, keep the same link!
      </h4>

      <p className="text-lg">Preview</p>

      <div
        className={`w-[30rem] h-[20rem] p-4 flex flex-col items-center justify-center border-dashed border-neutral-300 rounded-md border-2 max-w-md`}
      >
        {renderFilePreview(file, previewInfo.displayUrl)}
      </div>
      <h4 className="text-2xl font-bold">Click “Publish” to replace!</h4>
    </div>
  )
}
