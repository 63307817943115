NavbarHOC /* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import * as Y from 'yjs'
import useVisibility from '../../hooks/useVisibility'
import { ISEAPair } from 'gun'
import { IDraft } from '../../types/interface/drafts.interface'
import { AnyExtension, useEditor } from '@tiptap/react'
import { TiptapEditorProps } from '../../components/TipTap/props'
import TiptapToolBar from '../../components/TipTap/TiptapToolBar'
import TiptapDocTitlePopup from '../../components/TipTap/TiptapDocTitlePopup'
import LiveCollaborationPopup from '../../components/TipTap/LiveCollaborationPopup'
import useEditorHook, { postDocContent } from './useEditorHook'
import DefaultEditor from './DefaultEditor'
import { DocStatus } from '../ExcalidrawCanvas/SavingStatusUI'
import { getISEAKeyPair } from '../../utils/libCrypto'
import { defaultExtensions } from './extensions/defaultExtenstion'
import Collaboration from '@tiptap/extension-collaboration'
import NavbarHOC from '../HOC/NavbarHOC'
import { WebPageMoreMenu } from '../WebPages/WebPageMoreMenu'
import PluginNavbar from '../Navbars/PluginNavbar'
import { useContract } from '../../store/contract/hooks'
import { usePrivyHelper } from '../../hooks/usePrivyHelper'
import { checkIfOwnerOrCollaborator } from '../../utils/checkIfOwnerOrCollaborator'
import { useServerKeys } from '../../store/invoker/hooks'
import { DeletePopUp } from '../WebPages/DeletePopUp'

import { AnimatedLoader } from '../../pages/PublicPortal/components/Loader'
const OfflineEditor = ({
  setFiles,
  setCollabDocPreview,
  authKey,
  rtcData,
  connectUser,
}: {
  setFiles: React.Dispatch<File[]>
  authKey: ISEAPair
  rtcData: IDraft
  setCollabDocPreview: React.Dispatch<React.SetStateAction<string | undefined>>
  connectUser: (title: string) => void
}) => {
  const walletAddress = usePrivyHelper().walletAddress
  const { address: contractAddress, rtcId } = useParams()
  const [urlParams] = useSearchParams()
  const documentKey = getISEAKeyPair(urlParams.get('key') as string)
  const [isUserDocOwner, setUserDocOwnership] = useState(false)
  const ydoc = new Y.Doc()
  const [docStatus, setDocStatus] = useState<DocStatus>(DocStatus.SAVED)
  const editor = useEditor({
    extensions: [
      ...(defaultExtensions as AnyExtension[]),
      Collaboration.configure({
        document: ydoc,
      }),
    ],
    editorProps: TiptapEditorProps,
    autofocus: 'start',
    onUpdate: () => {
      postDocContent({
        contractAddress: contractAddress as string,
        documentKey: documentKey as ISEAPair,
        rtcId: rtcId as string,
        setDocStatus: (status) => setDocStatus(status),
        ydoc,
      })
    },
  })

  const [confirmDeleteModal, setConfirmDeleteModal] = useState<boolean>(false)
  const [moreMenu, setMoreMenu] = useState<boolean>(false)
  const [commentsEnabled, setCommentsEnabled] = useState<boolean>(true)
  const [indexEnabled, setIndexEnabled] = useState<boolean>(true)
  const contract = useContract(contractAddress as string)
  const portalName = contract?.metadata?.name
  const dropdownRef = useRef<HTMLDivElement>(null)
  const [previewMode, setPreviewMode] = useState<boolean>(false)

  const serverKeys = useServerKeys(
    walletAddress as string,
    contractAddress as string
  )
  const isCollaborator = checkIfOwnerOrCollaborator(
    contract,
    walletAddress as string,
    serverKeys
  )

  const {
    ref: titlePopUpDiv,
    isComponentVisible: titlePopUp,
    setIsComponentVisible: setTitlePopUp,
  } = useVisibility(false)
  const {
    ref: liveCollabPopRef,
    isComponentVisible: isLiveCollab,
    setIsComponentVisible: setIsLiveCollab,
  } = useVisibility(false)
  const {
    publishDocument,
    isLoading,
    handleBackButtonClick,
    draftName,
    setDraftName,
  } = useEditorHook({
    setDocTitlePopUp: setTitlePopUp,
    docTitle: rtcData?.name,
    ydoc,
    setCollabDocPreview,
    editor,
    setFiles,
    rtcData,
  })

  useEffect(() => {
    if (authKey && rtcData?.owner && rtcData?.owner === walletAddress) {
      setUserDocOwnership(true)
    }
  }, [])

  useEffect(() => {
    if (draftName) document.title = `${draftName} | Fileverse`

    return () => {
      document.title = 'Fileverse'
    }
  }, [draftName])

  useEffect(() => {
    setTimeout(() => {
      editor?.setEditable(!previewMode)
    })
  }, [previewMode])

  if (!editor || isLoading) {
    return (
      <div className="w-full h-[100vh] flex justify-center items-center">
        <AnimatedLoader text="Preparing your dDoc..." />
      </div>
    )
  }

  return (
    <div className="w-full h-full overflow-scroll no-scrollbar">
      <div className="w-full h-full">
        <div className="h-fit relative bg-[#ffffff]">
          {moreMenu && (
            <WebPageMoreMenu
              confirmDeleteModal={confirmDeleteModal}
              setConfirmDeleteModal={setConfirmDeleteModal}
              indexEnabled={indexEnabled}
              commentsEnabled={commentsEnabled}
              setCommentsEnabled={setCommentsEnabled}
              setIndexEnabled={setIndexEnabled}
              isDPage={false}
              fileDownloadName={draftName}
              filePreviewPage={false}
              moreMenuRef={dropdownRef}
              isOwner={isCollaborator}
              rtcId={rtcId}
            />
          )}
          <PluginNavbar
            isTitleFieldEnabled={!previewMode}
            dropdownRef={dropdownRef}
            docStatus={docStatus}
            onLiveCollaborationTrigger={() => {
              setIsLiveCollab(true)
            }}
            pluginTool={<TiptapToolBar editor={editor} />}
            portalName={portalName}
            pluginTitle={draftName}
            isPreviewMode={previewMode}
            setPreviewMode={setPreviewMode}
            backButtonAction={handleBackButtonClick}
            isUserACollaborator={true}
            isEditMode={false}
            setEditMode={() => null}
            setIsEditCancelFlag={() => null}
            isPublishLoading={false} // fix the name to be isLoading
            isCommentsEnabled={false}
            toggleCommentVisibility={() => null}
            toggleQrModalVisibility={() => null}
            isCommentsVisible={false}
            toggleMoreMenuVisibility={() => setMoreMenu(!moreMenu)}
            toggleProvenaceModalVisibility={() => null}
            commentCount={0}
            publishPlugin={publishDocument}
            isPublished={false}
            setPluginTitle={setDraftName}
            isCollaborating={false}
            collaborators={null}
          />
        </div>

        <DefaultEditor previewMode={previewMode} editor={editor} />
      </div>

      <TiptapDocTitlePopup
        titlePopUp={titlePopUp}
        titlePopUpDiv={titlePopUpDiv}
        draftName={draftName}
        setDraftName={setDraftName}
        setTitlePopUp={setTitlePopUp}
        publishDocument={() => publishDocument()}
      />
      <LiveCollaborationPopup
        disconnect={() => null}
        collaborators={[]}
        popupRef={liveCollabPopRef}
        isOpen={isLiveCollab}
        closePopup={() => setIsLiveCollab(false)}
        isConnected={false}
        connect={() => {
          connectUser(draftName)
        }}
        isDocOwner={isUserDocOwner}
        docType="dDoc"
      />
      <DeletePopUp
        isOpen={confirmDeleteModal}
        setIsOpen={setConfirmDeleteModal}
        rtcId={rtcId}
      />
    </div>
  )
}

export default OfflineEditor
