/* eslint-disable @typescript-eslint/no-explicit-any */
import isFinite from 'lodash/isFinite'

import { chains } from './networks/chains'

export const getChainBlockExplorer = (chainId: number) => {
  if (!isFinite(chainId)) {
    const chainIdHex = (self.ethereum as any).chainId
    chainId = parseInt(chainIdHex, 16)
  }
  const block = Object.values(chains).find((value) => {
    return value.id === chainId
  })
  const { blockExplorers } = block as {
    blockExplorers: { default: { url: string } }
  }
  return blockExplorers?.default.url
}
