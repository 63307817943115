import React from 'react'
import nftCollection from '../../../assets/nftCollections.svg'
import publicCollab from '../../../assets/publicCollab.svg'
import heartIcon from '../../../assets/heartIcon.svg'
import { useMediaQuery } from '@mui/material'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useFileData } from '../../../store/files/hooks'
import ActionButton from '../../Buttons/ActionButton'
import { FileSource, FileTypeEnum } from '../../../types/enum/file.enum'

const ConnectedWalletOverlay = () => {
  const isMediaMax1025px = useMediaQuery('(max-width : 1025px)')
  const isMediaMax530px = useMediaQuery('(max-width : 530px)')
  const { address: contractAddress, fileId } = useParams()
  const [urlParams] = useSearchParams()
  const chainId = parseInt(urlParams.get('chainId') || '')
  const navigate = useNavigate()
  const fileData = useFileData(contractAddress as string, fileId)
  const fileType = fileData?.metadata.fileType
  const uiTextDisplay = () => {
    if (
      fileData?.metadata.source === FileSource.DPAGE &&
      (fileType === FileTypeEnum.PRIVATE ||
        fileType === FileTypeEnum.MEMBERS_PRIVATE)
    ) {
      return {
        heading: 'Private dPage!',
        p1: `This decentralised webpage is acessible to Members and Collaborators. Connect your wallet to access the dPage OR discover the owner's Fileverse.`,
      }
    }
    if (fileType === FileTypeEnum.PRIVATE) {
      return {
        heading: 'Private File',
        p1: 'The owner of a Fileverse Portal has made this file private.',
        keyword: 'members',
      }
    }
    if (fileType === FileTypeEnum.MEMBERS_PRIVATE) {
      return {
        heading: 'Member Private File',
        p1: 'The owner of a Fileverse Portal has made this file private.',
      }
    }
    if (fileType === FileTypeEnum.GATED) {
      return {
        heading: 'Token Gated File',
        p1: 'The owner of the Fileverse Portal has made this file private.',
      }
    }
  }
  return (
    <div
      className={`${
        isMediaMax1025px ? 'absolute bottom-0 w-full' : 'max-w-[42rem]'
      } bg-white w-full  p-4 rounded-lg flex flex-col items-center justify-center `}
      data-cy="private-file-lock"
    >
      <div className="w-full">
        <h6
          className={`font-bold text-xl ${!isMediaMax1025px && 'text-center'} `}
        >
          {`${uiTextDisplay()?.heading} <3`}
        </h6>
        <p className={`${!isMediaMax1025px && 'text-center'}`}>
          {uiTextDisplay()?.p1}
        </p>
        <p className={`${!isMediaMax1025px && 'text-center'}`}>
          We invite you to visit the Portal and discover what it offers!
        </p>

        <div className="flex justify-center w-full my-2">
          <div
            className={`${
              isMediaMax530px ? 'w-[100%]' : 'w-[70%]'
            } flex justify-between mt-4 `}
          >
            <div className="w-28 rounded-lg h-24 bg-[#FFF2EB]  flex flex-col justify-center items-center">
              <img src={nftCollection} className="w-5 mb-2" />
              <h6 className="font-semibold">Discover</h6>
            </div>
            <div
              className="w-28 rounded-lg h-24 bg-[#FFD8D8] 
            flex flex-col justify-center items-center "
            >
              <img src={publicCollab} className="w-5 mb-2" />
              <h6 className="font-semibold">Collaborate</h6>
            </div>
            <div
              className="w-28 rounded-lg h-24
             bg-[#FFF5B0] flex flex-col justify-center items-center"
            >
              <img src={heartIcon} className="w-5 mb-2" />
              <h6 className="font-semibold">Claim</h6>
            </div>
          </div>
        </div>
        <div className="w-full mt-4 flex justify-center">
          <ActionButton
            width="w-[70%]"
            onclick={() =>
              navigate(`/${contractAddress}/member?chainId=${chainId}`)
            }
            title="Discover Portal"
          />
        </div>
      </div>
    </div>
  )
}

export default ConnectedWalletOverlay
