import explorer from '../assets/explorer.svg'
import pathfinder from '../assets/pathfinder.svg'
import openSourcerer from '../assets/openSourcerer.svg'
import { PointRank } from '../types/enum/pointRank.enum'
import { NotificationAudience } from '../api/notification/postNotification'
import { FileTypeEnum } from '../types/enum/file.enum'
import { NotificationType } from '../types/enum/notification'
import superSourcerer from '../assets/super_sourcerer.jpg'
import { Onboarding } from '../types/enum/onboarding.enum'

// export const FILE_CATEGORIES = ['Images', 'Videos', 'Audio', 'Documents', '3D']
export const ImageTypeExtensions = ['jpeg', 'jpg', 'png', 'svg', 'gif']
export const VideoTypeExtensions = [
  'mp4',
  'mkv',
  'wmv',
  'avi',
  'mpv',
  'mpeg',
  'mov',
]
export const AudioTypeExtensions = ['wav', 'mp3', 'm4a', 'flac', 'wma', 'aac']
export const DocumentTypeExtensions = [
  'pdf',
  'csv',
  'json',
  'txt',
  'doc',
  'docx',
  'xls',
  'xlsx',
  'ppt',
  'pptx',
  'md',
]
export const ThreeDTypeExtensions = ['glb', 'gltf']
export const PORTAL_MEMBERSHIP_MESSAGE = `Verify your membership for this portal`
export const TOKEN_FILE_MESSAGE = `This is a token gated file. sign this message to proof you have the required token to view this file`
// export const AGENT_SIGNATURE_MESSAGE =
//   'Sign this message to grant fileverse agent the permission to sign further transactions on your behalf'
export const rankImage = {
  [PointRank.EXPLORER]: explorer,
  [PointRank.PATHFINDER]: pathfinder,
  [PointRank.OPEN_SOURCERER]: openSourcerer,
  [PointRank.SUPER_SOURCERER]: superSourcerer,
}
export const NOTIFICATION_QUERY_KEY = 'notification'
export const FETCH_TASK_QUERY_KEY = 'fetchTasks'
export const FETCH_PORTAL_STORAGE = 'fetchStorage'
export const FETCH_TOTAL_UNREAD_NOTIFICATION_KEY = 'getUnreadNotifications'
export const NOTIFICATIONS_THAT_REQUIRE_ACTION = [
  NotificationType.COLLABORATOR_INVITE,
]
export const DEFAULT_TIMESTAMP = '1999-10-02T16:12:00.962Z'
export const KEY_GENERATION_NOTIFICATION_MESSAGE =
  'Your Portal indexing and key generation are in progress - it should be ready in a few seconds! 💛'
export const NEW_PORTAL_DEFAULT_METADATA_IPFS_HASH =
  'bafybeien7z7z4jy2lbtv5oba47s5lu2ttjokbxu7euz2xrnfcgmhbluqji/metadata.json'
export const FILE_NOTIFICATION_AUDIENCE: Record<
  FileTypeEnum,
  NotificationAudience
> = {
  [FileTypeEnum.PUBLIC]: 'public',
  [FileTypeEnum.PRIVATE]: 'collaborators_only',
  [FileTypeEnum.MEMBERS_PRIVATE]: 'members_only',
  [FileTypeEnum.GATED]: 'public',
}

export const STEPS_MAP = {
  [Onboarding.DEPLOY]: {
    title: 'Deploy Your Collaboration Smart Contract',
    description:
      'This is your own collaboration smart contract! It serves as your repository of hashes (representing your files) and ensures that the right people have access to your files, folders, or workspace and under what conditions.',
  },
  [Onboarding.GENERATE]: {
    title: 'Generating Encryption Keys and Indexing',
    description:
      'End-to-end encryption (E2EE) is a vital privacy protection. You choose which files should be E2EE. By design, you are the only one with the keys to the encryption & decryption of your files.',
  },
  [Onboarding.ACCESS]: {
    title: 'Your Fileverse Portal Is Ready!',
    description:
      "Onchain is the new online... here's to decentralisation, privacy, and self-sovereignty, for everyone!",
  },
  [Onboarding.SIGNLESS]: {
    title: '',
    description:
      'Make your Portal experience smooth and intuitive. Leverage Account Abstraction features to unlock simple onchain interactions.',
  },
}

export * from './appConfig'
