import { IServerKey } from './../../types/interface/invoker.interface'
import { AppState } from '..'
import { ICredential } from '../../types/interface/invoker.interface'
import { useAppSelector } from '../hooks'
import isEmpty from 'lodash/isEmpty'
import { useContractChainId } from '../contract/hooks'
import { createSelector } from '@reduxjs/toolkit'
import { InvokerStatus } from '../../types'

export function usePortalAgentAddress(contractAddress: string): string {
  return useAppSelector(
    (state: AppState) => state.contract.contracts[contractAddress]?.agentAddress
  )
}

export function useInvokerContracts(accountAddress: string): string[] {
  return useAppSelector(
    createSelector(
      (state: AppState) => state.invoker.invokers,
      (invokers) => invokers[accountAddress]?.contracts || []
    )
  )
}

export function useInvokerContractCredentials(
  accountAddress: string,
  contractAddress: string
): ICredential | undefined {
  return useAppSelector((state: AppState) => {
    if (isEmpty(accountAddress)) return
    return state.invoker.invokers[accountAddress]?.credentials[contractAddress]
  })
}

export function useAgentKey(invokerAddress: string, contractAddress: string) {
  return useAppSelector(
    (state: AppState) =>
      state.invoker.invokers[invokerAddress]?.serverKeys[contractAddress]
        ?.agentKey || ''
  )
}

export function useInvokerCredential(
  invokerAddress: string,
  contractAddress: string
): ICredential {
  return useAppSelector(
    (state: AppState) =>
      state.invoker.invokers[invokerAddress]?.credentials[contractAddress]
  )
}

export function useServerKeys(
  invokerAddress: string,
  contractAddress: string
): IServerKey {
  return useAppSelector(
    createSelector(
      (state: AppState) => state.invoker.invokers,
      (invokers) =>
        invokers[invokerAddress]?.serverKeys[contractAddress] || {
          portalEncryptionKey: '',
          portalDecryptionKey: '',
          memberEncryptionKey: '',
          memberDecryptionKey: '',
          ownerEncryptionKey: '',
          ownerDecryptionKey: '',
        }
    )
  )
}
export function useInvokerCredentialsAndKeys(
  invokerAddress: string,
  contractAddress: string
) {
  const credential = useInvokerCredential(invokerAddress, contractAddress)
  const serverKeys = useServerKeys(invokerAddress, contractAddress)
  const chainId = useContractChainId(contractAddress)
  const agentAddress = usePortalAgentAddress(contractAddress)
  const agentKey = useAgentKey(invokerAddress, contractAddress)
  return {
    ...credential,
    ...serverKeys,
    chainId,
    agentAddress,
    agentKey,
    owner: invokerAddress,
  }
}

export const useInvokerStatus = (
  invokerAddress: string,
  contractAddress: string
): InvokerStatus => {
  return useAppSelector(
    createSelector(
      [
        (state: AppState) => state.invoker.invokers,
        (state: AppState) => state.contract.contracts,
      ],
      (invokers, contracts) => {
        const serverKeys = invokers[invokerAddress]?.serverKeys[contractAddress]
        if (!serverKeys || isEmpty(serverKeys)) return InvokerStatus.Visitor
        const contract = contracts?.[contractAddress]
        if (!contract) return InvokerStatus.Visitor

        if (contract.owner === invokerAddress) return InvokerStatus.Owner

        if (
          contract.collaborators.includes(invokerAddress) &&
          serverKeys.portalDecryptionKey
        )
          return InvokerStatus.Collaborator

        return InvokerStatus.Visitor
      }
    )
  )
}
