import chatSpace from '../../assets/chatSpace.svg'
import publicCollab from '../../assets/publicCollab.svg'
import Canvas from '../../assets/Canvas.svg'
import nftCollection from '../../assets/nftCollections.svg'

export const comingSoonButtons = [
  {
    title: 'NFT Collection',
    icon: nftCollection,
  },
  {
    title: 'Canvas',
    icon: Canvas,
  },
  {
    title: 'Public Collaboration',
    icon: publicCollab,
  },
  {
    title: 'Chat Space',
    icon: chatSpace,
  },
]
export const folders = [
  {
    title: 'Images',
    icon: 'FileImage',
  },
  {
    title: 'Videos',
    icon: 'FileVideo',
  },
  {
    title: 'Audios',
    icon: 'FileMusic',
  },
  {
    title: 'Documents',
    icon: 'File',
  },
  {
    title: 'Whiteboards',
    icon: 'PenLine',
  },
  {
    title: 'Notes',
    icon: 'List',
  },
  {
    title: 'dPages',
    icon: 'PanelsTopLeft',
  },
  {
    title: '3D',
    icon: 'Box',
  },
  {
    title: 'All Files',
    icon: 'Ellipsis',
  },
]
