/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React from 'react'
import { BubbleMenu, BubbleMenuProps, isNodeSelection } from '@tiptap/react'
import cn from 'classnames'
import { useState } from 'react'
import {
  Bold,
  Code,
  Highlighter,
  Italic,
  Link,
  Strikethrough,
  Underline,
  AlignLeft,
} from 'lucide-react'
import { NodeSelector } from './node-selector'
import { ColorSelector } from './color-selector'
import {
  LinkPopup,
  useEditorToolbar,
  TextHighlighter,
  EditorAlignment,
} from './EditorUtils'
import { Button } from '../../pages/PublicPortal/components/Button'
import { Divider } from '@fileverse/ui'
import { IEditorTool } from '../../hooks/useVisibility'

export interface BubbleMenuItem {
  name: string
  isActive: () => boolean
  command: () => void
  icon: typeof BoldIcon
}

type EditorBubbleMenuProps = Omit<BubbleMenuProps, 'children'>

export const EditorBubbleMenu = (props: EditorBubbleMenuProps) => {
  const items: BubbleMenuItem[] = [
    {
      name: 'Bold',
      isActive: () => props.editor.isActive('bold'),
      command: () => props.editor.chain().focus().toggleBold().run(),
      icon: <Bold size={20} />,
    },
    {
      name: 'Italic',
      isActive: () => props.editor.isActive('italic'),
      command: () => props.editor.chain().focus().toggleItalic().run(),
      icon: <Italic size={20} />,
    },
    {
      name: 'Underline',
      isActive: () => props.editor.isActive('underline'),
      command: () => props.editor.chain().focus().toggleUnderline().run(),
      icon: <Underline size={20} />,
    },
    {
      name: 'Strikethrough',
      isActive: () => props.editor.isActive('strike'),
      command: () => props.editor.chain().focus().toggleStrike().run(),
      icon: <Strikethrough size={20} />,
    },
    {
      name: 'Alignment',
      isActive: () => toolVisibilty === IEditorTool.ALIGNMENT,
      command: () => setToolVisibility(IEditorTool.ALIGNMENT),
      icon: <AlignLeft size={20} />,
    },
    {
      name: 'Code',
      isActive: () => props.editor.isActive('codeBlock'),
      command: () => props.editor.chain().focus().toggleCodeBlock().run(),
      icon: <Code size={20} />,
    },
    {
      name: 'Link',
      isActive: () => props.editor.isActive('link'),
      command: () => setIsLinkPopupOpen(!isLinkPopupOpen),
      icon: <Link size={20} />,
    },
  ]

  const bubbleMenuProps: EditorBubbleMenuProps = {
    ...props,
    shouldShow: ({ state, editor }) => {
      const { selection } = state
      const { empty } = selection

      // don't show bubble menu if:
      // - the selected node is an image
      // - the selection is empty
      // - the selection is a node selection (for drag handles)
      if (editor.isActive('image') || empty || isNodeSelection(selection)) {
        return false
      }
      return true
    },
    tippyOptions: {
      moveTransition: 'transform 0.15s ease-out',
      duration: 200,
      animation: 'shift-toward-subtle',
      onHidden: () => {
        setIsNodeSelectorOpen(false)
        setIsColorSelectorOpen(false)
        setIsLinkPopupOpen(false)
      },
    },
  }

  const [isNodeSelectorOpen, setIsNodeSelectorOpen] = useState(false)
  const [isColorSelectorOpen, setIsColorSelectorOpen] = useState(false)
  const [isLinkPopupOpen, setIsLinkPopupOpen] = useState(false)

  const { toolRef, setToolVisibility, toolVisibilty } = useEditorToolbar({
    editor: props.editor,
  })

  return (
    <BubbleMenu
      {...bubbleMenuProps}
      className="flex gap-1 overflow-hidden rounded-[12px] h-[52px] min-w-[472px] w-full py-2 px-4 bg-white items-center shadow-elevation-3"
    >
      <NodeSelector
        editor={props.editor}
        isOpen={isNodeSelectorOpen}
        setIsOpen={() => {
          setIsNodeSelectorOpen(!isNodeSelectorOpen)
          setIsColorSelectorOpen(false)
        }}
      />
      <Divider direction="vertical" size="lg" />
      {items.map((item, index) => (
        <div key={index} className="flex items-center gap-1">
          <Button
            variant="ghost"
            onClick={item.command}
            className={cn('min-w-fit w-8 h-8 px-2', {
              'color-bg-brand': item.isActive(),
            })}
          >
            {item.icon}
          </Button>
          {(index === 4 || index === 6) && (
            <Divider direction="vertical" size="lg" />
          )}
        </div>
      ))}
      <ColorSelector
        editor={props.editor}
        isOpen={isColorSelectorOpen}
        setIsOpen={() => {
          setIsColorSelectorOpen(!isColorSelectorOpen)
          setIsNodeSelectorOpen(false)
        }}
      />
      <Button
        variant="ghost"
        onClick={() => setToolVisibility(IEditorTool.HIGHLIGHT)}
        className={cn('min-w-fit w-8 h-8 px-2', {
          'color-bg-brand': toolVisibilty === IEditorTool.HIGHLIGHT,
        })}
      >
        <Highlighter size={20} />
      </Button>
      {toolVisibilty === IEditorTool.ALIGNMENT && (
        <EditorAlignment
          setToolVisibility={setToolVisibility}
          editor={props.editor}
          elementRef={toolRef}
        />
      )}
      {isLinkPopupOpen && (
        <LinkPopup
          setToolVisibility={setToolVisibility}
          editor={props.editor}
          elementRef={toolRef}
          bubbleMenu={true}
          setIsLinkPopupOpen={setIsLinkPopupOpen}
        />
      )}
      {toolVisibilty === IEditorTool.HIGHLIGHT && (
        <TextHighlighter
          setVisibility={setToolVisibility}
          editor={props.editor as Editor}
          elementRef={toolRef}
        />
      )}
    </BubbleMenu>
  )
}
