/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import ProcessDoneIcon from '../../assets/check3.svg'
import FillingLoader from '../common/UploadBar/FillingLoader'

const ActionButton = ({
  onclick,
  title,
  isLoading,
  icon,
  loadingText,
  actionSuccess,
  isDisabled,
  width,
  cyId,
}: {
  onclick: any
  title: string
  isLoading?: boolean
  icon?: any
  width?: string
  loadingText?: string
  actionSuccess?: boolean
  isDisabled?: boolean
  cyId?: string
}) => {
  if (isLoading) {
    return (
      <FillingLoader
        fillingText={`${loadingText || 'Loading'}...`}
        width={` ${width ? width : 'min-w-[174px]'}  `}
        height="h-[45px]"
      />
    )
  }
  return (
    <div
      data-cy={cyId}
      className={` ${width ? width : 'min-w-[174px]'} h-[45px]`}
    >
      {actionSuccess ? (
        <div className="w-full flex justify-center items-center h-full">
          <img src={ProcessDoneIcon} className="scale-[80%]" />
        </div>
      ) : (
        <button
          className={`flex ${
            isDisabled
              ? 'cursor-not-allowed bg-[#D9D9D9] text-[#000000] '
              : 'cursor-pointer bg-black text-white'
          } w-full hover:scale-[1.03] ease-in duration-200 items-center justify-center p-1 rounded-lg h-11`}
          onClick={() => {
            !isDisabled && onclick()
          }}
        >
          {icon && <img src={icon} alt="Copy" className="mr-4 w-5" />}
          <h6 className=" font-normal">{title}</h6>
        </button>
      )}
    </div>
  )
}

export default ActionButton
