import { fetchFile } from './thunks'
import { createSlice } from '@reduxjs/toolkit'
import { INewFiles } from './interface'
import { IFileURLCache, INewFile } from '../../types/interface/file.interface'

export interface IAddFilePayload {
  contractAddress: string
  fileData: INewFile
}
export interface IDeleteFilePayload {
  contractAddress: string
  fileId: number
}

export interface ICacheFileIPFSURL {
  fileId: number
  contractAddress: string
  cache: Partial<IFileURLCache>
}

const initialState: INewFiles = {
  files: {},
}

const newFileSlice = createSlice({
  name: 'newFile',
  initialState,
  reducers: {
    addFile(
      state: INewFiles,
      action: { type: string; payload: IAddFilePayload }
    ): void {
      const { contractAddress, fileData } = action.payload
      if (state.files[contractAddress]) {
        state.files[contractAddress][fileData.fileId] = fileData
      } else {
        state.files[contractAddress] = {
          [fileData.fileId]: fileData,
        }
      }
    },
    editFile(
      state: INewFiles,
      action: {
        type: string
        payload: IAddFilePayload
      }
    ): void {
      const { contractAddress, fileData } = action.payload
      const { isDeleted } = fileData.metadata
      if (
        state.files[contractAddress] &&
        state.files[contractAddress][fileData.fileId]
      ) {
        if (isDeleted) {
          delete state.files[contractAddress][fileData.fileId]
        } else {
          state.files[contractAddress][fileData.fileId] = fileData
        }
      }
    },
    deleteFile(
      state: INewFiles,
      action: { type: string; payload: IDeleteFilePayload }
    ) {
      const { contractAddress, fileId } = action.payload

      if (
        !state.files[contractAddress] ||
        !state.files[contractAddress][fileId]
      ) {
        return
      }

      delete state.files[contractAddress][fileId]
    },
    cacheFileIPFSUrl(
      state: INewFiles,
      action: {
        type: string
        payload: ICacheFileIPFSURL
      }
    ) {
      const { contractAddress, fileId, cache } = action.payload
      const files = { ...state.files }
      const fileToUpdate = files[contractAddress]?.[fileId]

      if (!fileToUpdate) return

      const updatedCache = {
        metadataIPFSUrl:
          cache.metadataIPFSUrl || fileToUpdate?.cache?.metadataIPFSUrl || '',
        contentIPFSUrl:
          cache.contentIPFSUrl || fileToUpdate?.cache?.contentIPFSUrl || '',
      }

      const updatedFile = { ...fileToUpdate, cache: updatedCache }

      files[contractAddress] = {
        ...files[contractAddress],
        [fileId]: updatedFile,
      }
      return {
        ...state,
        files,
      }
    },
  },
  extraReducers: (builder) =>
    builder.addCase(fetchFile.fulfilled, (state, action) => {
      const { fileData, contractAddress } = action.payload
      if (fileData.metadata?.isDeleted) {
        return
      }

      if (state.files[contractAddress]) {
        state.files[contractAddress][fileData.fileId] = fileData
      } else {
        state.files[contractAddress] = {
          [fileData.fileId]: fileData,
        }
      }
    }),
})

export const { addFile, editFile, deleteFile, cacheFileIPFSUrl } =
  newFileSlice.actions

export default newFileSlice.reducer
