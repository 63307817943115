/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import Lottie from 'lottie-react'
// import Animation from '../../assets/loader/JSON_portal_loader.json'

interface LoaderProps {
  animationFile: any
  className?: string
}

const Loader = ({ animationFile, className }: LoaderProps) => {
  return <Lottie className={className} animationData={animationFile} />
}

export default Loader
