import * as ucans from 'ucans'
import { generateRSAKeyPair } from './libCrypto'
import { convertTypedArrayToString } from './string'
import { GenerateServerKeysError } from './errors'

export const generateKeyPairs = async () => {
  const editKeyPair = await ucans.EdKeypair.create({
    exportable: true,
  })
  const viewKeyPair = await ucans.EdKeypair.create({
    exportable: true,
  })
  const editSecret = await editKeyPair.export()
  const viewSecret = await viewKeyPair.export()
  const editDID = editKeyPair.did()
  const viewDID = viewKeyPair.did()

  return {
    editSecret,
    viewSecret,
    editDID,
    viewDID,
  }
}

export const getExportedPublicAndPrivateKeys = async () => {
  const rsaKeyPair = await generateRSAKeyPair()
  const publicKey = await window.crypto.subtle.exportKey(
    'spki',
    rsaKeyPair.publicKey
  )
  const privateKey = await window.crypto.subtle.exportKey(
    'pkcs8',
    rsaKeyPair.privateKey
  )
  return { publicKey, privateKey }
}

export const generateServerKeys = async (viewSecret: string) => {
  try {
    const ucanKeyPair = ucans.EdKeypair.fromSecretKey(viewSecret)
    const { publicKey, privateKey } = await getExportedPublicAndPrivateKeys()
    const encryptionKey = convertTypedArrayToString(new Uint8Array(publicKey))
    const decryptionKey = convertTypedArrayToString(new Uint8Array(privateKey))

    const ucan = await ucans.build({
      audience: process.env.REACT_APP_SERVICE_DID || '',
      issuer: ucanKeyPair,
      lifetimeInSeconds: 7 * 86400,
      capabilities: [
        {
          with: {
            scheme: 'key_sharing',
            hierPart: encryptionKey,
          },
          can: {
            namespace: 'server_keys',
            segments: ['REQUEST'],
          },
        },
      ],
    })
    return {
      token: ucans.encode(ucan),
      encryptionKey,
      decryptionKey,
    }
  } catch (err) {
    console.log(err)
    throw new GenerateServerKeysError('Failed to generate server keys')
  }
}
