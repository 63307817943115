import * as React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'
import cn from 'classnames'
import { Tooltip } from '@mui/material'

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-lg text-sm font-medium ring-offset-background shadow-sm transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 hover:bg-opacity-80 disabled:cursor-not-allowed disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'bg-[#fff] text-black',
        // danger: 'btn-danger text-danger-foreground hover:btn-danger-hover',
        // outline:
        //   'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
        secondary: 'bg-none text-white border-2 border-white',
        // ghost: 'hover:bg-none hover:text-accent-foreground shadow-none',
        // link: 'text-primary underline-offset-4 hover:underline',
      },
      size: {
        default: 'h-10 px-4 py-2',
        sm: 'h-9 px-3',
        lg: 'h-11 px-8',
        icon: 'h-10 w-10 shadow-none',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  leftIcon?: React.ReactNode
  rightIcon?: React.ReactNode
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  toolTipText?: string
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      leftIcon,
      rightIcon,
      onClick,
      toolTipText,
      ...props
    },
    ref
  ) => {
    return (
      <Tooltip arrow={true} title={toolTipText}>
        <button
          className={cn(buttonVariants({ variant, size, className }))}
          ref={ref}
          onClick={onClick}
          {...props}
        >
          {leftIcon && <span className="start-adornment">{leftIcon}</span>}
          {props.children}
          {rightIcon && <span className="end-adornment">{rightIcon}</span>}
        </button>
      </Tooltip>
    )
  }
)
Button.displayName = 'Button'

export default Button
