import { ZeroAddress } from 'ethers'
import { IInvoker } from '../../types/interface/invoker.interface'
import { INewContractState } from '../contract/interfaces'
import { IInvokerState } from '../invoker/interfaces'
import { IContractDbRecord, db } from './database'
import { IContract } from '../../types/interface/contract.interface'
import isEmpty from 'lodash/isEmpty'
import { INewFile } from '../../types/interface/file.interface'
import { INewFiles } from '../files/interface'

type IndexDbRecords = 'contracts' | 'invoker'

export interface IPreloadedData {
  invoker: IInvokerState
  contract: INewContractState
  files: INewFiles
}

export const modifyInvokerIndexDbState = async (
  invokerAddress: string,
  changes: Partial<IInvoker>
) => {
  try {
    const result = await db.invoker
      .where('address')
      .equals(invokerAddress)
      .modify(changes)
    if (!result) throw new Error('failed to save data to indexeddb')
  } catch (err) {
    console.error(err)
  }
}
export const modifyContractIndexedDbState = async (
  contractAddress: string,
  changes: Partial<IContractDbRecord>
) => {
  try {
    const result = await db.contracts
      .where('contractAddress')
      .equals(contractAddress)
      .modify(changes)
    if (!result) throw new Error('failed to save data to indexeddb')
  } catch (err) {
    console.error(err)
  }
}
export const addInvokerIndexDbState = async (state: IInvoker) => {
  // avoid duplicating records
  // by making sure we only add items when they are not on indexeddb
  const invokerState = await queryIndexDb('invoker', state.address)
  if (!invokerState || isEmpty(invokerState)) {
    try {
      const result = await db.invoker.add(state)
      if (!result) throw new Error('failed to save data to indexeddb')
    } catch (err) {
      console.log(err)
    }
  }
}
export const addContractIndexDbState = async (state: IContract) => {
  const contractState = await queryIndexDb('contracts', state.contractAddress)
  if (!contractState || isEmpty(contractState)) {
    try {
      const results = await db.contracts.add({ ...state, files: {} })
      if (!results) throw new Error('failed to save data to indexeddb')
    } catch (err) {
      console.log(err)
    }
  }
}

export const queryIndexDb = (recordName: IndexDbRecords, address: string) => {
  if (!address) return
  switch (recordName) {
    case 'contracts': {
      return db.contracts.where('contractAddress').equals(address).first()
    }
    case 'invoker': {
      return db.invoker.where('address').equals(address).first()
    }
  }
}
export const getInvokerStateFromIndexDb = async (): Promise<IInvokerState> => {
  const data = await db.invoker.toArray()

  const state: IInvokerState['invokers'] = {}

  data.forEach((record) => {
    const { address } = record
    if (!(address in state)) {
      state[address] = record
    }
  })
  const result = {
    invokers: state,
    activeInvokerAddress: '',
  }
  return result
}

export const getContractStateFromIndexDb =
  async (): Promise<INewContractState> => {
    const data = await db.contracts.toArray()

    const contractState: INewContractState['contracts'] = {}

    data.forEach((record) => {
      const { contractAddress } = record
      if (!(contractAddress in contractState)) {
        contractState[contractAddress] = record
      }
    })
    const result = {
      allContracts: [],
      contracts: contractState,
      activeContractAddress: ZeroAddress,
    }
    return result
  }

export const getFileStateOnFromIndexedDb = async () => {
  const fileState: Record<string, Record<number, INewFile>> = {}
  const data = await db.contracts.toArray()

  data.forEach((record) => {
    const { contractAddress, files } = record
    if (!(contractAddress in fileState)) {
      fileState[contractAddress] = files
    }
  })

  return {
    files: fileState,
  }
}

export const getPortalStateFromIndexDb = async (): Promise<IPreloadedData> => {
  const invoker = await getInvokerStateFromIndexDb()
  const contract = await getContractStateFromIndexDb()
  const files = await getFileStateOnFromIndexedDb()
  return { invoker, contract, files }
}

export const queryIdbContractByAddress = async (
  contractAddress: string
): Promise<IContract> => {
  try {
    const result = await db.contracts
      .where('contractAddress')
      .equals(contractAddress)
      .first()
    if (!result) throw new Error('contract not found')
    return result
  } catch (err) {
    console.error(err)
    throw new Error('Something went wrong')
  }
}

export const unsafe_getIdbContractByAddress = async (
  contractAddress: string
) => {
  try {
    const result = await db.contracts
      .where('contractAddress')
      .equals(contractAddress)
      .first()
    return result
  } catch (err) {
    console.error(err)
  }
}

export const queryIdbInvokerByAddress = async (
  invokerAddress: string
): Promise<IInvoker> => {
  try {
    const result = await db.invoker
      .where('address')
      .equals(invokerAddress)
      .first()
    if (!result) throw new Error('invoker not found')
    return result
  } catch (err) {
    console.error(err)
    throw new Error('Something went wrong')
  }
}
