/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { IFileMetaData } from '../../types/interface/file.interface'
import AudioPlayer from '../FileViewers/AudioPlayer'
import CSVViewer from '../FileViewers/CsvViewer'
import DefaultFileView from '../FileViewers/DefaultViewer'
import PdfViewer from '../FileViewers/PdfViewer'
import PlainTextViewer from '../FileViewers/PlainTextViewer'
import TextFileViewer from '../FileViewers/TextFileViewer'
import ThreeDViewer from '../FileViewers/ThreeDViewer'
import VideoPlayer from '../FileViewers/VideoPlayer'
import XLSXViewer from '../FileViewers/XlsxViewer'
import Logo from '../../assets/nftIcon.svg'

import lockedWebpage from '../../assets/lockedWebpage.png'
import BlackButton from '../common/BlackButton'
import { useNavigate } from 'react-router-dom'
// import { ConnectButton } from '@rainbow-me/rainbowkit'
import ImageViewer from '../FileViewers/ImageViewer'
import { FileTypeEnum } from '../../types/enum/file.enum'
import TokenGatedFileOverLay from './Overlays/TokenGatedFileOverLay'
import { usePrivyHelper } from '../../hooks/usePrivyHelper'

interface RenderFileProps {
  downloadUrl: string
  metadata: IFileMetaData
  isDPage?: boolean
  fileLocked?: boolean
  isEditing?: boolean
  isCollaborator?: boolean
  signerAddress?: string
  viewTokenGatedFile: any
}
export const RenderFile = ({
  downloadUrl,
  metadata,
  isDPage,
  fileLocked,
  isEditing,
  isCollaborator,
  signerAddress,
  viewTokenGatedFile,
}: RenderFileProps) => {
  const extension = metadata?.extension
  const mimetype = metadata?.mimeType
  const fileName = metadata?.name
  const navigate = useNavigate()

  const connectButtonRef = React.useRef<HTMLButtonElement>(null)
  const { loginWithSocials, connectInjectedWallet } = usePrivyHelper()

  const isTokenGatedFile = metadata?.fileType === FileTypeEnum.GATED
  if (fileLocked) {
    if (isDPage) {
      return (
        <div className="w-screen h-screen m-0 flex items-center justify-center">
          <img
            className="w-screen h-screen object-cover m-0"
            src={lockedWebpage}
          />
        </div>
      )
    }

    if (isTokenGatedFile) {
      return (
        <div className="w-full h-full flex items-center justify-center bg-[#323639] bg-opacity-80 px-4">
          <TokenGatedFileOverLay handleGatedFilePreview={viewTokenGatedFile} />
        </div>
      )
    }
    return (
      <div className="w-full h-full flex items-center justify-center bg-[#323639] bg-opacity-80 px-4">
        <div className="w-full flex flex-col justify-center items-center gap-6 bg-[#323639] bg-opacity-90 rounded-xl p-6 text-white max-w-lg">
          <img src={Logo} className="h-[8rem]" />
          <p className="text-base font-bold text-center">
            This file is private!{' '}
            {isCollaborator
              ? 'Sorry, it seems like you don’t have access to it.'
              : 'Connect your wallet try to access the file OR discover Fileverse!'}
          </p>
          <>
            {signerAddress ? (
              <BlackButton
                id="wallet-connect-btn"
                btnRef={connectButtonRef}
                customCSS="px-4"
                title="Explore Portal"
                hasLogo={false}
                onClick={() => navigate(`/contracts`)}
              />
            ) : (
              <div className="flex gap-[10px]">
                <BlackButton
                  id="wallet-connect-btn"
                  btnRef={connectButtonRef}
                  customCSS="px-4"
                  title="Connect wallet"
                  hasLogo={false}
                  onClick={connectInjectedWallet}
                />
                <BlackButton
                  id="wallet-connect-btn"
                  btnRef={connectButtonRef}
                  customCSS="px-4"
                  title="Social login"
                  hasLogo={false}
                  onClick={loginWithSocials}
                />
              </div>
            )}
          </>
        </div>
      </div>
    )
  }
  if (extension === 'gltf' || extension === 'glb') {
    return <ThreeDViewer url={downloadUrl} />
  }
  if (mimetype === 'application/pdf')
    return (
      <div
        data-cy="pdf-viewer"
        className="h-full flex flex-col justify-center 
    items-center w-full bg-[#323639] bg-opacity-90"
      >
        <PdfViewer url={downloadUrl} />
      </div>
    )

  if (mimetype === 'application/json' || extension === 'fileverse') {
    return (
      <div className="w-full h-full overflow-y-auto">
        <TextFileViewer isEditing={isEditing} url={downloadUrl} />
      </div>
    )
  }

  if (mimetype === 'text/csv')
    return (
      <div className="w-full h-full overflow-hidden border">
        <CSVViewer url={downloadUrl} />
      </div>
    )

  if (extension === 'xlsx')
    return (
      <div data-cy="xlsx-viewer">
        {' '}
        <XLSXViewer url={downloadUrl} />{' '}
      </div>
    )

  if (mimetype === 'text/plain') {
    return <PlainTextViewer url={downloadUrl} />
  }

  if (mimetype?.includes('audio'))
    return (
      <div
        data-cy="audio-viewer"
        className="w-full h-full bg-[#323639] bg-opacity-90"
      >
        <AudioPlayer url={downloadUrl} mimetype={mimetype} />
      </div>
    )

  if (mimetype?.includes('image'))
    return <ImageViewer url={downloadUrl} fileName={fileName} />

  if (mimetype?.includes('video'))
    return (
      <div
        data-cy="video-viewer"
        className="w-full h-full p-4 flex overflow-hidden justify-center items-center bg-[#323639] bg-opacity-90"
      >
        <VideoPlayer url={downloadUrl} mimetype={mimetype} />
      </div>
    )

  return (
    <DefaultFileView
      fileExt={extension}
      fileName={fileName}
      downloadUrl={downloadUrl}
    />
  )
}
