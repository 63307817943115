export enum ContractPermissionEnum {
  OWNER = 'OWNER',
  COLLABORATOR = 'COLLABORATOR',
  MEMBER = 'MEMBER',
  VISITOR = 'VISITOR',
}

export enum AddCollaboratorStatus {
  PENDING,
  RESOLVING,
  IDLE,
  ADDED,
}
