import React from 'react'
import { PrivyHelperContext } from '../providers/PrivyHelperContext'
export const usePrivyHelper = () => {
  const context = React.useContext(PrivyHelperContext)
  if (!context) {
    throw new Error(
      'usePublicPortalContext must be used within a PublicPortalProvider'
    )
  }
  return context
}
