import React from 'react'
import { useNavigate } from 'react-router-dom'
import Logo from '../../assets/mainLogo.png'
import cn from 'classnames'

const LogoSection = () => {
  const navigate = useNavigate()
  const isOnCreatePage = window.location.hash.includes('create')
  if (!isOnCreatePage) return null
  return (
    <div className="flex items-center p-4 lg:p-0">
      <div
        onClick={() => navigate(`/`)}
        className="flex min-w-[10rem]  items-center"
      >
        <img
          src={Logo}
          alt="Fileverse"
          className={cn(
            'object-cover mr-2 rounded-full cursor-pointer w-10 h-10'
          )}
        />

        <span
          data-cy="portal-header-name"
          className={cn('cursor-pointer leading-3 text-base font-xx')}
        >
          Fileverse
        </span>
      </div>
    </div>
  )
}

export default LogoSection
