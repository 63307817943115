/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useRef,
  useState,
} from 'react'
import EmojiPicker, { EmojiStyle } from 'emoji-picker-react'
import Spinner from '../Spinner'
import { ISEAPair } from 'gun'
import { IDraft } from '../../types/interface/drafts.interface'
import emojiIcon from '../../assets/emoji_gray.svg'
import grayImageIcon from '../../assets/image_gray.svg'
import { useInvokerContractCredentials } from '../../store/invoker/hooks'
import { useAppDispatch } from '../../store/hooks'
import { useParams, useSearchParams } from 'react-router-dom'
import { uploadSingleFileToIpfs } from '../../store/files/thunks'
import useComponentVisibilty from '../../hooks/useVisibility'
import { useCoverImage } from '../../hooks/useCoverImage'
import { IDpageAsset } from '../../utils/collaboration/rtc'
import { updatePluginMetadata } from '../../utils/collaboration/utils'
import { useGunNodes } from '../../hooks/useGunNode'
import { usePrivyHelper } from '../../hooks/usePrivyHelper'
import { Button } from '../../pages/PublicPortal/components/Button'
import { Avatar, LucideIcon } from '@fileverse/ui'
import coverImage from '../../pages/PublicPortal/assets/Cover.png'
import { ImageIcon, Smile } from 'lucide-react'
import defaultAvatar from '../../pages/PublicPortal/assets/default-avatar.svg'
import { Tooltip } from '@mui/material'

interface WebPageCoverProps {
  cover: string
  previewMode: boolean
  emoji: string | undefined
  authKey: ISEAPair | undefined
  rtcId: string | undefined
  rtcData: IDraft
  webpageName: string
  setCover: Dispatch<SetStateAction<string>>
  setDraftName: Dispatch<SetStateAction<string>>
  settingCover: boolean
  setSettingCover: Dispatch<SetStateAction<boolean>>
  setDraftAssetData: Dispatch<SetStateAction<IDpageAsset>>
  coverIPFSHash?: string | undefined
}

export const WebPageCover = ({
  cover,
  setCover,
  previewMode,
  emoji,
  authKey,
  rtcId,
  rtcData,
  webpageName,
  setDraftAssetData,
  settingCover,
  setSettingCover,
  coverIPFSHash,
  setDraftName,
}: WebPageCoverProps) => {
  const [avatarSrc, setAvatarSrc] = useState<string>('')
  const [urlParams] = useSearchParams()
  const { address: contractAddress } = useParams()
  const chainId = parseInt(urlParams.get('chainId') || '')
  const walletAddress = usePrivyHelper().walletAddress
  const { getAuthenticatedPluginMetadataNode } = useGunNodes()
  const invoker = walletAddress as string
  const [coverHover, setCoverHover] = useState<boolean>(false)
  const credential = useInvokerContractCredentials(
    invoker,
    contractAddress as string
  )
  const {
    ref: emojiSelector,
    isComponentVisible: selectingEmoji,
    setIsComponentVisible: setSelectingEmoji,
  } = useComponentVisibilty(false)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const dispatch = useAppDispatch()

  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleImageInput = async (event: ChangeEvent<HTMLInputElement>) => {
    const imageFile = event.target.files?.[0]
    const displayUrl = URL.createObjectURL(event.target.files?.[0]!)
    setCover(displayUrl)

    if (imageFile && imageFile.type.startsWith('image/')) {
      setSettingCover(true)
      if (credential && contractAddress && rtcId) {
        const uploadedCoverIPFSHash = await dispatch(
          uploadSingleFileToIpfs({
            credential,
            file: imageFile,
            fileName: `${webpageName} cover`,
            contractAddress,
            invoker,
            chainId,
          })
        ).unwrap()
        setDraftAssetData((prev) => {
          return { ...prev, coverIPFSHash: uploadedCoverIPFSHash }
        })
        updatePluginMetadata(
          { coverIPFSHash: uploadedCoverIPFSHash },
          getAuthenticatedPluginMetadataNode(rtcId as string)
        )
        setSettingCover(false)
      }
    }
  }

  useCoverImage({
    ipfsHash: coverIPFSHash,
    setCoverImage: setCover,
  })

  // useEffect(() => {
  // const delayDebounceFn = setTimeout(() => {
  //   if (contractAddress && rtcId)
  //     updateDpagesDraftMetadata({
  //       updatedData: {
  //         name: webpageName,
  //       },
  //       authKey,
  //       contractAddress,
  //       rtcId,
  //       rtcData: rtcData!,
  //     })
  // }, 3000)

  // return () => clearTimeout(delayDebounceFn)
  // }, [webpageName])

  return (
    <>
      <div
        className="w-full flex flex-col justify-start md:justify-center items-center mb-10 relative"
        onMouseEnter={() => setCoverHover(true)}
        onMouseLeave={() => setCoverHover(false)}
      >
        {settingCover && (
          <div className="absolute inset-0 color-utility-overlay flex justify-center items-center">
            <LucideIcon
              name="LoaderCircle"
              size={'lg'}
              className="animate-spin"
              fill="transparent"
              stroke={'white'}
            />
          </div>
        )}
        <img
          src={cover || coverImage}
          alt="cover"
          id="cover"
          className="w-screen h-[350px] object-cover"
        />

        {cover && (
          <div className="w-full min-w-[50%] pr-14 md:pr-0 max-w-5xl pb-2 absolute bottom-0 z-20">
            {coverHover && !previewMode && (
              <div className="flex flex-col items-end gap-2">
                {coverIPFSHash && !settingCover && (
                  <>
                    <Button
                      toggleLeftIcon={true}
                      leftIcon="Trash"
                      className="text-body-sm-bold color-text-inverse w-fit"
                      size={'sm'}
                      onClick={() => setCover('')}
                    >
                      Remove
                    </Button>
                    <Tooltip
                      classes={{
                        tooltip: 'bg-black text-white',
                        arrow: 'text-black',
                      }}
                      arrow
                      placement="bottom"
                      title="1500px x 500px recommended"
                    >
                      <Button
                        toggleLeftIcon={true}
                        leftIcon="Camera"
                        className="text-body-sm-bold color-text-inverse min-w-[157px]"
                        size={'sm'}
                        onClick={handleImageClick}
                      >
                        Edit cover image
                      </Button>
                    </Tooltip>
                  </>
                )}
              </div>
            )}
          </div>
        )}
        {selectingEmoji && (
          <div className="w-full min-w-[50%] mx-4 max-w-5xl absolute bottom-0 -mb-12">
            <div
              className="z-[99] w-fit absolute top-1/2 left-[5%]"
              ref={emojiSelector}
            >
              <EmojiPicker
                emojiStyle={EmojiStyle.NATIVE}
                onEmojiClick={(emojiData) => {
                  if (contractAddress && rtcId) {
                    setDraftAssetData((prev) => {
                      return { ...prev, emoji: emojiData.emoji }
                    })
                    updatePluginMetadata(
                      { emoji: emojiData.emoji },
                      getAuthenticatedPluginMetadataNode(rtcId)
                    )
                    setSelectingEmoji(false)
                  }
                }}
              />
            </div>
          </div>
        )}
        {emoji ? (
          <div className="w-full min-w-[50%] mx-4 max-w-5xl z-90 absolute bottom-0 -mb-12">
            <div
              onClick={() => {
                setSelectingEmoji(true)
                setDraftAssetData((prev) => {
                  return { ...prev, emoji: '' }
                })
              }}
              className="px-12 lg:pl-14 w-fit bg-transparent text-[78px] cursor-pointer"
            >
              {emoji}
            </div>
          </div>
        ) : (
          <div className="w-full min-w-[50%] mx-4 max-w-5xl z-90 absolute bottom-0 -mb-12">
            <div className="px-12 lg:pl-14 w-fit bg-transparent text-[78px] cursor-pointer">
              <Avatar
                src={avatarSrc || defaultAvatar}
                size={'3xl'}
                bordered={'border'}
                className=""
                onClick={() => setSelectingEmoji(true)}
              />
            </div>
          </div>
        )}
      </div>
      <div
        id="header"
        className="flex flex-col justify-start items-start gap-4 w-full min-w-[50%] mx-4 max-w-5xl text-[#D1D1D1]"
      >
        <div className="pl-12 lg:pl-14 flex justify-start items-center gap-2">
          {!emoji && !previewMode && (
            <Button
              variant="ghost"
              className="gap-2 text-color-secondary px-0 bg-transparent justify-start w-fit"
              size="sm"
              onClick={() => setSelectingEmoji(true)}
            >
              <Smile size={16} />
              Add Icon
            </Button>
          )}
          <div
            className="flex items-center justify-center gap-2 cursor-pointer"
            onClick={handleImageClick}
          >
            {!cover && !previewMode && (
              <Button
                variant="ghost"
                className="gap-2 text-color-secondary px-0 bg-transparent justify-start w-fit"
                size="sm"
                disabled={settingCover}
              >
                <ImageIcon size={16} />
                {settingCover ? 'Adding Cover' : 'Add Cover'}
              </Button>
            )}
            <input
              type="file"
              accept="image/*"
              ref={fileInputRef}
              onChange={handleImageInput}
              className="hidden"
            />
          </div>
        </div>
      </div>
      <div className="w-full min-w-[50%] mx-4 max-w-5xl">
        <input
          disabled={previewMode}
          type="text"
          placeholder="Untitled"
          className="px-12 lg:pl-14 text-heading-xlg lg:!text-heading-2xlg w-full bg-transparent"
          value={webpageName || ''}
          onChange={(e) => {
            setDraftName(e.target.value)
            setDraftAssetData((prev) => {
              return { ...prev, name: e.target.value }
            })
            updatePluginMetadata(
              { name: e.target.value },
              getAuthenticatedPluginMetadataNode(rtcId as string)
            )
          }}
        />
      </div>
    </>
  )
}
