/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import ActionButton from './common/ActionButton'
import { instantiatePortalContract } from '../utils/instantiateContract'
import { useAccount } from 'wagmi'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { JsonRpcSigner, Contract, Signer } from 'ethers'
import { useAppDispatch } from '../store/hooks'
import { handleTransaction } from '../store/contract/thunks'
import sendNotifcation from '../utils/notification'
import portalAbi from '../contract/portalAbi.json'
import { generateKeyPairs } from '../utils/keys'
import { updateInvokerCredentialReducer } from '../store/invoker/reducer'
import { useInvokerCredentialsAndKeys } from '../store/invoker/hooks'
import { useContract } from '../store/contract/hooks'
import { getCollaboratorKeys } from '../utils/contract'
import { generateInvokerCredentials } from '../utils/portal'
import { generateSHA256Hash } from '../utils/libCrypto/generation'
import { requestServerKeys } from '../store/keySharing/thunks'
import { getContractKeySharingKeys } from '../store/keySharing/hooks'
import WarningPopup from './Popup/WarningPopup'
import useComponentVisibilty from '../hooks/useVisibility'
import { addInvokerServerKeysThunk } from '../store/invoker/thunks'
import { useEthersSigner } from '../hooks/clientToProvider'
import { usePrivyHelper } from '../hooks/usePrivyHelper'

enum RecoveryStatus {
  IDLE,
  ADD_CREDIENTIAL,
  REMOVE_CREDENTIAL,
  ADDING_KEY,
  VERIFYING_KEY,
}

enum ResetOption {
  DEFAULT,
  ENTIRE_PORTAL,
}

const RecoverCredentialPopup = () => {
  const urlParams = useParams()
  const contractAddress = urlParams.address as string

  const { chain: connectedNetwork } = useAccount()
  const walletAddress = usePrivyHelper().walletAddress
  const [searchParams] = useSearchParams()
  const chainId = parseInt(searchParams.get('chainId') || '')
  const dispatch = useAppDispatch()
  const contractSigner = useEthersSigner({ chainId })
  const [recoveryStatus, setRecoveryStatus] = useState<RecoveryStatus>(
    RecoveryStatus.IDLE
  )
  const navigate = useNavigate()
  const portalInfo = useContract(contractAddress as string)
  const keySharingKeys = getContractKeySharingKeys(contractAddress as string)
  const [option, setOption] = useState<ResetOption>(ResetOption.DEFAULT)
  const invokerAddress = walletAddress || ''
  const { portalDecryptionKey, portalEncryptionKey } =
    useInvokerCredentialsAndKeys(invokerAddress, contractAddress)
  const {
    ref: ref,
    isComponentVisible: isShowWarning,
    setIsComponentVisible: setShowWarning,
  } = useComponentVisibilty(false)
  const handleFailure = () => {
    setRecoveryStatus(RecoveryStatus.IDLE)
  }
  const resetPortalKeys = async () => {
    try {
      setRecoveryStatus(RecoveryStatus.ADDING_KEY)
      const credentials = await generateInvokerCredentials()
      const {
        portalEncryptionKey,
        portalDecryptionKey,
        memberEncryptionKey,
        memberDecryptionKey,
        portalGunKey,
        ownerDecryptionKey,
        ownerEncryptionKey,
      } = credentials
      const portalEncryptionKeyVerifier = await generateSHA256Hash(
        portalEncryptionKey
      )
      const portalDecryptionKeyVerifier = await generateSHA256Hash(
        portalDecryptionKey
      )
      const memberEncryptionKeyVerifier = await generateSHA256Hash(
        memberEncryptionKey
      )
      const memberDecryptionKeyVerifier = await generateSHA256Hash(
        memberDecryptionKey
      )
      const portal = instantiatePortalContract(
        contractAddress,
        contractSigner as JsonRpcSigner
      )
      const functionToExecute = portal.interface.encodeFunctionData(
        'updateKeyVerifiers',
        [
          portalEncryptionKeyVerifier.valueOf(),
          portalDecryptionKeyVerifier.valueOf(),
          memberEncryptionKeyVerifier.valueOf(),
          memberDecryptionKeyVerifier.valueOf(),
        ]
      )

      await dispatch(
        handleTransaction({
          eventName: 'UpdatedKeyVerifiers',
          contract: portal,
          connectedNetworkId: connectedNetwork?.id,
          browserSigner: contractSigner as JsonRpcSigner,
          walletAddress: walletAddress as string,
          onTxSuccess: () => null,
          onResolvedTxEvent: async () => {
            await dispatch(
              addInvokerServerKeysThunk({
                invokerAddress,
                contractAddress: contractAddress as string,
                portalEncryptionKey,
                portalDecryptionKey,
                memberEncryptionKey,
                memberDecryptionKey,
                ownerEncryptionKey,
                ownerDecryptionKey,
                portalGunKey,
                agentKey: '',
              })
            )
            navigate(`/${contractAddress}?chainId=${chainId}`)
            sendNotifcation('Portal resetted successfully', '', 'success')
          },
          onTxFailure: (reason: string) => {
            sendNotifcation('Failed to forward transaction', reason, 'danger')
            handleFailure()
          },
          provider: contractSigner as JsonRpcSigner,
          encodedFunction: functionToExecute,
          onTxFailedEvent: (reason: string) => {
            sendNotifcation('Couldnt resolve event', reason, 'danger')
            handleFailure()
          },
          abi: portalAbi,
          handleTransactionFromWallet: async () => {
            const txn = await portal.updateKeyVerifiers(
              portalEncryptionKeyVerifier.valueOf(),
              portalDecryptionKeyVerifier.valueOf(),
              memberEncryptionKeyVerifier.valueOf(),
              memberDecryptionKeyVerifier.valueOf()
            )
            return txn.hash
          },
        })
      ).unwrap()
    } catch (error) {
      sendNotifcation('Failed to reset portal keys', '', 'danger')
      handleFailure()
    }
  }
  const handleNextResetAction = (
    keys: {
      editSecret: string
      viewSecret: string
      editDID: string
      viewDID: string
    },
    resetOption: ResetOption
  ) => {
    if (resetOption === ResetOption.ENTIRE_PORTAL) {
      setTimeout(resetPortalKeys, 20000)
      return
    } else {
      if (!portalDecryptionKey || !portalEncryptionKey) {
        dispatch(
          requestServerKeys({
            invokerAddress,
            credential: {
              contractAddress,
              ...keys,
            },
            contractAddress: contractAddress as string,
            chainId,
            keySharingKeys,
          })
        )
      }
      navigate(`/${contractAddress}?chainId=${chainId}`)
      sendNotifcation('Successfully added new credentials', '', 'success')
    }
  }

  const addNewInvokerCredential = async (resetOption: ResetOption) => {
    try {
      setRecoveryStatus(RecoveryStatus.ADD_CREDIENTIAL)
      const keys = await generateKeyPairs()
      const portal = new Contract(
        contractAddress,
        portalAbi,
        contractSigner as Signer
      )
      const functionToExecute = portal.interface.encodeFunctionData(
        'registerCollaboratorKeys',
        [keys.viewDID, keys.editDID]
      )
      await dispatch(
        handleTransaction({
          eventName: 'RegisteredCollaboratorKeys',
          contract: portal,
          connectedNetworkId: connectedNetwork?.id,
          browserSigner: contractSigner as JsonRpcSigner,
          walletAddress: walletAddress as string,
          onTxSuccess: () => null,
          onResolvedTxEvent: async () => {
            dispatch(
              updateInvokerCredentialReducer({
                credential: {
                  contractAddress,
                  ...keys,
                },
                invokerAddress: walletAddress as string,
              })
            )
            handleNextResetAction(keys, resetOption)
          },
          onTxFailure: (reason: string) => {
            handleFailure()
            sendNotifcation('Failed to forward transaction', reason, 'danger')
          },
          provider: contractSigner as JsonRpcSigner,
          encodedFunction: functionToExecute,
          onTxFailedEvent: (reason: string) => {
            handleFailure()
            sendNotifcation('Couldnt resolve event', reason, 'danger')
          },
          abi: portalAbi,
          handleTransactionFromWallet: async () => {
            const registerResult = await portal.registerCollaboratorKeys(
              keys.viewDID,
              keys.editDID
            )
            return registerResult.hash
          },
        })
      ).unwrap()
    } catch (error: any) {
      handleFailure()
      sendNotifcation('Failed to add new credentials', error?.message, 'danger')
    }
  }
  const resetPortal = async (resetOption: ResetOption) => {
    try {
      setRecoveryStatus(RecoveryStatus.VERIFYING_KEY)
      const c = await getCollaboratorKeys(
        contractAddress as string,
        invokerAddress
      )
      if (c?.viewDid || c?.editDid) {
        setRecoveryStatus(RecoveryStatus.REMOVE_CREDENTIAL)

        const portal = instantiatePortalContract(
          contractAddress as string,
          contractSigner as JsonRpcSigner
        )
        const functionToExecute = portal.interface.encodeFunctionData(
          'removeCollaboratorKeys'
        )
        await dispatch(
          handleTransaction({
            eventName: 'RemovedCollaboratorKeys',
            contract: portal,
            connectedNetworkId: connectedNetwork?.id,
            browserSigner: contractSigner as JsonRpcSigner,
            walletAddress: walletAddress as string,
            onTxSuccess: () => null,
            onResolvedTxEvent: () =>
              setTimeout(() => addNewInvokerCredential(resetOption), 20000),
            onTxFailure: (reason: string) => {
              handleFailure()
              sendNotifcation('Transaction failed', reason, 'danger')
            },
            provider: contractSigner as JsonRpcSigner,
            encodedFunction: functionToExecute,
            onTxFailedEvent: (reason: string) => {
              handleFailure()
              sendNotifcation(
                'Couldnt resolve the event RemovedCollaboratorKeys',
                reason,
                'danger'
              )
            },
            abi: portalAbi,
            handleTransactionFromWallet: async () => {
              const tx = await portal.removeCollaboratorKeys()
              return tx.hash
            },
          })
        ).unwrap()
      } else {
        addNewInvokerCredential(resetOption)
      }
    } catch (error: any) {
      handleFailure()
      sendNotifcation('Failed to add new credentials', error?.message, 'danger')
    }
  }
  useEffect(() => {
    if (!portalInfo?.collaborators?.includes(invokerAddress)) {
      navigate(`/${contractAddress}/member?chainId=${chainId}`)
      return
    }
  }, [])

  const buttonText = () => {
    if (recoveryStatus === RecoveryStatus.ADDING_KEY) {
      return 'Adding new portal keys'
    } else if (recoveryStatus === RecoveryStatus.REMOVE_CREDENTIAL) {
      return 'Removing auth keys'
    } else if (recoveryStatus === RecoveryStatus.ADD_CREDIENTIAL) {
      return 'Adding new auth keys'
    } else if (recoveryStatus === RecoveryStatus.VERIFYING_KEY) {
      return 'Checking contract for keys'
    } else {
      return 'Reset portal'
    }
  }
  return (
    <div
      className="bg-white h-full w-full p-4 rounded-lg flex 
  flex-col justify-center items-center"
    >
      <div className="mt-4 flex flex-col gap-8 items-center justify-center w-full">
        <h5 className="font-xx text-4xl leading-3">Oops!</h5>
        <p className="text-center w-[570px] text-lg">
          It seems like you lost your portal credentials. You will have{' '}
          <strong>add new credentials</strong> in order to be able to have
          access to this portal.
        </p>
      </div>
      <div className="w-full mt-4 gap-16 flex flex-wrap justify-center">
        <ActionButton
          isDisabled={option === ResetOption.ENTIRE_PORTAL}
          isLoading={
            recoveryStatus !== RecoveryStatus.IDLE &&
            option === ResetOption.DEFAULT
          }
          onclick={() => resetPortal(ResetOption.DEFAULT)}
          title={'Reset only my auth keys'}
          loadingText={buttonText()}
        />
        <ActionButton
          isDisabled={portalInfo?.owner !== invokerAddress}
          isLoading={
            recoveryStatus !== RecoveryStatus.IDLE &&
            option === ResetOption.ENTIRE_PORTAL
          }
          onclick={() => {
            setOption(ResetOption.ENTIRE_PORTAL)
            setShowWarning(true)
          }}
          buttonColor="bg-red text-white"
          title={'Reset the entire portal'}
          loadingText={buttonText()}
        />
      </div>
      <WarningPopup
        action={() => {
          setShowWarning(false)
          resetPortal(ResetOption.ENTIRE_PORTAL)
        }}
        closePopup={() => {
          setOption(ResetOption.DEFAULT)
          setShowWarning(false)
        }}
        isOpen={isShowWarning}
        popRef={ref}
      />
    </div>
  )
}

export default RecoverCredentialPopup
