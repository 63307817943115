import axios from 'axios'
import { Signer } from 'ethers'

export interface IMintHeartParams {
  signer: Signer
  message: string
  signature: string
  startBlock: number
  urlString: string
  rawUrl: string
}

export const mintHeart = async ({
  signer,
  message,
  signature,
  startBlock,
  urlString,
  rawUrl,
}: IMintHeartParams) => {
  const URL = `${process.env.REACT_APP_GATE_URL}/account/mint-heart`
  const invokerAddress = await signer.getAddress()
  const requestBody = {
    message,
    signature,
    startBlock,
    urlString,
    invokerAddress,
    rawUrl,
  }

  const response = await axios.post(URL, requestBody, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return response
}
