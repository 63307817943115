/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMediaQuery } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import classes from './Popup.module.css'
import cn from 'classnames'

interface PopupProps {
  children: JSX.Element
  isOpen: boolean
  width: string
  height?: string
  maxHeight?: string
  margin?: string
  closePopup?: () => void
  noScrollY?: boolean
}

export const Popup = (props: PopupProps) => {
  const popUpElement = useRef<HTMLDivElement>(null)
  const isMediaMax1025px = useMediaQuery('(max-width : 1025px)')
  const handleEscapePress = ({ keyCode }: any) => {
    if (keyCode === 27 && typeof props.closePopup === 'function')
      props.closePopup()
  }
  useEffect(() => {
    document.addEventListener('keydown', handleEscapePress)
    return () => {
      document.removeEventListener('keydown', handleEscapePress)
    }
  })

  return (
    <div
      data-cy="pop-up"
      style={{
        visibility: props.isOpen ? 'visible' : 'hidden',
        opacity: props.isOpen ? '1' : '0',
      }}
      className={cn(
        'flex justify-center items-center h-screen',
        {
          'fixed bottom-0': props.isOpen,
        },
        classes.overlay
      )}
    >
      <div
        ref={popUpElement}
        // className={`${classes.popup} ${!props.noScrollY && 'overflow-y-auto'}`}
        style={{
          width: isMediaMax1025px ? '100%' : props.width,
          height: props.height ? props.height : 'auto',
          margin: props.margin,
        }}
        data-cy="pop-up"
        className={cn({ 'absolute bottom-0': isMediaMax1025px })}
      >
        {props.children}
      </div>
    </div>
  )
}
