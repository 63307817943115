import axios from 'axios'
import { generateToken } from '../utils'
import qs from 'query-string'

interface GetTokensRequest {
  network: string
  contractAddress: string
  credentialEditSecret: string | undefined
  invokerAddress: string
  searchTerm?: string
  chain: number
  tokenAddress?: string
}

const tokensAPI = async ({
  network,
  contractAddress,
  credentialEditSecret,
  invokerAddress,
  searchTerm,
  chain,
  tokenAddress,
}: GetTokensRequest) => {
  const URL = `${process.env.REACT_APP_GATE_URL}/account/tokens` || ''
  let params
  if (searchTerm && network) params = { search: searchTerm, chain: network }

  if (tokenAddress && network) params = { tokenAddress, chain: network }

  if (!searchTerm && !tokenAddress && network) params = { chain: network }

  const token = await generateToken(contractAddress, credentialEditSecret || '')
  const { data } = await axios.get(URL, {
    params,
    paramsSerializer: (param: qs.StringifiableRecord) => {
      return qs.stringify(param)
    },
    headers: {
      Authorization: `Bearer ${token}`,
      invoker: invokerAddress,
      contract: contractAddress,
      chain,
    },
  })
  return data.token
}
export default tokensAPI
