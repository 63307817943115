/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import styles from './index.module.scss'
import { CheckCircle, ChevronDown, X } from 'lucide-react'
import { FILE_ICON_MAP } from '../UploadComponents'
import cn from 'classnames'
import { useTaskQueue, clearTaskQueue } from '../../store/taskQueue'
import {
  QueueStatus,
  TaskQueueType,
  setTaskQueueIsRunning,
} from '../../store/taskQueue/reducer'
import { ellipseFileName, getFileType } from '../../utils/fileUtils'
import { useAppDispatch } from '../../store/hooks'
import { uploadToIPFS } from '../../utils/uploadUtills'

import { addFileTrxCall } from '../../utils/transaction'

import { FileSource, FileTypeEnum } from '../../types/enum/file.enum'
import { logAnalyticsAPI } from '../../api/analytics/log'
import { addFile } from '../../store/files/reducer'
import { IFileMetaData } from '../../types/interface/file.interface'
import { usePortalSignlessMode } from '../../store/contract/hooks'

import type {
  TIPFSResponse,
  IExecutionStatusIndicatorProps,
  IQueueItem,
  IPreparedItem,
} from './types'
import { TaskQueueStatus } from './types'
import {
  ContractInteractionIndicator,
  PendingStatusIndicator,
  UploadingIndicator,
} from './components'
import { generateFileMetadataByType } from '../../utils/files'
import { useServerKeys } from '../../store/invoker/hooks'
import { updatePluginMetadata } from '../../utils/collaboration/utils'
import { useGunNodes } from '../../hooks/useGunNode'
import { useSafeAppStatus } from '../../store/common/hooks'
import { uploadFileToArweave } from '../../utils/irysUtils'
import { isOriginGnosisBlockscout } from '../../utils/isOriginGnosisBlockscout'
import { defaultNetwork } from '../../config/network-config'
import { hasAgent } from '../../utils/agentHandler'
import { usePrivyHelper } from '../../hooks/usePrivyHelper'

const TRX_VERB_MAP = {
  [TaskQueueType.Upload]: 'Uploading',
}

interface ITaskRunnerWidgetProps {
  onCloseQueue: () => void
}

// TODO: Organise Better
export const TaskRunnerWidget = (props: ITaskRunnerWidgetProps) => {
  const { onCloseQueue } = props
  const [collapsed, toggleCollapsed] = useState(false)
  const [currentExecutingItem, setCurrentExecutingItem] = useState(0)
  const [currentUploadItem, setCurrentUploadItem] = useState<number>(0)
  const [contractInteractionRunning, setContractInteractionRunning] =
    useState(false)

  const { type, argsQueue } = useTaskQueue()

  const onCollapseToggle = () => {
    toggleCollapsed((prev) => !prev)
  }
  const dispatch = useAppDispatch()
  const onCurrentUploadItemComplete = useCallback(() => {
    setCurrentUploadItem((prev) => prev + 1)
  }, [])

  const onContractRunnerStatusChange = useCallback((status: boolean) => {
    setContractInteractionRunning(status)
  }, [])

  const totalItems = argsQueue.length

  const queueType = TRX_VERB_MAP[type as TaskQueueType] || ''
  const remainingItems = totalItems - currentExecutingItem

  const currentExecutingItemText = !remainingItems
    ? 'Upload Completed'
    : `${remainingItems}/${totalItems} items remaining`

  const onExecutionComplete = useCallback(() => {
    setCurrentExecutingItem((prev) => prev + 1)
  }, [])

  useEffect(() => {
    if (currentExecutingItem >= totalItems)
      dispatch(setTaskQueueIsRunning(QueueStatus.Idle))
  }, [currentExecutingItem, dispatch, totalItems])

  useEffect(() => {
    // Only Run on unmount
    return () => {
      dispatch(clearTaskQueue())
    }
  }, [dispatch])

  if (!type || !argsQueue.length) return null

  const queueItems = useMemo(
    () =>
      argsQueue.map((item, index) => {
        return (
          <QueueItem
            item={item as File}
            key={index}
            onContractRunnerStatusChange={onContractRunnerStatusChange}
            canBeginExecution={currentUploadItem === index}
            contractInteractionRunning={contractInteractionRunning}
            isCurrentExecutingItem={currentExecutingItem === index}
            onExecutionComplete={onExecutionComplete}
            onCurrentUploadItemComplete={onCurrentUploadItemComplete}
          />
        )
      }),
    [
      argsQueue,
      contractInteractionRunning,
      currentExecutingItem,
      currentUploadItem,
      onContractRunnerStatusChange,
      onCurrentUploadItemComplete,
      onExecutionComplete,
    ]
  )

  return (
    <>
      <div className={styles.transaction_widget_container}>
        <div className={styles.transaction_widget_header}>
          <div className={styles.transaction_details}>
            {!remainingItems ? null : (
              <div className="text-heading-sm color-text-default">
                {queueType}:
              </div>
            )}
            <div className="text-heading-sm-bold color-text-default">
              {currentExecutingItemText}
            </div>
          </div>
          <div className="flex gap-[4px]">
            <ChevronDown
              onClick={onCollapseToggle}
              className={cn(styles.collapse_toggler, {
                [styles.collapsed]: collapsed,
              })}
            />
            <X className="cursor-pointer" onClick={onCloseQueue} />
          </div>
        </div>
        <div
          className={cn(styles.transacton_queue, {
            [styles.collapsed]: collapsed,
          })}
        >
          {queueItems}
        </div>
      </div>
    </>
  )
}

const QueueItem = memo(function QueueItem(props: IQueueItem) {
  const {
    item,
    canBeginExecution,
    onExecutionComplete,
    contractInteractionRunning,
    onCurrentUploadItemComplete,
    onContractRunnerStatusChange,
    isCurrentExecutingItem,
  } = props

  const [prepairedItem, setPrepairedItem] = useState<IPreparedItem | null>(null)

  const [executionStatus, setExecutionStatus] = useState<TaskQueueStatus>(
    TaskQueueStatus.Pending
  )

  const [ipfsResponse, setIpfsResponse] = useState<TIPFSResponse>({
    contentIpfsHash: '',
    metadataIpfsHash: '',
  })

  const [publishedFileId, setPublishedFileId] = useState<string | null>(null)

  const [signlessState, setSignlessState] = useState({
    loading: true,
    isSignless: false,
  })

  const dispatch = useAppDispatch()
  const invoker = usePrivyHelper().walletAddress
  const { extraArgs } = useTaskQueue()
  const { contractAddress = '' } = extraArgs

  const { name } = item
  const isSignless = usePortalSignlessMode(contractAddress as string)

  const serverKeys = useServerKeys(invoker as string, contractAddress as string)

  const preservedArgs = React.useRef(extraArgs)
  const ellipsedName = ellipseFileName(name)
  const fileType = getFileType(name, preservedArgs.current)
  const { getAuthPluginNode } = useGunNodes()
  const isSafeApp = useSafeAppStatus()
  const navTarget = isSafeApp || isOriginGnosisBlockscout ? '_self' : '_blank'

  const handleExecutionStart = useCallback(() => {
    setExecutionStatus(TaskQueueStatus.Uploading)
  }, [])

  useEffect(() => {
    async function computeTrulySignless() {
      setSignlessState({ loading: true, isSignless: false })
      const { contractAddress } = preservedArgs.current
      const _hasAgent = await hasAgent(contractAddress as string)

      setSignlessState({ loading: false, isSignless: _hasAgent && isSignless })
    }
    computeTrulySignless()
  }, [isSignless])

  const handleFileUploads = useCallback(async () => {
    try {
      const { fileType, ...rest } = preservedArgs.current || {}

      const fileArgs: any = { ...rest }

      if (fileArgs.isArweave) {
        const arweaveHash = await uploadFileToArweave(item)
        fileArgs.arweaveHash = arweaveHash
        fileArgs.source = FileSource.ARWEAVE
      }

      const generatedFile = await generateFileMetadataByType(
        invoker as string,
        item,
        fileType as FileTypeEnum,
        serverKeys,
        contractAddress as string,
        { ...fileArgs }
      )
      const { contentIpfsHash, metadataIpfsHash }: TIPFSResponse =
        await uploadToIPFS({
          contractAddress,
          invoker,
          metadataFile: generatedFile.metadataFile,
          contentFile: generatedFile.contentFile,
          fileTags: generatedFile.fileTags,
        })
      setPrepairedItem({
        ...generatedFile,
      })
      setIpfsResponse({ contentIpfsHash, metadataIpfsHash })
      setExecutionStatus(TaskQueueStatus.ContractInteraction)
      onCurrentUploadItemComplete()
    } catch (err) {
      console.log(err)
      throw err
    }
  }, [invoker, item, serverKeys, contractAddress, onCurrentUploadItemComplete])

  const handleContractInteraction = useCallback(
    async (isSignless: boolean) => {
      try {
        const preservedFileArgs = preservedArgs.current || {}
        onContractRunnerStatusChange(true)
        const { contentIpfsHash, metadataIpfsHash }: TIPFSResponse =
          ipfsResponse
        const { metadata, gateIpfsHash = '' } = prepairedItem as IPreparedItem

        const fileId = await addFileTrxCall(
          {
            contractAddress: contractAddress as string,
            metadataIpfsHash,
            contentIpfsHash,
            gateIpfsHash,
            fileType: preservedFileArgs.fileType as FileTypeEnum,
            walletAddress: invoker as string,
          },
          isSignless
        )

        logAnalyticsAPI({
          contract: contractAddress as string,
          invoker: invoker as string,
          data: {
            fileId,
            eventName: 'upload',
            ipfsHash: contentIpfsHash,
            tags: ['fileverse_files'],
          },
          chain: defaultNetwork.chainId,
        }).catch((err) => {
          console.log(err)
        })

        dispatch(
          addFile({
            contractAddress: contractAddress as string,
            fileData: {
              fileId,
              metadataIPFSHash: metadataIpfsHash,
              contentIPFSHash: contentIpfsHash,
              gateIPFSHash: gateIpfsHash,
              version: 0,
              metadata: metadata as IFileMetaData,
              cache: { contentIPFSUrl: '', metadataIPFSUrl: '' },
            },
          })
        )

        setPublishedFileId(fileId.toString())
        setExecutionStatus(TaskQueueStatus.Completed)

        if (preservedFileArgs?.rtcId) {
          await updatePluginMetadata(
            { isPublished: true, fileId },
            getAuthPluginNode(
              serverKeys,
              contractAddress as string,
              preservedFileArgs?.rtcId as string
            )
          )
        }

        onExecutionComplete()
        onContractRunnerStatusChange(false)
      } catch (err) {
        console.log(err)
        onContractRunnerStatusChange(false)
        throw err
      }
    },
    [
      contractAddress,
      dispatch,
      invoker,
      ipfsResponse,
      onContractRunnerStatusChange,
      onExecutionComplete,
      prepairedItem,
      serverKeys.portalGunKey,
    ]
  )

  const onViewFile = () => {
    if (executionStatus !== TaskQueueStatus.Completed || !publishedFileId)
      return
    window.open(
      `${window.origin}${window.location.pathname}#/${contractAddress}/file/${publishedFileId}?chainId=${defaultNetwork.chainId}`,
      navTarget
    )
  }

  const showPublishedLink =
    executionStatus === TaskQueueStatus.Completed && publishedFileId
  return (
    <div className={styles.queue_item}>
      <div className={styles.item_icon_and_name}>
        <div className="rounded color-bg-brand p-1">
          <div className={styles.item_icon}>{FILE_ICON_MAP[fileType]}</div>
        </div>
        <div
          onClick={onViewFile}
          className={cn('color-text-default text-body-sm', styles.item_name, {
            [styles.is_link]: showPublishedLink,
          })}
        >
          {ellipsedName}
        </div>
      </div>
      <div className={styles.item_status}>
        <ExecutionStatusIndicator
          handleFileUploads={handleFileUploads}
          handleContractInteraction={handleContractInteraction}
          status={executionStatus}
          canBeginExecution={canBeginExecution}
          handleExecutionStart={handleExecutionStart}
          contractInteractionRunning={contractInteractionRunning}
          isCurrentExecutingItem={isCurrentExecutingItem}
          signlessState={signlessState}
        />
      </div>
    </div>
  )
})

const ExecutionStatusIndicator = (props: IExecutionStatusIndicatorProps) => {
  const {
    status,
    handleFileUploads,
    canBeginExecution,
    handleExecutionStart,
    handleContractInteraction,
    contractInteractionRunning,
    signlessState,
  } = props

  if (status === TaskQueueStatus.Pending)
    return (
      <PendingStatusIndicator
        canBeginExecution={canBeginExecution}
        handleExecutionStart={handleExecutionStart}
      />
    )
  if (status === TaskQueueStatus.Uploading)
    return (
      <UploadingIndicator
        handleFileUploads={handleFileUploads}
        canBeginExecution={canBeginExecution}
      />
    )

  if (status === TaskQueueStatus.ContractInteraction)
    return (
      <ContractInteractionIndicator
        contractInteractionRunning={contractInteractionRunning}
        handleContractInteraction={handleContractInteraction}
        isCurrentExecutingItem={props.isCurrentExecutingItem}
        signlessState={signlessState}
      />
    )

  if (status === TaskQueueStatus.Completed) return <CheckCircle color="green" />

  return null
}
