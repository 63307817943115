import React from 'react'
import cn from 'classnames'
import { StepContent } from '../StepItem'
import { Onboarding } from '../../../types/enum/onboarding.enum'
import { AccessPortalButton } from '../Buttons/AccessPortalButton'
import { STEPS_MAP } from '../../../constants'

export const AccessPortalStep = ({
  name,
  isComplete,
  deployStatus,
  onGoSignlessClick,
  onSkipSignless,
  signlessComplete,
}: {
  name: string
  isComplete: boolean
  deployStatus: Onboarding
  onGoSignlessClick: () => void
  onSkipSignless: () => void
  signlessComplete: boolean
}) => {
  const isCurrentStep = deployStatus === Onboarding.ACCESS
  return (
    <StepContent title={name} isComplete={isComplete} isCurrent={isCurrentStep}>
      <div className={cn('flex flex-col gap-2 min-h-[70px] items-start mb-2')}>
        <p className={cn('text-body-sm color-text-secondary')}>
          {STEPS_MAP[Onboarding.ACCESS]?.description}
        </p>
        {isCurrentStep && <AccessPortalButton />}
      </div>

      <GoSignlessToggler
        onGoSignlessClick={onGoSignlessClick}
        onGoBackClick={onSkipSignless}
        isActive={deployStatus === Onboarding.SIGNLESS}
        isComplete={signlessComplete}
      />
    </StepContent>
  )
}

export const GoSignlessToggler = ({
  onGoSignlessClick,
  onGoBackClick,
  isActive,
  isComplete,
}: {
  onGoSignlessClick: () => void
  onGoBackClick: () => void
  isActive: boolean
  isComplete?: boolean
}) => {
  const onClick = () => {
    if (isComplete) return
    if (isActive) onGoBackClick()
    else onGoSignlessClick()
  }

  const ctaText = isComplete
    ? ''
    : isActive
    ? 'Go Back'
    : 'Optional: Go Signless Now!'

  return (
    <div
      onClick={onClick}
      className="underline cursor-pointer color-text-secondary text-body-sm-bold mb-4"
    >
      {ctaText}
    </div>
  )
}
