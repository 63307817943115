import React, { forwardRef, useImperativeHandle, useState } from 'react'

import {
  ConnectedWalletSideMenu,
  DisconnectedWalletSideMenu,
} from '../common/MobileSideMenu'
import { usePrivyHelper } from '../../hooks/usePrivyHelper'
import { useNavigate } from 'react-router-dom'
import { Menu } from 'lucide-react'

const CommonMobileSideMenu = forwardRef(
  (
    {
      isCollaborator,
      walletAddress,
    }: {
      isCollaborator: boolean
      walletAddress: string
    },
    ref
  ) => {
    const [sideMenuOpen, setSideMenuOpen] = useState<boolean>(false)

    const navigate = useNavigate()
    const { onDisconnectWallet } = usePrivyHelper()

    const handleDisconnectWallet = () => {
      onDisconnectWallet()
      navigate('/create')
    }

    useImperativeHandle(ref, () => ({
      toggleSidemMenu: () => {
        setSideMenuOpen(!sideMenuOpen)
      },
    }))
    return (
      <>
        <Menu
          size={36}
          className="w-[40px] h-[40px] cursor-pointer"
          onClick={() => setSideMenuOpen(!sideMenuOpen)}
        />
        {walletAddress ? (
          <ConnectedWalletSideMenu
            sideMenu={sideMenuOpen}
            setSideMenu={setSideMenuOpen}
            handleDisconnectWallet={handleDisconnectWallet}
            walletAddress={walletAddress}
            isCollaborator={isCollaborator}
          />
        ) : (
          <DisconnectedWalletSideMenu
            sideMenu={sideMenuOpen}
            setSideMenu={setSideMenuOpen}
          />
        )}
      </>
    )
  }
)

CommonMobileSideMenu.displayName = 'CommonMobileSideMenu'

export default CommonMobileSideMenu
