import { ISEAPair } from 'gun'
import { encryptUsingAESKey, encryptUsingRSAKey } from '../../crypto'
import {
  DataLockedKeyMaterial,
  DataMaterialFileKey,
} from '../../../types/interface/file.interface'
import { FileTypeEnum } from '../../../types/enum/file.enum'

export const createLock = async (
  key: string,
  dataKeyMaterial: {
    fileKey: DataMaterialFileKey
    fileChatKeyPair: ISEAPair
  },
  type?: FileTypeEnum
): Promise<DataLockedKeyMaterial> => {
  let decryptionObjectCiphertext: string
  let encryptedChatKeyPair: string

  if (type === FileTypeEnum.GATED) {
    decryptionObjectCiphertext = encryptUsingAESKey(
      dataKeyMaterial.fileKey,
      key
    )
    encryptedChatKeyPair = encryptUsingAESKey(
      dataKeyMaterial.fileChatKeyPair,
      key
    )
  } else {
    encryptedChatKeyPair = await encryptUsingRSAKey(
      dataKeyMaterial.fileChatKeyPair,
      key
    )
    decryptionObjectCiphertext = await encryptUsingRSAKey(
      dataKeyMaterial.fileKey,
      key
    )
  }

  return {
    lockedFileKey: decryptionObjectCiphertext,
    lockedChatKey: encryptedChatKeyPair,
  }
}
