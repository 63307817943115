/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { memo, useEffect, useState } from 'react'
import { Drawer, DrawerContent, DrawerHeader, DrawerTitle } from '../Drawer'
import { HeartBitSection } from './HeartBitSection'
import { useDialogStore } from './utils'
import { useMediaQuery } from 'usehooks-ts'
import { IPortalFile, ISectionsProps, LinkFileMetadata } from '../../interfaces'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../Dialog'
import { usePublicPortalContext } from '../../../../providers/PublicPortalProvider'
import { useParams, useSearchParams } from 'react-router-dom'
import { getPublicFileUrl } from '../../../../utils/files/filePreviewHandlers'
import Spinner from '../../../../components/Spinner'
import { motion } from 'framer-motion'
import { animationConfig } from '../CardControlWrapper'
import { Icon as DocIcon } from '../Icon'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  Icon,
  LucideIcon,
  TextAreaField,
  cn,
} from '@fileverse/ui'
import { getDocIconName } from '../Card/DocumentCard'
import { NonDeletedExcalidrawElement } from '@fileverse/whiteboard/types/element/types'
import { exportToBlob } from '@fileverse/whiteboard'
import { BinaryFiles } from '@fileverse/whiteboard/types/types'
import { getIPFSAsset } from '../../../../utils/getIPFSAsset'
import { AUDIO_EXTENSIONS, VIDEO_EXTENSIONS } from '../../../../utils/fileUtils'
import { TwitterTweetEmbed } from 'react-twitter-embed'
import coverImage from '../../assets/cover-img.jpg'

const HeartBitDialog = ({
  isOpen,
  onLike,
  file,
}: {
  isOpen: boolean
  onLike: () => void
  file?: IPortalFile
}) => {
  const isDesktop = useMediaQuery('(min-width: 768px)')
  const { resetDialogState, dialogState } = useDialogStore()
  const { itemId } = dialogState
  const { sections } = usePublicPortalContext()
  const { address: contractAddress } = useParams()
  const [intialising, setIntialising] = useState(true)
  const [fileInfo, setFileInfo] = useState<IPortalFile | null>(null)

  useEffect(() => {
    const currentFileInfo =
      file ||
      sections.reduce((acc: any, section: ISectionsProps) => {
        const item = section.files.find((file) => file.id === itemId)
        if (item) return item
        return acc
      }, null)

    if (!currentFileInfo) {
      resetDialogState()
      return
    }
    setFileInfo(currentFileInfo)
    setIntialising(false)
  }, [])

  const [searchParams] = useSearchParams()
  const onClose = (open?: boolean) => {
    if (open) return
    resetDialogState()
  }

  const getTokenId = () => {
    const { type, metadata, id } = fileInfo || {}
    if (id === 'portal') {
      return window.location.href
    }
    if (type === 'note') return `${location.href}&fileId${itemId}`
    if (type === 'link') return metadata?.link

    const chainId = parseInt(searchParams.get('chainId') || '')
    const chainIdQuery = chainId ? `?chainId=${chainId}` : ''
    const filePath = `/${contractAddress}/file/${metadata?.fileId}${chainIdQuery}`
    return `${window.location.origin}/#${filePath}`
  }

  if (intialising || !fileInfo) return null
  if (isDesktop) {
    return (
      <Dialog onOpenChange={onClose} open={isOpen}>
        <DialogContent className="max-w-lg p-[24px]">
          <DialogHeader className="gap-4 items-center">
            <DialogTitle className="w-full flex flex-col justify-center items-center">
              <div
                className=" max-h-[275px] max-w-[412px] min-h-[175px] min-w-[312px] flex justify-center items-center"
                id="emojiReward"
              >
                <ItemPreview item={fileInfo} />
              </div>
              <div className="text-center mt-3 w-full">
                <div className="text-sm font-medium">
                  {fileInfo?.metadata?.name}
                </div>
              </div>
            </DialogTitle>
          </DialogHeader>
          <HeartBitSection tokenId={getTokenId()} onLike={onLike} />
        </DialogContent>
      </Dialog>
    )
  }
  return (
    <Drawer onClose={onClose} open={isOpen}>
      <DrawerContent
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
        }}
        className="w-full px-5"
      >
        <div className="w-full flex justify-center">
          <div className="h-[8px] -mt-2 w-[100px] rounded-full bg-[#E8EBEC] "></div>
        </div>

        <DrawerHeader className="flex w-full flex-col justify-center gap-4 mt-2 items-center">
          <DrawerTitle className="w-full flex relative flex-col justify-center items-center">
            <div
              className=" min-h-[175px] w-[312px] flex justify-center items-center"
              id="emojiReward"
            >
              <ItemPreview item={fileInfo} />
            </div>
            <div className="text-center mt-4 w-full">
              <div className="text-sm font-medium">
                {fileInfo?.metadata?.name}
              </div>
            </div>
          </DrawerTitle>
        </DrawerHeader>
        <div className="pb-6">
          <HeartBitSection tokenId={getTokenId()} onLike={onLike} />
        </div>
      </DrawerContent>
    </Drawer>
  )
}

export default memo(HeartBitDialog)

const ItemPreview = ({ item }: { item: IPortalFile }) => {
  const { type, metadata } = item

  const { name } = metadata || {}

  const extension = metadata?.extension

  switch (type) {
    case 'note': {
      return (
        <div className="w-full h-full">
          <Card
            type={type}
            className={cn('flex flex-col w-full h-full justify-between gap-2')}
          >
            <motion.div
              layout="position"
              className="transition-opacity w-full h-[calc(100%-1rem)]"
              {...animationConfig}
            >
              <CardContent className={cn('h-full rounded-lg w-full p-0')}>
                <TextAreaField
                  className={cn(
                    'w-full h-full bg-transparent focus:outline-none focus:ring-0 focus:shadow-none resize-none border-none shadow-none p-0 text-body-sm color-text-default overflow-hidden hover:overflow-y-auto transition-opacity duration-200 ease-in-out'
                  )}
                  placeholder="Add notes"
                  value={metadata?.textContent}
                  readOnly={true}
                />
              </CardContent>
            </motion.div>
          </Card>
        </div>
      )
    }
    case 'link': {
      return <LinkPreview linkMetadata={item.metadata as LinkFileMetadata} />
    }
    case 'media':
      if (extension === 'json') {
        return <DocumentPreview type={'doc'} title={name as string} />
      }

      return (
        <ImagePreview
          url={metadata?.ipfsHash as string}
          title={name as string}
          extension={extension}
          isIPFS={item.id !== 'portal'}
        />
      )
    case 'pdf':
    case 'xls':
    case 'doc':
    case 'ppt':
    case '3d':
      return <DocumentPreview type={type} title={name as string} />

    default: {
      return (
        <DefaultPreview
          type={type}
          title={name as string}
          ipfsHash={metadata?.ipfsHash}
        />
      )
    }
  }
}

const LinkPreview = ({ linkMetadata }: { linkMetadata: LinkFileMetadata }) => {
  return (
    <div
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
      className="relative w-full h-full"
    >
      {linkMetadata.imageUrl ? (
        <img
          src={linkMetadata.imageUrl}
          alt="Placeholder"
          className="object-cover aspect-video w-full h-full rounded-sm"
          loading="lazy"
        />
      ) : linkMetadata.link ? (
        <TwitterEmbed url={linkMetadata.link} />
      ) : (
        <div
          className={cn(
            'text-center flex flex-col justify-center items-center h-full'
          )}
        >
          Seems like there’s no <br /> preview image.
        </div>
      )}
    </div>
  )
}

const TwitterEmbed = ({ url }: { url: string }) => {
  const extractTweetId = (url: string) => {
    const twitterUrlPattern =
      /https?:\/\/(www\.)?x\.com\/(?:#!\/)?(\w+)\/status(es)?\/(\d+)/
    const match = url.match(twitterUrlPattern)

    if (match && match[4]) {
      return match[4]
    }
    return null
  }

  const tweetId = extractTweetId(url)

  const [isLoading, setIsLoading] = useState(true)
  return (
    <div
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
      className="w-full  h-full"
    >
      {isLoading && (
        <div className={'flex w-full justify-center items-center h-full'}>
          <Spinner height={4} width={4} />
        </div>
      )}
      <TwitterTweetEmbed
        options={{
          cards: 'hidden',
          hideCard: true,
          hideThread: false,
          maxWidth: 4,
          height: 2,
        }}
        onLoad={() => {
          setIsLoading(false)
        }}
        tweetId={tweetId || ''}
      />
    </div>
  )
}

const ImagePreview = ({
  url,
  extension,
  title,
  isIPFS = true,
}: {
  url: string
  extension: string
  title: string
  isIPFS?: boolean
}) => {
  const [imgSrc, setImgSrc] = useState<string | null>(null)
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    async function fetchContent() {
      try {
        if (!url) return
        if (isIPFS) {
          const result = await getPublicFileUrl(url as string)
          setImgSrc(result.downloadUrl)
        } else setImgSrc(url)
      } catch (err) {
        console.error(err)
      } finally {
        setLoading(false)
      }
    }
    fetchContent()
  }, [url])

  if (
    [...AUDIO_EXTENSIONS, ...VIDEO_EXTENSIONS].includes(extension as string)
  ) {
    return <AudioAndVideoPreview title={title} extension={extension} />
  }

  return (
    <div className="flex items-center justify-center w-[100%] h-[100%] max-w-[312px] max-h-[175] rounded-sm overflow-hidden">
      {loading ? (
        <Spinner />
      ) : (
        <img
          className="aspect-auto w-[100%] h-[100%] object-contain"
          src={imgSrc ? imgSrc : coverImage}
        />
      )}
    </div>
  )
}

const DocumentPreview = ({ type, title }: { type: any; title: string }) => {
  return (
    <Card type={type} className="border">
      <div className={cn('flex justify-between h-full w-full gap-2 flex-row')}>
        <CardHeader>
          <div className="flex flex-row justify-between">
            <DocIcon name={getDocIconName(type)} size="sm" />
          </div>
          <CardTitle
            className="line-clamp-5 text-heading-xsm-bold"
            title={title}
          >
            {title}
          </CardTitle>
        </CardHeader>
        <motion.div
          layout="position"
          className={cn('transition-opacity w-full h-full')}
          {...animationConfig}
        >
          <CardContent
            className={cn('rounded-sm color-bg-tertiary p-0 h-full')}
          >
            <CardDescription
              className={cn(
                'text-center flex flex-col justify-center items-center h-full'
              )}
            >
              <DocIcon name={getDocIconName(type)} size="lg" />
            </CardDescription>
          </CardContent>
        </motion.div>
      </div>
    </Card>
  )
}

const DefaultPreview = ({
  type,
  title,
  ipfsHash,
}: {
  type: any
  title: string
  ipfsHash: string
}) => {
  const [imgSrc, setImgSrc] = useState<string | null>(null)

  useEffect(() => {
    ;(async () => {
      try {
        const result = await getPublicFileUrl(ipfsHash)
        const fileData = await (await fetch(result.downloadUrl)).text()
        const file = JSON.parse(fileData)
        if (type === 'whiteboard') {
          if (file.file.appState) {
            const data = await exportToBlob({
              elements: file.file.elements as NonDeletedExcalidrawElement[],
              appState: file.file.appState,
              files: file.file.files as BinaryFiles,
            })
            const url = URL.createObjectURL(data)
            setImgSrc(url)
          }
        }
        if (type === 'dPage') {
          const image = await getIPFSAsset({
            ipfsHash: file.coverIPFSHash,
            options: { responseType: 'blob' },
          })
          const imageURL = URL.createObjectURL(image?.data)
          setImgSrc(imageURL)
        }
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])
  return (
    <Card type={type} className="border">
      <div className={cn('flex justify-between h-full w-full flex-row gap-2')}>
        <CardHeader>
          <div className="flex flex-row justify-between">
            <div className="rounded-sm color-bg-brand p-1">
              <Icon
                name={
                  type === 'dDoc'
                    ? 'FileText'
                    : type === 'dPage'
                    ? 'PanelLeft'
                    : 'PenLine'
                }
                size="sm"
                fill="black"
              />
            </div>
          </div>
          <CardTitle
            className="line-clamp-5 text-heading-xsm-bold"
            title={title}
          >
            {title}
          </CardTitle>
        </CardHeader>
        <motion.div
          layout="position"
          className={cn('transition-opacity bg-red w-full h-full')}
          {...animationConfig}
        >
          <CardContent
            className={cn('rounded-sm color-bg-tertiary p-0 h-full')}
          >
            <div className="relative h-full">
              {imgSrc ? (
                <img
                  src={imgSrc}
                  alt="Placeholder"
                  className="object-cover aspect-video w-full h-full rounded-sm"
                  loading="lazy"
                />
              ) : (
                <CardDescription
                  className={cn(
                    'text-center flex flex-col justify-center items-center h-full'
                  )}
                >
                  Seems like there’s no <br /> preview image.
                </CardDescription>
              )}
            </div>
          </CardContent>
        </motion.div>
      </div>
    </Card>
  )
}

const AudioAndVideoPreview = ({
  title,
  extension,
}: {
  title: string
  extension: string
}) => {
  return (
    <Card className="w-full h-full border">
      <div
        className={cn(
          'flex justify-between h-full w-full',
          'flex-row',
          'gap-2'
        )}
      >
        <CardHeader>
          <div className="flex flex-row justify-between">
            <div className="rounded-sm color-bg-brand p-1">
              <LucideIcon
                name={
                  AUDIO_EXTENSIONS.includes(extension as string)
                    ? 'FileMusic'
                    : 'FileVideo'
                }
                size="sm"
                fill="transparent"
              />
            </div>
          </div>
          <CardTitle
            className="line-clamp-5 text-heading-xsm-bold"
            title={title}
          >
            {title}
          </CardTitle>
        </CardHeader>
        <motion.div
          layout="position"
          className={cn('transition-opacity', 'w-full h-full')}
          {...animationConfig}
        >
          <CardContent
            className={cn('rounded-sm color-bg-tertiary p-0 h-full')}
          >
            <CardDescription
              className={cn(
                'text-center flex flex-col justify-center items-center h-full'
              )}
            >
              <div className="h-full w-full flex flex-col justify-center items-center">
                <div className="rounded-sm color-bg-brand p-1 ">
                  <LucideIcon
                    name={
                      AUDIO_EXTENSIONS.includes(extension as string)
                        ? 'FileMusic'
                        : 'FileVideo'
                    }
                    size="lg"
                    fill="transparent"
                  />
                </div>
              </div>
            </CardDescription>
          </CardContent>
        </motion.div>
      </div>
    </Card>
  )
}
