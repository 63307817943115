import React, { useEffect, useState } from 'react'

const PlainTextViewer = ({ url }: { url: string }) => {
  const [text, setText] = useState<string>('')
  const getData = async () => {
    try {
      const res = await (await fetch(url)).text()
      setText(res)
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    getData()
  })

  return (
    <div className="h-full p-8 overflow-scroll">
      <p data-cy="text-viewer" className=" font-medium text-xl ">
        {text}
      </p>
    </div>
  )
}

export default PlainTextViewer
