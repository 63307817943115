import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useContract } from '../store/contract/hooks'
import { useFileData } from '../store/files/hooks'
import { WebPageCommentSection } from '../components/WebPages/WebPageCommentSection'
import { ISEAPair } from 'gun'
import { IChatMessage } from '../types/interface/message.interface'
import { instantiateSEA } from '../utils/instantiateGun'
import {
  IFileChatKeyPair,
  IFileMetaData,
  INewFile,
} from '../types/interface/file.interface'
import { checkIfOwnerOrCollaborator } from '../utils/checkIfOwnerOrCollaborator'
import { WebPageEditPage } from '../components/WebPages/WebPageEditPage'
import useComponentVisibilty from '../hooks/useVisibility'
// import * as Y from 'yjs'
import { IDraft } from '../types/interface/drafts.interface'
import { useMediaQuery } from '@mui/material'
import WebPageViewerV2 from '../components/WebPages/WebPageViewerV2'
import WebPageViewer from '../components/WebPages/WebPageViewer'
// import cn from 'classnames'
import { OutputData } from '@editorjs/editorjs'
import WebPageProofPublish from '../components/WebPages/WebPageProofPublish'
import { useCoverImage } from '../hooks/useCoverImage'
import useQRCode from '../hooks/useQRCode'

import { useGunNodes } from '../hooks/useGunNode'
import { QRModal } from '../components/Popup/QRModal'
import { WebPageMoreMenu } from '../components/WebPages/WebPageMoreMenu'
import PluginNavbar from '../components/Navbars/PluginNavbar'
import { DocStatus } from '../components/ExcalidrawCanvas/SavingStatusUI'
import { useServerKeys } from '../store/invoker/hooks'
import { updatePluginMetadata } from '../utils/collaboration/utils'
import { JSONContent } from '@tiptap/core'
import { usePrivyHelper } from '../hooks/usePrivyHelper'
import defaultCover from '../pages/PublicPortal/assets/Cover.png'
const SEA = instantiateSEA()

interface FileInfo {
  coverIPFSHash: string
  emoji: string
  editorData: OutputData | JSONContent
  source: string
  webpageName: string
  commentsEnabled: boolean
  indexEnabled: boolean
  rtcId: string
  rtcData: IDraft
  rtcKey: string
  version: number
}

export const PublicWebPage = ({
  downloadUrl,
  chatKey,
}: {
  downloadUrl: string
  chatKey: IFileChatKeyPair
}) => {
  const [moreMenu, setMoreMenu] = useState<boolean>(false)
  const [fileInfo, setFileInfo] = useState<FileInfo>()
  const [proofPublishOpen, setProofPublishOpen] = useState<boolean>(false)
  const [cover, setCover] = useState<string>('')
  const [showComments, setShowComments] = useState<boolean>(false)
  const [idList, setIdList] = useState<string[]>([]) // keep track of chat ids that has been rendered from GunDB - Gun can call a single chat multiple times, this is by design.
  const [userMessages, setUserMessages] = useState<Array<IChatMessage>>([])
  const [commentsEnabled, setCommentsEnabled] = useState<boolean>(false)
  const [commentsAvailable, setCommentsAvailable] = useState({
    available: false,
    count: 0,
  })
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [canceledEdit, setCanceledEdit] = useState<boolean>(false)

  const { address } = useParams()
  const [urlParams] = useSearchParams()
  const chainId = parseInt(urlParams.get('chainId') || '')
  const contract = useContract(address as string)

  const portalName = contract.metadata.name
  const { fileId, address: contractAddress } = useParams()
  const fileData = useFileData(contractAddress as string, fileId)
  const contentIPFSHash = fileData?.contentIPFSHash.split('/')[0]
  const walletAddress = usePrivyHelper().walletAddress
  const serverKeys = useServerKeys(
    walletAddress as string,
    contractAddress as string
  )
  const isCollaborator = checkIfOwnerOrCollaborator(
    contract,
    walletAddress as string,
    serverKeys
  )
  const navigate = useNavigate()
  const dropdownRef = useRef<HTMLDivElement>(null)

  const { getAuthenticatedPluginMetadataNode } = useGunNodes()
  const {
    isComponentVisible: confirmDeleteModal,
    setIsComponentVisible: setConfirmDeleteModal,
  } = useComponentVisibilty(false)
  const copyFileURL = `${window.origin}${window.location.pathname}#/${contractAddress}/file/${fileId}?chainId=${chainId}`
  const { qrRef, isQrVisible, setIsQrVisible } = useQRCode(false)

  const isMediaMax1025px = useMediaQuery('(max-width : 1025px)')
  // const ydoc = new Y.Doc()
  const {
    getFileChatNode,
    getEditedDpageContentNode,
    getEditedDpageAssetsNode,
  } = useGunNodes()

  const loadChatData = () => {
    try {
      const chatCollection = getFileChatNode(
        chatKey as ISEAPair,
        fileId as string
      )
      chatCollection.map().on(async (serialzedChatData: string, id: string) => {
        if (!idList.includes(id)) {
          const list = idList
          list.push(id)
          setIdList(list)
          const unserializedChatData = JSON.parse(serialzedChatData)
          const decryptedText = await SEA.decrypt(
            unserializedChatData.text,
            chatKey
          )
          setUserMessages((prev: Array<IChatMessage>) => {
            const chat = [
              ...prev,
              {
                by: unserializedChatData.by,
                text: decryptedText,
                createdAt: unserializedChatData.createdAt,
              },
            ].sort((a, b) => a.createdAt - b.createdAt)
            return chat
          })
          if (unserializedChatData)
            setCommentsAvailable({ ...commentsAvailable, available: true })
        }
      })
    } catch (err) {
      console.log(err)
    }
  }

  const getData = async () => {
    try {
      const res = await (await fetch(downloadUrl)).text()
      const data = JSON.parse(res)
      setFileInfo(data)
      loadChatData()
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    setCommentsEnabled(fileInfo?.commentsEnabled || false)
  }, [fileInfo])

  useEffect(() => {
    setCommentsAvailable({ ...commentsAvailable, count: userMessages.length })
  }, [userMessages])

  useEffect(() => {
    try {
      if (isCollaborator) {
        const draftEditAssetNode = getEditedDpageAssetsNode(
          fileInfo?.rtcId as string
        )
        const draftEditContentNode = getEditedDpageContentNode(
          fileInfo?.rtcId as string
        )
        if (canceledEdit && contractAddress && fileInfo) {
          draftEditContentNode?.put(null)
          draftEditAssetNode?.put(null)
          updatePluginMetadata(
            {
              name: fileInfo.webpageName,
              coverIPFSHash: fileInfo.coverIPFSHash,
              emoji: fileInfo.emoji,
              isPublished: true,
              isEdited: false,
              fileId,
            },
            getAuthenticatedPluginMetadataNode(fileInfo?.rtcId)
          )
        }
      }
    } catch (err) {
      console.log(err)
    }
  }, [canceledEdit, isCollaborator])

  useEffect(() => {
    if (confirmDeleteModal) setMoreMenu(true)
  }, [confirmDeleteModal])

  useCoverImage({
    ipfsHash: fileInfo?.coverIPFSHash,
    setCoverImage: setCover,
  })

  const getBackButtonLink = () => {
    if (walletAddress && isCollaborator) {
      return `/${address}/dPages?chainId=${chainId}`
    }
    return `/${address}/member?chainId=${chainId}`
  }

  return (
    <div className="w-full h-full bg-[#ffffff]">
      {!isEditing ? (
        <div className="h-fit overflow-scroll no-scrollbar">
          {moreMenu && (
            <WebPageMoreMenu
              confirmDeleteModal={confirmDeleteModal}
              setConfirmDeleteModal={setConfirmDeleteModal}
              indexEnabled={!!fileInfo?.indexEnabled}
              commentsEnabled={!!fileInfo?.commentsEnabled}
              isDPage={true}
              fileDownloadName={fileData?.metadata.name}
              filePreviewPage={true}
              setIsQrVisible={setIsQrVisible}
              setProofPublishOpen={setProofPublishOpen}
              pluginName="dPage"
              moreMenuRef={dropdownRef}
              isOwner={isCollaborator}
            />
          )}

          <PluginNavbar
            isTitleFieldEnabled={false}
            dropdownRef={dropdownRef}
            onLiveCollaborationTrigger={() => null}
            portalName={portalName}
            pluginTitle={fileData?.metadata.name as string}
            isPreviewMode={false}
            setPreviewMode={() => null}
            backButtonAction={() => navigate(getBackButtonLink())}
            isUserACollaborator={isCollaborator}
            isEditMode={false}
            setEditMode={setIsEditing}
            setIsEditCancelFlag={setCanceledEdit}
            isPublishLoading={false} // fix the name to be isLoading
            isCommentsEnabled={commentsEnabled}
            toggleCommentVisibility={() => setShowComments(!showComments)}
            toggleQrModalVisibility={() => setIsQrVisible(!isQrVisible)}
            isCommentsVisible={showComments}
            toggleMoreMenuVisibility={() => setMoreMenu(!moreMenu)}
            toggleProvenaceModalVisibility={() =>
              setProofPublishOpen(!proofPublishOpen)
            }
            commentCount={commentsAvailable.count}
            publishPlugin={() => null}
            isPublished={true}
            setPluginTitle={() => null}
            pluginTool={<></>}
            docStatus={DocStatus.SAVED}
            isCollaborating={false}
            collaborators={null}
          />
          <div className="w-full h-full pt-0 flex flex-col gap-1 pb-6">
            <WebPageCommentSection
              show={showComments}
              setShow={setShowComments}
              chatKey={chatKey}
              fileData={fileData as INewFile}
              setCommentMetadata={(data) =>
                setCommentsAvailable({ ...commentsAvailable, ...data })
              }
              isViewMode={!isCollaborator}
            />
            <main className="w-full h-full mt-[68px] rounded-[8px] flex flex-col justify-start items-center gap-2">
              <div className="relative w-full flex flex-col justify-center items-center mb-10">
                <img
                  src={cover || defaultCover}
                  alt="cover"
                  id="cover"
                  className="w-full h-[350px] object-cover"
                />
                <div className="w-full min-w-[50%] mx-4 max-w-5xl absolute bottom-0 -mb-12">
                  <div className="pl-12 lg:pl-14 w-fit bg-transparent text-[78px]">
                    {fileInfo?.emoji || '📄'}
                  </div>
                </div>
              </div>
              <div className="w-full min-w-[50%] mx-4 max-w-5xl">
                <p className="pl-12 lg:pl-14 text-heading-xlg lg:!text-heading-2xlg">
                  {fileInfo?.webpageName || 'Untitled'}
                </p>
              </div>
              {fileInfo &&
                (fileInfo.version === 2 ? (
                  <WebPageViewerV2
                    isEditing={isEditing}
                    hasToC={fileInfo?.indexEnabled}
                    editorData={fileInfo?.editorData}
                    // ydoc={ydoc}
                  />
                ) : (
                  <WebPageViewer
                    isMediaMax1025px={isMediaMax1025px}
                    isEditing={isEditing}
                    data={fileInfo.editorData as OutputData}
                  />
                ))}
            </main>
            {/* Provenance Modal */}
            <WebPageProofPublish
              fileData={fileData as INewFile}
              proofPublishOpen={proofPublishOpen}
              setProofPublishOpen={setProofPublishOpen}
            />
            {/* QR Modal */}
            <QRModal
              isQrVisible={isQrVisible}
              copyFileURL={copyFileURL}
              qrRef={qrRef}
              setIsQrVisible={setIsQrVisible}
            />
          </div>
        </div>
      ) : (
        // TODO: refactor props to directly accecpt the fileInfo instead of referencing it properties
        <WebPageEditPage
          fileData={{
            confirmDeleteModal: confirmDeleteModal,
            setConfirmDeleteModal: setConfirmDeleteModal,
            setCanceledEdit: setCanceledEdit,
            setIsEditing: setIsEditing,
            cover,
            emoji: fileInfo?.emoji as string,
            webpageName: fileInfo?.webpageName as string,
            editorData: fileInfo?.editorData as JSONContent,
            commentsEnabled: !!fileInfo?.commentsEnabled,
            indexEnabled: !!fileInfo?.indexEnabled,
            rtcId: fileInfo?.rtcId as string,
            rtcKey: fileInfo?.rtcKey as string,
            rtcData: fileInfo?.rtcData as IDraft,
            portalName,
            metadata: fileData?.metadata as IFileMetaData,
            contentIPFSHash: contentIPFSHash as string,
            version: fileInfo?.version as number,
          }}
        />
      )}
    </div>
  )
}
