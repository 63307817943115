import { useMediaQuery } from '@mui/material'
import React from 'react'
import FilePageDesktopView from '../components/PreviewScreen/FilePageDesktopView'
import FileMobilePage from '../components/PreviewScreen/FilePageMobileView'
import TasksProvider from '../components/Tasks/TasksProvider'

const FilePage = () => {
  const isMediaMax1025px = useMediaQuery('(max-width : 1025px)')
  return (
    <TasksProvider>
      <div className="h-full w-full relative no-scrollbar overflow-x-hidden">
        {isMediaMax1025px ? <FileMobilePage /> : <FilePageDesktopView />}
      </div>
    </TasksProvider>
  )
}

export default FilePage
