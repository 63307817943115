import React, { useEffect } from 'react'
import MainWrapper from '../common/MainWrapper'
import { useMediaMax1025px } from '../../hooks/useMediaQueryHook'
import Logo from '../../assets/mainLogo.svg'
import { Info } from 'lucide-react'
import Button from '../Buttons/Button'
import Google from '../../assets/google-sso.svg'
import { Connector, useAccount, useSwitchChain } from 'wagmi'
// import { useConnectModal } from '@rainbow-me/rainbowkit'
import { formatAddress } from '../../utils/formatAddress'
import { defaultNetwork } from '../../config/network-config'
import { AllowedChainId } from '../../config/wagmi-config'
import sendNotifcation from '../../utils/notification'
import fallbackLogo from '../../assets/mainLogo.svg'
import { useNavigate } from 'react-router-dom'
import { usePrivy } from '@privy-io/react-auth'
import { usePrivyHelper } from '../../hooks/usePrivyHelper'

type MockProps = Array<{
  title: string
  description: string
}>

type ButtonGroupProps = {
  isMediaMax1025px?: boolean
  walletAddress: string | undefined
  connector: Connector | undefined
}

const mocks: MockProps = [
  {
    title: 'Create a Portal',
    description:
      'By creating a Portal, you will deploy your own collaboration smart contract onchain',
  },
  {
    title: 'Invite Collaborators',
    description:
      'Invite your friends, colleagues or community to your Portal. ',
  },
  {
    title: 'Create and Share Content',
    description:
      'Create, store, manage, and share content including decentralised docs, files, whiteboards, pages...',
  },
]

export default function LandingPage() {
  const isMediaMax1025px = useMediaMax1025px()
  const walletAddress = usePrivyHelper().walletAddress as string

  const { chain: connectedNetwork, connector } = useAccount()
  const { connectOrCreateWallet } = usePrivy()

  const navigate = useNavigate()

  const {
    switchChainAsync,
    error,
    isSuccess: isNetworkSwitchSuccessful,
  } = useSwitchChain()

  const isNetworkDefault = connectedNetwork?.id === defaultNetwork.chainId

  const handleOnClick = () => {
    if (!isNetworkDefault && walletAddress) {
      switchChainAsync({
        chainId: defaultNetwork.chainId as AllowedChainId,
      })
    } else if (isNetworkDefault && walletAddress) {
      navigate(`/create`)
    } else {
      connectOrCreateWallet()
    }
  }

  useEffect(() => {
    if (isNetworkSwitchSuccessful) {
      sendNotifcation(
        'Network Switched',
        `You are now connected to the ${connectedNetwork?.name}`,
        'success'
      )
    } else if (error) {
      sendNotifcation('Error', error.message, 'danger')
    }
  }, [])

  const Banner = ({
    isMediaMax1025px,
    mocks,
  }: {
    isMediaMax1025px: boolean
    mocks: MockProps
  }) => (
    <div className="bg-yellow flex-1 order-2 lg:order-1 h-[90vh] w-[90vw] lg:w-full py-8 px-4 rounded-lg lg:rounded-none">
      <div className="flex flex-col items-center justify-center h-full w-full text-center max-w-md mx-auto">
        {/* Heading Title */}
        <h1
          className={`font-medium max-w-md ${
            isMediaMax1025px ? ' text-2xl' : 'text-5xl'
          }`}
        >
          Unlock a new way of ownership
        </h1>
        {/* Description Paragraph */}
        <p
          className={`font-medium mt-[36px] ${
            isMediaMax1025px ? ' text-sm' : 'text-base'
          }`}
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
        <MockList mocks={mocks} />
      </div>
    </div>
  )

  const Login = ({
    isMediaMax1025px,
    walletAddress,
    connector,
  }: ButtonGroupProps) => (
    <div className="bg-black flex-1 order-1 lg:order-2 h-[90vh] w-[90vw] lg:w-full py-8 px-4 text-white rounded-lg lg:rounded-none">
      <div className="flex flex-col items-center justify-center h-full w-full text-center max-w-md mx-auto">
        {/* Logo */}
        <img src={Logo} alt="logo" className="w-[60px] h-[60px]" />
        {/* Heading Title */}
        <h1
          className={`font-medium max-w-md mt-[36px] ${
            isMediaMax1025px ? ' text-xl' : 'text-4xl'
          }`}
        >
          Get Started
        </h1>
        {/* Description Paragraph */}
        <p
          className={`font-medium mt-[36px] ${
            isMediaMax1025px ? ' text-sm' : 'text-base'
          }`}
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
        <ButtonGroup
          {...{
            walletAddress,
            connector,
          }}
        />
      </div>
    </div>
  )

  const ButtonGroup = ({ walletAddress, connector }: ButtonGroupProps) => (
    <div
      id="button-group"
      className="flex flex-col justify-center items-center gap-4 mt-[36px]"
    >
      {/* Connect Wallet */}
      <Button
        variant="default"
        size="lg"
        className="h-[50px]"
        onClick={handleOnClick}
        leftIcon={
          walletAddress && (
            <img
              src={connector?.icon || fallbackLogo}
              alt="connector-icon"
              className="w-[24px] h-[24px] mr-2"
            />
          )
        }
      >
        <div className="flex flex-col">
          {walletAddress
            ? `Continue with ${connector?.name}`
            : 'Connect Wallet'}
          {walletAddress ? (
            <div className="text-xs">
              {connectedNetwork?.name || 'Unsupported'}:{' '}
              {formatAddress(walletAddress)}
            </div>
          ) : null}
        </div>
      </Button>
      {/* Info text */}
      {/* <WhitelistingHelperText /> */}
      <div className="h-[50px] text-base flex items-center">or</div>
      {/* TODO: Privy Integration */}
      {/* SSO Login */}
      <Button
        variant="secondary"
        disabled
        size="lg"
        className="h-[50px]"
        leftIcon={
          <img src={Google} alt="google" className="w-[24px] h-[24px] mr-2" />
        }
      >
        Continue with Google
      </Button>
      <p className="text-[12px]">
        <span className="inline-block align-middle mr-1">
          <Info color="black" size={16} fill="red" />
        </span>
        SSO login is currently unavailable!
      </p>
    </div>
  )

  const MockList = ({ mocks }: { mocks: MockProps }) => (
    <ol className="text-left mt-[36px] lg:mt-[78px] mx-auto space-y-8">
      {mocks.map((mock, index) => (
        <li key={index} className="flex gap-3">
          <div className="w-fit">
            <span className="rounded-full bg-black w-6 h-6 text-white flex justify-center items-center text-sm font-bold">
              {index + 1}
            </span>
          </div>
          <p>{mock.description}</p>
        </li>
      ))}
    </ol>
  )

  return (
    <MainWrapper noFooter={true}>
      <div className="w-full h-full flex flex-col lg:flex-row relative items-center justify-center gap-4 lg:gap-0">
        <Banner {...{ isMediaMax1025px, mocks }} />
        <Login
          {...{
            isMediaMax1025px,
            walletAddress,
            connector,
          }}
        />
      </div>
    </MainWrapper>
  )
}
