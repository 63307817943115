/* eslint-disable @typescript-eslint/no-explicit-any */
import { Node, mergeAttributes } from '@tiptap/core'
import { LinkButtonNodeView } from './LinkButtonNodeView'
import { ReactNodeViewRenderer } from '@tiptap/react'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    LinkButton: {
      /**
       * Toggle a LinkButton
       */
      setLinkButton: (option?: any) => ReturnType
    }
  }
}

export interface LinkButtonOptions {
  HTMLAttributes: Record<string, any>
}

export const LinkButton = Node.create<LinkButtonOptions>({
  name: 'LinkButton',

  group: 'inline',

  inline: true,

  content: 'text*',

  addOptions() {
    return {
      HTMLAttributes: {
        class: '',
      },
    }
  },

  addAttributes() {
    return {
      data: {
        default: null,
      },
    }
  },

  addNodeView() {
    return ReactNodeViewRenderer(LinkButtonNodeView)
  },

  parseHTML() {
    return [
      {
        tag: 'div[link-to-page-node]',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes(HTMLAttributes, { 'link-to-page-node': '' }),
      0,
    ]
  },

  addCommands() {
    return {
      setLinkButton:
        (option) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: {
              data: option,
            },
            content: [],
          })
        },
    }
  },
})
