import React from 'react'
import { Check, Cloud, CloudUpload } from 'lucide-react'

export enum DocStatus {
  SAVING,
  SAVED,
}

export const SavingStatusUI = ({ docStatus }: { docStatus: DocStatus }) => {
  if (docStatus === DocStatus.SAVING) {
    return (
      <div className="flex justify-center items-center flex-1 w-8 h-8 cursor-pointer relative hover:color-button-secondary-hover rounded">
        <CloudUpload size={20} />
      </div>
    )
  }
  return (
    <div className="flex justify-center items-center flex-1 w-8 h-8 cursor-pointer relative hover:color-button-secondary-hover rounded">
      <Cloud size={20} />
      <Check
        size={8}
        className="absolute translate-y-[1px] -translate-x-[1px]"
        strokeWidth={4}
      />
    </div>
  )
}
