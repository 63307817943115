/* eslint-disable @typescript-eslint/no-explicit-any */
import { useParams, useSearchParams } from 'react-router-dom'
import { GunInstance } from '../utils/instantiateGun'
import { ISEAPair } from 'gun'
import { getISEAKeyPair } from '../utils/libCrypto'
import { useServerKeys } from '../store/invoker/hooks'
import { IServerKey } from '../types/interface/invoker.interface'
import { captureException } from '@sentry/react'
import { MissingAuthKeyError } from '../utils/errors'
import { usePrivyHelper } from './usePrivyHelper'

export const useGunNodes = () => {
  const { address: contractAddress } = useParams()
  const [urlParams] = useSearchParams()

  const walletAddress = usePrivyHelper().walletAddress
  const serverKeys = useServerKeys(
    walletAddress as string,
    contractAddress as string
  )

  const getFileChatNode = (chatKey: ISEAPair, fileId: string) => {
    const fileChatNode = GunInstance.getAuthenticatedUserNode(chatKey)
    return fileChatNode.get(`#${contractAddress}/files/${fileId}`)
  }

  const getAuthenticatedDocumentContentNode = (
    documentId: string,
    rtcKey?: ISEAPair
  ) => {
    const documentKey = rtcKey || getISEAKeyPair(urlParams.get('key') as string)

    const contentNode = GunInstance.getAuthenticatedUserNode(documentKey)
    return contentNode.get(`${contractAddress}/document/content/${documentId}`)
  }

  const getAuthenticatedSignlessKeyNode = () => {
    if (!serverKeys?.portalGunKey) {
      throw new Error('cannot authenticate node with empty portalGunKey')
    }
    const authKey = getISEAKeyPair(serverKeys.portalGunKey)
    if (!authKey) return null
    const signlessKeyNode = GunInstance.getAuthenticatedUserNode(authKey)
      .get(`agent/key`)
      .get(contractAddress)
    return signlessKeyNode
  }

  const getEditedDpageContentNode = (dPageId: string) => {
    if (!serverKeys?.portalGunKey) {
      throw new Error('cannot authenticate node with empty portalGunKey')
    }
    const authKey = getISEAKeyPair(serverKeys.portalGunKey)
    if (!authKey) return null
    const draftEditContentNode = GunInstance.getAuthenticatedUserNode(
      authKey
    ).get(`${contractAddress}/dpages/edit/${dPageId}/content`)

    return draftEditContentNode
  }

  const getEditedDpageAssetsNode = (dPageId: string) => {
    if (!serverKeys?.portalGunKey) {
      throw new Error('cannot authenticate node with empty portalGunKey')
    }
    const authKey = getISEAKeyPair(serverKeys.portalGunKey)
    if (!authKey) return null
    const draftEditAssetNode = GunInstance.getAuthenticatedUserNode(
      authKey
    ).get(`${contractAddress}/dpages/edit/${dPageId}/asset`)

    return draftEditAssetNode
  }

  const getDpagesContentNodeV1 = (dPageId: string) => {
    return GunInstance.getGunNode(`${contractAddress}/content/${dPageId}`)
  }
  const getDocumentCursorNodeV1 = (docId: string) => {
    if (!urlParams.get('key')) {
      throw new Error('cannot authenticate node with empty rtcKey')
    }
    const documentKey = getISEAKeyPair(urlParams.get('key') as string)
    const cursorNode = GunInstance.getAuthenticatedUserNode(documentKey).get(
      `${contractAddress}/cursor/${docId}`
    )

    return cursorNode
  }
  const getDocumentContentNodeV1 = (docId: string) => {
    if (!urlParams.get('key')) {
      throw new Error('cannot authenticate node with empty rtcKey')
    }
    const documentKey = getISEAKeyPair(urlParams.get('key') as string)

    return GunInstance.getAuthenticatedUserNode(documentKey).get(
      `${contractAddress}/content/${docId}`
    )
  }

  const getAuthenticatedPluginMetadataNode = (
    pluginId: string,
    authKey?: ISEAPair
  ) => {
    const _authKey = authKey || getISEAKeyPair(serverKeys?.portalGunKey)

    if (!_authKey) {
      captureException(
        new MissingAuthKeyError('Failed to get Authenticated Plugin Node')
      )
      return
    }
    return GunInstance.getAuthenticatedUserNode(_authKey)
      .get(`${contractAddress}/rtc`)
      .get(pluginId)
  }

  const getEditedDdocContentNode = (dDocId: string, rtcKey: ISEAPair) => {
    const authKey = rtcKey
    const draftEditContentNode = GunInstance.getAuthenticatedUserNode(
      authKey
    ).get(`${contractAddress}/ddoc/edit/${dDocId}/content`)

    return draftEditContentNode
  }

  const getEditedDdocAssetNode = (dDocId: string, rtcKey: ISEAPair) => {
    const authKey = rtcKey
    const draftEditContentNode = GunInstance.getAuthenticatedUserNode(
      authKey
    ).get(`${contractAddress}/ddoc/edit/${dDocId}/asset`)

    return draftEditContentNode
  }

  // This is temporary,
  // we will remove this function once we have a better way to authenticate plugin nodes
  // on TaskRunnerWidget
  const getAuthPluginNode = (
    serverKeys: IServerKey,
    contractAddress: string,
    pluginId: string
  ) => {
    const authKey = getISEAKeyPair(serverKeys.portalGunKey)

    return GunInstance.getAuthenticatedUserNode(authKey)
      .get(`${contractAddress}/rtc`)
      .get(pluginId)
  }

  return {
    getFileChatNode,
    getAuthenticatedDocumentContentNode,
    getEditedDpageContentNode,
    getEditedDpageAssetsNode,
    getAuthenticatedSignlessKeyNode,
    getDpagesContentNodeV1,
    getDocumentCursorNodeV1,
    getDocumentContentNodeV1,
    getAuthenticatedPluginMetadataNode,
    getEditedDdocContentNode,
    getEditedDdocAssetNode,
    getAuthPluginNode,
  }
}

export const getMemberKeysNode = (contractAddress: string) => {
  const memberKeysNode = GunInstance.getGunNode('memberKeys')
  const contractMemberKeysNode = memberKeysNode.get(contractAddress)
  return contractMemberKeysNode
}
export const getSignlessKeyNode = (
  authKey: ISEAPair,
  contractAddress: string
) => {
  const node = GunInstance.getAuthenticatedUserNode(authKey)
    .get('agent/key')
    .get(contractAddress)

  return node
}

export const getPluginMetadataNode = (
  authKey: ISEAPair,
  contractAddress: string,
  rtcId: string
) => {
  const node = GunInstance.getAuthenticatedUserNode(authKey)
    .get(`${contractAddress}/rtc`)
    .get(rtcId)
  return node
}

export const getDocumentContentNode = (
  authkey: ISEAPair,
  documentId: string,
  contractAddress: string
) => {
  const contentNode = GunInstance.getAuthenticatedUserNode(authkey).get(
    `${contractAddress}/document/content/${documentId}`
  )
  return contentNode
}
