/* eslint-disable @typescript-eslint/no-explicit-any */
import { convertTypedArrayToString } from '../../string'
import { getPenumbra } from '../getPenumbra'

const encryptFile = async (file: File) => {
  const penumbra = await getPenumbra()
  const stream: any = await file.arrayBuffer()
  const [encrypted] = await penumbra.encrypt(null, {
    stream: new Response(new Uint8Array(stream))
      .body as ReadableStream<Uint8Array>,
    size: file.size,
  })
  const decryptionInfo: any = await penumbra.getDecryptionInfo(encrypted)
  const fileBlob = await penumbra.getBlob(encrypted)
  const encryptedFile = new File([fileBlob], file.name)
  const decryptionObject = {
    key: convertTypedArrayToString(decryptionInfo.key),
    iv: convertTypedArrayToString(decryptionInfo.iv),
    authTag: convertTypedArrayToString(decryptionInfo.authTag),
  }
  return {
    decryptionObject,
    encryptedFile,
  }
}
export default encryptFile
