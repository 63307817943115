/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import {
  cn,
  formatCounter,
  Card,
  CardFooter,
  CardProps,
  Icon,
  ButtonGroup,
  Button,
  LucideIcon,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
} from '@fileverse/ui'
import { getPublicFileUrl } from '../../../../utils/files/filePreviewHandlers'
import { AUDIO_EXTENSIONS, VIDEO_EXTENSIONS } from '../../../../utils/fileUtils'
import { motion } from 'framer-motion'
import { animationConfig } from '../CardControlWrapper'

export const MediaCard = ({
  type,
  size,
  url,
  likeCount,
  classNames,
  canShare = true,
  extension,
  title,
  filePath,
  noFooter,
  onLike,
}: CardProps & {
  extension?: string
  filePath: string
  noFooter?: boolean
  onLike?: (e: any) => void
}) => {
  const [imgSrc, setImgSrc] = useState<string | null>(null)
  const [height, setHeight] = useState<number | null>(null)

  useEffect(() => {
    async function fetchContent() {
      const result = await getPublicFileUrl(url as string)
      setImgSrc(result.downloadUrl)

      const imgHeight = new Image()
      imgHeight.onload = () => {
        setHeight(imgHeight.height)
      }
      imgHeight.src = result.downloadUrl
    }
    fetchContent()
  }, [url])

  if (!imgSrc) return null

  if (
    [...AUDIO_EXTENSIONS, ...VIDEO_EXTENSIONS].includes(extension as string)
  ) {
    return (
      <Card size={size} className={cn('', classNames)}>
        <motion.div
          className="w-full h-full transition-opacity absolute top-0 left-0 p-5"
          layout="position"
          {...animationConfig}
        >
          <div
            className={cn(
              'flex justify-between h-[calc(100%-1rem)] pb-2',
              size !== 'md' ? 'flex-col' : 'flex-row',
              size !== 'sm' && 'gap-2'
            )}
          >
            <CardHeader
              className={cn('', {
                'flex-[0.8]': size === 'md',
              })}
            >
              <div className="flex flex-row justify-between">
                <div className="rounded-sm color-bg-brand p-1">
                  <LucideIcon
                    name={
                      AUDIO_EXTENSIONS.includes(extension as string)
                        ? 'FileMusic'
                        : 'FileVideo'
                    }
                    size="sm"
                    fill="transparent"
                  />
                </div>
                {size === 'sm' ? (
                  <CardDescription>{type}</CardDescription>
                ) : null}
              </div>
              <CardTitle
                className="line-clamp-5 text-heading-xsm-bold"
                title={title}
              >
                {title}
              </CardTitle>
            </CardHeader>
            <motion.div
              layout="position"
              className={cn(
                'transition-opacity',
                size !== 'lg'
                  ? 'max-w-[60%] min-w-[60%]'
                  : 'w-full h-full xl:h-[80%]'
              )}
              {...animationConfig}
            >
              <CardContent
                className={cn('rounded-sm color-bg-tertiary p-0 h-full')}
              >
                {size !== 'sm' ? (
                  <CardDescription
                    className={cn(
                      'text-center flex flex-col justify-center items-center h-full'
                    )}
                  >
                    <div className="h-full w-full flex flex-col justify-center items-center">
                      <div className="rounded-sm color-bg-brand p-1 ">
                        <LucideIcon
                          name={
                            AUDIO_EXTENSIONS.includes(extension as string)
                              ? 'FileMusic'
                              : 'FileVideo'
                          }
                          size="lg"
                          fill="transparent"
                        />
                      </div>
                    </div>
                  </CardDescription>
                ) : null}
              </CardContent>
            </motion.div>
          </div>
          <CardFooter className="justify-between h-4">
            <motion.div
              layout="position"
              className="flex justify-between items-center w-full transition-opacity"
              {...animationConfig}
            >
              <ButtonGroup className="space-x-0 gap-3">
                <Button
                  onClick={onLike}
                  variant="ghost"
                  size="md"
                  className="min-w-fit p-0"
                >
                  <Icon name="Heart" size="sm" fill="black" className="mr-1" />
                  <span className="max-w-fit">
                    {likeCount && likeCount >= 1
                      ? formatCounter(likeCount)
                      : 'Like'}
                  </span>
                </Button>
                {canShare && (
                  <Button
                    onClick={async (e) => {
                      e.stopPropagation()
                      if (!window.navigator.share) return
                      await window.navigator.share({
                        url: `${window.location.origin}/#${filePath}`,
                        title,
                      })
                    }}
                    variant="ghost"
                    size="md"
                    className="min-w-fit p-0 inline-flex lg:hidden"
                  >
                    <Icon
                      name="Share2"
                      size="sm"
                      fill="black"
                      className="mr-1"
                    />
                    Share
                  </Button>
                )}
              </ButtonGroup>
              {size !== 'sm' ? (
                <CardDescription className="text-muted-foreground">
                  {type}
                </CardDescription>
              ) : null}
            </motion.div>
          </CardFooter>
        </motion.div>
      </Card>
    )
  }
  return (
    <Card
      type={type}
      size={size}
      className={cn(
        'flex flex-col justify-between gap-4 p-0 relative w-full h-full overflow-hidden',
        classNames
      )}
    >
      <motion.img
        initial={{ x: 0, y: 0, scale: 1 }}
        animate={{ x: 0, y: 0, scale: 1 }}
        exit={{ x: 0, y: 0, scale: 1 }}
        src={imgSrc}
        alt="Placeholder"
        className={cn(
          'object-center rounded-xl',
          height && height > 300
            ? 'object-cover hover:brightness-90 transition-colors'
            : 'object-scale-down'
        )}
        loading="lazy"
        layout
        transition={{
          type: 'spring',
          damping: 30,
          stiffness: 200,
          duration: 0.1,
        }}
        style={{
          position: 'absolute',
          top: '0%',
          left: '0%',
          transform: `translate(-50%, -50%)`,
          minWidth: '100%',
          minHeight: '100%',
          transition: 'transform 0.2s ease-in-out',
        }}
      />
      {!noFooter && (
        <CardFooter className="justify-between h-4 absolute bottom-0 py-[18px] px-0 group">
          <motion.div layout="position">
            <ButtonGroup className="space-x-0 gap-3 group-hover:bg-black/20 px-5 rounded-se-lg transition-all duration-300">
              <Button
                onClick={onLike}
                variant="ghost"
                size="md"
                className="min-w-fit p-0 text-white hover:text-white"
              >
                <Icon name="Heart" size="sm" fill="white" className="mr-1" />
                <span className="max-w-fit">
                  {likeCount && likeCount >= 1
                    ? formatCounter(likeCount)
                    : 'Like'}
                </span>
              </Button>
              {canShare && (
                <Button
                  onClick={async (e) => {
                    e.stopPropagation()
                    if (!window.navigator.share) return
                    await window.navigator.share({
                      url: `${window.location.origin}/#${filePath}`,
                      title,
                    })
                  }}
                  variant="ghost"
                  size="md"
                  className="min-w-fit p-0 text-white hover:text-white inline-flex lg:hidden"
                >
                  <Icon name="Share2" size="sm" fill="white" className="mr-1" />
                  Share
                </Button>
              )}
            </ButtonGroup>
          </motion.div>
        </CardFooter>
      )}
    </Card>
  )
}
