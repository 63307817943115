/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'

interface ButtonType {
  title: string
  linkTo: string
  icon: any
  iconStyles: string
  openNewTab?: boolean
  isDisable?: boolean
}
const SocialButton = ({
  title,
  icon,
  openNewTab,
  iconStyles,
  linkTo,
  isDisable,
}: ButtonType) => {
  return (
    <button
      onClick={() => {
        if (linkTo && !isDisable) {
          if (openNewTab) window.open(linkTo, '_blank')
          else {
            window.open(linkTo)
          }
        }
      }}
      className={`${
        isDisable
          ? ' bg-gray-300 cursor-not-allowed'
          : 'bg-black cursor-pointer'
      } flex items-center m-2 text-white py-3 px-6 rounded-md`}
    >
      {icon && (
        <img className={`${iconStyles} mr-2`} src={icon} alt="button icon" />
      )}
      {title}
    </button>
  )
}

export default SocialButton
