import React, { ReactNode } from 'react'
import cn from 'classnames'
import { Button, LucideIcon, LucideIconProps } from '@fileverse/ui'
import { useParams } from 'react-router-dom'
import { useContract } from '../store/contract/hooks'
import { useTour } from '@reactour/tour'
import { usePrivyHelper } from '../hooks/usePrivyHelper'

export const PublicPortalTourModal = ({
  setCurrentStep,
  currentStep,
  icon,
  title,
  desc,
}: {
  setCurrentStep: () => void
  currentStep: number
  icon: LucideIconProps['name']
  title: string
  desc: ReactNode
}) => {
  const { address: contractAddress } = useParams()
  const walletAddress = usePrivyHelper().walletAddress
  const contract = useContract(contractAddress as string)
  const isOwner = contract.owner === walletAddress
  const { setIsOpen } = useTour()

  const onNextStepClick = () => {
    if (!isOwner && currentStep === 2) {
      setIsOpen(false)
    } else {
      setCurrentStep()
    }
  }

  const ownerWidth = ['25', '50', '75', '100']
  const collaboratorWidth = ['35', '65', '100']

  const width = isOwner
    ? ownerWidth[currentStep]
    : collaboratorWidth[currentStep]

  return (
    <div
      className={cn(
        'w-[332px] min-h-[328px] h-fit py-2 bg-white shadow flex justify-center items-center rounded-xl'
      )}
    >
      <div className="w-[276px] min-h-[273px] h-fit py-2 flex-col justify-start items-start gap-4 inline-flex">
        <div className="flex justify-start items-center pt-4">
          <LucideIcon name={icon} fill="none" size={'md'} />
        </div>
        <div className="flex-col justify-start items-start gap-2.5 flex">
          <div className="text-heading-md">{title}</div>
          <div className="w-72 text-body-sm color-text-secondary">{desc}</div>
        </div>
        <div className="w-[276px] grow shrink basis-0 flex-col justify-start items-start gap-2 flex">
          <div className="w-10 text-helper-text-sm ">
            {currentStep + 1}
            <span className=" color-text-secondary">/{isOwner ? 4 : 3}</span>
          </div>
          <div className="w-[100%] h-2.5 relative color-bg-secondary rounded-full">
            <div
              className={`w-[${width}%] h-2.5 left-0 top-0 absolute bg-yellow rounded-full border border-yellow`}
            />
          </div>
        </div>
        <Button
          variant="secondary"
          size="sm"
          className="self-end rounded !min-w-[80px]"
          onClick={onNextStepClick}
        >
          {currentStep === 3
            ? 'Customize portal'
            : currentStep === 2 && !isOwner
            ? 'Customize Portal'
            : 'Next'}
        </Button>
      </div>
    </div>
  )
}
