/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react'
import ReactDOM from 'react-dom/client'
import 'react-notifications-component/dist/theme.css'
import './index.css'
import './styles/main.scss'
import App from './App'
// import reportWebVitals from './reportWebVitals'
import 'polyfill-object.fromentries'
import { HashRouter } from 'react-router-dom'
import { HeartBitProvider, SupportedChain } from '@fileverse/heartbit-react'

import { ReactNotifications } from 'react-notifications-component'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import * as Sentry from '@sentry/react'

import IndexedDbProvider from './components/IndexedDbStorage/indexedDbStorageProvider'
import { WagmiProvider } from '@privy-io/wagmi'
import { PrivyProvider } from '@privy-io/react-auth'

import { config } from './config/wagmi-config'
import '@fileverse/ui/dist/index.css'
import { defaultNetwork } from './config/network-config'
import { PrivyHelperProvider } from './providers/PrivyHelperContext'
import { PRIVIY_APP_ID, PRIVY_CONFIG } from './config'

// Filter out the TryCatch integration from Sentry as it conflicts with gunjs timeout polyfills
// Refrence: https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/trycatch/
// TODO: look into how this can be fixed from gun side
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: function (integrations) {
    const filteredIntegrations = integrations.filter((integration) => {
      return integration.name !== 'TryCatch'
    })
    return [...filteredIntegrations, new Sentry.BrowserTracing()]
  },
  tracesSampleRate: 1.0,
  enabled: process.env.NODE_ENV !== 'development',
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const queryClient = new QueryClient()
root.render(
  <HashRouter>
    <IndexedDbProvider>
      <PrivyProvider appId={PRIVIY_APP_ID} config={PRIVY_CONFIG}>
        <QueryClientProvider client={queryClient}>
          <WagmiProvider config={config} reconnectOnMount={false}>
            <PrivyHelperProvider>
              <ReactNotifications />
              <HeartBitProvider
                coreOptions={{
                  chain: defaultNetwork.networkId as SupportedChain,
                }}
              >
                <App />
              </HeartBitProvider>
            </PrivyHelperProvider>
          </WagmiProvider>
        </QueryClientProvider>
      </PrivyProvider>
    </IndexedDbProvider>
  </HashRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
