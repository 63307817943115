/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useEffect } from 'react'
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DropAnimation,
  MeasuringConfiguration,
  MeasuringStrategy,
  MouseSensor,
  TouchSensor,
  pointerWithin,
  defaultDropAnimationSideEffects,
  useSensor,
  useSensors,
  UniqueIdentifier,
  DragMoveEvent,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { Divider } from '@fileverse/ui'
import { CSS } from '@dnd-kit/utilities'

import { PortalSection, PortalSectionOverlay } from './components/PortalSection'
import PortalCover from './components/PortalCover'
import PortalAvatar from './components/PortalAvatar'
import PortalInfo from './components/PortalInfo'
import {
  PublicPortalProvider,
  usePublicPortalContext,
} from '../../providers/PublicPortalProvider'
import { CardItemOverlay } from './components/PortalCardItem'
import PublicPortalNavbar from './components/PublicPortalNavbar'
import { PublicPortalToolbar } from './components/PublicPortalToolbar'
import Footer from './components/Footer'
import PublicPortalDisabledPage from './components/PublicPortalDisabledPage'
import { TourProvider, useTour } from '@reactour/tour'
import publicPortalSteps from '../../utils/tour/publicPortalSteps'
import { useParams, useSearchParams } from 'react-router-dom'
import PublicEmptyState from './components/PublicEmptyState'
import {
  // AnimatePresence,
  motion,
} from 'framer-motion'
import { usePortalMetadata } from '../../store/contract/hooks'
import { AnimatedLoader } from './components/Loader'

const PUBLIC_PORTAL_TOUR_VIEWED_FIELD = 'fileverse_public_portal_tour_viewed'

const measuring: MeasuringConfiguration = {
  droppable: {
    strategy: MeasuringStrategy.Always,
  },
}

const dropAnimation: DropAnimation = {
  duration: 300,
  easing: 'cubic-bezier(0.18, 0.67, 0.6, 1.22)',
  keyframes({ transform }) {
    return [
      { transform: CSS.Transform.toString(transform.initial) },
      {
        transform: CSS.Transform.toString({
          scaleX: 1,
          scaleY: 1,
          x: transform.final.x - 5,
          y: transform.final.y - 5,
        }),
      },
    ]
  },
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0',
      },
    },
  }),
}

const PublicPortalPage = () => {
  const {
    isViewMode,
    sections,
    onSetActiveSection,
    setSections,
    loading: isLoading,
    layoutSwitchEnabled,
  } = usePublicPortalContext()
  const [searchParams] = useSearchParams()
  const { setIsOpen } = useTour()
  const triggerTour = localStorage.getItem(PUBLIC_PORTAL_TOUR_VIEWED_FIELD)
  const tour = searchParams.get('tour') || ''
  const contractAddress = useParams().address as string
  const portalMetadata = usePortalMetadata(contractAddress)

  const isPublicPortalEnabled = portalMetadata?.isPublicPortalEnabled

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        delay: 250,
        distance: 3,
        tolerance: 5,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 500,
        distance: 5,
        tolerance: 0,
      },
    })
  )

  // TODO: Refactor this function
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event
    if (!over || !active) return
    if (active.id === over.id) return

    //Handling Section Drag&Drop
    if (active.id.toString().includes('section')) {
      setSections((prev) => {
        // Find the index of the active and over section
        const activeSectionIndex = sections.findIndex(
          (prev) => prev.id === active.id
        )
        const overSectionIndex = sections.findIndex(
          (prev) => prev.id === over.id
        )
        // Swap the active and over section
        return arrayMove(prev, activeSectionIndex, overSectionIndex)
      })
      return
    }

    // Handling Item Drag&Drop
    if (
      active.id.toString().includes('item') &&
      over.id.toString().includes('section')
    ) {
      // dropped in same section at empty space
      if (active?.data?.current?.currentSectionId === over.id) return
      const activeSectionIndex = sections.findIndex(
        (section) => section.id === active?.data?.current?.currentSectionId
      )
      const overSectionIndex = sections.findIndex(
        (section) => section.id === over.id
      )
      const activeItemIndex = active?.data?.current?.itemPos
      const newItems = [...sections]
      const [removedItem] = newItems[activeSectionIndex].files.splice(
        activeItemIndex,
        1
      )
      newItems[overSectionIndex].files.push(removedItem)
      setSections(newItems)

      return
    }
    if (
      active.id.toString().includes('item') &&
      over.id.toString().includes('item')
    ) {
      // same section
      if (
        active?.data?.current?.currentSectionId ===
        over?.data?.current?.currentSectionId
      ) {
        const activeSectionIndex = sections.findIndex(
          (section) => section.id === active?.data?.current?.currentSectionId
        )
        const activeItemIndex = active?.data?.current?.itemPos
        const overItemIndex = over?.data?.current?.itemPos
        const newItems = [...sections]
        newItems[activeSectionIndex].files = arrayMove(
          newItems[activeSectionIndex].files,
          activeItemIndex,
          overItemIndex
        )
        setSections(newItems)
      } else {
        // different section
        const activeSectionIndex = sections.findIndex(
          (section) => section.id === active?.data?.current?.currentSectionId
        )
        const overSectionIndex = sections.findIndex(
          (section) => section.id === over?.data?.current?.currentSectionId
        )
        const activeItemIndex = active?.data?.current?.itemPos
        const overItemIndex = over?.data?.current?.itemPos
        const newItems = [...sections]
        const [removedItem] = newItems[activeSectionIndex].files.splice(
          activeItemIndex,
          1
        )
        newItems[overSectionIndex].files.splice(overItemIndex, 0, removedItem)
        setSections(newItems)
      }
    }
  }

  const handleDragMove = (event: DragMoveEvent) => {
    const { active, over } = event

    // Handle Items Sorting
    if (
      active.id.toString().includes('item') &&
      over?.id.toString().includes('item') &&
      active &&
      over &&
      active.id !== over.id
    ) {
      // Find the active section and over section
      const activeSection = findValueOfItems(active.id, 'item')
      const overSection = findValueOfItems(over.id, 'item')

      // If the active or over section is not found, return
      if (!activeSection || !overSection) return

      // Find the index of the active and over section
      const activeSectionIndex = sections.findIndex(
        (section) => section.id === active?.data?.current?.currentSectionId
      )
      const overSectionIndex = sections.findIndex(
        (section) => section.id === over?.data?.current?.currentSectionId
      )

      // Find the index of the active and over item
      const activeItemIndex = active?.data?.current?.itemPos
      const overItemIndex = over?.data?.current?.itemPos
      // In the same section
      if (activeSectionIndex === overSectionIndex) {
        const newItems = [...sections]
        newItems[activeSectionIndex].files = arrayMove(
          newItems[activeSectionIndex].files,
          activeItemIndex,
          overItemIndex
        )

        setSections(newItems)
      } else {
        // In different sections
        const newItems = [...sections]
        const [removeditem] = newItems[activeSectionIndex].files.splice(
          activeItemIndex,
          1
        )
        newItems[overSectionIndex].files.splice(overItemIndex, 0, removeditem)
        setSections(newItems)
      }
    }
  }

  const findValueOfItems = (id: UniqueIdentifier | undefined, type: string) => {
    if (type === 'section') {
      return sections.find((item) => item.id === id)
    }
    if (type === 'item') {
      return sections.find((section) =>
        section.files.find((item) => item.id === id)
      )
    }
  }

  useEffect(() => {
    if (isViewMode || isLoading) return
    if (!triggerTour || tour) {
      setIsOpen(true)
      localStorage.setItem(PUBLIC_PORTAL_TOUR_VIEWED_FIELD, 'true')
    }
  }, [triggerTour, isLoading])

  if (isLoading)
    return (
      <div className="w-[100%] h-[100vh] flex-1 items-center content-center">
        <AnimatedLoader
          text={
            isViewMode
              ? 'Loading Public Page...'
              : 'Loading Public Page editor...'
          }
        />
      </div>
    )

  if (!isPublicPortalEnabled && isViewMode) return <PublicPortalDisabledPage />

  return (
    <div className="w-screen h-screen flex flex-col items-center pointer-events-auto pt-[46px]">
      <div className="h-[46px] w-full bg-[#ffffff] fixed top-0 z-20">
        <PublicPortalNavbar />
      </div>
      <div className="w-full lg:p-8 lg:py-0 flex flex-col gap-16 justify-center items-center">
        <div className="w-full h-[320px] relative rounded-xl">
          <PortalCover />
          <div className="absolute -bottom-[11%] w-screen lg:w-[calc(100vw-4rem)] pointer-events-none">
            <div className="w-full lg:w-[80%] mx-auto px-8 lg:px-4">
              <PortalAvatar />
            </div>
          </div>
        </div>
        <PortalInfo />
      </div>
      <div className="w-full flex gap-0 flex-col justify-center items-center pt-6  lg:pt-12">
        <Divider className="w-[calc(100%-2rem)] lg:w-[calc(80%-4rem)]" />
        {sections.length <= 1 && isViewMode && !sections[0]?.files.length ? (
          <PublicEmptyState />
        ) : (
          <div className="w-full lg:w-[80%] h-full px-0 lg:px-4 lg:pt-8 space-y-2">
            <DndContext
              sensors={sensors}
              collisionDetection={pointerWithin}
              onDragEnd={handleDragEnd}
              onDragMove={handleDragMove}
              measuring={measuring}
            >
              <SortableContext
                items={sections.map((section) => section.id)}
                strategy={verticalListSortingStrategy}
                disabled={isViewMode}
              >
                {/* <AnimatePresence mode="popLayout"> */}
                {sections.map((section, index) => (
                  <motion.div
                    key={section.id || index}
                    onClick={() => onSetActiveSection(index)}
                    className="relative select-none"
                    style={{ minHeight: '334px' }}
                    initial={{ scale: 0.8, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    exit={{ scale: 0.8, opacity: 0 }}
                    layout="position"
                    transition={{
                      type: 'spring',
                      damping: 30,
                      stiffness: 200,
                      duration: 0.2,
                    }}
                  >
                    <PortalSection
                      section={section}
                      sectionPos={index}
                      disableSwitching={!layoutSwitchEnabled}
                    />
                  </motion.div>
                ))}
                {/* </AnimatePresence> */}
              </SortableContext>
              <DragOverlay
                adjustScale={false}
                dropAnimation={dropAnimation}
                transition={`transform 0.2s cubic-bezier(0.18, 0.67, 0.6, 1.22)`}
                className="shadow-elevation-4 rounded-xl cursor-grabbing"
              >
                <CardItemOverlay />
                <PortalSectionOverlay />
              </DragOverlay>
            </DndContext>
          </div>
        )}
      </div>
      <PublicPortalToolbar />
      <Footer />
    </div>
  )
}

const PublicPortal = () => (
  <PublicPortalProvider>
    <TourProvider steps={publicPortalSteps}>
      <PublicPortalPage />
    </TourProvider>
  </PublicPortalProvider>
)

export default memo(PublicPortal)
