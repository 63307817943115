/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  Dispatch,
  RefObject,
  SetStateAction,
  createContext,
  useCallback,
  useEffect,
  useMemo,
} from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { useInvokerCredential } from '../../store/invoker/hooks'
import fetchTasks from '../../api/tasks/fetchTasks'
import {
  UseMutateFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { PointRank } from '../../types/enum/pointRank.enum'
import { useContractStorage } from '../../store/contract/hooks'
import useComponentVisibilty from '../../hooks/useVisibility'
import { useAppDispatch } from '../../store/hooks'
import { FETCH_PORTAL_STORAGE, FETCH_TASK_QUERY_KEY } from '../../constants'
import { postCompletedTask as _postCompletedTask } from '../../api/tasks/postCompletedTasks'
import { AxiosResponse } from 'axios'
import sendNotifcation from '../../utils/notification'
import { verifyTaskCompletedStatus } from '../../api/tasks/verifiyTaskCompletedStatus'
import levelUpRank from '../../api/tasks/levelUpRank'
import { storageUsageAPI } from '../../api/claimStorage/claimStorage'
import { updatePortalStorage } from '../../store/contract/reducer'
import { usePrivyHelper } from '../../hooks/usePrivyHelper'

export enum TaskID {
  CREATE_PUBLISH_3_DPAGES = 'CREATE_PUBLISH_3_DPAGES',
  CREATE_PUBLISH_3_DDOCS = 'CREATE_PUBLISH_3_DDOCS',
  OWN_ENS_DOMAIN = 'OWN_ENS_DOMAIN',
  PRIVATE_KEY_DOWNLOAD = 'PRIVATE_KEY_DOWNLOAD',
  PUBLISH_PUBLIC_DDOC = 'PUBLISH_PUBLIC_DDOC',
  PUBLISH_PRIVATE_WHITEBOARD = 'PUBLISH_PRIVATE_WHITEBOARD',
  PUBLISH_PUBLIC_DPAGE = 'PUBLISH_PUBLIC_DPAGE',
  UPLOAD_PRIVATE_FILE = 'UPLOAD_PRIVATE_FILE',
  UPLOAD_PUBLIC_FILE = 'UPLOAD_PUBLIC_FILE',
  CHAT_ON_FILE = 'CHAT_ON_FILE',
  INVITE_PORTAL_COLLABORATOR = 'INVITE_PORTAL_COLLABORATOR',
  TRY_LIVE_COLLABORATION = 'TRY_LIVE_COLLABORATION',
  PARTICIPATE_PORTAL_CHAT = 'PARTICIPATE_PORTAL_CHAT',
  COMMENT_ON_10_FILES = 'COMMENT_ON_10_FILES',
  PUBLISH_10_PUBLIC_WHITEBOARDS = 'PUBLISH_10_PUBLIC_WHITEBOARDS',
  PUBLISH_10_PUBLIC_DDOCS = 'PUBLISH_10_PUBLIC_DDOCS',
  OWN_FARCASTER_HANDLE = 'OWN_FARCASTER_HANDLE',
  OWN_LENS_HANDLE = 'OWN_LENS_HANDLE',
  OWN_SAFE_MULTISIG = 'OWN_SAFE_MULTISIG',
  OWN_GITCOIN_PASSPORT = 'OWN_GITCOIN_PASSPORT',
  SHARE_TWITTER = 'CREATE_TWITTER_POST',
  SHARE_LENSTER = 'CREATE_LENSTER_POST',
  EDIT_PUBLIC_PORTAL = 'EDIT_PUBLIC_PORTAL',
}
export type TaskCateory = 'DISCOVERY' | 'ACHIEVEMENT' | 'ONCHAIN'
export interface ITask {
  taskId: TaskID
  activityType: string
  name: string
  points: number
  type: string
  category: TaskCateory
  completed: boolean
}
export interface ITasksInfo {
  tasks: ITask[]
  rank: PointRank
  totalPoints: number
  collectedPoints: number
  totalUnlockableStorage: number
  unlockedStorage: number
  canLevelUp: boolean
}
export interface ITaskCategory {
  label: TaskCateory
  description: string
  lockedDescription: string
  type: PointRank
  tasks: ITask[]
  icons: TaskID[]
}
export interface ILevelUpData {
  success: boolean
}
export interface ITaskState {
  taskInfo: ITasksInfo | null
  isTasksLoading: boolean
  taskListRef: RefObject<HTMLDivElement>
  setShowTaskList: Dispatch<SetStateAction<boolean>>
  isTaskCategoryCompleted: (category: TaskCateory) => boolean
  isShowTaskList: boolean
  achievementTasks: ITask[]
  taskCategories: ITaskCategory[]
  onChainCredTasks: ITask[]
  discoveryTasks: ITask[]
  isDownloadedKeys: boolean
  levelUpResponse: ILevelUpData | undefined
  levelUp: () => void
  updateTask: (taskId: TaskID, name: string) => void
  verifyTask: UseMutateFunction<
    any,
    unknown,
    {
      taskId: TaskID
      isOnchainTask: boolean
      name: string
    },
    unknown
  >
  markTaskAsDone: UseMutateFunction<
    AxiosResponse<any, any>,
    unknown,
    {
      taskId: TaskID
      name: string
    },
    unknown
  >
  contractStorage: {
    limit: number
    storageUsed: number
    totalStorage: number
  } | null
  isTaskCompleted: (id: TaskID) => boolean | undefined
}
export const TaskContext = createContext<ITaskState | null>(null)

const TasksProvider = ({ children }: { children: JSX.Element }) => {
  const { walletAddress } = usePrivyHelper()
  const { address: contractAddress } = useParams()
  const queryClient = useQueryClient()
  const [searchParams] = useSearchParams()
  const chainId = parseInt(searchParams.get('chainId') || '')
  const contractStorage = useContractStorage(contractAddress as string)
  const chain = parseInt(searchParams.get('chainId') || '')
  const dispatch = useAppDispatch()
  const credential = useInvokerCredential(
    walletAddress as string,
    contractAddress as string
  )
  const getTasks = async (): Promise<ITasksInfo | null> => {
    if (!credential?.editSecret) {
      return null
    }
    const tasks = await fetchTasks({
      contractAddress: contractAddress as string,
      credentialEditSecret: credential?.editSecret,
      chain,
      invokerAddress: walletAddress as string,
    })
    return tasks
  }
  const { data: tasksData, isLoading: isTasksLoading } = useQuery({
    queryKey: [FETCH_TASK_QUERY_KEY, walletAddress],
    queryFn: getTasks,
  })
  const { mutate: markTaskAsDone } = useMutation({
    mutationFn: ({ taskId, name }: { taskId: TaskID; name: string }) => {
      return _postCompletedTask({
        contractAddress: contractAddress as string,
        editSecret: credential?.editSecret,
        chainId,
        invoker: walletAddress as string,
        taskId,
        name,
      })
    },
    onSuccess: (data) => {
      sendNotifcation(
        'Task Completed',
        `You have completed the task "${data?.name}"`,
        'success'
      )
      queryClient.invalidateQueries({
        queryKey: [FETCH_TASK_QUERY_KEY, FETCH_PORTAL_STORAGE],
      })
    },
  })
  const { mutate: verifyTask } = useMutation({
    mutationFn: ({
      taskId,
      isOnchainTask,
      name,
    }: {
      taskId: TaskID
      isOnchainTask: boolean
      name: string
    }) => {
      return verifyTaskCompletedStatus({
        contractAddress: contractAddress as string,
        editSecret: credential?.editSecret,
        chainId,
        invoker: walletAddress as string,
        taskId,
        isOnchainTask,
        name,
      })
    },
    onSuccess: (data) => {
      if (data && data.success) {
        sendNotifcation(
          'Task Completed!',
          `You have completed the task ${data?.name}`,
          'success'
        )
        queryClient.invalidateQueries({
          queryKey: [FETCH_TASK_QUERY_KEY, FETCH_PORTAL_STORAGE],
        })
        return
      }
      if (data?.isOnchainTask) {
        sendNotifcation(
          'Failed to complete task',
          `Wallet doesn't own the needed asset`,
          'danger'
        )
      } else {
        sendNotifcation(
          'Task has not been competed yet',
          `You have not completed the task: ${data?.name}, click on the start button to begin tasks.`,
          'danger'
        )
      }
    },
  })
  const { mutate: _levelUp, data: levelUpResponse } = useMutation({
    mutationFn: async () => {
      const data: ILevelUpData = await levelUpRank({
        contractAddress: contractAddress as string,
        editSecret: credential?.editSecret,
        chain: chainId,
        invoker: walletAddress as string,
      })
      return data
    },
    onSuccess: (data) => {
      if (data && data.success) {
        queryClient.invalidateQueries({
          queryKey: [FETCH_TASK_QUERY_KEY, FETCH_PORTAL_STORAGE],
        })
      }
    },
  })
  const { data, isSuccess } = useQuery({
    queryFn: () => {
      return storageUsageAPI({
        contractAddress: contractAddress as string,
        editSecret: credential?.editSecret,
        invoker: walletAddress as string,
        chain,
      })
    },
    queryKey: [FETCH_PORTAL_STORAGE],
  })

  useEffect(() => {
    if (!isSuccess || !data) return
    const limit = Math.ceil(Number(data.storageLimit) / 1000000)
    const inUse = Math.ceil(Number(data.storageUse) / 1000000)
    const storage = {
      limit: limit,
      storageUsed: inUse,
      totalStorage:
        Math.round(
          (Math.min((inUse / limit) * 100, 100) + Number.EPSILON) * 100
        ) / 100,
      contractAddress: contractAddress as string,
    }
    dispatch(updatePortalStorage({ storage }))
  }, [isSuccess, data])

  const {
    ref: taskListRef,
    isComponentVisible: isShowTaskList,
    setIsComponentVisible: setShowTaskList,
  } = useComponentVisibilty(false)
  const discoveryTasks = useMemo(
    () => tasksData?.tasks.filter((i) => i.category === 'DISCOVERY') || [],
    [tasksData]
  )

  const achievementTasks = useMemo(
    () => tasksData?.tasks.filter((i) => i.category === 'ACHIEVEMENT') || [],
    [tasksData]
  )
  const onChainCredTasks = useMemo(
    () => tasksData?.tasks.filter((i) => i.category === 'ONCHAIN') || [],
    [tasksData]
  )

  const isDownloadedKeys = () => {
    const tasks = discoveryTasks
    const arr = tasks?.filter((i) => i.taskId === TaskID.PRIVATE_KEY_DOWNLOAD)
    if (arr?.length && arr[0].completed) {
      return true
    } else {
      return false
    }
  }
  const isTaskCompleted = (taskId: TaskID) => {
    const tasks = tasksData?.tasks.filter((i) => i.taskId === taskId)
    return tasks && tasks[0]?.completed
  }
  const isTaskCategoryCompleted = useCallback(
    (category: TaskCateory) => {
      let tasks: ITask[]

      switch (category) {
        case 'DISCOVERY': {
          tasks = discoveryTasks
          break
        }
        case 'ACHIEVEMENT': {
          tasks = achievementTasks
          break
        }
        case 'ONCHAIN': {
          tasks = onChainCredTasks
          break
        }
      }
      if (!tasks.length) {
        return false
      }
      for (const task of tasks) {
        if (!task.completed) {
          return false
        }
      }
      return true
    },
    [tasksData]
  )
  const updateTask = (taskId: TaskID, name: string) => {
    if (!isTaskCompleted(taskId)) {
      markTaskAsDone({ taskId, name })
    }
  }
  const levelUp = () => {
    return _levelUp()
  }
  const taskCategories: ITaskCategory[] = [
    {
      label: 'DISCOVERY',
      type: PointRank.PATHFINDER,
      tasks: discoveryTasks,
      description: 'Discover the full powers of your onchain Portal!',
      lockedDescription: '',
      icons: [
        TaskID.PRIVATE_KEY_DOWNLOAD,
        TaskID.UPLOAD_PRIVATE_FILE,
        TaskID.PUBLISH_PUBLIC_DDOC,
        TaskID.CHAT_ON_FILE,
        TaskID.INVITE_PORTAL_COLLABORATOR,
      ],
    },
    {
      label: 'ACHIEVEMENT',
      type: PointRank.OPEN_SOURCERER,
      tasks: achievementTasks,
      lockedDescription: 'Complete Discovery to unlock',
      description:
        'Master your onchain activities and show the world how to collaborate trustlessly!',
      icons: [
        TaskID.SHARE_TWITTER,
        TaskID.TRY_LIVE_COLLABORATION,
        TaskID.CHAT_ON_FILE,
        TaskID.PUBLISH_PUBLIC_DDOC,
        TaskID.SHARE_LENSTER,
      ],
    },
    {
      label: 'ONCHAIN',
      type: PointRank.SUPER_SOURCERER,
      tasks: onChainCredTasks,
      lockedDescription: 'Complete Quests to unlock',
      description:
        'Summon your web3 artifacts! Cast their blockchain glow for all to see!',

      icons: [
        TaskID.OWN_ENS_DOMAIN,
        TaskID.OWN_FARCASTER_HANDLE,
        TaskID.OWN_LENS_HANDLE,
        TaskID.OWN_SAFE_MULTISIG,
        TaskID.OWN_GITCOIN_PASSPORT,
      ],
    },
  ]

  useEffect(() => {
    if (tasksData?.canLevelUp) {
      setShowTaskList(true)
      levelUp()
    }
  }, [tasksData])

  return (
    <TaskContext.Provider
      value={{
        taskInfo: tasksData as ITasksInfo,
        isTasksLoading,
        contractStorage,
        setShowTaskList,
        isTaskCompleted,
        levelUpResponse,
        taskCategories,
        isShowTaskList,
        taskListRef,
        levelUp,
        isTaskCategoryCompleted,
        discoveryTasks,
        achievementTasks,
        onChainCredTasks,
        markTaskAsDone,
        verifyTask,
        isDownloadedKeys: isDownloadedKeys(),
        updateTask,
      }}
    >
      {children}
    </TaskContext.Provider>
  )
}
export default TasksProvider
