import Dexie, { Table } from 'dexie'
import { IInvoker } from '../../types/interface/invoker.interface'
import { IContract } from '../../types/interface/contract.interface'
import { INewFile } from '../../types/interface/file.interface'

export type IContractDbRecord = IContract & {
  files: Record<number, INewFile>
}
export class PortalDB extends Dexie {
  // 'invoker' && 'contracts' is added by dexie when declaring the stores()
  // We just tell the typing system this is the case
  invoker!: Table<IInvoker>
  contracts!: Table<IContractDbRecord>

  constructor() {
    super('portalDB')
    // only list out the database columns that is to be indexed by dexie
    this.version(1).stores({
      invoker: '++id, &address',
      contracts:
        '++id, &contractAddress, chainId, owner, tokenId, agentAddress, enabled_signless',
    })
  }
}

export const db = new PortalDB()
