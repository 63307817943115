import React from 'react'
import { IFileMetaData, INewFile } from '../../types/interface/file.interface'
import { RenderFile } from './RenderFile'
import { FileSource } from '../../types/enum/file.enum'
type T = (fileData: INewFile) => Promise<void>
const FileView = ({
  metadata,
  downloadUrl,
  fileLocked,
  isEditing,
  isCollaborator,
  signerAddress,
  viewTokenGatedFile,
}: {
  metadata?: IFileMetaData
  downloadUrl: string
  fileLocked?: boolean
  isEditing: boolean
  isCollaborator?: boolean
  signerAddress?: string
  viewTokenGatedFile: T | null
}) => {
  const isDPage = metadata?.source === FileSource.DPAGE
  return (
    <div data-cy="preview-screen" className={`h-full w-full`}>
      <RenderFile
        isEditing={isEditing}
        isDPage={isDPage}
        isCollaborator={isCollaborator}
        signerAddress={signerAddress}
        metadata={metadata as IFileMetaData}
        downloadUrl={downloadUrl}
        fileLocked={fileLocked as boolean}
        viewTokenGatedFile={viewTokenGatedFile}
      />
    </div>
  )
}

export default FileView
