import React, { BaseSyntheticEvent, useState } from 'react'
import {
  ButtonType,
  PrivateFileType,
  PrivateFileTypeMap,
  PrivateFileTypes,
} from '../common'
import { RadioInput } from '../../../../RadioInput'
import styles from './index.module.scss'
import { Button } from '../../../../../pages/PublicPortal/components/Button'
import { ButtonGroup } from '@fileverse/ui'

export const SelectPrivateFileTypeStep = ({
  onSwitchToVisibility,
  onContinueClick,
  isLoading,
}: {
  onSwitchToVisibility: (e: BaseSyntheticEvent) => void
  onContinueClick: (val: PrivateFileType) => void
  isLoading: boolean
}) => {
  const [selectedPrivateFileType, setSelectedPrivateFileType] =
    useState<PrivateFileType | null>(null)

  const onPrivateFileTypeChange = (e: BaseSyntheticEvent) => {
    const value = e.currentTarget.value
    setSelectedPrivateFileType(value)
  }

  const onButtonClick = () => {
    if (!selectedPrivateFileType) return
    onContinueClick(selectedPrivateFileType)
  }

  const radioOptions = PrivateFileTypes.map((option) => {
    const { value, labelValue, isDisabled } = PrivateFileTypeMap[option]
    return (
      <RadioInput
        key={value}
        labelValue={labelValue}
        checked={selectedPrivateFileType === option}
        onChange={onPrivateFileTypeChange}
        value={value}
        isDisabled={isDisabled}
      />
    )
  })
  return (
    <div className={styles.visiblity_step_container}>
      <div className={styles.visiblity_step_desc_container}>
        <div className="text-heading-md-bold color-text-default">
          Private Settings
        </div>
        <div className="text-body-sm color-text-secondary">
          Choose who to share the files with
        </div>
      </div>
      <div className={styles.private_options_container}>{radioOptions}</div>
      <ButtonGroup>
        <Button
          onClick={onSwitchToVisibility}
          id={ButtonType.Back}
          variant="secondary"
          className="w-[145px] h-[45px]"
        >
          Back
        </Button>
        <Button
          isLoading={isLoading}
          onClick={onButtonClick}
          id={ButtonType.Continue}
          disabled={!selectedPrivateFileType || isLoading}
          className="w-[145px] h-[45px]"
        >
          Continue
        </Button>
      </ButtonGroup>
    </div>
  )
}
