/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useRef } from 'react'

export default function useQRCode(initialIsVisible: boolean) {
  const [isQrVisible, setIsQrVisible] = useState(initialIsVisible)
  const qrRef = useRef<HTMLDivElement>(null)

  const handleClickOutside = (event: any) => {
    if (qrRef && qrRef.current && !qrRef.current.contains(event.target)) {
      setIsQrVisible(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  return { qrRef, isQrVisible, setIsQrVisible }
}
