/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { BaseSyntheticEvent, useState, memo, useMemo } from 'react'
import { X } from 'lucide-react'
import { Popup } from '../../Popup/PopUp'
import styles from './index.module.scss'
import { FileTypeEnum } from '../../../types/enum/file.enum'
import sendNotifcation from '../../../utils/notification'
import {
  MissingOrInvalidContractCredentials,
  SwitchNetworkRejected,
} from '../../../utils/errors'
import { defaultNetwork } from '../../../config/network-config'
import { validateOrSwitchNetwork } from '../../../utils/ethUtils'
import { useParams } from 'react-router-dom'
import { useInvokerContractCredentials } from '../../../store/invoker/hooks'
import { ICredential } from '../../../types/interface/invoker.interface'
import { useAppDispatch } from '../../../store/hooks'
import { setTaskQueue } from '../../../store/taskQueue'
import { TaskQueueType } from '../../../store/taskQueue/reducer'
import {
  FileListItem,
  PrivateSettingStep,
  SelectVisibilityStep,
  ButtonType,
} from './components'
import { validateFile } from '../../../utils/fileUtils'
import { AllowedChainId } from '../../../config/wagmi-config'
import { usePrivyHelper } from '../../../hooks/usePrivyHelper'

interface IModalProps {
  isOpen: boolean
  onClose: () => void
}

interface IUploadModalProps extends IModalProps {
  selectedFiles: File[]
  fileArgs?: any
}

export const UploadModal = memo(function UploadModal({
  isOpen,
  onClose,
  selectedFiles,
  fileArgs = {},
}: IUploadModalProps) {
  const [showPrivateSettings, togglePrivateSettings] = useState(false)

  const totalItems = selectedFiles.length
  const totalItemText =
    totalItems > 1 ? `${totalItems} items` : `${totalItems} item`

  const [isLoading, setLoading] = useState(false)
  const { address: contractAddress } = useParams()
  const walletAddress = usePrivyHelper().walletAddress

  const contractCredentials = useInvokerContractCredentials(
    walletAddress as string,
    contractAddress as string
  ) as ICredential

  const dispatch = useAppDispatch()

  const validateCredentialsAndChain = async () => {
    if (!contractCredentials)
      throw new MissingOrInvalidContractCredentials(
        'Invoker contract credentials are missing'
      )
    await validateOrSwitchNetwork(defaultNetwork.chainId as AllowedChainId)
  }

  const handleUploadFileEvent = async (
    fileType: FileTypeEnum,
    extraArgs?: any
  ) => {
    try {
      setLoading(true)
      await validateCredentialsAndChain()

      const filesArr: any[] = []
      selectedFiles.forEach((file) => {
        if (!validateFile(file, fileArgs)) filesArr.push(file)
      })

      switch (fileType) {
        case FileTypeEnum.PUBLIC:
        case FileTypeEnum.MEMBERS_PRIVATE:
        case FileTypeEnum.PRIVATE:
          dispatch(
            setTaskQueue({
              argsQueue: [...filesArr],
              type: TaskQueueType.Upload,
              extraArgs: {
                fileType,
                ...fileArgs,
                contractAddress,
              },
            })
          )
          break
        case FileTypeEnum.GATED:
          dispatch(
            setTaskQueue({
              argsQueue: [...filesArr],
              type: TaskQueueType.Upload,
              extraArgs: {
                fileType,
                ...extraArgs,
                ...fileArgs,
                contractAddress,
              },
            })
          )
          break
      }
      onClose()
    } catch (err) {
      if (err instanceof MissingOrInvalidContractCredentials)
        sendNotifcation(
          'Failed to upload file',
          'Credentials not found',
          'danger'
        )
      if (err instanceof SwitchNetworkRejected)
        sendNotifcation(
          'Failed to upload file',
          'Please switch to correct network',
          'danger'
        )
      else {
        sendNotifcation(
          'Failed to upload file',
          'Please try again after sometime',
          'danger'
        )
      }
    } finally {
      setLoading(false)
    }
  }
  const onButtonClick = (event: BaseSyntheticEvent) => {
    const buttonType = event.currentTarget.id
    switch (buttonType) {
      case ButtonType.Private:
        return togglePrivateSettings(true)
      case ButtonType.Back:
        return togglePrivateSettings(false)
      case ButtonType.Public:
        return handleUploadFileEvent(FileTypeEnum.PUBLIC)
      default:
        console.warn('Invalid button type')
        break
    }
  }

  const { fileListItems, isDisabled } = useMemo(() => {
    const fileListItems = selectedFiles.map((file, idx) => {
      const errorText = validateFile(file, fileArgs)
      return (
        <FileListItem
          key={idx}
          file={file}
          fileArgs={fileArgs}
          errorText={errorText}
        />
      )
    })
    // Disable buttons if all files have error text
    const isDisabled = fileListItems.every(
      (elm) => elm.props.errorText !== null
    )

    return { fileListItems, isDisabled }
  }, [fileArgs, selectedFiles])

  const modalChildren = showPrivateSettings ? (
    <PrivateSettingStep
      onSwitchToVisibility={onButtonClick}
      handleUploadFileEvent={handleUploadFileEvent}
      isLoading={isLoading}
    />
  ) : (
    <SelectVisibilityStep
      onButtonClick={onButtonClick}
      isLoading={isLoading}
      isDisabled={isDisabled}
    />
  )

  return (
    <Popup isOpen={isOpen} width="680px">
      <div className={styles.upload_modal_container}>
        <div className={styles.modal_left_panel}>
          <div className={styles.file_list_container}>
            <div className={styles.heading_container}>
              <div className="text-heading-md-bold color-text-default">
                Waiting for Upload
              </div>
              <div className="text-body-md">{totalItemText}</div>
            </div>
            <div className={styles.files_list}>{fileListItems}</div>
          </div>
        </div>
        <div className={styles.modal_right_panel}>
          <div className={styles.close_button} onClick={onClose}>
            <X />
          </div>
          <div className={styles.upload_steps_container}>{modalChildren}</div>
        </div>
      </div>
    </Popup>
  )
})
