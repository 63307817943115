import { createSlice } from '@reduxjs/toolkit'

export enum UploadStatus {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  TXN_PENDING = 'TXN_PENDING',
  ENCRYPTING = 'ENCRYPTING',
  FINISHED_ENCRYPTING = 'FINISHED_ENCRYPTING',
}

const uploadSlice = createSlice({
  name: 'upload',
  initialState: {
    uploadStatus: UploadStatus.INACTIVE,
    uploadProgress: 0,
    transactionHash: '',
  },
  reducers: {
    setUploadActive(state): void {
      state.uploadStatus = UploadStatus.ACTIVE
    },
    setUploadInActive(state): void {
      state.uploadStatus = UploadStatus.INACTIVE
    },
    setUploadEncrypting(state): void {
      state.uploadStatus = UploadStatus.ENCRYPTING
    },
    setFinshedEncrypting(state): void {
      state.uploadStatus = UploadStatus.FINISHED_ENCRYPTING
    },
    setTransactionHash(state, action): void {
      state.transactionHash = action.payload
    },
    setTxnPending(state): void {
      state.uploadStatus = UploadStatus.TXN_PENDING
    },
    updateUploadProgress(state, action): void {
      state.uploadProgress = action.payload
    },
  },
})
export const {
  setUploadActive,
  setTxnPending,
  updateUploadProgress,
  setUploadInActive,
  setUploadEncrypting,
  setFinshedEncrypting,
  setTransactionHash,
} = uploadSlice.actions

export default uploadSlice.reducer
