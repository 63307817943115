/* eslint-disable @typescript-eslint/no-explicit-any */
import { captureException } from '@sentry/react'
import { Interface, JsonRpcProvider } from 'ethers'
import { getEthersProvider } from '../ethUtils'

export const waitForTransactionToBeMined = async (
  hash: string,
  eventName: string,
  provider: JsonRpcProvider,
  contractAbi: any
) => {
  if (!hash) {
    throw new Error('cannot listen for event with undefined hash')
  }
  if (!eventName) {
    throw new Error('cannot listen for event name of undefined')
  }
  const prvider = getEthersProvider()
  const txReceipt = await (await prvider.getTransaction(hash))?.wait()

  if (txReceipt?.logs) {
    const iface = new Interface(contractAbi)
    const logs = txReceipt.logs

    for (let i = 0; i < logs.length; i++) {
      try {
        const decodedData = iface.parseLog(logs[i])
        if (decodedData?.name === eventName) {
          return decodedData.args
        }
      } catch (error) {
        captureException(error)
        console.log(null)
      }
    }
  }
  return null
}
