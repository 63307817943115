/* eslint-disable @typescript-eslint/no-explicit-any */
import Quill from 'quill'
import React, { useEffect, useState } from 'react'
import isNull from 'lodash/isNull'
import isEmpty from 'lodash/isEmpty'

const EditorPreview = ({ fileState }: { fileState: any }) => {
  const [emptyDocument, setEmptyDocument] = useState(false)
  let quill: Quill

  useEffect(() => {
    if (isNull(fileState) || isEmpty(fileState)) {
      setEmptyDocument(true)
    } else {
      quill = new Quill(document.querySelector('#editor') as Element, {
        modules: {
          toolbar: false,
        },
        theme: 'snow',
      })
      quill.setContents(fileState?.file?.ops)
    }
  }, [])

  return (
    <>
      {emptyDocument ? (
        <div className="w-full h-[100%] flex justify-center items-center">
          <h1 className="text-2xl text-gray-300">Empty Document</h1>
        </div>
      ) : (
        <div className="rounded-[8px] h-[100%]">
          <div
            data-cy="collabDoc-viewer"
            className={`flex h-screen w-full pt-4 overflow-scroll no-scrollbar`}
          >
            <div className={`w-[100%] h-screen flex justify-end`}>
              <div className="w-full h-screen p-4 bg-white">
                <div
                  data-cy="collabdoc-preview"
                  id="editor"
                  className="overflow-scroll no-scrollbar  border-none h-full"
                ></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default EditorPreview
