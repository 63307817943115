import React, { BaseSyntheticEvent } from 'react'
import styles from './index.module.scss'
import cn from 'classnames'

interface IRadioInputProps {
  checked: boolean
  onChange: (e: BaseSyntheticEvent) => void
  value: string
  labelValue: string
  isDisabled?: boolean
}

export const RadioInput = (props: IRadioInputProps) => {
  const { checked, onChange, value, labelValue, isDisabled = false } = props

  const onRadioChange = (e: BaseSyntheticEvent) => {
    if (isDisabled) return
    onChange(e)
  }

  return (
    <label
      htmlFor={value}
      className={cn(styles.radio_label, { [styles.disabled]: isDisabled })}
    >
      <input
        disabled={isDisabled}
        value={value}
        id={value}
        checked={checked}
        onChange={onRadioChange}
        type="radio"
      />
      <span className="text-body-sm color-text-default">{labelValue}</span>
    </label>
  )
}
