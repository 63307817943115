import { ISEAPair } from 'gun'
import { FileTypeEnum } from '../../../types/enum/file.enum'
import {
  DataLockedKeyMaterial,
  DataMaterialFileKey,
} from '../../../types/interface/file.interface'
import { decryptUsingAESKey, decryptUsingRSAKey } from '../../crypto'

export const openLock = async (
  key: string,
  dataKeyMaterial: DataLockedKeyMaterial | null,
  lockType?: FileTypeEnum
): Promise<{ fileKey: DataMaterialFileKey; chatKey: ISEAPair }> => {
  if (!dataKeyMaterial) {
    throw new Error('cannot unlock file with data material null')
  }
  let unLockedFileKey: DataMaterialFileKey
  let unLockedChatKey: ISEAPair
  if (lockType === FileTypeEnum.GATED) {
    unLockedChatKey = decryptUsingAESKey(dataKeyMaterial.lockedChatKey, key)
    unLockedFileKey = decryptUsingAESKey(dataKeyMaterial.lockedFileKey, key)
  } else {
    unLockedFileKey = JSON.parse(
      await decryptUsingRSAKey(dataKeyMaterial.lockedFileKey, key)
    )
    unLockedChatKey = JSON.parse(
      await decryptUsingRSAKey(dataKeyMaterial.lockedChatKey, key)
    )
  }
  return {
    fileKey: unLockedFileKey,
    chatKey: unLockedChatKey,
  }
}
