/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { FileIcon, defaultStyles } from 'react-file-icon'

import PrimaryButton from '../common/PrimaryButton'

export default function DefaultFileViewer({
  downloadUrl,
  fileExt,
  fileName,
}: {
  downloadUrl: string
  fileExt: string
  fileName: string
}) {
  return (
    <div
      data-cy="default-viewer"
      className="w-full h-full flex flex-col items-center"
    >
      <div className="text-center m-auto ">
        <div className="w-32 m-auto">
          <FileIcon
            extension={fileExt}
            {...defaultStyles[fileExt as keyof typeof defaultStyles]}
          />
        </div>
        <div className="mt-4 font-semibold text-xl">{fileName}</div>
        <a
          href={downloadUrl}
          download={`${fileName}`}
          className="items-center flex flex-row justify-center mt-6"
        >
          <PrimaryButton title="Download File" />
        </a>
      </div>
    </div>
  )
}
