import React from 'react'
export const accordianData = [
  {
    header: 'What is permanent storage?',
    body: (
      <p>
        Store your content indefinitely on the permanent storage plugin! It
        ensures the longevity of your online content, and safeguards it against
        accidental loss over time or censorship.
      </p>
    ),
  },
  {
    header: 'What is Arweave?',
    body: (
      <p>
        Arweave is a blockchain-based network designed for the permanent,
        tamper-resistant storage of data. It uses a unique economic model and
        blockweave technology to ensure data is stored forever.
      </p>
    ),
  },
  {
    header: 'How is Arweave different from IPFS?',
    body: (
      <p>
        Arweave provides permanent data storage on its blockchain ensuring data
        is preserved indefinitely. IPFS offers a decentralised system for
        efficient data sharing and retrieval, but without an inherent guarantee
        of long-term storage.
      </p>
    ),
  },
  {
    header: 'How much does it cost?',
    body: (
      <p>
        Most uploaded files typically cost a fraction of a cent for 200 years of
        storage, and the cost per GB usually fluctuates between $3 to $8. Visit
        the{' '}
        <a
          href="https://arwiki.wiki/#/en/storage-endowment"
          target="_blank"
          rel="noreferrer"
        >
          Arweave storage cost page
        </a>{' '}
        for more info.
      </p>
    ),
  },
  {
    header: 'Can it be undone?',
    body: (
      <p>
        If you upload content with permanent storage it cannot be removed under
        any circumstances. Your content will be stored permanently!
      </p>
    ),
  },
]
