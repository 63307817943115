import React, { Ref, SetStateAction } from 'react'
import {
  Link,
  QrCode,
  ShieldCheck,
  Download,
  LogIn,
  MessageSquareText,
  Trash2,
  List,
} from 'lucide-react'
import SvgIcon from '@mui/material/SvgIcon'
import lensterLogo from '../../assets/hey.svg'
import XIcon from '@mui/icons-material/X'
import farcasterLogo from '../../assets/farcaster.svg'
import cn from 'classnames'
import groupBy from 'lodash/groupBy'
import copyToClipboard from 'copy-to-clipboard'
import sendNotifcation from '../../utils/notification'
import { Avatar, Divider, Toggle } from '@fileverse/ui'
import { Tooltip } from '../../pages/PublicPortal/components/Tooltip'

interface ActionTypes {
  icon: JSX.Element
  label: string
  group: string
  command: () => void
}

export const WebPageMoreMenu = ({
  setConfirmDeleteModal,
  setProofPublishOpen,
  // setIsEditing,
  setCommentsEnabled,
  commentsEnabled,
  indexEnabled,
  setIndexEnabled,
  setIsQrVisible,
  isBottomNav,
  filePreviewPage,
  isDPage,
  downloadUrl,
  fileDownloadName,
  isOwner,
  isDownloadEnabled,
  isWhiteboard,
  downloadWhiteboard,
  isArweave,
  // confirmDeleteModal,
  pluginName,
  moreMenuRef,
}: // rtcId,
{
  setConfirmDeleteModal: React.Dispatch<SetStateAction<boolean>>
  setDownloadPopup?: React.Dispatch<SetStateAction<boolean>>
  setProofPublishOpen?: React.Dispatch<SetStateAction<boolean>>
  setIsQrVisible?: React.Dispatch<SetStateAction<boolean>>
  setIsEditing?: React.Dispatch<SetStateAction<boolean>>
  setCommentsEnabled?: React.Dispatch<SetStateAction<boolean>>
  confirmDeleteModal: boolean
  commentsEnabled?: boolean
  indexEnabled?: boolean
  setIndexEnabled?: React.Dispatch<SetStateAction<boolean>>
  isBottomNav?: boolean
  filePreviewPage: boolean
  isDPage?: boolean
  downloadUrl?: string
  fileDownloadName?: string
  isOwner?: boolean
  isDownloadEnabled?: boolean
  isWhiteboard?: boolean
  downloadWhiteboard?: () => void
  isArweave?: boolean
  pluginName?: string
  moreMenuRef: Ref<HTMLDivElement>
  rtcId?: string
}) => {
  const handleTwitterShareClick = () => {
    const href = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
      window.location.href
    )}&via=fileverse&text=Checkout%20my%20${pluginName}`

    if (filePreviewPage) {
      window.open(href, '_blank')
      return
    }
  }

  const handleHeyShareClick = () => {
    const href = `https://lenster.xyz/?text=Checkout%20my%20${pluginName}&url=${encodeURIComponent(
      window.location.href
    )}`

    if (filePreviewPage) {
      window.open(href, '_blank')
      return
    }
  }

  const handleWarpcastShareClick = () => {
    const href = `https://warpcast.com/~/compose?text=Checkout%20my%20${pluginName}%20via%20%40fileverse&embeds[]=${window.location.href}`

    if (filePreviewPage) {
      window.open(href, '_blank')
      return
    }
  }

  const handleFileverseAboutClick = () => {
    const href = `https://fileverse.io`

    if (filePreviewPage) {
      window.open(href, '_blank')
      return
    }
  }

  const handleLoginClick = () => {
    const href = `/#/create`

    if (filePreviewPage) {
      window.open(href, '_blank')
      return
    }
  }

  const ACTIONS: ActionTypes[] = [
    {
      icon: <Link size={24} />,
      label: 'Copy Link',
      group: 'More Actions',
      command: () => {
        if (filePreviewPage) {
          copyToClipboard(window.location.href)
          sendNotifcation('Link copied and ready for sharing!', '', 'success')
        }
      },
    },
    {
      icon: <QrCode size={24} />,
      label: 'Get QR Code',
      group: 'More Actions',
      command: () => setIsQrVisible && setIsQrVisible(true),
    },
    {
      icon: <ShieldCheck size={24} />,
      label: 'Provernance',
      group: 'More Actions',
      command: () => setProofPublishOpen && setProofPublishOpen(true),
    },
    {
      icon: <Download size={24} />,
      label: 'Download',
      group: 'More Actions',
      command: () => null,
    },
    {
      icon: <XIcon />,
      label: 'Share on X',
      group: 'Socials',
      command: () => handleTwitterShareClick(),
    },
    {
      icon: (
        <SvgIcon
          component={() => <img src={lensterLogo} alt="hey" className="w-6" />}
        />
      ),
      label: 'Share on Hey',
      group: 'Socials',
      command: () => handleHeyShareClick(),
    },
    {
      icon: (
        <SvgIcon
          component={() => (
            <img src={farcasterLogo} alt="hey" className="w-6" />
          )}
        />
      ),
      label: 'Share on Warpcast',
      group: 'Socials',
      command: () => handleWarpcastShareClick(),
    },
    {
      icon: <Avatar variant="square" />,
      label: 'About Fileverse',
      group: 'Misc',
      command: () => handleFileverseAboutClick(),
    },
    {
      icon: <LogIn size={24} />,
      label: 'Sign up or Log in',
      group: 'Misc',
      command: () => handleLoginClick(),
    },
  ]

  const groupedActions = groupBy(ACTIONS, 'group')

  const ListItem = ({ item }: { item: ActionTypes }) => {
    if (isWhiteboard && item.label === 'Download') {
      return (
        <li
          className="py-2 px-4 hover:color-button-secondary-hover rounded cursor-pointer flex justify-between items-center"
          onClick={downloadWhiteboard}
        >
          <div className="flex gap-2 items-center">
            {item.icon}
            <span>{item.label}</span>
          </div>
        </li>
      )
    }
    if (item.label === 'Download') {
      if (!downloadUrl) return <></>
      return (
        <a
          href={downloadUrl}
          download={`${fileDownloadName}`}
          className="py-2 px-4 hover:color-button-secondary-hover cursor-pointer flex justify-between items-center"
        >
          <li className="flex gap-2 items-center">
            {item.icon}
            <span>{item.label}</span>
          </li>
        </a>
      )
    }
    return (
      <li
        className="py-2 px-4 hover:color-button-secondary-hover cursor-pointer flex justify-between items-center"
        onClick={item.command}
      >
        <div className="flex gap-2 items-center">
          {item.icon}
          <span>{item.label}</span>
        </div>
      </li>
    )
  }

  return (
    <>
      <div
        ref={moreMenuRef}
        id="menu"
        className={cn(
          'rounded bg-white w-[228px] shadow-elevation-3 fixed z-20 text-body-sm p-2',
          filePreviewPage ? 'right-10' : 'right-8',
          {
            'bottom-[7vh] max-[375px]:bottom-[10vh] right-[calc(100%-70vw)]':
              isBottomNav,
          },
          { 'top-[4.2rem]': !isBottomNav }
        )}
      >
        {filePreviewPage ? (
          <ul className="flex flex-col">
            {Object.keys(groupedActions).map((group) => (
              <div key={group} className="">
                {groupedActions[group].map((item, index) => (
                  <ListItem key={index} item={item} />
                ))}
                {group !== 'Misc' && <Divider className="w-full" size="lg" />}
              </div>
            ))}
          </ul>
        ) : (
          <ul className="flex flex-col">
            <Tooltip text="Enable Comments" position="left">
              <li className="py-2 px-4 hover:color-button-secondary-hover cursor-pointer flex justify-between items-center">
                <div className="flex gap-2 items-center">
                  <MessageSquareText size={24} />
                  <span>Comments</span>
                </div>
                <Toggle
                  checked={commentsEnabled}
                  onCheckedChange={() => setCommentsEnabled?.(!commentsEnabled)}
                />
              </li>
            </Tooltip>

            {isDPage && (
              <Tooltip text="Enable Indexing" position="left">
                <li className="py-2 px-4 hover:color-button-secondary-hover cursor-pointer flex justify-between items-center">
                  <div className="flex gap-2 items-center">
                    <List size={24} />
                    <span>Indexing</span>
                  </div>
                  <Toggle
                    checked={indexEnabled}
                    onCheckedChange={() => setIndexEnabled?.(!indexEnabled)}
                  />
                </li>
              </Tooltip>
            )}
            {isWhiteboard ? (
              <li
                className="py-2 px-4 hover:color-button-secondary-hover my-1"
                onClick={downloadWhiteboard}
              >
                <div className="flex gap-2 items-center">
                  <Download size={24} />
                  <span>Download</span>
                </div>
              </li>
            ) : (
              downloadUrl && (
                <Tooltip
                  text={isDownloadEnabled ? 'Download' : 'Coming Soon'}
                  position="left"
                >
                  <a href={downloadUrl} download={`${fileDownloadName}`}>
                    <li className="py-2 px-4 hover:color-button-secondary-hover my-1">
                      <div className="flex gap-2 items-center">
                        <Download size={24} />
                        <span>Download</span>
                      </div>
                    </li>
                  </a>
                </Tooltip>
              )
            )}
            {isOwner && !isArweave && (
              <li
                onClick={() => {
                  setConfirmDeleteModal?.(true)
                  // setIsEditing && setIsEditing(false)
                }}
                className="py-2 px-4 my-1 cursor-pointer hover:color-button-secondary-hover"
              >
                <div className="flex gap-2 items-center color-text-danger">
                  <Trash2 size={24} />
                  <span>Delete</span>
                </div>
              </li>
            )}
          </ul>
        )}
      </div>
    </>
  )
}
