import { hexlify } from 'ethers'
import { ISEAPair } from 'gun'
import { instantiateSEA } from '../instantiateGun'
import { Base64 } from 'base64-string'

const SEA = instantiateSEA()

export const generateAESKey = (): Promise<CryptoKey> => {
  return window.crypto.subtle.generateKey(
    {
      name: 'AES-GCM',
      length: 256,
    },
    true,
    ['encrypt', 'decrypt']
  )
}

export const generateRSAKeyPair = (): Promise<CryptoKeyPair> => {
  return window.crypto.subtle.generateKey(
    {
      name: 'RSA-OAEP',
      modulusLength: 4096,
      publicExponent: new Uint8Array([1, 0, 1]),
      hash: 'SHA-256',
    },
    true,
    ['encrypt', 'decrypt']
  )
}

export const generateSHA256Hash = async (string: string) => {
  const utf8 = new TextEncoder().encode(string)
  const hashBuffer = await crypto.subtle.digest('SHA-256', utf8)
  const hashArray = new Uint8Array(hashBuffer)
  const hashInHex = hexlify(hashArray)
  return hashInHex
}

export const generateISEAKey = async () => {
  const seaKeyPair = await SEA.pair()
  const enc = new Base64()
  const b64 = enc.urlEncode(JSON.stringify(seaKeyPair))
  return b64
}
export const generateRoomEncryptionKey = async <
  T extends 'string' | 'cryptoKey' = 'string'
>(
  returnAs?: T
): Promise<T extends 'cryptoKey' ? CryptoKey : string> => {
  const key = await window.crypto.subtle.generateKey(
    {
      name: 'AES-GCM',
      length: 128,
    },
    true, // extractable
    ['encrypt', 'decrypt']
  )
  return (
    returnAs === 'cryptoKey'
      ? key
      : (await window.crypto.subtle.exportKey('jwk', key)).k
  ) as T extends 'cryptoKey' ? CryptoKey : string
}
export const generateCollabRoomKey = async () => {
  const seaKeyPair = await SEA.pair()
  const key = {
    seaKeyPair,
    roomKey: await generateRoomEncryptionKey(),
  }
  const enc = new Base64()
  const b64 = enc.urlEncode(JSON.stringify(key))
  return b64
}
export const resolveCollabRoomKey = (
  key: string
): { seaKeyPair: ISEAPair; roomKey: string } | undefined => {
  if (!key) return
  const enc = new Base64()
  const b64 = enc.decode(key)
  const roomKeyMaterial = JSON.parse(b64)
  return roomKeyMaterial
}
export const getISEAKeyPair = (
  key: string | undefined
): ISEAPair | undefined => {
  if (!key) return
  const enc = new Base64()
  const b64 = enc.decode(key)
  return JSON.parse(b64)
}
