/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-key */
import React, { useCallback, useEffect, useState } from 'react'
import { UniqueIdentifier, useDndContext } from '@dnd-kit/core'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import cn from 'classnames'
import { CardSize, CardProps, FileType } from '@fileverse/ui'
import {
  PortalCard,
  TextBlockCard,
  MediaCard,
  LinkCard,
  DocumentCard,
} from '../components/Card'
import { CardControlWrapper } from '../components/CardControlWrapper'
import { DocType, IPortalCardItemProps } from '../interfaces'
import { CardInteractionWrapper } from './CardInteractionWrapper'
import { usePublicPortalContext } from '../../../providers/PublicPortalProvider'
import { useParams, useSearchParams } from 'react-router-dom'
import { DropdownProvider } from '../../../providers/DropdownContext'

import { useHeartBitContext } from './HeartBit/HeartBitProvider'
import HeartBitDialog from './HeartBit'
import { useDialogStore } from './HeartBit/utils'
import { getHashedString } from '../../../utils/ethUtils'
import { heartbitCoreSDK } from '../utils/heartbitSDK'
import { usePrivyHelper } from '../../../hooks/usePrivyHelper'

export const PortalCardItem = ({
  id,
  title,
  size,
  type,
  likeCount,
  metadata,
  disabled,
  ...props
}: IPortalCardItemProps) => {
  const { listeners, index, isDragging, setNodeRef, transform, transition } =
    useSortable({
      id: id,
      data: {
        type: 'item',
        currentSectionId: props.sectionId,
        itemPos: props.itemPos,
        title: title,
        metadata: metadata,
        fileType: type,
        size: size,
        likeCount: likeCount,
      },
      transition: {
        duration: 200,
        easing: 'cubic-bezier(0.18, 0.67, 0.6, 1.22)',
      },
      animateLayoutChanges: () => true,
    })

  const { onRemoveFile, setSections, isViewMode } = usePublicPortalContext()

  const handleSizeChange = (newSize: CardSize, index: number) => {
    setSections((sections) => {
      return sections.map((section) => {
        if (section.id === props.sectionId) {
          const nextItems = [...section.files]
          nextItems[index] = {
            ...nextItems[index],
            size: newSize as CardSize,
          }
          return {
            ...section,
            files: nextItems,
          }
        } else {
          return section
        }
      })
    })
  }

  const { address: contractAddress } = useParams()
  const [searchParams] = useSearchParams()
  const chainId = parseInt(searchParams.get('chainId') || '')
  const chainIdQuery = chainId ? `?chainId=${chainId}` : ''
  const filePath = `/${contractAddress}/file/${metadata?.fileId}${chainIdQuery}`
  const walletAddress = usePrivyHelper().walletAddress as string
  const { isMinted, setMinted, getUserBalance } = useHeartBitContext()

  const [totalHb, setTotalHb] = useState<number>(0)

  const getTotalHeartBits = useCallback(async () => {
    let tokenId
    if (type === 'note') tokenId = `${location.href}&fileId${id}`
    if (type === 'link') tokenId = metadata?.link

    const chainId = parseInt(searchParams.get('chainId') || '')
    const chainIdQuery = chainId ? `?chainId=${chainId}` : ''
    const filePath = `/${contractAddress}/file/${metadata?.fileId}${chainIdQuery}`
    tokenId = `${window.location.origin}/#${filePath}`
    const hash = getHashedString(tokenId)
    const count = await heartbitCoreSDK.getTotalHeartBitCountByHash({
      hash,
    })

    setTotalHb(count)
  }, [
    contractAddress,
    id,
    metadata?.fileId,
    metadata?.link,
    searchParams,
    type,
  ])

  useEffect(() => {
    getTotalHeartBits()
  }, [getTotalHeartBits])

  const { setDialogState, dialogState } = useDialogStore()

  const onLikeClick = (
    e:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: UniqueIdentifier
  ) => {
    e?.stopPropagation()
    e?.preventDefault()
    setDialogState({
      ...dialogState,
      itemId: id,
    })
  }

  const getUserLikeCount = useCallback(async () => {
    let tokenId
    if (type === 'note') tokenId = `${location.href}&fileId${id}`
    if (type === 'link') tokenId = metadata?.link

    const chainId = parseInt(searchParams.get('chainId') || '')
    const chainIdQuery = chainId ? `?chainId=${chainId}` : ''
    const filePath = `/${contractAddress}/file/${metadata?.fileId}${chainIdQuery}`
    tokenId = `${window.location.origin}/#${filePath}`

    await getUserBalance(tokenId, walletAddress)
  }, [
    contractAddress,
    getUserBalance,
    id,
    metadata?.fileId,
    metadata?.link,
    searchParams,
    type,
    walletAddress,
  ])

  useEffect(() => {
    getUserLikeCount()
  }, [getUserLikeCount])

  const { itemId } = dialogState
  if (isViewMode) {
    return (
      <>
        <DropdownProvider>
          <CardInteractionWrapper
            filePath={filePath}
            size={size}
            liked={isMinted}
            onLike={(e: any) => onLikeClick(e, id)}
            onClick={() => {
              if (type === 'note') return
              if (type === 'link') {
                window.open(metadata?.link, '_blank')
                return
              }
              if (type === 'dPage' && metadata?.extension === 'dummy') {
                window.open(metadata?.link, '_blank')
                return
              }
              window.open(`${window.location.origin}/#${filePath}`, '_blank')
            }}
            link={metadata?.link}
            type={type}
          >
            <CardItem
              size={size}
              title={title}
              url={type === 'link' ? metadata?.link : metadata?.ipfsHash}
              metadata={metadata}
              type={type}
              filePath={filePath}
              likeCount={totalHb}
              text={metadata?.textContent}
              onLike={(e: any) => onLikeClick(e, id)}
            />
          </CardInteractionWrapper>
        </DropdownProvider>
        {itemId === id ? (
          <HeartBitDialog
            isOpen={itemId === id}
            onLike={() => {
              setMinted(true)
            }}
          />
        ) : null}
      </>
    )
  }

  return (
    <CardControlWrapper
      key={id || index}
      deleteFunction={() => onRemoveFile(id)}
      defaultSize={size}
      isDragging={isDragging}
      onSizeChange={(size: CardSize) => handleSizeChange(size, index)}
      isAnimated
    >
      {(size: CardSize) => (
        <div
          ref={setNodeRef}
          {...listeners}
          className={cn(
            'w-full h-full select-none cursor-grab',
            disabled && 'cursor-default'
          )}
        >
          {isDragging ? (
            <div className="w-full h-full color-bg-default-hover rounded-xl shadow-inner" />
          ) : (
            <CardItem
              id={id as string}
              style={{
                transform: CSS.Transform.toString(transform),
                transition,
              }}
              size={size}
              title={title}
              url={type === 'link' ? metadata?.link : metadata?.ipfsHash}
              metadata={metadata}
              type={type}
              likeCount={likeCount}
              text={metadata?.textContent}
              filePath={filePath}
            />
          )}
        </div>
      )}
    </CardControlWrapper>
  )
}

export type CardItemProps = Omit<CardProps, 'type'> & {
  id?: UniqueIdentifier
  metadata?: any
  type: DocType | FileType
  imageUrl?: string
  faviconUrl?: string
  source?: string
}

export const CardItem = ({
  size,
  metadata,
  url,
  type,
  likeCount,
  id,
  filePath,
  noFooter,
  onLike,
}: CardItemProps & {
  filePath: string
  noFooter?: boolean
  onLike?: (e: any) => void
}) => {
  const {
    extension,
    name,
    textContent,
    title: linkTitle,
    imageUrl,
    source,
    faviconUrl,
  } = metadata || {}

  switch (type) {
    case 'link':
      return (
        <LinkCard
          id={id}
          url={url}
          size={size}
          type={type}
          title={linkTitle}
          likeCount={likeCount}
          imageUrl={imageUrl}
          source={source}
          faviconUrl={faviconUrl}
          noFooter={noFooter}
          onLike={onLike}
        />
      )
    case 'media':
      if (extension === 'json') {
        return (
          <DocumentCard
            size={size}
            type="doc"
            title={name}
            url={url}
            likeCount={likeCount}
            filePath={filePath}
            noFooter={noFooter}
            onLike={onLike}
          />
        )
      }
      return (
        <MediaCard
          size={size}
          type={type}
          url={url}
          title={name}
          likeCount={likeCount}
          extension={extension}
          filePath={filePath}
          noFooter={noFooter}
          onLike={onLike}
        />
      )
    case 'note':
      return (
        <TextBlockCard
          id={id}
          size={size}
          type={type}
          text={textContent}
          likeCount={likeCount}
          noFooter={noFooter}
          onLike={onLike}
        />
      )
    case 'pdf':
    case 'xls':
    case 'doc':
    case 'ppt':
    case '3d':
      return (
        <DocumentCard
          title={name}
          size={size}
          type={type}
          url={url}
          likeCount={likeCount}
          filePath={filePath}
          noFooter={noFooter}
          onLike={onLike}
        />
      )
    default:
      return (
        <PortalCard
          size={size}
          type={type}
          title={name}
          url={url}
          likeCount={likeCount}
          metadata={metadata}
          noFooter={noFooter}
          onLike={onLike}
        />
      )
  }
}

export const CardItemOverlay = () => {
  const { active } = useDndContext()

  if (!active) return null
  const { id, data } = active
  if (data?.current?.type !== 'item') return null

  const size = data?.current?.size
  const title = data?.current?.title
  const metadata = data?.current?.metadata
  const type = data?.current?.fileType
  const likeCount = data?.current?.likeCount
  const filePath = data?.current?.filePath

  return (
    <CardItem
      id={id as any}
      size={size}
      title={title}
      url={type === 'link' ? metadata.link : metadata?.ipfsHash}
      metadata={metadata}
      type={type}
      likeCount={likeCount}
      text={metadata?.textContent}
      filePath={filePath}
    />
  )
}
