import { AppState } from '../index'
import { useAppSelector } from '../hooks'
import {
  IContract,
  IContractMetadata,
} from '../../types/interface/contract.interface'
import { createSelector } from '@reduxjs/toolkit'

export function useContract(contractAddress: string): IContract {
  return useAppSelector(
    (state: AppState) => state.contract.contracts[contractAddress]
  )
}

export const usePublicLayoutFileId = (contractAddress: string) => {
  return useAppSelector(
    (state: AppState) =>
      state.contract.contracts[contractAddress]?.publicLayoutFileId
  )
}

export const usePortalMetadata = (contractAddress: string) => {
  return useAppSelector(
    (state: AppState) => state.contract.contracts[contractAddress]?.metadata
  )
}

export function usePortalSignlessMode(contractAddress: string): boolean {
  return useAppSelector(
    (state: AppState) =>
      state.contract.contracts[contractAddress]?.enabled_signless
  )
}
export function useContractMembers(
  contractAddress: string
): Array<{ invokerAddress: string }> {
  return useAppSelector(
    (state: AppState) =>
      state.contract.contracts[contractAddress]?.members || []
  )
}
export function useContractStorage(contractAddress: string) {
  return useAppSelector((state: AppState) => {
    return state.contract.contracts[contractAddress]?.storage || null
  })
}
export function useAllContract(): Record<string, IContract> {
  return useAppSelector((state: AppState) => state.contract.contracts)
}
export function useContractMetadata(
  contractAddress: string
): IContractMetadata {
  return useAppSelector(
    (state: AppState) => state.contract.contracts[contractAddress]?.metadata
  )
}

export function useActiveContractAddress(): string {
  return useAppSelector(
    (state: AppState) => state.contract.activeContractAddress
  )
}
export function useContractChainId(contractAddress: string): number {
  return useAppSelector((state: AppState) => {
    if (contractAddress) {
      return state.contract?.contracts[contractAddress]?.chainId
    }
    return 0
  })
}

export function useContractCollaborators(contractAddress: string): string[] {
  return useAppSelector(
    createSelector(
      (state: AppState) => state.contract.contracts,
      (contracts) => contracts[contractAddress]?.collaborators || []
    )
  )
}
