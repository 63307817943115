import { configureStore } from '@reduxjs/toolkit'

import { save, load } from 'redux-localstorage-simple'

import account from './account/reducer'
import upload from './upload/reducer'
import contract from './contract/reducer'
import invoker from './invoker/reducer'
import files from './files/reducer'
import drafts from './drafts/reducer'
import keySharing from './keySharing/reducer'
import pageStatus from './page/reducer'
import portalUpdateLogs from './portalUpdateLogs/reducer'
import portalArweaveConfig from './arweave/reducer'
import taskQueue from './taskQueue/reducer'
import commonStore from './common/reducer'
import fileSearchStore from './fileSearch/reducer'
import { indexeddbMiddleware } from './middleware'
import { IPreloadedData } from './middleware/utils'

const PERSISTED_KEYS: string[] = [
  'keySharing',
  'drafts',
  'portalUpdateLogs',
  'portalArweaveConfig',
]
const IGNORE_STATES: string[] = []

const store = (preloadedData?: IPreloadedData) => {
  return configureStore({
    reducer: {
      account,
      upload,
      files,
      contract,
      invoker,
      keySharing,
      drafts,
      pageStatus,
      portalUpdateLogs,
      portalArweaveConfig,
      taskQueue,
      commonStore,
      fileSearchStore,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredPaths: ['taskQueue.argsQueue'],
          ignoredActions: [
            'taskQueue/updateTaskQueue',
            'taskQueue/clearTaskQueue',
            'taskQueue/setTaskQueue',
          ],
        },
      }).concat(
        save({
          namespace: 'fileverse',
          namespaceSeparator: '.',
          states: PERSISTED_KEYS,
          ignoreStates: IGNORE_STATES,
        }),
        indexeddbMiddleware
      ),
    preloadedState: load({
      states: PERSISTED_KEYS,
      namespace: 'fileverse',
      namespaceSeparator: '.',
      preloadedState: preloadedData,
    }),
  })
}

export default store

export const t = store()
export type AppState = ReturnType<typeof t.getState>
export type AppDispatch = typeof t.dispatch
