import { JsonRpcSigner, Wallet, type Contract } from 'ethers'
import { SecretFile } from '../../types/interface/file.interface'
import { IKeyVerifiers } from '../../types/interface/ikeyverifiers'
import { FileTypeEnum } from '../../types/enum/file.enum'

interface CommonTransactionArgs {
  contractAddress: string
  walletAddress: string
}
export interface MintPortalTrxArgs {
  walletAddress: string
  secretFileContent: SecretFile
  keyVerifiers: IKeyVerifiers
}

export enum ContractMethod {
  Mint = 'mint',
  AddCollaborator = 'addCollaborator',
  RegisterCollaboratorKeys = 'registerCollaboratorKeys',
  AddFile = 'addFile',
  EditFile = 'editFile',
  RemoveCollaborator = 'removeCollaborator',
  UpdateMetadata = 'updateMetadata',
  RemoveCollaboratorKeys = 'removeCollaboratorKeys',
}

export enum PortalContractEventEnum {
  Mint = 'Mint',
  EDITED_FILE = 'EditedFile',
  OWNERSHIP_TRANSFERRED = 'OwnershipTransferred',
  ADDED_FILE = 'AddedFile',
  UPDATED_KEY_VERIFIERS = 'UpdatedKeyVerifiers',
  ADDED_COLLABORATOR = 'AddedCollaborator',
  REMOVED_COLLABORATOR = 'RemovedCollaborator',
  UPDATED_PORTAL_METADATA = 'UpdatedPortalMetadata',
  REMOVED_COLLABORATOR_KEYS = 'RemovedCollaboratorKeys',
  REGISTERED_COLLABORATOR_KEYS = 'RegisteredCollaboratorKeys',
}

export interface TransactionArgs {
  contract: Contract
  walletAddress: string
  encodedFunction: string
}

export interface AddCollaboratorArgs extends CommonTransactionArgs {
  collaboratorAddress: string
}

export type SignerOrWallet = JsonRpcSigner | Wallet

export enum ContractType {
  Portal = 'Portal',
  Registry = 'Registry',
}

export interface AddFileTransactionArgs extends CommonTransactionArgs {
  contentIpfsHash: string
  metadataIpfsHash: string
  fileType: FileTypeEnum
  gateIpfsHash: string
  version?: number
}

export interface EditFileTransactionArgs extends AddFileTransactionArgs {
  fileId: number
}

export interface DeleteFileArgs extends CommonTransactionArgs {
  fileId: number
}

export interface RemoveCollaboratorArgs extends CommonTransactionArgs {
  collaboratorAddress: string
  previousCollaborator: string
}

export interface PortalMetadataArgs extends CommonTransactionArgs {
  metadataIpfsHash: string
}
