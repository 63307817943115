import { generateAESKey } from './generation'
import { importRSAEncryptionKey } from './import'
import { convertTypedArrayToString } from '../string'
import { IServerKey } from '../../types/interface/invoker.interface'

export const encryptServerKeys = async (
  keyPem: string,
  serverKeys: IServerKey
) => {
  const enc = new TextEncoder()
  const keyEncryptionKey = await importRSAEncryptionKey(keyPem)
  const messageEncryptionKey = await generateAESKey()
  const iv = window.crypto.getRandomValues(new Uint8Array(12))

  const encryptedPortalEncryptionKey = await window.crypto.subtle.encrypt(
    { name: 'AES-GCM', iv: iv },
    messageEncryptionKey,
    enc.encode(serverKeys.portalEncryptionKey).buffer
  )
  const encryptedPortalDecryptionKey = await window.crypto.subtle.encrypt(
    { name: 'AES-GCM', iv: iv },
    messageEncryptionKey,
    enc.encode(serverKeys.portalDecryptionKey).buffer
  )
  const encryptedMemberEncryptionKey = await window.crypto.subtle.encrypt(
    { name: 'AES-GCM', iv: iv },
    messageEncryptionKey,
    enc.encode(serverKeys.memberEncryptionKey).buffer
  )

  const encryptedMemberDecryptionKey = await window.crypto.subtle.encrypt(
    { name: 'AES-GCM', iv: iv },
    messageEncryptionKey,
    enc.encode(serverKeys.memberDecryptionKey).buffer
  )
  const encryptedPortalGunKey = await window.crypto.subtle.encrypt(
    { name: 'AES-GCM', iv: iv },
    messageEncryptionKey,
    enc.encode(serverKeys.portalGunKey).buffer
  )

  const exportedMessageEncryptionKey = await window.crypto.subtle.exportKey(
    'raw',
    messageEncryptionKey
  )
  const encryptedMessageEncryptionKey = await window.crypto.subtle.encrypt(
    {
      name: 'RSA-OAEP',
    },
    keyEncryptionKey,
    exportedMessageEncryptionKey
  )
  return {
    key: convertTypedArrayToString(
      new Uint8Array(encryptedMessageEncryptionKey)
    ),
    iv: convertTypedArrayToString(iv),
    encryptedPortalEncryptionKey: convertTypedArrayToString(
      new Uint8Array(encryptedPortalEncryptionKey)
    ),
    encryptedPortalDecryptionKey: convertTypedArrayToString(
      new Uint8Array(encryptedPortalDecryptionKey)
    ),
    encryptedMemberEncryptionKey: convertTypedArrayToString(
      new Uint8Array(encryptedMemberEncryptionKey)
    ),
    encryptedMemberDecryptionKey: convertTypedArrayToString(
      new Uint8Array(encryptedMemberDecryptionKey)
    ),
    encryptedPortalGunKey: convertTypedArrayToString(
      new Uint8Array(encryptedPortalGunKey)
    ),
  }
}
