/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import isEmpty from 'lodash/isEmpty'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useContract } from '../store/contract/hooks'
import { fetchAndSaveContract } from '../store/contract/thunks'
import { useAppDispatch } from '../store/hooks'
import { DocumentPageStatus, FileSource } from '../types/enum/file.enum'
import QuillEditor from './domain/QuillEditor'
import { useServerKeys } from '../store/invoker/hooks'
import { generateISEAKey, getISEAKeyPair } from '../utils/libCrypto'
import { ISEAPair } from 'gun'
import sendNotifcation from '../utils/notification'
import { IDraft } from '../types/interface/drafts.interface'
import { getOrCreateDdocsMetadata } from '../utils/collaboration/utils'
import { isAddress } from 'ethers'
import { resolveEnsName } from '../utils/networks/ensResolvers'
import WalletDisconnected from '../components/common/WalletDisconnected'
import Header from '../components/common/Headers/MainHeader'
import TipTapEditor from './domain/TipTapEditor'
import { useLocalStorage } from 'usehooks-ts'
import { checkIfOwnerOrCollaborator } from '../utils/checkIfOwnerOrCollaborator'
import { captureException, captureMessage } from '@sentry/react'
import { usePrivyHelper } from '../hooks/usePrivyHelper'
import { ConnectToDocumentProps } from './CollaborativeWhiteboard'
import { AnimatedLoader } from './PublicPortal/components/Loader'

const CollaborativeDocument = () => {
  const [files, setFiles] = useState<File[]>([])
  const [urlParams, setUrlParam] = useSearchParams()
  const { rtcId } = useParams()
  const { address: contractAddress } = useParams()
  const chainId = parseInt(urlParams.get('chainId') || '')
  const walletAddress = usePrivyHelper().walletAddress as string
  const contract = useContract(contractAddress as string)
  const [rtcData, setDraftRTCData] = useState<IDraft>()
  const [username] = useLocalStorage('username', '')
  const serverKeys = useServerKeys(
    walletAddress as string,
    contractAddress as string
  )
  const authKey = getISEAKeyPair(serverKeys.portalGunKey)
  const [pageStatus, setPageStatus] = useState<DocumentPageStatus>(
    DocumentPageStatus.PENDING
  )
  const [collabDocPreview, setCollabDocPreview] = useState<string | undefined>()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const rtcKey = urlParams.get('key')

  const docVersion = Number(urlParams.get('version'))
  const isLoading = usePrivyHelper().isLoading
  useEffect(() => {
    if (files.length > 0) {
      navigate(`/${contractAddress}?chainId=${chainId}`, {
        state: {
          file: files,
          source: FileSource.COLLAB_DOCUMENT,
          collabDocPreview: collabDocPreview,
          version: 2,
          rtcId,
          rtcData,
        },
      })
    }
  }, [files, collabDocPreview])

  const setupDocument = async () => {
    try {
      if (!rtcId) {
        sendNotifcation(
          'Unable to load document',
          'Specify the rtc_id of the document that is to be previewed',
          'danger'
        )
        return
      }
      // This key is required for editing the document
      if (!rtcKey) {
        const key = await generateISEAKey()
        urlParams.set('key', key)
        setUrlParam(urlParams)
      }
      if (authKey) {
        const draftMetadata = await getOrCreateDdocsMetadata(
          authKey,
          contractAddress as string,
          rtcId,
          serverKeys,
          urlParams.get('key') as string,
          walletAddress as string
        )
        setDraftRTCData(draftMetadata)
        setPageStatus(DocumentPageStatus.READY)
      } else {
        if ((!walletAddress || !username) && !isLoading) {
          // this could be fixed too
          setPageStatus(DocumentPageStatus.DISCONNECTED)
          return
        } else {
          setPageStatus(DocumentPageStatus.READY)
        }
      }
    } catch (error: any) {
      captureException(error)
      console.log(error)
    }
  }

  const setUpDocumentPage = async () => {
    setPageStatus(DocumentPageStatus.PENDING)
    try {
      // come back to this
      if (!isAddress(contractAddress as string)) {
        const address = await resolveEnsName(contractAddress as string)
        if (address) {
          navigate(`/${address}/document/${rtcId}`)
        } else {
          captureMessage(
            `Failed to resolve ens name ${contractAddress} for document`
          )
          sendNotifcation(
            'Failed to load portal',
            'The portal ens name provided couldnt be resolved. Try using the portal address instead',
            'danger',
            7000
          )
        }
        navigate('/')
      }
      // If someone visits for the first time or a link is shared
      if (isEmpty(contract)) {
        setPageStatus(DocumentPageStatus.FETCHING_CONTRACT)
        await dispatch(
          fetchAndSaveContract({
            contractAddress: contractAddress as string,
            invokerAddress: walletAddress,
            chainId,
          })
        ).unwrap()
        setPageStatus(DocumentPageStatus.PENDING)
      }
      await setupDocument()
    } catch (error: any) {
      captureException(error)
      sendNotifcation(
        'Failed to render document',
        error.message,
        'danger',
        7000
      )
      console.log(error.message)
      setPageStatus(DocumentPageStatus.PENDING)
      if (
        checkIfOwnerOrCollaborator(
          contract,
          walletAddress as string,
          serverKeys
        )
      )
        // if collaborator or owner redirect to portal dashboard
        navigate(`/${contractAddress}?chainId=${chainId}`)
      // else redirect to public page
      else navigate(`/${contractAddress}/members?chainId=${chainId}`)
    }
  }
  useEffect(() => {
    setUpDocumentPage()
  }, [walletAddress, contractAddress, isLoading])

  return (
    <div
      data-cy="collaborative-document"
      className="h-screen w-screen bg-[#f9fbfd]"
    >
      {pageStatus !== DocumentPageStatus.DISCONNECTED ? (
        <div className="h-full w-full flex flex-col justify-center items-center">
          {(pageStatus === DocumentPageStatus.PENDING ||
            pageStatus === DocumentPageStatus.FETCHING_CONTRACT) && (
            <AnimatedLoader />
          )}
          {pageStatus === DocumentPageStatus.READY && (
            <div className="h-full w-full">
              {!docVersion || docVersion === 2 ? (
                <TipTapEditor
                  setFiles={setFiles}
                  setCollabDocPreview={setCollabDocPreview}
                  authKey={authKey as ISEAPair}
                  rtcData={rtcData as IDraft}
                />
              ) : (
                <QuillEditor
                  setFiles={setFiles}
                  documentKey={urlParams.get('key') as string}
                  setCollabDocPreview={setCollabDocPreview}
                  authKey={authKey as ISEAPair}
                  rtcData={rtcData as IDraft}
                />
              )}
            </div>
          )}
        </div>
      ) : (
        <ConnectToDocument
          onJoinDoc={(user) => {
            if (username || user) setPageStatus(DocumentPageStatus.READY)
          }}
        />
      )}
    </div>
  )
}

export default CollaborativeDocument

export const ConnectToDocument = ({ onJoinDoc }: ConnectToDocumentProps) => {
  return (
    <div className="h-[100vh] min-h-fit">
      <Header />
      <div className="h-[90vh] flex flex-col justify-center w-full p-4">
        <WalletDisconnected joinDoc={onJoinDoc} collabDoc />
      </div>
    </div>
  )
}
