/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { SetStateAction, useEffect, useRef, useState } from 'react'
import { Comment } from './Comment'
import {
  IFileChatKeyPair,
  INewFile,
} from '../../types/interface/file.interface'
import { instantiateSEA } from '../../utils/instantiateGun'
import { useParams, useSearchParams } from 'react-router-dom'
import { IChatMessage } from '../../types/interface/message.interface'
import { usePostNotification } from '../../hooks/usePostNotification'
import { convertAddress, formatAddress } from '../../utils/formatAddress'
import { FILE_NOTIFICATION_AUDIENCE } from '../../constants'
import { NotificationType } from '../../types/enum/notification'
import AvatarCanvas from './Avatar'
import { logAnalyticsAPI } from '../../api/analytics/log'
import cn from 'classnames'
import { useGunNodes } from '../../hooks/useGunNode'
import { ISEAPair } from 'gun'
import logo from '../../assets/mainLogo.svg'
import { IconButton, TextAreaField } from '@fileverse/ui'
import { useEscapeKey } from '../../hooks/useEscapeKey'
import { useOnClickOutside } from 'usehooks-ts'
import { useTasksContext } from '../../hooks/useTasksContext'
import { TaskID } from '../Tasks/TasksProvider'
import { usePrivyHelper } from '../../hooks/usePrivyHelper'
import { useContract } from '../../store/contract/hooks'
import { useServerKeys } from '../../store/invoker/hooks'
import { Button } from '../../pages/PublicPortal/components/Button'
import { checkIfOwnerOrCollaborator } from '../../utils/checkIfOwnerOrCollaborator'

export type SetCommentMetdataReturnType =
  | { count: number }
  | { available: boolean }
interface WebPageCommentSectionProps {
  show: boolean
  setShow: React.Dispatch<SetStateAction<boolean>>
  chatKey: IFileChatKeyPair
  fileData: INewFile
  setCommentMetadata: (data: SetCommentMetdataReturnType) => void
  isViewMode: boolean
}
const SEA = instantiateSEA()

export const WebPageCommentSection = ({
  show,
  chatKey,
  setShow,
  fileData,
  setCommentMetadata,
  isViewMode,
}: WebPageCommentSectionProps) => {
  const [text, setText] = useState('')
  const walletAddress = usePrivyHelper().walletAddress
  const { address: contractAddress } = useParams()
  const [urlParams] = useSearchParams()
  const chainId = parseInt(urlParams.get('chainId') || '')
  const postNotification = usePostNotification()
  const fileId = fileData?.fileId
  const { getFileChatNode } = useGunNodes()
  const contract = useContract(contractAddress as string)
  const serverKeys = useServerKeys(
    walletAddress as string,
    contractAddress as string
  )
  const isCollaborator = checkIfOwnerOrCollaborator(
    contract,
    walletAddress as string,
    serverKeys
  )
  const { connectInjectedWallet, loginWithSocials } = usePrivyHelper()
  const { markTaskAsDone, isTaskCompleted } = useTasksContext() || {}
  const [userMessages, setUserMessages] = useState<Array<IChatMessage>>([])
  const [idList, setIdList] = useState<string[]>([]) // keep track of chat ids that has been rendered from GunDB - Gun can call a single chat multiple times, this is by design.
  const handleInputChange = (e: any) => {
    setText(e.target.value)
  }

  const saveMessage = async () => {
    try {
      const chatCollection = getFileChatNode(
        chatKey as ISEAPair,
        fileId.toString()
      )
      const encryptedText = await SEA.encrypt(text, chatKey)
      const serializedChatData = JSON.stringify({
        by: walletAddress,
        text: encryptedText,
        createdAt: Date.now(),
      })
      const chatDataHash = await SEA.work(serializedChatData, null, null, {
        name: 'SHA-256',
      })

      chatCollection.get(chatDataHash).put(serializedChatData, (ack: any) => {
        if (!ack?.err) {
          postNotification({
            audience: FILE_NOTIFICATION_AUDIENCE[fileData.metadata.fileType],
            content: {
              fileId: JSON.stringify(fileId),
              text,
              link: `${window.origin}${window.location.pathname}#/${contractAddress}/file/${fileId}?chainId=${chainId}`,
            },
            message: `${formatAddress(
              walletAddress as string
            )} messaged on the dpage ${fileData.metadata.name}`,
            type: NotificationType.FILE_MESSAGE,
          })

          logAnalyticsAPI({
            contract: contractAddress as string,
            invoker: walletAddress as string,
            data: {
              fileId: fileId.toString(),
              eventName: 'chat',
              tags: ['fileverse_chat'],
            },
            chain: chainId as number,
          })

          if (!isCollaborator || typeof isTaskCompleted !== 'function') return

          if (!isTaskCompleted?.(TaskID.CHAT_ON_FILE)) {
            markTaskAsDone?.({
              taskId: TaskID.CHAT_ON_FILE,
              name: 'Use chat on a file',
            })
          }
        }
      })
      setText('')
    } catch (error) {
      console.log(error)
    }
  }

  const loadChatData = () => {
    if (!fileData || !Object.keys(fileData).includes('fileId')) return
    try {
      const chatCollection = getFileChatNode(
        chatKey,
        fileData.fileId.toString()
      )
      chatCollection.map().on(async (serialzedChatData: string, id: string) => {
        if (!idList.includes(id)) {
          const list = idList
          list.push(id)
          setIdList(list)
          const unserializedChatData = JSON.parse(serialzedChatData)

          const decryptedText = await SEA.decrypt(
            unserializedChatData.text,
            chatKey
          )

          setUserMessages((prev: Array<IChatMessage>) => {
            const chat = [
              ...prev,
              {
                by: unserializedChatData.by,
                text: decryptedText,
                createdAt: unserializedChatData.createdAt,
              },
            ].sort((a, b) => a.createdAt - b.createdAt)
            return chat
          })
          if (unserializedChatData) setCommentMetadata({ available: true })
        }
      })
    } catch (err) {
      console.log(err)
    }
  }

  const handleKeyEvent = (event: any) => {
    if (event.key === 'Enter' && text) {
      saveMessage()
    }
  }
  const getData = async () => {
    try {
      loadChatData()
    } catch (err) {
      console.log(err)
    }
  }

  const commentRef = useRef<HTMLDivElement>(null)

  useOnClickOutside(commentRef, () => setShow(false))

  useEscapeKey(() => setShow(false))

  useEffect(() => {
    getData()
  }, [fileData])

  useEffect(() => {
    setCommentMetadata({ count: userMessages.length })
  }, [userMessages])

  return (
    <>
      <div
        ref={commentRef}
        className={cn(
          'pt-2 overflow-y-auto no-scrollbar z-[99] shadow w-[82vw] lg:w-1/3 max-w-md h-screen fixed top-0 bg-[#ffffff] duration-300 transition-all rounded-s-lg',
          {
            'right-0': show,
            '-right-full': !show,
          }
        )}
      >
        <div className="w-full flex justify-start ml-2 cursor-pointer gap-4 items-center">
          <IconButton
            onClick={() => setShow(false)}
            icon="Close"
            size="md"
            variant="ghost"
          />
          <div className="text-body-md-bold color-text-default">
            Comments ({userMessages.length || 0})
          </div>
        </div>
        {walletAddress && fileData ? (
          <div className="h-[85vh] lg:h-[95vh] flex flex-col justify-between">
            <Comment userMessages={userMessages} />
            <div
              className="flex flex-col gap-4 p-8 h-fit"
              onKeyDown={handleKeyEvent}
            >
              <div className="flex items-center">
                <div className="flex items-center gap-3">
                  <AvatarCanvas
                    inputValue={walletAddress}
                    canvasId={convertAddress(walletAddress)}
                  />
                  <div className="flex flex-col justify-between items-start">
                    <p>You</p>
                  </div>
                </div>
              </div>
              <TextAreaField
                onChange={handleInputChange}
                rows={3}
                className="border-[1px] shadow-none"
                placeholder="What are your thoughts?"
                value={text}
              />
              <div className="flex justify-end">
                <Button
                  onClick={() => {
                    if (text) saveMessage()
                  }}
                >
                  Send
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="w-full h-full flex flex-col justify-center items-center px-4">
              <div className="flex flex-col justify-center items-center gap-8 text-center">
                <img
                  src={logo}
                  alt="fileverse"
                  className="w-[80px] bg-yellow rounded-lg"
                />
                <div className="flex justify-center items-center gap-2">
                  <p className="text-heading-xlg-bold">
                    {fileData
                      ? 'Connect to wallet'
                      : `
                      ${
                        isViewMode
                          ? 'Owner has not published yet'
                          : 'Publish your portal'
                      } `}
                  </p>
                </div>
                <p className="w-[65%] text-body-md-bold color-text-secondary">
                  {fileData
                    ? 'Chat directly with anyone that has access to this Portal!'
                    : isViewMode
                    ? 'Comment will be enabled after the owner publish this public page'
                    : 'This comment section will be enabled after you publish your Portal.'}
                </p>
                {fileData ? (
                  <div className="flex justify-between gap-[10px]">
                    <Button size="lg" onClick={connectInjectedWallet}>
                      Connect
                    </Button>
                    <Button size="lg" onClick={loginWithSocials}>
                      Social Login
                    </Button>
                  </div>
                ) : (
                  <Button
                    size="lg"
                    onClick={() => {
                      setShow(false)
                    }}
                  >
                    Go back
                  </Button>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      {/* Overlay background when sidebar is shown */}
      <div
        className={cn(
          'fixed inset-0 z-50 bg-black/80 duration-300 transition-opacity pointer-events-none',
          !show ? 'opacity-0 ' : 'opacity-100'
        )}
      />
    </>
  )
}
