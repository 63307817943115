/* eslint-disable @typescript-eslint/no-explicit-any */
import { ICredential } from './../../types/interface/invoker.interface'
import { generateToken } from './../utils'
import axios, { AxiosRequestConfig } from 'axios'

interface UploadFileRequest {
  credential: ICredential
  file: File | (File & { previewURL: string })
  name: string
  contractAddress: string
  invoker?: string
  config?: AxiosRequestConfig
  chain: number
}

export const uploadFileAPI = async (
  request: UploadFileRequest,
  fileTags?: any
) => {
  const body = new FormData()
  body.append('file', request.file)
  body.append('name', request.name)

  const token = await generateToken(
    request.contractAddress,
    request.credential.editSecret || ''
  )
  return axios.post(
    `${process.env.REACT_APP_STORAGE_URL}/upload/`,

    body,
    {
      ...request.config,
      headers: {
        Authorization: `Bearer ${token}`,
        contract: request.contractAddress,
        invoker: request.invoker || '',
        chain: request.chain,
      },
      params: { tags: fileTags },
      // paramsSerializer: (param: qs.StringifiableRecord) => {
      //   return qs.stringify(param)
      // },
    }
  )
}
