/* eslint-disable @typescript-eslint/no-explicit-any */
import Gun, { IGunInstance, IGunChain, ISEAPair } from 'gun'
import 'gun/lib/radix'
import 'gun/lib/radisk'
import 'gun/lib/store'
import 'gun/lib/rindexed'
// TODO next explore these imports
// import 'gun/lib/open'
// import 'gun/lib/bye'
// import 'gun/lib/shim'
import Sea from 'gun/sea'
import { GunError, MissingAuthKeyError } from './errors'

export const instantiateSEA = () => {
  return Sea
}

export const seaInstance = instantiateSEA()
class GunInstanceClass {
  public gun: IGunInstance
  constructor() {
    this.gun = Gun({
      peers: [process.env.REACT_APP_GUN_URL as string],
      localStorage: false,
      radisk: true,
      retry: Infinity,
    })
  }
}

export const gunInstance = new GunInstanceClass().gun

export class GunInstance {
  public static getGunNode(node: string) {
    return gunInstance.get(node)
  }

  // TODO : Use this where required
  public static async getGunNodeOnDefined(
    gunNode: IGunChain<any, IGunInstance<any>, IGunInstance<any>, any>
  ) {
    return new Promise((resolve) => {
      gunNode.on((data: unknown, _key, _msg, event) => {
        if (data !== undefined) {
          event.off()
          resolve(data)
        }
      })
    })
  }

  public static async putGunNodeData(
    gunNode: IGunChain<any, IGunInstance<any>, IGunInstance<any>, any>,
    data: unknown
  ) {
    return new Promise((resolve, reject) => {
      gunNode.put(data, (ack: any) => {
        if (ack.err) {
          reject(new GunError(ack.err))
        } else {
          resolve(ack)
        }
      })
    })
  }

  public static getAuthenticatedUserNode(authKey: ISEAPair | undefined) {
    if (!authKey) throw new MissingAuthKeyError('Auth key is missing')
    return gunInstance.user().auth(authKey)
  }
}
