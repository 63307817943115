import { ApolloClient, InMemoryCache, gql } from '@apollo/client'
import {
  ProfileDocument,
  SingleProfileQueryRequest,
} from '../types/interface/graphGenerated'
import { resolveEnsAddress, resolveEnsName } from './networks/ensResolvers'

enum ENV {
  DEV = 'development',
  PROD = 'production',
  STAG = 'staging',
}

const APP_ENV =
  (process.env.REACT_APP_ENV as ENV.DEV | ENV.PROD | ENV.STAG) || ENV.DEV

const API_URL =
  APP_ENV === ENV.PROD
    ? 'https://api-v2.lens.dev'
    : 'https://api-v2-mumbai-live.lens.dev'

const LENS_HANDLE_NAMESPACE = APP_ENV === ENV.PROD ? 'lens' : 'test'

export const apolloClient = new ApolloClient({
  uri: API_URL,
  cache: new InMemoryCache(),
})

const GET_LENS_DEFAULT_PROFILE_QUERY = gql`
  query getLensProfile($request: DefaultProfileRequest!) {
    defaultProfile(request: $request) {
      handle {
        localName
        ownedBy
      }
    }
  }
`

const GET_LENS_PROFILE_OWNER_BY_HANDLE_QUERY = gql`
  query getLensProfileOwnerByHandle($request: HandleToAddressRequest!) {
    handleToAddress(request: $request)
  }
`

export const getDefaultProfileRequest = async (evmAddress: string) => {
  const { data = {} } = await apolloClient.query({
    query: GET_LENS_DEFAULT_PROFILE_QUERY,
    variables: {
      request: {
        for: evmAddress,
      },
    },
  })
  const { handle = {} } = data?.defaultProfile || {}
  const { localName = null } = handle
  return localName
}

export const getProfileRequest = async (request: SingleProfileQueryRequest) => {
  const result = await apolloClient.query({
    query: ProfileDocument,
    variables: {
      request,
    },
  })

  return result.data.profile?.ownedBy
}

export const getLensProfileOwnerByHandle = async (handle: string) => {
  try {
    const { data = {} } = await apolloClient.query({
      query: GET_LENS_PROFILE_OWNER_BY_HANDLE_QUERY,
      variables: {
        request: {
          handle: `${LENS_HANDLE_NAMESPACE}/${handle}`,
        },
      },
    })
    return data?.handleToAddress
  } catch (err) {
    console.log('Failed To Find Lens Profile Owner By Handle')
  }
}

export const getAddressName = async (address: string) => {
  const ensName = await resolveEnsAddress(address)
  if (ensName) return ensName
  const lensProfileName = await getDefaultProfileRequest(address)
  if (lensProfileName) return lensProfileName
  return address
}

export const resolveToEvmAddress = async (name: string) => {
  const ensAddress = await resolveEnsName(name)
  if (ensAddress) return ensAddress
  const lensProfileAddress = await getLensProfileOwnerByHandle(name)
  if (lensProfileAddress) return lensProfileAddress
  return null
}
