/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import CTAButton from '../common/CTAButton'
import { Popup } from './PopUp'
import xicon from '../../assets/x-icon.svg'

const WarningPopup = ({
  isOpen,
  popRef,
  closePopup,
  action,
}: {
  isOpen: boolean
  popRef: any
  closePopup: () => void
  action: () => void
}) => {
  return (
    <Popup isOpen={isOpen} width={'600px'}>
      <div
        ref={popRef}
        className="bg-white relative min-h-[250px] w-full p-4 rounded-lg gap-4 flex flex-col justify-center items-center"
      >
        <img
          onClick={() => closePopup()}
          src={xicon}
          className="w-8 cursor-pointer absolute top-4 right-4"
          alt="x-icon"
        />
        <h1 className="text-center font-bold text-2xl">Are you sure ?</h1>
        <p className="w-[500px] text-center text-lg">
          Note that this action will make you and your collaborators
          <strong> lose access </strong>
          to your existing <strong> private files </strong>
        </p>
        <div className="w-full flex mt-4 flex-wrap gap-16 justify-center">
          <CTAButton
            onClick={() => {
              closePopup()
            }}
            title={`Cancel`}
          />
          <CTAButton
            onClick={() => {
              action()
            }}
            backgroundColorClassName={'bg-red text-white'}
            title={`Confirm`}
          />
        </div>
      </div>
    </Popup>
  )
}

export default WarningPopup
