import { createSlice } from '@reduxjs/toolkit'

// export interface CommonStoreState {
//   isSafeApp: boolean
// }

const getInitialState = () => {
  return {
    isSafeApp: false,
  }
}

const commonStoreSlice = createSlice({
  name: 'commonStore',
  initialState: getInitialState(),
  reducers: {
    setSafeApp: (state, action) => {
      state.isSafeApp = action.payload
    },
  },
})

export default commonStoreSlice.reducer

export const { setSafeApp } = commonStoreSlice.actions
