import { http } from '@wagmi/core'
import { createConfig } from '@privy-io/wagmi'

import { defaultNetwork, gnosisChain, sepoliaChain } from './network-config'
import { type Chain } from 'viem'
import { RPC_URL_MAP, SupportedNetworks } from '../constants'
export const getChainsByDAppEnvironment = (): [Chain, ...Chain[]] => {
  if (defaultNetwork.chainId === 11155111) return [sepoliaChain, gnosisChain]
  return [gnosisChain, sepoliaChain]
}

export const config = createConfig({
  chains: getChainsByDAppEnvironment(),
  transports: {
    [gnosisChain.id]: http(RPC_URL_MAP[SupportedNetworks.Gnosis]),
    [sepoliaChain.id]: http(RPC_URL_MAP[SupportedNetworks.Sepolia]),
  },
})

export type GnosisChainId = 100
export type SepoliaChainId = 11155111

export type AllowedChainId = GnosisChainId | SepoliaChainId
