import { createSlice } from '@reduxjs/toolkit'

export enum TaskQueueType {
  Upload = 'Upload',
}

export enum QueueStatus {
  Idle = 'Idle',
  Running = 'Running',
}

export interface ITaskQueueState {
  type: TaskQueueType | ''
  argsQueue: unknown[]
  extraArgs: {
    [key: string]: unknown
  }
  queueStatus: QueueStatus
}

const initialState: ITaskQueueState = {
  type: '',
  argsQueue: [],
  extraArgs: {},
  queueStatus: QueueStatus.Idle,
}

const taskQueueSlice = createSlice({
  name: 'taskQueue',
  initialState,
  reducers: {
    setTaskQueue(state, action): void {
      state.type = action.payload.type
      state.argsQueue = action.payload.argsQueue
      state.extraArgs = action.payload.extraArgs
      state.queueStatus = QueueStatus.Running
    },
    updateTaskQueue(state, action): void {
      const { payload } = action
      const { position, args } = payload
      state.argsQueue[position] = args
    },
    clearTaskQueue(state) {
      state.type = ''
      state.argsQueue = []
      state.extraArgs = {}
      state.queueStatus = QueueStatus.Idle
    },
    setTaskQueueIsRunning(state, action): void {
      state.queueStatus = action.payload
    },
  },
})

export const {
  setTaskQueue,
  updateTaskQueue,
  clearTaskQueue,
  setTaskQueueIsRunning,
} = taskQueueSlice.actions

export default taskQueueSlice.reducer
