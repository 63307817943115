/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMediaQuery } from '@mui/material'
// import { ConnectButton } from '@rainbow-me/rainbowkit'
import React, { Ref, SetStateAction } from 'react'
import { usePrivyHelper } from '../../hooks/usePrivyHelper'
// import { useSafeAppStatus } from '../../store/common/hooks'
// import { isOriginGnosisBlockscout } from '../../utils/isOriginGnosisBlockscout'

const menuItemsInitialValue = [
  {
    title: 'About',
    link: 'https://about.fileverse.io/',
    target: '_blank',
  },
]

const AccountTab = ({
  handleDisconnectWallet,
  setAccountDropDown,
  signerAddress,
  width,
  right,
  top,
  accountRef,
}: {
  handleDisconnectWallet: any
  setAccountDropDown: SetStateAction<any>
  isCollaborator?: boolean
  signerAddress?: string
  isLocked?: boolean
  width?: string
  right?: string
  extraStyles?: string
  top?: string
  accountRef?: Ref<HTMLDivElement>
}) => {
  const isMediaMax1025px = useMediaQuery('(max-width : 1025px)')
  const { loginWithSocials, connectInjectedWallet } = usePrivyHelper()
  const connectButtonRef = React.useRef<HTMLDivElement>(null)
  // const isSafeApp = useSafeAppStatus()
  // const navTarget = isSafeApp || isOriginGnosisBlockscout ? '_self' : '_blank'
  return (
    <div
      ref={accountRef}
      data-cy="account-drop-down"
      className={`flex flex-col ${
        isMediaMax1025px ? 'top-[3rem]' : `${top || 'top-[50px]'}`
      } ${width ? width : 'w-[10rem] '} ${
        right ? right : 'right-0'
      } absolute   z-[99999] bg-white  border-fade border rounded-lg shadow-lg`}
    >
      {menuItemsInitialValue.map((item, index) => (
        <a href={item.link} key={index} target={item?.target}>
          <div className="h-[34px] flex items-center mx-[12px] px-2 py-2 border-fade border-bottom border-b">
            <span className="hover:cursor-pointer">{item.title}</span>
          </div>
        </a>
      ))}
      {signerAddress ? (
        <div
          data-cy="disconnect-btn"
          className="h-[34px] flex items-center mx-[12px] px-2 py-2 mb-2"
          onClick={() => {
            handleDisconnectWallet()
            setAccountDropDown(false)
          }}
        >
          <span className="hover:cursor-pointer text-red">{'Disconnect'}</span>
        </div>
      ) : (
        <>
          <div
            data-cy="wallet-connect-btn"
            id="wallet-connect-btn"
            ref={connectButtonRef}
            className="h-[34px] flex items-center  px-2 py-2 mx-[12px] border-fade border-bottom border-b"
            onClick={connectInjectedWallet}
          >
            <span className="hover:cursor-pointer">Connect Wallet</span>
          </div>
          <div
            data-cy="wallet-connect-btn"
            id="wallet-connect-btn"
            ref={connectButtonRef}
            className="flex items-center mx-[12px] px-2 py-2"
            onClick={loginWithSocials}
          >
            <span className="hover:cursor-pointer">Social Login</span>
          </div>
        </>
      )}
    </div>
  )
}

export default AccountTab
