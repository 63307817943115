/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { BaseSyntheticEvent, useState } from 'react'
import { ButtonType } from '../common'
import { TokenSelectionStep } from './TokenSelectionStep'
import cn from 'classnames'
import styles from './index.module.scss'
import { TextInput } from '../../../../Input'
import { UploadModalButton } from '../UploadModalButton'
import {
  capitalizeFirstLetter,
  getImgSrcForIPFS,
} from '../../../../../utils/string'
import { Image } from '../../../../Image'
import { Token } from '../../../../../types/interface/token.interface'
import { FileTypeEnum } from '../../../../../types/enum/file.enum'

export const TokenGatedStep = ({
  onBackClick,
  handleUploadFileEvent,
}: {
  onBackClick: () => void
  handleUploadFileEvent: (fileType: FileTypeEnum, extraArgs?: any) => void
}) => {
  const [selectedToken, setSelectedToken] = useState<any>(null)
  const [showFinalStep, setShowFinalStep] = useState(false)

  const onTokenSelect = (token: any) => {
    setSelectedToken(token)
  }

  const onButtonClick = (e: BaseSyntheticEvent) => {
    const buttonType = e.currentTarget.id
    switch (buttonType) {
      case ButtonType.Back:
        return onBackClick()
      case ButtonType.Continue:
        return setShowFinalStep(true)
      default:
        console.warn('Invalid button type')
        break
    }
  }

  const onShowTokenSelectionStep = () => {
    setShowFinalStep(false)
  }

  if (showFinalStep)
    return (
      <FinalTokenGatedStep
        selectedToken={selectedToken}
        onShowTokenSelectionStep={onShowTokenSelectionStep}
        handleUploadFileEvent={handleUploadFileEvent}
      />
    )

  return (
    <TokenSelectionStep
      onTokenSelect={onTokenSelect}
      selectedToken={selectedToken}
      onButtonClick={onButtonClick}
    />
  )
}

const FinalTokenGatedStep = ({
  selectedToken,
  onShowTokenSelectionStep,
  handleUploadFileEvent,
}: {
  selectedToken: any
  onShowTokenSelectionStep: () => void
  handleUploadFileEvent: (fileType: FileTypeEnum, extraArgs?: any) => void
}) => {
  const [tokenAmount, setTokenAmount] = useState<string>('')
  const { image, chain, symbol } = selectedToken

  const onChange = (e: BaseSyntheticEvent) => {
    const value = e.currentTarget.value
    if (value.includes('.')) return
    setTokenAmount(value)
  }

  const onContinueClick = () => {
    const extraArgs: Token = {
      name: selectedToken.symbol,
      image: selectedToken.image,
      contractAddress: selectedToken.contractAddress,
      tokenType: selectedToken.type,
      gateBalance: 1,
      chain: selectedToken.chain,
      type: selectedToken.type === 'erc20' ? 'token' : 'nft',
      id: selectedToken.id,
      decimals: selectedToken.decimals,
    }

    handleUploadFileEvent(FileTypeEnum.GATED, { ...extraArgs, tokenAmount })
  }

  const imageSrc = getImgSrcForIPFS(image)
  return (
    <div className={cn(styles.visiblity_step_container)}>
      <div className="flex flex-col w-[100%] items-center gap-[14px]">
        <div className={styles.token_desc_title}>Network</div>
        <div className={styles.token_desc_value}>
          {capitalizeFirstLetter(chain)}
        </div>
        <div className={styles.token_desc_title}>Token</div>
        <div className={styles.token_desc_value}>
          {image && (
            <div className={styles.image_container}>
              <Image src={imageSrc} />
            </div>
          )}
          {symbol}
        </div>
        <div className={styles.token_desc_title}>Required Amount</div>
        <TextInput
          onChange={onChange}
          customCss="text-center"
          placeholder="Enter token amount"
          type="number"
          value={tokenAmount}
        />
      </div>

      <div className={cn(styles.visiblity_cta, 'mt-auto')}>
        <UploadModalButton
          onClick={onShowTokenSelectionStep}
          id={ButtonType.Back}
          type="secondary"
        >
          Back
        </UploadModalButton>
        <UploadModalButton
          onClick={onContinueClick}
          id={ButtonType.Continue}
          isDisabled={!parseInt(tokenAmount)}
        >
          Continue
        </UploadModalButton>
      </div>
    </div>
  )
}
