/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, createContext, useCallback, useState } from 'react'
import { getHashedString } from '../../../../utils/ethUtils'
import { useHeartBit } from '@fileverse/heartbit-react'

interface IHeartBitContext {
  isMinted: boolean
  getUserBalance: (tokenId: string, userAddress: string) => Promise<void>
  userHeartBits: number
  setMinted: Dispatch<React.SetStateAction<boolean>>
}

export const HeartBitContext = createContext<IHeartBitContext | null>(null)
export const useHeartBitContext = () => {
  const context = React.useContext(HeartBitContext)
  return context || ({} as IHeartBitContext)
}

const Provider = ({ children }: { children: JSX.Element }) => {
  const [isMintedByUser, setIsMintedByUser] = useState<boolean>(false)

  const [userHeartBits, setUserHeartBits] = useState(0)
  const { getTotalHeartMintsByUser } = useHeartBit()

  const getUserBalance = useCallback(
    async (tokenId: string, userAddress: string) => {
      if (!tokenId || !userAddress) return
      const hashedURL = getHashedString(tokenId)
      const currentBalance = await getTotalHeartMintsByUser({
        hash: hashedURL,
        account: userAddress,
      })
      setIsMintedByUser(currentBalance > 0)
      setUserHeartBits(currentBalance)
    },
    [getTotalHeartMintsByUser]
  )

  return (
    <HeartBitContext.Provider
      value={{
        isMinted: isMintedByUser,
        getUserBalance,
        userHeartBits,
        setMinted: setIsMintedByUser,
      }}
    >
      {children}
    </HeartBitContext.Provider>
  )
}

export default Provider
