import React, { useEffect } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import FolderFile from './FolderFile'
import { useAppDispatch } from '../../store/hooks'
import { fetchAllFiles } from '../../store/files/thunks'

import PublicFolderCard from '../PublicPageComponents/PublicFolderCard'
import { folders } from '../PublicPageComponents/Constants'
import CreateFolderCard from '../PublicPageComponents/CreateFolderCard'

export default function Folders() {
  const [urlParams, setUrlParams] = useSearchParams()
  const { address: contractAddress } = useParams()
  const dispatch = useAppDispatch()
  const chainId = parseInt(urlParams.get('chainId') || '')

  useEffect(() => {
    if (!contractAddress) return
    dispatch(fetchAllFiles({ contractAddress, chainId }))
  }, [contractAddress])

  return urlParams.get('folder') ? (
    <FolderFile />
  ) : (
    <div
      data-cy="folder-tab"
      className={`w-full h-full overflow-scroll no-scrollbar justify-center lg:justify-start flex flex-row flex-wrap gap-[25px] mt-4`}
    >
      {folders.map((f) => (
        <div
          data-cy={`${f.title}-folder-card`}
          key={`folder-f-${f.title} h-full`}
          onClick={() => {
            urlParams.set('folder', f.title)
            setUrlParams(urlParams)
          }}
        >
          <PublicFolderCard
            width={'w-[236px]'}
            title={f.title}
            icon={f.icon}
            view={'Collaborator'}
          />
        </div>
      ))}
      {/* TODO: Coming soon */}
      <CreateFolderCard
        title="Create your folder"
        icon="Plus"
        width={'w-[236px]'}
      />
    </div>
  )
}
