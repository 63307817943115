import { Middleware } from '@reduxjs/toolkit'
import { actionConfig } from './actionConfig'
import { handleContractAction, handleInvokerAction } from './actionHelpers'

export const indexeddbMiddleware: Middleware =
  () => (next) => async (action) => {
    const handler = actionConfig[action.type]
    if (!handler) return next(action)

    const stateName = action.type.split('/')

    switch (stateName[0]) {
      case 'contract': {
        await handleContractAction(action, handler)
        break
      }
      case 'invoker': {
        await handleInvokerAction(action, handler)
        break
      }
      case 'newFile': {
        await handleContractAction(action, handler)
        break
      }
    }

    return next(action)
  }
