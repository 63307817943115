/* eslint-disable @typescript-eslint/no-explicit-any */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MediaFieldsFragment = {
  __typename?: 'Media'
  url: any
  width?: number | null
  height?: number | null
  mimeType?: any | null
}
export enum FollowModules {
  FeeFollowModule = 'FeeFollowModule',
  ProfileFollowModule = 'ProfileFollowModule',
  RevertFollowModule = 'RevertFollowModule',
  UnknownFollowModule = 'UnknownFollowModule',
}

type FollowModuleFields_FeeFollowModuleSettings_Fragment = {
  __typename?: 'FeeFollowModuleSettings'
  type: FollowModules
  recipient: any
  amount: {
    __typename?: 'ModuleFeeAmount'
    value: string
    asset: {
      __typename?: 'Erc20'
      name: string
      symbol: string
      decimals: number
      address: any
    }
  }
}

type FollowModuleFields_ProfileFollowModuleSettings_Fragment = {
  __typename?: 'ProfileFollowModuleSettings'
  type: FollowModules
  contractAddress: any
}

type FollowModuleFields_RevertFollowModuleSettings_Fragment = {
  __typename?: 'RevertFollowModuleSettings'
  type: FollowModules
  contractAddress: any
}

type FollowModuleFields_UnknownFollowModuleSettings_Fragment = {
  __typename?: 'UnknownFollowModuleSettings'
  type: FollowModules
  contractAddress: any
  followModuleReturnData: any
}
export type FollowModuleFieldsFragment =
  | FollowModuleFields_FeeFollowModuleSettings_Fragment
  | FollowModuleFields_ProfileFollowModuleSettings_Fragment
  | FollowModuleFields_RevertFollowModuleSettings_Fragment
  | FollowModuleFields_UnknownFollowModuleSettings_Fragment
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  BlockchainData: any
  BroadcastId: any
  ChainId: any
  CollectModuleData: any
  ContentEncryptionKey: any
  ContractAddress: any
  CreateHandle: any
  Cursor: any
  DateTime: any
  EncryptedValueScalar: any
  Ens: any
  EthereumAddress: any
  FollowModuleData: any
  Handle: any
  HandleClaimIdScalar: any
  IfpsCid: any
  InternalPublicationId: any
  Jwt: any
  LimitScalar: any
  Locale: any
  Markdown: any
  MimeType: any
  NftOwnershipId: any
  Nonce: any
  NotificationId: any
  ProfileId: any
  ProfileInterest: any
  ProxyActionId: any
  PublicationId: any
  PublicationTag: any
  PublicationUrl: any
  ReactionId: any
  ReferenceModuleData: any
  Search: any
  Signature: any
  Sources: any
  TimestampScalar: any
  TokenId: any
  TxHash: any
  TxId: any
  UnixTimestamp: any
  Url: any
  Void: any
}
export const ProfileFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bio' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attributes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'displayType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'traitType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isFollowedByMe' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isFollowing' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'who' },
                value: { kind: 'NullValue' },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'followNftAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'metadata' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDefault' } },
          { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'picture' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'NftImage' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contractAddress' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tokenId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'verified' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'MediaSet' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'original' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'MediaFields' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'small' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'MediaFields' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'medium' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'MediaFields' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coverPicture' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'NftImage' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contractAddress' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tokenId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'verified' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'MediaSet' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'original' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'MediaFields' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'small' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'MediaFields' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'medium' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'MediaFields' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'ownedBy' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dispatcher' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canUseRelay' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stats' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalFollowers' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalFollowing' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalPosts' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalComments' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalMirrors' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalPublications' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalCollects' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'followModule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FollowModuleFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'onChainIdentity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ens' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'proofOfHumanity' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sybilDotOrg' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'verified' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'source' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'twitter' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'handle' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'worldcoin' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isHuman' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
}
export const MediaFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MediaFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Media' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mimeType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MediaFieldsFragment, unknown>
export const FollowModuleFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FollowModuleFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FollowModule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FeeFollowModuleSettings' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'amount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'asset' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'symbol' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'decimals' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'address' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'recipient' } },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ProfileFollowModuleSettings' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contractAddress' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RevertFollowModuleSettings' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contractAddress' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UnknownFollowModuleSettings' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contractAddress' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'followModuleReturnData' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FollowModuleFieldsFragment, unknown>
export type DefaultProfileQuery = {
  __typename?: 'Query'
  defaultProfile?: {
    __typename?: 'Profile'
    id: any
    name?: string | null
    bio?: string | null
    isFollowedByMe: boolean
    isFollowing: boolean
    followNftAddress?: any | null
    metadata?: any | null
    isDefault: boolean
    handle: any
    ownedBy: any
    attributes?: Array<{
      __typename?: 'Attribute'
      displayType?: string | null
      traitType?: string | null
      key: string
      value: string
    }> | null
    picture?:
      | {
          __typename?: 'MediaSet'
          original: {
            __typename?: 'Media'
            url: any
            width?: number | null
            height?: number | null
            mimeType?: any | null
          }
          small?: {
            __typename?: 'Media'
            url: any
            width?: number | null
            height?: number | null
            mimeType?: any | null
          } | null
          medium?: {
            __typename?: 'Media'
            url: any
            width?: number | null
            height?: number | null
            mimeType?: any | null
          } | null
        }
      | {
          __typename?: 'NftImage'
          contractAddress: any
          tokenId: string
          uri: any
          verified: boolean
        }
      | null
    coverPicture?:
      | {
          __typename?: 'MediaSet'
          original: {
            __typename?: 'Media'
            url: any
            width?: number | null
            height?: number | null
            mimeType?: any | null
          }
          small?: {
            __typename?: 'Media'
            url: any
            width?: number | null
            height?: number | null
            mimeType?: any | null
          } | null
          medium?: {
            __typename?: 'Media'
            url: any
            width?: number | null
            height?: number | null
            mimeType?: any | null
          } | null
        }
      | {
          __typename?: 'NftImage'
          contractAddress: any
          tokenId: string
          uri: any
          verified: boolean
        }
      | null
    dispatcher?: {
      __typename?: 'Dispatcher'
      address: any
      canUseRelay: boolean
    } | null
    stats: {
      __typename?: 'ProfileStats'
      totalFollowers: number
      totalFollowing: number
      totalPosts: number
      totalComments: number
      totalMirrors: number
      totalPublications: number
      totalCollects: number
    }
    followModule?:
      | {
          __typename?: 'FeeFollowModuleSettings'
          type: FollowModules
          recipient: any
          amount: {
            __typename?: 'ModuleFeeAmount'
            value: string
            asset: {
              __typename?: 'Erc20'
              name: string
              symbol: string
              decimals: number
              address: any
            }
          }
        }
      | {
          __typename?: 'ProfileFollowModuleSettings'
          type: FollowModules
          contractAddress: any
        }
      | {
          __typename?: 'RevertFollowModuleSettings'
          type: FollowModules
          contractAddress: any
        }
      | {
          __typename?: 'UnknownFollowModuleSettings'
          type: FollowModules
          contractAddress: any
          followModuleReturnData: any
        }
      | null
    onChainIdentity: {
      __typename?: 'OnChainIdentity'
      proofOfHumanity: boolean
      ens?: { __typename?: 'EnsOnChainIdentity'; name?: any | null } | null
      sybilDotOrg: {
        __typename?: 'SybilDotOrgIdentity'
        verified: boolean
        source: {
          __typename?: 'SybilDotOrgIdentitySource'
          twitter: {
            __typename?: 'SybilDotOrgTwitterIdentity'
            handle?: string | null
          }
        }
      }
      worldcoin: { __typename?: 'WorldcoinIdentity'; isHuman: boolean }
    }
  } | null
}
export type DefaultProfileQueryVariables = Exact<{
  request: DefaultProfileRequest
}>

export const DefaultProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'defaultProfile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'request' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DefaultProfileRequest' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultProfile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'request' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'request' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProfileFields' },
                },
              ],
            },
          },
        ],
      },
    },
    ...ProfileFieldsFragmentDoc.definitions,
    ...MediaFieldsFragmentDoc.definitions,
    ...FollowModuleFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<DefaultProfileQuery, DefaultProfileQueryVariables>
export type DefaultProfileRequest = {
  ethereumAddress: Scalars['EthereumAddress']
}
export type ProfileQuery = {
  __typename?: 'Query'
  profile?: {
    __typename?: 'Profile'
    id: any
    name?: string | null
    bio?: string | null
    isFollowedByMe: boolean
    isFollowing: boolean
    followNftAddress?: any | null
    metadata?: any | null
    isDefault: boolean
    handle: any
    ownedBy: any
    attributes?: Array<{
      __typename?: 'Attribute'
      displayType?: string | null
      traitType?: string | null
      key: string
      value: string
    }> | null
    picture?:
      | {
          __typename?: 'MediaSet'
          original: {
            __typename?: 'Media'
            url: any
            width?: number | null
            height?: number | null
            mimeType?: any | null
          }
          small?: {
            __typename?: 'Media'
            url: any
            width?: number | null
            height?: number | null
            mimeType?: any | null
          } | null
          medium?: {
            __typename?: 'Media'
            url: any
            width?: number | null
            height?: number | null
            mimeType?: any | null
          } | null
        }
      | {
          __typename?: 'NftImage'
          contractAddress: any
          tokenId: string
          uri: any
          verified: boolean
        }
      | null
    coverPicture?:
      | {
          __typename?: 'MediaSet'
          original: {
            __typename?: 'Media'
            url: any
            width?: number | null
            height?: number | null
            mimeType?: any | null
          }
          small?: {
            __typename?: 'Media'
            url: any
            width?: number | null
            height?: number | null
            mimeType?: any | null
          } | null
          medium?: {
            __typename?: 'Media'
            url: any
            width?: number | null
            height?: number | null
            mimeType?: any | null
          } | null
        }
      | {
          __typename?: 'NftImage'
          contractAddress: any
          tokenId: string
          uri: any
          verified: boolean
        }
      | null
    dispatcher?: {
      __typename?: 'Dispatcher'
      address: any
      canUseRelay: boolean
    } | null
    stats: {
      __typename?: 'ProfileStats'
      totalFollowers: number
      totalFollowing: number
      totalPosts: number
      totalComments: number
      totalMirrors: number
      totalPublications: number
      totalCollects: number
    }
    followModule?:
      | {
          __typename?: 'FeeFollowModuleSettings'
          type: FollowModules
          recipient: any
          amount: {
            __typename?: 'ModuleFeeAmount'
            value: string
            asset: {
              __typename?: 'Erc20'
              name: string
              symbol: string
              decimals: number
              address: any
            }
          }
        }
      | {
          __typename?: 'ProfileFollowModuleSettings'
          type: FollowModules
          contractAddress: any
        }
      | {
          __typename?: 'RevertFollowModuleSettings'
          type: FollowModules
          contractAddress: any
        }
      | {
          __typename?: 'UnknownFollowModuleSettings'
          type: FollowModules
          contractAddress: any
          followModuleReturnData: any
        }
      | null
    onChainIdentity: {
      __typename?: 'OnChainIdentity'
      proofOfHumanity: boolean
      ens?: { __typename?: 'EnsOnChainIdentity'; name?: any | null } | null
      sybilDotOrg: {
        __typename?: 'SybilDotOrgIdentity'
        verified: boolean
        source: {
          __typename?: 'SybilDotOrgIdentitySource'
          twitter: {
            __typename?: 'SybilDotOrgTwitterIdentity'
            handle?: string | null
          }
        }
      }
      worldcoin: { __typename?: 'WorldcoinIdentity'; isHuman: boolean }
    }
  } | null
}

export const ProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'profile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'request' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SingleProfileQueryRequest' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'request' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'request' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProfileFields' },
                },
              ],
            },
          },
        ],
      },
    },
    ...ProfileFieldsFragmentDoc.definitions,
    ...MediaFieldsFragmentDoc.definitions,
    ...FollowModuleFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ProfileQuery, ProfileQueryVariables>

export type SingleProfileQueryRequest = {
  /** The handle for the profile */
  handle?: InputMaybe<Scalars['Handle']>
  /** The profile id */
  profileId?: InputMaybe<Scalars['ProfileId']>
}
export type ProfileQueryVariables = Exact<{
  request: SingleProfileQueryRequest
}>
export type QueryProfileArgs = {
  request: SingleProfileQueryRequest
}
