/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from 'react'
import {
  cn,
  formatCounter,
  Card,
  CardHeader,
  CardDescription,
  CardTitle,
  CardContent,
  CardFooter,
  CardProps,
  ButtonGroup,
  Icon,
  Button,
} from '@fileverse/ui'
import { Icon as DocIcon } from '../Icon'
import { motion } from 'framer-motion'
import { animationConfig } from '../CardControlWrapper'
import { getPublicFileUrl } from '../../../../utils/files/filePreviewHandlers'
import { Document, Page } from 'react-pdf'
import Spinner from '../../../../components/Spinner'

export type IDocumentCardProps = Omit<CardProps, 'type'> & {
  type: 'pdf' | 'xls' | 'doc' | 'ppt' | '3d'
  filePath: string
}

export const getDocIconName = (type: IDocumentCardProps['type']) => {
  switch (type) {
    case 'pdf':
      return 'FilePdf'
    case 'xls':
      return 'FileExcel'
    case 'doc':
      return 'FileWord'
    case 'ppt':
      return 'FilePpt'
    case '3d':
      return 'File3d'
  }
}

export const DocumentCard = ({
  title,
  size,
  type,
  url,
  likeCount,
  classNames,
  canShare = true,
  filePath,
  noFooter,
  onLike,
}: IDocumentCardProps & {
  filePath: string
  noFooter?: boolean
  onLike?: (e: any) => void
}) => {
  const [pdfPreview, setPdfPreview] = useState<string>()

  useEffect(() => {
    async function fetchContent() {
      const result = await getPublicFileUrl(url as string)
      setPdfPreview(result.downloadUrl)
    }
    fetchContent()
  }, [url])

  const PdfPreview = useMemo(() => {
    if (pdfPreview) {
      return (
        <Document
          loading={
            <div className="flex justify-center items-center w-full h-full">
              <Spinner />
            </div>
          }
          file={pdfPreview}
        >
          <div className="h-full py-3 w-full max-w-[200px]">
            <Page
              width={200}
              key={`page_1`}
              pageNumber={1}
              renderAnnotationLayer={true}
            />
          </div>
        </Document>
      )
    }
    return <DocIcon name="FilePdf" size="lg" />
  }, [pdfPreview])

  return (
    <Card size={size} className={cn('', classNames)}>
      <motion.div
        className="w-full h-full transition-opacity absolute top-0 left-0 p-5"
        layout="position"
        {...animationConfig}
      >
        <div
          className={cn(
            'flex justify-between h-[calc(100%-1rem)] pb-2',
            size !== 'md' ? 'flex-col' : 'flex-row',
            size !== 'sm' && 'gap-2'
          )}
        >
          <CardHeader
            className={cn('', {
              'flex-[0.8]': size === 'md',
            })}
          >
            <div className="flex flex-row justify-between">
              <DocIcon name={getDocIconName(type)} size="sm" />

              {size === 'sm' ? <CardDescription>{type}</CardDescription> : null}
            </div>
            <CardTitle
              className="line-clamp-5 text-heading-xsm-bold"
              title={title}
            >
              {title}
            </CardTitle>
          </CardHeader>
          <motion.div
            layout="position"
            className={cn(
              'transition-opacity',
              size !== 'lg'
                ? 'max-w-[60%] min-w-[60%]'
                : 'w-full h-full xl:h-[80%]'
            )}
            {...animationConfig}
          >
            <CardContent
              className={cn('rounded-sm color-bg-tertiary p-0 h-full')}
            >
              {size !== 'sm' ? (
                <CardDescription
                  className={cn(
                    'text-center flex flex-col justify-center items-center h-full overflow-hidden'
                  )}
                >
                  {type === 'pdf' ? (
                    PdfPreview
                  ) : (
                    <DocIcon name={getDocIconName(type)} size="lg" />
                  )}
                </CardDescription>
              ) : null}
            </CardContent>
          </motion.div>
        </div>
        {!noFooter && (
          <CardFooter className="justify-between h-4">
            <motion.div
              layout="position"
              className="flex justify-between items-center w-full transition-opacity"
              {...animationConfig}
            >
              <ButtonGroup className="space-x-0 gap-3">
                <Button
                  onClick={onLike}
                  variant="ghost"
                  size="md"
                  className="min-w-fit p-0"
                >
                  <Icon name="Heart" size="sm" fill="black" className="mr-1" />
                  <span className="max-w-fit">
                    {likeCount && likeCount >= 1
                      ? formatCounter(likeCount)
                      : 'Like'}
                  </span>
                </Button>
                {canShare && (
                  <Button
                    onClick={async (e) => {
                      e.stopPropagation()
                      if (!window.navigator.share) return
                      await window.navigator.share({
                        url: `${window.location.origin}/#${filePath}`,
                        title,
                      })
                    }}
                    variant="ghost"
                    size="md"
                    className="min-w-fit p-0 inline-flex lg:hidden"
                  >
                    <Icon
                      name="Share2"
                      size="sm"
                      fill="black"
                      className="mr-1"
                    />
                    Share
                  </Button>
                )}
              </ButtonGroup>
              {size !== 'sm' ? (
                <CardDescription className="text-muted-foreground">
                  {type}
                </CardDescription>
              ) : null}
            </motion.div>
          </CardFooter>
        )}
      </motion.div>
    </Card>
  )
}
