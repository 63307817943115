import { FileSource, FileTypeEnum } from '../types/enum/file.enum'

const fileTypeMapping = {
  [FileTypeEnum.PUBLIC]: 'public',
  [FileTypeEnum.PRIVATE]: 'private',
  [FileTypeEnum.MEMBERS_PRIVATE]: 'members_private',
  [FileTypeEnum.GATED]: 'gated',
}

export const getFileTags = (filetype: FileTypeEnum, filesource: FileSource) => {
  const tags = []
  if (filesource === FileSource.EXCALIDRAW) {
    tags.push('fileverse_whiteboard')
  }
  if (filesource === FileSource.COLLAB_DOCUMENT) {
    tags.push('fileverse_documents')
  }
  if (filesource === FileSource.USER_FILE_SYSTEM) {
    tags.push('fileverse_files')
  }
  if (filesource === FileSource.DPAGE) {
    tags.push('fileverse_dpage')
  }
  tags.push(fileTypeMapping[filetype])
  return tags
}
