/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import NotificationButton from '../Buttons/NotificationButton'
import { defaultNetwork } from '../../config/network-config'
import { getAddress } from 'ethers'
import { INotification } from './NotificationProvider'
import { useNotificationProvider } from '../../hooks/useNotificationProvider'
import { useSafeAppStatus } from '../../store/common/hooks'
import { isOriginGnosisBlockscout } from '../../utils/isOriginGnosisBlockscout'
import { AllowedChainId } from '../../config/wagmi-config'

const InviteNotificationSection = ({ data }: { data: INotification }) => {
  const contractAddress = getAddress(data.portalAddress)
  const chainId = defaultNetwork.chainId as AllowedChainId
  const provider = useNotificationProvider()
  const isSafeApp = useSafeAppStatus()
  const navTarget = isSafeApp || isOriginGnosisBlockscout ? '_self' : '_blank'
  return (
    <div className=" flex flex-col gap-1">
      <div className="flex gap-2">
        <NotificationButton
          preferred={true}
          title="Accept"
          onClick={() => {
            provider?.processNotification(data?._id)
            window.open(
              `${window.origin}${window.location.pathname}#/${contractAddress}?chainId=${chainId}`,
              navTarget
            )
          }}
        />
        <NotificationButton
          onClick={() => {
            provider?.rejectPortalInvite(data._id)
            provider?.processNotification(data?._id)
          }}
          title="Reject"
        />
      </div>
    </div>
  )
}

export default InviteNotificationSection
