import React, { Dispatch, SetStateAction } from 'react'
import CTAButton from '../common/CTAButton'
import { Popup } from '../Popup/PopUp'

const TiptapDocTitlePopup = ({
  titlePopUp,
  titlePopUpDiv,
  draftName,
  setDraftName,
  setTitlePopUp,
  publishDocument,
}: {
  titlePopUp: boolean
  titlePopUpDiv: React.RefObject<HTMLDivElement>
  draftName: string
  setDraftName: (draftName: string) => Promise<void>
  setTitlePopUp: Dispatch<SetStateAction<boolean>>
  publishDocument: () => void
}) => {
  return (
    <Popup isOpen={titlePopUp} width={'450px'}>
      <div ref={titlePopUpDiv} className="bg-white rounded-lg p-4">
        <h6 className="font-semibold">Add name</h6>
        <input
          className="border w-full mt-2 rounded-lg p-2"
          type="text"
          placeholder="Untitled"
          value={draftName}
          onChange={(e) => setDraftName(e.target.value)}
        />
        <div
          id="preview"
          className="h-60 p-4 overflow-scroll no-scrollbar w-full mt-2 border"
        ></div>
        <div className="flex justify-between w-full mt-4">
          <CTAButton
            onClick={() => {
              setTitlePopUp(false)
            }}
            title="Close"
          />
          <CTAButton
            isDisabled={!draftName}
            onClick={() => publishDocument()}
            backgroundColorClassName={
              'bg-black text-white border-black border-2'
            }
            title="Next"
          />
        </div>
      </div>
    </Popup>
  )
}

export default TiptapDocTitlePopup
