import React from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { useFilterFiles } from '../../store/files/hooks'
import PublicFileCard from '../PublicPageComponents/PublicFilesCard'
import { INewFile } from '../../types/interface/file.interface'

export default function FolderFile() {
  const { address: contractAddress } = useParams()
  const [urlParams] = useSearchParams()
  const filteredFiles = useFilterFiles(
    contractAddress as string,
    urlParams.get('folder') || '*'
  )
  if (filteredFiles.length === 0)
    return (
      <div className="flex w-full h-[70vh] justify-center items-center">
        <p className="text-heading-md">No file to show</p>
      </div>
    )

  return (
    <div className="flex flex-wrap gap-[25px] w-full overflow-y-scroll no-scrollbar mt-4 justify-center lg:justify-start">
      {filteredFiles.map((file: INewFile, id: number) => (
        <div key={`${contractAddress}-file-${id}`}>
          <PublicFileCard file={file} width={'w-[236px]'} />
        </div>
      ))}
    </div>
  )
}
