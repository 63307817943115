/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useEffect } from 'react'

interface DropdownContextValue {
  isOpen: boolean
  toggle: () => void
  onItemClick?: (arg: any) => any
  triggerRef: React.RefObject<any>
}

const DropdownContext = createContext<DropdownContextValue | null>(null)

export const useDropdown = () => {
  const context = React.useContext(DropdownContext)
  if (!context) {
    throw new Error('useDropdown must be used within a DropdownProvider')
  }
  return context
}

export const DropdownProvider = ({
  onItemClick,
  children,
}: {
  children: React.ReactNode
  onItemClick?: (arg: any) => any
}) => {
  const ref = React.useRef<any>(null)
  const [isOpen, setIsOpen] = React.useState(false)

  const toggle = () => setIsOpen((prev) => !prev)
  /**
   * Optional triggerRef:
     to be used in the case where you don't want the dropdown to close when clicking the items inside the dropdown.
     In this case ref value should be passed to dropdown container and triggerRef to the trigger element
   */
  const triggerRef = React.useRef<any | null>(null)
  const handleClickOutside = (event: MouseEvent) => {
    if (triggerRef.current && triggerRef.current.contains(event.target as Node))
      return
    if (!ref.current) return
    if (!ref.current.contains(event.target as Node)) setIsOpen(false)
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, {
      capture: true,
    })
    return () => {
      document.removeEventListener('click', handleClickOutside, {
        capture: true,
      })
    }
  }, [isOpen])

  const withRef = React.isValidElement(children)
    ? React.cloneElement(children, {
        ref: ref,
      } as any)
    : null

  return (
    <DropdownContext.Provider
      value={{
        isOpen,
        toggle,
        onItemClick,
        triggerRef,
      }}
    >
      {withRef}
    </DropdownContext.Provider>
  )
}
