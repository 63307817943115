/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-key */
import React, { ReactNode } from 'react'
import { FileType, Icon, LucideIcon, SegmentedControl } from '@fileverse/ui'
import { Box } from '@radix-ui/themes'
import { shareAnimationConfig, ShareDropDown } from './PublicPortalNavbar'
import { AnimatePresence, motion } from 'framer-motion'
import cn from 'classnames'
import { useMediaMax1025px } from '../../../hooks/useMediaQueryHook'
import { DocType } from '../interfaces'
import { useDropdown } from '../../../providers/DropdownContext'
export interface CardInteractionWrapperProps {
  children: ReactNode
  size: 'sm' | 'md' | 'lg'
  liked: boolean
  onLike: (e: any) => void
  onClick: () => void
  filePath: string
  type: FileType | DocType
  link: string
}

export const CardInteractionWrapper = React.forwardRef<
  HTMLDivElement,
  CardInteractionWrapperProps
>(
  (
    { children, size, liked, onLike, filePath, onClick, type, link, ...props },
    ref
  ) => {
    const { toggle, isOpen } = useDropdown()

    const isMediaMax1025px = useMediaMax1025px()
    const cardOptions = [
      <div onClick={toggle}>
        <Icon name="Share2" size={'md'} fill="black" />
      </div>,
      <div onClick={onLike}>
        <LucideIcon
          name="Heart"
          size={'md'}
          fill={liked ? 'red' : 'none'}
          stroke={liked ? 'red' : 'black'}
        />
      </div>,
    ]

    const linkToBeCopied =
      type === 'note'
        ? window.location.href
        : type === 'link'
        ? link
        : `${window.location.host}/#${filePath}`

    return (
      <Box
        onClick={onClick}
        ref={ref}
        className="w-full h-full"
        gridColumn={{
          initial:
            size === 'sm' ? 'span 2' : size === 'md' ? 'span 4' : 'span 4',
          md: size === 'sm' ? 'span 1' : size === 'md' ? 'span 2' : 'span 3',
        }}
        gridRow={{
          initial:
            size === 'sm' ? 'span 2' : size === 'md' ? 'span 2' : 'span 4',
          md: size === 'sm' ? 'span 2' : size === 'md' ? 'span 2' : 'span 4',
        }}
        style={{
          minWidth: size === 'sm' ? '156px' : size === 'md' ? '328px' : '328px',
          minHeight:
            size === 'sm' ? '212px' : size === 'md' ? '212px' : '440px',
          maxHeight:
            size === 'sm' ? '212px' : size === 'md' ? '212px' : '440px',
        }}
        {...props}
      >
        <div className="w-full h-full relative cursor-pointer group">
          {children}
          <div
            onClick={(e) => {
              e.stopPropagation()
            }}
            className={cn(
              ' absolute z-10 -bottom-6 left-1/2 transform -translate-x-1/2 hover:cursor-pointer group-hover:opacity-100 opacity-0 transition-opacity duration-300',
              !isOpen && 'invisible',
              !isMediaMax1025px && 'group-hover:visible'
            )}
          >
            <SegmentedControl
              showActiveState={false}
              options={cardOptions}
              container={true}
              setCurrentState={() => null}
            />
            {isOpen && (
              <AnimatePresence>
                <motion.div
                  key="shareDropDown"
                  {...shareAnimationConfig}
                  className="absolute right-4 bottom-[-190px] z-[99]"
                >
                  <ShareDropDown filePath={linkToBeCopied} />
                </motion.div>
              </AnimatePresence>
            )}
          </div>
        </div>
      </Box>
    )
  }
)

CardInteractionWrapper.displayName = 'CardInteractionWrapper'
