import React, { useCallback, useEffect, useState } from 'react'
import Spinner from '../../../Spinner'
import { Fingerprint, Info } from 'lucide-react'
import {
  IPendingStatusIndicatorProps,
  TContractInteractionIndicatorProps,
  TUploadingIndicatorProps,
  TaskQueueStatus,
} from '../../types'
import { WaitingTimer } from '..'

export const UploadingIndicator = (props: TUploadingIndicatorProps) => {
  const { handleFileUploads, canBeginExecution } = props
  const [hasFailed, setHasFailed] = useState(false)

  const startUpload = useCallback(async () => {
    if (!canBeginExecution) return
    try {
      setHasFailed(false)
      await handleFileUploads()
    } catch (err) {
      setHasFailed(true)
    }
  }, [canBeginExecution, handleFileUploads])

  useEffect(() => {
    startUpload()
  }, [startUpload])

  if (hasFailed) return <RetryIndicator onClick={startUpload} />
  return <LoadingIndicator />
}

export const ContractInteractionIndicator = (
  props: TContractInteractionIndicatorProps
) => {
  const {
    handleContractInteraction,
    contractInteractionRunning,
    isCurrentExecutingItem,
    signlessState,
  } = props
  const { isSignless, loading } = signlessState
  const [isLoading, setLoading] = useState(false)
  const [hasFailed, setHasFailed] = useState(false)

  const onRetryClick = () => {
    setHasFailed(false)
    onSignClick()
  }

  const onSignClick = useCallback(async () => {
    if (isLoading || contractInteractionRunning || hasFailed || loading) return
    if (isSignless && !isCurrentExecutingItem) return
    try {
      setLoading(true)
      setHasFailed(false)
      await handleContractInteraction(isSignless)
    } catch (err) {
      console.log(err)
      setHasFailed(true)
    } finally {
      setLoading(false)
    }
  }, [
    contractInteractionRunning,
    handleContractInteraction,
    hasFailed,
    isCurrentExecutingItem,
    isLoading,
    isSignless,
    loading,
  ])

  useEffect(() => {
    if (!isSignless) return
    onSignClick()
  }, [isSignless, onSignClick])

  if (hasFailed) return <RetryIndicator onClick={onRetryClick} />

  if (!isCurrentExecutingItem && !isLoading) return <WaitingTimer />
  if (isLoading || isSignless || loading) return <LoadingIndicator />
  return <Fingerprint className="cursor-pointer" onClick={onSignClick} />
}

export const PendingStatusIndicator = (props: IPendingStatusIndicatorProps) => {
  const { canBeginExecution, handleExecutionStart } = props

  useEffect(() => {
    if (!canBeginExecution) return
    handleExecutionStart()
  }, [canBeginExecution, handleExecutionStart])

  return <>{TaskQueueStatus.Pending}</>
}

export const LoadingIndicator = () => {
  return <Spinner width={4} height={4} />
}

export const RetryIndicator = ({ onClick }: { onClick: () => void }) => {
  return (
    <div
      onClick={onClick}
      className="cursor-pointer flex gap-[5px] items-center"
    >
      <span className="hover:underline">Retry</span>
      <Info color="red" />
    </div>
  )
}
