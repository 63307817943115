/* eslint-disable @typescript-eslint/no-explicit-any */
import { Editor, EditorContent } from '@tiptap/react'
import React, { useEffect, useRef } from 'react'
import { EditorBubbleMenu } from './EditorBubbleMenu'
import './styles/tiptap.scss'
import { EditingProvider } from '../../hooks/useEditingContext'
import 'tippy.js/animations/shift-toward-subtle.css'
import { SidebarMenu } from './SidebarMenu'
import { ColumnsMenu } from './extensions/MultiColumn/menus'

const TiptapEditor = ({
  editor,
  isDisabled,
  hasToC,
}: {
  editor: Editor
  isDisabled: boolean
  hasToC?: boolean
}) => {
  const ref = useRef<HTMLDivElement>(null)

  const focusEditor = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if ((ref.current as any)?.contains(e.target)) return
    editor?.chain().focus().run()
  }

  useEffect(() => {
    editor?.chain().focus()
  }, [])

  useEffect(() => {
    editor?.setEditable(!isDisabled)
  }, [isDisabled])

  return (
    <div onClick={focusEditor} className="w-full flex justify-center relative">
      {hasToC && <SidebarMenu editor={editor} hasToC={hasToC} />}
      <div className="w-full min-h-[50vh] min-w-[50%] max-w-5xl px-12 lg:pl-[0.75rem] pt-2">
        <div ref={ref} className="w-full h-full">
          {editor && !isDisabled && (
            <div>
              <EditorBubbleMenu editor={editor} />
              <ColumnsMenu editor={editor} appendTo={ref} />
            </div>
          )}
          <EditingProvider isDisabled={isDisabled}>
            <EditorContent editor={editor} />
          </EditingProvider>
        </div>
      </div>
    </div>
  )
}

export default TiptapEditor
