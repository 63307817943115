import React, { useRef } from 'react'

import {
  Carousel,
  CarouselContent,
  CarouselIndicator,
  CarouselItem,
} from '../../pages/PublicPortal/components/Carousel'
import Autoplay from 'embla-carousel-autoplay'
import { AnimatePresence, motion } from 'framer-motion'
import cn from 'classnames'
import { useMediaMax1025px } from '../../hooks/useMediaQueryHook'
import useMediaQuery from '../../hooks/useMediaQuery'

const data = [
  {
    imgSrc:
      'https://s3.eu-west-2.amazonaws.com/assets.fileverse.io/dapp/public/PICTURE3.png',
    title: ' What can I do with my Fileverse Portal?',
    description:
      'Fileverse Portal is an Onchain alternative to Google Workspace & Notion. Create, store, share any content including decentralised docs, knowledge bases, digital gardens, whiteboards, slides… on your own or as a group!',
  },
  {
    imgSrc:
      'https://s3.eu-west-2.amazonaws.com/assets.fileverse.io/dapp/public/PICTURE1+(1).png',
    title: 'Why Fileverse?',
    description:
      'Because we all deserve a better and healthier Internet experience. Fileverse uses peer-to-peer storage networks, end-to-end encryption, Onchain super powers. It’s fully owned by you. It’s all by design, not by promise dear anon <3',
  },
  {
    imgSrc:
      'https://s3.eu-west-2.amazonaws.com/assets.fileverse.io/dapp/public/PICTURE2+(1).png',
    title: 'Tell me more…',
    description:
      'Each Fileverse Portal is a self-deployed file management and collaboration smart contract. It acts as your content hash directory and allows you to set granular access permissions for your files, folders and Portal. The interface/UI is hosted on IPFS, making the whole collaboration app available to you without requiring our servers.',
  },
]

export const CarouselBanner = ({ showVideo }: { showVideo: boolean }) => {
  const plugin = useRef(
    Autoplay({
      delay: 4000,
      stopOnInteraction: false,
      stopOnLastSnap: false,
      stopOnMouseEnter: false,
      stopOnFocusIn: false,
    })
  )
  const isMediaMax1025px = useMediaMax1025px()
  const isMaxHeight800px = useMediaQuery('(max-height : 800px)')

  return (
    <div
      className={cn(
        'flex-1 h-full w-full px-4 lg:px-8',
        isMediaMax1025px && showVideo
          ? 'bg-white max-h-[265px] '
          : 'bg-yellow py-8',
        {
          ['max-h-[80vh] !px-3']: isMediaMax1025px && !showVideo,
          ['max-h-[510px] lg:max-h-full']: isMaxHeight800px,
        }
      )}
    >
      <div className="flex flex-col items-center justify-center h-full w-full text-left lg:py-8">
        {!showVideo ? (
          <Carousel
            plugins={[plugin.current]}
            className="w-full max-w-screen-sm px-0 pb-6 pt-4"
            onMouseEnter={plugin.current.stop}
            onMouseLeave={plugin.current.reset}
          >
            <CarouselContent>
              <AnimatePresence mode="popLayout">
                {data.map((item, index) => (
                  <CarouselItem key={index}>
                    <motion.div
                      initial={{ x: 10, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      exit={{ x: -10, opacity: 0 }}
                      transition={{
                        duration: 0.5,
                        ease: [0.48, 0.15, 0.25, 0.96],
                      }}
                      layout
                    >
                      {/* image section */}
                      <div
                        className={cn(
                          'w-full h-full flex flex-col justify-between items-center gap-8',
                          { ['gap-[16px]']: isMediaMax1025px }
                        )}
                      >
                        <div
                          className={cn(
                            'rounded overflow-hidden relative w-full h-full lg:max-h-[604px]',
                            {
                              ['lg:aspect-auto max-h-[40vh]']: isMediaMax1025px,
                            },
                            {
                              'lg:h-[60vh]': isMaxHeight800px,
                            }
                          )}
                        >
                          <img
                            src={item.imgSrc}
                            alt="carousel-image"
                            className="object-scale-down object-top rounded w-full h-full max-h-[40vh] lg:max-h-[604px] py-2"
                          />
                        </div>

                        <div className="space-y-2">
                          {/* title */}
                          <h4 className="text-heading-md-bold">{item.title}</h4>
                          {/* description */}
                          <p className="text-body-sm">{item.description}</p>
                        </div>
                      </div>
                    </motion.div>
                  </CarouselItem>
                ))}
              </AnimatePresence>
            </CarouselContent>
            <div className="flex gap-2 mt-4 w-full lg:max-w-xs">
              {data.map((_, index) => (
                <CarouselIndicator key={index} index={index} />
              ))}
            </div>
          </Carousel>
        ) : (
          <OnBoardingMediaAsset />
        )}
      </div>
    </div>
  )
}

export default CarouselBanner

const OnBoardingMediaAsset = () => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const isMaxHeight800px = useMediaQuery('(max-height : 800px)')
  return (
    <div className="w-full max-w-screen-sm">
      <div className="w-full h-full flex flex-col justify-between items-center gap-8">
        <div
          className={cn(
            'rounded overflow-hidden shadow-elevation-2 relative w-full h-full max-w-md min-w-[320px] lg:max-w-full',
            {
              ['lg:h-[604px]']: !isMaxHeight800px,
              ['lg:h-[60vh]']: isMaxHeight800px,
            }
          )}
        >
          <video
            playsInline
            ref={videoRef}
            preload="auto"
            autoPlay
            muted
            loop
            className={cn(
              'rounded object-cover aspect-video shadow-elevation-2',
              {
                ['lg:-top-[110%] lg:absolute lg:left-0 lg:min-h-[1920px] lg:min-w-[1080px] lg:aspect-auto']:
                  !isMaxHeight800px,
                ['lg:min-h-full lg:min-w-full lg:relative']: isMaxHeight800px,
              }
            )}
            poster="/images/portal_deployment.webp"
          >
            <source
              src="https://s3.eu-west-2.amazonaws.com/assets.fileverse.io/dapp/public/onboarding_vid.mp4"
              type="video/mp4"
            />
          </video>
        </div>

        <div className="space-y-2 hidden lg:block">
          {/* title */}
          <h4 className="text-heading-md-bold">
            Welcome to your onchain portal
          </h4>
          {/* description */}
          <p className="text-body-sm">
            A decentralised collaboration App and workspace. Discover new forms
            of group coordination, regain your privacy, and master your
            productivity. End-to-end encryption (E2EE) is a vital privacy
            protection.
          </p>
        </div>
      </div>
    </div>
  )
}
