import { ICredential } from './../../types/interface/invoker.interface'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { createMessageAPI } from '../../api/message/messageAPI'
import { MessageTopicEnum } from '../../types/enum/message.enum'
import { generateRSAKeyPair } from '../../utils/libCrypto'
import { convertTypedArrayToString } from '../../utils/string'
import { addKeys } from './reducer'
import * as ucans from 'ucans'
import { ISharingKey } from './interface'

interface RequestKeysPayload {
  invokerAddress: string
  credential: ICredential
  contractAddress: string
  chainId: number
  keySharingKeys: ISharingKey
}

export const requestServerKeys = createAsyncThunk(
  'keySharing/requestServerKeys',
  async (payload: RequestKeysPayload, { dispatch }) => {
    try {
      const ucanKeypair = ucans.EdKeypair.fromSecretKey(
        payload.credential.viewSecret
      )
      const keyExchangeKeyPair = await generateRSAKeyPair()
      const publicKey = await window.crypto.subtle.exportKey(
        'spki',
        keyExchangeKeyPair.publicKey
      )
      const privateKey = await window.crypto.subtle.exportKey(
        'pkcs8',
        keyExchangeKeyPair.privateKey
      )
      const encryptionKey = convertTypedArrayToString(new Uint8Array(publicKey))
      const decryptionKey = convertTypedArrayToString(
        new Uint8Array(privateKey)
      )
      if (
        !payload.keySharingKeys?.publicKey ||
        !payload.keySharingKeys?.privateKey
      ) {
        dispatch(
          addKeys({
            contractAddress: payload.contractAddress,
            privateKey: decryptionKey,
            publicKey: encryptionKey,
          })
        )
      }

      const ucan = await ucans.build({
        audience: process.env.REACT_APP_SERVICE_DID || '',
        issuer: ucanKeypair,
        lifetimeInSeconds: 7 * 86400,
        capabilities: [
          {
            with: {
              scheme: 'key_sharing',
              hierPart: payload.keySharingKeys?.publicKey || encryptionKey,
            },
            can: {
              namespace: 'server_keys',
              segments: ['REQUEST'],
            },
          },
        ],
      })

      const token = ucans.encode(ucan)
      const requestPayload = {
        contractAddress: payload.contractAddress,
        credential: payload.credential,
        invokerAddress: payload.invokerAddress,
        topic: MessageTopicEnum.KEY_REQUEST,
        content: token,
        forAddress: '*',
        chain: payload.chainId,
      }

      await createMessageAPI(requestPayload)
    } catch (error) {
      console.log(error, 'from request server keys')
    }
  }
)
