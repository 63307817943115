/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react'
import { NavLink, useParams, useSearchParams } from 'react-router-dom'
import { FilePreviewOnCard } from '../Files/FilePreviewOnCard'
import { INewFile } from '../../types/interface/file.interface'
import { useMediaQuery } from '@mui/material'
import LockIcon from '../../assets/fileLock.svg'
import { FileTypeEnum } from '../../types/enum/file.enum'
import { useServerKeys } from '../../store/invoker/hooks'
import { DocumentTypeExtensions, ThreeDTypeExtensions } from '../../constants'
import { verifyUserFileAccess } from '../../utils/files/encryption/utils'
import { getTrimmedName } from '../../utils/string'
import { useSafeAppStatus } from '../../store/common/hooks'
import { isOriginGnosisBlockscout } from '../../utils/isOriginGnosisBlockscout'
import cn from 'classnames'
import { usePrivyHelper } from '../../hooks/usePrivyHelper'
import { getFileIconBySource } from '../../pages/domain/Home/components/common/FileItem'
import { EllipsisVertical } from 'lucide-react'
import { RecentActionButton } from '../../pages/PublicPortal/components/PublicPortalNavbar'
import { DeleteFileModal } from '../../pages/domain/Home/components/DeleteFileModal'
import copyToClipboard from 'copy-to-clipboard'
import sendNotifcation from '../../utils/notification'
import { defaultNetwork } from '../../config/network-config'
import { useOnClickOutside } from 'usehooks-ts'
import { useEscapeKey } from '../../hooks/useEscapeKey'

interface FileCardProps {
  file: INewFile
  width: string
  handleBecomeAMemberButton?: () => void
  isMember?: any
}

const PublicFileCard = ({
  file,
  width,
  handleBecomeAMemberButton,
  isMember,
}: FileCardProps) => {
  const { address: contractAddress } = useParams()
  file.metadata.fileType === FileTypeEnum.MEMBERS_PRIVATE
  const walletAddress = usePrivyHelper().walletAddress
  const invokerAddress = walletAddress as string
  const serverKeys = useServerKeys(invokerAddress, contractAddress as string)
  const [searchParams] = useSearchParams()
  const chainId = parseInt(searchParams.get('chainId') || '')
  const userAccess = verifyUserFileAccess(file.metadata, serverKeys, isMember)
  const isSafeApp = useSafeAppStatus()
  const navTarget = isSafeApp || isOriginGnosisBlockscout ? '_self' : '_blank'
  const fileName = file.metadata.name
  const isMemberWithoutFileAccess =
    file.metadata.fileType === FileTypeEnum.MEMBERS_PRIVATE && !userAccess
  const textToCopy = `${window.origin}${window.location.pathname}#/${contractAddress}/file/${file.fileId}?chainId=${defaultNetwork.chainId}`
  const [isDropDownVisible, setDropdownVisible] = React.useState(false)
  const dropdownRef = React.useRef<HTMLDivElement>(null)
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false)

  useOnClickOutside(dropdownRef, () => setDropdownVisible(false))

  useEscapeKey(() => setDropdownVisible(false))

  return isMemberWithoutFileAccess ? (
    <MemberLockedFileCard
      handleBecomeAMemberButton={handleBecomeAMemberButton}
      width={width}
      file={file}
    />
  ) : (
    <NavLink
      to={`/${contractAddress}/file/${file.fileId}?chainId=${chainId}`}
      target={navTarget}
      className={'h-full'}
    >
      <div
        className={cn(
          'relative aspect-[3/2.2] rounded-lg p-3 space-y-3 cursor-pointer hover:bg-[#E8EBEC] color-bg-secondary transition-colors duration-200 ease-in-out',
          width || 'w-56'
        )}
        data-cy="file-card"
      >
        <div className="flex h-[15%] items-center justify-between">
          <div className="flex w-full gap-1 items-center">
            <div
              className={cn(
                !ThreeDTypeExtensions.includes(file.metadata?.extension) &&
                  !DocumentTypeExtensions.includes(file.metadata?.extension)
                  ? 'rounded color-bg-brand p-1'
                  : ''
              )}
            >
              {getFileIconBySource(file)}
            </div>
            <h6 className="color-text-default text-heading-xsm">
              {getTrimmedName(fileName, 7, 15)}
            </h6>
          </div>
          <div
            ref={dropdownRef}
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
            }}
            className="w-[36px] relative flex items-center justify-center h-[36px] hover:bg-[#F2F4F5] rounded-[4px]"
          >
            <EllipsisVertical
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                setDropdownVisible(!isDropDownVisible)
              }}
            />
            {isDropDownVisible && (
              <div className="absolute z-10 top-10 right-0">
                <RecentActionButton
                  onDelete={() => setOpenDeleteModal(true)}
                  onCopy={() => {
                    copyToClipboard(textToCopy)
                    sendNotifcation('Copied', '', 'warning')
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <div className="flex w-full h-[75%] bg-white overflow-hidden justify-center">
          <FilePreviewOnCard
            extension={file.metadata.extension}
            mimeType={file.metadata.mimeType}
            contentIPFSHash={file.contentIPFSHash}
            fileMetadata={file.metadata}
            isMember={isMember}
          />
        </div>
      </div>
      {openDeleteModal && (
        <DeleteFileModal
          file={file}
          isOpen={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
        />
      )}
    </NavLink>
  )
}

export default PublicFileCard

export const MemberLockedFileCard = ({
  width,
  file,
  handleBecomeAMemberButton,
}: {
  width: string
  file: INewFile
  handleBecomeAMemberButton?: () => void
}) => {
  const isTokenGated = file.metadata.fileType === FileTypeEnum.GATED
  const isMediaMax1280px = useMediaQuery('(max-width: 1580px)')
  return (
    <div className="h-full" data-cy="file-card">
      <div
        className={`bg-[#FBFBFB] hover:scale-[1.03] ease-in duration-200 border ${width} color-border-default aspect-[3/3.4] mr-4 drop-shadow-md rounded-lg cursor-pointer`}
      >
        <div className=" flex w-full h-[75%] justify-center">
          <div className="flex flex-col items-center h-full w-full justify-center">
            <img src={LockIcon} className=" w-10 mb-2" />
            <div className="text-[#000000] w-[98%] text-xs	font-normal  text-center">
              {isTokenGated
                ? 'This is a Token gated file and can only be viewed by token holders.'
                : 'This is a private and end-to-end encrypted file. Only members can view it.'}
            </div>
          </div>
        </div>

        <div className="h-[25%] rounded-b-lg flex items-center">
          <div className="text-md h-full w-full p-2 text-center">
            <div
              className="bg-yellow h-full flex items-center justify-center rounded-lg"
              onClick={handleBecomeAMemberButton}
            >
              <p className={`font-bold ${isMediaMax1280px && 'text-sm'} `}>
                Become a member
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
