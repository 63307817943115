/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { SetStateAction, useEffect, useRef } from 'react'
import { Editor, EditorContent, useEditor } from '@tiptap/react'
import { EditorBubbleMenu } from '../TipTap/EditorBubbleMenu'
import { EditingProvider } from '../../hooks/useEditingContext'
import 'tippy.js/animations/shift-toward-subtle.css'
import { SidebarMenu } from '../TipTap/SidebarMenu'
import { ColumnsMenu } from '../TipTap/extensions/MultiColumn/menus'
import { TiptapEditorProps } from '../TipTap/props'
import { defaultExtensions } from '../TipTap/extensions/defaultExtenstion'
import Collaboration from '@tiptap/extension-collaboration'
import * as Y from 'yjs'
import { AnyExtension, JSONContent } from '@tiptap/core'

const WebPageViewerV2 = ({
  editorData,
  isEditing,
  setEditor,
  ydoc,
  hasToC,
}: {
  ydoc?: Y.Doc
  editorData?: JSONContent
  isEditing: boolean
  setEditor?: React.Dispatch<SetStateAction<Editor | undefined>>
  hasToC?: boolean
}) => {
  const editor = useEditor({
    extensions: [
      ...(defaultExtensions as AnyExtension[]),
      ydoc
        ? Collaboration.configure({
            document: ydoc,
          })
        : ({} as AnyExtension),
    ],
    editorProps: TiptapEditorProps,
    content: editorData && editorData,
    editable: isEditing,
    autofocus: 'start',
  })

  const ref = useRef<HTMLDivElement>(null)

  const focusEditor = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if ((ref.current as any)?.contains(e.target)) return
    editor?.chain().focus().run()
  }

  useEffect(() => {
    editor && editor?.setEditable(isEditing)
  }, [isEditing])

  useEffect(() => {
    editor && setEditor?.(editor as Editor)
  }, [editor])

  useEffect(() => {
    if (editor && editorData)
      setTimeout(() => {
        editor?.commands.setContent(editorData.content!)
      }, 500)
  }, [editorData, editor])

  return (
    <div onClick={focusEditor} className="w-full flex justify-center relative">
      {hasToC && <SidebarMenu editor={editor as Editor} hasToC={hasToC} />}
      <div className="w-full min-h-[50vh] min-w-[50%] max-w-5xl px-12 lg:pl-[0.75rem] pt-2">
        <div ref={ref} className="w-full h-full">
          {editor && isEditing && (
            <div>
              <EditorBubbleMenu editor={editor as Editor} />
              <ColumnsMenu editor={editor as Editor} appendTo={ref} />
            </div>
          )}
          <EditingProvider isDisabled={!isEditing}>
            <EditorContent editor={editor as Editor} />
          </EditingProvider>
        </div>
      </div>
    </div>
  )
}

export default WebPageViewerV2
