import { NotificationType } from '../../types/enum/notification'
import { generateToken } from '../utils'
import axios from 'axios'
export type NotificationAudience =
  | 'members_only'
  | 'collaborators_only'
  | 'public'
  | 'individuals'
export interface INotificationContent {
  portalLogo?: string
  portalAddress?: string
  account?: string
  by?: string
  metadataIPFSHash?: string
  fileType?: string
  fileMetadata?: string
  fileId?: string
  link?: string
  text?: string
}
interface INotificationPost {
  content: INotificationContent
  audience: NotificationAudience
  forAddress?: [string]
  message: string
  type: NotificationType
  portalAddress: string
}
export const postNotification = async ({
  contractAddress,
  credentialEditSecret,
  invoker,
  chain,
  notification,
}: {
  contractAddress: string
  credentialEditSecret: string
  invoker: string
  chain: number
  notification: INotificationPost
}) => {
  const token = await generateToken(contractAddress, credentialEditSecret || '')
  const URL = `${process.env.REACT_APP_COORDINATOR_URL}/notification`

  return axios.post(URL, notification, {
    headers: {
      Authorization: `Bearer ${token}`,
      invoker,
      contract: contractAddress,
      chain,
    },
  })
}
