import React from 'react'
import PageWrapper from '../components/common/PageWrapper'
import ResetPortal from '../components/ResetPortal'

const ForceResetPage = () => {
  return (
    <PageWrapper>
      <ResetPortal />
    </PageWrapper>
  )
}

export default ForceResetPage
