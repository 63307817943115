/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useMediaQuery } from '@mui/material'
import etherumLogo from '../assets/etherumLogo.svg'
import createPortalLogo from '../assets/createPortalLogo.svg'
import { ContractCard } from '../components/Contracts/ContractCard'
import { useInvokerContracts } from '../store/invoker/hooks'
import { useNavigate } from 'react-router-dom'
import { formatAddress } from '../utils/formatAddress'
import PageWrapper from '../components/common/PageWrapper'
import cn from 'classnames'
import styles from './Portals.module.scss'
import { useAppDispatch } from '../store/hooks'
import RecoverPopup from '../components/Popup/RecoverPopup'
import { getPortalMetadataFromLastWorkingUrl } from '../store/contract/thunks'
import { usePrivyHelper } from '../hooks/usePrivyHelper'
import Spinner from '../components/Spinner'
import { Button } from '@fileverse/ui'
import { useSafeAppStatus } from '../store/common/hooks'

const Contracts = () => {
  const [openRecoveryPopup, setOpenRecoveryPopup] = useState<boolean>(false)
  const isMediaMax1025px = useMediaQuery('(max-width : 1025px)')
  const { isLoading, isDisconnected } = usePrivyHelper()

  const invokerAddress = usePrivyHelper().walletAddress as string

  const contractList = useInvokerContracts(invokerAddress)
  const navigate = useNavigate()
  const address = formatAddress(invokerAddress as string)

  const dispatch = useAppDispatch()
  const dispatchPortalLatestMetadata = (contractAddress: string) => {
    dispatch(
      getPortalMetadataFromLastWorkingUrl({ contractAddress, skipCache: true })
    )
  }

  useEffect(() => {
    contractList.forEach(dispatchPortalLatestMetadata)
  }, [contractList])

  if (isLoading)
    return (
      <PageWrapper noFooter={isMediaMax1025px}>
        <div className="h-full w-full flex justify-center items-center">
          <Spinner />
        </div>
      </PageWrapper>
    )
  return (
    <PageWrapper noFooter={isMediaMax1025px}>
      <div
        data-cy="my-portal-page"
        className={`h-full flex ${
          isMediaMax1025px ? 'rounded-lg' : 'overflow-y-scroll'
        }  flex-col no-scrollbar justify-center w-full`}
      >
        {invokerAddress && !isDisconnected ? (
          <>
            <div
              className={` w-full flex justify-center  h-full  ${
                isMediaMax1025px ? 'py-4' : ''
              } `}
            >
              <div className={`h-full  flex flex-col`}>
                <div
                  className={`w-full flex justify-center items-center text-center text-sm bg-[#FFF9CE] p-2 border-2 border-[#FFDF0A] rounded-t-lg`}
                >
                  <p>
                    Connecting from a new browser or device? Return to an
                    existing Portal{' '}
                    <span
                      onClick={() => setOpenRecoveryPopup(true)}
                      className=" text-[#0085FF] underline underline-offset-2 cursor-pointer"
                    >
                      here
                    </span>
                  </p>
                </div>
                <div
                  className={`h-full  flex flex-col gap-4 rounded-t-none rounded-lg border-t-0 border-2 color-border-default ${
                    isMediaMax1025px
                      ? 'w-full overflow-scroll no-scrollbar'
                      : ' overflow-hidden'
                  }    py-4`}
                >
                  <div
                    className={`w-full ${
                      isMediaMax1025px ? 'flex-col gap-2' : 'px-4'
                    } flex justify-end items-center`}
                  >
                    <p
                      className={` text-[#000000] ${
                        isMediaMax1025px
                          ? 'text-center font-medium text-lg'
                          : 'font-semibold text-2xl  w-[80%]'
                      }  `}
                    >
                      My Portal
                    </p>
                    <div
                      className={`${
                        isMediaMax1025px ? 'w-full' : ''
                      } flex justify-center`}
                    >
                      <div
                        className={`bg-[#000000] h-[50px] flex gap-4 w-[200px] justify-center rounded-lg px-4 py-2 items-center`}
                      >
                        <div className="flex gap-3">
                          <img
                            src={etherumLogo}
                            alt=""
                            className="w-[22px] h-[22px]"
                          />
                          <p className="text-[#FFFFFF]">{address}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={cn(styles.portals_wrapper, 'no-scrollbar')}>
                    <div className={styles.portals_container}>
                      <div className="h-[20rem] rounded-xl color-border-default  border-[1.5px]  justify-center flex items-center ">
                        <div
                          className={`w-full  h-auto p-4 flex-col gap-4  items-center`}
                        >
                          <p
                            className={`h-[20%] ${
                              isMediaMax1025px
                                ? 'text-center font-medium'
                                : ' font-semibold'
                            }  text-xl text-[#000000] mb-4`}
                          >
                            {isMediaMax1025px
                              ? 'It’s good to have your portal'
                              : 'Create as many Portals as you need'}
                          </p>
                          <div className="h-[150px] w-full mb-4">
                            <img
                              src={createPortalLogo}
                              alt=""
                              className="h-full w-full"
                            />
                          </div>
                          <div className="w-full flex justify-center">
                            <button
                              className="w-full max-w-[250px] flex items-center justify-center
                        mt-4   rounded-lg	h-10 bg-[#000000]
                        font-semibold	 text-[#FFFFFF] text-base"
                              onClick={() => navigate('/create')}
                            >
                              {isMediaMax1025px
                                ? 'Create portal'
                                : 'New portal'}
                            </button>
                          </div>
                        </div>
                      </div>
                      {contractList.map((contractAddress) => {
                        return (
                          <ContractCard
                            invokerAddress={invokerAddress}
                            contractAddress={contractAddress}
                            key={contractAddress}
                          />
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <RecoverPopup
              isOpen={openRecoveryPopup}
              setOpenRecoveryPopup={setOpenRecoveryPopup}
            />
          </>
        ) : (
          <ConnectWalletSection />
        )}
      </div>
    </PageWrapper>
  )
}

const ConnectWalletSection = () => {
  const { connectInjectedWallet, loginWithSocials } = usePrivyHelper()
  const isMobile = useMediaQuery('(max-width: 1025px)')
  const isSafeApp = useSafeAppStatus()
  return (
    <div className="h-full w-full flex justify-center items-center">
      <div
        className={cn(
          'border-[2px] border-[#E2E2E2] min-w-[45%] py-6 rounded-lg flex flex-col gap-4',
          isMobile ? '!px-2' : 'px-12'
        )}
      >
        <div className="text-center font-semibold  text-[#000000] text-2xl">
          Connect your Wallet
        </div>
        <div className="w-[100%] text-center font-medium text-base text-[#777777]">
          Please connect your wallet to continue
        </div>
        <div
          className={cn(
            'mt-4 w-full flex gap-[20px] flex-wrap',
            isSafeApp ? 'justify-center' : 'justify-evenly'
          )}
        >
          {isSafeApp ? null : (
            <Button
              onClick={loginWithSocials}
              className="rounded-[4px]"
              variant="secondary"
            >
              Sign in with Socials
            </Button>
          )}
          <Button
            onClick={connectInjectedWallet}
            className="rounded-[4px] min-w-[161px]"
          >
            Connect Wallet
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Contracts
