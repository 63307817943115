/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'

export type SortState = {
  keyName: string | null
  order: 'default' | 'asc' | 'desc'
}

export function useSortableData<TableData>(
  initialData: TableData[],
  defaultSortState?: SortState,
  customOrder?: { [key: string]: { [key: string]: number } }
) {
  const [data, setData] = useState<TableData[]>(initialData)
  const [sortState, setSortState] = useState<SortState>({
    keyName: defaultSortState?.keyName || null,
    order: defaultSortState?.order || 'default',
  })

  const getOrder = (keyName: string): 'asc' | 'desc' => {
    return sortState.keyName === keyName && sortState.order === 'asc'
      ? 'desc'
      : 'asc'
  }

  const compareValues = (
    valA: any,
    valB: any,
    newOrder: 'asc' | 'desc',
    keyName: string
  ) => {
    if (customOrder && customOrder[keyName]) {
      const orderA = customOrder[keyName][valA as unknown as string] ?? 0
      const orderB = customOrder[keyName][valB as unknown as string] ?? 0
      return newOrder === 'asc' ? orderA - orderB : orderB - orderA
    }

    if (typeof valA === 'number' && typeof valB === 'number') {
      return newOrder === 'asc' ? valA - valB : valB - valA
    }

    if (typeof valA === 'string' && typeof valB === 'string') {
      const comparison = valA.localeCompare(valB)
      return newOrder === 'asc' ? comparison : -comparison
    }

    return 0
  }

  const sortData = (keyName: string) => {
    const newOrder = getOrder(keyName)
    const sortedData = [...data].sort((a, b) => {
      const valA = a[keyName as keyof TableData]
      const valB = b[keyName as keyof TableData]
      return compareValues(valA, valB, newOrder, keyName)
    })

    setData(sortedData)
    setSortState({ keyName, order: newOrder })
  }

  useEffect(() => {
    setData(initialData)
  }, [initialData])

  return { data, sortState, sortData }
}
