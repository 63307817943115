import { createSlice } from '@reduxjs/toolkit'
import isNull from 'lodash/isNull'
import isEmpty from 'lodash/isEmpty'
import { ICredentialInvoker } from '../../types/interface/invoker.interface'
import { IInvokerState } from './interfaces'

export interface ISetAgentKeyPayload {
  invokerAddress: string
  agentKey: string
  contractAddress: string
}

export interface IRemoveAgentKeyPayload {
  invokerAddress: string
  contractAddress: string
}
export interface IAddContractToInvokerList {
  contractAddress: string
  invokerAddress: string
}
export interface ISetMemberKeyPayload {
  invoker: string
  contractAddress: string
  memberDecryptionKey: string
}
export interface IAddServerKeysPayload {
  contractAddress: string
  invokerAddress: string
  portalEncryptionKey: string
  portalDecryptionKey: string
  memberEncryptionKey: string
  memberDecryptionKey: string
  ownerDecryptionKey: string
  ownerEncryptionKey: string
  portalGunKey: string
  agentKey: string
}

const initialState: IInvokerState = {
  invokers: {},
  activeInvokerAddress: '',
}

const invokerSlice = createSlice({
  name: 'invoker',
  initialState,
  reducers: {
    updateInvokerCredentialReducer(
      state: IInvokerState,
      action: { payload: ICredentialInvoker; type: string }
    ): void {
      const { credential } = action.payload
      const contractAddress = credential.contractAddress
      const invokerAddress = action.payload.invokerAddress
      if (
        isNull(state.invokers[invokerAddress]) ||
        isEmpty(state.invokers[invokerAddress])
      ) {
        state.invokers[invokerAddress] = {
          address: invokerAddress,
          contracts: [],
          credentials: {},
          serverKeys: {},
        }
      }
      if (!state.invokers[invokerAddress].contracts.includes(contractAddress)) {
        state.invokers[invokerAddress].contracts.push(contractAddress)
      }
      state.invokers[invokerAddress].credentials[contractAddress] = credential
    },
    setInvokerAgentKey: (
      state: IInvokerState,
      action: {
        payload: ISetAgentKeyPayload
      }
    ) => {
      const { invokerAddress, agentKey, contractAddress } = action.payload
      if (isEmpty(state.invokers[invokerAddress])) {
        state.invokers[invokerAddress] = {
          address: invokerAddress,
          contracts: [],
          credentials: {},
          serverKeys: {},
        }
      }

      state.invokers[invokerAddress].serverKeys[contractAddress] = {
        ...state.invokers[invokerAddress].serverKeys[contractAddress],
        agentKey,
      }
    },
    removeAgentKey: (
      state: IInvokerState,
      action: {
        payload: IRemoveAgentKeyPayload
      }
    ) => {
      const { invokerAddress, contractAddress } = action.payload
      const invokerRecord = state.invokers[invokerAddress]
      if (!isEmpty(invokerRecord?.serverKeys[contractAddress]?.agentKey)) {
        invokerRecord.serverKeys[contractAddress].agentKey = ''
      }
    },
    addInvokerContract(
      state: IInvokerState,
      action: {
        payload: IAddContractToInvokerList
        type: string
      }
    ): void {
      const contractAddress = action.payload.contractAddress
      const invokerAddress = action.payload?.invokerAddress
      if (isEmpty(state.invokers[invokerAddress])) {
        state.invokers[invokerAddress] = {
          address: invokerAddress,
          contracts: [],
          credentials: {},
          serverKeys: {},
        }
      }
      if (!state.invokers[invokerAddress].contracts.includes(contractAddress)) {
        state.invokers[invokerAddress].contracts.push(contractAddress)
      }
    },
    addInvokerServerKeysReducer: (
      state: IInvokerState,
      action: {
        type: string
        payload: IAddServerKeysPayload
      }
    ): void => {
      const contractAddress = action.payload.contractAddress
      const invokerAddress = action.payload.invokerAddress
      if (isEmpty(state.invokers[invokerAddress])) {
        state.invokers[invokerAddress] = {
          address: invokerAddress,
          contracts: [],
          credentials: {},
          serverKeys: {},
        }
      }
      if (!state.invokers[invokerAddress].contracts.includes(contractAddress)) {
        state.invokers[invokerAddress].contracts.push(contractAddress)
      }
      state.invokers[invokerAddress].serverKeys[contractAddress] = {
        portalEncryptionKey: action.payload.portalEncryptionKey,
        portalDecryptionKey: action.payload.portalDecryptionKey,
        memberEncryptionKey: action.payload.memberEncryptionKey,
        memberDecryptionKey: action.payload.memberDecryptionKey,
        ownerDecryptionKey: action.payload.ownerDecryptionKey,
        ownerEncryptionKey: action.payload.ownerEncryptionKey,
        portalGunKey: action.payload.portalGunKey,
        agentKey: action.payload.agentKey,
      }
    },
    removeInvokerServerKeys: (
      state: IInvokerState,
      action: {
        payload: {
          contractAddress: string
          invokerAddress: string
        }
      }
    ): void => {
      const contractAddress = action.payload.contractAddress
      const invokerAddress = action.payload.invokerAddress
      if (isEmpty(state.invokers[invokerAddress])) return
      if (state.invokers[invokerAddress].serverKeys[contractAddress]) {
        state.invokers[invokerAddress].serverKeys[
          contractAddress
        ].portalDecryptionKey = ''
        state.invokers[invokerAddress].serverKeys[
          contractAddress
        ].portalEncryptionKey = ''
        state.invokers[invokerAddress].serverKeys[
          contractAddress
        ].memberDecryptionKey = ''
        state.invokers[invokerAddress].serverKeys[
          contractAddress
        ].memberEncryptionKey = ''
        state.invokers[invokerAddress].serverKeys[
          contractAddress
        ].portalGunKey = ''
      }
    },
    setInvokerMembersDecryptionKey: (
      state: IInvokerState,
      action: {
        type: string
        payload: ISetMemberKeyPayload
      }
    ): void => {
      const invokerAddress = action.payload.invoker
      const contractAddress = action.payload.contractAddress
      const memberDecryptionKey = action.payload.memberDecryptionKey
      if (isEmpty(state.invokers[invokerAddress])) {
        state.invokers[invokerAddress] = {
          address: invokerAddress,
          contracts: [],
          credentials: {},
          serverKeys: {},
        }
      }
      state.invokers[invokerAddress].serverKeys[contractAddress] = {
        ...state.invokers[invokerAddress].serverKeys[contractAddress],
        memberDecryptionKey,
      }
    },
  },
})

export const {
  updateInvokerCredentialReducer,
  addInvokerContract,
  addInvokerServerKeysReducer,
  setInvokerMembersDecryptionKey,
  setInvokerAgentKey,
  removeAgentKey,
  removeInvokerServerKeys,
} = invokerSlice.actions

export default invokerSlice.reducer
