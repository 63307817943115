import React from 'react'
import cn from 'classnames'
import styles from './StepItem.module.scss'
import { Onboarding } from '../../types/enum/onboarding.enum'
import { STEPS_MAP } from '../../constants'
import { LucideIcon } from '@fileverse/ui'

interface StepItemProps {
  isComplete: boolean
  isLastItem: boolean
  position: number
  isCurrent: boolean
  children?: React.ReactNode
  title: string
  nextOptional?: boolean
}

export const StepItem = ({
  isComplete,
  isLastItem,
  position,
  isCurrent,
  children,
  nextOptional = false,
}: StepItemProps) => {
  return (
    <>
      {isLastItem ? null : (
        <StepLine isComplete={isComplete} isOptional={nextOptional} />
      )}
      <div className="group relative flex items-start">
        <StepNumberContent
          position={position}
          isComplete={isComplete}
          isCurrent={isCurrent}
        />
        {children}
      </div>
    </>
  )
}

const StepLine = ({
  isComplete,
  isOptional,
}: {
  isComplete: boolean
  isOptional: boolean
}) => {
  const cssClasses = cn(
    'absolute left-4 top-4 -ml-px mt-0.5 h-[120%] w-0.5',
    styles.animated_stepper_line,
    { [styles.optional]: isOptional },
    { [styles.animated]: isComplete }
  )

  return <div className={cssClasses} aria-hidden="true" />
}

const StepNumberContent = ({
  position,
  isComplete,
  isCurrent,
}: Partial<StepItemProps>) => {
  const cssClasses = cn(
    'relative z-10 flex h-8 w-8 font-bold items-center justify-center rounded-full border-2',
    {
      'bg-black text-white border-black': isCurrent,
      // [styles.animated_stepper_circle]: isCurrent && position !== 1,
    },
    isComplete
      ? 'bg-black text-white border-black'
      : 'bg-[#F1F1F1] text-black border-[#F1F1F1]'
  )
  return (
    <span className="flex h-9 items-center">
      <span className={cssClasses}>
        {isComplete ? (
          <LucideIcon
            name="Check"
            size="sm"
            fill="transparent"
            stroke="white"
          />
        ) : (
          position
        )}
      </span>
    </span>
  )
}

export const StepContent = ({
  children,
  title,
  isComplete,
  isCurrent,
}: Partial<StepItemProps>) => {
  return (
    <span className="ml-6 flex gap-2 min-w-0 flex-col">
      <div className="flex items-start lg:items-center gap-4 lg:gap-2">
        <span
          className={cn(
            'text-[1rem] text-heading-sm-bold',
            !isCurrent && 'color-text-secondary',
            isComplete && 'color-text-default'
          )}
        >
          {title}
        </span>
        {title === STEPS_MAP[Onboarding.GENERATE].title && isCurrent && (
          <LucideIcon
            name="LoaderCircle"
            size="md"
            fill="transparent"
            className="animate-spin"
          />
        )}
      </div>
      <span
        className={cn(
          'text-body-sm color-text-secondary',
          !isCurrent ? 'hidden' : 'block'
        )}
      >
        {children}
      </span>
    </span>
  )
}
