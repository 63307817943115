import { createSlice } from '@reduxjs/toolkit'
import isEmpty from 'lodash/isEmpty'
import { IDocumentDraftsStore } from './interfaces'

const initialState: IDocumentDraftsStore = {
  documentDrafts: {},
}

const draftsSlice = createSlice({
  name: 'collaborations',
  initialState,
  reducers: {
    saveDocumentDraft: (state: IDocumentDraftsStore, action) => {
      const { collaboration, contractAddress, key } = action.payload

      if (state.documentDrafts[contractAddress]) {
        state.documentDrafts[contractAddress][key] = collaboration
      } else {
        state.documentDrafts[contractAddress] = { [key]: collaboration }
      }
    },
    removeDocumentDraft: (state: IDocumentDraftsStore, action) => {
      const { portal, key } = action.payload
      const records = Object.entries(state.documentDrafts[portal])
      const filteredRecords = records.filter((record) => record[0] !== key)
      state.documentDrafts[portal] = Object.fromEntries(filteredRecords)
    },
    setDocumentDraftTitle: (state: IDocumentDraftsStore, action) => {
      const { portalAddress, documentTitle, key } = action.payload
      const record = state.documentDrafts[portalAddress]
      if (!isEmpty(record)) {
        state.documentDrafts[portalAddress][key] = {
          ...record[key],
          title: documentTitle,
        }
      }
    },
  },
})

export const { saveDocumentDraft, removeDocumentDraft, setDocumentDraftTitle } =
  draftsSlice.actions

export default draftsSlice.reducer
