/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSelector } from '@reduxjs/toolkit'
import { AppState } from '..'
import {
  AudioTypeExtensions,
  DocumentTypeExtensions,
  ImageTypeExtensions,
  ThreeDTypeExtensions,
  VideoTypeExtensions,
} from '../../constants'
import { FileSource, FileTypeEnum } from '../../types/enum/file.enum'
import { INewFile } from '../../types/interface/file.interface'
import { useAppSelector } from '../hooks'
import { usePublicLayoutFileId } from '../contract/hooks'
import { PUBLIC_PORTAL_FILE_NAME } from '../../utils/publicPortalUtils'
import { SortOptions } from '../../pages/domain/Home/components/common'

export const useFileData = (
  contractAddress: string,
  fileId: any
): INewFile | undefined => {
  const fileData = useAppSelector((state: AppState) => {
    if (state.files.files[contractAddress]) {
      return state.files.files[contractAddress][fileId]
    }
  })
  return fileData
}

export const useAllContractFiles = (contractAddress: string) => {
  return useAppSelector(
    createSelector(
      (state: AppState) => state.files.files,
      (files) => files[contractAddress] || {}
    )
  )
}

export const useSortedFiles = (
  contractAddress: string,
  sortBy: SortOptions
) => {
  const files = useAllContractFiles(contractAddress)
  const publicPortalFileId = usePublicLayoutFileId(contractAddress)
  return Object.values(files)
    .sort((a, b) => {
      switch (sortBy) {
        case SortOptions.Edited:
          return (b.metadata?.editedAt || 0) - (a.metadata?.editedAt || 0)
        case SortOptions.Created:
          return b.metadata.uploadedAt - a.metadata.uploadedAt
        case SortOptions.Viewed:
          return b.metadata.uploadedAt - a.metadata.uploadedAt
        default:
          return b.metadata.uploadedAt - a.metadata.uploadedAt
      }
    })
    .filter((file: INewFile) => {
      return (
        file.fileId !== Number(publicPortalFileId) &&
        file?.metadata?.name?.toLowerCase() !== PUBLIC_PORTAL_FILE_NAME
      )
    })
}

export const useFileSearch = (contractAddress: string, searchValue: string) => {
  const files = useAllContractFiles(contractAddress)
  return Object.values(files).filter((file: INewFile) => {
    return (
      file?.metadata?.name
        ?.toLowerCase()
        .includes(searchValue?.toLowerCase()) &&
      file?.metadata?.name?.toLowerCase() !== PUBLIC_PORTAL_FILE_NAME
    )
  })
}

export const usePortalFilesCount = (contractAddress: string) => {
  const files = Object.values(useAllContractFiles(contractAddress))
  return files.length
}

export function usePublicFilesForMembers(contractAddress: string): INewFile[] {
  return useAppSelector((state: AppState) => {
    const allFiles = state.files.files[contractAddress] || {}
    return Object.values(allFiles).filter(
      (file: INewFile) => file.metadata.fileType !== FileTypeEnum.PRIVATE
    )
  })
}

export const useFilteredFiles = (
  contractAddress: string,
  searchBy = '',
  folder?: string,
  fileType: FileTypeEnum = FileTypeEnum.PUBLIC
) => {
  const filesByFolder = useFilterFiles(contractAddress, folder)
  const publicPortalFileId = usePublicLayoutFileId(contractAddress)
  return filesByFolder.filter(
    (file: INewFile) =>
      file.metadata.fileType === fileType &&
      file.fileId !== Number(publicPortalFileId) &&
      file?.metadata?.name?.toLowerCase() !== PUBLIC_PORTAL_FILE_NAME &&
      file?.metadata?.name?.toLowerCase().includes(searchBy.toLowerCase())
  )
}

export function useFilterFiles(
  contractAddress: string,
  folder?: string
): INewFile[] {
  const allFiles = useAllContractFiles(contractAddress)
  const publicPortalFileId = usePublicLayoutFileId(contractAddress)
  switch (folder) {
    case 'Images':
      return Object.values(allFiles).filter((file: INewFile) =>
        ImageTypeExtensions.includes(file.metadata.extension)
      )
    case 'Videos':
      return Object.values(allFiles).filter((file: INewFile) =>
        VideoTypeExtensions.includes(file.metadata.extension)
      )
    case 'Audios':
      return Object.values(allFiles).filter((file: INewFile) =>
        AudioTypeExtensions.includes(file.metadata.extension)
      )
    case 'Documents':
      return Object.values(allFiles).filter(
        (file: INewFile) =>
          DocumentTypeExtensions.includes(file.metadata.extension) &&
          file.metadata.source !== FileSource.EXCALIDRAW &&
          file.metadata.source !== FileSource.COLLAB_DOCUMENT &&
          file.metadata.source !== FileSource.DPAGE &&
          file.fileId !== Number(publicPortalFileId) &&
          file?.metadata?.name?.toLowerCase() !== PUBLIC_PORTAL_FILE_NAME
      )
    case '3D':
      return Object.values(allFiles).filter((file: INewFile) =>
        ThreeDTypeExtensions.includes(file.metadata.extension)
      )
    case 'Whiteboards':
      return Object.values(allFiles).filter(
        (file: INewFile) => file.metadata.source === FileSource.EXCALIDRAW
      )
    case 'Notes':
      return Object.values(allFiles).filter(
        (file: INewFile) => file.metadata.source === FileSource.COLLAB_DOCUMENT
      )
    case 'dPages':
      return Object.values(allFiles).filter(
        (file: INewFile) => file.metadata.source === FileSource.DPAGE
      )
    default:
      return Object.values(allFiles).filter(
        (file: INewFile) =>
          file.fileId !== Number(publicPortalFileId) &&
          file?.metadata?.name?.toLowerCase() !== PUBLIC_PORTAL_FILE_NAME
      )
  }
}
export function useFilterForOnlyMembers(
  contractAddress: string,
  folder?: string
): INewFile[] {
  const filteredFiles = useFilterFiles(contractAddress, folder)
  return filteredFiles.filter((file: INewFile) => {
    return file.metadata.fileType !== FileTypeEnum.PRIVATE
  })
}
