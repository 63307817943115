/* eslint-disable @typescript-eslint/no-explicit-any */

const convertTypedArrayToString = (data: Uint8Array) => {
  return window.btoa(
    Array.from(data)
      .map((c) => String.fromCharCode(c))
      .join('')
  )
}

const convertStringToTypedArray = (data: string): Uint8Array => {
  const binaryString = window.atob(data)
  const uint8 = new Uint8Array(binaryString.length)
  for (let i = 0; i < binaryString.length; i++) {
    uint8[i] = binaryString.charCodeAt(i)
  }
  return uint8
}

const toLowerCase = (value: unknown) => {
  if (typeof value !== 'string') return value
  return value.toLowerCase()
}

const getMaskedString = (input: any, maskLength: number): string => {
  if (input.length > 16) {
    return '*'.repeat(maskLength)
  } else {
    return '*'.repeat(input.toString().length)
  }
}

const getSlicedName = (name: string, length: number) => {
  if (!name) return 'Untitled'
  return name.length > length ? `${name.slice(0, length)}...` : name
}

const getTrimmedName = (name: string, length: number, limit: number) => {
  if (name?.length > limit) {
    const first = name.substring(0, length)
    const last = name.substring(name?.length - length)
    return `${first}...${last}`
  }
  return name
}

const getImgSrcForIPFS = (value: string) => {
  if (!value) return value
  if (value.startsWith('ipfs://'))
    return `https://ipfs.io/ipfs/${value.slice(7)}`
  return value
}

const capitalizeFirstLetter = (value: string) => {
  if (!value) return value
  if (value.length === 1) return value.toUpperCase()
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export const formatLargeNumbers = (input: number): string => {
  if (input < 1000) return input.toString()
  if (input < 10000)
    return input.toString().slice(0, 1) + ',' + input.toString().slice(1)
  if (input < 1000000) {
    if (input % 1000 === 0) return input / 1000 + 'K'
    return (input / 1000).toFixed(1) + 'K'
  }
  if (input % 1000000 === 0) return input / 1000000 + 'M'
  return (input / 1000000).toFixed(1) + 'M'
}

export {
  convertTypedArrayToString,
  convertStringToTypedArray,
  // convertUint8ToBase64,
  // convertToBase64String,
  // convertBase64ToString,
  toLowerCase,
  getMaskedString,
  getSlicedName,
  getTrimmedName,
  getImgSrcForIPFS,
  capitalizeFirstLetter,
}
