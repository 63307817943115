import { ENV, APP_ENV } from '../constants'

type ArweaveConfig = {
  token: string
  url: string
  chainId: number
  chainIdHex: string
  chainName: string
  orignalChainIdHex: string
  orignalChainId: number
}

type ArweaveConfigMap = {
  [key in ENV]: ArweaveConfig
}

export const ARWEAVE_CONFIG_MAP: ArweaveConfigMap = {
  [ENV.DEV]: {
    token: 'ethereum',
    url: 'https://up.arweave.net',
    chainId: 11155111,
    chainIdHex: '0xaa36a7',
    chainName: 'Sepolia',
    orignalChainIdHex: '0xaa36a7',
    orignalChainId: 11155111,
  },
  [ENV.PROD]: {
    token: 'ethereum',
    url: 'https://up.arweave.net',
    chainId: 137,
    chainIdHex: '0x89',
    chainName: 'Polygon Mainnet',
    orignalChainIdHex: '0x64',
    orignalChainId: 100,
  },
  [ENV.STAG]: {
    token: 'ethereum',
    url: 'https://up.arweave.net',
    chainId: 80001,
    chainIdHex: '0x13881',
    chainName: 'Polygon Mumbai',
    orignalChainIdHex: '0xaa36a7',
    orignalChainId: 11155111,
  },
}

export const ARWEAVE_CONFIG: ArweaveConfig = ARWEAVE_CONFIG_MAP[APP_ENV]
