/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMediaQuery } from '@mui/material'
import React, { Dispatch, SetStateAction, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import cancelIcon from '../../assets/cancelIcon.svg'
import nftCollection from '../../assets/nftCollections.svg'
import { useAppDispatch } from '../../store/hooks'
import { addInvokerCredentialsThunk } from '../../store/invoker/thunks'
import sendNotifcation from '../../utils/notification'
import { TxnHashPreview } from '../FileUploadSection/TxnHashPreview'
import { useContractChainId } from '../../store/contract/hooks'
import { Popup } from './PopUp'
import {
  parseTrxEventLogs,
  waitForTransactionReceipt,
} from '../../utils/transaction/trxUtils'
import { generateKeyPairs, generateServerKeys } from '../../utils/keys'

import { addCollaboratorToStore } from '../../store/contract/reducer'
import { createMessageAPI } from '../../api/message/messageAPI'
import { MessageTopicEnum } from '../../types/enum/message.enum'
import { addKeys } from '../../store/keySharing/reducer'
import {
  RelayerError,
  ResolveEventError,
  SwitchNetworkRejected,
} from '../../utils/errors'
import { CustomButton } from '../CustomButton'

import cn from 'classnames'
import { registerCollaboratorTrx } from '../../utils/transaction'
import {
  ContractType,
  PortalContractEventEnum,
} from '../../utils/transaction/interface'
import { usePrivyHelper } from '../../hooks/usePrivyHelper'

const RegisterCollaboratorPopup = ({
  collaboratorPopUpRef,
  isPopUpVisible,
  setPopupVisibility,
}: {
  collaboratorPopUpRef?: React.RefObject<HTMLDivElement>
  isPopUpVisible: boolean
  setPopupVisibility: Dispatch<SetStateAction<boolean>>
}) => {
  const [registering, setRegistering] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const { address: contractAddress } = useParams()

  const walletAddress = usePrivyHelper().walletAddress
  const chainId = useContractChainId(contractAddress as string)
  // const keySharingKeys = getContractKeySharingKeys(contractAddress as string)
  const isMediaMax1025px = useMediaQuery('(max-width : 1025px)')
  const [txHash, setTxHash] = useState('')
  const navigate = useNavigate()

  const handleRegistration = async () => {
    try {
      setRegistering(true)

      const collaboratorKeys = await generateKeyPairs()

      const trxHash = await registerCollaboratorTrx(
        contractAddress as string,
        walletAddress as string,
        { viewDID: collaboratorKeys.viewDID, editDID: collaboratorKeys.editDID }
      )
      setTxHash(trxHash)
      const { logs } = await waitForTransactionReceipt(trxHash)

      parseTrxEventLogs(
        logs,
        ContractType.Portal,
        PortalContractEventEnum.REGISTERED_COLLABORATOR_KEYS
      )

      const { encryptionKey, decryptionKey, token } = await generateServerKeys(
        collaboratorKeys.viewSecret
      )

      await createMessageAPI({
        contractAddress: contractAddress as string,
        invokerAddress: walletAddress as string,
        content: token,
        topic: MessageTopicEnum.KEY_REQUEST,
        forAddress: '*',
        chain: chainId as number,
        credential: {
          ...collaboratorKeys,
          contractAddress: contractAddress as string,
        },
      })

      dispatch(
        addCollaboratorToStore({
          contractAddress: contractAddress as string,
          collaboratorAddress: walletAddress as string,
        })
      )
      dispatch(
        addKeys({
          contractAddress: contractAddress as string,
          privateKey: decryptionKey,
          publicKey: encryptionKey,
        })
      )
      await dispatch(
        addInvokerCredentialsThunk({
          credential: {
            ...collaboratorKeys,
            contractAddress: contractAddress as string,
          },
          invokerAddress: walletAddress as string,
        })
      )
      navigate(`/${contractAddress}/settings?chainId=${chainId}`)
      setPopupVisibility(false)
    } catch (error) {
      if (error instanceof SwitchNetworkRejected)
        sendNotifcation(
          'Switch network rejected',
          'You need to switch to the correct network to register as a collaborator',
          'danger'
        )
      else if (error instanceof RelayerError)
        sendNotifcation(
          'Relayer error',
          'Something went wrong. Please try again',
          'danger'
        )
      else if (error instanceof ResolveEventError)
        sendNotifcation(
          'Resolve event error',
          'Something went wrong. Please try again',
          'danger'
        )
      else
        sendNotifcation(
          'Registration failed',
          'Something went wrong. Please try again',
          'danger'
        )
      console.log(error)
    } finally {
      setRegistering(false)
    }
  }

  return (
    <Popup isOpen={isPopUpVisible} width={'570px'}>
      <div
        data-cy="register-as-collaborator-pop-up"
        ref={collaboratorPopUpRef}
        className={cn(
          'bg-white ounded-lg flex relative justify-center items-center',
          isMediaMax1025px ? 'p-4' : 'p-8'
        )}
      >
        <img
          onClick={() => {
            if (registering) return
            setPopupVisibility(false)
          }}
          data-cy="cancel-collab-register-pop-up"
          src={cancelIcon}
          className="rounded-[50%] w-8 absolute top-2 right-2 cursor-pointer  bg-[#DDDDDD]"
        />
        <div className="w-full">
          <h6 className="text-2xl font-bold">
            Yay! You&apos;re indeed a Collaborator!
          </h6>
          <p className="mt-4">
            You&apos;re now one step away to get full-access to this Portal.
            Complete the on-chain registration to get started!
          </p>
          <div
            className={cn(
              'w-full justify-center flex mt-4',
              isMediaMax1025px ? '' : 'p-4'
            )}
          >
            <div
              className={cn(
                isMediaMax1025px ? 'w-full max-w-[400px]' : 'w-[80%]'
              )}
            >
              <div className="flex mb-4 items-center">
                <div className="w-[20%] mr-2">
                  <div className="w-16 h-16 border border-[#D4A992] flex items-center rounded-lg justify-center bg-[#FFF2EB]">
                    <img src={nftCollection} />
                  </div>
                </div>
                <div className="w-[80%]">
                  <p>
                    Benefit 1: Access the Private side of this Portal to live
                    collaborate with the Owner and other Colalborators.
                  </p>
                </div>
              </div>
              <div className="flex mb-4  items-center">
                <div className="w-[20%] mr-2">
                  <div className="w-16 h-16 flex border-[#FFAF51] items-center rounded-lg  justify-center bg-[#FFD8D8]">
                    <img src={nftCollection} />
                  </div>
                </div>
                <div className="w-[80%]">
                  <p>
                    Benefit 2: Collaborators can created Whiteboards, collab
                    documents, and so much more!
                  </p>
                </div>
              </div>
              <div className="flex mb-4  items-center">
                <div className="w-[20%] mr-2">
                  <div className="w-16 h-16 flex items-center border-[#FFDF0A] rounded-lg justify-center bg-[#FFF5B0]">
                    <img src={nftCollection} />
                  </div>
                </div>
                <div className="w-[80%]">
                  <p>
                    Benefit 3: Let the owner of the Portal know you&apos;re now
                    ready. Enter the private space of on-chain coordination.
                  </p>
                </div>
              </div>
              <div className="w-full mt-8">
                {txHash ? (
                  <TxnHashPreview TXNhash={txHash} />
                ) : (
                  <CustomButton
                    onClick={handleRegistration}
                    isLoading={registering}
                    customCss="w-full hover:scale-[1.03] ease-in duration-200"
                  >
                    Register
                  </CustomButton>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default RegisterCollaboratorPopup
