import { ICredential } from './../../types/interface/invoker.interface'
import axios from 'axios'
import { IContract } from '../../types/interface/contract.interface'
import { generateToken } from '../utils'

interface OrgAnalyticsRequest {
  contract: IContract
  credential: ICredential
  invoker?: string
  chain: number
}

export const orgAnalyticsAPI = async (request: OrgAnalyticsRequest) => {
  const token = await generateToken(
    request.contract.contractAddress,
    request.credential.editSecret || ''
  )
  return axios.get(`${process.env.REACT_APP_STORAGE_URL}/analytics`, {
    headers: {
      Authorization: `Bearer ${token}`,
      contract: request.contract.contractAddress,
      invoker: request.invoker || '',
      chain: request.chain,
    },
  })
}
