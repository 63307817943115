/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { SetStateAction, useEffect, useState } from 'react'
import EditorJS, { OutputData } from '@editorjs/editorjs'
import Header from '@editorjs/header'
import Delimiter from '@editorjs/delimiter'
import Checklist from '@editorjs/checklist'
import SimpleImage from '@maitrakhatri/editorjs-simple-image'
import Code from '@editorjs/code'
import Embed from '@editorjs/embed'
import Table from '@editorjs/table'
import InlineCode from '@editorjs/inline-code'
import Underline from '@editorjs/underline'
import Paragraph from 'editorjs-paragraph-with-alignment'
import DragDrop from 'editorjs-drag-drop'
import Marker from '@editorjs/marker'
import Hyperlink from 'editorjs-hyperlink'
import NestedList from '@editorjs/nested-list'
import { useParams } from 'react-router-dom'
import { getISEAKeyPair } from '../../utils/libCrypto'
import { SEA } from 'gun'
import uuid from 'react-uuid'
import { saveEditorContentOnGun } from './saveEditorContentOnGun'
import { useGunNodes } from '../../hooks/useGunNode'

function Editor({
  documentKey,
  setEditorData,
  isDisabled,
}: {
  documentKey: string
  setEditorData: React.Dispatch<SetStateAction<OutputData>>
  isDisabled: boolean
}) {
  const id = uuid()
  const key = documentKey
  const { rtcId } = useParams()
  const rtcKey = getISEAKeyPair(key)
  const { getDpagesContentNodeV1 } = useGunNodes()
  const draftContentNode = getDpagesContentNodeV1(rtcId as string)
  const [editor, setEditor] = useState<EditorJS>()

  const getDataFromGun = async () => {
    const data: any = await Promise.race([
      new Promise((resolve) => {
        draftContentNode.once((data: any) => {
          resolve(data)
        })
      }),
      new Promise((resolve) => setTimeout(() => resolve(undefined), 5000)),
    ])
    const rtcContent = data?.content
    const decryptedData = await SEA.decrypt(rtcContent, rtcKey!)
    return decryptedData
  }

  const initializeEditor = async () => {
    const draftData = await getDataFromGun()
    setEditorData(draftData)
    const editor = new EditorJS({
      data: draftData,
      onReady: () => {
        new DragDrop(editor)
      },
      autofocus: true,
      holder: 'editorjs',
      placeholder: 'Press TAB for menu...',
      onChange() {
        editor
          .save()
          .then((outputData) => {
            saveEditorContentOnGun(outputData, draftContentNode, id, rtcKey!)
            setEditorData(outputData)
          })
          .catch((error) => {
            console.log('Saving failed: ', error)
          })
      },
      tools: {
        header: Header,
        underline: Underline,
        paragraph: {
          class: Paragraph,
          inlineToolbar: true,
        },
        image: {
          class: SimpleImage,
          inlineToolbar: true,
        },
        list: {
          class: NestedList,
          inlineToolbar: true,
          config: {
            defaultStyle: 'unordered',
          },
        },
        checklist: {
          class: Checklist,
          inlineToolbar: true,
        },
        delimiter: Delimiter,
        embed: Embed,
        table: {
          class: Table,
          inlineToolbar: true,
        },
        code: Code,
        inlineCode: InlineCode,
        marker: Marker,
        hyperlink: {
          class: Hyperlink,
          config: {
            shortcut: 'CMD+L',
            target: '_blank',
            rel: 'noreferrer',
            validate: false,
          },
        },
      },
    })
    setEditor(editor)
  }

  useEffect(() => {
    initializeEditor()
  }, [])

  useEffect(() => {
    editor?.readOnly.toggle(isDisabled)
  }, [isDisabled])

  return (
    <div className="Editor w-full min-w-[50%] mx-4 max-w-5xl overflow-scroll no-scrollbar px-8 lg:pl-14">
      <div id="editorjs"></div>
    </div>
  )
}

export default Editor
