/* eslint-disable react/no-unknown-property */
import React, { Suspense } from 'react'

import {
  OrbitControls,
  useGLTF,
  Environment,
  Loader,
  Stage,
} from '@react-three/drei'
import { Canvas } from '@react-three/fiber'

function Model({ file }: { file: string }) {
  const { scene } = useGLTF(file)
  return <primitive object={scene} scale={0.5} />
}
// const LoadingScreen = () => {
//   const {progress} = useProgress();
//   return <Html>{Math.floor(progress)}% Loaded</Html>
// }
const ThreeDViewer = ({ url }: { url: string }) => {
  return (
    <>
      <Canvas data-cy="threeD-preview">
        <OrbitControls />
        <ambientLight intensity={0.3} />
        <directionalLight intensity={2.5} />
        <Suspense fallback={null}>
          <Stage intensity={0}>
            <Model file={url} />
            <Environment preset="sunset" />
          </Stage>
        </Suspense>
      </Canvas>
      <Loader />
    </>
  )
}

export default ThreeDViewer
