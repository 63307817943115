import React, { useEffect, useMemo, useState } from 'react'
import {
  YellowLinearProgress,
  BlackLinearProgress,
} from '../PortalSideBar/ClaimStorage'
import flare from '../../assets/flare.svg'
import taskUpload from '../../assets/taskUpload.svg'
import cancelIcon from '../../assets/cancelIcon.svg'
import lensTask from '../../assets/lensTask.svg'
import farcaster from '../../assets/farcaster.svg'
import ensTask from '../../assets/ensTask.svg'
import safeTask from '../../assets/safeTask.svg'
import animationFile from '../../assets/loader/congrats.json'
import gitcoinTask from '../../assets/gitcoinTask.svg'
import goToIcon from '../../assets/arrowForward.svg'
import lensterLogo from '../../assets/lensterLogo.svg'
import whiteFlare from '../../assets/white_flare.svg'
import twitterLogo from '../../assets/twitter_733635 1.svg'
import shield from '../../assets/shield.svg'
import cn from 'classnames'
import { PointRank } from '../../types/enum/pointRank.enum'
import { FETCH_TASK_QUERY_KEY, rankImage } from '../../constants'
import { Popup } from '../Popup/PopUp'
import {
  ITaskCategory,
  ITaskState,
  ITasksInfo,
  TaskCateory,
  TaskID,
} from './TasksProvider'
import { Tooltip, useMediaQuery } from '@mui/material'
import { notificationIcon } from '../Notification/NotificationCard'
import { NotificationType } from '../../types/enum/notification'
import { useQueryClient } from '@tanstack/react-query'
import TasksAccordian from './TasksAccordian'
import Loader from '../common/Loader'
import { useTasksContext } from '../../hooks/useTasksContext'
import Spinner from '../Spinner'

const TasksModal = () => {
  const isMediaMax1025px = useMediaQuery('(max-width : 1025px)')
  const queryClient = useQueryClient()
  const tasksState = useTasksContext()
  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [FETCH_TASK_QUERY_KEY],
    })
  }, [])

  if (!tasksState) return <></>
  return (
    <Popup isOpen={tasksState.isShowTaskList} width="100%" height="100%">
      <div
        ref={tasksState.taskListRef}
        className={`w-full h-full ${
          isMediaMax1025px && 'px-4'
        } flex items-center justify-center`}
      >
        <div
          className={cn(
            !isMediaMax1025px && 'h-[80%] w-[70%]',
            ' bg-white relative rounded-lg py-4 flex'
          )}
        >
          <ProfileSection tasksState={tasksState} />
          <div className="flex justify-center mr-4 items-center h-full">
            <div
              className=" w-[2px] h-[100%] bg-gradient-to-b
                 from-white via-gray-200 to-white "
            ></div>
          </div>
          <TaskList tasksState={tasksState} />
        </div>
      </div>
    </Popup>
  )
}

export default TasksModal

export const ProfileSection = ({ tasksState }: { tasksState: ITaskState }) => {
  if (!tasksState?.taskInfo) {
    return <></>
  }
  const { rank, collectedPoints } = tasksState.taskInfo as ITasksInfo
  const unlockedStorage = tasksState.taskInfo.unlockedStorage
  const totalUnlockableStorage = tasksState.taskInfo.totalUnlockableStorage
  const storageUsed = formatStorage(unlockedStorage)
  const totalStorage = formatStorage(totalUnlockableStorage)
  const [showAnimation, setShowAnimation] = useState(false)

  useEffect(() => {
    if (tasksState.levelUpResponse?.success) {
      setShowAnimation(true)
      setTimeout(() => {
        setShowAnimation(false)
      }, 10000)
    }
  }, [tasksState.levelUpResponse])

  return (
    <div className="h-full w-[30%] px-4 p-2 flex flex-col justify-center items-center">
      {showAnimation && (
        <div className="absolute top-[-50px]">
          <Loader animationFile={animationFile} />
        </div>
      )}
      <div>
        <RankSection
          rank={rank || PointRank.EXPLORER}
          collectedPoints={collectedPoints}
        />
        <div className="w-full flex flex-col gap-6 justify-center">
          <div className="w-full flex flex-col justify-center items-center">
            <div className="w-full flex justify-center">
              <div className="w-[255px] flex justify-center items-center flex-col gap-1">
                <div className="flex w-full justify-center gap-2 items-center">
                  <img src={flare} />
                  <p className="text-center font-medium">
                    {' '}
                    {`${collectedPoints} points`}
                  </p>
                  <img src={flare} />
                </div>
                <div className="w-[253px]">
                  <YellowLinearProgress
                    variant="determinate"
                    value={
                      (Number(unlockedStorage) /
                        Number(totalUnlockableStorage)) *
                      100
                    }
                  />
                </div>
                <div className="flex flex-col gap-4">
                  <p className="font-medium text-center text-base">
                    {`Unlocked ${storageUsed} of ${totalStorage} `}
                  </p>
                  <div className="w-full flex justify-center">
                    <div className="flex gap-2 border border-yellow p-2 rounded-lg items-center">
                      <a
                        href="mailto:hello@fileverse.io"
                        className="font-medium text-center text-[#333333] text-base"
                      >
                        Need more storage?
                      </a>
                      <Tooltip
                        placement="right-end"
                        title="You can only earn up to 1GB in one portal, for more contact us!"
                      >
                        <div className="w-[15px]">
                          <img src={goToIcon} className="w-full" />
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const RankSection = ({
  collectedPoints,
  rank,
}: {
  collectedPoints: number
  rank: PointRank
}) => {
  const getRankImage = (collectedPoints: number) => {
    if (collectedPoints >= 680) {
      return rankImage[PointRank.SUPER_SOURCERER]
    } else if (collectedPoints >= 430) {
      return rankImage[PointRank.OPEN_SOURCERER]
    } else if (collectedPoints >= 130) {
      return rankImage[PointRank.PATHFINDER]
    } else {
      return rankImage[PointRank.EXPLORER]
    }
  }
  return (
    <div className="flex flex-col justify-center items-center gap-10 w-full">
      <div className="flex w-[60%] flex-col items-center justify-center">
        <h2 className="text-base font-normal text-center mt-1 text-[#333333]">
          Rank
        </h2>
        <h1 className="text-lg rounded-md text-center font-bold bg-yellow px-2 py-1">
          {getRankName[rank]}
        </h1>
        <div className="w-[300px]">
          <img
            src={getRankImage(collectedPoints)}
            className="mt-2 w-full  h-auto"
          />
        </div>
      </div>
    </div>
  )
}

export const TaskList = ({ tasksState }: { tasksState: ITaskState }) => {
  const {
    setShowTaskList,
    taskCategories,
    isTaskCategoryCompleted,
    isTasksLoading,
  } = tasksState

  const shouldAnimateCategory = (index: number) => {
    if (!index) return !isTaskCategoryCompleted(taskCategories[0].label)
    const prevCategory = taskCategories[index - 1].label
    return isTaskCategoryCompleted(prevCategory)
  }
  if (isTasksLoading)
    return (
      <div className="flex w-[100%] h-[100%] items-center justify-center">
        <Spinner />
      </div>
    )
  return (
    <div className=" w-[70%] no-scrollbar overflow-scroll p-4 flex flex-col gap-4">
      <div className="w-full">
        <img
          onClick={() => setShowTaskList(false)}
          src={cancelIcon}
          className="w-6 cursor-pointer z-40 right-2 top-2 absolute"
        />

        <p className="font-bold text-center mt-4 text-[22px]">
          Earn points to claim storage space and future perks!
        </p>
      </div>
      {taskCategories.map((data, index) => (
        <TaskCategoryCard
          key={data.label}
          categoryInfo={data}
          isCompleted={isTaskCategoryCompleted(data.label)}
          shouldAnimateShadow={shouldAnimateCategory(index)}
        />
      ))}
    </div>
  )
}

export const TaskCategoryCard = ({
  categoryInfo,
  isCompleted,
  shouldAnimateShadow,
}: {
  categoryInfo: ITaskCategory
  isCompleted: boolean
  shouldAnimateShadow: boolean
}) => {
  const {
    type,
    label: categoryName,
    tasks,
    description,
    icons,
    lockedDescription,
  } = categoryInfo
  const wizard = rankImage[type]
  const label = getRankName[type]
  const categoryLabel: Record<TaskCateory, string> = {
    ['DISCOVERY']: 'Discovery',
    ['ACHIEVEMENT']: 'Quest',
    ['ONCHAIN']: 'Onchain Cred',
  }
  const tasksHeaderText: Record<TaskCateory, string> = {
    ['DISCOVERY']: 'Check Discovery Tasks',
    ['ACHIEVEMENT']: 'Check Quests',
    ['ONCHAIN']: 'Check Onchain Cred Tasks',
  }

  const getTotalPoints = useMemo(() => {
    let totalPoints = 0

    tasks.forEach((task) => {
      totalPoints += task.points
    })
    return { totalPoints }
  }, [tasks])
  const { totalPoints } = getTotalPoints
  const title = categoryLabel[categoryName]
  const completedTasks = shouldAnimateShadow
    ? tasks.filter((i) => i.completed)
    : []
  const percent = (completedTasks.length / tasks.length) * 100
  const totalStorage: Record<TaskCateory, number> = {
    ['DISCOVERY']: 200,
    ['ACHIEVEMENT']: 200,
    ['ONCHAIN']: 400,
  }
  return (
    <div className="bg-white rounded-lg shadow-[5px_2px_5px_0px_#AAAAAA] border">
      <div className="flex pl-0 p-4 justify-between gap-4">
        <div className="flex gap-4 flex-grow items-center ">
          <div className="flex-shrink-0 flex w-[150px] flex-col items-center">
            <img src={wizard} className="w-[95px]" />
            <div className="px-2 font-bold py-1 bg-yellow text-base rounded-[4px]">
              {label}
            </div>
          </div>

          <div className="flex flex-col  justify-center">
            <div className="flex gap-4 flex-wrap items-center">
              <h2 className=" text-lg font-medium ">{title}</h2>
              <div className="flex gap-4 items-center">
                {icons.map((taskID, index) => (
                  <img
                    key={index}
                    src={taskIcon[taskID]}
                    className="w-[28px]"
                  />
                ))}
              </div>
            </div>

            <p className=" text-xl font-normal">{description}</p>
          </div>
        </div>

        <div className="flex min-w-[120px] flex-col justify-between">
          <div className="px-3 py-1 bg-yellow flex justify-center items-center rounded-[4px] font-bold">
            +{totalStorage[categoryName]}MB
          </div>

          <div
            className={cn(
              'flex rounded-[4px] justify-evenly px-3 py-1 gap-2 border',
              { 'bg-yellow': isCompleted, 'border-yellow': !isCompleted }
            )}
          >
            <div className="font-bold">+{totalPoints}</div>
            {isCompleted ? (
              <img src={whiteFlare} className="w-[24px]" />
            ) : (
              <img src={flare} className="w-[24px]" />
            )}
          </div>

          <div>
            <BlackLinearProgress variant="determinate" value={percent} />
            <div className="text-center text-[#6B7280] w-full">{`${completedTasks.length}/${tasks.length}`}</div>
          </div>
        </div>
      </div>
      <TasksAccordian
        shouldAnimateShadow={shouldAnimateShadow}
        isCompleted={isCompleted}
        tasks={tasks}
        lockedDescription={lockedDescription}
        tasksHeaderText={tasksHeaderText[categoryName]}
      />
    </div>
  )
}
export const getRankName = {
  [PointRank.EXPLORER]: 'Explorer',
  [PointRank.OPEN_SOURCERER]: 'Spellcaster',
  [PointRank.PATHFINDER]: 'PathFinder',
  [PointRank.SUPER_SOURCERER]: 'Open-Sourcerer',
}
export const taskIcon = {
  [TaskID.OWN_LENS_HANDLE]: lensTask,
  [TaskID.OWN_ENS_DOMAIN]: ensTask,
  [TaskID.OWN_FARCASTER_HANDLE]: farcaster,
  [TaskID.OWN_GITCOIN_PASSPORT]: gitcoinTask,
  [TaskID.OWN_SAFE_MULTISIG]: safeTask,
  [TaskID.CHAT_ON_FILE]: notificationIcon[NotificationType.DPAGE_COMMENT],
  [TaskID.CREATE_PUBLISH_3_DDOCS]: taskUpload,
  [TaskID.CREATE_PUBLISH_3_DPAGES]: taskUpload,
  [TaskID.COMMENT_ON_10_FILES]:
    notificationIcon[NotificationType.DPAGE_COMMENT],
  [TaskID.INVITE_PORTAL_COLLABORATOR]:
    notificationIcon[NotificationType.COLLABORATOR_INVITE],
  [TaskID.PARTICIPATE_PORTAL_CHAT]:
    notificationIcon[NotificationType.DPAGE_COMMENT],
  [TaskID.PRIVATE_KEY_DOWNLOAD]: shield,
  [TaskID.TRY_LIVE_COLLABORATION]:
    notificationIcon[NotificationType.COLLABORATOR_INVITE],
  [TaskID.PUBLISH_10_PUBLIC_DDOCS]:
    notificationIcon[NotificationType.DDOC_PUBLISH],
  [TaskID.PUBLISH_10_PUBLIC_WHITEBOARDS]:
    notificationIcon[NotificationType.DDOC_PUBLISH],
  [TaskID.PUBLISH_PRIVATE_WHITEBOARD]:
    notificationIcon[NotificationType.DDOC_PUBLISH],
  [TaskID.PUBLISH_PUBLIC_DDOC]: notificationIcon[NotificationType.DDOC_PUBLISH],
  [TaskID.PUBLISH_PUBLIC_DPAGE]:
    notificationIcon[NotificationType.DDOC_PUBLISH],
  [TaskID.UPLOAD_PRIVATE_FILE]:
    notificationIcon[NotificationType.NEW_FILE_ADDED],
  [TaskID.UPLOAD_PUBLIC_FILE]:
    notificationIcon[NotificationType.NEW_FILE_ADDED],
  [TaskID.SHARE_TWITTER]: twitterLogo,
  [TaskID.SHARE_LENSTER]: lensterLogo,
  [TaskID.EDIT_PUBLIC_PORTAL]: notificationIcon[NotificationType.DDOC_PUBLISH],
}
export function formatStorage(value: number) {
  if (value < 1000 * 1000 * 1000) {
    return Math.round(value / (1000 * 1000)) + 'MB'
  } else {
    return Math.round(value / (1000 * 1000 * 1000)) + 'GB'
  }
}
