export enum FileTypeEnum {
  PUBLIC,
  PRIVATE,
  GATED,
  MEMBERS_PRIVATE,
}

export enum FileSource {
  USER_FILE_SYSTEM,
  EXCALIDRAW,
  COLLAB_DOCUMENT,
  NOTES,
  DPAGE,
  ARWEAVE,
}

export enum FileStatus {
  PENDING,
  INVALID_ID,
  ERROR,
  READY,
  LOCKED,
  DISCONNECTED,
}

export enum DocumentPageStatus {
  PENDING,
  FETCHING_CONTRACT,
  READY,
  DISCONNECTED,
}
