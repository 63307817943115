import { createSlice } from '@reduxjs/toolkit'

const LS_ACCOUNT_KEY = 'fileverse.accountAddress'

const getInitialState = () => {
  return {
    address: localStorage.getItem(LS_ACCOUNT_KEY),
  }
}

const accountSlice = createSlice({
  name: 'account',
  initialState: getInitialState(),
  reducers: {
    updateAddress(state, action): void {
      state.address = action.payload.address
      localStorage.setItem(LS_ACCOUNT_KEY, action.payload.address)
    },
    disconnectAccount(state) {
      state.address = null
      localStorage.removeItem(LS_ACCOUNT_KEY)
    },
  },
})

export const { updateAddress, disconnectAccount } = accountSlice.actions
export default accountSlice.reducer
