/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Contract } from 'ethers'
import portalAbi from '../contract/portalAbi.json'
import registryAbi from '../contract/registryAbi.json'
import heartMinterAbi from '../contract/heartMinterAbi.json'
import {
  IContract,
  IContractMetadata,
} from '../types/interface/contract.interface'

import { INewFile } from '../types/interface/file.interface'
import { getIPFSAsset } from './getIPFSAsset'
import { defaultNetwork, networksConfig } from '../config/network-config'
import { AllowedChainId } from '../config/wagmi-config'
import { getEthersProvider } from './ethUtils'
import { PUBLIC_PORTAL_METADATA_FILE_PREFIX } from './publicPortalUtils'

export const getAppDefaultProvider = (chainId?: AllowedChainId) =>
  getEthersProvider({
    chainId: chainId || (defaultNetwork.chainId as AllowedChainId),
  })

export const getReadOnlyContract = (
  contractAddress: string,
  contractAbi: any
): Contract => {
  const provider = getAppDefaultProvider()
  const contract = new Contract(contractAddress, contractAbi, provider)
  return contract
}

export const getContractMetadata = async (
  contractAddress: string,
  option?: {
    lastIPFSUrl: string
  }
): Promise<{ data: IContractMetadata; lastWorkingUrl: string }> => {
  const contract = getReadOnlyContract(contractAddress, portalAbi)
  if (contract) {
    try {
      const metadataIPFSHash = await contract.metadataIPFSHash()
      const res: any = await getIPFSAsset({
        ipfsHash: metadataIPFSHash,
        lastIPFSUrl: option?.lastIPFSUrl,
      })
      return { data: res?.data, lastWorkingUrl: res?.url }
    } catch (err) {
      console.log(err)
    }
  }
  throw Error('Could not create ethers.Contract object')
}

export const getContractFile = async (
  fileId: number,
  contractAddress: string,
  totalFilesOnContract?: number
): Promise<INewFile | undefined> => {
  const contract = getReadOnlyContract(contractAddress, portalAbi)
  if (contract) {
    const fileCount = totalFilesOnContract || (await contract.getFileCount())
    const fileCountInt = Number(fileCount)
    if (fileId > fileCountInt - 1) {
      throw new Error(`File with Id ${fileId} does not exists`)
    }
    const file = await contract.files(fileId)
    if (!file.metadataIPFSHash || !file.contentIPFSHash) {
      return
    }
    const result: any = await getIPFSAsset({
      ipfsHash: file.metadataIPFSHash.replace(
        PUBLIC_PORTAL_METADATA_FILE_PREFIX,
        ''
      ),
    })
    const fileMetadata = result?.data
    const lastWorkingUrl = result?.url
    return {
      fileId: fileId,
      contentIPFSHash: file.contentIPFSHash,
      metadataIPFSHash: file.metadataIPFSHash.replace(
        PUBLIC_PORTAL_METADATA_FILE_PREFIX,
        ''
      ),
      gateIPFSHash: file.gateIPFSHash,
      version: parseInt(file.version._hex, 16),
      metadata: fileMetadata,
      cache: { contentIPFSUrl: '', metadataIPFSUrl: lastWorkingUrl },
    }
  }
  throw Error('Could not create ethers.Contract object')
}

export const getContractFileCount = async (
  contractAddress: string
): Promise<number> => {
  const contract = getReadOnlyContract(contractAddress, portalAbi)
  if (contract) {
    const fileCount = await contract.getFileCount()
    return Number(fileCount)
  }
  throw Error('Could not create ethers.Contract object')
}

export const getContractInfo = async (
  contractAddress: string,
  chainId: number
): Promise<IContract> => {
  const portal = getReadOnlyContract(contractAddress, portalAbi)
  if (!portal) throw Error('Could not create ethers.Contract of portal')

  const registry = getReadOnlyContract(
    networksConfig[chainId].registry,
    registryAbi
  )

  const metadataIPFSHash = await portal.metadataIPFSHash()
  const res: any = await getIPFSAsset({ ipfsHash: metadataIPFSHash })
  const metadata: IContractMetadata = res!.data
  const owner = (await portal.owner()) as string
  const collaborators = (await portal.getCollaborators()).map(
    (colab: string) => colab
  )
  const portalInfo = await registry.portalInfo(contractAddress)
  const tokenId = parseInt(portalInfo.tokenId)
  const resp: IContract = {
    contractAddress: contractAddress,
    metadata,
    owner: owner,
    collaborators: collaborators,
    members: collaborators, // we need to add all members and not just only collaborators
    chainId: chainId,
    agentAddress: '',
    enabled_signless: false,
    tokenId,
    metadataLastWorkingIPFSUrl: res.url,
  }
  return resp
}

export const getCollaboratorKeys = async (
  contractAddress: string,
  invokerAddress: string
) => {
  try {
    const contract = getReadOnlyContract(contractAddress, portalAbi)

    if (contract) {
      const keys = await contract.collaboratorKeys(invokerAddress)
      return keys
    }
  } catch (error) {
    console.log({ error })
    return
  }
}

export const getReadOnlyHeartMinterContract = (): Contract => {
  const provider = getAppDefaultProvider()
  const contractAddress = defaultNetwork.heartMinterAddress
  const contract = new Contract(contractAddress!, heartMinterAbi, provider)
  return contract
}

export const getPortalContract = (portalAddress: string): Contract =>
  getReadOnlyContract(portalAddress, portalAbi)

export const getRegistryContract = (): Contract =>
  getReadOnlyContract(defaultNetwork.registry, registryAbi)
