import {
  addCollaboratorToContractStateOnIndexDb,
  addContractToIndexDbRecord,
  addContractToInvokerContractListOnIndexeddb,
  addFileToContractStateOnIndexedDb,
  addInvokerAgentKeysToIndexedDb,
  addInvokerCredentialsToIndexedDb,
  addInvokerServerKeysToIndexDb,
  addMemberToContractStateOnIndexDb,
  deleteFileFromContractStateOnIndexedDb,
  removeContractCollaboratorOnIndexDb,
  removeInvokerAgentKeyFromIndexDb,
  removePortalAgentOnIndexedDb,
  updateContractChainIdOnIndexDb,
  updateContractMemberStateOnIndexDb,
  updateContractMetadataStateOnIndexDb,
  updateContractSignlessStateOnIndexedDb,
  updatePortalAgentOnIndexDb,
  updatePortalStorageOnIndexDb,
  updateContractMetadataIPFSUrlOnIndexDb,
  cacheFileIPFSUrlOnIndexedDb,
} from './actionHandlers'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const actionConfig: Record<string, any> = {
  'invoker/addInvokerCredential': addInvokerCredentialsToIndexedDb,
  'invoker/setInvokerAgentKey': addInvokerAgentKeysToIndexedDb,
  'invoker/removeAgentKey': removeInvokerAgentKeyFromIndexDb,
  'invoker/addInvokerContract': addContractToInvokerContractListOnIndexeddb,
  'invoker/addInvokerServerKeys': addInvokerServerKeysToIndexDb,
  'contract/addContract': addContractToIndexDbRecord,
  'contract/fetchAndAddContract/fulfilled': addContractToIndexDbRecord,
  'contract/setPortalSignlessMode': updateContractSignlessStateOnIndexedDb,
  'contract/updateContractMetadataState': updateContractMetadataStateOnIndexDb,
  'contract/setPortalAgent': updatePortalAgentOnIndexDb,
  'contract/removePortalAgent': removePortalAgentOnIndexedDb,
  'contract/addContractMember': addMemberToContractStateOnIndexDb,
  'contract/savePortalMemberList': updateContractMemberStateOnIndexDb,
  'contract/addCollaboratorToStore': addCollaboratorToContractStateOnIndexDb,
  'contract/removeContractCollaborator': removeContractCollaboratorOnIndexDb,
  'contract/setContractChainId': updateContractChainIdOnIndexDb,
  'contract/updatePortalStorage': updatePortalStorageOnIndexDb,
  'newFile/addFile': addFileToContractStateOnIndexedDb,
  'newFile/fetchFile/fulfilled': addFileToContractStateOnIndexedDb,
  'newFile/editFile': addFileToContractStateOnIndexedDb,
  'newFile/deleteFile': deleteFileFromContractStateOnIndexedDb,
  'newFile/cacheFileIPFSUrl': cacheFileIPFSUrlOnIndexedDb,
  'contract/updatePortalMetadataIPFSUrl':
    updateContractMetadataIPFSUrlOnIndexDb,
}
