/* eslint-disable @typescript-eslint/no-explicit-any */
import { IContract } from '../../types/interface/contract.interface'
import { IInvoker } from '../../types/interface/invoker.interface'
import { queryIndexDb } from './utils'

type HandlerFunction = (state: any, payload: any) => Promise<void> | void

export const handleInvokerAction = async (
  action: any,
  _function: HandlerFunction
) => {
  const payload = action.payload
  const invokerState = await queryIndexDb('invoker', payload.invokerAddress)

  await _function(invokerState as IInvoker, payload)
}

export const handleContractAction = async (
  action: any,
  _function: (state: IContract, payload: any) => void
) => {
  const payload = action.payload
  const contractState = await queryIndexDb('contracts', payload.contractAddress)
  _function(contractState as IContract, payload)
}
