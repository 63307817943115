import React from 'react'
import Footer from './Footer'
import { Button } from '@fileverse/ui'
import crossEyeFileverseLogo from '../assets/cross-eye-fileverse-logo.svg'
// import useHeaderHook from '../../../hooks/useHeaderHook'
// import RegisterCollaboratorPopup from '../../../components/Popup/RegisterCollaboratorPopup'
// Need to refactor header hook  causing infinite requests
// Temp added header hook for collaborators
export default function PublicPortalDisabledPage() {
  // const {
  //   isRegisterCollaboratorPopupVisible,
  //   registerCollaboratorRef,
  //   setRegisterCollaboratorPopup,
  // } = useHeaderHook()

  return (
    <div className="w-screen h-screen color-bg-default relative flex justify-center items-center">
      <div className="flex flex-col gap-6 justify-center items-center">
        <img src={crossEyeFileverseLogo} alt="fileverse logo" />
        <p className="text-heading-lg-bold">
          This Fileverse Portal is not public, yet
        </p>
        <a
          href="https://fileverse.io/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button className="w-fit px-6">About Fileverse</Button>
        </a>
      </div>
      <div className="fixed bottom-5">
        <Footer />
      </div>
      {/* {isRegisterCollaboratorPopupVisible && (
        <RegisterCollaboratorPopup
          isPopUpVisible={isRegisterCollaboratorPopupVisible}
          collaboratorPopUpRef={registerCollaboratorRef}
          setPopupVisibility={setRegisterCollaboratorPopup}
        />
      )} */}
    </div>
  )
}
