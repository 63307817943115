import React from 'react'
import cn from 'classnames'
import { useMediaMax1025px } from '../../hooks/useMediaQueryHook'
import { getMaskedString, getTrimmedName } from '../../utils/string'

interface HashedKeyProps {
  keyName: string
  value: string
  isMasked: boolean
}

export const HashedKeyViewer = ({
  keyName,
  value,
  isMasked,
}: HashedKeyProps) => {
  const isMediaMax1025px = useMediaMax1025px()
  const trimLength = isMediaMax1025px ? 5 : 20
  const stringLimit = isMediaMax1025px ? 10 : 40
  const maskLength = isMediaMax1025px ? 10 : 35
  return (
    <div className="cursor-pointer flex items-center gap-2 grow-0 shrink-0">
      <strong className="h-12 w-48">{keyName}</strong>
      <p
        className={cn('text-[#777777] h-12', {
          'text-3xl': isMasked,
        })}
      >
        {isMasked
          ? getMaskedString(value, maskLength)
          : getTrimmedName(value.toString(), trimLength, stringLimit)}
      </p>
    </div>
  )
}
