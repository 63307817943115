/* eslint-disable no-constant-condition */
import React from 'react'
import { Button } from '../../pages/PublicPortal/components/Button'

const NotificationButton = ({
  title,
  preferred,
  isLoading,
  onClick,
}: {
  onClick: () => void
  title: string
  isLoading?: boolean
  preferred?: boolean
}) => {
  return (
    <>
      <Button
        onClick={() => {
          !isLoading && onClick()
        }}
        variant={preferred ? 'default' : 'secondary'}
        isLoading={isLoading}
        disabled={isLoading}
      >
        {title}
      </Button>
    </>
  )
}

export default NotificationButton
