/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { FileSource, FileTypeEnum } from '../../../../../types/enum/file.enum'
import { FileType } from '../../../../../utils/fileUtils'
import {
  Database,
  File,
  FileImage,
  FileMusic,
  FileText,
  FileVideo,
  PanelsTopLeft,
  PencilLine,
} from 'lucide-react'
// TODO: Fix types
export const FileNameMap: any = {
  [FileSource.ARWEAVE]: 'Arweave',
  [FileSource.COLLAB_DOCUMENT]: 'dDoc',
  [FileSource.DPAGE]: 'dPage',
  [FileSource.EXCALIDRAW]: 'Whiteboard',
  [FileSource.USER_FILE_SYSTEM]: 'User File System',
}

export const FileTypeMap: any = {
  [FileTypeEnum.GATED]: 'Gated',
  [FileTypeEnum.PUBLIC]: 'Public',
  [FileTypeEnum.PRIVATE]: 'Private',
  [FileTypeEnum.MEMBERS_PRIVATE]: 'Members Private',
}

export const FileIconMap: any = {
  [FileSource.ARWEAVE]: <Database size={18} />,
  [FileSource.COLLAB_DOCUMENT]: <FileText size={18} />,
  [FileSource.DPAGE]: <PanelsTopLeft size={18} />,
  [FileSource.EXCALIDRAW]: <PencilLine size={18} />,
}

export const SystemFileIconMap: any = {
  [FileType.Audio]: <FileMusic size={18} />,
  [FileType.Video]: <FileVideo size={18} />,
  [FileType.Document]: <FileText size={18} />,
  [FileType.PDF]: <FileText size={18} />,
  [FileType.Sheet]: <FileText size={18} />,
  [FileType.Image]: <FileImage size={18} />,
  [FileType.Other]: <File size={18} />,
}

export enum SortOptions {
  Created = 'created',
  Edited = 'edited',
  Viewed = 'viewed',
}

export const SortByOptions = [
  { label: 'Created', value: SortOptions.Created },
  { label: 'Edited', value: SortOptions.Edited },
  { label: 'Viewed', value: SortOptions.Viewed, disabled: true },
]

export const SortByValueLabelMap: {
  [key in SortOptions]: string
} = {
  [SortOptions.Edited]: 'Edited',
  [SortOptions.Created]: 'Created',
  [SortOptions.Viewed]: 'Viewed',
}

export { FileItem } from './FileItem'
export { EmptyState } from './EmptyState'
