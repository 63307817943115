import React, { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { getCollaboratorKeys, getReadOnlyContract } from '../utils/contract'
import portalAbi from '../contract/portalAbi.json'
import { useAppDispatch } from '../store/hooks'
import { saveCollaboratorsList } from '../store/contract/reducer'
import {
  useInvokerContractCredentials,
  useServerKeys,
} from '../store/invoker/hooks'
import { isEmpty } from 'lodash'
import { generateServerKeys } from '../utils/keys'
import { createMessageAPI, getMessagesAPI } from '../api/message/messageAPI'
import { ICredential } from '../types/interface/invoker.interface'
import { useSearchParams } from 'react-router-dom'
import { toLowerCase } from '../utils/string'
import { IMessage } from '../types/interface/message.interface'
import { MessageTopicEnum } from '../types/enum/message.enum'
import { addKeys } from '../store/keySharing/reducer'
import sendNotifcation from '../utils/notification'

const usePortalCollaboratorsQuery = (contractAddress: string) => {
  return useQuery({
    queryKey: ['collaborators', contractAddress],
    queryFn: async () => {
      try {
        const portalInstance = getReadOnlyContract(
          contractAddress as string,
          portalAbi
        )
        const c = await portalInstance.getCollaborators()

        return { collaborators: [...c] }
      } catch (error) {
        console.error(error)
      }
    },
  })
}
export const useRegisterCollaborator = (
  contractAddress: string,
  currentWalletAddress: string | undefined
) => {
  const [shouldRegister, setShouldRegister] = React.useState(false)
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const [isRegistrationCompleted, setIsRegistrationCompleted] = useState(false)
  const chainId = parseInt(searchParams.get('chainId') || '')
  const { data, isLoading, error } =
    usePortalCollaboratorsQuery(contractAddress)

  const [retryCollaborationFlow, setRetryAddCollaboratorFlow] = useState(false)

  const credential = useInvokerContractCredentials(
    currentWalletAddress as string,
    contractAddress as string
  )

  const serverKeys = useServerKeys(
    currentWalletAddress as string,
    contractAddress as string
  )
  const _didUserAlreadyRequestKey = async (message: IMessage) => {
    if (message.topic === MessageTopicEnum.KEY_REQUEST) {
      const isRequestedKey =
        toLowerCase(message.invokerAddress) ===
        toLowerCase(currentWalletAddress)
      return isRequestedKey
    }
  }

  const didUserAlreadyRequestKey = async () => {
    if (isEmpty(credential)) return
    const { data } = await getMessagesAPI({
      contractAddress: contractAddress as string,
      credential: credential as ICredential,
      invokerAddress: currentWalletAddress as string,
      chain: chainId,
    })
    const { messages = [] } = data
    for (const message of messages) {
      if (toLowerCase(message.contractAddress) === toLowerCase(contractAddress))
        return _didUserAlreadyRequestKey(message)
    }
  }

  const handleCollaboratorRegistrationStatus = async () => {
    if (
      isLoading ||
      error ||
      !currentWalletAddress ||
      !data ||
      !contractAddress
    )
      return
    const { collaborators } = data
    if (collaborators.length !== 0)
      dispatch(
        saveCollaboratorsList({
          contractAddress: contractAddress as string,
          collaborators,
        })
      )
    if (collaborators.includes(currentWalletAddress)) {
      const collaboratorKeys = await getCollaboratorKeys(
        contractAddress,
        currentWalletAddress
      )
      if (collaboratorKeys?.[0] && collaboratorKeys[1]) {
        if (
          !isEmpty(credential) &&
          serverKeys?.portalDecryptionKey &&
          serverKeys.portalEncryptionKey
        ) {
          return
        }

        const isKeysSameAsThatOnTheSmartContract =
          credential?.viewDID === collaboratorKeys?.[0] &&
          credential?.editDID === collaboratorKeys[1]

        if (!isEmpty(credential) && isKeysSameAsThatOnTheSmartContract) {
          const userAlreadyRequestedForKey = await didUserAlreadyRequestKey()

          if (userAlreadyRequestedForKey) return
          const { encryptionKey, decryptionKey, token } =
            await generateServerKeys(credential.viewSecret)

          await createMessageAPI({
            contractAddress: contractAddress as string,
            invokerAddress: currentWalletAddress as string,
            content: token,
            topic: MessageTopicEnum.KEY_REQUEST,
            forAddress: '*',
            chain: chainId as number,
            credential: {
              ...credential,
              contractAddress: contractAddress as string,
            },
          })
          dispatch(
            addKeys({
              contractAddress: contractAddress as string,
              privateKey: decryptionKey,
              publicKey: encryptionKey,
            })
          )

          setIsRegistrationCompleted(true)

          sendNotifcation(
            'Portal Keys has been requested successfully',
            '',
            'success'
          )
          return
        } else {
          setRetryAddCollaboratorFlow(true)
        }
      }
      setShouldRegister(true)
    }
  }

  React.useEffect(() => {
    handleCollaboratorRegistrationStatus()
  }, [
    contractAddress,
    credential,
    currentWalletAddress,
    data,
    dispatch,
    error,
    isLoading,
  ])

  return {
    shouldRegister,
    isRegistrationCompleted,
    toggleShouldRegister: () => setShouldRegister((prev) => !prev),
    isLoading,
    retryCollaborationFlow,
  }
}
