import React, { useRef } from 'react'
import NotificationHeader from './NotificationHeader'
import NotificationCard from './NotificationCard'
import notification_empty_state from '../../assets/notification_empty_state.svg'
import { useNotificationProvider } from '../../hooks/useNotificationProvider'
import cn from 'classnames'
import Spinner from '../Spinner'
export interface NotifcationQueryParams {
  offset?: number
  limit?: number
  isRead?: boolean | null
  invokerAddress?: string
  portalAddress?: string
}
const NotificationCenter = () => {
  const provider = useNotificationProvider()
  return (
    <div
      className={cn('w-[474px] border bg-white  rounded-lg shadow-2xl', {
        'overflow-hidden':
          provider?.notifications.length && provider?.notifications.length > 1,
      })}
      onClick={(e) => e.stopPropagation()}
    >
      {/* <div className="border-t-[0px]  border-[#FFF6A7] border-r-white border-t-transparent float-right border-b-[15px] border-b-transparent border-l-[20px]"></div> */}
      <NotificationHeader />
      <NotificationState />
    </div>
  )
}

export default NotificationCenter

export const NotificationState = () => {
  const provider = useNotificationProvider()
  const notificationRef = useRef<HTMLDivElement>(null)

  const handleScroll = () => {
    const div = notificationRef.current

    if (div) {
      const atBottom = div.scrollTop + div.clientHeight === div.scrollHeight

      if (atBottom) {
        provider?.fetchNextPage()
      }
    }
  }

  if (!provider) {
    return <></>
  }

  const { isNotificationError, isNotificationsLoading, notifications } =
    provider

  if (isNotificationError) {
    return (
      <div className="w-full z-10 h-[450px] flex flex-col items-center justify-center">
        Error occurred while fetching notification
      </div>
    )
  }
  return (
    <div
      onScroll={handleScroll}
      ref={notificationRef}
      className=" w-full z-10 bg-white ease-in duration-200 transition-all relative max-h-[500px] overflow-y-scroll no-scrollbar"
    >
      {isNotificationsLoading ? (
        <div className="w-full h-[450px] flex flex-col gap-2 items-center justify-center">
          <Spinner />
          <p className="color-text-default text-body-sm-bold">Loading...</p>
        </div>
      ) : notifications && notifications.length ? (
        notifications.map((notification) => {
          return (
            <NotificationCard
              key={notification._id}
              notification={notification}
              type={notification.type}
            />
          )
        })
      ) : (
        <NotificationEmptyState />
      )}
    </div>
  )
}
export const NotificationEmptyState = () => {
  return (
    <div className="w-full h-[450px] bg-white flex flex-col items-center justify-center">
      <img src={notification_empty_state} />
      <h1 className="text-2xl font-medium">Woops!</h1>
      <p className="text-[#777777] font-medium">No notification to show</p>
    </div>
  )
}
