/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { SetStateAction, useEffect, useState } from 'react'
import EditorJS, { OutputData } from '@editorjs/editorjs'
import Header from '@editorjs/header'
import Delimiter from '@editorjs/delimiter'
import Checklist from '@editorjs/checklist'
import SimpleImage from '@maitrakhatri/editorjs-simple-image'
import Code from '@editorjs/code'
import Embed from '@editorjs/embed'
import Table from '@editorjs/table'
import InlineCode from '@editorjs/inline-code'
import Underline from '@editorjs/underline'
import Paragraph from 'editorjs-paragraph-with-alignment'
import DragDrop from 'editorjs-drag-drop'
import Marker from '@editorjs/marker'
import Hyperlink from 'editorjs-hyperlink'
import NestedList from '@editorjs/nested-list'
import uuid from 'react-uuid'
import { getISEAKeyPair } from '../../utils/libCrypto'
import { IDraft } from '../../types/interface/drafts.interface'
import { saveEditorContentOnGun } from './saveEditorContentOnGun'
import { useGunNodes } from '../../hooks/useGunNode'

function WebPageViewer({
  data,
  isEditing,
  setEditorData,
  rtcId,
  documentKey,
}: {
  rtcId?: string
  documentKey?: string
  rtcData?: IDraft
  data: OutputData
  isEditing: boolean
  setEditorData?: React.Dispatch<SetStateAction<OutputData | undefined>>
  isMediaMax1025px?: boolean
}) {
  const [editor, setEditor] = useState<EditorJS>()
  const rtcKey = getISEAKeyPair(documentKey)
  const { getDpagesContentNodeV1 } = useGunNodes()

  useEffect(() => {
    const id = uuid()
    const draftContentNode = getDpagesContentNodeV1(rtcId as string)
    const editor = new EditorJS({
      data: data,
      onReady: () => {
        new DragDrop(editor)
      },
      onChange() {
        if (isEditing) {
          editor
            .save()
            .then((outputData) => {
              saveEditorContentOnGun(outputData, draftContentNode, id, rtcKey!)
              setEditorData && setEditorData(outputData)
            })
            .catch((error) => {
              console.log('Saving failed: ', error)
            })
        }
      },
      holder: 'editorjs',
      readOnly: !isEditing,
      tools: {
        header: Header,
        underline: Underline,
        paragraph: {
          class: Paragraph,
          inlineToolbar: true,
        },
        image: {
          class: SimpleImage,
          inlineToolbar: true,
        },
        list: {
          class: NestedList,
          inlineToolbar: true,
          config: {
            defaultStyle: 'unordered',
          },
        },
        checklist: {
          class: Checklist,
          inlineToolbar: true,
        },
        delimiter: Delimiter,
        embed: Embed,
        table: {
          class: Table,
          inlineToolbar: true,
        },
        code: Code,
        inlineCode: InlineCode,
        marker: Marker,
        hyperlink: {
          class: Hyperlink,
          config: {
            shortcut: 'CMD+L',
            target: '_blank',
            rel: 'noreferrer',
            validate: false,
          },
        },
      },
    })
    setEditor(editor)
  }, [])

  useEffect(() => {
    editor?.readOnly.toggle(!isEditing)
  }, [isEditing])

  return (
    <div
      data-cy="webpages-viwer"
      className={`Editor w-full h-screen min-w-[50%] mx-4 max-w-5xl px-16 lg:pl-14`}
    >
      <div id="editorjs"></div>
    </div>
  )
}

export default WebPageViewer
