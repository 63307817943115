/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, {
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useState,
} from 'react'
import sendNotifcation from '../../utils/notification'
import { StepItem } from './StepItem'
import cn from 'classnames'
import { Onboarding } from '../../types/enum/onboarding.enum'
import { STEPS_MAP } from '../../constants'
import { CreatePortalProvider } from '../../providers'
import { SignlessComponent } from './SignlessComponent'
import { AccessPortalStep, CreatePortalStep, GenerateKeysStep } from './Steps'

export interface Step {
  name: string
  isComplete: boolean
  nextOptional?: boolean
}

interface PortalDeploymentStepProps {
  toggleVideoPlayer: Dispatch<SetStateAction<boolean>>
}

export default memo(function PortalDeploymentSteps({
  toggleVideoPlayer,
}: PortalDeploymentStepProps) {
  const [deployStatus, setDeployStatus] = useState<Onboarding>(
    Onboarding.DEPLOY
  )

  const [signlesState, setSignlessState] = useState<{
    currentStep: 'ACTIVATE' | 'CONFIRM' | 'COMPLETE'
    isComplete: boolean
  }>({
    currentStep: 'ACTIVATE',
    isComplete: false,
  })

  const [steps, setSteps] = useState<Step[]>([
    {
      name: STEPS_MAP[Onboarding.DEPLOY].title,
      isComplete: false,
    },
    {
      name: STEPS_MAP[Onboarding.GENERATE].title,

      isComplete: false,
    },
    {
      name: STEPS_MAP[Onboarding.ACCESS].title,
      nextOptional: true,
      isComplete: false,
    },
  ])

  const onGoSignlessClick = () => {
    const updatedSteps = [...steps]
    updatedSteps.push({
      name: STEPS_MAP[Onboarding.SIGNLESS].title,
      isComplete: false,
    })
    setSteps(updatedSteps)
    setDeployStatus(Onboarding.SIGNLESS)
  }

  const onSkipSignless = () => {
    const updatedSteps = [...steps]
    updatedSteps.pop()
    setSteps(updatedSteps)
    setDeployStatus(Onboarding.ACCESS)
  }

  const updateStepCompleteStatus = useCallback(
    (step: Onboarding, isComplete: boolean) => {
      setSteps((prevState) => {
        const updatedSteps = [...prevState]
        updatedSteps[step].isComplete = isComplete
        return updatedSteps
      })
    },
    []
  )

  const onTxSuccessCb = useCallback(() => {
    updateStepCompleteStatus(Onboarding.DEPLOY, true)
    setDeployStatus(Onboarding.GENERATE)
  }, [updateStepCompleteStatus])

  const onTxErrorCb = useCallback(() => {
    updateStepCompleteStatus(Onboarding.DEPLOY, false)
    setDeployStatus(Onboarding.DEPLOY)
  }, [updateStepCompleteStatus])

  const onResolvedEventCb = useCallback(() => {
    updateStepCompleteStatus(Onboarding.GENERATE, true)
    updateStepCompleteStatus(Onboarding.ACCESS, true)
    setDeployStatus(Onboarding.ACCESS)
  }, [updateStepCompleteStatus])

  const onResolveEventErrorCb = useCallback(() => {
    updateStepCompleteStatus(Onboarding.DEPLOY, false)
    setDeployStatus(Onboarding.DEPLOY)
  }, [updateStepCompleteStatus])

  const onActivateSignlessCb = useCallback(() => {
    setSignlessState((prev) => ({
      ...prev,
      currentStep: 'CONFIRM',
    }))
  }, [])

  const onConfirmSignlessCb = useCallback(
    (collaboratorAddress: string) => {
      setSignlessState((prev) => ({
        ...prev,
        currentStep: 'COMPLETE',
        isComplete: true,
      }))
      updateStepCompleteStatus(Onboarding.SIGNLESS, true)
      sendNotifcation(
        'Signless mode successfully activated',
        `You have successfully added ${collaboratorAddress} as a signing agent of this Portal.`,
        'success'
      )
    },
    [updateStepCompleteStatus]
  )

  return (
    <CreatePortalProvider
      onTxSuccessCb={onTxSuccessCb}
      onTxErrorCb={onTxErrorCb}
      onResolvedEventCb={onResolvedEventCb}
      onResolveEventErrorCb={onResolveEventErrorCb}
      onActivateSignlessCb={onActivateSignlessCb}
      onConfirmSignlessCb={onConfirmSignlessCb}
    >
      <div
        aria-label="Progress"
        className={cn('h-full w-full space-y-4 px-4', {})}
      >
        <h1 className="text-heading-xlg text-center pb-2">Portal creation</h1>
        <ol
          role="list"
          className={cn('overflow-hidden mx-auto max-w-[450px] space-y-6')}
        >
          {steps.map((step, stepIndex) => (
            <StepsCard
              key={step.name}
              stepsLength={steps.length}
              deployStatus={deployStatus}
              step={step}
              stepIndex={stepIndex}
              toggleVideoPlayer={toggleVideoPlayer}
              signlessState={signlesState}
              onGoSignlessClick={onGoSignlessClick}
              onSkipSignless={onSkipSignless}
            />
          ))}
        </ol>
      </div>
    </CreatePortalProvider>
  )
})

export const StepsCard = ({
  step,
  stepIndex,
  stepsLength,
  deployStatus,
  toggleVideoPlayer,
  signlessState,
  onGoSignlessClick,
  onSkipSignless,
}: {
  step: Step
  stepIndex: number
  stepsLength: number
  deployStatus: Onboarding
  toggleVideoPlayer: Dispatch<SetStateAction<boolean>>
  signlessState: {
    currentStep: 'ACTIVATE' | 'CONFIRM' | 'COMPLETE'
    isComplete: boolean
  }
  onGoSignlessClick: () => void
  onSkipSignless: () => void
}) => {
  const { name, isComplete, nextOptional } = step
  const position = stepIndex + 1
  const displayStepComponent = (index: number) => {
    switch (index) {
      case 0: {
        return (
          <CreatePortalStep
            name={name}
            isComplete={isComplete}
            deployStatus={deployStatus}
            toggleVideoPlayer={toggleVideoPlayer}
          />
        )
      }
      case 1: {
        return (
          <GenerateKeysStep
            name={name}
            isComplete={isComplete}
            deployStatus={deployStatus}
          />
        )
      }
      case 2: {
        return (
          <AccessPortalStep
            name={name}
            isComplete={isComplete}
            deployStatus={deployStatus}
            onGoSignlessClick={onGoSignlessClick}
            onSkipSignless={onSkipSignless}
            signlessComplete={signlessState.isComplete}
          />
        )
      }
      case 3: {
        return <SignlessComponent signlessState={signlessState} />
      }
      default:
        break
    }
  }

  return (
    <li key={name} className="relative mr-0">
      <StepItem
        isComplete={isComplete}
        isLastItem={position === stepsLength}
        position={position}
        isCurrent={deployStatus === stepIndex}
        title={name}
        nextOptional={nextOptional}
      >
        {displayStepComponent(stepIndex)}
      </StepItem>
    </li>
  )
}
