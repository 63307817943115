/* eslint-disable @typescript-eslint/no-explicit-any */

import { IFileMetaData } from '../../types/interface/file.interface'

export enum TaskQueueStatus {
  Preparing = 'Preparing',
  Pending = 'Pending',
  Uploading = 'Uploading',
  Completed = 'Completed',
  ContractInteraction = 'ContractInteraction',
}

export interface IQueueItem {
  item: File
  canBeginExecution: boolean
  contractInteractionRunning: boolean
  onExecutionComplete: () => void
  onCurrentUploadItemComplete: () => void
  onContractRunnerStatusChange: (status: boolean) => void
  isCurrentExecutingItem: boolean
}

export type TIPFSResponse = {
  contentIpfsHash: string
  metadataIpfsHash: string
}

interface SignlessState {
  isSignless: boolean
  loading: boolean
}
export interface IExecutionStatusIndicatorProps
  extends Pick<
    IQueueItem,
    | 'canBeginExecution'
    | 'contractInteractionRunning'
    | 'isCurrentExecutingItem'
  > {
  status: TaskQueueStatus
  handleExecutionStart: () => void
  handleFileUploads: () => Promise<void>
  handleContractInteraction: (isSignless: boolean) => Promise<void>
  signlessState: SignlessState
}

export type TUploadingIndicatorProps = Pick<
  IExecutionStatusIndicatorProps,
  'handleFileUploads' | 'canBeginExecution'
>

export type TContractInteractionIndicatorProps = Pick<
  IExecutionStatusIndicatorProps,
  | 'handleContractInteraction'
  | 'contractInteractionRunning'
  | 'isCurrentExecutingItem'
  | 'signlessState'
>

export interface IPendingStatusIndicatorProps
  extends Pick<IExecutionStatusIndicatorProps, 'handleExecutionStart'> {
  canBeginExecution: boolean
}

export interface IPreparedItem {
  metadata: IFileMetaData
  contentFile: File
  metadataFile: File
  fileTags: string[]
  gateIpfsHash?: string
}
