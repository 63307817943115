/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useAppDispatch } from '../store/hooks'
import {
  getPortalMetadataFromLastWorkingUrl,
  loadPortalState,
  verifyPortalChainId,
} from '../store/contract/thunks'
import {
  useContract,
  useContractChainId,
  useContractCollaborators,
} from '../store/contract/hooks'
import isEmpty from 'lodash/isEmpty'
import { getReadOnlyContract } from '../utils/contract'
import portalAbi from '../contract/portalAbi.json'
import { saveCollaboratorsList } from '../store/contract/reducer'
import useComponentVisibilty from './useVisibility'
import {
  useInvokerContractCredentials,
  useServerKeys,
} from '../store/invoker/hooks'
import useVisibility from './useVisibility'
import { checkIfOwnerOrCollaborator } from '../utils/checkIfOwnerOrCollaborator'
import { usePrivyHelper } from './usePrivyHelper'

const useHeaderHook = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const chainId = parseInt(searchParams.get('chainId') || '')
  const { address: contractAddress } = useParams()
  const { walletAddress, onDisconnectWallet } = usePrivyHelper()
  const contract = useContract((contractAddress as string) || '')
  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const portalCollaborators = useContractCollaborators(
    contractAddress as string
  )
  const serverKeys = useServerKeys(
    walletAddress as string,
    contractAddress as string
  )
  const isCollaborator = checkIfOwnerOrCollaborator(
    contract,
    walletAddress as string,
    serverKeys
  )
  const credential = useInvokerContractCredentials(
    walletAddress as string,
    contractAddress as string
  )
  const contractChainId = useContractChainId(contractAddress as string)
  const {
    ref: nonCollaboratorPopupRef,
    isComponentVisible: isNonCollaboratorPoupVisible,
    setIsComponentVisible: setNonCollaboratorPopupVisibility,
  } = useComponentVisibilty(false)

  const {
    ref: registerCollaboratorRef,
    isComponentVisible: isRegisterCollaboratorPopupVisible,
    setIsComponentVisible: setRegisterCollaboratorPopup,
  } = useComponentVisibilty(false)
  const {
    ref: accountDropDownRef,
    isComponentVisible: accountDropDownVisibility,
    setIsComponentVisible: setAccountDropDownVisibility,
  } = useVisibility(false)
  const {
    ref: aboutButtonRef,
    isComponentVisible: aboutDropDownVisibility,
    setIsComponentVisible: setAboutDropDownVisibility,
  } = useVisibility(false)
  const isUserOnPortalDashboard = () => {
    const path = window.location.hash.substring(45)
    if (
      (path.includes('files') ||
        path.includes('settings') ||
        path.includes('analytics') ||
        path.includes('people') ||
        path.includes('file') ||
        window.location.hash === `#/${contractAddress}?chainId=${chainId}`) &&
      isCollaborator
    ) {
      return true
    }
    return false
  }

  const queryAndUpdatePortalCollaborators = async () => {
    const portalInstance = getReadOnlyContract(
      contractAddress as string,
      portalAbi
    )
    const _portalCollaborators = await portalInstance.getCollaborators()
    dispatch(
      saveCollaboratorsList({
        contractAddress: contractAddress as string,
        collaborators: _portalCollaborators,
      })
    )
  }

  const handleDisconnectWallet = () => {
    onDisconnectWallet()
    navigate('/create')
  }
  useEffect(() => {
    if (
      portalCollaborators.includes(walletAddress as string) &&
      isEmpty(credential) &&
      contractAddress &&
      window.location.hash.substring(45).includes('member')
    ) {
      setRegisterCollaboratorPopup(true)
    }
  }, [walletAddress, portalCollaborators])
  useEffect(() => {
    ;(async () => {
      if (contractAddress && !isUserOnPortalDashboard()) {
        const verifiedChainId = await dispatch(
          verifyPortalChainId({
            chainId,
            contractAddress,
            contractChainId,
          })
        ).unwrap()
        if (verifiedChainId && chainId !== verifiedChainId) {
          searchParams.set('chainId', verifiedChainId.toString())
          setSearchParams(searchParams)
        }
        if (isEmpty(contract) && walletAddress && verifiedChainId) {
          await dispatch(
            loadPortalState({
              contractAddress: contractAddress as string,
              invokerAddress: walletAddress as string,
              chainId: verifiedChainId,
            })
          ).unwrap()
        }
      }
    })()
  }, [chainId, contractAddress])

  const dispatchPortalLatestMetadata = (contractAddress: string) => {
    dispatch(getPortalMetadataFromLastWorkingUrl({ contractAddress }))
  }

  useEffect(() => {
    if (chainId && contractAddress) {
      dispatchPortalLatestMetadata(contractAddress)
      queryAndUpdatePortalCollaborators()
    }
  }, [contractAddress])

  return {
    isRegisterCollaboratorPopupVisible,
    registerCollaboratorRef,
    handleDisconnectWallet,
    isNonCollaboratorPoupVisible,
    setNonCollaboratorPopupVisibility,
    nonCollaboratorPopupRef,
    setRegisterCollaboratorPopup,
    isUserOnPortalDashboard: isUserOnPortalDashboard(),
    headerRef: walletAddress ? accountDropDownRef : aboutButtonRef,
    setAboutDropDownVisibility,
    aboutDropDownVisibility,
    setAccountDropDownVisibility,
    accountDropDownVisibility,
  }
}
export default useHeaderHook
