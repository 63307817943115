import React, { Dispatch, SetStateAction } from 'react'
import { StepContent } from '../StepItem'
import { Onboarding } from '../../../types/enum/onboarding.enum'
import { STEPS_MAP } from '../../../constants'
import { CreatePortalButton } from '../Buttons/CreatePortalButton'

export const CreatePortalStep = ({
  name,
  isComplete,
  deployStatus,
  toggleVideoPlayer,
}: {
  name: string
  isComplete: boolean
  deployStatus: Onboarding
  toggleVideoPlayer: Dispatch<SetStateAction<boolean>>
}) => {
  return (
    <StepContent
      title={name}
      isComplete={isComplete}
      isCurrent={deployStatus === Onboarding.DEPLOY}
    >
      <div className="flex flex-col gap-4 py-2">
        <p className="text-body-sm color-text-secondary">
          {STEPS_MAP[Onboarding.DEPLOY]?.description || ''}
        </p>
        {deployStatus === Onboarding.DEPLOY && (
          <CreatePortalButton onToggleVideo={toggleVideoPlayer} />
        )}
      </div>
    </StepContent>
  )
}
