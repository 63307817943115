import React, { useState, useEffect } from 'react'

async function sha256(message: string): Promise<string> {
  const msgBuffer = new TextEncoder().encode(message)
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray
    .map((b) => ('00' + b.toString(16)).slice(-2))
    .join('')
  return hashHex
}

interface AvatarCanvasProps {
  inputValue: string
  canvasId: number
}

const AvatarCanvas: React.FC<AvatarCanvasProps> = ({
  inputValue,
  canvasId,
}) => {
  const [matrix, setMatrix] = useState<Array<number[]>>(
    Array(5).fill(Array(5).fill(0))
  ) // Initialize the matrix with 5x5 zeros

  useEffect(() => {
    const calculateMatrix = async () => {
      const hash = (await sha256(inputValue)).substr(0, 25) // Extract the first 25 characters
      const newMatrix: number[][] = []
      for (let i = 0; i < 5; i++) {
        newMatrix.push([])
        for (let j = 0; j < 5; j++) {
          const n = parseInt(hash[i * 5 + j], 16)
          newMatrix[i].push(n > 7 ? 0 : 1)
        }
      }
      setMatrix(newMatrix)
    }

    calculateMatrix()
  }, [inputValue])

  // const clearCanvas = () => {
  //   const canvas = document.querySelector('canvas') as HTMLCanvasElement
  //   const ctx = canvas.getContext('2d')
  //   if (ctx) {
  //     ctx.fillStyle = '#F8F8F8'
  //     ctx.fillRect(0, 0, canvas.width, canvas.height)
  //   }
  // }

  const drawMatrix = async () => {
    const canvas = document.getElementById(`${canvasId}`) as HTMLCanvasElement
    const ctx = canvas.getContext('2d')

    // Hash the inputValue and use it to generate RGB values
    const hash = await sha256(inputValue)
    const r = parseInt(hash.substring(0, 2), 16)
    const g = parseInt(hash.substring(2, 4), 16)
    const b = parseInt(hash.substring(4, 6), 16)

    if (ctx) {
      ctx.fillStyle = `rgba(${r}, ${g}, ${b}, 1)`

      for (let i = 0; i < 5; i++) {
        for (let j = 0; j < 5; j++) {
          if (matrix[i][j] === 1) {
            ctx.fillRect(j * 8, i * 8, 8, 8)
          }
        }
      }
    }
  }

  useEffect(() => {
    drawMatrix()
  }, [matrix])

  return <canvas id={canvasId.toString()} width={40} height={40}></canvas>
}

export default AvatarCanvas
