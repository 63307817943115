/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import {
  ButtonGroup,
  Icon,
  IconButton,
  LucideIcon,
  TextAreaField,
  TextField,
} from '@fileverse/ui'
import { usePublicPortalContext } from '../../../providers/PublicPortalProvider'
import { HeartBitCore, SupportedChain } from '@fileverse/heartbit-core'
import { defaultNetwork } from '../../../config/network-config'
import { getHashedString } from '../../../utils/ethUtils'
import { formatLargeNumbers, getTrimmedName } from '../../../utils/string'
import { useParams } from 'react-router-dom'
import { useContract, usePortalMetadata } from '../../../store/contract/hooks'
import { useMediaMax1025px } from '../../../hooks/useMediaQueryHook'
import cn from 'classnames'
import farcasterLogo from '../assets/farcasterlogo.png'
import lensLogo from '../assets/lens.png'

const Socials = () => {
  const contractAddress = useParams().address as string
  const portalMetadata = usePortalMetadata(contractAddress)
  return (
    <div className="w-fit">
      <ButtonGroup>
        {portalMetadata.socials.twitter && (
          <a
            href={portalMetadata.socials.twitter}
            target="_blank"
            rel="noopener noreferrer"
            className={cn('hover:color-button-secondary-hover rounded')}
          >
            <IconButton icon="X" variant="ghost" size="md" />
          </a>
        )}
        {portalMetadata.socials.link && (
          <a
            href={portalMetadata.socials.link}
            target="_blank"
            rel="noopener noreferrer"
            className={cn(
              'hover:color-button-secondary-hover rounded w-9 h-9 flex justify-center items-center'
            )}
          >
            <LucideIcon name="Globe" fill="none" size="md" />
          </a>
        )}
        {portalMetadata.socials.warpcast && (
          <a
            href={portalMetadata.socials.warpcast}
            target="_blank"
            rel="noopener noreferrer"
            className={cn(
              'hover:color-button-secondary-hover rounded w-9 h-9 flex justify-center items-center'
            )}
          >
            <div className=" w-9 h-9 flex items-center justify-center">
              <img src={farcasterLogo} alt="farcaster" className="w-6" />
            </div>
          </a>
        )}
        {portalMetadata.socials.lens && (
          <a
            href={portalMetadata.socials.lens}
            target="_blank"
            rel="noopener noreferrer"
            className={cn(
              'hover:color-button-secondary-hover rounded w-9 h-9 flex justify-center items-center'
            )}
          >
            <div className=" w-9 h-9 flex items-center justify-center">
              <img src={lensLogo} alt="lens" className="w-6 rounded-full" />
            </div>
          </a>
        )}
      </ButtonGroup>
    </div>
  )
}

export default function PortalInfo() {
  const {
    name,
    description,
    isViewMode,
    updateName,
    updateDescription,
    commentsMetadata,
  } = usePublicPortalContext()

  const { count } = commentsMetadata as any

  const contractAddress = useParams().address as string
  const isMediaMax1025px = useMediaMax1025px()
  const contract = useContract(contractAddress as string)
  const owner = contract.owner

  const [isExpanded, setIsExpanded] = useState<boolean>(false)

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded)
  }

  const descriptionText =
    description === '' && isViewMode
      ? `Coming sooooon! I'm exploring the decentralised whiteboards and collaborative documents right now. :)`
      : description

  const displayText =
    descriptionText.length > 75 && !isExpanded
      ? `${descriptionText.substring(0, 75)}...`
      : descriptionText

  return (
    <div className="w-full lg:w-[80%] px-4 flex flex-col sm:flex-row justify-between">
      <div className="w-full max-w-screen-sm flex flex-col justify-center items-start gap-4 lg:gap-5">
        <div className="w-full flex flex-col gap-2">
          {!isViewMode ? (
            <TextField
              defaultValue={name}
              onChange={(e) => updateName(e.target.value)}
              placeholder="Click to edit portal name"
              className="lg:!text-heading-2xlg !text-heading-xlg border-none outline-none shadow-none placeholder:color-text-disabled p-0"
            />
          ) : (
            <p className="lg:!text-heading-2xlg !text-heading-xlg p-0">
              {name}
            </p>
          )}
          <p className="text-helper-text-sm color-text-secondary">
            {`Owner: ${
              isMediaMax1025px ? getTrimmedName(owner, 7, 15) : owner
            } `}
          </p>
        </div>
        <div className="max-w-[564px] w-full flex flex-col gap-2">
          <p className="text-heading-md">About</p>
          {!isViewMode ? (
            <TextAreaField
              defaultValue={descriptionText}
              onChange={(e) => updateDescription(e.target.value)}
              className="!min-h-14 lg:!min-h-12 h-full !max-w-screen-sm w-full text-body-sm lg:text-body-md p-0 border-none outline-none shadow-none resize-none placeholder:color-text-disabled no-scrollbar"
              placeholder={`Click to edit description. Coming sooooon! I'm exploring the decentralized whiteboards and collaborative documents right now :)`}
            />
          ) : (
            <p
              className={`max-w-[564px] w-full text-wrap text-body-sm lg:text-body-md p-0 ${
                isExpanded ? 'h-auto' : 'h-fit'
              }`}
            >
              {displayText}
              <span
                className="text-body-sm-bold lg:text-body-md-bold cursor-pointer"
                onClick={toggleExpanded}
              >
                {' '}
                {descriptionText.length > 75
                  ? isExpanded
                    ? 'Less'
                    : 'More'
                  : ''}
              </span>
            </p>
          )}
        </div>
        <div className="w-fit block sm:hidden">
          <Socials />
        </div>
        <div className="w-full flex gap-6">
          <PageLikes />
          <div className="flex gap-2 justify-center items-center">
            <Icon name="Comment" fill="#77818A" size={'sm'} />
            <p className="text-body-sm color-text-secondary">{count}</p>
          </div>
        </div>
      </div>
      <div className="w-fit hidden sm:block">
        <Socials />
      </div>
    </div>
  )
}

const PageLikes = () => {
  const [likes, setLikes] = useState<string>('0')
  useEffect(() => {
    const fetchLikes = async () => {
      const heartBitSdk = new HeartBitCore({
        chain: defaultNetwork.networkId as SupportedChain,
      })
      const hash = getHashedString(window.location.href)
      const totalLikes = await heartBitSdk.getTotalHeartBitCountByHash({
        hash,
      })
      setLikes(formatLargeNumbers(totalLikes))
    }
    fetchLikes()
  }, [])

  return (
    <div className="flex gap-2 justify-center items-center">
      <Icon name="Heart" fill="#77818A" size={'sm'} />
      <p className="text-body-sm color-text-secondary">{likes}</p>
    </div>
  )
}
