/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  JsonRpcProvider,
  FallbackProvider,
  toUtf8Bytes,
  keccak256,
  isAddress,
  BrowserProvider,
  JsonRpcSigner,
} from 'ethers'
import { CHAIN_CONFIG_MAP, defaultNetwork } from '../config/network-config'

import { resolveToEvmAddress } from './getDefaultProfileName'
import { InvalidAddressError, SwitchNetworkRejected } from './errors'
import { switchChain, getAccount } from '@wagmi/core'
import { getClient, getConnectorClient } from '@wagmi/core'
import type { Client, Chain, Transport, Account } from 'viem'
import { AllowedChainId, config } from '../config/wagmi-config'
import { type Connector } from 'wagmi'
import { WALLET_RPC_METHOD_MAP } from '../config'

const PROVIDER_CACHE: any = {}

export const getDefaultProvider = () => {
  if (PROVIDER_CACHE[defaultNetwork.chainId])
    return PROVIDER_CACHE[defaultNetwork.chainId]

  PROVIDER_CACHE[defaultNetwork.chainId] = new JsonRpcProvider(
    defaultNetwork.rpcUrl,
    { chainId: defaultNetwork.chainId },
    { staticNetwork: true }
  )

  return PROVIDER_CACHE[defaultNetwork.chainId]
}

export const getBlockNumber = async () => {
  const provider = getDefaultProvider()
  return await provider.getBlockNumber()
}

export const getHashedString = (str: string) => keccak256(toUtf8Bytes(str))

// TODO Fix Initialization
// export const getEthersProvider = async () => {
//   if (!window?.ethereum) throw new Error('Wallet not Found')

//   await (window as any).ethereum.send('eth_requestAccounts')
//   return new ethers.JsonRpcProvider((window as any).ethereum)
// }

export class EthersProviderError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'EthersProviderError'
  }
}

// TODO: Revisit post migration
export const switchWalletChain = async (
  chainIdHex: string,
  postSwitchChainCallback?: () => void
) => {
  try {
    // const provider = getEthersProvider()
    // await provider.send('wallet_switchEthereumChain', [{ chainId: chainIdHex }])
    if (typeof postSwitchChainCallback === 'function') postSwitchChainCallback()
  } catch (err) {
    throw new EthersProviderError('Failed to switch chain')
  }
}

// export const getWalletBalance = async (address: string) => {
//   const provider = await getEthersProvider()
//   const balance = await provider.getBalance(address)
//   return ethers.formatEther(balance)
// }

// export const getGasPrice = async () => {
//   const provider = await getEthersProvider()
//   const gasPrice = await provider.getGasPrice()
//   return ethers.formatEther(gasPrice)
// }

// export const estimateGas = async () => {
//   return ethers.formatEther(2100)
// }

export const isEvmAddress = (address: string) => isAddress(address)

export const getValidEvmAddress = async (addressString: string) => {
  if (isEvmAddress(addressString)) return addressString
  const resolvedAddress = await resolveToEvmAddress(addressString)
  if (resolvedAddress) return resolvedAddress
  throw new InvalidAddressError('Invalid Address')
}

export const addEthereumChain = async (
  connector: Connector,
  chainToAdd: number
) => {
  const provider: any = await connector.getProvider()
  const chainData = CHAIN_CONFIG_MAP[chainToAdd]
  if (chainData && provider)
    provider
      .request({
        method: WALLET_RPC_METHOD_MAP.ADD_CHAIN,
        params: [chainData],
      })
      .then((res: any) => {
        console.log('added chain', res)
      })
      .catch((err: any) => {
        console.error(err)
      })
}

export const validateOrSwitchNetwork = async (
  chainId: AllowedChainId,
  addChainMannually = false
) => {
  const { chainId: currentChainId, connector } = getAccount(config)
  if (addChainMannually && connector) await addEthereumChain(connector, chainId)
  if (chainId === currentChainId) return
  try {
    await switchChain(config, { chainId: chainId })
  } catch (err) {
    throw new SwitchNetworkRejected('Switch Network Rejected')
  }
}

export const clientToProvider = (client: Client<Transport, Chain>) => {
  const { chain, transport } = client
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  }
  if (transport.type === 'fallback') {
    const providers = (transport.transports as ReturnType<Transport>[]).map(
      ({ value }) => new JsonRpcProvider(value?.url, network)
    )
    if (providers.length === 1) return providers[0]
    return new FallbackProvider(providers)
  }
  if (PROVIDER_CACHE[chain.id]) return PROVIDER_CACHE[chain.id]
  PROVIDER_CACHE[chain.id] = new JsonRpcProvider(transport.url, network, {
    staticNetwork: true,
  })
  return PROVIDER_CACHE[chain.id]
}

export const getEthersProvider = ({
  chainId,
}: { chainId?: AllowedChainId } = {}) => {
  const client = getClient(config, { chainId })
  const provider = clientToProvider(client as Client<Transport, Chain>) // Cast 'client' to 'Client<Transport, Chain>'
  return provider
}

export function clientToSigner(client: Client<Transport, Chain, Account>) {
  const { account } = client

  const provider = clientToBrowserProvider(client)
  const signer = new JsonRpcSigner(provider, account.address)
  return signer
}

/** Action to convert a viem Wallet Client to an ethers.js Signer. */
export async function getEthersSigner({
  chainId,
}: { chainId?: AllowedChainId } = {}) {
  const client = await getConnectorClient(config, { chainId })
  return clientToSigner(client)
}

export const clientToBrowserProvider = (
  client: Client<Transport, Chain, Account>
) => {
  const { chain, transport } = client
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  }
  return new BrowserProvider(transport, network)
}

export const getBrowserProvider = async () => {
  const client = await getConnectorClient(config, {
    chainId: defaultNetwork.chainId as AllowedChainId,
  })
  return clientToBrowserProvider(client as Client<Transport, Chain, Account>)
}
