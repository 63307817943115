import axios from 'axios'
import { generateToken } from '../utils'

const fetchTasks = async ({
  contractAddress,
  credentialEditSecret,
  invokerAddress,
  chain,
}: {
  contractAddress: string
  credentialEditSecret: string
  invokerAddress: string
  chain: number
}) => {
  const URL = `${process.env.REACT_APP_STORAGE_URL}/task/list`

  const token = await generateToken(contractAddress, credentialEditSecret || '')
  const { data } = await axios.get(URL, {
    headers: {
      Authorization: `Bearer ${token}`,
      invoker: invokerAddress,
      contract: contractAddress,
      chain,
    },
  })
  return data
}
export default fetchTasks
