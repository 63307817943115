/* eslint-disable @typescript-eslint/no-explicit-any */
import { Editor, EditorContent } from '@tiptap/react'
import React, { useEffect, useRef } from 'react'
import { EditorBubbleMenu } from './EditorBubbleMenu'
import { EditingProvider } from '../../hooks/useEditingContext'

const DefaultEditor = ({
  editor,
  previewMode,
}: {
  editor: Editor
  previewMode: boolean
}) => {
  const ref = useRef<HTMLDivElement>(null)

  const focusEditor = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if ((ref.current as any)?.contains(e.target)) return
    editor?.chain().focus().run()
  }

  useEffect(() => {
    editor.chain().focus()
  }, [])
  return (
    <div
      onClick={focusEditor}
      className="h-full mt-[68px] w-full flex justify-center"
    >
      <div className="p-[96px] min-h-[1056px] shadow-inner bg-white overflow-scroll no-scrollbar rounded-md w-[70%] max-w-[856px]">
        <div ref={ref} className="w-full h-full">
          {!previewMode && (
            <div>
              <EditorBubbleMenu editor={editor} />
            </div>
          )}
          <EditingProvider isDisabled={previewMode}>
            <EditorContent editor={editor} />
          </EditingProvider>
        </div>
      </div>
    </div>
  )
}

export default DefaultEditor
