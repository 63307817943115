import React from 'react'
import { LucideIcon } from '@fileverse/ui'
import cn from 'classnames'
interface SpinnerProps {
  height?: number
  width?: number
  stroke?: string
}
export const Spinner = ({ height, width, stroke }: SpinnerProps) => {
  return (
    <LucideIcon
      name="LoaderCircle"
      className={cn(
        'animate-spin',
        height && `h-${height}`,
        width && `w-${width}`
      )}
      fill="transparent"
      stroke={stroke || 'black'}
    />
  )
}
export default Spinner
