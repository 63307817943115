/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, {
  SetStateAction,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Popup } from './PopUp'
import ActionButton from '../Buttons/ActionButton'
import { useDropzone } from 'react-dropzone'
import { useMediaQuery } from '@mui/material'
import cn from 'classnames'
import UploadIcon from '../../assets/upload3.svg'
import Spinner from '../Spinner'
import DownloadDoneIcon from '@mui/icons-material/DownloadDone'
import ErrorIcon from '@mui/icons-material/Error'
import { ContractCard } from '../Contracts/ContractCard'
import { loadPortalState } from '../../store/contract/thunks'
import sendNotifcation from '../../utils/notification'
import { isSafeApp } from '../../utils/safeApp'
import { useAppDispatch } from '../../store/hooks'
import { useNavigate } from 'react-router-dom'
import {
  setPortalAgent,
  setPortalSignlessMode,
} from '../../store/contract/reducer'
import { setInvokerAgentKey } from '../../store/invoker/reducer'
import { ICredentialInvoker } from '../../types/interface/invoker.interface'
import {
  hasAgentKeyAndAddress,
  validatePortalKeyParams,
} from '../../utils/recover/portalKeys'
import { verifyPortalKeys } from '../../utils/recover/verifyPortalKeys'
import {
  addInvokerCredentialsThunk,
  addInvokerServerKeysThunk,
} from '../../store/invoker/thunks'
import {
  InvalidRecoveryJsonError,
  MissingAgentKeyAndAddressError,
} from '../../utils/errors'
import { usePrivyHelper } from '../../hooks/usePrivyHelper'

enum RECOVERY_STATE {
  IDLE = 'IDLE',
  PROCESSING = 'PROCESSING',
  ERROR = 'ERROR',
  FETCHING_DATA = 'FETCHING_DATA',
  DONE = 'DONE',
}

interface IRecoveryKeys {
  contractAddress: string
  invokerAddress: string
  portalEncryptionKey: string
  portalDecryptionKey: string
  memberEncryptionKey: string
  memberDecryptionKey: string
  agentKey: string
  agentAddress: string
  ownerEncryptionKey: string
  ownerDecryptionKey: string
  portalGunKey: string
  newCredentialInvoker: ICredentialInvoker
}

const Description = ({ recoveryState }: { recoveryState: RECOVERY_STATE }) => {
  if (recoveryState === RECOVERY_STATE.ERROR) {
    return (
      <>
        <p className="underline">Error pairing with portal: </p>
        <p>
          You need to connect the wallet that owns this Portal and file keys!
        </p>
      </>
    )
  }
  if (
    recoveryState === RECOVERY_STATE.FETCHING_DATA ||
    recoveryState === RECOVERY_STATE.DONE
  ) {
    return <>Your Portal is now available!</>
  }
  return (
    <>
      Upload your Portal’s backup keys (found in Settings) while connected to
      the same wallet address.
    </>
  )
}

export default function RecoverPopup({
  isOpen,
  setOpenRecoveryPopup,
}: {
  isOpen: boolean
  setOpenRecoveryPopup: React.Dispatch<SetStateAction<boolean>>
}) {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const invokerAddress = usePrivyHelper().walletAddress
  const popUpRef = useRef<HTMLInputElement>(null)
  const inputFilesRef = useRef<HTMLInputElement>(null)
  const isMediaMax1025px = useMediaQuery('(max-width : 1025px)')

  const [recoveryState, setRecoveryState] = useState<RECOVERY_STATE>(
    RECOVERY_STATE.IDLE
  )
  const [fetchedContractAddress, setFetchedContractAddress] =
    useState<string>('')
  const [fetchedChainId, setFetchedChainId] = useState<string>('')

  const fileInputClick = () => {
    if (inputFilesRef.current) {
      inputFilesRef.current.click()
    }
  }
  const onAllFileSelectClick = (e: SyntheticEvent) => {
    e.stopPropagation()
    inputFilesRef.current!.click()
  }

  const addUpdatedKeys = async (payload: IRecoveryKeys) => {
    const {
      agentKey,
      agentAddress,
      contractAddress,
      invokerAddress,
      newCredentialInvoker,
    } = payload
    await dispatch(addInvokerCredentialsThunk(newCredentialInvoker))
    await dispatch(addInvokerServerKeysThunk(payload))

    if (agentKey && agentAddress) {
      dispatch(
        setInvokerAgentKey({
          invokerAddress,
          agentKey,
          contractAddress,
        })
      )
      dispatch(
        setPortalAgent({
          contractAddress,
          agentAddress,
        })
      )
      dispatch(setPortalSignlessMode({ contractAddress, enabled: true }))
    }
  }

  const handleImportViaFileUpload = (file: File[]) => {
    setRecoveryState(RECOVERY_STATE.PROCESSING)
    const reader = new FileReader()
    reader.onload = async (e: ProgressEvent<FileReader>) => {
      try {
        const text = JSON.parse(decodeURIComponent(e?.target?.result as string))

        validatePortalKeyParams(text, invokerAddress as string)

        const {
          contractAddress,
          viewSecret,
          editSecret,
          portalEncryptionKey,
          portalDecryptionKey,
          memberEncryptionKey,
          memberDecryptionKey,
          viewDID,
          chainId,
          ownerDecryptionKey,
          ownerEncryptionKey,
          agentAddress,
          agentKey,
          owner,
          portalGunKey,
        } = text

        const _isSafeApp = await isSafeApp()

        if (!hasAgentKeyAndAddress(text) && _isSafeApp)
          throw new MissingAgentKeyAndAddressError(
            'Agent key and address not found in the file you uploaded'
          )

        const portal = await dispatch(
          loadPortalState({
            contractAddress,
            invokerAddress: invokerAddress as string,
            chainId,
            owner,
          })
        )

        const isVerified = await verifyPortalKeys({
          chainId,
          contractAddress,
          invokerAddress: invokerAddress as string,
          portalEncryptionKey,
          portalDecryptionKey,
          memberDecryptionKey,
          memberEncryptionKey,
          owner,
          portal,
        })

        if (!isVerified) throw Error('Failed to verify Portal Keys')

        const newCredentialInvoker: ICredentialInvoker = {
          credential: {
            contractAddress: contractAddress as string,
            viewDID: viewDID,
            editDID: viewDID,
            editSecret,
            viewSecret,
          },
          invokerAddress: invokerAddress as string,
        }

        await addUpdatedKeys({
          contractAddress,
          invokerAddress: invokerAddress as string,
          portalEncryptionKey,
          portalDecryptionKey,
          memberDecryptionKey,
          memberEncryptionKey,
          agentKey,
          agentAddress,
          ownerDecryptionKey,
          ownerEncryptionKey,
          portalGunKey,
          newCredentialInvoker,
        })

        setFetchedContractAddress(contractAddress)
        setFetchedChainId(chainId)
        setRecoveryState(RECOVERY_STATE.FETCHING_DATA)
        sendNotifcation('Credentials recovered successfully', '', 'success')
        setTimeout(() => {
          setRecoveryState(RECOVERY_STATE.DONE)
        }, 1000)
      } catch (err: any) {
        setRecoveryState(RECOVERY_STATE.ERROR)
        if (err instanceof InvalidRecoveryJsonError)
          sendNotifcation('Not all credentials was provided', '', 'danger')
        else if (err instanceof MissingAgentKeyAndAddressError)
          sendNotifcation(
            'Failed to import portal',
            'Signless keys not found in the file you uploaded, all safe account should have a signless key',
            'danger'
          )
        else
          sendNotifcation(
            'Unable to read credentials from file',
            `${err?.message}`,
            'danger'
          )
      }
    }
    reader.readAsText(file[0])
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleImportViaFileUpload,
    maxFiles: 1,
    noClick: true,
  })

  const retryRecovery = () => {
    setRecoveryState(RECOVERY_STATE.IDLE)
  }
  const navigateToRecoveredPortal = () => {
    navigate(`/${fetchedContractAddress}?chainId=${fetchedChainId}`)
  }

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        popUpRef.current &&
        !popUpRef.current.contains(event.target as Node) &&
        recoveryState !== RECOVERY_STATE.PROCESSING
      ) {
        setRecoveryState(RECOVERY_STATE.IDLE)
        setOpenRecoveryPopup(false)
      }
    }

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpen, setOpenRecoveryPopup, recoveryState])

  return (
    <Popup isOpen={isOpen} width="426px">
      <div
        ref={popUpRef}
        className={cn(
          'w-full bg-white flex flex-col justify-center items-center gap-7 p-7 rounded-xl',
          isMediaMax1025px && 'rounded-b-none'
        )}
      >
        <div className="w-full flex flex-col justify-center items-center gap-4 text-center px-2">
          <p className={cn(isMediaMax1025px ? 'text-2xl' : 'text-[32px]')}>
            {recoveryState === RECOVERY_STATE.ERROR ? (
              <>Pair your Portal</>
            ) : (
              <>Access Your Portal</>
            )}
          </p>
          <p
            className={cn(
              'text-[#777777]',
              isMediaMax1025px ? 'text-sm' : 'text-xl'
            )}
          >
            <Description recoveryState={recoveryState} />
          </p>
        </div>

        {recoveryState === RECOVERY_STATE.DONE ? (
          <ContractCard
            invokerAddress={invokerAddress as string}
            contractAddress={fetchedContractAddress as string}
          />
        ) : (
          <div
            className={cn(
              `w-full rounded-lg cursor-default border-dashed border-2 py-8 px-4`,
              isMediaMax1025px ? 'h-[255px]' : 'h-[315px]'
            )}
            {...getRootProps()}
          >
            <div
              className={cn(
                'w-full h-full flex flex-col items-center justify-center py-4 px-2  mb-2 rounded',
                {
                  'inset-0 grid place-content-center bg-white opacity-60 rounded-lg':
                    isDragActive,
                }
              )}
              onClick={fileInputClick}
            >
              {recoveryState === RECOVERY_STATE.IDLE && (
                <>
                  <input {...getInputProps()} />
                  <div>
                    <div
                      className={cn(
                        'cursor-pointer flex flex-col justify-center items-center gap-4 mt-4'
                      )}
                    >
                      <div
                        className={cn(
                          'w-[120px] h-auto aspect-square rounded-full bg-yellow flex justify-center items-center'
                        )}
                      >
                        <img
                          src={UploadIcon}
                          alt="Upload Files"
                          className={`w-12`}
                        />
                      </div>
                      <div
                        className={cn(
                          'text-center font-bold',
                          isMediaMax1025px ? 'text-lg' : 'text-2xl leading-6'
                        )}
                        onClick={onAllFileSelectClick}
                      >
                        <input
                          data-cy="file-input"
                          multiple={true}
                          type={'file'}
                          ref={inputFilesRef}
                          accept="application/JSON"
                          className="hidden"
                          onChange={(e) => {
                            const uploadedFiles = Array.from(
                              e.target.files as ArrayLike<File>
                            )
                            handleImportViaFileUpload(uploadedFiles)
                          }}
                        />
                        <>
                          Drag & drop your backup keys or &nbsp;
                          <span className="underline">Browse</span>
                        </>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {recoveryState === RECOVERY_STATE.PROCESSING && (
                <div className="flex flex-col justify-center items-center gap-5">
                  <Spinner />
                  <p className="font-medium">
                    Privately loading your Portal data...
                  </p>
                </div>
              )}
              {recoveryState === RECOVERY_STATE.FETCHING_DATA && (
                <div
                  className={cn(
                    'w-full cursor-pointer flex flex-col justify-center items-center gap-4 mt-4'
                  )}
                >
                  <div
                    className={cn(
                      'w-[120px] h-auto aspect-square rounded-full bg-yellow flex justify-center items-center border-2 border-dashed border-black'
                    )}
                  >
                    <DownloadDoneIcon fontSize="large" />
                  </div>
                </div>
              )}
              {recoveryState === RECOVERY_STATE.ERROR && (
                <div
                  className={cn(
                    'w-full cursor-pointer flex flex-col justify-center items-center gap-4 mt-4'
                  )}
                >
                  <div
                    className={cn(
                      'w-[120px] h-auto aspect-square rounded-full bg-yellow flex justify-center items-center border-2 border-dashed border-black'
                    )}
                  >
                    <ErrorIcon fontSize="large" />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {recoveryState === RECOVERY_STATE.IDLE && (
          <div className="w-full">
            <ActionButton onclick={fileInputClick} title={'Recover'} />
          </div>
        )}
        {recoveryState === RECOVERY_STATE.ERROR && (
          <div className="w-full">
            <ActionButton onclick={retryRecovery} title={'Retry'} />
          </div>
        )}
        {recoveryState === RECOVERY_STATE.DONE && (
          <div className="w-full">
            <ActionButton onclick={navigateToRecoveredPortal} title={'Enter'} />
          </div>
        )}
      </div>
    </Popup>
  )
}
