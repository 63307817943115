/* eslint-disable @typescript-eslint/no-explicit-any */
import { deleteFile, editFile } from '../store/files/reducer'
import { getFileData } from '../store/files/thunks'
import { useAppDispatch } from '../store/hooks'
import { usePortalLatestBlockNumber } from '../store/portalUpdateLogs/hooks'
import { updatePortalLatestBlockNumber } from '../store/portalUpdateLogs/reducer'
import { INewFile } from '../types/interface/file.interface'
import { getIPFSAsset } from '../utils/getIPFSAsset'
import { useCallback, useEffect, useState } from 'react'
import { getMostRecentUpdatedFilesFromContract } from '../utils/portal'
import { useEthersProvider } from './clientToProvider'
import { getPortalCreationInfo } from './usePortalCreationTimeStampHook'

const usePortalEditFileEvent = (contractAddress: string) => {
  const [isFetchingEditedFiles, setIsFetchingEditedFile] =
    useState<boolean>(false)

  const dispatch = useAppDispatch()
  const provider = useEthersProvider()
  const latestBlock = usePortalLatestBlockNumber(contractAddress as string)

  const processContractEvent = useCallback(async () => {
    setIsFetchingEditedFile(true)
    let fromBlock = parseInt(latestBlock.toString())
    try {
      if (!fromBlock) {
        const { blockNumber: contractBlockNumber } =
          await getPortalCreationInfo(contractAddress)
        fromBlock = contractBlockNumber
      }
      const latestEditedFiles = await getMostRecentUpdatedFilesFromContract(
        contractAddress,
        fromBlock
      )

      for (let i = 0; i < latestEditedFiles.length; i++) {
        const file = await dispatch(
          getFileData({
            contractAddress: contractAddress as string,
            fileId: parseInt(latestEditedFiles[i]?.args?.fileId),
          })
        ).unwrap()

        if (!file) continue

        const metadata = await getIPFSAsset({
          ipfsHash: latestEditedFiles[i]?.args?.metadataIPFSHash,
        })

        if (metadata?.data?.isDeleted) {
          dispatch(
            deleteFile({
              contractAddress,
              fileId: file.fileId,
            })
          )
        } else {
          const updatedFile: INewFile = {
            ...file,
            contentIPFSHash: latestEditedFiles[i]?.args?.contentIPFSHash,
            metadataIPFSHash: latestEditedFiles[i]?.args?.metadataIPFSHash,
            metadata: metadata?.data,
            cache: { metadataIPFSUrl: '', contentIPFSUrl: '' },
          }

          dispatch(
            editFile({
              contractAddress: contractAddress as string,
              fileData: updatedFile,
            })
          )
        }
      }
      // save latest block number after processing all the events from previous blocks
      const latestBlockNumber = await provider.getBlockNumber()
      if (latestBlockNumber <= fromBlock) return
      dispatch(
        updatePortalLatestBlockNumber({
          contractAddress: contractAddress as string,
          latestBlock: latestBlockNumber,
        })
      )
    } catch (error: any) {
      console.log(error)
    } finally {
      setIsFetchingEditedFile(false)
    }
  }, [contractAddress])

  useEffect(() => {
    processContractEvent()
  }, [processContractEvent])

  return { isFetchingEditedFiles }
}

export default usePortalEditFileEvent
