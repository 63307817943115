import Quill from 'quill'
import QuillCursors from 'quill-cursors'
import { QuillBinding } from 'y-quill'
import * as Y from 'yjs'

export const QuillInstance = () => {
  Quill.register('modules/cursors', QuillCursors)
  const quill = new Quill(document.querySelector('#editor') as Element, {
    modules: {
      cursors: true,
      toolbar: '#toolbar',
      history: {
        userOnly: true,
      },
      clipboard: {
        matchVisual: false,
      },
    },
    theme: 'snow',
    placeholder: 'Page Title',
    scrollingContainer: 'html',
  })
  return quill
}

export const BindYtextToQuill = (ytext: Y.Text, quill: Quill) => {
  return new QuillBinding(ytext, quill)
}
