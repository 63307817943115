import React, { useRef, useState } from 'react'
import { Button, LucideIcon } from '@fileverse/ui'
import coverImage from '../assets/Cover.png'
import { useParams, useSearchParams } from 'react-router-dom'
import { uploadSingleFileToIpfs } from '../../../store/files/thunks'
import { useAppDispatch } from '../../../store/hooks'
import { useInvokerContractCredentials } from '../../../store/invoker/hooks'
import { ICredential } from '../../../types/interface/invoker.interface'
import { getPublicFileUrl } from '../../../utils/files/filePreviewHandlers'
import { usePublicPortalContext } from '../../../providers/PublicPortalProvider'
import { usePrivyHelper } from '../../../hooks/usePrivyHelper'

export default function PortalCover() {
  const { coverIPFSUrl, isViewMode, updateCoverIPFSUrl } =
    usePublicPortalContext()
  const imageInputRef = useRef<HTMLInputElement | null>(null)
  const [coverHover, setCoverHover] = useState<boolean>(false)
  const [isCoverLoading, setIsCoverLoading] = useState<boolean>(false)
  const [coverSrc, setCoverSrc] = useState<string>(coverIPFSUrl || coverImage)

  const walletAddress = usePrivyHelper().walletAddress as string

  const { address: contractAddress } = useParams()
  const [searchParams] = useSearchParams()
  const chainId = parseInt(searchParams.get('chainId') || '')
  const dispatch = useAppDispatch()

  const credential = useInvokerContractCredentials(
    walletAddress as string,
    contractAddress as string
  ) as ICredential

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      if (event.target.files && event.target.files[0]) {
        setIsCoverLoading(true)

        const uploadedCoverIPFSHash = await dispatch(
          uploadSingleFileToIpfs({
            credential,
            file: event.target.files[0],
            fileName: `logo`,
            contractAddress: contractAddress as string,
            invoker: walletAddress as string,
            chainId,
          })
        ).unwrap()

        const fileUrl = await getPublicFileUrl(uploadedCoverIPFSHash)
        updateCoverIPFSUrl(fileUrl.ipfsUrl)
        setCoverSrc(fileUrl.ipfsUrl)
      }
    } catch (err) {
      console.log(err)
    } finally {
      setIsCoverLoading(false)
      event.target.value = ''
    }
  }
  return (
    <div className="relative h-[320px]">
      {isCoverLoading && (
        <div className="rounded-xl absolute inset-0 color-utility-overlay flex justify-center items-center">
          <LucideIcon
            name="LoaderCircle"
            size={'lg'}
            className="animate-spin"
            fill="transparent"
            stroke={'white'}
          />
        </div>
      )}
      <img
        src={coverSrc}
        alt="portal-cover"
        className="w-full h-[320px] object-cover rounded-b-xl md:rounded-xl"
        onMouseEnter={() => setCoverHover(true)}
        onMouseLeave={() => setCoverHover(false)}
        onError={() => setCoverSrc(coverImage)}
      />
      <input
        ref={imageInputRef}
        className="hidden absolute"
        type="file"
        accept="image/*"
        onChange={(e) => handleImageChange(e)}
      />
      {coverHover && !isViewMode && (
        <div
          className="absolute bottom-5 right-5 flex flex-col items-end gap-2"
          onMouseEnter={() => setCoverHover(true)}
          onMouseLeave={() => setCoverHover(false)}
        >
          {coverIPFSUrl && (
            <Button
              toggleLeftIcon={true}
              leftIcon="Trash"
              className="text-body-sm-bold color-text-inverse w-fit rounded-[4px]"
              size={'sm'}
              onClick={() => {
                updateCoverIPFSUrl('')
                setCoverSrc('')
              }}
            >
              Remove
            </Button>
          )}
          <Button
            toggleLeftIcon={true}
            leftIcon="Camera"
            className="text-body-sm-bold color-text-inverse"
            size={'sm'}
            onClick={() => {
              imageInputRef?.current?.click()
            }}
          >
            Edit cover image
          </Button>
        </div>
      )}
    </div>
  )
}
