import axios from 'axios'

export const getPortalMembers = async ({
  contractAddress,
  contractChainId,
  invokerAddress,
}: {
  contractAddress: string
  contractChainId: number
  invokerAddress: string
}) => {
  const chainId = contractChainId
  const contract = contractAddress
  return axios.get(`${process.env.REACT_APP_GATE_URL}/contract/members`, {
    headers: {
      contract,
      invoker: invokerAddress,
      chainId,
    },
  })
}
