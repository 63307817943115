import React from 'react'
import cn from 'classnames'
import { useCreatePortal } from '../../../providers'

import styles from './index.module.scss'
import { useNavigate } from 'react-router-dom'
import { defaultNetwork } from '../../../config/network-config'
import { Button } from '../../../pages/PublicPortal/components/Button'

export const SIGNLESS_DESCRIPTION_MAP = {
  ACTIVATE: {
    desc: 'By activating signless you are creating an Onchain agent that will sign Portal-related actions/transactions on your behalf.',
    btnText: 'Activate',
    btnLoadingText: 'Activating...',
    stepIdx: 1,
    btnCss: 'h-[45px] w-[112px] rounded',
  },
  CONFIRM: {
    desc: 'Confirm here to add your Onchain agent as a collaborator to your Portal.',
    btnText: 'Confirm',
    btnLoadingText: 'Confirming...',
    stepIdx: 2,
    btnCss: 'h-[45px] w-[112px] rounded',
  },
  COMPLETE: {
    desc: 'Congrats, you just completed the signless activation! Ready to start exploring?',
    btnText: <>Open Your Portal</>,
    btnLoadingText: '',
    stepIdx: 2,
    btnCss: 'h-[43px] w-[180px] rounded',
  },
}

export const SignlessComponent = ({
  signlessState,
}: {
  signlessState: {
    currentStep: 'ACTIVATE' | 'CONFIRM' | 'COMPLETE'
    isComplete: boolean
  }
}) => {
  const {
    onActivateSignless,
    onConfirmSignless,
    portalAddress: contractAddress,
  } = useCreatePortal()
  const { currentStep, isComplete } = signlessState
  const navigate = useNavigate()

  const onButtonClick = () => {
    if (currentStep === 'ACTIVATE') onActivateSignless()
    if (currentStep === 'CONFIRM') onConfirmSignless()
    if (currentStep === 'COMPLETE')
      navigate(`/${contractAddress}?chainId=${defaultNetwork.chainId}`, {
        state: {
          newPortal: true,
        },
      })
  }

  return (
    <span className={cn('flex gap-2 min-w-0 flex-col ml-6 mb-4', {})}>
      <div className={cn(styles.accordian_toggler)}>
        <span className="text-heading-sm-bold">Go signless now!</span>
      </div>
      <SignlessChildren
        currentStep={currentStep}
        isComplete={isComplete}
        onButtonClick={onButtonClick}
      />
    </span>
  )
}

export const SignlessChildren = ({
  currentStep,
  onButtonClick,
}: {
  onButtonClick: () => void
  isComplete: boolean
  currentStep: 'ACTIVATE' | 'CONFIRM' | 'COMPLETE'
}) => {
  const { isLoading } = useCreatePortal()

  const desc = SIGNLESS_DESCRIPTION_MAP[currentStep]?.desc || ''
  const btnText = SIGNLESS_DESCRIPTION_MAP[currentStep]?.btnText || ''
  const btnLoadingText =
    SIGNLESS_DESCRIPTION_MAP[currentStep]?.btnLoadingText || ''
  const stepIdx = SIGNLESS_DESCRIPTION_MAP[currentStep]?.stepIdx || 0
  const currentButtonText = isLoading ? btnLoadingText : btnText

  return (
    <div className={cn(styles.signless_content, {})}>
      <p className={cn('text-body-sm color-text-secondary', {})}>{desc}</p>
      <div
        className={cn(styles.signless_step_indicator, {
          ['hidden']: currentStep === 'COMPLETE',
        })}
      >{`Step ${stepIdx} of 2`}</div>
      <Button
        onClick={onButtonClick}
        isLoading={isLoading}
        className="min-w-[112px] min-h-[35px]"
        disabled={isLoading}
      >
        {currentButtonText}
      </Button>
    </div>
  )
}
