/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ISEAPair } from 'gun'
import * as CryptoJS from 'crypto-js'
import unlockGateAPI from '../../../api/gateapi/unlockGateApi'
import { DataLockedKeyMaterial } from '../../../types/interface/file.interface'
import { getIPFSAsset } from '../../getIPFSAsset'
import { verifyPortalMembersKeysFromPortalContract } from '../../messages/keyVerifiers'
import { requestSignature } from '../../messages/requestSignature'
import decryptFile from './decryptFile'
import { openLock } from './openLock'
import {
  PORTAL_MEMBERSHIP_MESSAGE,
  TOKEN_FILE_MESSAGE,
} from '../../../constants'
import { IFileAccess } from '../filePreviewHandlers'
import { FileTypeEnum } from '../../../types/enum/file.enum'
import { getMemberKeysNode } from '../../../hooks/useGunNode'

const idTracker: string[] = []

export const unlockFile = async (
  decryptionKey: string,
  fileLock: DataLockedKeyMaterial | null,
  contentIPFSHash: string,
  ipfsUrl?: string,
  fileType?: FileTypeEnum
): Promise<
  { file: string; chatKey: ISEAPair; ipfsUrl: string } | undefined
> => {
  if (!fileLock || !decryptionKey || !contentIPFSHash) return
  const unLockedKeys = await openLock(decryptionKey, fileLock, fileType)
  const result = await decryptFile(
    unLockedKeys.fileKey,
    contentIPFSHash,
    ipfsUrl
  )
  return {
    file: result.downloadUrl,
    chatKey: unLockedKeys.chatKey,
    ipfsUrl: result.ipfsUrl as string,
  }
}

export const getAndVerifyMemberKeysAndUnlockFile = async (
  contractAddress: string,
  account: string,
  signMessageAsync: (args?: any) => Promise<`0x${string}`>,
  chainId: number,
  memberLock: DataLockedKeyMaterial | null,
  contentIPFSHash: string
): Promise<IFileAccess | undefined> => {
  const signatureRequest = await requestSignature(
    account as string,
    PORTAL_MEMBERSHIP_MESSAGE,
    signMessageAsync
  )
  const memberKeyNode = getMemberKeysNode(contractAddress)
  const fileAccess = await Promise.race([
    new Promise((resolve) => {
      memberKeyNode.on(async (data, id) => {
        if (!idTracker.includes(id)) {
          idTracker.push(id)
          const memberGateId = data?.memberGateId
          const encryptedMemberDecryptionKey = data?.memberGateKey

          if (memberGateId && encryptedMemberDecryptionKey) {
            const unlockGateResponse = await unlockGateAPI({
              invokerAddress: account,
              message: signatureRequest.message,
              signature: signatureRequest.signature,
              contractAddress: contractAddress as string,
              gateId: memberGateId,
              chain: chainId,
            })
            if (unlockGateResponse?.data?.gateKey) {
              const gateKey = unlockGateResponse.data.gateKey
              const wrapped = CryptoJS.AES.decrypt(
                encryptedMemberDecryptionKey,
                gateKey
              ).toString(CryptoJS.enc.Utf8)
              const { memberDecryptionKey } = JSON.parse(wrapped)
              const isVerified =
                await verifyPortalMembersKeysFromPortalContract({
                  keys: {
                    memberDecryptionKey,
                  },
                  contractAddress: contractAddress as string,
                  chainId,
                })
              if (isVerified) {
                const unlockedFileData = await unlockFile(
                  memberDecryptionKey,
                  memberLock,
                  contentIPFSHash,
                  undefined,
                  FileTypeEnum.MEMBERS_PRIVATE
                )
                if (unlockedFileData) {
                  resolve({
                    fileUrl: unlockedFileData.file,
                    chatKey: unlockedFileData.chatKey,
                    decryptionKey: memberDecryptionKey,
                  })
                }
              } else {
                throw new Error(
                  "Member key doesn't march with this portal member key"
                )
              }
            }
            memberKeyNode.off()
          }
        }
      })
    }),
    new Promise((resolve) =>
      setTimeout(() => {
        memberKeyNode.off()
        resolve(undefined)
      }, 15000)
    ),
  ])
  return fileAccess as IFileAccess | undefined
}
export const verifyAccountAssetAndUnlockFile = async (
  contractAddress: string,
  account: string,
  signMessageAsync: (args?: any) => Promise<`0x${string}`>,
  chainId: number,
  tokenLock: DataLockedKeyMaterial | null,
  contentIPFSHash: string,
  gateIPFSHash: string
): Promise<(IFileAccess & { ipfsUrl: string }) | undefined> => {
  const signatureRequest = await requestSignature(
    account,
    TOKEN_FILE_MESSAGE,
    signMessageAsync
  )
  const gate = await getIPFSAsset({ ipfsHash: gateIPFSHash })
  const { gateId } = gate!.data
  const unlockGateResponse = await unlockGateAPI({
    invokerAddress: account,
    message: signatureRequest.message,
    signature: signatureRequest.signature,
    contractAddress,
    gateId,
    chain: chainId,
  })

  if (unlockGateResponse?.data?.gateKey) {
    const gateKey = unlockGateResponse.data.gateKey
    const unlockedFileData = await unlockFile(
      gateKey,
      tokenLock,
      contentIPFSHash,
      undefined,
      FileTypeEnum.GATED
    )
    if (unlockedFileData) {
      return {
        fileUrl: unlockedFileData.file,
        chatKey: unlockedFileData.chatKey,
        ipfsUrl: unlockedFileData.ipfsUrl,
      }
    }
  }
  return
}
