/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { BaseSyntheticEvent, useState } from 'react'
import { FileTypeEnum } from '../../../../../types/enum/file.enum'

import {
  AnyOneWithLink,
  CollaboratorsOnly,
  // MembersOnly,
  PrivateFileType,
  TokenGated,
} from '../common'

import { SelectPrivateFileTypeStep } from './SelectPrivateFileTypeStep'
import { TokenGatedStep } from './TokenGatedStep'

export const PrivateSettingStep = ({
  onSwitchToVisibility,
  handleUploadFileEvent,
  isLoading,
}: {
  onSwitchToVisibility: (e: BaseSyntheticEvent) => void
  handleUploadFileEvent: (fileType: FileTypeEnum, extraArgs?: any) => void
  isLoading: boolean
}) => {
  const [showTokenGatedStep, setShowTokenGatedStep] = useState(false)

  const onContinueClick = (selectedPrivateFileType: PrivateFileType) => {
    switch (selectedPrivateFileType) {
      case CollaboratorsOnly:
        return handleUploadFileEvent(FileTypeEnum.PRIVATE)
      // case MembersOnly:
      //   return handleUploadFileEvent(FileTypeEnum.MEMBERS_PRIVATE)
      case TokenGated:
        return setShowTokenGatedStep(true)
      case AnyOneWithLink:
        break
      default:
        console.warn('Invalid private file type')
        break
    }
  }

  const onBackClick = () => {
    setShowTokenGatedStep(false)
  }

  if (showTokenGatedStep)
    return (
      <TokenGatedStep
        onBackClick={onBackClick}
        handleUploadFileEvent={handleUploadFileEvent}
      />
    )
  return (
    <SelectPrivateFileTypeStep
      onSwitchToVisibility={onSwitchToVisibility}
      onContinueClick={onContinueClick}
      isLoading={isLoading}
    />
  )
}
