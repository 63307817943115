/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import { deleteFileTrxCall } from '../../../../../utils/transaction'
import { useParams } from 'react-router-dom'
import { usePortalSignlessMode } from '../../../../../store/contract/hooks'
import { INewFile } from '../../../../../types/interface/file.interface'
import { FileSource, FileTypeEnum } from '../../../../../types/enum/file.enum'
import { unlockFile } from '../../../../../utils/files/decryption/utils'
import { useServerKeys } from '../../../../../store/invoker/hooks'
import { isEmpty } from 'lodash'
import { getIPFSAsset } from '../../../../../utils/getIPFSAsset'
import { useAppDispatch } from '../../../../../store/hooks'
import { removeDraft } from '../../../../../utils/collaboration/utils'
import { useGunNodes } from '../../../../../hooks/useGunNode'
import { deleteFile } from '../../../../../store/files/reducer'
import sendNotifcation from '../../../../../utils/notification'

import { usePrivyHelper } from '../../../../../hooks/usePrivyHelper'
import { DynamicModal } from '../../../../PublicPortal/components/DynamicModal'
import { CircleHelp } from 'lucide-react'
export const DeleteFileModal = ({
  isOpen,
  file,
  onClose,
}: {
  isOpen: boolean
  file: INewFile
  onClose: () => void
}) => {
  const [isDeleting, setDeleting] = useState(false)
  const walletAddress = usePrivyHelper().walletAddress
  const contractAddress = useParams().address as string
  const isSignless = usePortalSignlessMode(contractAddress as string)
  const pluginFiles = [FileSource.COLLAB_DOCUMENT, FileSource.DPAGE]
  const serverKeys = useServerKeys(
    walletAddress as string,
    contractAddress as string
  )
  const { getAuthenticatedPluginMetadataNode } = useGunNodes()

  const dispatch = useAppDispatch()

  const fetchFileContent = async (file: INewFile) => {
    let fileContent: any
    if (file?.metadata.fileType !== FileTypeEnum.PUBLIC) {
      const unlockedFileData = await unlockFile(
        serverKeys.portalDecryptionKey,
        file.metadata.portalLock,
        file.contentIPFSHash
      )
      if (isEmpty(unlockedFileData)) return null

      const res = await (await fetch(unlockedFileData?.file)).text()
      fileContent = JSON.parse(res)
    } else {
      const res = await getIPFSAsset({
        ipfsHash: file?.contentIPFSHash as string,
      })
      fileContent = res?.data
    }
    return fileContent
  }
  const removeFileDraft = async (fileContent: any) => {
    const rtcId = fileContent?.rtcId
    if (!rtcId) return

    await removeDraft(
      getAuthenticatedPluginMetadataNode(rtcId as string),
      rtcId as string
    )
  }

  const handleFileEditedEvent = async (fileId: number) => {
    try {
      if (!file) return

      if (pluginFiles.includes(file?.metadata.source as FileSource)) {
        const fileContent = await fetchFileContent(file)
        if (fileContent) await removeFileDraft(fileContent)
      }
      dispatch(
        deleteFile({
          contractAddress: contractAddress as string,
          fileId,
        })
      )
      sendNotifcation('File deleted', '', 'success')
    } catch (error: any) {
      console.log(error)
      sendNotifcation('Failed to delete file', error?.message, 'danger')
    } finally {
      onClose()
      setDeleting(false)
    }
  }

  const handleDeleteFile = async () => {
    try {
      setDeleting(true)

      const eventFileId = await deleteFileTrxCall(
        {
          contractAddress,
          fileId: file.fileId,
          walletAddress: walletAddress as string,
        },
        isSignless
      )
      handleFileEditedEvent(eventFileId)

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setDeleting(false)
      onClose()
      sendNotifcation('Signing failed', '', 'danger')
    }
  }
  return (
    <DynamicModal
      className="max-w-[600px]"
      open={isOpen}
      onOpenChange={onClose}
      disableOutsideClick={isDeleting}
      title="Delete confirmation"
      content={
        <div className="flex gap-2 items-start w-full h-full">
          <span className="w-6">
            <CircleHelp size={24} className="color-text-secondary" />
          </span>
          <span className="text-body-sm">
            Are you sure you want to delete this file?
          </span>
        </div>
      }
      primaryAction={{
        label: 'Delete',
        onClick: handleDeleteFile,
        variant: 'danger',
        isLoading: isDeleting,
        className: 'w-full md:w-auto',
      }}
      secondaryAction={{
        label: 'Cancel',
        onClick: onClose,
        className: 'w-full md:w-auto',
      }}
    />
  )
}
