/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import { HashedKeyViewer } from './HashedKeyViewer'
import { Tooltip } from '@mui/material'

export default function HashedJSONViewer({ file }: { file: any }) {
  const sol = JSON.parse(JSON.stringify(file)) //to support both JS Objects and JSON files
  const [isMasked, setIsMasked] = useState<boolean>(true)
  const toggleMask = () => setIsMasked(!isMasked)
  return (
    <div
      data-cy="json-preview"
      className="w-full h-full max-h-[50vh] overflow-y-scroll overflow-x-hidden no-scrollbar"
    >
      {file && (
        <Tooltip title="Click to reveal">
          <div onClick={toggleMask} className="w-full flex flex-col">
            {Object.entries(sol).map(([key, value]) => (
              <HashedKeyViewer
                keyName={key}
                value={value as string}
                key={key}
                isMasked={isMasked}
              />
            ))}
          </div>
        </Tooltip>
      )}
    </div>
  )
}
