/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  BaseSyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import styles from './index.module.scss'
import { ButtonType, OptionsArr, TabType, TabsArr } from '../common'
import { TextInput, SelectInput } from '../../../../Input'
import cn from 'classnames'
import { useParams } from 'react-router-dom'
import { useContractChainId } from '../../../../../store/contract/hooks'
import { useInvokerContractCredentials } from '../../../../../store/invoker/hooks'
import tokensAPI from '../../../../../api/tokenApi/tokenApi'
import nftAPI from '../../../../../api/tokenApi/nftApi'
import { getImgSrcForIPFS } from '../../../../../utils/string'
import { Image } from '../../../../Image'
import Spinner from '../../../../Spinner'
import { usePrivyHelper } from '../../../../../hooks/usePrivyHelper'
import { Button } from '../../../../../pages/PublicPortal/components/Button'
import { ButtonGroup } from '@fileverse/ui'
import { isAddress } from 'ethers'
import sendNotifcation, {
  clearAllNotification,
} from '../../../../../utils/notification'
import { isValidUrl } from '../../../../../pages/PublicPortal/utils/isValidUrl'
import { validateOpenSeaUrl } from '../../../../../utils/validateOpenSeaUrl'

interface ITokenAndNFTsProps {
  selectedChain: string
  selectedTab: TabType
  searchValue: string
  onTokenSelect: (token: any) => void
}

export const TokenSelectionStep = ({
  onTokenSelect,
  selectedToken,
  onButtonClick,
}: {
  onTokenSelect: (token: any) => void
  selectedToken: any
  onButtonClick: (e: BaseSyntheticEvent) => void
}) => {
  const [activeTab, setActiveTab] = useState<TabType>(TabType.All)
  const [selectedChain, setSelectedChain] = useState<string>('Ethereum')
  const [searchValue, setSearchValue] = useState<string>('')
  const onTabChange = (value: TabType) => {
    setActiveTab(value)
  }
  const onChange = (value: string) => {
    setSelectedChain(value)
  }

  return (
    <div className={cn(styles.visiblity_step_container, 'gap-[12px]')}>
      <div className="flex gap-[8px] pt-[20px] text-body-sm">
        <TextInput
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder="Name, address, OpenSea URL"
        />
        <SelectInput
          options={OptionsArr}
          onChange={onChange}
          defaultValue={'Ethereum'}
        />
      </div>
      <Tabs
        tabItems={TabsArr}
        activeTab={activeTab}
        onTabChange={onTabChange}
      />
      <TokenAndNFTs
        onTokenSelect={onTokenSelect}
        selectedTab={activeTab}
        selectedChain={selectedChain}
        searchValue={searchValue}
      />
      <ButtonGroup>
        <Button
          onClick={onButtonClick}
          id={ButtonType.Back}
          variant="secondary"
          className="w-[145px] h-[45px]"
        >
          Back
        </Button>
        <Button
          disabled={!selectedToken}
          onClick={onButtonClick}
          id={ButtonType.Continue}
          className="w-[145px] h-[45px]"
        >
          Continue
        </Button>
      </ButtonGroup>
    </div>
  )
}

const TokenAndNFTs = (props: ITokenAndNFTsProps) => {
  const { selectedChain, selectedTab, onTokenSelect, searchValue } = props

  const [userTokens, setUserTokens] = useState<any[]>([])
  const [userNfts, setUserNfts] = useState<any[]>([])
  const [isLoading, setLoading] = useState(false)

  const walletAddress = usePrivyHelper().walletAddress
  const { address: contractAddress } = useParams()

  const contractChainId = useContractChainId(contractAddress as string)
  const credential = useInvokerContractCredentials(
    walletAddress as string,
    contractAddress as string
  )

  const validateSearchTerm = (searchValue: string) => {
    try {
      const { tokenAddress, tokenId } = validateOpenSeaUrl(searchValue)
      return { tokenAddress, tokenId }
    } catch (error: any) {
      clearAllNotification()
      sendNotifcation('Failed to fetch', error?.message, 'danger')
    }
  }

  const fetchTokensAndNFTs = useCallback(async () => {
    if (!walletAddress) return
    try {
      setUserNfts([])
      setUserTokens([])

      let searchTerm, nftTokenAddress, nftTokenId, inputTokenAddress
      setLoading(true)

      if (isValidUrl(searchValue)) {
        const resposne = validateSearchTerm(searchValue)
        nftTokenAddress = resposne?.tokenAddress
        nftTokenId = resposne?.tokenId
      }

      if (isAddress(searchValue)) {
        inputTokenAddress = searchValue
      } else {
        searchTerm = searchValue
      }

      const [tokenResponse, nftResponse] = await Promise.all([
        tokensAPI({
          network: selectedChain.toLocaleLowerCase(),
          credentialEditSecret: credential?.editSecret as string,
          contractAddress: contractAddress as string,
          invokerAddress: walletAddress,
          searchTerm: searchTerm,
          chain: contractChainId as number,
          tokenAddress: inputTokenAddress,
        }),
        nftAPI({
          network: selectedChain.toLocaleLowerCase(),
          credentialEditSecret: credential?.editSecret as string,
          contractAddress: contractAddress as string,
          invokerAddress: walletAddress,
          searchTerm: searchTerm,
          chain: contractChainId as number,
          tokenDetails: {
            address: nftTokenAddress || '',
            id: nftTokenId || '',
          },
        }),
      ])
      setUserTokens(tokenResponse)
      setUserNfts(nftResponse)
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }, [
    contractAddress,
    contractChainId,
    credential?.editSecret,
    selectedChain,
    walletAddress,
    searchValue,
  ])

  useEffect(() => {
    fetchTokensAndNFTs()
  }, [fetchTokensAndNFTs])

  const allItems = useMemo(() => {
    if (selectedTab === TabType.Tokens) return userTokens
    if (selectedTab === TabType.NFTs) return userNfts
    return [...userTokens, ...userNfts]
  }, [selectedTab, userNfts, userTokens])

  const cardItems = allItems.map((item, key) => {
    const { image = '', name = 'unknown' } = item
    const imgSrc = getImgSrcForIPFS(image)
    return (
      <div
        onClick={() => onTokenSelect(item)}
        key={key}
        className={cn(styles.token_card)}
      >
        <div className={styles.token_image_container}>
          <Image src={imgSrc} />
        </div>
        <div className={styles.token_name_container}>{name}</div>
      </div>
    )
  })

  if (isLoading)
    return (
      <div className="m-auto">
        <Spinner />
      </div>
    )

  if (allItems.length === 0)
    return (
      <div className="m-auto text-body-sm color-text-default">
        No tokens found
      </div>
    )
  return <div className={styles.tokens_nfts_container}>{cardItems}</div>
}

interface ITabsProps {
  tabItems: TabType[]
  activeTab?: TabType
  onTabChange: (value: TabType) => void
}

const Tabs = (props: ITabsProps) => {
  const { tabItems, activeTab, onTabChange } = props

  const items = tabItems.map((item) => {
    return (
      <TabItem
        key={item}
        label={item}
        isActive={item == activeTab}
        onTabChange={onTabChange}
      />
    )
  })

  return <div className={styles.tab_container}>{items}</div>
}

interface ITabItemProps {
  label: TabType
  isActive: boolean
  onTabChange: (value: TabType) => void
}

const TabItem = ({ label, isActive, onTabChange }: ITabItemProps) => {
  return (
    <div
      onClick={() => onTabChange(label)}
      className={cn(styles.tab_item, { [styles.is_active]: isActive })}
    >
      {label}
    </div>
  )
}
