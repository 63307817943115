import React from 'react'
import { useParams } from 'react-router-dom'
import { useFileSearch } from '../../../../../store/files/hooks'
import { useFileSearchValue } from '../../../../../store/fileSearch/hooks'
import { FileItem, EmptyState, SortOptions } from '../common'

const NO_FILE_FOUND = 'No files found.'

export const FileSearchResult = () => {
  const searchValue = useFileSearchValue()
  const contractAddress = useParams().address as string
  const result = useFileSearch(contractAddress, searchValue)
  const resultItems = result.map((file) => (
    <FileItem file={file} sortedBy={SortOptions.Created} key={file.fileId} />
  ))
  return (
    <div className="w-full h-full flex flex-col gap-[16px]">
      <div className="flex-col overflow-y-auto scrollbar-none min-h-[50vh]">
        {resultItems.length ? resultItems : <EmptyState text={NO_FILE_FOUND} />}
      </div>
    </div>
  )
}
