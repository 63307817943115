/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { useParams } from 'react-router-dom'
import {
  useFilterFiles,
  useFilterForOnlyMembers,
} from '../../store/files/hooks'
import logoPlaceholder from '../../assets/folderIcons/logoPlaceholder.svg'
import disabledPlaceholder from '../../assets/folderIcons/disabledPlaceholder.svg'
import { LucideIcon } from '@fileverse/ui'
import cn from 'classnames'

export default function PublicFolderCard({
  title,
  icon,
  width,
  view,
  backgroundColor,
}: {
  title: string
  icon: string
  width?: string
  view?: string
  backgroundColor?: string
}) {
  const { address: contractAddress } = useParams()
  const filteredFiles =
    view === 'Collaborator'
      ? useFilterFiles(contractAddress as string, title)
      : useFilterForOnlyMembers(contractAddress as string, title)
  return (
    <div
      data-cy="folder"
      className={cn(
        'relative aspect-[3/2.2] rounded-lg p-3 space-y-3 cursor-pointer hover:bg-[#E8EBEC] transition-colors duration-200 ease-in-out',
        width || 'w-56',
        backgroundColor || 'color-bg-secondary'
      )}
    >
      <div className="flex h-[15%] items-center">
        <div className="flex w-[65%] gap-1 items-center">
          <div className="rounded color-bg-brand p-1">
            <LucideIcon name={icon as any} size="sm" fill="transparent" />
          </div>
          <h6 className="color-text-default text-heading-xsm">
            {title} <span>({filteredFiles.length})</span>
          </h6>
        </div>

        <div className="bg-white w-[30%] rounded-t-lg absolute h-[15%] right-3 top-6">
          <div className="w-8 h-8 -translate-x-[0.15rem] -translate-y-[0.85rem] bg-white transform -rotate-[60deg] origin-bottom-right" />
        </div>
      </div>
      <div className="bg-white h-[85%] rounded-tr-none rounded-lg flex flex-col items-center justify-center relative">
        {logoPlaceholder && disabledPlaceholder && (
          <>
            <img
              src={
                filteredFiles.length > 0 ? logoPlaceholder : disabledPlaceholder
              }
              alt="folder-icon"
              className="w-[75px]"
            />
            <LucideIcon
              name={icon as any}
              size="md"
              fill={filteredFiles.length > 0 ? 'transparent' : '#F2F4F5'}
              stroke={filteredFiles.length > 0 ? 'black' : '#A1AAB1'}
              className="absolute top-[58%] left-[42%] -translate-x-1/2 -translate-y-1/2"
            />{' '}
          </>
        )}
      </div>
    </div>
  )
}
