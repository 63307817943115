/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState } from 'react'

import {
  cn,
  Icon,
  Card,
  CardHeader,
  CardDescription,
  CardTitle,
  CardContent,
  CardFooter,
  // TextAreaField,
  ButtonGroup,
  Button,
  formatCounter,
} from '@fileverse/ui'
import { CardItemProps } from '../PortalCardItem'
import { getDomainName } from '../../utils/getDomainName'
import { motion } from 'framer-motion'
import { animationConfig } from '../CardControlWrapper'

export const LinkCard = ({
  size,
  likeCount,
  classNames,
  canShare = true,
  title,
  url,
  source,
  imageUrl,
  faviconUrl,
  noFooter,
  onLike,
}: CardItemProps & {
  noFooter?: boolean
  onLike?: (e: any) => void
}) => {
  const [linkMetadata, setLinkMetadata] = useState({
    faviconUrl: faviconUrl,
    imageUrl: imageUrl,
  })
  return (
    <Card size={size} className={cn('', classNames)}>
      <motion.div
        className="w-full h-full transition-opacity absolute top-0 left-0 p-5"
        layout="position"
        {...animationConfig}
      >
        <div
          className={cn(
            'flex justify-between h-[calc(100%-1rem)] pb-2',
            size !== 'md' ? 'flex-col' : 'flex-row',
            size !== 'sm' && 'gap-2'
          )}
        >
          <CardHeader
            className={cn({
              'flex-[0.8]': size === 'md',
            })}
          >
            <div className="flex flex-row justify-between">
              {linkMetadata.faviconUrl ? (
                <img
                  src={linkMetadata.faviconUrl}
                  alt={source}
                  className="w-6 h-6 object-cover"
                  onError={() =>
                    setLinkMetadata({ ...linkMetadata, faviconUrl: '' })
                  }
                />
              ) : (
                <Icon name="Placeholder" size="sm" fill="black" />
              )}

              {size === 'sm' ? (
                <CardDescription>{getDomainName(url!)}</CardDescription>
              ) : null}
            </div>
            <CardTitle
              className="line-clamp-5 text-heading-xsm-bold"
              title={title}
            >
              {/* TODO: Coming back to this when implementing editable title */}
              {/* <TextAreaField
              readOnly
              value={title}
              className="bg-transparent outline-none border-none resize-none no-scrollbar w-full h-full focus:outline-none focus:ring-0 focus:shadow-none shadow-none p-0 min-h-0"
            /> */}
              {title}
            </CardTitle>
          </CardHeader>
          <motion.div
            layout="position"
            className={cn(
              'transition-opacity',
              size !== 'lg'
                ? 'max-w-[60%] min-w-[60%]'
                : 'w-full h-full xl:h-[80%]'
            )}
          >
            <CardContent
              className={cn('rounded-sm color-bg-tertiary p-0 h-full')}
            >
              {size !== 'sm' ? (
                <div className="relative h-full">
                  {linkMetadata.imageUrl ? (
                    <img
                      src={linkMetadata.imageUrl}
                      alt="Placeholder"
                      className="object-cover aspect-video w-full h-full rounded-sm"
                      loading="lazy"
                      onError={() =>
                        setLinkMetadata({ ...linkMetadata, imageUrl: '' })
                      }
                    />
                  ) : (
                    <CardDescription className="text-center flex flex-col justify-center items-center h-full">
                      Seems like there’s no <br /> preview image.
                    </CardDescription>
                  )}
                </div>
              ) : null}
            </CardContent>
          </motion.div>
        </div>
        {!noFooter && (
          <CardFooter className="justify-between h-4">
            <motion.div
              layout="position"
              className="flex justify-between items-center w-full transition-opacity"
              {...animationConfig}
            >
              <ButtonGroup className="space-x-0 gap-3">
                <Button
                  onClick={onLike}
                  variant="ghost"
                  size="md"
                  className="min-w-fit p-0"
                >
                  <Icon name="Heart" size="sm" fill="black" className="mr-1" />
                  <span className="max-w-fit">
                    {likeCount && likeCount >= 1
                      ? formatCounter(likeCount)
                      : 'Like'}
                  </span>
                </Button>
                {canShare && (
                  <Button
                    onClick={async (e) => {
                      e.stopPropagation()
                      if (!window.navigator.share) return
                      await window.navigator.share({
                        url,
                        title,
                      })
                    }}
                    variant="ghost"
                    size="md"
                    className="min-w-fit p-0 inline-flex lg:hidden"
                  >
                    <Icon
                      name="Share2"
                      size="sm"
                      fill="black"
                      className="mr-1"
                    />
                    Share
                  </Button>
                )}
              </ButtonGroup>
              {size !== 'sm' ? (
                <CardDescription className="text-muted-foreground">
                  {getDomainName(url!)}
                </CardDescription>
              ) : null}
            </motion.div>
          </CardFooter>
        )}
      </motion.div>
    </Card>
  )
}
