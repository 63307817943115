import { generateToken } from './../utils'
import { ICredential } from './../../types/interface/invoker.interface'
import axios from 'axios'

interface GetMessagesRequest {
  contractAddress: string
  credential: ICredential
  invokerAddress: string
  chain: number
}

interface CreateMessageRequest {
  contractAddress: string
  credential: ICredential
  invokerAddress: string
  topic: string
  content: string
  forAddress: string
  chain: number
}

interface ProcessMessageRequest {
  contractAddress: string
  credential: ICredential
  invokerAddress: string
  messageId: string
  chain: number
}

const BASE_URL = `${process.env.REACT_APP_COORDINATOR_URL}`

export const createMessageAPI = async (request: CreateMessageRequest) => {
  const { topic, content, forAddress } = request

  const token = await generateToken(
    request.contractAddress,
    request.credential.editSecret || ''
  )

  return axios.post(
    `${BASE_URL}/message/create`,
    {
      topic,
      content,
      forAddress,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        contract: request.contractAddress,
        invoker: request.invokerAddress,
        chain: request.chain,
      },
    }
  )
}

export const getMessagesAPI = async (request: GetMessagesRequest) => {
  const token = await generateToken(
    request.contractAddress,
    request.credential.editSecret || ''
  )

  return axios.post(
    `${BASE_URL}/message/get`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        contract: request.contractAddress,
        invoker: request.invokerAddress,
        chain: request.chain,
      },
    }
  )
}

export const processMessageAPI = async (request: ProcessMessageRequest) => {
  const token = await generateToken(
    request.contractAddress,
    request.credential.editSecret || ''
  )

  return axios.post(
    `${BASE_URL}/message/${request.messageId}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        contract: request.contractAddress,
        invoker: request.invokerAddress,
        chain: request.chain,
      },
    }
  )
}
