/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useContract } from '../../store/contract/hooks'
import { checkIfOwnerOrCollaborator } from '../../utils/checkIfOwnerOrCollaborator'
import * as Y from 'yjs'
import useVisibility from '../../hooks/useVisibility'
import { ISEAPair } from 'gun'
import { IDraft } from '../../types/interface/drafts.interface'
import { AnyExtension, useEditor } from '@tiptap/react'
import Collaboration from '@tiptap/extension-collaboration'
import { TiptapEditorProps } from '../../components/TipTap/props'
import { WebrtcProvider } from 'y-webrtc'
import CollaborationCursor from '@tiptap/extension-collaboration-cursor'
import TiptapToolBar from '../../components/TipTap/TiptapToolBar'
import TiptapDocTitlePopup from '../../components/TipTap/TiptapDocTitlePopup'
import LiveCollaborationPopup from '../../components/TipTap/LiveCollaborationPopup'
import { useLocalStorage } from 'usehooks-ts'
import useEditorHook, { postDocContent } from './useEditorHook'
import DefaultEditor from './DefaultEditor'
import GetStartedPopup from './GetStartedPopup'
import { TaskID } from '../Tasks/TasksProvider'
import { useTasksContext } from '../../hooks/useTasksContext'
import { DocStatus } from '../ExcalidrawCanvas/SavingStatusUI'
import { getISEAKeyPair } from '../../utils/libCrypto'
import { defaultExtensions } from './extensions/defaultExtenstion'
import { WebPageMoreMenu } from '../WebPages/WebPageMoreMenu'
import PluginNavbar from '../Navbars/PluginNavbar'
import { useServerKeys } from '../../store/invoker/hooks'
import { updatePluginMetadata } from '../../utils/collaboration/utils'
import { getPluginMetadataNode, useGunNodes } from '../../hooks/useGunNode'
import { SESSION_HOST_FLAG } from '../../utils/constants'
import sendNotifcation from '../../utils/notification'
import { captureMessage } from '@sentry/react'
import { usePrivyHelper } from '../../hooks/usePrivyHelper'
import { AnimatedLoader } from '../../pages/PublicPortal/components/Loader'
const OnlineEditor = ({
  setFiles,
  setCollabDocPreview,
  authKey,
  rtcData,
  disconnectUser,
}: {
  setFiles: React.Dispatch<File[]>
  authKey: ISEAPair
  rtcData: IDraft
  setCollabDocPreview: React.Dispatch<React.SetStateAction<string | undefined>>
  disconnectUser: (docTitle: string) => void
}) => {
  const walletAddress = usePrivyHelper().walletAddress
  const invoker = walletAddress as string
  const [username] = useLocalStorage('username', '')
  const userId = username || walletAddress
  const { address: contractAddress, rtcId } = useParams()
  const contract = useContract(contractAddress as string)
  const serverKeys = useServerKeys(
    walletAddress as string,
    contractAddress as string
  )
  const isCollaborator = checkIfOwnerOrCollaborator(
    contract,
    invoker,
    serverKeys
  )
  const isUserDocOwner = !!(
    authKey &&
    rtcData?.owner &&
    rtcData?.owner === walletAddress
  )
  const [ydoc] = useState(new Y.Doc())
  const taskContext = useTasksContext()
  const [urlParams] = useSearchParams()
  const chainId = parseInt(urlParams.get('chainId') || '')
  const documentKey = getISEAKeyPair(urlParams.get('key') as string)
  const { getAuthenticatedPluginMetadataNode } = useGunNodes()
  const [docStatus, setDocStatus] = useState<DocStatus>(DocStatus.SAVED)
  const provider = useMemo(() => {
    return new WebrtcProvider(rtcId!, ydoc, {
      signaling: [process.env.REACT_APP_SIGNALING_SERVER as string],
    })
  }, [])

  const [confirmDeleteModal, setConfirmDeleteModal] = useState<boolean>(false)
  const [previewMode, setPreviewMode] = useState<boolean>(false)
  const [moreMenu, setMoreMenu] = useState<boolean>(false)
  const [commentsEnabled, setCommentsEnabled] = useState<boolean>(true)
  const [indexEnabled, setIndexEnabled] = useState<boolean>(true)
  const portalName = contract?.metadata?.name
  const dropdownRef = useRef<HTMLDivElement>(null)
  const SESSION_DISCONNECTION_MESSAGE = 'The host just ended the session'

  const usercolors = [
    '#30bced',
    '#6eeb83',
    '#fa69d1',
    '#ecd444',
    '#ee6352',
    '#db3041',
    '#0ad7f2',
    '#1bff39',
  ]

  const editor = useEditor(
    {
      extensions: [
        ...(defaultExtensions as AnyExtension[]),
        Collaboration.configure({
          document: ydoc,
        }),
        CollaborationCursor.configure({
          provider,
          user: {
            name: userId,
            color: usercolors[Math.floor(Math.random() * usercolors.length)],
          },
        }),
      ],
      editorProps: TiptapEditorProps,
      autofocus: 'start',
      onUpdate: () => {
        postDocContent({
          contractAddress: contractAddress as string,
          documentKey: documentKey as ISEAPair,
          rtcId: rtcId as string,
          setDocStatus: (status) => setDocStatus(status),
          ydoc,
        })
      },
    },
    []
  )

  const {
    ref: titlePopUpDiv,
    isComponentVisible: titlePopUp,
    setIsComponentVisible: setTitlePopUp,
  } = useVisibility(false)
  const {
    ref: liveCollabPopRef,
    isComponentVisible: isLiveCollab,
    setIsComponentVisible: setIsLiveCollab,
  } = useVisibility(isCollaborator)
  const {
    publishDocument,
    isLoading,
    draftName,
    setDraftName,
    handleBackButtonClick,
  } = useEditorHook({
    setDocTitlePopUp: setTitlePopUp,
    docTitle: rtcData?.name,
    ydoc,
    setCollabDocPreview,
    editor,
    setFiles,
    rtcData,
  })

  const { isTasksLoading = true } = taskContext || {}

  useEffect(() => {
    // wait for tasks get api to complete before running this
    if (isTasksLoading || !isCollaborator) return
    taskContext?.updateTask(
      TaskID.TRY_LIVE_COLLABORATION,
      'Try a Live collaboration'
    )
  }, [isCollaborator, isTasksLoading])

  useEffect(() => {
    return () => {
      provider.disconnect()
      provider.destroy()
      ydoc.destroy()
    }
  }, [])

  useEffect(() => {
    if (draftName) document.title = `${draftName} | Fileverse`

    return () => {
      document.title = 'Fileverse'
    }
  }, [draftName])

  const getDraftMetadata = async () => {
    if (!authKey) return
    const portalDraftMetaDataNode = getPluginMetadataNode(
      authKey,
      contractAddress as string,
      rtcId as string
    )

    const metadata = await Promise.race([
      new Promise((resolve) => {
        portalDraftMetaDataNode.on((data: IDraft) => {
          if (!data)
            captureMessage(
              `Failed to get draft metadata for ${rtcId} and ${contractAddress}`
            )
          resolve(data)
        })
      }),
      new Promise((resolve) => {
        setTimeout(() => {
          captureMessage(
            `Failed to get draft metadata for ${rtcId} and ${contractAddress}`
          )
          resolve(undefined)
        }, 5000)
      }),
    ])
    portalDraftMetaDataNode.off()
    return metadata as IDraft
  }

  const registerAsHostOrListenToSessionHost = async () => {
    const ymap = ydoc.getMap(rtcId)
    const draftMetadata = await getDraftMetadata()

    const collaborationStatus =
      draftMetadata?.collaborationStatus &&
      JSON.parse(draftMetadata?.collaborationStatus)

    // set user as host if the doc doesnt have a host set
    if (!collaborationStatus?.isActive && isCollaborator) {
      ymap.set(SESSION_HOST_FLAG, walletAddress)
      await updatePluginMetadata(
        {
          collaborationStatus: JSON.stringify({
            isActive: true,
            by: walletAddress as string,
          }),
        },
        getAuthenticatedPluginMetadataNode(rtcId as string, authKey)
      )
      return
    }

    if (ymap.get(SESSION_HOST_FLAG) === walletAddress) return
    listenForWhenToDisconnect(isCollaborator)
  }
  const listenForWhenToDisconnect = (isCollaborator = false) => {
    const ymap = ydoc.getMap(rtcId)
    const _listen = () => {
      const observer = () => {
        if (ymap.get(SESSION_HOST_FLAG) === null) {
          disconnectUser(draftName)
          sendNotifcation(SESSION_DISCONNECTION_MESSAGE, '', 'danger')
          ymap.unobserve(observer)
          !isCollaborator &&
            navigate(`/${contractAddress}/member?chainId=${chainId}`, {
              replace: true,
            })
        }
      }
      ymap.observe(observer)
    }
    if (isCollaborator) {
      _listen()
    } else {
      // in this case it is important to wait for user to sync with remote peer first before listening
      provider.on('synced', () => {
        if (ymap.get(SESSION_HOST_FLAG) === null) {
          navigate(`/${contractAddress}/member?chainId=${chainId}`, {
            replace: true,
          })
          sendNotifcation(SESSION_DISCONNECTION_MESSAGE, '', 'danger')
          return
        }
        _listen()
      })
    }
  }
  const navigate = useNavigate()
  useEffect(() => {
    if (!provider.connected) return
    if (!isCollaborator) {
      listenForWhenToDisconnect()
      return
    }
    registerAsHostOrListenToSessionHost()
  }, [provider.connected])

  const handleDisconnection = async () => {
    const ymap = ydoc.getMap(rtcId)
    if (ymap.get(SESSION_HOST_FLAG) === walletAddress) {
      ymap.set(SESSION_HOST_FLAG, null)
      await updatePluginMetadata(
        { collaborationStatus: JSON.stringify({ isActive: false, by: null }) },
        getAuthenticatedPluginMetadataNode(rtcId as string, authKey)
      )
    }
    disconnectUser(draftName)
  }

  if (!editor || isLoading) {
    return (
      <div className="w-full h-[100vh] flex justify-center items-center">
        <AnimatedLoader text="Preparing your dDoc..." />
      </div>
    )
  }

  return (
    <div className="w-full h-full overflow-scroll no-scrollbar">
      <div className="w-full h-full">
        <div className="h-fit relative bg-[#ffffff]">
          {moreMenu && (
            <WebPageMoreMenu
              confirmDeleteModal={confirmDeleteModal}
              setConfirmDeleteModal={setConfirmDeleteModal}
              indexEnabled={indexEnabled}
              commentsEnabled={commentsEnabled}
              setCommentsEnabled={setCommentsEnabled}
              setIndexEnabled={setIndexEnabled}
              isDPage={false}
              fileDownloadName={draftName}
              filePreviewPage={false}
              moreMenuRef={dropdownRef}
              isOwner={isCollaborator}
              rtcId={rtcId}
            />
          )}
          <PluginNavbar
            isTitleFieldEnabled={!previewMode}
            dropdownRef={dropdownRef}
            docStatus={docStatus}
            onLiveCollaborationTrigger={() => {
              setIsLiveCollab(true)
            }}
            pluginTool={<TiptapToolBar editor={editor} />}
            portalName={portalName}
            pluginTitle={draftName}
            isPreviewMode={previewMode}
            setPreviewMode={setPreviewMode}
            backButtonAction={() => {
              handleDisconnection()
              handleBackButtonClick()
            }}
            isUserACollaborator={isCollaborator}
            isEditMode={false}
            setEditMode={() => null}
            setIsEditCancelFlag={() => null}
            isPublishLoading={false} // fix the name to be isLoading
            isCommentsEnabled={false}
            toggleCommentVisibility={() => null}
            toggleQrModalVisibility={() => null}
            isCommentsVisible={false}
            toggleMoreMenuVisibility={() => setMoreMenu(!moreMenu)}
            toggleProvenaceModalVisibility={() => null}
            commentCount={0}
            publishPlugin={publishDocument}
            isPublished={false}
            setPluginTitle={setDraftName}
            isCollaborating={true}
            collaborators={editor?.storage.collaborationCursor.users}
          />
        </div>
        <DefaultEditor editor={editor} previewMode={previewMode} />

        <TiptapDocTitlePopup
          titlePopUp={titlePopUp}
          titlePopUpDiv={titlePopUpDiv}
          draftName={draftName}
          setDraftName={setDraftName}
          setTitlePopUp={setTitlePopUp}
          publishDocument={() => publishDocument()}
        />
        <LiveCollaborationPopup
          disconnect={handleDisconnection}
          collaborators={editor?.storage.collaborationCursor.users}
          popupRef={liveCollabPopRef}
          isOpen={isLiveCollab}
          isDocOwner={isUserDocOwner}
          closePopup={() => setIsLiveCollab(false)}
          isConnected={provider.connected}
          connect={() => null}
          docType="dDoc"
        />
        <GetStartedPopup />
      </div>
    </div>
  )
}

export default OnlineEditor
