import axios from 'axios'
import { generateToken } from '../utils'

const markAllNotificationAsRead = async ({
  contractAddress,
  editSecret,
  invoker,
  chain,
}: {
  contractAddress: string
  editSecret?: string
  invoker: string
  chain: number
  portalAddress?: string
}) => {
  if (!editSecret) return
  const token = await generateToken(contractAddress, editSecret || '')
  const URL = `${process.env.REACT_APP_COORDINATOR_URL}/notification/mark-read`
  return axios.post(
    URL,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        invoker,
        contract: contractAddress,
        chain,
      },
    }
  )
}
export default markAllNotificationAsRead
