import React, { useEffect, useRef, useState } from 'react'
import styles from './index.module.scss'
import { PluginCard, PluginType } from '../PluginCard'
import cn from 'classnames'
import { ChevronLeft, ChevronRight, InfoIcon } from 'lucide-react'
import { Tooltip } from '../../../../PublicPortal/components/Tooltip'
// import { Tooltip } from '@fileverse/ui'
const PluginArr: PluginType[] = [
  'dpage',
  'whiteboard',
  'doc',
  'permastorage',
  'aipowa',
  'mynotes',
  'chat',
  'nft',
]

export const CreateSection = () => {
  const plugins = PluginArr.map((type) => <PluginCard key={type} type={type} />)
  const [scrollX, setscrollX] = useState(0)
  const [scrollEnd, setscrollEnd] = useState(false)
  const containerRef = useRef(null)

  const scrollCheck = (element: HTMLDivElement | null) => {
    if (!element) return
    setscrollX(element.scrollLeft)
    if (
      Math.floor(element.scrollWidth - element.scrollLeft) <=
      element.offsetWidth
    ) {
      setscrollEnd(true)
    } else {
      setscrollEnd(false)
    }
  }
  const sideScroll = (
    element: HTMLDivElement | null,
    distance: number,
    step: number
  ) => {
    if (!element) return
    let scrollAmount = 0
    const slideTimer = setInterval(() => {
      element.scrollLeft += step
      scrollAmount += Math.abs(step)
      if (scrollAmount >= distance) {
        clearInterval(slideTimer)
      }
    })
  }
  const scrollDiv = (direction: string) => {
    const distance = 430
    if (direction === 'forward') {
      sideScroll(containerRef.current, distance, 10)
    } else {
      sideScroll(containerRef.current, distance, -10)
    }
  }

  useEffect(() => {
    scrollCheck(containerRef.current)
  }, [])

  return (
    <div className="flex w-full flex-col gap-[16px]">
      <div className="flex justify-between">
        <div className="font-medium text-lg flex gap-[8px] items-center">
          Create
          <Tooltip text="Use the following plugins to create and live collaborate on decentralized boards, docs, web pages, and more!">
            <InfoIcon size="16px" className="text-[#77818A] mb-[-2px]" />
          </Tooltip>
        </div>
        <div className="flex gap-[4px]">
          <button
            onClick={() => scrollDiv('back')}
            className={cn(
              'w-[30px] p-[8px] flex justify-center items-center hover:color-button-secondary-hover rounded-[4px]',
              scrollX === 0 && 'text-[#A1AAB1]'
            )}
          >
            <ChevronLeft size="14px" />
          </button>
          <button
            onClick={() => scrollDiv('forward')}
            className={cn(
              'w-[30px] p-[8px] flex justify-center items-center hover:color-button-secondary-hover rounded-[4px]',
              scrollEnd && 'text-[#A1AAB1]'
            )}
          >
            <ChevronRight size="14px" />
          </button>
        </div>
      </div>
      <div
        className="w-full no-scrollbar overflow-scroll"
        onScroll={() => scrollCheck(containerRef.current)}
        ref={containerRef}
      >
        <div className={cn(styles.responsive_grid, 'plugins-tour')}>
          {plugins}
        </div>
      </div>
    </div>
  )
}
