/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-key */
import React, { ReactNode, useEffect, useState } from 'react'
import { Box } from '@radix-ui/themes'
import {
  IconButton,
  LucideIcon,
  SegmentedControl,
  CardSize,
} from '@fileverse/ui'
import cn from 'classnames'
import { motion } from 'framer-motion'

export interface CardControlWrapperProps {
  defaultSize: CardSize
  deleteFunction: () => void
  children: (size: CardSize) => ReactNode
  isDragging?: boolean
  onSizeChange?: (size: CardSize) => void
  isAnimated?: boolean
}

const cardSizeText: { [key: number]: CardSize } = {
  0: 'sm',
  1: 'md',
  2: 'lg',
}

export const animationConfig = {
  initial: { scale: 0.8, opacity: 0 },
  animate: { scale: 1, opacity: 1 },
  exit: { scale: 0.8, opacity: 0 },
  transition: {
    type: 'spring',
    damping: 30,
    stiffness: 200,
    duration: 0.1,
  },
}
export const CardControlWrapper = React.forwardRef<
  HTMLDivElement,
  CardControlWrapperProps
>(
  (
    {
      defaultSize,
      deleteFunction,
      children,
      isDragging,
      onSizeChange,
      isAnimated = false,
      ...props
    },
    ref
  ) => {
    const [size, setSize] = useState<CardSize>(defaultSize)
    const [cardSizeIndex, setCardSizeIndex] = useState<number>(
      Object.keys(cardSizeText).findIndex(
        (key: any) => cardSizeText[key] === defaultSize
      )
    )
    const cardOptions = [
      <div>
        <LucideIcon fill="none" name={'Square'} size={'sm'} />
      </div>,
      <div>
        <LucideIcon fill="none" name={'RectangleHorizontal'} />
      </div>,
      <div>
        <LucideIcon fill="none" name={'Square'} size={'md'} />
      </div>,
    ]

    useEffect(() => {
      setSize(cardSizeText[cardSizeIndex])
      onSizeChange?.(cardSizeText[cardSizeIndex])
    }, [cardSizeIndex])

    return (
      <Box
        ref={ref}
        className="w-full h-full relative group"
        gridColumn={{
          initial:
            size === 'sm' ? 'span 2' : size === 'md' ? 'span 4' : 'span 4',
          md: size === 'sm' ? 'span 1' : size === 'md' ? 'span 2' : 'span 3',
        }}
        gridRow={{
          initial:
            size === 'sm' ? 'span 2' : size === 'md' ? 'span 2' : 'span 4',
          md: size === 'sm' ? 'span 2' : size === 'md' ? 'span 2' : 'span 4',
        }}
        style={{
          minWidth: size === 'sm' ? '156px' : size === 'md' ? '328px' : '328px',
          minHeight:
            size === 'sm' ? '212px' : size === 'md' ? '212px' : '440px',
          maxHeight:
            size === 'sm' ? '212px' : size === 'md' ? '212px' : '440px',
        }}
        {...props}
      >
        <motion.div
          {...animationConfig}
          layout={isAnimated}
          className="w-full h-full relative"
        >
          {children(size)}
        </motion.div>
        <motion.div
          {...animationConfig}
          layout="size"
          className={cn(
            'absolute -top-4 -left-4 group-hover:visible group-hover:opacity-100 opacity-0 transition-opacity duration-300',
            isDragging && 'invisible'
          )}
        >
          <IconButton
            onClick={deleteFunction}
            icon="Trash"
            variant="ghost"
            size="md"
            rounded
            elevation={3}
            className="color-icon-inverse"
          />
        </motion.div>
        <motion.div
          {...animationConfig}
          layout="size"
          className={cn(
            'absolute z-10 -bottom-6 left-1/2 transform -translate-x-1/2 group-hover:visible group-hover:opacity-100 opacity-0 transition-opacity duration-300',
            isDragging && 'invisible'
          )}
        >
          <SegmentedControl
            options={cardOptions}
            container={true}
            currentState={cardSizeIndex}
            setCurrentState={setCardSizeIndex}
          />
        </motion.div>
      </Box>
    )
  }
)

CardControlWrapper.displayName = 'CardControlWrapper'
