import { ISharingKey } from './../../store/keySharing/interface'
import { importRSADecryptionKey } from './import'
import { convertStringToTypedArray } from '../string'

interface ServerKeyDecryptionPayload {
  key: string
  iv: string
  encryptedPortalEncryptionKey: string
  encryptedPortalDecryptionKey: string
  encryptedMemberEncryptionKey: string
  encryptedMemberDecryptionKey: string
  encryptedPortalGunKey: string
}

export const decryptWithAESKey = async (
  value: string,
  key: CryptoKey,
  ivString: string
) => {
  const dec = new TextDecoder()
  const iv = convertStringToTypedArray(ivString)
  const decrypted = await window.crypto.subtle.decrypt(
    { name: 'AES-GCM', iv },
    key,
    convertStringToTypedArray(value).buffer
  )
  return dec.decode(decrypted)
}

export const decryptServerKeys = async (
  payload: ServerKeyDecryptionPayload,
  keySharingKeys: ISharingKey
) => {
  const {
    key: encryptedMessageDecryptionKey,
    iv,
    encryptedPortalEncryptionKey,
    encryptedPortalDecryptionKey,
    encryptedMemberEncryptionKey,
    encryptedMemberDecryptionKey,
    encryptedPortalGunKey,
  } = payload
  const keyDecryptionKeyPem = keySharingKeys.privateKey
  // const keyDecryptionKeyPem = localStorage.getItem('keyExchangeDecryptionkey')
  const keyDecryptionKey = await importRSADecryptionKey(
    keyDecryptionKeyPem as string
  )

  const messageDecryptionKeyPem = await window.crypto.subtle.decrypt(
    {
      name: 'RSA-OAEP',
    },
    keyDecryptionKey,
    convertStringToTypedArray(encryptedMessageDecryptionKey).buffer
  )
  const messageDecryptionKey = await window.crypto.subtle.importKey(
    'raw',
    messageDecryptionKeyPem,
    'AES-GCM',
    true,
    ['encrypt', 'decrypt']
  )

  const portalDecryptionKey = await decryptWithAESKey(
    encryptedPortalDecryptionKey,
    messageDecryptionKey,
    iv
  )
  const portalGunKey = await decryptWithAESKey(
    encryptedPortalGunKey,
    messageDecryptionKey,
    iv
  )
  const portalEncryptionKey = await decryptWithAESKey(
    encryptedPortalEncryptionKey as string,
    messageDecryptionKey,
    iv
  )
  const memberEncryptionKey = await decryptWithAESKey(
    encryptedMemberEncryptionKey as string,
    messageDecryptionKey,
    iv
  )
  const memberDecryptionKey = await decryptWithAESKey(
    encryptedMemberDecryptionKey as string,
    messageDecryptionKey,
    iv
  )
  return {
    portalEncryptionKey,
    portalDecryptionKey,
    memberEncryptionKey,
    memberDecryptionKey,
    portalGunKey,
  }
}
