/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'

import { ISEAPair } from 'gun'
import { IDraft } from '../../types/interface/drafts.interface'

import { Tooltip } from '@mui/material'
import LiveEditor from '../../components/TipTap/LiveEditor'
import OfflineEditor from '../../components/TipTap/OfflineEditor'
import { useSearchParams } from 'react-router-dom'
import linkIcon from '../../assets/link.svg'
import CTAButton from '../../components/common/CTAButton'
import TasksProvider from '../../components/Tasks/TasksProvider'

const TipTapEditor = ({
  setFiles,
  setCollabDocPreview,
  authKey,
  rtcData,
}: {
  setFiles: React.Dispatch<File[]>
  authKey: ISEAPair
  rtcData: IDraft
  setCollabDocPreview: React.Dispatch<React.SetStateAction<string | undefined>>
}) => {
  const [urlParams, setUrlParam] = useSearchParams()

  const startLiveCollaboration = (docTitle: string) => {
    urlParams.set('collab', 'true')
    setUrlParam(urlParams, {
      replace: true,
      state: {
        title: docTitle,
      },
    })
  }
  const endCollaboration = (title: string) => {
    urlParams.delete('collab')
    setUrlParam(urlParams, {
      replace: true,
      state: {
        title,
      },
    })
  }
  return (
    <TasksProvider>
      <div className="w-full h-screen">
        {urlParams.get('collab') === 'true' ? (
          <LiveEditor
            setFiles={setFiles}
            setCollabDocPreview={setCollabDocPreview}
            authKey={authKey}
            rtcData={rtcData}
            disconnectUser={endCollaboration}
          />
        ) : (
          <OfflineEditor
            setFiles={setFiles}
            setCollabDocPreview={setCollabDocPreview}
            authKey={authKey as ISEAPair}
            rtcData={rtcData as IDraft}
            connectUser={startLiveCollaboration}
          />
        )}
      </div>
    </TasksProvider>
  )
}

export default TipTapEditor

export const DocOwnerForm = ({
  setUserName,
  username,
  connect,
}: {
  setUserName: (name: string) => void
  username: string
  connect: () => void
}) => {
  return (
    <div className="p-2 w-full items-center overflow-scroll no-scrollbar justify-between flex">
      <input
        onChange={(e) => setUserName(e.target.value)}
        placeholder="Enter your name"
        value={username}
        className=" border w-64 rounded-lg p-2 focus:border-black"
      />
      <CTAButton
        isDisabled={username?.trim() === ''}
        leftIcon={linkIcon}
        backgroundColorClassName={'bg-black text-white border-2 border-black'}
        title={'Copy link'}
        onClick={() => connect()}
      />
    </div>
  )
}
export const DocCollaboratorsList = ({
  collaborators,
}: {
  collaborators: Array<any>
}) => {
  return (
    <div className="p-2 max-h-48 w-full no-scrollbar overflow-scroll">
      {collaborators.length > 0 && (
        <ol className="list-decimal list-inside">
          {collaborators.map((collab, index) => (
            <Tooltip
              key={index}
              placement="top-start"
              title={collab?.name || collab?.username}
            >
              <li className=" w-[150px] truncate text-base text-ellipsis">
                {collab?.name || collab?.username}
              </li>
            </Tooltip>
          ))}
        </ol>
      )}
    </div>
  )
}
