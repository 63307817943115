export interface AccountBlock {
  account: string
  blockTimestamp: string
}

export const getLatestCollaboratorInfo = (accounts: AccountBlock[]) => {
  const accountMap = new Map<string, AccountBlock>()

  accounts.forEach((accountBlock) => {
    const existingAccountBlock = accountMap.get(accountBlock.account)
    if (
      !existingAccountBlock ||
      accountBlock.blockTimestamp > existingAccountBlock.blockTimestamp
    ) {
      accountMap.set(accountBlock.account, accountBlock)
    }
  })

  return Array.from(accountMap.values())
}
