/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  // Button,
  // Checkbox,
  TextField,
  Tag,
} from '@fileverse/ui'
import { File, FileText, Search } from 'lucide-react'
import { FileSource } from '../../../types/enum/file.enum'
import { getFileType } from '../../../utils/fileUtils'
import { INewFile } from '../../../types/interface/file.interface'
import cn from 'classnames'
import { useFilteredFiles } from '../../../store/files/hooks'
import { useParams } from 'react-router-dom'
import { usePublicPortalContext } from '../../../providers/PublicPortalProvider'
import { UniqueIdentifier } from '@dnd-kit/core'
import {
  FileIconMap,
  FileTypeMap,
  SystemFileIconMap,
} from '../../domain/Home/components/common'
import { Button } from './Button'
import { Checkbox } from './Checkbox'
import { DynamicModal } from './DynamicModal'

export interface IPortalModalProps {
  open: boolean
  onOpenChange: (open: boolean) => void
  sectionId?: UniqueIdentifier
}

const FileCategories = [
  { label: 'All files' },
  { label: 'Images' },
  { label: 'Videos' },
  { label: 'dPages' },
  { label: 'dDocs' },
  { label: 'Whiteboards' },
  { label: 'Audios' },
  { label: '3D' },
]

export default function FilesPickerModal({
  open,
  onOpenChange,
}: IPortalModalProps) {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const [selectedFiles, setSelectedFiles] = useState<INewFile[]>([])
  const [checked, setChecked] = useState<boolean>(false)
  const { address: contractAddress } = useParams()
  const { onAddFiles, setShowConfirmModal } = usePublicPortalContext()

  const files = useFilteredFiles(
    contractAddress as string,
    searchTerm,
    FileCategories[activeIndex].label
  )
  const handleCategoryClick = (idx: number) => {
    setActiveIndex(idx)
  }

  const onAddClick = () => {
    onAddFiles(selectedFiles)
    onOpenChange(false)
  }

  const checkAllBoxes = (e: any) => {
    const isChecked = e.target.checked
    if (isChecked) {
      setSelectedFiles(files)
      setChecked(true)
    } else {
      setSelectedFiles([])
      setChecked(false)
    }
  }

  return (
    <DynamicModal
      open={open}
      onOpenChange={onOpenChange}
      title="Add files"
      className="max-w-screen-sm md:max-screen-lg lg:max-w-screen-lg"
      contentClassName="!p-0"
      content={
        <div className="flex w-full h-full">
          {/* Sidebar */}
          <div className="min-w-[240px] h-full color-bg-secondary hidden lg:flex flex-col py-5 rounded-bl-lg">
            <div className="flex flex-col gap-1 px-4">
              {FileCategories.map((item, index) => (
                <Button
                  key={index}
                  type="button"
                  variant="ghost"
                  className={cn(
                    'min-w-fit justify-start rounded-md',
                    activeIndex === index
                      ? 'color-bg-brand hover:bg-[#FFDF0A]'
                      : ''
                  )}
                  onClick={() => handleCategoryClick(index)}
                >
                  {item.label}
                </Button>
              ))}
            </div>
          </div>
          {/* Table */}
          <div className="w-full h-full flex flex-col justify-between pt-5">
            <div className="w-full h-full flex flex-col gap-4 px-6 overflow-hidden">
              <TextField
                leftIcon={<Search size={16} />}
                placeholder="Search files"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="shadow-none border-[1px] h-[40px]"
              />
              <div className="min-h-[440px] max-h-[440px] overflow-scroll no-scrollbar">
                <Table>
                  <TableHeader>
                    <TableRow className="p-0">
                      <TableHead className="p-0  w-10">
                        <Checkbox
                          checked={checked}
                          onCheckedChange={checkAllBoxes}
                        />
                      </TableHead>
                      <TableHead className="p-0">File name</TableHead>
                      <TableHead className="p-0">
                        <div className="flex justify-center w-full lg:justify-start">
                          Access
                        </div>
                      </TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {files.length > 0 ? (
                      files.map((item, index) => (
                        <FileItem
                          key={index}
                          file={item}
                          files={files}
                          selectedFiles={selectedFiles}
                          setSelectedFiles={setSelectedFiles}
                          checked={checked}
                          setChecked={setChecked}
                        />
                      ))
                    ) : (
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell className="inline-flex space-x-2 justify-center items-start max-w-40 lg:max-w-md  p-0 color-text-secondary h-auto">
                          <span>
                            <File />
                          </span>
                          <div className="flex flex-col gap-2">
                            <span className="break-words">
                              You don&apos;t have any public files yet. Visit
                              your dashboard to upload files and/or create docs,
                              whiteboards, etc.
                            </span>
                            <Button
                              variant="secondary"
                              size="sm"
                              className="min-w-fit w-[120px]"
                              onClick={() => setShowConfirmModal(true)}
                            >
                              Visit my dashboard
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      }
      primaryAction={{
        label: 'Add',
        onClick: onAddClick,
        className: 'w-full md:w-auto',
      }}
    />
  )
}

export const FileItem = ({
  file,
  selectedFiles,
  setSelectedFiles,
  files,
  setChecked,
}: {
  file: INewFile
  selectedFiles: INewFile[]
  setSelectedFiles: (files: INewFile[]) => void
  checked: boolean
  setChecked: (checked: boolean) => void
  files: INewFile[]
}) => {
  const fileSource = getFileType(file.metadata.name, file.metadata)

  const fileIcon =
    file.metadata.source === FileSource.USER_FILE_SYSTEM
      ? SystemFileIconMap[fileSource]
      : FileIconMap[file.metadata.source] || <FileText />

  const onClick = (e: any) => {
    const isChecked = e.target.checked
    let newSelectedFiles

    if (isChecked) {
      newSelectedFiles = [...selectedFiles, file]
    } else {
      newSelectedFiles = selectedFiles.filter(
        (item) => item.fileId !== file.fileId
      )
    }

    setSelectedFiles(newSelectedFiles)

    if (newSelectedFiles.length === files.length) {
      setChecked(true)
    } else {
      setChecked(false)
    }
  }

  return (
    <TableRow className="p-0">
      <TableCell className="p-0">
        <Checkbox
          checked={selectedFiles.some((item) => item.fileId === file.fileId)}
          onCheckedChange={onClick}
        />
      </TableCell>
      <TableCell className="inline-flex space-x-2 justify-center items-center max-w-40 lg:max-w-md p-0">
        <span>{fileIcon}</span>
        <span className="truncate">{file.metadata.name}</span>
      </TableCell>
      <TableCell className="p-0">
        <div className="flex justify-end lg:justify-start">
          <Tag variant="gray" icon="Users" className="rounded">
            {FileTypeMap[file.metadata.fileType]}
          </Tag>
        </div>
      </TableCell>
    </TableRow>
  )
}
