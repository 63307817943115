import React from 'react'
import { SecretFile } from '../../types/interface/file.interface'
import HashedJSONViewer from '../FileViewers/HashedJSONViewer'
import { DynamicModal } from '../../pages/PublicPortal/components/DynamicModal'

export const ViewKeyModal = ({
  fileData,
  isSecretScreenActive,
  setSecretScreenActive,
}: {
  fileData: SecretFile | undefined
  isSecretScreenActive: boolean
  setSecretScreenActive: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  return (
    <DynamicModal
      open={isSecretScreenActive}
      onOpenChange={setSecretScreenActive}
      title="View Backup Key"
      content={
        <div className="h-full w-full">
          <HashedJSONViewer file={fileData} />
        </div>
      }
      className="!max-w-[720px]"
    />
  )
}
