import React from 'react'
import { IChatMessage } from '../../types/interface/message.interface'
import AvatarCanvas from './Avatar'
import { formatAddress } from '../../utils/formatAddress'

import { useEffect, useRef } from 'react'

export const Comment = ({
  userMessages,
}: {
  userMessages: Array<IChatMessage>
}) => {
  const getTime = (day: number) => {
    const date = new Date(day)
    const time = date.toLocaleString('en-US', {
      hour: 'numeric',
      hour12: true,
      minute: 'numeric',
    })
    const dateAndMonth = `${date.getDate()} ${date.toLocaleString('en-us', {
      month: 'short',
    })}`
    return `${time}, ${dateAndMonth}`
  }

  const endOfMessagesRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (endOfMessagesRef.current) {
      endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [userMessages])

  return (
    <div className="h-[80vh] overflow-auto no-scrollbar">
      {userMessages.map((item, index) => (
        <div key={index} className="flex flex-col gap-4 py-4 px-8 border-b">
          <div className="flex items-center">
            <div className="flex items-center gap-3">
              <AvatarCanvas canvasId={index} inputValue={item.by} />
              <div className="flex flex-col justify-between items-start">
                <p>{`${formatAddress(item.by)}`}</p>
                <small className=" text-gray-500">
                  {getTime(item.createdAt)}
                </small>
              </div>
            </div>
          </div>
          <p className="text-sm">{item.text}</p>
        </div>
      ))}
      <div ref={endOfMessagesRef}></div>
    </div>
  )
}
