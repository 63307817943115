import React from 'react'
import { useMediaQuery } from '@mui/material'
import MainWrapper from '../components/common/MainWrapper'
import { FAQs } from '../components/common/FAQs'

export default function Manifesto() {
  const isMediaMax1025px = useMediaQuery('(max-width : 1025px)')

  return (
    <MainWrapper>
      <div className={`w-full flex justify-center h-full `}>
        <div
          className={`rounded-lg ${
            isMediaMax1025px ? 'w-full' : 'w-[50%]'
          }   color-border-default border shadow-lg overflow-auto no-scrollbar p-8 h-auto`}
        >
          <h2
            className={`font-bold text-center ${
              isMediaMax1025px ? 'text-2xl' : 'text-3xl'
            } `}
          >
            Frequently Asked Questions
          </h2>
          <FAQs />
        </div>
      </div>
    </MainWrapper>
  )
}
