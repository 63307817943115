/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { Dispatch, SetStateAction } from 'react'
import { useAccount } from 'wagmi'
import sendNotifcation from '../../../utils/notification'
import { defaultNetwork } from '../../../config/network-config'
import { useCreatePortal } from '../../../providers'
import { Info } from 'lucide-react'
import { NetworkState } from '../../../types/enum/networkstate.enum'
import { useSafeAppStatus } from '../../../store/common/hooks'
import { Button } from '../../../pages/PublicPortal/components/Button'
import { usePrivyHelper } from '../../../hooks/usePrivyHelper'
import { validateOrSwitchNetwork } from '../../../utils/ethUtils'
import { AllowedChainId } from '../../../config/wagmi-config'
import { Tooltip } from '../../../pages/PublicPortal/components/Tooltip'
import { METAMASK_WALLET_CLIENT_TYPE, WC_CONNECTOR_TYPE } from '../../../config'

export const CreatePortalButton = ({
  onToggleVideo,
}: {
  onToggleVideo: Dispatch<SetStateAction<boolean>>
}) => {
  const { chainId } = useAccount()
  const walletAddress = usePrivyHelper().walletAddress
  const isSafeApp = useSafeAppStatus()
  const { onCreatePortal, whitelistedState, isLoading } = useCreatePortal()
  const {
    isLoading: isPrivyLoading,
    connectorType,
    walletClientType,
  } = usePrivyHelper()
  const { isWhitelisted } = whitelistedState

  const createPortal = async () => {
    if (isLoading) return
    if (!walletAddress || !isWhitelisted) return
    try {
      if (isSafeApp && chainId !== defaultNetwork.chainId) {
        sendNotifcation(
          'Wrong network',
          `We currently only support ${defaultNetwork.name}`,
          'info'
        )
        return
      }

      if (!isSafeApp && chainId !== defaultNetwork.chainId) {
        await validateOrSwitchNetwork(
          defaultNetwork.chainId as AllowedChainId,
          walletClientType === METAMASK_WALLET_CLIENT_TYPE &&
            connectorType === WC_CONNECTOR_TYPE
        )
        if (connectorType === WC_CONNECTOR_TYPE) return
      }

      onToggleVideo(true)
      await onCreatePortal()
    } catch (error: any) {
      if (connectorType === WC_CONNECTOR_TYPE)
        sendNotifcation(
          'Failed to create portal',
          `Make sure you are connected to ${defaultNetwork.name} network`,
          'danger'
        )
      else sendNotifcation('Something went wrong', 'Please try again', 'danger')
    }
  }

  return (
    <div className="flex justify-start items-start">
      <div className="gap-4 flex flex-col justify-center items-start">
        {/* Tooltip that shows a message if the user is not whitelisted and is connected */}
        <Tooltip
          text={
            !isWhitelisted
              ? 'Your address is not valid. Try a different one or contact our team on X'
              : ''
          }
        >
          <span>
            <Button
              onClick={createPortal}
              disabled={isLoading || !isWhitelisted}
              isLoading={isLoading || isPrivyLoading}
            >
              <ButtonChildren />
            </Button>
          </span>
        </Tooltip>
        <WhitelistingHelperText />
      </div>
    </div>
  )
}

const ButtonChildren = () => {
  const { chainId } = useAccount()

  const getButtonText = () => {
    if (!chainId || chainId !== defaultNetwork.chainId)
      return `Switch to ${defaultNetwork.name}`
    return 'Create Portal'
  }

  return (
    <div className="flex items-center justify-center gap-3">
      <ChainImg />
      <h6 className="text-[15px] font-normal">{getButtonText()}</h6>
    </div>
  )
}

const ChainImg = () => {
  const { chainId, isConnected } = useAccount()
  const { logo } = defaultNetwork
  if (chainId === defaultNetwork.chainId || !logo || !isConnected) return null
  return <img src={logo} alt={defaultNetwork.name} className="w-[24px]" />
}

export const WhitelistingHelperText = () => {
  const { whitelistedState } = useCreatePortal()
  const { isConnected } = useAccount()
  const { isWhitelisted, networkState } = whitelistedState

  if (networkState !== NetworkState.done || isWhitelisted || !isConnected)
    return null

  return (
    <p className="text-helper-text-sm color-text-secondary">
      <span className="inline-block align-middle mr-1">
        <Info className="text-white" size={16} fill="red" />
      </span>
      Try a different address or{' '}
      <a
        href="https://twitter.com/intent/tweet?text=Hello%20%40fileverse%2C%20I%27m%20looking%20to%20get%20access%20to%20your%20private%20beta.%20Let%20me%20in!&url="
        target="_blank"
        rel="noopener noreferrer"
        className="underline"
      >
        reach out to us for access
      </a>
    </p>
  )
}
