/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useSearchParams } from 'react-router-dom'
// import { useConnectModal } from '@rainbow-me/rainbowkit'
import { useSignMessage } from 'wagmi'
import checkWhitelistAPI from '../../api/account/whitelist'
import { captureException } from '@sentry/react'
import sendNotifcation, { clearAllNotification } from '../../utils/notification'

import { generatePortalData, getNewPortalInfo } from '../../utils/portal'

import {
  mintPortalTransaction,
  addCollaboratorCall,
} from '../../utils/transaction'
import {
  parseTrxEventLogs,
  waitForTransactionReceipt,
} from '../../utils/transaction/trxUtils'
import { SecretFile } from '../../types/interface/file.interface'
import { getAgentSignatureMessage } from '../../utils/messages'
import { requestSignature } from '../../utils/messages/requestSignature'
import { createAgent, encryptAndStoreAgentKey } from '../../utils/agentHandler'
import { useInvokerCredentialsAndKeys } from '../../store/invoker/hooks'
import { getISEAKeyPair } from '../../utils/libCrypto'
import { ISEAPair } from 'gun'
import { batch } from 'react-redux'
import {
  addCollaboratorToStore,
  setPortalAgent,
  setPortalSignlessMode,
} from '../../store/contract/reducer'
import { setInvokerAgentKey } from '../../store/invoker/reducer'
import {
  addInvokerCredentialsThunk,
  addInvokerServerKeysThunk,
} from '../../store/invoker/thunks'
import { useAppDispatch } from '../../store/hooks'
import {
  addContractThunk,
  setUpMemberFileKey,
} from '../../store/contract/thunks'
import { getContractMetadata } from '../../utils/contract'
import { defaultNetwork } from '../../config/network-config'
import { KEY_GENERATION_NOTIFICATION_MESSAGE } from '../../constants'
import {
  ContractType,
  PortalContractEventEnum,
} from '../../utils/transaction/interface'
import { NetworkState } from '../../types/enum/networkstate.enum'
import { usePrivyHelper } from '../../hooks/usePrivyHelper'

interface ICreatePortalContextProps {
  whitelistedState: WhitelistedState
  portalAddress: string
  onCreatePortal: () => Promise<void>
  isLoading: boolean
  onActivateSignless: () => Promise<void>
  onConfirmSignless: () => Promise<void>
}

const CreatePortalContext = createContext<ICreatePortalContextProps | null>(
  null
)

export const useCreatePortal = () => {
  const context = React.useContext(CreatePortalContext)
  if (!context)
    throw new Error(
      'useCreatePortal must be used within a CreatePortalProvider'
    )
  return context
}

interface ICreatePortalProviderProps {
  children: React.ReactNode
  onTxSuccessCb?: () => void
  onResolvedEventCb?: () => void
  onResolveEventErrorCb?: () => void
  onTxErrorCb?: () => void
  onActivateSignlessCb?: () => void
  onConfirmSignlessCb?: (collaboratorAddress: string) => void
}

interface WhitelistedState {
  isWhitelisted: boolean
  networkState: NetworkState
}

// TODO Move logic from Steps to Provider
export const CreatePortalProvider = ({
  children,
  onTxErrorCb,
  onTxSuccessCb,
  onResolvedEventCb,
  onResolveEventErrorCb,
  onActivateSignlessCb,
  onConfirmSignlessCb,
}: ICreatePortalProviderProps) => {
  const [whitelistedState, setWhitelisted] = useState<WhitelistedState>({
    isWhitelisted: false,
    networkState: NetworkState.idle,
  })
  const [isLoading, setLoading] = useState<boolean>(false)
  const [searchParams] = useSearchParams()
  const code = searchParams.get('code') || ''

  const [createPortalState, setCreatePortalState] = useState({
    secretFileContent: {} as SecretFile,
    portalAddress: '',
    agentAddress: '',
  })
  const { signMessageAsync } = useSignMessage()
  const dispatch = useAppDispatch()

  const { secretFileContent, portalAddress, agentAddress } = createPortalState

  const walletAddress = usePrivyHelper().walletAddress as string

  const onConnectAccount = useCallback(async () => {
    try {
      if (!walletAddress) return

      setLoading(true)
      setWhitelisted((prev) => ({
        ...prev,
        networkState: NetworkState.loading,
      }))
      const response = await checkWhitelistAPI({
        invoker: walletAddress as string,
        code,
        origin: document.referrer,
      })
      setWhitelisted(() => ({
        isWhitelisted: response?.data?.whitelisted,
        networkState: NetworkState.done,
      }))
    } catch (err: any) {
      console.log(err)
      captureException(err)
      sendNotifcation(
        'An error occurred while checking whitelist',
        ``,
        'danger'
      )
      setWhitelisted((prev) => ({
        ...prev,
        networkState: NetworkState.done,
      }))
    } finally {
      setLoading(false)
    }
  }, [walletAddress, code])

  useEffect(() => {
    onConnectAccount()
  }, [onConnectAccount])

  const { portalGunKey } = useInvokerCredentialsAndKeys(
    walletAddress as string,
    portalAddress as string
  )
  const authKey = getISEAKeyPair(portalGunKey)

  const setupPortalOnStore = useCallback(
    async (portalAddress: string, _secretFileContent: SecretFile) => {
      try {
        const portalInfo = await getNewPortalInfo(portalAddress)
        const { chainId } = defaultNetwork
        await dispatch(
          setUpMemberFileKey({
            contractAddress: portalAddress,
            editSecretKey: _secretFileContent.editSecret,
            memberDecryptionKey: _secretFileContent.memberDecryptionKey,
            invokerAddress: walletAddress as string,
            chainId,
            tokenId: Number(portalInfo?.tokenId),
          })
        )

        await dispatch(
          addInvokerCredentialsThunk({
            credential: {
              editDID: _secretFileContent.editDID,
              editSecret: _secretFileContent.editSecret,
              viewDID: _secretFileContent.viewDID,
              viewSecret: _secretFileContent.viewSecret,
              contractAddress: portalAddress,
            },
            invokerAddress: walletAddress as string,
          })
        )

        await dispatch(
          addInvokerServerKeysThunk({
            ..._secretFileContent,
            invokerAddress: walletAddress as string,
            contractAddress: portalAddress,
          })
        )

        const result = await getContractMetadata(portalAddress)

        await dispatch(
          addContractThunk({
            contractAddress: portalAddress,
            chainId,
            metadata: result.data,
            collaborators: [walletAddress as string],
            members: [{ invokerAddress: walletAddress as string }],
            owner: walletAddress as string,
            agentAddress: '',
            enabled_signless: false,
            metadataLastWorkingIPFSUrl: result.lastWorkingUrl,
            tokenId: Number(portalInfo?.tokenId),
          })
        )
        clearAllNotification()
        if (typeof onResolvedEventCb === 'function') onResolvedEventCb()
      } catch (err) {
        captureException(err)
        if (typeof onResolveEventErrorCb === 'function') onResolveEventErrorCb()
        sendNotifcation(
          'Couldnt resolve transaction event',
          'Please Try Again',
          'danger'
        )
        console.log(err)
      }
    },
    [dispatch, onResolveEventErrorCb, onResolvedEventCb, walletAddress]
  )

  const onCreatePortal = useCallback(async () => {
    try {
      setLoading(true)

      const { chainId } = defaultNetwork
      const { secretFileContent, keyVerifiers } = await generatePortalData(
        chainId as number,
        walletAddress as string
      )
      const mintTrxHash = await mintPortalTransaction({
        walletAddress: walletAddress as string,
        secretFileContent,
        keyVerifiers,
      })

      if (typeof onTxSuccessCb === 'function') onTxSuccessCb()
      sendNotifcation(KEY_GENERATION_NOTIFICATION_MESSAGE, '', 'default', 10000)
      const { logs } = await waitForTransactionReceipt(mintTrxHash)
      const parsedLogs =
        parseTrxEventLogs(
          logs,
          ContractType.Registry,
          PortalContractEventEnum.Mint
        ) || []
      const mintedPortalAddress = parsedLogs[1]

      await setupPortalOnStore(mintedPortalAddress, secretFileContent)
      setCreatePortalState((prev) => ({
        ...prev,
        portalAddress: mintedPortalAddress,
        secretFileContent,
      }))
    } catch (err: any) {
      if (typeof onTxErrorCb === 'function') onTxErrorCb()
      throw new Error(err)
    } finally {
      setLoading(false)
    }
  }, [onTxErrorCb, onTxSuccessCb, setupPortalOnStore, walletAddress])

  const onActivateSignless = useCallback(async () => {
    try {
      setLoading(true)
      const messageToSign = getAgentSignatureMessage()
      const { signature } = await requestSignature(
        walletAddress as string,
        messageToSign,
        signMessageAsync
      )
      const { status, agentAddress } = await createAgent(
        portalAddress,
        signature
      )
      if (status !== 'success' || !agentAddress)
        throw new Error('Failed to create agent')
      const agentKey = await encryptAndStoreAgentKey(
        signature,
        secretFileContent.portalEncryptionKey,
        portalAddress,
        agentAddress,
        authKey as ISEAPair
      )
      setCreatePortalState((prev) => ({
        ...prev,
        agentAddress,
      }))

      batch(() => {
        dispatch(
          setPortalAgent({
            contractAddress: portalAddress as string,
            agentAddress: agentAddress,
          })
        )
        dispatch(
          setInvokerAgentKey({
            invokerAddress: walletAddress as string,
            agentKey: agentKey,
            contractAddress: portalAddress as string,
          })
        )
      })
      if (typeof onActivateSignlessCb === 'function') onActivateSignlessCb()
    } catch (err) {
      captureException(err)
      sendNotifcation(
        'An error occurred while activating signless',
        `Please Try Again`,
        'danger'
      )
    } finally {
      setLoading(false)
    }
  }, [
    authKey,
    dispatch,
    onActivateSignlessCb,
    portalAddress,
    secretFileContent.portalEncryptionKey,
    signMessageAsync,
    walletAddress,
  ])

  const onConfirmSignless = useCallback(async () => {
    try {
      if (!agentAddress) throw new Error('Agent address not found')
      setLoading(true)

      await addCollaboratorCall({
        collaboratorAddress: agentAddress,
        contractAddress: portalAddress,
        walletAddress: walletAddress as string,
      })

      dispatch(
        addCollaboratorToStore({
          contractAddress: portalAddress as string,
          collaboratorAddress: agentAddress,
        })
      )
      dispatch(
        setPortalSignlessMode({
          contractAddress: portalAddress as string,
          enabled: true,
        })
      )
      if (typeof onConfirmSignlessCb === 'function')
        onConfirmSignlessCb(agentAddress)
    } catch (err) {
      sendNotifcation(
        'Failed to go signless',
        'Activate signless transaction in order to go signless',
        'danger'
      )
    } finally {
      setLoading(false)
    }
  }, [
    agentAddress,
    portalAddress,
    walletAddress,
    dispatch,
    onConfirmSignlessCb,
  ])

  const contextValue = useMemo(() => {
    return {
      onCreatePortal,
      whitelistedState,
      portalAddress,
      isLoading: isLoading,
      onActivateSignless,
      onConfirmSignless,
    }
  }, [
    onCreatePortal,
    whitelistedState,
    portalAddress,
    isLoading,
    onActivateSignless,
    onConfirmSignless,
  ])

  return (
    <CreatePortalContext.Provider value={contextValue}>
      {children}
    </CreatePortalContext.Provider>
  )
}
