/* eslint-disable @typescript-eslint/no-explicit-any */
import { ISEAPair } from 'gun'
import { fromUint8Array, toUint8Array } from 'js-base64'
import Quill from 'quill'
import * as Y from 'yjs'
import { SEA } from './utils'
import { DocStatus } from '../../components/ExcalidrawCanvas/SavingStatusUI'
import { GunInstance } from '../instantiateGun'
import { captureException } from '@sentry/react'

export interface IDpageAsset {
  emoji: string | undefined
  coverIPFSHash: string | undefined
  name: string
  fileId: string
}
export interface IPluginFileData {
  content: string
  by: string
}

export const saveYdocUpdates = (
  ydoc: Y.Doc,
  quill: Quill,
  docContentNode: any,
  rtcKey: ISEAPair,
  id: string,
  onSaving: (v: DocStatus) => void
) => {
  ydoc.on('update', async () => {
    onSaving(DocStatus.SAVING)
    quill?.formatLine(0, 1, 'header', 1)
    const state = Y.encodeStateAsUpdate(ydoc)
    const data: IPluginFileData = {
      content: fromUint8Array(state),
      by: id,
    }
    const encryptedData = await SEA.encrypt(data, rtcKey)
    try {
      await GunInstance.putGunNodeData(docContentNode, encryptedData)
      onSaving(DocStatus.SAVED)
    } catch (err) {
      console.log(err)
      captureException(err)
    }
  })
}

export const applyUpdatesFromGunNode = (
  ydoc: Y.Doc,
  quill: Quill,
  docContentNode: any,
  rtcKey: ISEAPair,
  id: string
) => {
  docContentNode.on(async (data: string) => {
    const decryptedData: IPluginFileData = await SEA.decrypt(data, rtcKey)
    if (
      decryptedData.by !== id &&
      toUint8Array(decryptedData.content) !== Y.encodeStateAsUpdate(ydoc) //if the change is made by the current user then don't show the data, show new data for other collaborator
    ) {
      Y.applyUpdate(ydoc, toUint8Array(decryptedData.content))
      quill?.formatLine(0, 1, 'header', 1)
    }
  })
}

export const handleUserCursorPosition = (
  quill: Quill,
  docCursorNode: any,
  currentUser: string
) => {
  quill.on('selection-change', async (range) => {
    try {
      const node = docCursorNode.get(currentUser)
      await GunInstance.putGunNodeData(node, {
        user: currentUser,
        cursorPosition: JSON.stringify(range),
      })
    } catch (err) {
      console.log(err)
      captureException(err)
    }
  })
}

export const handleRemotePeerCursorPosition = (
  docCursorNode: any,
  quill: Quill,
  currentUser: string
) => {
  const cursors = quill.getModule('cursors')
  const usercolors = [
    '#30bced',
    '#6eeb83',
    '#fa69d1',
    '#ecd444',
    '#ee6352',
    '#db3041',
    '#0ad7f2',
    '#1bff39',
  ]
  docCursorNode
    .map((data: any) => (data.user !== currentUser ? data : undefined))
    .on((data: any) => {
      const color = usercolors[Math.floor(Math.random() * usercolors.length)]
      cursors.createCursor(data.user, data.user, color)
      cursors.moveCursor(data.user, JSON.parse(data.cursorPosition))
    })
}
