import { createSlice } from '@reduxjs/toolkit'

interface PortalUpdateLogs {
  updates: Record<string, number>
  creation: Record<string, number>
  legacyDocWarning: Record<string, boolean>
  settingSignal: Record<string, boolean>
}

const initialState: PortalUpdateLogs = {
  updates: {},
  creation: {},
  legacyDocWarning: {},
  settingSignal: {},
}

const portalUpdateLogsSlice = createSlice({
  name: 'portalUpdateLogs',
  initialState,
  reducers: {
    updatePortalLatestBlockNumber(state: PortalUpdateLogs, action): void {
      const { contractAddress, latestBlock } = action.payload
      if (!contractAddress || !latestBlock) return
      if (state?.updates) {
        state.updates[contractAddress] = latestBlock
      } else {
        state.updates = {
          [contractAddress]: latestBlock,
        }
      }
    },
    updatePortalCreationTimestamp(state: PortalUpdateLogs, action): void {
      const { contractAddress, creationTimestamp } = action.payload
      if (!contractAddress || !creationTimestamp) return
      if (state?.creation) {
        state.creation[contractAddress] = creationTimestamp
      } else {
        state.creation = {
          [contractAddress]: creationTimestamp,
        }
      }
    },
    updateLegacyDocWarning(state: PortalUpdateLogs, action): void {
      const { contractAddress, legacyDocWarningState } = action.payload
      if (!contractAddress || !legacyDocWarningState) return
      if (state?.legacyDocWarning) {
        state.legacyDocWarning[contractAddress] = legacyDocWarningState
      } else {
        state.legacyDocWarning = {
          [contractAddress]: legacyDocWarningState,
        }
      }
    },
    updateSettingSignal(state: PortalUpdateLogs, action): void {
      const { walletAddress, settingSignalState } = action.payload
      if (!walletAddress || !settingSignalState) return
      if (state?.settingSignal) {
        state.settingSignal[walletAddress] = settingSignalState
      } else {
        state.settingSignal = {
          [walletAddress]: settingSignalState,
        }
      }
    },
  },
})

export const {
  updatePortalLatestBlockNumber,
  updatePortalCreationTimestamp,
  updateLegacyDocWarning,
  updateSettingSignal,
} = portalUpdateLogsSlice.actions

export default portalUpdateLogsSlice.reducer
