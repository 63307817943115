/* eslint-disable no-constant-condition */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import CTAButton from '../common/CTAButton'
import linkIcon from '../../assets/link.svg'
import {
  DocCollaboratorsList,
  DocOwnerForm,
} from '../../pages/domain/TipTapEditor'
import { Tooltip } from '@mui/material'
import { Popup } from '../Popup/PopUp'
import xicon from '../../assets/x-icon.svg'
import infoIcon from '../../assets/info.svg'
import copyToClipboard from 'copy-to-clipboard'
import stopCircle from '../../assets/stopCircle.svg'
import sendNotifcation from '../../utils/notification'
import { useLocalStorage } from 'usehooks-ts'
import { checkIfOwnerOrCollaborator } from '../../utils/checkIfOwnerOrCollaborator'
import { useContract } from '../../store/contract/hooks'
import { useNavigate, useParams } from 'react-router-dom'
import { useServerKeys } from '../../store/invoker/hooks'
import { usePrivyHelper } from '../../hooks/usePrivyHelper'

const LiveCollaborationPopup = ({
  isConnected,
  popupRef,
  collaborators,
  disconnect,
  isOpen,
  closePopup,
  connect,
  docType,
}: {
  connect: any
  isConnected: boolean
  popupRef: React.LegacyRef<HTMLDivElement>
  disconnect: () => void
  collaborators: any[]
  isOpen: boolean
  closePopup: () => void
  isDocOwner: boolean
  docType: string
}) => {
  const navigate = useNavigate()
  const [username, setUserName] = useLocalStorage('username', '')
  const walletAddress = usePrivyHelper().walletAddress
  const { address: contractAddress } = useParams()
  const contract = useContract(contractAddress as string)
  const serverKeys = useServerKeys(
    walletAddress as string,
    contractAddress as string
  )
  const isCollaborator = checkIfOwnerOrCollaborator(
    contract,
    walletAddress as string,
    serverKeys
  )
  const handleUsername = (name: string) => {
    setUserName(name)
  }
  const handleConnect = async () => {
    await connect()
    copyToClipboard(`${window.location.href}&collab=true`)
    sendNotifcation(
      'Link copied !!',
      'Go ahead share it with anyone',
      'success'
    )
  }
  const renderMiddleArea = () => {
    if (!isConnected && isCollaborator) {
      return (
        <DocOwnerForm
          connect={handleConnect}
          setUserName={handleUsername}
          username={username}
        />
      )
    }
    return <DocCollaboratorsList collaborators={collaborators} />
  }
  return (
    <Popup isOpen={isOpen} width={'500px'}>
      <div ref={popupRef} className="bg-white relative w-full rounded-lg">
        <img
          onClick={() => closePopup()}
          src={xicon}
          className="w-6 cursor-pointer absolute top-4 right-4"
          alt="x-icon"
        />
        <div className="py-6 border-b-2">
          <h1 className="text-center text-[22px] mb-2 font-medium">
            {isConnected
              ? 'Live collaboration ongoing...'
              : `Share this ${docType} for live collaboration`}
          </h1>

          {isConnected ? (
            <>
              <p className="text-center text-[#777777] text-sm font-medium">
                Click ‘Copy link’ and share with anyone you want.
              </p>
              <p className="text-center text-[#777777]  text-sm font-medium">
                To stop the live collaboration, click on the ‘Stop
                collaboration’ button.
              </p>
            </>
          ) : (
            <p className="text-center text-[#777777] text-sm font-medium">
              Add a name first, then click ‘copy link’ and share with anyone you
              want
            </p>
          )}
        </div>
        <div className="py-2 pt-3 px-6">
          <Tooltip
            placement="top"
            title={
              'Here you can see the names of people or aliens collaborating on your dDoc.'
            }
          >
            <div className="flex gap-1 items-center">
              <h2 className="text-base font-medium">People with access</h2>

              <img src={infoIcon} className="w-[14px]" />
            </div>
          </Tooltip>
          {renderMiddleArea()}
        </div>
        <div className={`py-6 px-6 flex flex-col ${isConnected && 'gap-8'}`}>
          <div className="flex gap-2">
            <div className="w-[10%] mt-1">
              <img src={infoIcon} className="w-[14px]" />
            </div>

            <p className=" text-sm text-[#777777]  font-medium">
              {`This ${docType} is not stored on centralized servers, nor on our
              servers. Content on your ${docType} is end-to-end encrypted and only
              visible between you & anyone you share this link with.`}
            </p>
          </div>
          <div className="flex justify-end gap-4">
            {isConnected && (
              <CTAButton
                leftIcon={stopCircle}
                title={'Stop collaboration'}
                onClick={async () => {
                  if (isCollaborator) {
                    disconnect()
                  } else {
                    navigate(
                      `/${contractAddress}/member?chainId=${contract?.chainId}`,
                      { replace: true }
                    )
                  }
                }}
              />
            )}
            {isCollaborator && isConnected && (
              <CTAButton
                onClick={async () => {
                  copyToClipboard(window.location.href)
                  closePopup()
                  sendNotifcation(
                    'Link copied !!',
                    'Go ahead share it with anyone',
                    'success'
                  )
                }}
                leftIcon={linkIcon}
                backgroundColorClassName={
                  'bg-black text-white border-2 border-black'
                }
                title={'Copy link'}
              />
            )}
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default LiveCollaborationPopup
