/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-key */
import React, { useState } from 'react'
import {
  // Button,
  IconButton,
  TextField,
  Toggle,
  // Tooltip,
} from '@fileverse/ui'
import { IToolbarProps } from '../interfaces'
import { FloatingToolbar } from './FloatingToolbar'
import { usePublicPortalContext } from '../../../providers/PublicPortalProvider'
import { Button } from './Button'
import { Tooltip } from './Tooltip'
import { AnimatePresence, motion } from 'framer-motion'
import { useOnClickOutside } from 'usehooks-ts'

export const animationConfig = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 40 },
  transition: {
    type: 'spring',
    damping: 30,
    stiffness: 300,
    duration: 0.2,
  },
}

export default function Toolbar({
  onAddFiles,
  onAddSection,
  onAddText,
  onUpdate,
}: IToolbarProps) {
  const [showSettings, setShowSettings] = useState<boolean>(false)
  const [showAddLinkPrompt, setShowAddLinkPrompt] = useState<boolean>(false)
  const [userLink, setUserLink] = useState<string>('')
  const {
    addLinkFile,
    loadingButton,
    layoutSwitchEnabled,
    commentsEnabled,
    setLayoutSwitchEnabled,
    setCommentsEnabled,
    fetchingMetadata,
  } = usePublicPortalContext()

  const settingsRef = React.useRef<HTMLDivElement>(null)
  const linkRef = React.useRef<HTMLDivElement>(null)

  useOnClickOutside(settingsRef, () => setShowSettings(false))
  useOnClickOutside(linkRef, () => setShowAddLinkPrompt(false))

  const handleSettingsClick = (event: any) => {
    if (settingsRef.current && !settingsRef.current.contains(event.target)) {
      setShowSettings(false)
    } else {
      setShowSettings(!showSettings)
    }
  }

  const handleAddLinkClick = (event: any) => {
    if (linkRef.current && !linkRef.current.contains(event.target)) {
      setShowAddLinkPrompt(false)
    } else {
      setShowAddLinkPrompt(!showAddLinkPrompt)
    }
  }

  const floatingToolbarOptions: React.ReactNode[][] = [
    [
      <Tooltip text="Add files">
        <IconButton
          icon="Plus"
          variant="ghost"
          size="md"
          onClick={onAddFiles}
        />
      </Tooltip>,
      <Tooltip text="Add Section">
        <IconButton
          icon="Section"
          variant="ghost"
          size="md"
          onClick={onAddSection}
        />
      </Tooltip>,
      <Tooltip text="Add a Note">
        <IconButton icon="Type" variant="ghost" size="md" onClick={onAddText} />
      </Tooltip>,
      <Tooltip text="Add a Link">
        <IconButton
          icon="Link"
          variant="ghost"
          size="md"
          onClick={handleAddLinkClick}
        />
      </Tooltip>,
    ],

    [
      <Tooltip text="Settings">
        <IconButton
          onClick={handleSettingsClick}
          icon="Settings"
          variant="ghost"
          size="md"
        />
      </Tooltip>,
    ],
    [
      <Tooltip text="To publish the portal and make content available to visitors click “Update”">
        <Button
          size="lg"
          className="min-w-[96px] max-w-[96px]"
          onClick={onUpdate}
          isLoading={loadingButton}
          disabled={loadingButton}
        >
          Update
        </Button>
      </Tooltip>,
    ],
  ]

  const onAddClick = () => {
    addLinkFile(userLink, () => setShowAddLinkPrompt(false))
  }

  const onEnableLayoutSwitch = (prev: boolean) => {
    setLayoutSwitchEnabled(!prev)
  }

  const onEnableComments = (prev: boolean) => {
    setCommentsEnabled(!prev)
  }

  return (
    <div className="relative">
      <AnimatePresence mode="wait">
        {showSettings && (
          <motion.div
            ref={settingsRef}
            key="settings"
            {...animationConfig}
            className="w-fit absolute -top-40 right-0 color-bg-default rounded-lg color-border-default border-[0.5px] shadow-elevation-4 flex flex-col gap-4 justify-start items-start p-6"
          >
            <div className="w-full flex gap-6 justify-between items-center">
              <div className="flex flex-col justify-start items-start">
                <p className="text-body-sm-bold">Comments</p>
                <p className="text-helper-text-sm color-text-secondary">
                  Allow visitors to leave comments
                </p>
              </div>
              <Toggle
                checked={commentsEnabled}
                onCheckedChange={onEnableComments}
              />
            </div>
            <div className="w-full flex gap-6 justify-between items-center">
              <div className="flex flex-col justify-start items-start">
                <p className="text-body-sm-bold">Layout switching</p>
                <p className="text-helper-text-sm color-text-secondary">
                  Allow to switch between grid and list view
                </p>
              </div>
              <Toggle
                checked={layoutSwitchEnabled}
                onCheckedChange={onEnableLayoutSwitch}
              />
            </div>
          </motion.div>
        )}
        {showAddLinkPrompt && (
          <motion.div
            ref={linkRef}
            key="addLinkPrompt"
            {...animationConfig}
            className="w-[400px] absolute bottom-20 translate-x-0 color-bg-default rounded-lg color-border-default border-[0.5px] shadow-elevation-4 flex justify-between gap-2 items-center px-4 py-2"
          >
            {' '}
            <TextField
              className="min-w-[19rem]"
              onChange={(e) => setUserLink(e.target.value)}
              disabled={fetchingMetadata}
            />
            <Button
              className="min-w-fit"
              onClick={onAddClick}
              isLoading={fetchingMetadata}
            >
              Add
            </Button>
          </motion.div>
        )}
      </AnimatePresence>
      <FloatingToolbar options={floatingToolbarOptions} />
    </div>
  )
}
