/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-const */
import React, { useEffect, useRef, useState } from 'react'
import isNull from 'lodash/isNull'
import isEmpty from 'lodash/isEmpty'
import { EditorBubbleMenu } from '../TipTap/EditorBubbleMenu'
import { Editor, EditorContent } from '@tiptap/react'
import { EditingProvider } from '../../hooks/useEditingContext'
import Spinner from '../Spinner'
import { useMediaMax1025px } from '../../hooks/useMediaQueryHook'
import cn from 'classnames'

const TipTapEditorPreview = ({
  fileState,
  isEditing,
  isFetching,
  editor,
}: {
  fileState: any
  isEditing: boolean
  isFetching?: boolean
  editor: Editor
}) => {
  const isMediaMax1025px = useMediaMax1025px()
  const [emptyDocument, setEmptyDocument] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  const focusEditor = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if ((ref.current as any)?.contains(e.target)) return
    editor?.chain().focus().run()
  }

  useEffect(() => {
    if (isNull(fileState) || isEmpty(fileState)) {
      setEmptyDocument(true)
    }
  }, [fileState])

  return (
    <>
      {emptyDocument ? (
        <div className="w-full h-full flex justify-center items-center">
          <h1 className="text-2xl text-gray-300">Empty Document</h1>
        </div>
      ) : (
        <div
          onClick={focusEditor}
          className="h-full w-full flex justify-center"
        >
          <div
            className={cn(
              'min-h-[1056px] shadow-inner bg-white overflow-scroll no-scrollbar rounded-md',
              isMediaMax1025px ? 'w-full p-8' : 'w-[856px] p-[96px]'
            )}
          >
            {isFetching && (
              <div className="w-full h-full flex justify-center items-center">
                <Spinner />
              </div>
            )}
            <div
              ref={ref}
              className={cn('w-full h-full', isFetching && 'hidden')}
            >
              {isEditing && (
                <div>{editor && <EditorBubbleMenu editor={editor} />}</div>
              )}
              <EditingProvider isDisabled={!isEditing}>
                <EditorContent editor={editor} />
              </EditingProvider>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default TipTapEditorPreview
