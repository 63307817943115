/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useRef, useState } from 'react'
import {
  IFileChatKeyPair,
  INewFile,
} from '../../types/interface/file.interface'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { ISEAPair } from 'gun'
import { checkIfOwnerOrCollaborator } from '../../utils/checkIfOwnerOrCollaborator'
import { useContract } from '../../store/contract/hooks'
import { useMediaMax1025px } from '../../hooks/useMediaQueryHook'
import useQRCode from '../../hooks/useQRCode'
import TiptapToolBar from './TiptapToolBar'
import { WebPageCommentSection } from '../WebPages/WebPageCommentSection'
import { instantiateSEA, GunInstance } from '../../utils/instantiateGun'
import cn from 'classnames'
import { useFileData } from '../../store/files/hooks'
import { MakeFileFromObject } from '../../utils/makeFileFromObject'
import { useServerKeys } from '../../store/invoker/hooks'
import WebPageProofPublish from '../WebPages/WebPageProofPublish'
import useComponentVisibilty from '../../hooks/useVisibility'
import EditorPreview from '../FileViewers/QuillEditorPreview'
import TipTapEditorPreview from '../FileViewers/TipTapEditorPreview'
import * as Y from 'yjs'
import { useGunNodes } from '../../hooks/useGunNode'
import { postEditedPluginContent } from './useEditorHook'
import { getISEAKeyPair } from '../../utils/libCrypto'
import { IPluginFileData } from '../../utils/collaboration/rtc'
import { toUint8Array } from 'js-base64'
import { QRModal } from '../Popup/QRModal'
import LegacyDocWarning from '../Popup/LegacyDocWarning'
import { useLegacyDocWarning } from '../../store/portalUpdateLogs/hooks'
import PluginNavbar from '../Navbars/PluginNavbar'
import { WebPageMoreMenu } from '../WebPages/WebPageMoreMenu'
import { DocStatus } from '../ExcalidrawCanvas/SavingStatusUI'
import { AnyExtension } from '@tiptap/core'
import Collaboration from '@tiptap/extension-collaboration'
import { Editor, useEditor } from '@tiptap/react'
import { defaultExtensions } from './extensions/defaultExtenstion'
import { TiptapEditorProps } from './props'
import { captureException } from '@sentry/react'
import { usePrivyHelper } from '../../hooks/usePrivyHelper'
import { DeletePopUp } from '../WebPages/DeletePopUp'

const SEA = instantiateSEA()

export default function PublicDDocPage({
  downloadUrl,
  chatKey,
}: {
  downloadUrl: string
  chatKey: IFileChatKeyPair
}) {
  const { address: contractAddress, fileId } = useParams()
  const [urlParams] = useSearchParams()
  const walletAddress = usePrivyHelper().walletAddress
  const fileData = useFileData(contractAddress as string, fileId)
  const contract = useContract(contractAddress as string)
  const isMediaMax1025px = useMediaMax1025px()
  const { qrRef, isQrVisible, setIsQrVisible } = useQRCode(false)

  const [fileInfo, setFileInfo] = useState<any>()
  const [moreMenu, setMoreMenu] = useState<boolean>(false)
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [showComments, setShowComments] = useState<boolean>(false)
  const [commentsAvailable, setCommentsAvailable] = useState({
    available: true,
    count: 0,
  })
  const docName = fileData?.metadata.name?.split('.')[0]
  const [title, setTitle] = useState<string>(docName || 'Unititled')
  const serverKeys = useServerKeys(
    walletAddress as string,
    contractAddress as string
  )
  const chainId = parseInt(urlParams.get('chainId') || '')
  const isCollaborator = checkIfOwnerOrCollaborator(
    contract,
    walletAddress as string,
    serverKeys
  )

  const [proofPublishOpen, setProofPublishOpen] = useState<boolean>(false)
  const {
    isComponentVisible: confirmDeleteModal,
    setIsComponentVisible: setConfirmDeleteModal,
  } = useComponentVisibilty(false)
  const copyFileURL = `${window.origin}${window.location.pathname}#/${contractAddress}/file/${fileId}?chainId=${chainId}`

  const navigate = useNavigate()
  const version = fileInfo?.version
  const isNewVersion = version === 2
  const [previewMode, setPreviewMode] = useState<boolean>(false)
  const [commentsEnabled, setCommentsEnabled] = useState<boolean>(true)
  const [isOldDoc, setIsOldDoc] = useState<boolean>(false)
  const [openWarningPopup, setOpenWarningPopup] = useState<boolean>(false)
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const [isEditorEnabled, setIsEditorEnabled] = useState<boolean>(false)
  const [draftAssetData, setDraftAssetData] = useState({
    name: docName,
    fileId: fileId!,
  })
  const [ydoc] = useState(new Y.Doc())
  const [docStatus, setDocStatus] = useState<DocStatus>(DocStatus.SAVED)
  const {
    getAuthenticatedDocumentContentNode,
    getAuthenticatedPluginMetadataNode,
    getEditedDdocContentNode,
    getEditedDdocAssetNode,
  } = useGunNodes()
  const authKey = getISEAKeyPair(serverKeys.portalGunKey) as ISEAPair
  const isWarningHidden = useLegacyDocWarning(contractAddress as string)

  const editor = useEditor(
    {
      extensions: [
        ...(defaultExtensions as AnyExtension[]),
        Collaboration.configure({
          document: ydoc,
        }),
      ],
      editorProps: TiptapEditorProps,
      content: fileInfo?.file,
      editable: false,
    },
    []
  )

  const getData = async () => {
    try {
      const res = await (await fetch(downloadUrl)).text()
      const data = JSON.parse(res)
      if (!data?.rtcId) setIsOldDoc(true)
      setFileInfo(data)
      if (
        Object.prototype.hasOwnProperty.call(
          fileData?.metadata,
          'commentsEnabled'
        )
      ) {
        setCommentsEnabled(fileData?.metadata?.commentsEnabled!)
      } else {
        setCommentsEnabled(true)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const publishTipTapDocument = () => {
    setIsEditorEnabled(false)

    const documentFile = {
      file: editor?.getJSON(),
      source: 'fileverse_document',
      version: 2,
      rtcId: fileInfo?.rtcId,
      rtcData: fileInfo?.rtcData,
      rtcKey: fileInfo?.rtcKey,
      commentsEnabled: commentsEnabled,
    }
    const file = MakeFileFromObject(documentFile, `${title || 'Untitled'}`)
    return file
  }

  const reinstateDraftEditNodeContents = async () => {
    const draftEditAssetNode = getEditedDdocAssetNode(
      fileInfo?.rtcId,
      fileInfo?.rtcKey
    )
    if (!authKey) return
    const draftAsset = await Promise.race([
      new Promise((resolve) => {
        draftEditAssetNode.on((data: IPluginFileData) => {
          if (data) {
            resolve(data)
            draftEditAssetNode.off()
          }
        })
      }),
      new Promise((resolve) =>
        setTimeout(() => {
          resolve(undefined)
          draftEditAssetNode.off()
        }, 5000)
      ),
    ])
    if (draftAsset) {
      const _draftAsset = draftAsset as any

      setDraftAssetData({
        name: _draftAsset.name || fileInfo?.name,
        fileId: fileId as string,
      })
    }
    const editedDdocContentNode = getEditedDdocContentNode(
      fileInfo?.rtcId,
      fileInfo?.rtcKey
    )
    const draftEditContent = await Promise.race([
      new Promise((resolve) => {
        editedDdocContentNode.on((data: IPluginFileData) => {
          if (data) {
            resolve(data)
          }
        })
      }),
      new Promise((resolve) => setTimeout(() => resolve(undefined), 5000)),
    ])
    if (!draftEditContent) {
      setTimeout(() => {
        editor?.commands.setContent(fileInfo?.file?.content!)
      })
      setIsFetching(false)
    } else {
      const rtcContent = (draftEditContent as IPluginFileData)?.content
      const decryptedData = await SEA.decrypt(rtcContent, fileInfo?.rtcKey)
      const contents = toUint8Array(decryptedData)
      editor?.commands.clearContent()
      Y.applyUpdate(ydoc, contents)
      setIsFetching(false)
    }
  }

  const saveDraftAsset = async (draftEditAssetNode: any) => {
    const entries = Object.entries(draftAssetData)

    for (const [key, value] of entries) {
      try {
        await GunInstance.putGunNodeData(draftEditAssetNode.get(key), value)
      } catch (err) {
        console.log(err)
      }
    }
  }

  const showWarningPopup = () => {
    setOpenWarningPopup(true)
  }

  useEffect(() => {
    if (fileInfo?.rtcId && isEditing) {
      const draftEditAssetNode = getEditedDdocAssetNode(
        fileInfo?.rtcId,
        fileInfo?.rtcKey
      )
      // save only new draft asset to gun
      if (fileInfo?.name !== draftAssetData.name) {
        saveDraftAsset(draftEditAssetNode)
      }
    }
  }, [draftAssetData, fileInfo, isEditing])

  const onEditClick = async () => {
    try {
      setIsEditing(true)
      setIsFetching(true)

      if (isOldDoc) {
        setTimeout(() => {
          editor?.commands.clearContent()
          editor?.commands.setContent(fileInfo?.file?.content!)
        })
      } else {
        await reinstateDraftEditNodeContents()
        const authenticatedMetadataNode = getAuthenticatedPluginMetadataNode(
          fileInfo?.rtcId
        )

        const entries = Object.entries({ isEdited: true })
        for (const [key, value] of entries) {
          await GunInstance.putGunNodeData(
            authenticatedMetadataNode.get(key),
            value
          )
        }
        const editedDdocContentNode = getEditedDdocContentNode(
          fileInfo?.rtcId,
          fileInfo?.rtcKey
        )
        ydoc.on('update', () => {
          postEditedPluginContent({
            documentKey: fileInfo?.rtcKey,
            rtcId: fileInfo?.rtcId as string,
            setDocStatus: (status) => setDocStatus(status),
            contentNode: editedDdocContentNode,
            ydoc,
          })
        })
      }

      setIsEditorEnabled(true)
      setIsFetching(false)
    } catch (err) {
      console.log(err)
      captureException(err)
    }
  }

  const onCancelClick = async () => {
    try {
      setIsEditorEnabled(false)
      setIsEditing(false)
      setTitle(docName as string)

      setTimeout(() => {
        editor?.commands.setContent(fileInfo?.file?.content!)
      })

      if (fileInfo.rtcId && fileInfo.rtcKey) {
        const draftEditContentNode = getAuthenticatedDocumentContentNode(
          fileInfo?.rtcId,
          fileInfo?.rtcKey
        )
        if (draftEditContentNode)
          await GunInstance.putGunNodeData(draftEditContentNode, null)

        const draftEditAssetNode = getEditedDdocAssetNode(
          fileInfo?.rtcId,
          fileInfo?.rtcKey
        )
        if (draftEditAssetNode)
          await GunInstance.putGunNodeData(draftEditAssetNode, null)
        const authenticatedMetadataNode = getAuthenticatedPluginMetadataNode(
          fileInfo?.rtcId
        )

        const entries = Object.entries({ isPublished: true, isEdited: false })
        for (const [key, value] of entries) {
          await GunInstance.putGunNodeData(
            authenticatedMetadataNode.get(key),
            value
          )
        }
      }
    } catch (err) {
      console.log(err)
      captureException(err)
    }
  }

  const onBackButtonClick = () => {
    if (isOldDoc && isEditing && !isWarningHidden) {
      showWarningPopup()
      return
    }
    navigate(`/${contractAddress}?chainId=${chainId}`)
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    if (isEditing && isOldDoc && !isWarningHidden) {
      window.addEventListener('beforeunload', (e) => {
        e.preventDefault()
        showWarningPopup()
      })

      return () => {
        window.removeEventListener('beforeunload', (e) => {
          e.preventDefault()
          showWarningPopup()
        })
      }
    }
  }, [isOldDoc, isEditing, isWarningHidden])

  const dropdownRef = useRef<HTMLDivElement>(null)
  const toggleCommentsVisibility = () => {
    setShowComments(!showComments)
  }

  useEffect(() => {
    setTimeout(() => {
      editor?.setEditable(isEditing || previewMode)
    })
  }, [isEditing, previewMode])

  useEffect(() => {
    setTimeout(() => {
      if (fileInfo) editor?.commands.setContent(fileInfo.file)
    })
  }, [fileInfo])

  return (
    <div className="h-[100vh] w-[100%] bg-[#f9fbfd]">
      <div className="h-full w-full rounded-md">
        <div className="relative bg-[#ffffff]">
          {moreMenu && (
            <WebPageMoreMenu
              confirmDeleteModal={confirmDeleteModal}
              setIsEditing={setIsEditing}
              fileDownloadName={fileData?.metadata.name}
              filePreviewPage={!isEditing}
              setProofPublishOpen={setProofPublishOpen}
              setIsQrVisible={setIsQrVisible}
              isOwner={isCollaborator}
              isDownloadEnabled={!!downloadUrl}
              setConfirmDeleteModal={setConfirmDeleteModal}
              commentsEnabled={commentsEnabled}
              setCommentsEnabled={setCommentsEnabled}
              pluginName="dDoc"
              moreMenuRef={dropdownRef}
            />
          )}
          <PluginNavbar
            isTitleFieldEnabled={isEditing}
            isPublishLoading={false}
            onEditClick={onEditClick}
            dropdownRef={dropdownRef}
            docStatus={docStatus}
            onLiveCollaborationTrigger={() => null}
            pluginTool={<TiptapToolBar editor={editor as Editor} />}
            portalName={contract?.metadata.name as string}
            setPluginTitle={setTitle}
            pluginTitle={title}
            isPreviewMode={previewMode}
            setPreviewMode={setPreviewMode}
            backButtonAction={onBackButtonClick}
            isUserACollaborator={isCollaborator}
            isEditMode={isEditing}
            setEditMode={setIsEditing}
            isCommentsVisible={showComments}
            toggleQrModalVisibility={() => setIsQrVisible(!isQrVisible)}
            isPublished={!isEditing}
            setIsEditCancelFlag={() => null}
            onCancleClick={onCancelClick}
            isCommentsEnabled={commentsEnabled}
            toggleCommentVisibility={toggleCommentsVisibility}
            toggleMoreMenuVisibility={() => setMoreMenu(!moreMenu)}
            commentCount={commentsAvailable.count}
            publishPlugin={() => null}
            createEditedFile={publishTipTapDocument}
            toggleProvenaceModalVisibility={() =>
              setProofPublishOpen(!proofPublishOpen)
            }
            isCollaborating={false}
            collaborators={null}
            rtcId={fileInfo?.rtcId as string}
            rtcKey={fileInfo?.rtcKey as ISEAPair}
            collaborationDisabled={true}
          />
        </div>

        <div className="h-[92vh] w-full">
          {isNewVersion ? (
            <div
              className={cn('bg-[#f9fbfd]', !isMediaMax1025px && `mt-[2vh]`)}
            >
              <TipTapEditorPreview
                editor={editor as Editor}
                isEditing={isEditorEnabled && !previewMode}
                isFetching={isFetching}
                fileState={fileInfo}
              />
            </div>
          ) : (
            fileInfo && <EditorPreview fileState={fileInfo} />
          )}

          <WebPageCommentSection
            show={showComments}
            setShow={setShowComments}
            chatKey={chatKey}
            fileData={fileData as INewFile}
            setCommentMetadata={(data) =>
              setCommentsAvailable({ ...commentsAvailable, ...data })
            }
            isViewMode={!isCollaborator}
          />
          {/* Provenance Modal */}
          <WebPageProofPublish
            fileData={fileData as INewFile}
            proofPublishOpen={proofPublishOpen}
            setProofPublishOpen={setProofPublishOpen}
          />
          {/* QR Modal */}
          <QRModal
            isQrVisible={isQrVisible}
            copyFileURL={copyFileURL}
            qrRef={qrRef}
            setIsQrVisible={setIsQrVisible}
          />
          <DeletePopUp
            isOpen={confirmDeleteModal}
            setIsOpen={setConfirmDeleteModal}
          />
        </div>
        <LegacyDocWarning
          setOpenWarningPopup={setOpenWarningPopup}
          openWarningPopup={openWarningPopup}
          publish={publishTipTapDocument}
        />
      </div>
    </div>
  )
}
