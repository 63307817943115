import React, { useState } from 'react'
import BrokenImageIcon from '@mui/icons-material/BrokenImage'

export const Image = ({ src }: { src: string }) => {
  const [hasError, setHasError] = useState(false)
  const onImgLoadError = () => {
    setHasError(true)
  }
  if (hasError || !src) return <BrokenImageIcon />
  return <img src={src} onError={onImgLoadError} />
}
