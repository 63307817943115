import axios from 'axios'

interface UnlockGateRequest {
  message: string
  signature: string
  invokerAddress: string
  contractAddress: string
  gateId: string
  chain: number
}

const unlockGateAPI = async ({
  message,
  signature,
  invokerAddress,
  contractAddress,
  gateId,
  chain,
}: UnlockGateRequest) => {
  const URL = `${process.env.REACT_APP_GATE_URL}/gate/unlock`

  return axios.post(
    URL,
    { message, signature, gateId },
    {
      headers: {
        contract: contractAddress,
        invoker: invokerAddress || '',
        chain,
      },
    }
  )
}
export default unlockGateAPI
