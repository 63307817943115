/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as ucans from 'ucans'

export const generateToken = async (
  contractAddress: string,
  secretKey: string
) => {
  const keypair = ucans.EdKeypair.fromSecretKey(secretKey.trim())
  const ucan = await ucans.build({
    audience: process.env.REACT_APP_SERVICE_DID!,
    issuer: keypair,
    lifetimeInSeconds: 7 * 86400,
    capabilities: [
      {
        with: {
          scheme: 'storage',
          hierPart: contractAddress.toLowerCase(),
        },
        can: { namespace: 'file', segments: ['CREATE'] },
      },
    ],
  })
  const token = ucans.encode(ucan)
  return token
}
