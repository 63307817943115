import { captureException } from '@sentry/react'

export class InvalidAddressError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'InvalidAddressError'
  }
}

export class CollaboratorAlreadyExistsError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'CollaboratorAlreadyExistsError'
  }
}

export class ResolveEventError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'ResolveEventError'
    captureException(this)
  }
}

export class RelayerError extends Error {
  constructor(message: string, cause?: unknown) {
    super(message)
    this.name = 'RelayerError'
    captureException(this, {
      extra: { requestParams: JSON.stringify(cause || {}) },
    })
  }
}

export class SwitchNetworkRejected extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'SwitchNetworkRejected'
  }
}

export class GenerateServerKeysError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'GenerateServerKeysError'
    captureException(this)
  }
}

export class InvalidPortalAddressError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'InvalidPortalAddressError'
    captureException(this)
  }
}

export class MissingOrInvalidContractCredentials extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'MissingOrInvalidContractCredentials'
    captureException(this)
  }
}

export class MissingAgentKeyError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'MissingAgentKeyError'
    captureException(this)
  }
}

export class MissingPortalDecryptionKeyError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'MissingPortalDecryptionKeyError'
    captureException(this)
  }
}

export class FileCreationError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'FileCreationError'
    captureException(this)
  }
}

export class InvalidRecoveryJsonError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'InvalidRecoveryJsonError'
  }
}

export class MissingAgentKeyAndAddressError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'MissingAgentKeyAndAddressError'
    captureException(this)
  }
}

export class ArweaveUploadError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'ArweaveUploadError'
    captureException(this)
  }
}

export class GunError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'GunError'
    captureException(this)
  }
}

export class MissingAuthKeyError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'MissingAuthKeyError'
    captureException(this)
  }
}
