/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react'
import RegisterCollaboratorPopup from '../../Popup/RegisterCollaboratorPopup'
import NonCollaboratorPopup from '../../Popup/NonCollaboratorPopup'
import AccountSection from '../AccountSection'
import LogoSection from '../LogoSection'
import useHeaderHook from '../../../hooks/useHeaderHook'
// import NotificationProvider from '../../Notification/NotificationProvider'
import { TextField } from '@fileverse/ui'
import { Search, X } from 'lucide-react'
import {
  changeFileSearchValue,
  clearFileSearchValue,
} from '../../../store/fileSearch/reducer'
import { useAppDispatch } from '../../../store/hooks'
import { useFileSearchValue } from '../../../store/fileSearch/hooks'
import { useParams } from 'react-router-dom'
import { defaultNetwork } from '../../../config/network-config'

const MainHeader = () => {
  const {
    isRegisterCollaboratorPopupVisible,
    registerCollaboratorRef,
    handleDisconnectWallet,
    isNonCollaboratorPoupVisible,
    setNonCollaboratorPopupVisibility,
    setRegisterCollaboratorPopup,
    nonCollaboratorPopupRef,
    isUserOnPortalDashboard,
    setAboutDropDownVisibility,
    aboutDropDownVisibility,
    setAccountDropDownVisibility,
    accountDropDownVisibility,
    headerRef,
  } = useHeaderHook()

  return (
    <div
      ref={headerRef}
      className="flex relative justify-between w-full items-center"
    >
      <LogoSection />
      <SearchFileTextField />
      <AccountSection
        setRegisterCollaboratorPopupVisibility={setRegisterCollaboratorPopup}
        setNonCollaboratorPopupVisibility={setNonCollaboratorPopupVisibility}
        isUserOnPortalDashboard={isUserOnPortalDashboard}
        setAccountDropDown={setAccountDropDownVisibility}
        accountDropDown={accountDropDownVisibility}
        setAboutDropDown={setAboutDropDownVisibility}
        aboutDropDown={aboutDropDownVisibility}
        handleDisconnectWallet={handleDisconnectWallet}
      />

      <NonCollaboratorPopup
        isPopUpActive={isNonCollaboratorPoupVisible}
        setPopupActive={setNonCollaboratorPopupVisibility}
        popupref={nonCollaboratorPopupRef}
      />
      {isRegisterCollaboratorPopupVisible && (
        <RegisterCollaboratorPopup
          isPopUpVisible={isRegisterCollaboratorPopupVisible}
          collaboratorPopUpRef={registerCollaboratorRef}
          setPopupVisibility={setRegisterCollaboratorPopup}
        />
      )}
    </div>
  )
}

export default MainHeader

export const SearchFileTextField = () => {
  const dispatch = useAppDispatch()
  const searchValue = useFileSearchValue()
  const contractAddress = useParams().address as string

  const onTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeFileSearchValue({ value: e.target.value }))
  }

  const clearSearch = () => {
    dispatch(clearFileSearchValue())
  }

  const isHomePage =
    location.hash === `#/${contractAddress}?chainId=${defaultNetwork.chainId}`
  const isFilePage = location.hash.includes('/files')

  useEffect(() => {
    return () => {
      dispatch(clearFileSearchValue())
    }
  }, [])

  if (!isHomePage && !isFilePage) return null
  return (
    <div className="w-[357px] min-h-[40px]">
      <TextField
        value={searchValue}
        onChange={onTextFieldChange}
        placeholder="Search files"
        leftIcon={<Search size={16} />}
        rightIcon={
          searchValue.length ? <X size={16} onClick={clearSearch} /> : null
        }
        className="shadow-none border-[1px]"
      />
    </div>
  )
}
