import { Avatar, LucideIcon } from '@fileverse/ui'
import React from 'react'

export default function Footer() {
  return (
    <footer className="w-full flex gap-4 justify-center items-center py-8">
      <Avatar size={'lg'} />
      <a
        href="https://fileverse.io/"
        target="_blank"
        rel="noopener noreferrer"
        className="text-heading-xsm flex gap-2"
      >
        Powered by Fileverse
        <LucideIcon name="ExternalLink" size={'sm'} fill="none" />
      </a>
    </footer>
  )
}
