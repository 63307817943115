/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from 'react'
import SadLogo from '../../assets/fileverseLogoSad-1.svg'

import {
  Chart as ChartJS,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip as TooltipChartJS,
  SubTitle,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { orgAnalyticsAPI } from '../../api/analytics/orgAnalytics'
import { useParams } from 'react-router'
import {
  useContract,
  useContractChainId,
  useContractCollaborators,
} from '../../store/contract/hooks'
import { useInvokerContractCredentials } from '../../store/invoker/hooks'
import { ICredential } from '../../types/interface/invoker.interface'
import Spinner from '../../components/Spinner'
import { useFilterFiles } from '../../store/files/hooks'
import { useEthersSigner } from '../../hooks/clientToProvider'
import { usePrivyHelper } from '../../hooks/usePrivyHelper'
import { Download, Eye, File, Users } from 'lucide-react'

ChartJS.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  TooltipChartJS,
  SubTitle
)

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom' as const,
    },
  },
  scales: {
    yAxes: {
      ticks: {
        beginAtZero: true,
        precision: 0,
      },
    },
  },
}

const Analytics = () => {
  const { address: contractAddress } = useParams()
  const contract = useContract(contractAddress as string)
  const walletAddress = usePrivyHelper().walletAddress as string
  const contractSigner = useEthersSigner()
  const credential = useInvokerContractCredentials(
    walletAddress as string,
    contractAddress as string
  )

  const [analyticsData, setAnalyticsData] = useState<any>(null)
  const [downloads, setDownloads] = useState(null)
  const [visits, setVisits] = useState(null)
  const [status, setStatus] = useState('pending')
  const portalMembers = useContractCollaborators(contractAddress as string)
  const contractChainId = useContractChainId(contractAddress as string)
  const allFiles = useFilterFiles(contractAddress as string)
  const totalFiles = Object.keys(allFiles).length

  const getOrgAnalytics = async () => {
    setStatus('pending')
    try {
      const res = await orgAnalyticsAPI({
        credential: credential as ICredential,
        contract: contract,
        invoker: walletAddress,
        chain: contractChainId as number,
      })

      setDownloads(res.data.downloads)
      setVisits(res.data.views)

      const viewData = res.data.dataPoints
        .filter((d: any) => d.eventName === 'view')
        .map((d: any) => ({
          y: d.count,
          x: d.dateLabel,
        }))

      const uploadData = res.data.dataPoints
        .filter((d: any) => d.eventName === 'upload')
        .map((d: any) => ({
          y: d.count,
          x: d.dateLabel,
        }))

      setAnalyticsData({
        datasets: [
          {
            label: 'Views',
            data: viewData,
            borderColor: '#FF0000',
            backgroundColor: '#FF0000',
            borderWidth: 2,
            pointRadius: 2,
          },
          {
            label: 'Uploads',
            data: uploadData,
            borderColor: '#3086F6',
            backgroundColor: '#3086F6',
            borderWidth: 2,
            pointRadius: 2,
          },
        ],
      })
      setStatus('ready')
    } catch (e) {
      setStatus('error')
    }
  }

  useEffect(() => {
    if (!contractAddress || !contractSigner) return
    getOrgAnalytics()
  }, [contractSigner, contractAddress])

  return (
    <>
      {status === 'pending' && (
        <div className="flex flex-col gap-2 h-[70vh] justify-center items-center">
          <Spinner />
          <p className="color-text-default text-body-sm-bold">
            Getting your analytics...
          </p>
        </div>
      )}
      {status === 'ready' && (
        <div
          data-cy="analytics-section"
          className="w-full h-full min-h-[680px] max-w-screen-xl no-scrollbar overflow-scroll flex flex-col gap-6 mt-6"
        >
          <div className="flex flex-row justify-center xl:justify-between items-center flex-wrap gap-6">
            <div className="p-6 rounded-lg flex gap-[30px] items-center justify-center color-bg-secondary">
              <div className="w-[120px] flex flex-col justify-start">
                <p className=" text-[48px] font-medium leading-[58px]">
                  {visits === null ? '--' : visits}
                </p>
                <p className="text-heading-xsm">Visits</p>
              </div>
              <Eye fill="None" stroke="#A1AAB1" size={48} />
            </div>
            <div className="p-6 rounded-lg flex gap-[30px] items-center justify-center color-bg-secondary">
              <div className="w-[120px] flex flex-col justify-start">
                <p className=" text-[48px] font-medium leading-[58px]">
                  {downloads === null ? '--' : downloads}
                </p>
                <p className="text-heading-xsm"> Downloads</p>
              </div>
              <Download fill="None" stroke="#A1AAB1" size={48} />
            </div>
            <div className="p-6 rounded-lg flex gap-[30px] items-center justify-center color-bg-secondary">
              <div className="w-[120px] flex flex-col justify-start">
                <p className=" text-[48px] font-medium leading-[58px]">
                  {totalFiles}
                </p>
                <p className="text-heading-xsm">Files</p>
              </div>
              <File fill="None" stroke="#A1AAB1" size={48} />
            </div>
            <div className="p-6 rounded-lg flex gap-[30px] items-center justify-center color-bg-secondary">
              <div className="w-[120px] flex flex-col justify-start">
                <p className=" text-[48px] font-medium leading-[58px]">
                  {portalMembers.length}
                </p>
                <p className="text-heading-xsm">Members</p>
              </div>
              <Users fill="None" stroke="#A1AAB1" size={48} />
            </div>
          </div>

          <div>
            {analyticsData && (
              <Line
                datasetIdKey="id"
                options={options}
                data={analyticsData}
                height={100}
              />
            )}
          </div>
        </div>
      )}
      {status === 'error' && (
        <div className="h-full flex flex-col justify-center items-center">
          <img src={SadLogo} className="w-[200px]" />
          <p className="text-center p-4 font-medium">
            An Error occurred while loading this page. Please try again
          </p>
        </div>
      )}
    </>
  )
}

export default Analytics
