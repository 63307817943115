import isEmpty from 'lodash/isEmpty'
import { IContract } from '../types/interface/contract.interface'
import { IServerKey } from '../types/interface/invoker.interface'

export const checkIfOwnerOrCollaborator = (
  contract: IContract,
  invokerAddress: string,
  serverKeys: IServerKey
) => {
  if (!contract || isEmpty(contract)) {
    return false
  }
  const isOwner = contract.owner === invokerAddress
  if (!serverKeys) return false
  const portalDecryptionKey = serverKeys.portalDecryptionKey
  const isCollaborator =
    contract.collaborators.includes(invokerAddress) && !!portalDecryptionKey
  return isOwner || isCollaborator
}
