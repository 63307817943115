import React, { ReactNode } from 'react'
import cn from 'classnames'
import { Divider } from '@fileverse/ui'

export interface FloatingToolbarProps
  extends React.AllHTMLAttributes<HTMLDivElement> {
  options: ReactNode[][]
  alignment?: 'vertical' | 'horizontal'
}

export const FloatingToolbar = React.forwardRef<
  HTMLDivElement,
  FloatingToolbarProps
>(({ options, alignment = 'vertical', ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={cn(
        'floating-toolbar-tour p-2 color-bg-default flex justify-center items-center gap-4 rounded-xl color-border-default border-[0.5px] shadow-elevation-4',
        { 'flex-col w-[68px] px-6 py-4': alignment === 'horizontal' },
        { 'h-[68px] py-6 px-4': alignment === 'vertical' }
      )}
      {...props}
    >
      {options.map((items, index) => (
        <div
          key={index}
          className={cn('flex justify-center items-center gap-4', {
            'flex-col': alignment === 'horizontal',
          })}
        >
          <div
            className={cn('flex gap-2', {
              'flex-col': alignment === 'horizontal',
            })}
          >
            {items.map((item, index) => (
              <span
                key={index}
                className="min-w-9 min-h-9 hover:color-button-secondary-hover hover:cursor-pointer transition-colors rounded-[4px]"
              >
                {item}
              </span>
            ))}
          </div>
          {index !== options.length - 1 && (
            <Divider direction={alignment} size="lg" />
          )}
        </div>
      ))}
    </div>
  )
})

FloatingToolbar.displayName = 'FloatingToolbar'
