import { createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE = {
  value: '',
} as const

const fileSearchSlice = createSlice({
  name: 'fileSearch',
  initialState: INITIAL_STATE,
  reducers: {
    changeFileSearchValue(state, action) {
      state.value = action.payload.value
    },
    clearFileSearchValue(state) {
      state.value = ''
    },
  },
})

export const { changeFileSearchValue, clearFileSearchValue } =
  fileSearchSlice.actions

export default fileSearchSlice.reducer
