import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  ICredentialInvoker,
  IInvoker,
} from '../../types/interface/invoker.interface'
import {
  IAddServerKeysPayload,
  addInvokerServerKeysReducer,
  updateInvokerCredentialReducer,
} from './reducer'
import { queryIndexDb } from '../middleware/utils'
import {
  addInvokerCredentialsToIndexedDb,
  addInvokerServerKeysToIndexDb,
} from '../middleware/actionHandlers'

// As indexdb updates are async in nature,
// we need call an async thunk to update the store
// making sure that the updates are in order
export const addInvokerCredentialsThunk = createAsyncThunk(
  'invoker/addInvokerCredential',
  async (payload: ICredentialInvoker, { dispatch }) => {
    const invokerIdbState = await queryIndexDb(
      'invoker',
      payload.invokerAddress
    )
    await addInvokerCredentialsToIndexedDb(invokerIdbState as IInvoker, payload)
    dispatch(updateInvokerCredentialReducer(payload))
    return payload
  }
)

export const addInvokerServerKeysThunk = createAsyncThunk(
  'invoker/addInvokerServerKeys',
  async (payload: IAddServerKeysPayload, { dispatch }) => {
    const { invokerAddress, contractAddress } = payload
    const invokerIdbState = await queryIndexDb('invoker', invokerAddress)
    const iDBPayload = {
      portalEncryptionKey: payload.portalEncryptionKey,
      portalDecryptionKey: payload.portalDecryptionKey,
      memberEncryptionKey: payload.memberEncryptionKey,
      memberDecryptionKey: payload.memberDecryptionKey,
      ownerEncryptionKey: payload.ownerEncryptionKey,
      ownerDecryptionKey: payload.ownerDecryptionKey,
      portalGunKey: payload.portalGunKey,
      agentKey: payload.agentKey,
      invokerAddress,
      contractAddress,
    }
    await addInvokerServerKeysToIndexDb(invokerIdbState as IInvoker, iDBPayload)
    dispatch(addInvokerServerKeysReducer(iDBPayload))
  }
)
