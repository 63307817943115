import React, { useRef, useState } from 'react'
import FileView from '../FileView'
import TokenGatedFileOverLay from './TokenGatedFileOverLay'
import DisconnectedWalletOverlay from './DisconnectedWalletOverlay'
import { FileSource, FileTypeEnum } from '../../../types/enum/file.enum'
import { useNavigate, useParams } from 'react-router-dom'
import { useFileData } from '../../../store/files/hooks'
import ConnectedWalletOverlay from './ConnectedWalletOverlay'
import { INewFile } from '../../../types/interface/file.interface'
import { useContract, useContractChainId } from '../../../store/contract/hooks'
import useQRCode from '../../../hooks/useQRCode'
import { QRModal } from '../../Popup/QRModal'
import { checkIfOwnerOrCollaborator } from '../../../utils/checkIfOwnerOrCollaborator'
import WebPageProofPublish from '../../WebPages/WebPageProofPublish'
import { WebPageMoreMenu } from '../../WebPages/WebPageMoreMenu'
import PluginNavbar from '../../Navbars/PluginNavbar'
import { DocStatus } from '../../ExcalidrawCanvas/SavingStatusUI'
import { useServerKeys } from '../../../store/invoker/hooks'
import { usePrivyHelper } from '../../../hooks/usePrivyHelper'

const PrivateFileAuthenicationOverLay = ({
  gatedFilePreviewHandler,
}: {
  gatedFilePreviewHandler: (fileData: INewFile) => Promise<void>
}) => {
  const { address: contractAddress, fileId } = useParams()
  const walletAddress = usePrivyHelper().walletAddress as string
  const contract = useContract(contractAddress as string)
  const fileData = useFileData(contractAddress as string, fileId)
  const chainId = useContractChainId(contractAddress as string)
  const isDPage = fileData?.metadata.source === FileSource.DPAGE
  const fileDisplayName = fileData?.metadata.name || ''
  const [moreMenu, setMoreMenu] = useState<boolean>(false)
  const [commentsEnabled, setCommentsEnabled] = useState<boolean>(false)
  const [proofPublishOpen, setProofPublishOpen] = useState<boolean>(false)
  const [showComments, setShowComments] = useState<boolean>(false)
  const [commentsAvailable] = useState({
    available: false,
    count: 0,
  })
  const { qrRef, isQrVisible, setIsQrVisible } = useQRCode(false)
  const copyFileURL = `${window.location.href}`
  const serverKeys = useServerKeys(
    walletAddress as string,
    contractAddress as string
  )
  const isCollaborator = checkIfOwnerOrCollaborator(
    contract,
    walletAddress as string,
    serverKeys
  )
  const dropdownRef = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()

  const handleBackButtonClick = async () => {
    if (!isCollaborator) {
      navigate(`/${contractAddress}/member?chainId=${chainId}`)
      return
    }

    navigate(`/${contractAddress}/files?chainId=${chainId}`)
  }

  return (
    <div className="w-full h-full">
      <div className="h-[68px] relative bg-[#ffffff]">
        {moreMenu && (
          <WebPageMoreMenu
            confirmDeleteModal={false}
            setIsEditing={() => null}
            fileDownloadName={fileData?.metadata.name}
            filePreviewPage={false}
            setProofPublishOpen={setProofPublishOpen}
            setIsQrVisible={setIsQrVisible}
            isOwner={isCollaborator}
            isDownloadEnabled={false}
            setConfirmDeleteModal={() => null}
            commentsEnabled={commentsEnabled}
            setCommentsEnabled={setCommentsEnabled}
            moreMenuRef={dropdownRef}
          />
        )}
        <PluginNavbar
          isTitleFieldEnabled={false}
          isLocked={true}
          dropdownRef={dropdownRef}
          docStatus={DocStatus.SAVED}
          onLiveCollaborationTrigger={() => null}
          pluginTool={<></>}
          portalName={contract?.metadata.name as string}
          setPluginTitle={() => null}
          pluginTitle={fileDisplayName}
          isPreviewMode={true}
          setPreviewMode={() => null}
          backButtonAction={handleBackButtonClick}
          isUserACollaborator={isCollaborator}
          isEditMode={false}
          setEditMode={() => null}
          isCommentsVisible={showComments}
          toggleQrModalVisibility={() => setIsQrVisible(!isQrVisible)}
          isPublished={true}
          setIsEditCancelFlag={() => null}
          isPublishLoading={false} // fix the name to be isLoading
          isCommentsEnabled={commentsEnabled}
          toggleCommentVisibility={() => setShowComments(!showComments)}
          toggleMoreMenuVisibility={() => setMoreMenu(!moreMenu)}
          commentCount={commentsAvailable.count}
          publishPlugin={() => null}
          toggleProvenaceModalVisibility={() =>
            setProofPublishOpen(!proofPublishOpen)
          }
          isCollaborating={false}
          collaborators={null}
        />
      </div>
      <div className="w-full h-[92vh] justify-center flex">
        <div className={`w-screen ${isDPage && 'h-screen object-cover'}`}>
          <FileView
            metadata={fileData?.metadata}
            isEditing={false}
            fileLocked={true}
            downloadUrl={''}
            isCollaborator={isCollaborator}
            signerAddress={walletAddress}
            viewTokenGatedFile={gatedFilePreviewHandler}
          />
        </div>
      </div>

      {isDPage && (
        <section
          className={`absolute top-0 bottom-0 backdrop-brightness-50 justify-center 
          flex backdrop-blur-md ${
            isDPage ? 'h-[100vh]' : ''
          } items-center  w-full z-[99]`}
        >
          {fileData?.metadata.fileType === FileTypeEnum.GATED ? (
            <TokenGatedFileOverLay
              handleGatedFilePreview={gatedFilePreviewHandler}
            />
          ) : walletAddress ? (
            <ConnectedWalletOverlay />
          ) : (
            <DisconnectedWalletOverlay />
          )}
        </section>
      )}
      {/* Provenance Modal */}
      <WebPageProofPublish
        fileData={fileData as INewFile}
        proofPublishOpen={proofPublishOpen}
        setProofPublishOpen={setProofPublishOpen}
      />
      {/* QR Modal */}
      <QRModal
        isQrVisible={isQrVisible}
        copyFileURL={copyFileURL}
        qrRef={qrRef}
        setIsQrVisible={setIsQrVisible}
      />
    </div>
  )
}

export default PrivateFileAuthenicationOverLay
