import React from 'react'
import { CustomButton, ICustomButtonProps } from '../../../../CustomButton'

export const UploadModalButton = ({
  children,
  ...restProps
}: ICustomButtonProps) => {
  return (
    <CustomButton customCss="w-[145px] h-[45px]" {...restProps}>
      <div className="flex gap-[12px]">{children}</div>
    </CustomButton>
  )
}
