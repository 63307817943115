/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { SetStateAction, useEffect, useRef, useState } from 'react'

// Third-party library imports
import { Editor } from '@tiptap/react'
import { JSONContent } from '@tiptap/core'
import { ISEAPair, SEA } from 'gun'
import { OutputData } from '@editorjs/editorjs'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { toUint8Array } from 'js-base64'
import * as Y from 'yjs'

// Local component imports
import Spinner from '../Spinner'
import WebPageViewer from './WebPageViewer'
import WebPageViewerV2 from './WebPageViewerV2'
import { WebPageCover } from './WebPageCover'

// Local hooks imports
import { useContract } from '../../store/contract/hooks'
import { useServerKeys } from '../../store/invoker/hooks'

// Local utility imports
import { getISEAKeyPair } from '../../utils/libCrypto'
import { MakeFileFromObject } from '../../utils/makeFileFromObject'
import { postEditedPluginContent } from '../TipTap/useEditorHook'

// Local type imports
import { IDraft } from '../../types/interface/drafts.interface'
import { IFileMetaData } from '../../types/interface/file.interface'
import { IDpageAsset, IPluginFileData } from '../../utils/collaboration/rtc'

import { useGunNodes } from '../../hooks/useGunNode'
import {
  editDraftName,
  removeDraft,
  updatePluginMetadata,
} from '../../utils/collaboration/utils'
import { checkIfOwnerOrCollaborator } from '../../utils/checkIfOwnerOrCollaborator'
import { WebPageMoreMenu } from './WebPageMoreMenu'
import PluginNavbar from '../Navbars/PluginNavbar'
import { DocStatus } from '../ExcalidrawCanvas/SavingStatusUI'
import TiptapToolBar from '../TipTap/TiptapToolBar'
import WebPageToolbar from './WebPageToolbar'
import { GunInstance } from '../../utils/instantiateGun'
import { captureException } from '@sentry/react'
import { usePrivyHelper } from '../../hooks/usePrivyHelper'
import { DeletePopUp } from './DeletePopUp'

interface WebPageEditPageProps {
  fileData: {
    cover: string
    emoji: string
    webpageName: string
    editorData: JSONContent
    commentsEnabled: boolean
    indexEnabled: boolean
    portalName: string
    metadata: IFileMetaData
    contentIPFSHash: string
    rtcId: string
    rtcKey: string
    rtcData: IDraft
    setIsEditing: React.Dispatch<SetStateAction<boolean>>
    setCanceledEdit: React.Dispatch<SetStateAction<boolean>>
    setConfirmDeleteModal: React.Dispatch<SetStateAction<boolean>>
    version: number
    confirmDeleteModal: boolean
  }
}

export const WebPageEditPage = ({ fileData }: WebPageEditPageProps) => {
  const { address: contractAddress, fileId } = useParams()
  const [urlParams] = useSearchParams()
  const chainId = parseInt(urlParams.get('chainId') || '')
  const walletAddress = usePrivyHelper().walletAddress
  const invoker = walletAddress as string
  const [commentsEnabled, setCommentsEnabled] = useState<boolean>(
    fileData.commentsEnabled
  )
  const [indexEnabled, setIndexEnabled] = useState<boolean>(
    fileData.indexEnabled
  )
  const [cover, setCover] = useState<string>(fileData.cover)
  const [editorData, setEditorData] = useState<
    OutputData | JSONContent | undefined
  >(undefined)
  const [isFetching, setIsFetching] = useState<boolean>(true)
  const [previewMode, setPreviewMode] = useState<boolean>(false)
  const [settingCover, setSettingCover] = useState<boolean>(false)
  const [draftAssetData, setDraftAssetData] = useState<IDpageAsset>({
    name: fileData.webpageName,
    emoji: fileData.emoji,
    coverIPFSHash: fileData.metadata.coverIPFSHash,
    fileId: fileId!,
  })
  const [tiptapEditor, setTiptapEditor] = useState<Editor>()
  const serverKeys = useServerKeys(invoker, contractAddress as string)
  const [draftName, setDraftName] = useState<string>(draftAssetData?.name || '')
  const dropdownRef = useRef<HTMLDivElement>(null)
  const [moreMenu, setMoreMenu] = useState<boolean>(false)
  const contract = useContract(contractAddress as string)
  const portalName = contract?.metadata?.name
  const isCollaborator = checkIfOwnerOrCollaborator(
    contract,
    invoker,
    serverKeys
  )
  const authKey = getISEAKeyPair(serverKeys.portalGunKey) as ISEAPair
  const newVersion = fileData?.version === 2
  const [ydoc] = useState(new Y.Doc())
  const [docStatus, setDocStatus] = useState<DocStatus>(DocStatus.SAVED)
  const { getEditedDpageContentNode, getAuthenticatedPluginMetadataNode } =
    useGunNodes()
  const navigate = useNavigate()

  const publishWebPageV2 = () => {
    const editorData = tiptapEditor?.getJSON()

    const { name, emoji, coverIPFSHash } = draftAssetData

    const data = {
      webpageName: name,
      coverIPFSHash,
      emoji,
      editorData,
      source: 'fileverse_dPage',
      commentsEnabled,
      indexEnabled,
      rtcId: fileData.rtcId,
      rtcData: fileData.rtcData,
      rtcKey: fileData.rtcKey,
      version: fileData.version,
    }
    const file = MakeFileFromObject(data, `${name || 'untitled'}`)
    return file
  }

  const publishWebPage = () => {
    const { name, emoji, coverIPFSHash } = draftAssetData
    const data = {
      webpageName: name,
      coverIPFSHash,
      emoji,
      editorData,
      source: 'fileverse_dPage',
      commentsEnabled,
      rtcId: fileData.rtcId,
      rtcData: fileData.rtcData,
      rtcKey: fileData.rtcKey,
      version: 0,
    }
    const file = MakeFileFromObject(data, `${name || 'untitled'}`)
    return file
  }

  const publishDPage = () => {
    let file
    if (newVersion) {
      file = publishWebPageV2()
    } else {
      file = publishWebPage()
    }
    return file
  }

  const reinstateDraftEditNodeContents = async () => {
    try {
      const draftEditContentNode = getEditedDpageContentNode(fileData.rtcId)
      const draftEditAssetNode = getAuthenticatedPluginMetadataNode(
        fileData.rtcId
      )
      if (!authKey) return
      const draftAsset = await Promise.race([
        new Promise((resolve) => {
          draftEditAssetNode.on((data: IPluginFileData) => {
            if (data) {
              resolve(data)
              draftEditAssetNode.off()
            }
          })
        }),
        new Promise((resolve) =>
          setTimeout(() => {
            resolve(undefined)
            draftEditAssetNode.off()
          }, 5000)
        ),
      ])
      if (draftAsset) {
        const _draftAsset = draftAsset as IDraft

        setDraftAssetData({
          name: _draftAsset.name || fileData.webpageName,
          emoji: _draftAsset.emoji || fileData.emoji,
          coverIPFSHash:
            _draftAsset.coverIPFSHash || fileData.metadata.coverIPFSHash,
          fileId: fileId as string,
        })
      }
      const draftEditContent = await Promise.race([
        new Promise((resolve) => {
          draftEditContentNode?.on((data: IPluginFileData) => {
            if (data) {
              resolve(data)
            }
          })
        }),
        new Promise((resolve) => setTimeout(() => resolve(undefined), 5000)),
      ])

      if (!draftEditContent) {
        setEditorData(fileData.editorData)
        setTimeout(() => {
          tiptapEditor?.commands.setContent(fileData.editorData?.content!)
        })
        setIsFetching(false)
      } else {
        const rtcContent = (draftEditContent as IPluginFileData)?.content
        const decryptedData = await SEA.decrypt(
          rtcContent,
          getISEAKeyPair(fileData.rtcKey as string) as ISEAPair
        )
        const contents = toUint8Array(decryptedData)
        Y.applyUpdate(ydoc, contents)
        setIsFetching(false)
      }
    } catch (err) {
      captureException(err)
    }
  }

  const saveDraftAsset = async () => {
    const draftEditAssetNode = getAuthenticatedPluginMetadataNode(
      fileData.rtcId
    )
    const entries = Object.entries(draftAssetData)

    for (const [key, value] of entries) {
      try {
        const node = draftEditAssetNode.get(key)
        await GunInstance.putGunNodeData(node, value)
      } catch (err) {
        console.error(err)
      }
    }
  }

  const handleBackButtonClick = () => {
    // TODO: add check to not delete draft with contents not only text e.g images
    if (tiptapEditor?.getText().trim().length === 0) {
      removeDraft(
        getAuthenticatedPluginMetadataNode(fileData?.rtcId as string),
        fileData?.rtcId as string
      )
      navigate(`/${contractAddress}?chainId=${chainId}`)
      return
    }
    if (isCollaborator) {
      editDraftName(
        draftAssetData.name,
        getAuthenticatedPluginMetadataNode(fileData?.rtcId as string)
      )
      navigate(`/${contractAddress}/dPages?chainId=${chainId}`)
    } else {
      navigate(`/${contractAddress}/member?chainId=${chainId}`)
    }
  }

  useEffect(() => {
    // save only new draft asset to gun
    if (
      fileData.webpageName !== draftAssetData.name ||
      fileData.metadata.coverIPFSHash !== draftAssetData.coverIPFSHash ||
      fileData.emoji !== draftAssetData.emoji
    ) {
      saveDraftAsset()
    }
  }, [draftAssetData])

  useEffect(() => {
    if (!fileData.rtcId) return
    reinstateDraftEditNodeContents()
    try {
      updatePluginMetadata(
        { isEdited: true, fileId },
        getAuthenticatedPluginMetadataNode(fileData.rtcId)
      )
    } catch (err) {
      captureException(err)
    }
  }, [fileData, tiptapEditor])

  useEffect(() => {
    if (!fileData?.rtcId) return
    try {
      const draftEditContentNode = getEditedDpageContentNode(fileData.rtcId)
      ydoc.on('update', () => {
        postEditedPluginContent({
          documentKey: getISEAKeyPair(fileData?.rtcKey) as ISEAPair,
          contentNode: draftEditContentNode,
          rtcId: fileData.rtcId as string,
          setDocStatus: (status) => setDocStatus(status),
          ydoc,
        })
      })
    } catch (err) {
      captureException(err)
    }
  }, [])

  return (
    <div
      data-cy="single-webpage"
      className="h-full flex flex-col overflow-scroll no-scrollbar w-full"
    >
      <div className="h-full flex flex-col overflow-scroll no-scrollbar">
        <>
          <div className="h-fit relative bg-[#ffffff]">
            {moreMenu && (
              <WebPageMoreMenu
                confirmDeleteModal={fileData.confirmDeleteModal}
                indexEnabled={indexEnabled}
                commentsEnabled={commentsEnabled}
                setCommentsEnabled={setCommentsEnabled}
                setIndexEnabled={setIndexEnabled}
                isDPage={true}
                fileDownloadName={draftAssetData.name}
                filePreviewPage={false}
                setIsEditing={fileData.setIsEditing}
                setConfirmDeleteModal={fileData.setConfirmDeleteModal}
                isOwner={isCollaborator}
                moreMenuRef={dropdownRef}
              />
            )}
            <PluginNavbar
              isTitleFieldEnabled={true}
              dropdownRef={dropdownRef}
              docStatus={docStatus}
              onLiveCollaborationTrigger={() => null}
              pluginTool={
                newVersion ? (
                  <TiptapToolBar editor={tiptapEditor as Editor} />
                ) : (
                  <WebPageToolbar previewMode={previewMode} />
                )
              }
              portalName={portalName}
              pluginTitle={draftName}
              isPreviewMode={previewMode}
              setPreviewMode={setPreviewMode}
              backButtonAction={handleBackButtonClick}
              isUserACollaborator={isCollaborator}
              isEditMode={true}
              setEditMode={fileData.setIsEditing}
              setIsEditCancelFlag={fileData.setCanceledEdit}
              isPublishLoading={false} // fix the name to be isLoading
              isCommentsEnabled={commentsEnabled}
              toggleCommentVisibility={() => null}
              toggleQrModalVisibility={() => null}
              isCommentsVisible={false}
              toggleMoreMenuVisibility={() => setMoreMenu(!moreMenu)}
              toggleProvenaceModalVisibility={() => null}
              commentCount={0}
              publishPlugin={() => null}
              createEditedFile={publishDPage}
              isPublished={false}
              setPluginTitle={setDraftName}
              isCollaborating={false}
              collaborators={null}
              isDisabled={settingCover}
              rtcId={fileData.rtcId}
              rtcKey={getISEAKeyPair(fileData.rtcKey as string) as ISEAPair}
              dPageInfo={{
                emoji: draftAssetData.emoji as string,
                coverIPFSHash: draftAssetData.coverIPFSHash as string,
              }}
              collaborationDisabled={true}
            />
          </div>

          <main className="h-full w-full mt-[68px] rounded-[8px] flex flex-col justify-start items-center gap-2">
            <WebPageCover
              cover={cover}
              setCover={setCover}
              previewMode={previewMode}
              emoji={draftAssetData.emoji}
              authKey={authKey}
              setDraftAssetData={setDraftAssetData}
              rtcId={fileData.rtcId}
              rtcData={fileData.rtcData}
              webpageName={draftName}
              setDraftName={setDraftName}
              setSettingCover={setSettingCover}
              settingCover={settingCover}
              coverIPFSHash={draftAssetData.coverIPFSHash}
            />

            {isFetching ? (
              <Spinner />
            ) : newVersion ? (
              <WebPageViewerV2
                isEditing={!previewMode}
                hasToC={indexEnabled}
                setEditor={setTiptapEditor}
                editorData={editorData}
                ydoc={ydoc}
              />
            ) : (
              <WebPageViewer
                rtcId={fileData.rtcId}
                isEditing={previewMode ? false : true}
                data={editorData as OutputData}
                setEditorData={setEditorData}
                documentKey={fileData.rtcKey}
                rtcData={fileData.rtcData}
              />
            )}
          </main>

          <DeletePopUp
            isOpen={fileData.confirmDeleteModal}
            setIsOpen={fileData.setConfirmDeleteModal}
          />
        </>
      </div>
    </div>
  )
}
