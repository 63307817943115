export const DELETE_FILE_METADATA = {
  metadataIpfsHash:
    'bafybeify3xbts44jrrcidno7gxqs5fyvf5rbx3zkncnbjaibejjetvqtqe/metadata',
  contentIpfsHash:
    'bafybeie34k3a6k2kpswsqtg6iuts6tvqv4kx2sliqbwmvpdovbe4yowfiy/deletedFile.json',
  gateIpfsHash: '',
  fileType: 0,
  version: 0,
  metadata: {
    name: 'deletedFile.json',
    size: 23,
    mimeType: 'application/json',
    extension: 'json',
    version: 0,
    fileType: 0,
    source: 0,
    tokenLock: null,
    ownerLock: null,
    portalLock: null,
    memberLock: null,
    publicLock: null,
    owner: '',
    isDeleted: true,
  },
}
