/* eslint-disable @typescript-eslint/no-explicit-any */
import { OutputData } from '@editorjs/editorjs'
import { Content } from '@tiptap/core'
import { IGunChain, IGunInstance, ISEAPair, SEA } from 'gun'
import { GunInstance } from '../../utils/instantiateGun'

type EditorGunNode = IGunChain<any, IGunInstance<any>, IGunInstance<any>, any>

export const saveEditorContentOnGun = async (
  outputData: OutputData | Content,
  draftContentNode: EditorGunNode,
  id: string,
  rtcKey: ISEAPair
) => {
  try {
    const timestamp = Date.now()
    const encryptedData = await SEA.encrypt(outputData, rtcKey)
    const data = {
      content: encryptedData,
      by: id,
      timestamp,
    }
    await GunInstance.putGunNodeData(draftContentNode, data)
  } catch (err) {
    console.error(err)
  }
}
