import React, { useState } from 'react'
import { Popup } from '../Popup/PopUp'
import CTAButton from '../common/CTAButton'
import { useParams } from 'react-router-dom'
import { useContract } from '../../store/contract/hooks'
import { checkIfOwnerOrCollaborator } from '../../utils/checkIfOwnerOrCollaborator'
import { useServerKeys } from '../../store/invoker/hooks'
import { usePrivyHelper } from '../../hooks/usePrivyHelper'

const GetStartedPopup = () => {
  const { address: contractAddress } = useParams()
  const walletAddress = usePrivyHelper().walletAddress
  const contract = useContract(contractAddress as string)
  const serverKeys = useServerKeys(
    walletAddress as string,
    contractAddress as string
  )
  const isCollaborator = checkIfOwnerOrCollaborator(
    contract,
    walletAddress as string,
    serverKeys
  )
  const [isOpened, setOpened] = useState(!isCollaborator)
  return (
    <Popup isOpen={isOpened} width={'650px'}>
      <div className="h-full gap-4 bg-white py-12 p-4 rounded-lg flex flex-col gap">
        <h1 className="text-center text-2xl font-semibold">
          You are on {contract?.metadata?.name || 'Fileverse portal'} {'<3'}
        </h1>
        <div className="w-full justify-center items-center flex flex-col gap-14">
          <p className="w-full text-center text-lg ">
            Fileverse uses end-to-end encryption and no centralised servers.
            What you add on this dDoc will stay private between you and anyone
            that has access to this link. Not even Fileverse can see what you’re
            adding.
          </p>
          <div className="w-full flex justify-center">
            <CTAButton
              backgroundColorClassName={
                'bg-black text-white border-black border-2'
              }
              onClick={() => setOpened(false)}
              title="Get started"
            />
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default GetStartedPopup
