/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  useContract,
  useContractCollaborators,
} from '../../store/contract/hooks'
import { useAppDispatch } from '../../store/hooks'
import { saveCollaboratorsList } from '../../store/contract/reducer'
import { getSafeOwners, isSafeApp } from '../../utils/safeApp'
import { LucideIcon, TextField } from '@fileverse/ui'
import { Button } from '../PublicPortal/components/Button'
import { Search } from 'lucide-react'
import PeopleTable, { PeopleData } from '../../components/People/PeopleTable'
import { usePortalAgentAddress } from '../../store/invoker/hooks'
import InviteCollaborator from '../../components/People/InviteCollaborator'
import { getCollaboratorsInfo } from '../../utils/subgraph/getCollaboratorsInfo'
import {
  AccountBlock,
  getLatestCollaboratorInfo,
} from '../../utils/subgraph/getLatestCollaboratorInfo'
import { getReadOnlyContract } from '../../utils/contract'
import portalAbi from '../../contract/portalAbi.json'
import { usePrivyHelper } from '../../hooks/usePrivyHelper'

const People = () => {
  const urlParams = useParams()
  const contractAddress = urlParams.address as string
  const allCollaborators = useContractCollaborators(contractAddress)
  const dispatch = useAppDispatch()
  const contract = useContract(contractAddress)
  const agentAddress = usePortalAgentAddress(contractAddress)
  const { walletAddress } = usePrivyHelper()
  const isOwner = walletAddress === contract.owner

  const [peopleData, setPeopleData] = useState<PeopleData[]>([])
  const [displayData, setDisplayData] = useState<PeopleData[]>([])
  const [openInvitePopup, setOpenInvitePopup] = useState<boolean>(false)

  const [searchMember, setSeachMember] = useState<string>('')
  const [isSafe, setSafeApp] = useState(true)
  const [allSafeOwners, setAllSafeOwners] = useState<string[]>([])
  const [allCollaboratorsInfo, setAllCollaboratosInfo] = useState<
    AccountBlock[]
  >([])

  const initializeSafeOwners = async () => {
    const _safeOwners = await getSafeOwners()
    setAllSafeOwners([..._safeOwners])
  }
  useEffect(() => {
    initializeSafeOwners()
  }, [isSafe])

  const checkIfSafeApp = async () => {
    const _isSafeApp = await isSafeApp()
    setSafeApp(_isSafeApp)
  }

  const fetchAndSetAllPeople = () => {
    let allPeople: PeopleData[]

    const safeOwnerList = allSafeOwners.map((item: any) => {
      return {
        address: item,
        role: 'Safe Owner',
        joinedOn: 0,
      }
    })

    const ownerList = [
      {
        address: contract.owner.toLowerCase(),
        role: 'Owner',
        joinedOn: 0,
      },
    ]

    allPeople = allCollaboratorsInfo.map((item) => {
      if (item.account === agentAddress.toLowerCase())
        return {
          address: item.account,
          role: 'Agent',
          joinedOn: Number(item.blockTimestamp) * 1000,
        }
      if (allSafeOwners.includes(item.account))
        return {
          address: item.account,
          role: 'delete',
          joinedOn: Number(item.blockTimestamp) * 1000,
        }
      return {
        address: item.account,
        role: 'Collaborator',
        joinedOn: Number(item.blockTimestamp) * 1000,
      }
    })

    const _allPeople = allPeople.filter((item) => item.role !== 'delete')

    allPeople = [..._allPeople, ...safeOwnerList, ...ownerList]

    setPeopleData([...allPeople])
    setDisplayData([...allPeople])
  }

  useEffect(() => {
    if (!allCollaboratorsInfo && !allSafeOwners) return
    fetchAndSetAllPeople()
    checkIfSafeApp()
  }, [allCollaboratorsInfo, allSafeOwners])

  useEffect(() => {
    setDisplayData(peopleData)

    if (searchMember === '') return

    setDisplayData((prev) =>
      prev.filter((item) => item.address.includes(searchMember))
    )
  }, [searchMember])

  useEffect(() => {
    setDisplayData(peopleData)
  }, [peopleData])

  const getCollaboratorsInfoFromSubgraph = async () => {
    const collaboratorInfo = await getCollaboratorsInfo(
      contractAddress,
      allCollaborators
    )
    const latestCollaboratoInfo = getLatestCollaboratorInfo(
      collaboratorInfo.data.addedCollaborators
    )
    setAllCollaboratosInfo(latestCollaboratoInfo)
  }

  useEffect(() => {
    getCollaboratorsInfoFromSubgraph()
  }, [allCollaborators])

  const queryAndUpdatePortalCollaborators = async () => {
    const portalInstance = getReadOnlyContract(
      contractAddress as string,
      portalAbi
    )
    const portalCollaborators = await portalInstance.getCollaborators()
    dispatch(
      saveCollaboratorsList({
        contractAddress: contractAddress as string,
        collaborators: portalCollaborators,
      })
    )
  }

  useEffect(() => {
    queryAndUpdatePortalCollaborators()
  }, [])

  return (
    <div
      data-cy="people-section"
      className="w-full h-full min-h-[680px] no-scrollbar overflow-scroll flex flex-col gap-8"
    >
      <div className="flex flex-col sm:flex-row items-start justify-between sm:items-center w-full pt-4 gap-4 sm:gap-0">
        <h4 className="text-heading-xlg-bold">Collaborators</h4>
        <div className="flex gap-2">
          <TextField
            value={searchMember}
            onChange={(e) => setSeachMember(e.target.value)}
            placeholder="Search members"
            leftIcon={<Search size={16} />}
            className="h-[40px] min-w-[240px] w-[240px] shadow-none border-[1px]"
          />
          {isOwner && (
            <Button
              size="lg"
              className="min-w-[140px] w-[140px] flex gap-3"
              onClick={() => setOpenInvitePopup(true)}
            >
              <LucideIcon name="Plus" fill="White" stroke="White" size="sm" />
              Invite
            </Button>
          )}
        </div>
      </div>
      <div className="w-full">
        {peopleData.length !== 0 && (
          <PeopleTable
            peopleData={displayData}
            setPeopleData={setPeopleData}
            allCollaborators={allCollaborators}
            isSafeApp={isSafe}
          />
        )}
      </div>
      <InviteCollaborator
        openInvitePopup={openInvitePopup}
        setOpenInvitePopup={setOpenInvitePopup}
        allCollaborators={allCollaborators}
        setPeopleData={setPeopleData}
        isSafeApp={isSafe}
      />
    </div>
  )
}
export default People
