import React, { useRef } from 'react'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogClose,
} from './Dialog'
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerFooter,
  DrawerClose,
} from './Drawer'
import { useMediaQuery, useOnClickOutside } from 'usehooks-ts'
import { Button } from './Button'
import cn from 'classnames'

interface DynamicModalProps {
  open: boolean
  onOpenChange: (open: boolean) => void
  title: string
  description?: string
  content?: React.ReactNode
  primaryAction?: {
    label: string
    onClick?: () => void
    variant?: 'default' | 'secondary' | 'ghost' | 'danger'
    className?: string
    isLoading?: boolean
  }
  secondaryAction?: {
    label: string
    onClick?: () => void
    variant?: 'default' | 'secondary' | 'ghost' | 'danger'
    className?: string
    isLoading?: boolean
  }
  className?: string
  contentClassName?: string
  disableOutsideClick?: boolean
}

export const DynamicModal = ({
  open,
  onOpenChange,
  title,
  description,
  content,
  primaryAction,
  secondaryAction,
  className,
  contentClassName,
  disableOutsideClick,
}: DynamicModalProps) => {
  const isMobile = useMediaQuery('(max-width: 768px)')
  const modalRef = useRef<HTMLDivElement>(null)

  useOnClickOutside(modalRef, () => {
    if (disableOutsideClick) return
    onOpenChange(false)
  })

  if (isMobile) {
    return (
      <Drawer open={open} onOpenChange={onOpenChange}>
        <DrawerContent ref={modalRef} className="w-full">
          <DrawerHeader className="px-6 pt-0">
            <DrawerTitle className="text-left text-heading-md">
              {title}
            </DrawerTitle>
          </DrawerHeader>
          <div
            className={cn(
              'flex flex-col gap-4 w-full h-full border-t-[1px] text-body-sm color-text-default',
              {
                'px-6 pt-6 pb-2': !contentClassName,
              },
              contentClassName
            )}
          >
            {description && <p>{description}</p>}
            {content && content}
          </div>
          <DrawerFooter className="px-5 pb-5 gap-2 w-full">
            {secondaryAction && (
              <DrawerClose asChild>
                <Button
                  disabled={secondaryAction?.isLoading}
                  isLoading={secondaryAction?.isLoading}
                  className={secondaryAction?.className}
                  variant={secondaryAction?.variant || 'ghost'}
                  onClick={secondaryAction?.onClick}
                >
                  {secondaryAction?.label}
                </Button>
              </DrawerClose>
            )}
            {primaryAction && (
              <Button
                disabled={primaryAction?.isLoading}
                isLoading={primaryAction?.isLoading}
                className={primaryAction?.className}
                variant={primaryAction?.variant || 'default'}
                onClick={primaryAction?.onClick}
              >
                {primaryAction?.label}
              </Button>
            )}
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    )
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent ref={modalRef} className={cn('', className)}>
        <DialogHeader className="px-6 pt-6">
          <DialogTitle className="text-heading-md">{title}</DialogTitle>
        </DialogHeader>
        <div
          className={cn(
            'flex flex-col gap-4 w-full h-full border-t-[1px] text-body-sm color-text-default',
            {
              'px-6 pt-6 pb-2': !contentClassName,
            },
            contentClassName
          )}
        >
          {description && <p>{description}</p>}
          {content && content}
        </div>
        <DialogFooter className="px-5 pb-5 gap-2">
          {secondaryAction && (
            <DialogClose asChild>
              <Button
                disabled={secondaryAction?.isLoading}
                isLoading={secondaryAction?.isLoading}
                className={secondaryAction?.className}
                variant={secondaryAction?.variant || 'ghost'}
                onClick={secondaryAction?.onClick}
              >
                {secondaryAction?.label}
              </Button>
            </DialogClose>
          )}
          {primaryAction && (
            <Button
              disabled={primaryAction?.isLoading}
              isLoading={primaryAction?.isLoading}
              className={primaryAction?.className}
              variant={primaryAction?.variant || 'default'}
              onClick={primaryAction?.onClick}
            >
              {primaryAction?.label}
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
