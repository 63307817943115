/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useRef, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import {
  useContract,
  useContractChainId,
  useContractCollaborators,
  usePortalSignlessMode,
} from '../../store/contract/hooks'
import { IContractMetadata } from '../../types/interface/contract.interface'
import { uploadFileAPI } from '../../api/fileAPI/uploadFile'
import {
  updateContractMetadataState,
  updatePortalMetadataIPFSUrl,
} from '../../store/contract/reducer'
import {
  useAgentKey,
  useInvokerContractCredentials,
  useInvokerCredentialsAndKeys,
  usePortalAgentAddress,
} from '../../store/invoker/hooks'
import { ICredential } from '../../types/interface/invoker.interface'
import { MakeFileFromObject } from '../../utils/makeFileFromObject'
import { setPortalSignlessMode } from '../../store/contract/reducer'
import sendNotifcation from '../../utils/notification'
import { getLogoUrl } from '../../utils/getLogoUrl'
import useComponentVisibilty from '../../hooks/useVisibility'
import { ViewKeyModal } from '../../components/onboarding/ViewKeyModal'
import { defaultNetwork } from '../../config/network-config'
import { downloadKeyFile } from '../../utils/dowloadSecretKey'
import { PORTAL_NAME_LIMIT } from '../../utils/constants'
import { useAppDispatch } from '../../store/hooks'
import { TaskID } from '../../components/Tasks/TasksProvider'
import { useTasksContext } from '../../hooks/useTasksContext'
import { hasAgent } from '../../utils/agentHandler'
import { updatePortalMetadataCall } from '../../utils/transaction'
import { validateOrSwitchNetwork } from '../../utils/ethUtils'
import { AllowedChainId } from '../../config/wagmi-config'
import { ActivateSignlessModal } from '../../components/UploadComponents/ActivateSignlessModal'
import { usePrivyHelper } from '../../hooks/usePrivyHelper'
import { Button } from '../PublicPortal/components/Button'
import { Tooltip } from '../PublicPortal/components/Tooltip'
import { InfoIcon } from 'lucide-react'
import {
  Avatar,
  ButtonGroup,
  LucideIcon,
  TextField,
  Toggle,
} from '@fileverse/ui'
import cn from 'classnames'
import { uploadSingleFileToIpfs } from '../../store/files/thunks'
import { getPublicFileUrl } from '../../utils/files/filePreviewHandlers'
import { DynamicModal } from '../PublicPortal/components/DynamicModal'

const Setting = () => {
  interface Image {
    previewUrl: string
    file: File | null
  }
  const {
    isComponentVisible: isSecretScreenActive,
    setIsComponentVisible: setSecretScreenActive,
  } = useComponentVisibilty(false)
  const urlParams = useParams()
  const contractAddress = urlParams.address as string
  const contract = useContract(contractAddress)
  const walletAddress = usePrivyHelper().walletAddress
  const [searchParams] = useSearchParams()
  const chainId = parseInt(searchParams.get('chainId') || '')
  const invokerAddress = walletAddress || ''
  const ImageInput = useRef<HTMLInputElement>(null)
  const [portalNameLimit, setPortalNameLimit] = useState(25)
  const [isEdit, setEdit] = useState(false)
  const [logo, setLogo] = useState<Image>()
  const [cover, setCover] = useState<Image>()
  const [saving, setSaving] = useState(false)
  const invoker = walletAddress as string
  const [showDownloadKeyModal, setShowDownloadKeyModal] = useState(false)
  const dispatch = useAppDispatch()
  const [contractLogo, setContractLogo] = useState<string | undefined>('')
  const [, setSignlessEnabled] = useState<boolean>(false)
  const contractChainId = useContractChainId(contractAddress)
  const [isAvatarLoading, setIsAvatarLoading] = useState<boolean>(false)

  const isSignless = usePortalSignlessMode(contractAddress as string)
  const secretFileData = useInvokerCredentialsAndKeys(
    invokerAddress,
    contractAddress as string
  )
  const portal = useContract(contractAddress as string)
  const portalCollaborators = useContractCollaborators(
    contractAddress as string
  )
  const agentKey = useAgentKey(
    walletAddress as string,
    contractAddress as string
  )
  const agentAddress = usePortalAgentAddress(contractAddress as string)
  const [showSignlessPopup, setShowSignlessPopup] = useState(false)
  const tasksContext = useTasksContext()

  const credential = useInvokerContractCredentials(
    invoker,
    contractAddress as string
  ) as ICredential
  const [newContractMetaData, setNewContractMeta] = useState<IContractMetadata>(
    contract?.metadata
  )
  const isOwner = contract.owner === invoker
  const checkSignless = async () => {
    const portalHasAgent = await hasAgent(contractAddress as string)
    setSignlessEnabled(portalHasAgent)
  }

  const updateMetadataEventHandler = async (
    hash: string,
    metadata: IContractMetadata
  ) => {
    setEdit(false)

    dispatch(
      updateContractMetadataState({
        metadata,
        contractAddress,
      })
    )
    dispatch(
      updatePortalMetadataIPFSUrl({
        contractAddress: contractAddress,
        metadataLastWorkingIPFSUrl: '',
      })
    )
    setSaving(false)
  }
  const saveNewMetaData = async () => {
    if (
      newContractMetaData.name.trim() === '' ||
      newContractMetaData.description.trim() === ''
    ) {
      sendNotifcation(
        'Unable to Save Contract Metadata',
        `Cannot keep Portal Name or Description empty`,
        'danger'
      )
      return
    }
    try {
      setSaving(true)
      await validateOrSwitchNetwork(defaultNetwork.chainId as AllowedChainId)
      const metadata = { ...newContractMetaData }
      if (logo?.file) {
        const fileResponse = await uploadFileAPI({
          credential,
          file: logo.file,
          name: logo.file.name,
          contractAddress,
          invoker: invoker,
          chain: contractChainId as number,
        })
        metadata.logo = fileResponse.data.ipfsHash
      }
      if (cover?.file) {
        const fileResponse = await uploadFileAPI({
          credential,
          file: cover.file,
          name: cover.file.name,
          contractAddress,
          invoker,
          chain: contractChainId as number,
        })
        metadata.cover = fileResponse.data.ipfsHash
      }
      const metadataFile = MakeFileFromObject(metadata, 'metadata.json')
      const fileResponse = await uploadFileAPI({
        credential,
        file: metadataFile,
        name: 'metadata.json',
        contractAddress,
        invoker,
        chain: contractChainId as number,
      })

      const { txHash: hash } = await updatePortalMetadataCall({
        contractAddress: contractAddress as string,
        walletAddress: walletAddress as string,
        metadataIpfsHash: fileResponse.data.ipfsHash,
      })
      updateMetadataEventHandler(hash, metadata)
      sendNotifcation(
        'Setting Updated',
        'Portal setting has been updated successfully',
        'info'
      )
    } catch (error: any) {
      setSaving(false)
      setLogo({
        file: null,
        previewUrl: '',
      })
      setCover({
        file: null,
        previewUrl: '',
      })
      console.log(error)
      sendNotifcation(
        'Error Updating Portal',
        error?.response?.statusText || 'Error updating portal setting',
        'danger'
      )
    }
  }

  const getLogo = async () => {
    const logoUrl = await getLogoUrl(contract.metadata.logo)
    if (logoUrl) {
      setContractLogo(logoUrl)
    }
  }

  const handleSignlessToggle = () => {
    if (
      !agentKey ||
      !agentAddress ||
      !portalCollaborators.includes(agentAddress)
    ) {
      if (!isOwner) {
        sendNotifcation(
          'Only Owner can enable Signless',
          'Please contact with the Owner of this Portal for enabling Signless',
          'info',
          10000
        )
        return
      }
      setShowSignlessPopup(true)
    } else {
      dispatch(
        setPortalSignlessMode({
          contractAddress: contractAddress as string,
          enabled: !isSignless,
        })
      )
    }
  }
  const handleKeysDownload = () => {
    downloadKeyFile({
      secretFileData,
      contractAddress,
      portalName: portal.metadata.name,
    })
    if (!tasksContext?.isDownloadedKeys) {
      tasksContext?.markTaskAsDone({
        taskId: TaskID.PRIVATE_KEY_DOWNLOAD,
        name: 'Download your private keys',
      })
    }
  }

  const handleCharacterLimitChange = (
    enterValue: string,
    limitType: string
  ) => {
    if (limitType === 'portalName') {
      setPortalNameLimit(PORTAL_NAME_LIMIT - enterValue.length)
    }
    // else {
    //   setPortalDescriptionLimit(PORTAL_DES_LIMIT - enterValue.length)
    // }
  }

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      ImageInput?.current?.click()
      const files = event.target.files as FileList
      if (files && files[0]) {
        setIsAvatarLoading(true)

        setLogo({
          previewUrl: URL.createObjectURL(files[0]),
          file: files[0],
        })
        const uploadedAvatarIPFSHash = await dispatch(
          uploadSingleFileToIpfs({
            credential,
            file: files[0],
            fileName: `logo`,
            contractAddress: contractAddress as string,
            invoker: walletAddress as string,
            chainId,
          })
        ).unwrap()

        const fileUrl = await getPublicFileUrl(uploadedAvatarIPFSHash)
        setContractLogo(fileUrl.ipfsUrl)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsAvatarLoading(false)
      !isEdit && setEdit(true)
      event.target.value = ''
    }
  }

  const onCompleteSignless = () => {
    setShowSignlessPopup(false)
  }

  useEffect(() => {
    getLogo()
    // getCover()
  }, [])

  useEffect(() => {
    checkSignless()
    setPortalNameLimit(PORTAL_NAME_LIMIT - contract?.metadata?.name.length)
    // setPortalDescriptionLimit(
    //   PORTAL_DES_LIMIT - contract?.metadata?.description.length
    // )
  }, [contract])

  return (
    <div
      data-cy="setting-section"
      className="w-full h-full min-h-[680px] no-scrollbar overflow-scroll flex flex-col gap-8"
    >
      {/* Settings Title and Save Button */}
      <div className="flex justify-between items-center w-full pt-4">
        <h4 className="text-heading-xlg-bold">Settings</h4>
        <Button
          disabled={!isOwner || saving}
          isLoading={saving}
          size="lg"
          onClick={() => {
            isEdit && saveNewMetaData()
          }}
          className="min-w-[140px] w-[140px]"
        >
          Save Changes
        </Button>
      </div>
      {/* General Portal Settings */}
      <div className="flex flex-col lg:flex-row gap-6">
        <div className="inline-flex min-w-[262px] gap-1 items-start">
          <h6 className="text-heading-sm-bold">General portal settings</h6>
          <Tooltip text="These edits are only visible to you and your collaborators.">
            <InfoIcon size="16px" className="text-[#77818A] mt-1" />
          </Tooltip>
        </div>
        <div className="flex flex-col gap-4 max-w-screen-md w-full">
          <div className="inline-flex gap-6 items-center">
            <div className="relative rounded-full w-fit pointer-events-auto">
              {isAvatarLoading && (
                <div className="absolute inset-0 color-utility-overlay flex justify-center items-center rounded-full">
                  <LucideIcon
                    name="LoaderCircle"
                    size={'md'}
                    className="animate-spin"
                    fill="transparent"
                    stroke={'white'}
                  />
                </div>
              )}
              <Avatar
                src={contractLogo}
                size={'xl'}
                bordered={'border'}
                className=""
                onError={() => setContractLogo('')}
              />
              <input
                ref={ImageInput}
                className="hidden absolute"
                type="file"
                accept="image/*"
                onChange={(e) => handleImageChange(e)}
              />
            </div>
            <ButtonGroup>
              <Button
                disabled={!isOwner}
                size="md"
                onClick={(e: any) => handleImageChange(e)}
                variant="secondary"
              >
                Upload new picture
              </Button>
              <Button
                size="md"
                variant="ghost"
                className={cn('min-w-fit color-text-danger', {
                  'hover:text-muted cursor-not-allowed':
                    !isOwner || !contractLogo,
                })}
                disabled={!isOwner || !contractLogo}
                onClick={() => {
                  setContractLogo('')
                  setNewContractMeta((prev) => {
                    return {
                      ...prev,
                      logo: '',
                    }
                  })
                  !isEdit && setEdit(true)
                }}
              >
                Delete
              </Button>
            </ButtonGroup>
          </div>
          <TextField
            label="Portal name"
            placeholder="Portal Name"
            disabled={!isOwner}
            defaultValue={newContractMetaData.name}
            isValid={portalNameLimit < 25 && portalNameLimit > 0}
            onChange={(e) => {
              handleCharacterLimitChange(e.target.value, 'portalName')
              setNewContractMeta((prev) => {
                return {
                  ...prev,
                  name: e.target.value,
                }
              })
              !isEdit && setEdit(true)
            }}
            message={
              portalNameLimit < 25 && portalNameLimit > 0
                ? ''
                : 'Portal name should be between 1 and 25 characters'
            }
            className="w-full shadow-none border-[1px]"
          />
        </div>
      </div>
      {/* Social profiles */}
      <div className="flex flex-col lg:flex-row gap-6">
        <div className="inline-flex min-w-[262px] gap-1 items-start">
          <h6 className="text-heading-sm-bold">Social profiles</h6>
          <Tooltip
            position="bottom"
            text="Social media handles will be displayed on your public Portal."
          >
            <InfoIcon size="16px" className="text-[#77818A] mt-1" />
          </Tooltip>
        </div>
        <div className="flex flex-col gap-4 max-w-screen-md w-full">
          <TextField
            disabled={!isOwner}
            label="X"
            placeholder="x.com/username"
            value={newContractMetaData.socials.twitter}
            onChange={(e) => {
              setNewContractMeta((prev) => {
                return {
                  ...prev,
                  socials: {
                    ...prev.socials,
                    twitter: e.target.value,
                  },
                }
              })
              !isEdit && setEdit(true)
            }}
            className="w-full shadow-none border-[1px]"
          />
          <TextField
            disabled={!isOwner}
            label="Warpcast"
            placeholder="warpcast.com/username"
            value={newContractMetaData.socials.warpcast}
            onChange={(e) => {
              setNewContractMeta((prev) => {
                return {
                  ...prev,
                  socials: {
                    ...prev.socials,
                    warpcast: e.target.value,
                  },
                }
              })
              !isEdit && setEdit(true)
            }}
            className="w-full shadow-none border-[1px]"
          />
          <TextField
            disabled={!isOwner}
            label="Lens"
            placeholder="hey.xyz/username, orb.club/username, phaver.com/username"
            value={newContractMetaData.socials.lens}
            onChange={(e) => {
              setNewContractMeta((prev) => {
                return {
                  ...prev,
                  socials: {
                    ...prev.socials,
                    lens: e.target.value,
                  },
                }
              })
              !isEdit && setEdit(true)
            }}
            className="w-full shadow-none border-[1px]"
          />
          <TextField
            disabled={!isOwner}
            label="Link"
            placeholder="https://"
            value={newContractMetaData.socials.link}
            onChange={(e) => {
              setNewContractMeta((prev) => {
                return {
                  ...prev,
                  socials: {
                    ...prev.socials,
                    link: e.target.value,
                  },
                }
              })
              !isEdit && setEdit(true)
            }}
            className="w-full shadow-none border-[1px]"
          />
        </div>
      </div>
      {/* Security and privacy */}
      <div className="flex flex-col lg:flex-row gap-6">
        <div className="inline-flex min-w-[262px] gap-1 items-start">
          <h6 className="text-heading-sm-bold">Security and privacy</h6>
        </div>
        <div className="flex flex-col w-full max-w-screen-md gap-5">
          <div className="w-full flex justify-between items-center">
            <div className="flex flex-col justify-start items-start gap-1">
              <p className="text-heading-sm">Signless transactions</p>
              <p className="text-body-sm color-text-secondary">
                Signless transactions for admins
              </p>
            </div>
            <Toggle
              checked={isSignless}
              onCheckedChange={handleSignlessToggle}
            />
          </div>
          <div className="w-full px-4 py-2 flex justify-between items-center color-bg-brand-light rounded">
            <div className="inline-flex gap-1">
              <p className="text-heading-sm">Backup key</p>
              <Tooltip
                position="right"
                beakAlignment="end"
                text="End-to-End Encryption is a vital privacy protection. Your Backup keys are used for privacy & self-sovereignty by design."
              >
                <InfoIcon size="16px" className="text-[#77818A]" />
              </Tooltip>
            </div>
            <ButtonGroup>
              <Button
                onClick={() => {
                  setSecretScreenActive(true)
                }}
                className="min-w-fit"
                variant="ghost"
              >
                View
              </Button>
              <Button
                onClick={() => setShowDownloadKeyModal(true)}
                variant="secondary"
                className="min-w-fit bg-transparent"
              >
                Download
              </Button>
            </ButtonGroup>
          </div>
        </div>
      </div>
      <ViewKeyModal
        fileData={secretFileData}
        setSecretScreenActive={setSecretScreenActive}
        isSecretScreenActive={isSecretScreenActive}
      />
      <ActivateSignlessModal
        onCompleteSignless={onCompleteSignless}
        isOpen={showSignlessPopup}
      />
      <DynamicModal
        className="max-w-[600px]"
        open={showDownloadKeyModal}
        onOpenChange={setShowDownloadKeyModal}
        title="Download Backup Key"
        content={
          <div className="flex gap-2 items-start w-full h-full">
            <span className="w-6">
              <InfoIcon size="24px" className="color-text-danger" />
            </span>
            <span className="text-body-sm">
              This Backup Key allows your account to be end-to-end encrypted
              (not even Fileverse will be able to see what you come up with).
              Also, if you lose access to your Fileverse account, you can use
              your Backup Key to recover all your content!
            </span>
          </div>
        }
        primaryAction={{
          label: 'Download',
          onClick: handleKeysDownload,
          className: 'w-full md:min-w-[114px] md:w-[114px] h-[40px]',
        }}
        secondaryAction={{
          label: 'Cancel',
          onClick: () => setShowDownloadKeyModal(false),
          className: 'w-full md:min-w-[114px] md:w-[114px] h-[40px]',
        }}
      />
    </div>
  )
}

export default Setting
