/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { FileStatus } from '../../types/enum/file.enum'
import FilePageReadyState from './FilePageReadyState'
import PrivateFileAuthenicationOverLay from './Overlays/PrivateFileAuthenicationOverLay'
import useFilePreviewHelper from './useFilePreviewHelper'
import { JsonRpcSigner } from 'ethers'
import { IFileChatKeyPair } from '../../types/interface/file.interface'
import { AnimatedLoader } from '../../pages/PublicPortal/components/Loader'

const FilePageDesktopView = () => {
  const {
    fileChatKey,
    fileStatus,
    signer,
    setFileStatus,
    setRecallFileData,
    downloadUrl,
    viewTokenGatedFile,
  } = useFilePreviewHelper()

  return (
    <div className="w-full flex items-center h-full" data-cy="file-preview">
      {fileStatus === FileStatus.PENDING && (
        <div className={`w-full flex justify-center h-screen items-center`}>
          <AnimatedLoader />
        </div>
      )}
      {fileStatus === FileStatus.ERROR && (
        <div className="w-full flex justify-center">
          Oops! an error occured. Try reloading this page
        </div>
      )}
      {(fileStatus === FileStatus.DISCONNECTED ||
        fileStatus === FileStatus.LOCKED) && (
        <PrivateFileAuthenicationOverLay
          gatedFilePreviewHandler={viewTokenGatedFile}
        />
      )}
      {fileStatus === FileStatus.READY && (
        <FilePageReadyState
          setFileStatus={setFileStatus}
          downloadUrl={downloadUrl}
          signer={signer as JsonRpcSigner}
          setRecallFileData={setRecallFileData}
          fileChatKey={fileChatKey as IFileChatKeyPair}
          viewTokenGatedFile={viewTokenGatedFile}
        />
      )}
    </div>
  )
}

export default FilePageDesktopView
