/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react'
import {
  rectSwappingStrategy,
  SortableContext,
  useSortable,
} from '@dnd-kit/sortable'
import { useDndContext } from '@dnd-kit/core'
import { CSS } from '@dnd-kit/utilities'
import cn from 'classnames'
import { Box } from '@radix-ui/themes'

import { ButtonGroup, GridLayout, Icon, LayoutModes } from '@fileverse/ui'
import { IPortalSectionProps } from '../interfaces'
import { PortalListItem } from './PortalListItem'
import { PortalCardItem } from './PortalCardItem'
import { Section } from './Section'
import { usePublicPortalContext } from '../../../providers/PublicPortalProvider'
import { useMediaQuery } from 'usehooks-ts'
import { useTour } from '@reactour/tour'
import { PortalCard } from './Card/PortalCard'
import {
  // AnimatePresence,
  motion,
} from 'framer-motion'
import { useParams } from 'react-router-dom'
import { Button } from './Button'
import HeartBitProvider from './HeartBit/HeartBitProvider'
import { useFilterFiles } from '../../../store/files/hooks'
import { CardControlWrapper } from './CardControlWrapper'

export const PortalSection = ({
  section,
  className,
  sectionPos,
  disableSwitching,
}: IPortalSectionProps) => {
  const {
    isViewMode,
    onSectionTitleChange,
    onSectionLayoutChange,
    onRemoveSection,
    addTextFile,
    setShowAddFilesModal,
    setActiveSectionIdx,
    activeSectionIdx,
    sections,
  } = usePublicPortalContext()

  const { currentStep, isOpen } = useTour()

  const { id, title, layout, files } = section
  const { address: contractAddress } = useParams()
  const allFiles = useFilterFiles(contractAddress as string)

  const { setNodeRef, transform, transition, isDragging } = useSortable({
    id: id,
    data: {
      type: 'section',
      sectionId: id,
      sectionPos,
    },
    transition: {
      duration: 350,
      easing: 'cubic-bezier(0.18, 0.67, 0.6, 1.22)',
    },
  })

  const onTitleChange = (title: string) => {
    onSectionTitleChange(sectionPos, title)
  }

  const onLayoutChange = (layout: LayoutModes) => {
    if (disableSwitching) return
    onSectionLayoutChange(sectionPos, layout)
  }

  const handleUploadClick = () => {
    setShowAddFilesModal(true)
  }

  const onRemoveSectionClick = () => {
    onRemoveSection(sectionPos)
  }

  const CardTemplate = ({
    isOpen,
    sectionPos,
  }: {
    isOpen: boolean
    sectionPos: number
  }) => {
    if (isOpen) {
      return (
        <div className="card-tour">
          <CardControlWrapper defaultSize={'sm'} deleteFunction={() => null}>
            {(size) => (
              <PortalCard
                size={size}
                type={'dDoc'}
                title={'dDoc Public File'}
                url={''}
                likeCount={0}
                metadata={{}}
                classNames="h-[208px]"
              />
            )}
          </CardControlWrapper>
        </div>
      )
    }
    return (
      <Box
        gridColumn={{
          initial: 'span 4',
          md: 'span 2',
        }}
        gridRow={{
          initial: 'span 2',
          md: 'span 2',
        }}
        style={{
          minHeight: '208px',
        }}
        className="w-full h-full"
      >
        <motion.div
          initial={false}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.8, opacity: 0 }}
          layout={false}
          transition={{
            type: 'spring',
            damping: 20,
            stiffness: 200,
            duration: 0.5,
          }}
          className="w-full h-full flex flex-col justify-between items-start p-5 rounded-xl border-dashed border-2 color-border-default"
        >
          <p className="text-body-sm color-text-secondary">
            {!allFiles.length && (
              <>
                No public files or content added yet. <br />
              </>
            )}{' '}
            Click below to get started.
          </p>
          <ButtonGroup className="flex-col gap-3 space-x-0">
            <Button
              className="flex items-center gap-2 justify-start py-0 px-2"
              variant="ghost"
              onClick={handleUploadClick}
            >
              <Icon name="Upload" variant="ghost" size="md" fill="black" />
              <span className="text-sm font-medium leading-none">
                Add public file
              </span>
            </Button>
            <Button
              className="flex items-center gap-2 justify-start py-0 px-2"
              variant="ghost"
              onClick={() => addTextFile(sectionPos)}
            >
              <Icon name="Type" variant="ghost" size="md" fill="black" />
              <span className="text-sm font-medium leading-none">
                Add public note
              </span>
            </Button>
          </ButtonGroup>
        </motion.div>
      </Box>
    )
  }

  // set active section index to the previous item when the last item is removed
  useEffect(() => {
    if (sections.length === 0) return
    if (activeSectionIdx > sections.length - 1) {
      setActiveSectionIdx(sections.length - 1)
    }
  }, [sections])

  return (
    <Section
      ref={setNodeRef}
      id={id as string}
      title={title}
      setTitle={onTitleChange}
      currentLayoutMode={layout}
      setCurrentLayoutMode={onLayoutChange}
      viewMode={isViewMode}
      style={{
        transition,
        transform: CSS.Translate.toString(transform),
      }}
      isDragging={isDragging}
      sectionpos={sectionPos}
      onRemoveSection={onRemoveSectionClick}
      className={cn('relative select-none', className)}
      disabled={disableSwitching}
    >
      {isDragging ? (
        <div className="w-full h-[35vh] color-bg-default-hover rounded-xl shadow-inner" />
      ) : layout === 'LIST' ? (
        <PortalListItem files={files} />
      ) : (
        <GridLayout
          className="gap-4"
          columns={{
            initial: 'repeat(4, minmax(70px, 1fr))',
            sm: 'repeat(6, minmax(70px, 1fr))',
            md: 'repeat(auto-fill, minmax(156px, 1fr))',
            lg: 'repeat(auto-fill, minmax(172px, 1fr))',
            xl: 'repeat(auto-fill, minmax(208px, 1fr))',
          }}
        >
          {files?.length === 0 && !isViewMode ? (
            <CardTemplate
              isOpen={currentStep === 2 && isOpen}
              sectionPos={sectionPos}
            />
          ) : (
            <SortableContext
              items={files?.map((item: any) => item.id)}
              strategy={rectSwappingStrategy}
              disabled={isViewMode}
            >
              {/* <AnimatePresence mode="popLayout"> */}
              {files?.map((item: any, index) => {
                return (
                  <HeartBitProvider key={item.id}>
                    <PortalCardItem {...item} sectionId={id} itemPos={index} />
                  </HeartBitProvider>
                )
              })}
              {/* </AnimatePresence> */}
            </SortableContext>
          )}
        </GridLayout>
      )}
    </Section>
  )
}

const renderSortableItemOverlay = (item: any, index: number) => {
  return (
    <PortalCardItem
      key={index}
      id={item.id}
      title={item.title}
      metadata={item.metadata}
      size={'sm'}
      type={item.type}
      likeCount={item.likeCount}
      sectionId={item.id}
      itemPos={index}
    />
  )
}

export const PortalSectionOverlay = () => {
  const { sections } = usePublicPortalContext()
  const isMobile = useMediaQuery('(max-width: 767px)')
  const isTabletOrLarger = useMediaQuery('(min-width: 768px)')
  const { active } = useDndContext()

  if (!active) return null
  const { id, data } = active
  if (data?.current?.type !== 'section') return null

  const sectionPos = data?.current?.sectionPos || 0
  const currentSectionProps = sections[sectionPos]
  const { files, layout } = currentSectionProps
  const itemsToShowOnMobile = files.slice(0, 2) // assuming 2 items per row
  const itemsToShowOnTablet = files.slice(0, 5) // assuming 6 items per row

  return layout === 'CARD' ? (
    <Section
      id={id}
      title={currentSectionProps.title}
      viewMode={true}
      section={currentSectionProps}
      className="border-dashed color-border-default border-2 h-auto xl:h-full"
    >
      <GridLayout
        className="gap-4"
        columns={{
          initial: 'repeat(4, minmax(70px, 1fr))',
          sm: 'repeat(6, minmax(70px, 1fr))',
          md: 'repeat(auto-fill, minmax(156px, 1fr))',
          lg: 'repeat(auto-fill, minmax(172px, 1fr))',
          xl: 'repeat(auto-fill, minmax(208px, 1fr))',
        }}
      >
        {isMobile && itemsToShowOnMobile.map(renderSortableItemOverlay)}
        {isTabletOrLarger && itemsToShowOnTablet.map(renderSortableItemOverlay)}
        {files.length > (isMobile ? 2 : 5) && (
          <Box
            className="w-full h-full cursor-grab"
            gridColumn={{
              initial: 'span 2',
              md: 'span 1',
            }}
            gridRow={{
              initial: 'span 2',
              md: 'span 2',
            }}
            style={{
              minHeight: '212px',
            }}
          >
            <div className="h-full w-full font-bold rounded-xl color-bg-default-hover flex flex-col justify-center items-center">
              +{files.length - (isMobile ? 2 : 5)} more
            </div>
          </Box>
        )}
      </GridLayout>
    </Section>
  ) : (
    <PortalSection
      section={currentSectionProps}
      className="border-dashed color-border-default border-2 h-auto xl:h-full"
      sectionPos={sectionPos}
    />
  )
}
