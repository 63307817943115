/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { useDialogStore } from './utils'
import { HeartBitUI } from '@fileverse/heartbit-react'
import { SetStateAction, useState } from 'react'
import { usePrivyHelper } from '../../../../hooks/usePrivyHelper'
import { Button } from '../Button'

interface HeartBitButtonPairProps {
  heartBitUiRef: React.RefObject<any>
  toggleConnectCTA: () => void
  isLoading: boolean
  setMinitingInProcess?: React.Dispatch<SetStateAction<boolean>>
}

export const HeartBitButtonPair = (props: HeartBitButtonPairProps) => {
  const { heartBitUiRef, toggleConnectCTA, isLoading, setMinitingInProcess } =
    props

  const { setDialogState, dialogState } = useDialogStore()

  const [isFilling, toggleFilling] = useState(false)
  const walletAddress = usePrivyHelper().walletAddress as string

  const { isLoading: isPrivyLoading } = usePrivyHelper()
  const onMouseDown = (e?: any) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    toggleFilling(true)
    const date = new Date()
    setDialogState({
      ...dialogState,
      startTime: date.getTime(),
    })
  }

  const onMouseUp = (e?: any) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    setMinitingInProcess && setMinitingInProcess(true)
    if (!walletAddress) toggleConnectCTA()
    toggleFilling(false)
    const date = new Date()
    setDialogState({
      ...dialogState,
      endTime: date.getTime(),
    })
  }

  return (
    <div className="flex flex-col gap-8 items-center mt-4 justify-center">
      <HeartBitUI
        scale={9}
        ref={heartBitUiRef}
        startFilling={isFilling}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        isDisabled={isLoading || isPrivyLoading}
        fillInterval={500}
      />
      <Button
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onTouchStart={onMouseDown}
        onTouchEnd={onMouseUp}
        onContextMenu={(e: any) => e.preventDefault()}
        className="w-full h-[40px]"
        isLoading={isLoading || isPrivyLoading}
      >
        {isLoading || isPrivyLoading
          ? 'Sending onchain love'
          : isFilling
          ? 'Keep holding to send onchain LOVE'
          : 'Press and hold to send onchain LOVE'}
      </Button>
    </div>
  )
}
