/* eslint-disable @typescript-eslint/no-explicit-any */
import isEmpty from 'lodash/isEmpty'
import createGateApi from '../../api/gateapi/createGateApi'
import unlockGateAPI from '../../api/gateapi/unlockGateApi'
import { defaultNetwork } from '../../config/network-config'
import { TOKEN_FILE_MESSAGE } from '../../constants'
import { getInvokerStateFromIndexDb } from '../../store/middleware/utils'
import { FileTypeEnum } from '../../types/enum/file.enum'
import { IServerKey } from '../../types/interface/invoker.interface'
import { TokenGateHashInfo } from '../../types/interface/token.interface'
import { FileCreationError } from '../errors'
import { requestSignature } from '../messages/requestSignature'
import { FileMetadataFactory } from '../metadata'
import { getNetworkId } from '../networks/getNetworkId'
import { uploadSingleFileToIPFS } from '../uploadUtills'
import { JSONContent, type Editor } from '@tiptap/react'

export const createPublicFile = async (
  owner: string,
  file: File,
  fileArgs = {}
) => {
  try {
    const publicFile = await FileMetadataFactory.getPublicFileMetadata(
      file,
      owner,
      fileArgs
    )

    return {
      ...publicFile,
    }
  } catch (error) {
    console.log(error)
    throw new FileCreationError('Unable to create public file')
  }
}

export const createCollaboratorFile = async (
  owner: string,
  file: File,
  serverKeys: IServerKey,
  fileArgs = {}
) => {
  try {
    const collabFile = await FileMetadataFactory.getCollaboratorFileMetadata(
      file,
      owner,
      serverKeys,
      fileArgs
    )
    return {
      ...collabFile,
    }
  } catch (error) {
    console.log(error)
    throw new FileCreationError('Unable to create collaborators private file')
  }
}

export const createMemberFile = async (
  owner: string,
  file: File,
  serverKeys: IServerKey,
  fileArgs = {}
) => {
  try {
    const memberFile = await FileMetadataFactory.getMemberFileMetadata(
      file,
      owner,
      serverKeys,
      fileArgs
    )
    return {
      ...memberFile,
    }
  } catch (err) {
    console.log(err)
    throw new FileCreationError('Unable to create member file')
  }
}
// TODO Refactor this function
export const createTokenGatedFile = async (
  owner: string,
  file: File,
  serverKeys: IServerKey,
  portalAddress: string,
  fileArgs = <any>{}
) => {
  try {
    const {
      tokenType,
      contractAddress,
      chain,
      id,
      name,
      tokenAmount,
      decimals = 18,
    } = fileArgs
    const min = Math.pow(10, decimals) * Number(tokenAmount)
    const tokenChainId = getNetworkId(chain)
    const tokenId = tokenType === 'erc1155' ? id : '*'
    const amount = tokenType === 'erc20' ? Math.ceil(min) : tokenAmount
    const tokenParam = `${tokenChainId}:${tokenType}:${contractAddress}:${tokenId}:${amount}:*`
    const params = [tokenParam]

    const { invokers } = await getInvokerStateFromIndexDb()

    const credential = invokers[owner]?.credentials[portalAddress]

    const createGateResponse = await createGateApi({
      params,
      credentialEditSecret: credential.editSecret,
      contractAddress: portalAddress,
      invoker: owner,
      includeCollaborators: true,
      chain: defaultNetwork.chainId,
    })

    const { gateId, gateKey, params: responseParams } = createGateResponse.data
    const gate: TokenGateHashInfo = {
      gateId,
      params: responseParams,
      image: createGateResponse.data?.image || '',
      name: createGateResponse.data?.name || name || '',
      address: contractAddress,
      amount: tokenAmount,
      chain,
    }

    const gateFile = FileMetadataFactory.getGateFile(gate)
    const gateIpfsHash = await uploadSingleFileToIPFS({
      contentFile: gateFile,
      fileName: 'gate.json',
      contractAddress: portalAddress,
      invoker: owner,
    })
    const gatedMetadataFile = await FileMetadataFactory.getGatedFileMetadata(
      file,
      owner,
      serverKeys,
      gateKey,
      fileArgs
    )

    return {
      ...gatedMetadataFile,
      gateIpfsHash,
    }
  } catch (err) {
    throw new FileCreationError('Unable to create token gated file')
  }
}
export const editTokenGatedFile = async (
  owner: string,
  file: File,
  serverKeys: IServerKey,
  portalAddress: string,
  fileArgs = <any>{}
) => {
  try {
    const { tokenDetails } = fileArgs
    if (isEmpty(tokenDetails)) {
      throw new Error('token details cannot be empty')
    }
    const gateFile = FileMetadataFactory.getGateFile(tokenDetails)
    const gateIpfsHash = await uploadSingleFileToIPFS({
      contentFile: gateFile,
      fileName: 'gate.json',
      contractAddress: portalAddress,
      invoker: owner,
    })
    const signatureRequest = await requestSignature(owner, TOKEN_FILE_MESSAGE)
    const unlockGateResponse = await unlockGateAPI({
      invokerAddress: owner,
      message: signatureRequest.message,
      signature: signatureRequest.signature,
      contractAddress: portalAddress,
      gateId: tokenDetails.gateId,
      chain: defaultNetwork.chainId,
    })

    if (!unlockGateResponse?.data?.gateKey) {
      throw new Error('failed to retrieve gate key')
    }
    const gatedMetadataFile = await FileMetadataFactory.getGatedFileMetadata(
      file,
      owner,
      serverKeys,
      unlockGateResponse.data.gateKey,
      fileArgs
    )

    return {
      ...gatedMetadataFile,
      gateIpfsHash,
    }
  } catch (err) {
    throw new FileCreationError('Unable to create token gated file')
  }
}

export const generateFileMetadataByType = async (
  owner: string,
  file: File,
  fileType: FileTypeEnum,
  serverKeys?: IServerKey,
  portalAddress?: string,
  fileArgs = {}
) => {
  if (fileType === FileTypeEnum.PUBLIC)
    return await createPublicFile(owner, file, fileArgs)
  if (fileType === FileTypeEnum.PRIVATE)
    return await createCollaboratorFile(
      owner,
      file,
      serverKeys as IServerKey,
      fileArgs
    )
  // if (fileType === FileTypeEnum.MEMBERS_PRIVATE)
  //   return await createMemberFile(
  //     owner,
  //     file,
  //     serverKeys as IServerKey,
  //     fileArgs
  //   )
  if (fileType === FileTypeEnum.GATED) {
    const { isEdited, ...rest } = fileArgs as any
    if (isEdited) {
      return await editTokenGatedFile(
        owner,
        file,
        serverKeys as IServerKey,
        portalAddress as string,
        rest
      )
    } else {
      return await createTokenGatedFile(
        owner,
        file,
        serverKeys as IServerKey,
        portalAddress as string,
        fileArgs
      )
    }
  }

  throw new FileCreationError('Unable to create file')
}

export const isTiptapContentEmpty = (editor: Editor | null) => {
  if (!editor) return true
  if (editor.isEmpty) return true

  // Text Content
  const hasTextContent = editor.getText().trim().length > 0

  const editorContentJSON = editor.getJSON().content || ([] as JSONContent[])

  const flatContentJSON = editorContentJSON.map((elm: JSONContent) =>
    elm.content ? elm.content[0] : ([] as JSONContent)
  )

  // has resizable media
  const hasMediaContent =
    flatContentJSON.filter((elm) => elm.type === 'resizableMedia').length > 0

  return !hasTextContent && !hasMediaContent
}
