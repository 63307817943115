/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-key */
import { IconButton, LucideIcon, useHoverAndLongPress } from '@fileverse/ui'
import cn from 'classnames'
import React, { SetStateAction } from 'react'
import { SegmentedControl } from './SegmentedControl'
import { useSortable } from '@dnd-kit/sortable'

export interface SectionProps extends React.AllHTMLAttributes<HTMLDivElement> {
  [x: string]: any
  title: string
  setTitle?: React.Dispatch<SetStateAction<string>>
  currentLayoutMode?: LayoutModes
  setCurrentLayoutMode: React.Dispatch<SetStateAction<LayoutModes>>
  viewMode?: boolean
  isDragging?: boolean
  onRemoveSection?: () => void
  id?: any
}

export enum LayoutModes {
  LIST = 'LIST',
  CARD = 'CARD',
}

export const Section = React.forwardRef<HTMLDivElement, SectionProps>(
  (
    {
      children,
      className,
      title,
      setTitle,
      currentLayoutMode,
      setCurrentLayoutMode,
      viewMode,
      isDragging,
      onRemoveSection,
      id,
      disabled,
      ...props
    },
    ref
  ) => {
    const layoutOptions = [
      <div
        onClick={() => setCurrentLayoutMode(LayoutModes.LIST)}
        data-testid="list-mode-button"
      >
        <LucideIcon fill="none" name={'List'} />
      </div>,
      <div
        onClick={() => setCurrentLayoutMode(LayoutModes.CARD)}
        data-testid="card-mode-button"
      >
        <LucideIcon fill="none" name={'LayoutGrid'} />
      </div>,
    ]

    const { listeners } = useSortable({
      id: id,
    })

    const { onMouseEnter, onMouseLeave, isActive } = useHoverAndLongPress({})

    return (
      <section
        ref={ref}
        className={cn(
          'w-full color-bg-default p-4 flex flex-col gap-6 rounded-xl',
          className
        )}
        {...props}
      >
        {!isDragging && (
          <div
            {...listeners}
            className={cn(
              'section-tour w-full flex justify-between items-center px-3 rounded-xl border border-dashed transition-opacity duration-300 ease-in-out',
              !viewMode && isActive
                ? 'border-default hover:cursor-grab'
                : 'border-transparent'
            )}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            {viewMode ? (
              <p className="text-heading-md">{title}</p>
            ) : (
              setTitle && (
                <input
                  type="text"
                  className="text-heading-md border-none outline-none"
                  defaultValue={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              )
            )}
            <div
              className={cn('block', {
                hidden: viewMode && disabled,
              })}
            >
              <SegmentedControl
                options={layoutOptions}
                container={false}
                currentState={currentLayoutMode === LayoutModes.LIST ? 0 : 1}
                setCurrentState={() => null}
                disabled={disabled}
              />
            </div>
            {!viewMode && (
              <div
                className={cn(
                  'absolute -top-1 -left-1 transition-opacity duration-300',
                  {
                    'opacity-0': !isActive,
                    'opacity-100': isActive,
                  }
                )}
              >
                <IconButton
                  onClick={onRemoveSection}
                  icon="Trash"
                  variant="ghost"
                  size="md"
                  rounded
                  elevation={3}
                  className="color-icon-inverse w-11 h-11"
                />
              </div>
            )}
          </div>
        )}
        {children}
      </section>
    )
  }
)

Section.displayName = 'Section'
