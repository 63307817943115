/* eslint-disable @typescript-eslint/no-explicit-any */
import { getInvokerStateFromIndexDb } from '../../store/middleware/utils'
import { defaultNetwork } from '../../config/network-config'
import { uploadFileAPI } from '../../api/fileAPI/uploadFile'

export const uploadToIPFS = async (contentPayload: any) => {
  const { contractAddress, invoker, contentFile, metadataFile, fileTags } =
    contentPayload

  const { invokers } = await getInvokerStateFromIndexDb()

  const credential = invokers[invoker]?.credentials[contractAddress]

  const chainId = defaultNetwork.chainId

  const contentUploadRequest = {
    credential,
    file: contentFile,
    name: contentFile.name,
    contractAddress,
    invoker,
    chain: chainId,
  }
  const metadataUploadRequest = {
    credential,
    file: metadataFile,
    name: metadataFile.name,
    contractAddress,
    invoker,
    chain: chainId,
  }

  const [contentResponse, metadataResponse] = await Promise.all([
    uploadFileAPI(contentUploadRequest),
    uploadFileAPI(metadataUploadRequest, fileTags),
  ])

  const contentIpfsHash = contentResponse.data.ipfsHash
  const metadataIpfsHash = metadataResponse.data.ipfsHash
  return {
    contentIpfsHash,
    metadataIpfsHash,
  }
}

export const uploadSingleFileToIPFS = async (contentPayload: any) => {
  const { contractAddress, invoker, contentFile, fileName } = contentPayload

  const { invokers } = await getInvokerStateFromIndexDb()

  const credential = invokers[invoker]?.credentials[contractAddress]

  const fileUploadRequest = {
    credential,
    file: contentFile,
    name: fileName,
    contractAddress,
    invoker: invoker,
    chain: defaultNetwork.chainId,
  }

  const contentIpfsHash = await uploadFileAPI(fileUploadRequest)
  return contentIpfsHash.data.ipfsHash
}
