import { Button } from '@fileverse/ui'
import copyToClipboard from 'copy-to-clipboard'
import React from 'react'
import sendNotifcation from '../../../../utils/notification'
import { useParams } from 'react-router-dom'
import { useDialogStore } from './utils'
import { FRAME_URL } from '../../../../config'
export const SocialShareCTA = ({ tokenId }: { tokenId: string }) => {
  const contractAddress = useParams().address as string

  const { resetDialogState } = useDialogStore()
  return (
    <div className="flex flex-col gap-2 w-full p-[14px]">
      <Button
        onClick={() => {
          copyToClipboard(tokenId)
          resetDialogState()
          sendNotifcation('Link copied !!', '', 'warning')
        }}
        variant={'secondary'}
        className="rounded-[4px] h-[40px]"
      >
        <div className="w-full">Copy link</div>
      </Button>
      <div className="text-center leading-[20px] font-normal text-sm text-[#77818A]">
        Or
      </div>
      <Button
        onClick={() => {
          window.open(
            `https://warpcast.com/~/compose?text=Checkout%20my%20Portal%20via%20%40fileverse&embeds[]=${FRAME_URL}/${contractAddress}`,
            '_blank'
          )
        }}
        className="rounded-[4px] h-[40px]"
      >
        Share on farcaster
      </Button>
    </div>
  )
}
