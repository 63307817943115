/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ISEAPair } from 'gun'
import React, { useEffect, useState } from 'react'
import throttle from 'lodash.throttle'
import * as Y from 'yjs'
import { DocStatus } from '../ExcalidrawCanvas/SavingStatusUI'
import { fromUint8Array, toUint8Array } from 'js-base64'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { getISEAKeyPair } from '../../utils/libCrypto'
import { decryptUsingSEAKey, encrypUsingSEAKey } from '../../utils/crypto'
import isEmpty from 'lodash/isEmpty'
import { checkIfOwnerOrCollaborator } from '../../utils/checkIfOwnerOrCollaborator'
import { useContract } from '../../store/contract/hooks'
import { Editor } from '@tiptap/react'
import { editDraftName, removeDraft } from '../../utils/collaboration/utils'
import sendNotifcation from '../../utils/notification'
import { MakeFileFromObject } from '../../utils/makeFileFromObject'
import { IndexeddbPersistence } from 'y-indexeddb'
import { getDocumentContentNode, useGunNodes } from '../../hooks/useGunNode'
import { IDraft } from '../../types/interface/drafts.interface'
import { useEthersSigner } from '../../hooks/clientToProvider'
import { GunInstance } from '../../utils/instantiateGun'
import { captureException } from '@sentry/react'
import { useServerKeys } from '../../store/invoker/hooks'
import { usePrivyHelper } from '../../hooks/usePrivyHelper'

export interface IDocumentContent {
  docId: string
  title: string
  content: string
  timestamp: number
}
const useEditorHook = ({
  setDocTitlePopUp,
  docTitle,
  ydoc,
  setCollabDocPreview,
  editor,
  setFiles,
  rtcData,
}: {
  setDocTitlePopUp: React.Dispatch<React.SetStateAction<boolean>>
  setFiles: React.Dispatch<File[]>
  editor: Editor | null
  ydoc: Y.Doc
  docTitle: string
  setCollabDocPreview: React.Dispatch<React.SetStateAction<string | undefined>>
  rtcData: IDraft
}) => {
  const [urlParams] = useSearchParams()
  const { rtcId } = useParams()
  const documentKey = getISEAKeyPair(urlParams.get('key') as string)
  const walletAddress = usePrivyHelper().walletAddress
  const [searchParams] = useSearchParams()
  const chainId = parseInt(searchParams.get('chainId') || '')
  const { address: contractAddress } = useParams()
  const signer = useEthersSigner({ chainId })
  const [draftName, setDraftName] = useState<string>(docTitle)
  const contract = useContract(contractAddress as string)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const _location = useLocation()
  const navigate = useNavigate()
  const {
    getAuthenticatedDocumentContentNode,
    getAuthenticatedPluginMetadataNode,
  } = useGunNodes()

  new IndexeddbPersistence(rtcId as string, ydoc)

  const serverKeys = useServerKeys(
    walletAddress as string,
    contractAddress as string
  )
  const isCollaborator = checkIfOwnerOrCollaborator(
    contract,
    walletAddress as string,
    serverKeys
  )
  const getDocContents = async ({
    rtcId,
  }: {
    authKey: ISEAPair
    rtcId: string
  }): Promise<IDocumentContent | undefined> => {
    try {
      const contentNode = getAuthenticatedDocumentContentNode(rtcId)
      const content = await Promise.race([
        new Promise((resolve) => {
          contentNode.on((doc: IDocumentContent) => {
            if (doc) {
              resolve(doc)
              contentNode.off()
            }
          })
        }),
        new Promise((resolve) =>
          setTimeout(() => {
            contentNode.off()
            resolve(undefined)
          }, 5000)
        ),
      ])
      return content as IDocumentContent
    } catch (error) {
      console.log(error)
    }
  }
  const initializeDocument = async () => {
    if (isCollaborator) {
      setDraftName(docTitle || _location.state?.title || '')
    }
    try {
      const docContentInfo = await getDocContents({
        authKey: documentKey as ISEAPair,
        rtcId: rtcId as string,
      })
      if (docContentInfo && !isEmpty(docContentInfo)) {
        const { content: docEncryptedContent } = docContentInfo

        const plainContent = await decryptUsingSEAKey(
          docEncryptedContent,
          documentKey!
        )
        const contents = toUint8Array(plainContent)
        Y.applyUpdate(ydoc, contents)
        setIsLoading(false)
      } else {
        setIsLoading(false)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const handleBackButtonClick = () => {
    if (editor?.getText().trim().length === 0 && isCollaborator) {
      removeDraft(
        getAuthenticatedPluginMetadataNode(rtcId as string),
        rtcId as string
      )
      navigate(`/${contractAddress}?chainId=${chainId}`)
      return
    }
    if (isCollaborator) {
      draftName &&
        editDraftName(
          draftName,
          getAuthenticatedPluginMetadataNode(rtcId as string)
        )
      navigate(`/${contractAddress}?chainId=${chainId}`)
    } else {
      navigate(`/${contractAddress}/member?chainId=${chainId}`)
    }
  }
  useEffect(() => {
    initializeDocument()
  }, [])

  const getCollabDocPreview = async () => {
    const html = editor?.getHTML()
    return html
  }

  const showTitlePopUp = async () => {
    const html = await getCollabDocPreview()
    const preview = document.querySelector('#preview')!
    preview.innerHTML = html || ''
    setCollabDocPreview(html)
    setDocTitlePopUp(true)
  }
  const publishDocument = async () => {
    if (!contractAddress || !signer) {
      sendNotifcation('Connect you wallet to publish', '', 'danger')
      return
    }
    const documentFile = {
      file: editor?.getJSON(),
      source: 'fileverse_document',
      version: 2,
      rtcId,
      rtcData,
      rtcKey: documentKey,
    }
    editDraftName(
      draftName,
      getAuthenticatedPluginMetadataNode(rtcId as string)
    )

    const html = await getCollabDocPreview()
    setCollabDocPreview(html)
    const file = MakeFileFromObject(documentFile, `${draftName || 'Untitled'}`)
    setFiles([file])
  }
  const downloadProject = async () => {
    const documentFile = {
      file: editor?.getJSON(),
      source: 'fileverse_document',
      version: 2,
    }
    const file =
      'text/json;charset=utf-8,' +
      encodeURIComponent(JSON.stringify(documentFile))
    const a = document.createElement('a')
    a.href = 'data:' + file
    a.download = `${draftName || 'Untitled'}`
    a.click()
  }

  const onTitleChange = async (draftName: string) => {
    setDraftName(draftName)
    const authNode = getAuthenticatedPluginMetadataNode(rtcId as string)
    if (!authNode) return
    const nameNode = authNode.get('name')
    await GunInstance.putGunNodeData(nameNode, draftName)
  }

  return {
    downloadProject,
    publishDocument,
    showTitlePopUp,
    postDocContent,
    isLoading,
    draftName,
    setDraftName: onTitleChange,
    handleBackButtonClick,
  }
}

export default useEditorHook

export const postDocContent = throttle(
  async ({
    documentKey,
    contractAddress,
    rtcId,
    setDocStatus,
    ydoc,
  }: {
    documentKey: ISEAPair
    contractAddress: string
    rtcId: string
    setDocStatus: (status: DocStatus) => void
    ydoc: Y.Doc
  }) => {
    const plainContent = fromUint8Array(Y.encodeStateAsUpdate(ydoc))

    if (plainContent !== 'AAA=') {
      setDocStatus(DocStatus.SAVING)
      const encryptedContent = await encrypUsingSEAKey(
        plainContent,
        documentKey
      )
      try {
        const contentNode = getDocumentContentNode(
          documentKey,
          rtcId,
          contractAddress
        )
        await GunInstance.putGunNodeData(contentNode, {
          docId: rtcId,
          content: encryptedContent,
          timestamp: Date.now(),
        })

        setTimeout(() => setDocStatus(DocStatus.SAVED), 1000)
      } catch (err) {
        console.log(err)
        captureException(err)
      }
    }
  },
  1000
)

export const postEditedPluginContent = throttle(
  async ({
    documentKey,
    contentNode,
    rtcId,
    setDocStatus,
    ydoc,
  }: {
    documentKey: ISEAPair
    rtcId: string
    setDocStatus: (status: DocStatus) => void
    ydoc: Y.Doc
    contentNode: any
  }) => {
    const plainContent = fromUint8Array(Y.encodeStateAsUpdate(ydoc))

    if (!contentNode) return

    if (plainContent !== 'AAA=') {
      setDocStatus(DocStatus.SAVING)
      const encryptedContent = await encrypUsingSEAKey(
        plainContent,
        documentKey
      )
      try {
        await GunInstance.putGunNodeData(contentNode, {
          docId: rtcId,
          content: encryptedContent,
          timestamp: Date.now(),
        })
        setTimeout(() => setDocStatus(DocStatus.SAVED), 1000)
      } catch (err) {
        console.log(err)
        captureException(err)
      }
    }
  },
  1000
)
