/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef } from 'react'
import { ButtonGroup } from '@mui/material'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

import { Lock, UsersRound, EllipsisVertical } from 'lucide-react'
import { useState } from 'react'
import { useParams, useSearchParams, NavLink } from 'react-router-dom'
import { defaultNetwork } from '../../../../../config/network-config'
import { useSafeAppStatus } from '../../../../../store/common/hooks'
import { FileSource } from '../../../../../types/enum/file.enum'
import { INewFile } from '../../../../../types/interface/file.interface'
import {
  ellipseStringAtMid,
  getFileExtension,
  getFileNameWithoutExtension,
  getFileType,
} from '../../../../../utils/fileUtils'
import { isOriginGnosisBlockscout } from '../../../../../utils/isOriginGnosisBlockscout'

import { IconButton } from '@fileverse/ui'
import copyToClipboard from 'copy-to-clipboard'
import {
  FileNameMap,
  FileTypeMap,
  SystemFileIconMap,
  FileIconMap,
  SortByValueLabelMap,
  SortOptions,
} from '.'
import useMediaQuery from '../../../../../hooks/useMediaQuery'
import cn from 'classnames'
import { DeleteFileModal } from '../DeleteFileModal'
import { RecentActionButton } from '../../../../PublicPortal/components/PublicPortalNavbar'
import { useOutsideClick } from '../../../../../hooks/useOutsideClickDetector'
import sendNotifcation from '../../../../../utils/notification'
import { Tooltip } from '../../../../PublicPortal/components/Tooltip'
import { usePrivyHelper } from '../../../../../hooks/usePrivyHelper'
import {
  DocumentTypeExtensions,
  ThreeDTypeExtensions,
} from '../../../../../constants'
import { Icon as DocIcon } from '../../../../PublicPortal/components/Icon'
dayjs.extend(relativeTime)

const getTimeAgoString = (file: INewFile, sortedBy: SortOptions) => {
  switch (sortedBy) {
    case SortOptions.Created:
      return dayjs.unix(file.metadata.uploadedAt).fromNow()
    case SortOptions.Edited:
      return dayjs
        .unix(file.metadata?.editedAt || file.metadata?.uploadedAt)
        .fromNow()
    case SortOptions.Viewed:
      return dayjs.unix(file.metadata.uploadedAt).fromNow()
    default:
      return dayjs.unix(file.metadata.uploadedAt).fromNow()
  }
}

const getFileSourceName = (file: INewFile) => {
  if (
    file.metadata.source === FileSource.USER_FILE_SYSTEM ||
    file.metadata.source === FileSource.ARWEAVE
  )
    return getFileExtension(file?.metadata?.name)

  return FileNameMap[file.metadata.source]
}

export const getFileIconBySource = (file: INewFile) => {
  const source = file?.metadata?.source
  const extension = file?.metadata?.extension

  if (ThreeDTypeExtensions.includes(extension))
    return <DocIcon name="File3d" size="md" />
  switch (extension) {
    case 'pdf':
      return <DocIcon name="FilePdf" size="md" />
    case 'xls':
    case 'xlsx':
      return <DocIcon name="FileExcel" size="md" />
    case 'doc':
    case 'docx':
      return <DocIcon name="FileWord" size="md" />
    case 'ppt':
    case 'pptx':
      return <DocIcon name="FilePpt" size="md" />
  }
  if (source === FileSource.USER_FILE_SYSTEM)
    return SystemFileIconMap[getFileType(file.metadata.name)]
  return FileIconMap[source]
}

export const getByString = (
  sortedBy: SortOptions,
  currentWalletAddress: string,
  file: INewFile
) => {
  if (sortedBy === SortOptions.Created)
    return file?.metadata?.owner === currentWalletAddress
      ? 'you'
      : ellipseStringAtMid(file.metadata.owner)
  if (sortedBy === SortOptions.Edited) {
    const editedBy = file?.metadata?.editedBy || file?.metadata?.owner
    return editedBy === currentWalletAddress
      ? 'you'
      : ellipseStringAtMid(editedBy)
  }
  return 'you'
}

export const FileItem = (props: { file: INewFile; sortedBy: SortOptions }) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const { file, sortedBy = SortOptions.Created } = props
  const { address: contractAddress } = useParams()
  const [searchParams] = useSearchParams()
  const [isHovering, toggleHovering] = useState(false)
  const isSafeApp = useSafeAppStatus()
  const chainId = parseInt(searchParams.get('chainId') || '')
  const fileSourceName = getFileSourceName(file)
  const walletAddress = usePrivyHelper().walletAddress as string
  const isMaxWidth1024 = useMediaQuery('(max-width: 1024px)')

  const timeAgoString = getTimeAgoString(file, sortedBy)
  const byString = getByString(sortedBy, walletAddress, file)
  const fileType = FileTypeMap[file.metadata.fileType]
  const navLink = `/${contractAddress}/file/${file.fileId}?chainId=${chainId}`
  const navTarget = isSafeApp || isOriginGnosisBlockscout ? '_self' : '_blank'
  const textToCopy = `${window.origin}${window.location.pathname}#/${contractAddress}/file/${file.fileId}?chainId=${defaultNetwork.chainId}`
  const [isDropDownVisible, setDropdownVisible] = useState(false)
  const dropdownRef = useRef(null)
  useOutsideClick([dropdownRef], () => setDropdownVisible(false))

  return (
    <div className="w-full">
      <NavLink
        className={cn(
          'w-full hover:bg-[#f8f9fa] cursor-pointer border-b border-[#e8ebec] flex py-[12px] px-[16px]'
        )}
        to={navLink}
        target={navTarget}
        onMouseOver={() => toggleHovering(true)}
        onMouseLeave={() => toggleHovering(false)}
      >
        <div className="flex w-full items-center gap-[16px]">
          <div
            className={cn(
              !ThreeDTypeExtensions.includes(file.metadata?.extension) &&
                !DocumentTypeExtensions.includes(file.metadata?.extension)
                ? 'rounded color-bg-brand p-1'
                : ''
            )}
          >
            {getFileIconBySource(file)}
          </div>
          <div className="flex w-full flex-col">
            <div className="text-body-sm-bold max-w-[100px] lg:max-w-[200px] w-full truncate">
              {getFileNameWithoutExtension(file.metadata?.name)}
            </div>
            <div className="text-helper-text-sm color-text-secondary flex gap-[8px]">
              <div className="truncate">{fileSourceName}</div>
              <div className="w-[20vw] sm:w-[60vw] lg:w-[180px] truncate">{`${SortByValueLabelMap[sortedBy]} by ${byString} ${timeAgoString}`}</div>
            </div>
          </div>
        </div>
        <div
          className={cn(
            'flex items-center',
            isMaxWidth1024 ? 'gap-4' : 'gap-[64px]'
          )}
        >
          <div className="text-[12px] text-[#77818A] text-nowrap flex gap-[4px] items-center min-w-fit">
            {fileType === 'Public' ? (
              <UsersRound size="16px" />
            ) : (
              <Lock size="16px" />
            )}
            {fileType}
          </div>

          {!isMaxWidth1024 ? (
            <div className="min-w-[80px] mb-[-2px]">
              {isHovering && (
                <ButtonGroup className="gap-[8px]">
                  <CopyButton textToCopy={textToCopy} />
                  <DeleteButton
                    onClick={(
                      e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                    ) => {
                      e.stopPropagation()
                      e.preventDefault()
                      setOpenDeleteModal(true)
                    }}
                  />
                </ButtonGroup>
              )}
            </div>
          ) : (
            <div
              ref={dropdownRef}
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
              }}
              className="w-[36px] relative flex items-center justify-center h-[36px] hover:bg-[#F2F4F5] rounded-[4px]"
            >
              <EllipsisVertical
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  setDropdownVisible(!isDropDownVisible)
                }}
              />
              {isDropDownVisible && (
                <div className="absolute z-10 top-10 right-0">
                  <RecentActionButton
                    onDelete={() => setOpenDeleteModal(true)}
                    onCopy={() => {
                      copyToClipboard(textToCopy)
                      sendNotifcation('Copied', '', 'warning')
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </NavLink>
      {openDeleteModal && (
        <DeleteFileModal
          file={file}
          isOpen={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
        />
      )}
    </div>
  )
}

export const DeleteButton = ({
  onClick,
}: {
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}) => {
  return (
    <Tooltip text="Delete">
      <div className="w-fit h-fit hover:bg-[#F2F4F5] rounded-[4px]">
        <IconButton
          size="sm"
          onClick={onClick}
          icon="Trash"
          variant="ghost"
          className="color-text-secondary"
        />
      </div>
    </Tooltip>
  )
}

export const CopyButton = (props: { textToCopy: string }) => {
  const [isCopied, setCopied] = useState(false)

  const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    e.preventDefault()
    setCopied(true)
    copyToClipboard(props.textToCopy)
    setTimeout(() => {
      setCopied(false)
    }, 2000)
    sendNotifcation('Copied', '', 'info')
  }
  return (
    <Tooltip text={isCopied ? 'Copied' : 'Copy'}>
      <div className="w-fit h-fit hover:bg-[#F2F4F5] rounded-[4px]">
        <IconButton
          size="sm"
          onClick={onClick}
          icon="Link"
          variant="ghost"
          className="color-text-secondary"
        />
      </div>
    </Tooltip>
  )
}
