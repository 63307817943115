import React from 'react'
import cn from 'classnames'
import { Tooltip } from '@mui/material'
import contractLogo from '../../assets/mainLogo.svg'
import { Button } from '../../pages/PublicPortal/components/Button'

const BlackButton = ({
  isDisabled,
  onClick,
  title,
  isLoading,
  customCSS,
  tooltip,
  hasLogo = true,
  id,
  btnRef,
}: {
  isDisabled?: boolean
  onClick?: () => void
  title: string
  isLoading?: boolean
  customCSS?: string
  tooltip?: string
  hasLogo?: boolean
  id?: string
  btnRef?: React.RefObject<HTMLButtonElement>
}) => {
  return (
    <Tooltip arrow={true} title={tooltip ? tooltip : ''}>
      <Button
        id={id}
        ref={btnRef}
        disabled={isDisabled}
        onClick={() => {
          !isLoading && onClick && onClick()
        }}
        isLoading={isLoading}
        className={cn(customCSS ? customCSS : '')}
      >
        {title}
        {hasLogo && (
          <img
            src={contractLogo}
            alt="logo"
            className="object-cover w-6 rounded-lg ml-2"
          />
        )}
      </Button>
    </Tooltip>
  )
}

export default BlackButton
