/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from 'react'
import FileLoadingCard from './FileLoadingCard'
import { useSortedFiles } from '../../store/files/hooks'
import { useParams, useSearchParams } from 'react-router-dom'
import { useAppDispatch } from '../../store/hooks'
import { fetchAllFiles } from '../../store/files/thunks'
import { usePublicLayoutFileId } from '../../store/contract/hooks'
import { FileItem } from '../../pages/domain/Home/components/common/FileItem'
import { SortOptions } from '../../pages/domain/Home/components/common'

export default function AllFiles({
  isPermaFile,
  isFetchingEditedFiles,
}: {
  isPermaFile?: boolean
  isFetchingEditedFiles?: boolean
}) {
  const { address: contractAddress } = useParams()
  const [searchParam] = useSearchParams()
  const chainId = parseInt(searchParam.get('chainId') || '')
  const dispatch = useAppDispatch()
  const allFiles = useSortedFiles(
    contractAddress as string,
    SortOptions.Created
  )

  const [loading, setLoading] = useState(true)
  const publicLayoutFileId = usePublicLayoutFileId(contractAddress as string)
  const fetchFiles = async () => {
    await dispatch(
      fetchAllFiles({
        contractAddress: contractAddress as string,
        chainId,
      })
    )
    setLoading(false)
  }

  const fileItems = allFiles.map((file) => {
    return (
      <FileItem file={file} key={file.fileId} sortedBy={SortOptions.Created} />
    )
  })

  useEffect(() => {
    fetchFiles()
  }, [contractAddress])

  if (loading || isFetchingEditedFiles)
    return (
      <div className="w-full h-full overflow-y-scroll no-scrollbar">
        {Array.from(
          { length: Object.keys(allFiles).length },
          (_, id: number) => (
            <FileLoadingCard key={id} className="w-full lg:w-[435px] mx-4" />
          )
        )}
      </div>
    )

  const filteredFiles = Object.keys(allFiles)
    .reverse()
    .filter((_fileId) =>
      isPermaFile ? allFiles[Number(_fileId)]?.metadata.arweaveHash : allFiles
    )
    .filter((_fileId) => _fileId !== publicLayoutFileId?.toString())

  return filteredFiles.length > 0 ? (
    <div className="w-full h-full overflow-y-scroll no-scrollbar">
      {fileItems}
    </div>
  ) : (
    <div className="mx-auto flex flex-col h-[70vh] w-full text-center justify-center">
      <div className="text-heading-md">No files to show</div>
    </div>
  )
}
