export enum PrivateFileType {
  CollaboratorsOnly = 'collaborators-only',
  // MembersOnly = 'members-only',
  TokenGated = 'token-gated',
  AnyOneWithLink = 'anyone-with-link',
}

export const [CollaboratorsOnly, TokenGated, AnyOneWithLink] = [
  PrivateFileType.CollaboratorsOnly,
  // PrivateFileType.MembersOnly,
  PrivateFileType.TokenGated,
  PrivateFileType.AnyOneWithLink,
]

export const PrivateFileTypes = [
  CollaboratorsOnly,
  // MembersOnly,
  TokenGated,
  AnyOneWithLink,
]

export const PrivateFileTypeMap = {
  [CollaboratorsOnly]: {
    labelValue: 'Collaborators only',
    value: CollaboratorsOnly,
    isDisabled: false,
  },
  // [MembersOnly]: {
  //   labelValue: 'Members only',
  //   value: 'members-only',
  //   isDisabled: false,
  // },
  [TokenGated]: {
    labelValue: 'Token-gated',
    value: TokenGated,
    isDisabled: false,
  },
  [AnyOneWithLink]: {
    labelValue: 'Anyone with link',
    value: AnyOneWithLink,
    isDisabled: true,
  },
}

export enum ButtonType {
  Private = 'Private',
  Public = 'Public',
  Back = 'Back',
  Continue = 'Continue',
}

export const OptionsArr = [
  {
    label: 'Ethereum',
    value: 'Ethereum',
  },
  {
    label: 'Polygon',
    value: 'Polygon',
  },
  {
    label: 'Goerli',
    value: 'Goerli',
  },
]

export enum TabType {
  All = 'All',
  Tokens = 'Tokens',
  NFTs = 'NFTs',
}

export const TabsArr = [TabType.All, TabType.Tokens, TabType.NFTs]
