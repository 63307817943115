import React, { useEffect, useState } from 'react'
import backArrow from '../assets/backArrow.svg'
import createIcon from '../assets/createPortalLogo.svg'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import uuid from 'react-uuid'
import { useServerKeys } from '../store/invoker/hooks'
import { ISEAPair } from 'gun'
import { IDraft } from '../types/interface/drafts.interface'
import { getContractDraftsFromGunDB } from '../utils/collaboration/utils'
import { getISEAKeyPair } from '../utils/libCrypto'
import { FileSource } from '../types/enum/file.enum'
import { WebpagesDraftCard } from '../components/WebPages/WebpagesDraftCard'
import { useAllContractFiles } from '../store/files/hooks'
import { usePrivyHelper } from '../hooks/usePrivyHelper'
import { AnimatedLoader } from './PublicPortal/components/Loader'

export const WebPages = () => {
  const rtcId = uuid()
  const navigate = useNavigate()
  const { address: contractAddress } = useParams()
  const walletAddress = usePrivyHelper().walletAddress
  const serverKeys = useServerKeys(
    walletAddress as string,
    contractAddress as string
  )
  const authKey = getISEAKeyPair(serverKeys.portalGunKey)
  const [drafts, setDrafts] = useState<Array<IDraft>>([])
  const [editedFiles, setEditedFiles] = useState<string[] | undefined>([])
  const allFiles = useAllContractFiles(contractAddress as string)
  const uploadedFiles = Object.values(allFiles)
  const webpagesFiles = uploadedFiles.filter(
    (item) => item.metadata.source === FileSource.DPAGE
  )

  const [urlParams] = useSearchParams()
  const chainId = parseInt(urlParams.get('chainId') || '')

  const [isLoading, setIsLoading] = useState(true)

  const getDrafts = async () => {
    if (urlParams.get('task')) {
      navigate(`/${contractAddress}/dPages/${rtcId}?chainId=${chainId}`)
      return
    }
    setIsLoading(true)
    const allDrafts = await getContractDraftsFromGunDB(
      authKey as ISEAPair,
      contractAddress as string
    )

    if (allDrafts) {
      const editedDPages = allDrafts
        .filter(
          (item) =>
            item &&
            item?.type === FileSource.DPAGE &&
            Object.prototype.hasOwnProperty.call(item, 'isEdited') &&
            item?.isEdited === true
        )
        .map((item) => item.fileId)
      setEditedFiles(editedDPages)
      const dPagesDraft = allDrafts.filter(
        (item) =>
          item &&
          item?.type === FileSource.DPAGE &&
          Object.prototype.hasOwnProperty.call(item, 'isPublished') &&
          item.isPublished !== true
      )
      setDrafts(dPagesDraft)
      if (dPagesDraft?.length === 0 && webpagesFiles?.length === 0) {
        navigate(`/${contractAddress}/dPages/${rtcId}?chainId=${chainId}`)
      } else {
        setIsLoading(false)
      }
    } else {
      navigate(`/${contractAddress}/dPages/${rtcId}?chainId=${chainId}`)
    }
  }

  useEffect(() => {
    getDrafts()
  }, [])
  return (
    <>
      {isLoading ? (
        <div className=" w-screen h-screen flex flex-col gap-4 justify-center items-center">
          <AnimatedLoader text="Preparing your dPage..." />
        </div>
      ) : (
        <div className="h-full flex flex-col overflow-scroll no-scrollbar">
          <div
            id="navbar"
            className="w-[100%] flex justify-between items-center p-8"
          >
            <div className="flex gap-4 items-center">
              <button
                onClick={() =>
                  navigate(`/${contractAddress}?chainId=${chainId}`)
                }
                className="p-2 mr-2 bg-[#FAFAFA] border border-[#E2E2E2] items-center relative flex justify-center rounded-[8px]"
              >
                <img src={backArrow} className="w-3" />
              </button>
              <p className="font-bold text-lg">dPages</p>
            </div>
          </div>
          <main className="w-[100%] flex flex-col justify-start items-start px-8 gap-6">
            <div className="flex flex-col gap-2 w-[100%]">
              <p className="text-heading-md">Draft dPages</p>
              <div className="w-[100%] flex flex-wrap justify-start gap-7">
                <div
                  data-cy="create-webpage"
                  className="w-[223px] h-[275px] border border-[#E2E2E2] rounded-[8px] flex flex-col justify-center items-center gap-11 py-12 cursor-pointer"
                  onClick={() =>
                    navigate(
                      `/${contractAddress}/dPages/${rtcId}?chainId=${chainId}`
                    )
                  }
                >
                  <img
                    src={createIcon}
                    alt="create"
                    className="w-[70%] object-scale-down"
                  />
                  <p className="text-lg font-bold">Start New</p>
                </div>
                <div className=" border-r h-[200px] self-center"></div>
                {drafts.map((draft) => (
                  <WebpagesDraftCard
                    draft={draft}
                    contractAddress={contractAddress as string}
                    key={Math.random()}
                  />
                ))}
              </div>
            </div>
            <div className="flex flex-col gap-2 my-4 w-[100%]">
              <p className="text-heading-md">Published dPages</p>
              <div className="w-[100%] flex flex-wrap justify-start gap-7">
                {webpagesFiles.length === 0 ? (
                  <p className=" w-full my-4 text-center">
                    No dPages published yet
                  </p>
                ) : (
                  webpagesFiles.map((item) => (
                    <WebpagesDraftCard
                      editedFiles={editedFiles}
                      uploadedFileData={item}
                      contractAddress={contractAddress as string}
                      key={Math.random()}
                    />
                  ))
                )}
              </div>
            </div>
          </main>
        </div>
      )}
    </>
  )
}
