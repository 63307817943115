/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useRef, useState } from 'react'

import {
  Button,
  ButtonGroup,
  Icon,
  LucideIcon,
  Table,
  TableBody,
  TableCell,
  TableRow,
  formatCounter,
} from '@fileverse/ui'
import { IPortalDataProps, IPortalFile } from '../interfaces'
import { Icon as DocIcon } from './Icon'
import { getDocIconName, IDocumentCardProps } from './Card/DocumentCard'
import { getIPFSAsset } from '../../../utils/getIPFSAsset'
import {
  AUDIO_EXTENSIONS,
  IMAGE_EXTENSIONS,
  VIDEO_EXTENSIONS,
} from '../../../utils/fileUtils'
import { ShareDropDown } from './PublicPortalNavbar'
import ReactDOM from 'react-dom'
import HeartBitProvider, {
  useHeartBitContext,
} from './HeartBit/HeartBitProvider'
import { useParams, useSearchParams } from 'react-router-dom'
import { getDomainName } from '../utils/getDomainName'
import HeartBitDialog from './HeartBit'
import { getHashedString } from '@fileverse/heartbit-core'
import { heartbitCoreSDK } from '../utils/heartbitSDK'
import { useDialogStore } from './HeartBit/utils'
import { UniqueIdentifier } from '@dnd-kit/core'
import { usePrivyHelper } from '../../../hooks/usePrivyHelper'

const getIconName = (type: any) => {
  switch (type) {
    case 'note':
      return 'Pencil'
    case 'dDoc':
      return 'FileText'
    case 'dPage':
      return 'PanelLeft'
    case 'whiteboard':
      return 'PenLine'
    default:
      return 'Placeholder'
  }
}

const DropdownPortal = ({
  children,
  style,
  onOutsideClick,
}: {
  children: JSX.Element
  style: any
  onOutsideClick: () => void
}) => {
  const portalRef = useRef(null)

  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      if (
        portalRef.current &&
        !(portalRef.current as any).contains(event.target)
      ) {
        onOutsideClick()
      }
    }

    document.addEventListener('mousedown', handleOutsideClick)

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [onOutsideClick])
  return ReactDOM.createPortal(
    <div ref={portalRef} style={style}>
      {children}
    </div>,
    document.body
  )
}
export const PortalListItem = ({ files }: { files: IPortalDataProps }) => {
  const [faviconUrls, setFaviconUrls] = useState<string[]>([])
  const [imageUrls, setImageUrls] = useState<string[]>([])

  const getImageIcon = async (contentIPFSHash: string | undefined) => {
    if (!contentIPFSHash) return null

    const image = await getIPFSAsset({
      ipfsHash: contentIPFSHash,
      options: { responseType: 'blob' },
    })
    return URL.createObjectURL(image?.data)
  }

  const updateFaviconUrl = (index: number, newValue: string) => {
    setFaviconUrls(() => {
      const updatedArray = [...faviconUrls]
      updatedArray[index] = newValue
      return updatedArray
    })
  }

  useEffect(() => {
    const fetchImageUrls = async () => {
      const urls = await Promise.all(
        files.map(async (item) => {
          if (item.type === 'media') {
            const imageURL = await getImageIcon(item.metadata?.ipfsHash)
            return imageURL || ''
          }
          return ''
        })
      )
      setImageUrls(urls)
    }
    const fetchFaviconUrls = async () => {
      const urls = await Promise.all(
        files.map(async (item) => {
          if (item.type === 'link') {
            return item.metadata?.faviconUrl || ''
          }
          return ''
        })
      )
      setFaviconUrls(urls)
    }

    fetchImageUrls()
    fetchFaviconUrls()
  }, [files])

  return (
    <Table>
      <TableBody>
        {files.map((item, index) => {
          return (
            <HeartBitProvider key={index}>
              <TableItem
                item={item}
                index={index}
                imageUrls={imageUrls}
                faviconUrls={faviconUrls}
                onError={() => updateFaviconUrl(index, '')}
                url={item.metadata?.link}
              />
            </HeartBitProvider>
          )
        })}
      </TableBody>
    </Table>
  )
}

const TableItem = ({
  index,
  item,
  imageUrls,
  faviconUrls,
  onError,
  url,
}: {
  index: number
  item: IPortalFile
  imageUrls: string[]
  faviconUrls: string[]
  onError: () => void
  url: string
}) => {
  const [hoveredRowIndex, setHoveredRowIndex] = useState<number | null>(null)
  const { isMinted, setMinted } = useHeartBitContext()
  const { type, metadata, id } = item
  const shareElementRef = React.createRef<SVGSVGElement>()
  const [isShowDropdown, setDropdownVisibility] = useState(false)
  const [dropdownStyle, setDropdownStyle] = useState<any>()
  const { address: contractAddress } = useParams()
  const [searchParams] = useSearchParams()
  const chainId = parseInt(searchParams.get('chainId') || '')
  const chainIdQuery = chainId ? `?chainId=${chainId}` : ''

  const walletAddress = usePrivyHelper().walletAddress as string
  const [totalHb, setTotalHb] = useState<number>(0)
  const { setDialogState, dialogState } = useDialogStore()

  const onLikeClick = (
    e:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: UniqueIdentifier
  ) => {
    if (isMinted) return
    e?.stopPropagation()
    e?.preventDefault()
    setDialogState({
      ...dialogState,
      itemId: id,
    })
  }
  const { getUserBalance } = useHeartBitContext()

  const getTokenId = () => {
    const { type, metadata, id } = item
    if (id === 'portal') {
      return window.location.href
    }
    if (type === 'note') return `${location.href}&fileId${itemId}`
    if (type === 'link') return metadata?.link

    const chainId = parseInt(searchParams.get('chainId') || '')
    const chainIdQuery = chainId ? `?chainId=${chainId}` : ''
    const filePath = `/${contractAddress}/file/${metadata?.fileId}${chainIdQuery}`
    return `${window.location.origin}/#${filePath}`
  }

  const getUserLikeCount = useCallback(async () => {
    await getUserBalance(getTokenId(), walletAddress)
  }, [
    contractAddress,
    getUserBalance,
    id,
    metadata?.fileId,
    metadata?.link,
    searchParams,
    type,
    walletAddress,
  ])

  useEffect(() => {
    getUserLikeCount()
  }, [getUserLikeCount])

  const handleShareButtonClick = () => {
    const rect = shareElementRef.current?.getBoundingClientRect()
    if (!rect) return

    if (!isShowDropdown) {
      const dropdownStyle = {
        position: 'absolute',
        top: `${rect.bottom + window.scrollY}px`,
        left: `${rect.left + window.scrollX}px`,
        zIndex: 1000,
      }
      setDropdownStyle(dropdownStyle)
    }

    setDropdownVisibility(!isShowDropdown)
  }

  const getTotalHeartBits = useCallback(async () => {
    let tokenId
    if (type === 'note') tokenId = `${location.href}&fileId${id}`
    if (type === 'link') tokenId = metadata?.link

    const chainId = parseInt(searchParams.get('chainId') || '')
    const chainIdQuery = chainId ? `?chainId=${chainId}` : ''
    const filePath = `/${contractAddress}/file/${metadata?.fileId}${chainIdQuery}`
    tokenId = `${window.location.origin}/#${filePath}`
    const hash = getHashedString(tokenId)
    const count = await heartbitCoreSDK.getTotalHeartBitCountByHash({
      hash,
    })

    setTotalHb(count)
  }, [
    contractAddress,
    id,
    metadata?.fileId,
    metadata?.link,
    searchParams,
    type,
  ])

  const getFileType = (item: IPortalFile) => {
    if (item.type === 'link') {
      return getDomainName(url)
    }
    if (IMAGE_EXTENSIONS.includes(item.metadata?.extension)) return 'image'
    if (AUDIO_EXTENSIONS.includes(item.metadata?.extension)) return 'audio'
    if (VIDEO_EXTENSIONS.includes(item.metadata?.extension)) return 'video'
    return item.type
  }

  useEffect(() => {
    getTotalHeartBits()
  }, [getTotalHeartBits])

  const { itemId } = dialogState
  const filePath = `/${contractAddress}/file/${metadata?.fileId}${chainIdQuery}`

  return (
    <>
      <TableRow
        onClick={() => {
          if (type === 'note') return
          if (type === 'link') {
            window.open(metadata?.link, '_blank')
            return
          }
          if (type === 'dPage' && metadata?.extension === 'dummy') {
            window.open(metadata?.link, '_blank')
            return
          }
          window.open(`${window.location.origin}/#${filePath}`, '_blank')
        }}
        onMouseEnter={() => setHoveredRowIndex(index)}
        onMouseLeave={() => setHoveredRowIndex(null)}
        className="cursor-default hover:!color-bg-default-hover flex justify-start items-center"
      >
        <TableCell className="lg:mb-1 mb-14 px-3">
          {item.type === 'media' ? (
            [...AUDIO_EXTENSIONS, ...VIDEO_EXTENSIONS].includes(
              item?.metadata?.extension as string
            ) ? (
              <div className="rounded-sm color-bg-brand p-1">
                <LucideIcon
                  name={
                    AUDIO_EXTENSIONS.includes(
                      item?.metadata?.extension as string
                    )
                      ? 'FileMusic'
                      : 'FileVideo'
                  }
                  size="sm"
                  fill="transparent"
                />
              </div>
            ) : (
              <img
                src={imageUrls[index]}
                alt={item.title}
                className="w-5 h-5 object-cover rounded-sm"
              />
            )
          ) : item.type === 'link' ? (
            faviconUrls[index] ? (
              <img
                src={faviconUrls[index]}
                alt={item.title}
                className=" w-6 h-6 object-cover"
                onError={onError}
              />
            ) : (
              <div className="rounded-sm p-1">
                <Icon name="Placeholder" size="sm" fill="black" />
              </div>
            )
          ) : ['pdf', 'doc', 'xls', 'ppt', '3d'].includes(item.type) ? (
            <DocIcon
              name={getDocIconName(item.type as IDocumentCardProps['type'])}
              size="md"
            />
          ) : (
            <div className="rounded-sm color-bg-brand p-1">
              <Icon name={getIconName(item.type)} size="sm" fill="black" />
            </div>
          )}
        </TableCell>
        <TableCell className="px-0 py-2 lg:py-4 lg:h-12 h-fit flex flex-col flex-1 justify-start lg:max-w-500px lg:w-fit">
          <p className="truncate w-[60vw] lg:max-w-500px lg:w-fit lg:text-wrap">
            {item.type === 'link'
              ? item?.metadata?.title
              : item.type === 'note'
              ? item.metadata?.textContent || 'Untitled Note'
              : item.metadata?.name}
          </p>
          <p className="text-helper-text-sm color- text-secondary lg:hidden">
            {getFileType(item)}
          </p>
          <ButtonGroup className="space-x-0 gap-3 lg:hidden">
            <Button
              onClick={(e) => onLikeClick(e, id)}
              variant="ghost"
              size="md"
              className="min-w-fit p-0"
            >
              <LucideIcon
                name="Heart"
                size="sm"
                fill={isMinted ? 'red' : 'none'}
                stroke={isMinted ? 'red' : 'black'}
                className="mr-1"
              />
              <span className="max-w-fit">
                {item.likeCount && item.likeCount >= 1
                  ? formatCounter(item.likeCount)
                  : 'Like'}
              </span>
            </Button>
            <Button
              onClick={async (e) => {
                e.stopPropagation()
                if (!window.navigator.share) return
                await window.navigator.share({
                  url: `${window.location.origin}/#/${contractAddress}/file/${item.metadata?.fileId}${chainIdQuery}`,
                  title: item.title,
                })
              }}
              variant="ghost"
              size="md"
              className="min-w-fit p-0"
            >
              <Icon name="Share2" size="sm" fill="black" className="mr-1" />
              Share
            </Button>
          </ButtonGroup>
        </TableCell>
        <TableCell className="min-w-[200px] hidden lg:block">
          <div className="hidden lg:block">{getFileType(item)}</div>
        </TableCell>
        <TableCell className="hidden lg:block">
          <div className="w-24 justify-start items-center gap-2 hidden lg:flex">
            <Icon name="Heart" fill="black" stroke="black" />
            {totalHb}
          </div>
        </TableCell>
        <TableCell className="hidden lg:block">
          <div
            className={`w-24 flex relative justify-center items-center gap-2 ${
              hoveredRowIndex !== index && !isShowDropdown && 'invisible'
            }`}
          >
            <Icon
              ref={shareElementRef}
              onClick={() => handleShareButtonClick()}
              className="cursor-pointer"
              name="Share2"
              fill="black"
              stroke="black"
            />
            <div className="cursor-pointer" onClick={(e) => onLikeClick(e, id)}>
              <LucideIcon
                name="Heart"
                size={'md'}
                fill={isMinted ? 'red' : 'none'}
                stroke={isMinted ? 'red' : 'black'}
              />
              {isShowDropdown && (
                <DropdownPortal
                  onOutsideClick={() => setDropdownVisibility(false)}
                  style={dropdownStyle}
                >
                  <div
                    onClick={() => {
                      setDropdownVisibility(false)
                    }}
                    className="absolute bottom-[-200px] z-[99]"
                  >
                    <ShareDropDown
                      filePath={`${window.location.origin}/#/${contractAddress}/file/${item.metadata?.fileId}${chainIdQuery}`}
                    />
                  </div>
                </DropdownPortal>
              )}
            </div>
          </div>
        </TableCell>
      </TableRow>
      {itemId === id ? (
        <HeartBitDialog
          isOpen={itemId === id}
          onLike={() => {
            setMinted(true)
          }}
        />
      ) : null}
    </>
  )
}
