import { generateToken } from '../utils'
import axios from 'axios'

export const processNotification = async ({
  contractAddress,
  credentialEditSecret,
  list,
  invoker,
  chain,
}: {
  contractAddress: string
  credentialEditSecret: string
  list: string[]
  invoker: string
  chain: number
}) => {
  const token = await generateToken(contractAddress, credentialEditSecret || '')
  const URL = `${process.env.REACT_APP_COORDINATOR_URL}/notification/process`
  const requestBody = {
    notifications: list,
  }

  return axios.post(URL, requestBody, {
    headers: {
      Authorization: `Bearer ${token}`,
      invoker,
      contract: contractAddress,
      chain,
    },
  })
}
