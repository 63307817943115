import React from 'react'
import styles from './index.module.scss'
import { File } from 'lucide-react'

export const EmptyState = (props: { text: string }) => {
  return (
    <div className={styles.empty_state}>
      <div>
        <File size="24px" />
      </div>{' '}
      {props.text}
    </div>
  )
}
