/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { Dispatch, ReactNode, SetStateAction, useState } from 'react'
import cn from 'classnames'
import { useMediaMax1025px } from '../../hooks/useMediaQueryHook'
import { ChevronUp, CircleHelp } from 'lucide-react'

interface AccordianProps {
  header: string
  body: ReactNode
  customHeaderBg?: string
  index?: number
  openIndex?: number
  setOpenIndex?: React.Dispatch<SetStateAction<number>>
}

export default function Accordian({
  header,
  body,
  customHeaderBg,
  index,
  openIndex,
  setOpenIndex,
}: AccordianProps) {
  const [isOpen, setIsOpen] = useState<boolean>(openIndex === index)
  return (
    <div
      className="w-[100%] object-scale-down"
      id="accordion-collapse"
      data-accordion="collpase"
    >
      <AccordianHeader
        setOpenIndex={setOpenIndex}
        index={index}
        customHeaderBg={customHeaderBg}
        header={header}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
      />
      {isOpen && <AccordianBody body={body} />}
    </div>
  )
}

const AccordianHeader = ({
  setIsOpen,
  isOpen,
  header,
  customHeaderBg,
  index,
  setOpenIndex,
}: {
  setIsOpen: Dispatch<SetStateAction<boolean>>
  isOpen: boolean
  header: string
  customHeaderBg?: string
  index?: number
  setOpenIndex?: React.Dispatch<SetStateAction<number>>
}) => {
  const isMediaMax1025px = useMediaMax1025px()

  const classNames = cn(
    'flex items-center justify-between w-full p-5 font-medium text-left text-[#000000] border',
    isMediaMax1025px ? 'text-sm' : '',
    isOpen ? 'border-b-0 rounded-t-xl' : 'rounded-xl',
    customHeaderBg ? customHeaderBg : 'bg-[#F1F1F1]',
    'border-[#D9D9D9]'
  )

  return (
    <h2 id="accordion-open-heading-1">
      <button
        onClick={() => {
          setIsOpen(!isOpen)
          if (!isOpen) setOpenIndex && setOpenIndex(index!)
        }}
        type="button"
        className={classNames}
        data-accordion-target="#accordion-open-body-1"
        aria-expanded="true"
        aria-controls="accordion-open-body-1"
      >
        <span className="flex items-center">
          <CircleHelp fill="black" size={20} color="white" className="mr-2" />
          {header}
        </span>
        <div
          className={cn(
            'transition-transform transform duration-300 ease-in-out',
            !isOpen ? 'rotate-180' : 'rotate-0'
          )}
        >
          <ChevronUp />
        </div>
      </button>
    </h2>
  )
}
const AccordianBody = ({ body }: { body: ReactNode }) => {
  const isMediaMax1025px = useMediaMax1025px()

  return (
    <div id="accordion-open-body-1" aria-labelledby="accordion-open-heading-1">
      <div
        className={cn(
          'bg-white p-5 border rounded-b-xl round border-gray-200',
          isMediaMax1025px && 'text-sm'
        )}
      >
        {body}
      </div>
    </div>
  )
}
