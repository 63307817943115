import { fetchAndSaveContract, fetchStorageUsage } from './thunks'
import { createSlice } from '@reduxjs/toolkit'
import { ZeroAddress } from 'ethers'
import { INewContractState } from './interfaces'
import {
  IContract,
  IContractMetadata,
} from '../../types/interface/contract.interface'

export interface IUpdateMetadataPayload {
  contractAddress: string
  metadata: IContractMetadata
}
export interface IUpdateMetadataIPFSUrlPayload {
  contractAddress: string
  metadataLastWorkingIPFSUrl: string
}
export interface IUpdateAgentPayload {
  contractAddress: string
  agentAddress: string
}
export interface IUpdateContractChainIdPayload {
  contractAddress: string
  chainId: number
}

export interface IUpdateMemberPayload {
  contractAddress: string
  members: Array<{ invokerAddress: string }>
}
export interface IUpdateCollaboratorListPayload {
  contractAddress: string
  collaboratorAddress: string
}
export interface ISetCollaboratorsPayload {
  contractAddress: string
  collaborators: string[]
}
export interface ISetContractStoragePayload {
  storage: {
    limit: number
    storageUsed: number
    totalStorage: number
    contractAddress: string
  }
}

const initialState: INewContractState = {
  allContracts: [],
  contracts: {},
  activeContractAddress: ZeroAddress,
}

const contractSlice = createSlice({
  name: 'contract',
  initialState,
  reducers: {
    setActiveContractAddress: (state: INewContractState, action) => {
      const contractAddress = action.payload
      if (state.allContracts.includes(contractAddress)) {
        state.activeContractAddress = contractAddress
      }
    },
    setPortalSignlessMode: (
      state: INewContractState,
      action: { payload: { contractAddress: string; enabled: boolean } }
    ) => {
      const { contractAddress, enabled } = action.payload
      if (state.contracts[contractAddress]) {
        state.contracts[contractAddress].enabled_signless = enabled
      }
    },
    updateContractMetadataState: (
      state: INewContractState,
      action: {
        type: string
        payload: IUpdateMetadataPayload
      }
    ) => {
      const { contractAddress } = action.payload
      if (state.contracts[contractAddress]) {
        state.contracts[contractAddress].metadata = action.payload.metadata
      }
    },
    updatePortalMetadataIPFSUrl: (
      state: INewContractState,
      action: {
        type: string
        payload: IUpdateMetadataIPFSUrlPayload
      }
    ) => {
      const { contractAddress } = action.payload
      if (state.contracts[contractAddress]) {
        state.contracts[contractAddress].metadataLastWorkingIPFSUrl =
          action.payload.metadataLastWorkingIPFSUrl
      }
    },
    setPortalAgent: (
      state: INewContractState,
      action: {
        payload: IUpdateAgentPayload
      }
    ) => {
      const { contractAddress, agentAddress } = action.payload
      if (state.contracts[contractAddress]) {
        state.contracts[contractAddress].agentAddress = agentAddress
      }
    },
    removePortalAgent: (
      state: INewContractState,
      action: {
        payload: {
          contractAddress: string
        }
      }
    ) => {
      const { contractAddress } = action.payload
      if (state.contracts[contractAddress]?.agentAddress) {
        state.contracts[contractAddress].agentAddress = ''
      }
    },
    addContractReducer: (
      state: INewContractState,
      action: {
        payload: IContract
      }
    ) => {
      const contract = action.payload
      const contractAddress = contract.contractAddress
      state.contracts[contractAddress] = contract
      if (!state.allContracts.includes(contractAddress)) {
        state.allContracts.push(contractAddress)
      }
    },
    addContractMember: (
      state: INewContractState,
      action: {
        payload: { contractAddress: string; invokerAddress: string }
        type: string
      }
    ) => {
      const contractAddress = action.payload.contractAddress
      state.contracts[contractAddress].members.push({
        invokerAddress: action.payload.invokerAddress,
      })
    },
    savePortalMemberList: (
      state: INewContractState,
      action: {
        payload: IUpdateMemberPayload
      }
    ) => {
      const contractAddress = action.payload.contractAddress
      if (contractAddress in state.contracts) {
        state.contracts[contractAddress].members = action.payload.members
      }
    },
    addCollaboratorToStore: (
      state: INewContractState,
      action: {
        payload: IUpdateCollaboratorListPayload
        type: string
      }
    ) => {
      const contractAddress = action.payload.contractAddress
      const collaboratorAddress = action.payload.collaboratorAddress
      if (contractAddress in state.contracts) {
        const c = state.contracts[contractAddress].collaborators
        state.contracts[contractAddress].collaborators = [
          collaboratorAddress,
          ...c,
        ]
      }
    },
    removeContractCollaborator: (
      state: INewContractState,
      action: {
        payload: IUpdateCollaboratorListPayload
        type: string
      }
    ) => {
      const contractAddress = action.payload.contractAddress
      const collaboratorAddress = action.payload.collaboratorAddress
      if (contractAddress in state.contracts) {
        const collaboratorState = state.contracts[contractAddress].collaborators
        const newList = collaboratorState.filter(
          (i) => i !== collaboratorAddress
        )
        state.contracts[contractAddress].collaborators = newList
      }
    },
    setContractChainId: (
      state: INewContractState,
      action: { type: string; payload: IUpdateContractChainIdPayload }
    ) => {
      const contractAddress = action.payload.contractAddress
      if (
        state.contracts[contractAddress] &&
        !state.contracts[contractAddress].chainId
      ) {
        state.contracts[contractAddress].chainId = action.payload.chainId
      }
    },
    saveCollaboratorsList: (
      state: INewContractState,
      action: {
        payload: ISetCollaboratorsPayload
      }
    ) => {
      const contractAddress = action.payload.contractAddress
      if (contractAddress in state.contracts) {
        state.contracts[contractAddress].collaborators =
          action.payload.collaborators
      }
    },
    updatePortalStorage: (
      state: INewContractState,
      action: {
        payload: ISetContractStoragePayload
      }
    ) => {
      const { storage } = action.payload
      const { contractAddress } = storage
      if (contractAddress in state.contracts) {
        const portal = state.contracts[contractAddress]

        portal.storage = {
          limit: storage.limit,
          storageUsed: storage.storageUsed,
          totalStorage: storage.totalStorage,
        }
      }
    },
    updateContractLayoutFileId: (
      state: INewContractState,
      action: {
        payload: { contractAddress: string; layoutFileId: string }
      }
    ) => {
      const { contractAddress, layoutFileId } = action.payload
      if (state.contracts[contractAddress]) {
        state.contracts[contractAddress].publicLayoutFileId = layoutFileId
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchAndSaveContract.fulfilled,
      (state: INewContractState, action) => {
        const contract = action.payload
        if (contract) {
          const contractAddress = contract.contractAddress
          state.contracts[contractAddress] = contract
          if (!state.allContracts.includes(contractAddress)) {
            state.allContracts.push(contractAddress)
          }
        }
      }
    ),
      builder.addCase(
        fetchStorageUsage.fulfilled,
        (state: INewContractState, action) => {
          const storage = action.payload
          const { contractAddress } = storage
          const portal = state.contracts[contractAddress]
          portal.storage = {
            limit: storage.limit,
            storageUsed: storage.storageUsed,
            totalStorage: storage.totalStorage,
          }
        }
      )
  },
})

export const {
  addContractReducer,
  setActiveContractAddress,
  addContractMember,
  addCollaboratorToStore,
  updateContractMetadataState,
  setContractChainId,
  saveCollaboratorsList,
  savePortalMemberList,
  removeContractCollaborator,
  setPortalSignlessMode,
  setPortalAgent,
  removePortalAgent,
  updatePortalStorage,
  updatePortalMetadataIPFSUrl,
  updateContractLayoutFileId,
} = contractSlice.actions

export default contractSlice.reducer
