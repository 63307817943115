import { useEffect, useState } from 'react'
import { useContract } from '../store/contract/hooks'
import { useParams } from 'react-router-dom'
import { getLogoUrl } from '../utils/getLogoUrl'

const usePortalLogo = () => {
  const { address: contractAddress } = useParams()
  const [contractLogo, setContractLogo] = useState<string>('')
  const contract = useContract((contractAddress as string) || '')
  const getLogo = async () => {
    const fileUrl = await getLogoUrl(contract?.metadata.logo)
    if (fileUrl) {
      setContractLogo(fileUrl)
    }
  }
  useEffect(() => {
    getLogo()
  }, [contract])
  return contractLogo
}

export default usePortalLogo
