/* eslint-disable @typescript-eslint/no-explicit-any */
import { ISEAPair } from 'gun'
import { SEA } from './collaboration/utils'
import { importRSADecryptionKey, importRSAEncryptionKey } from './libCrypto'
import { convertTypedArrayToString, convertStringToTypedArray } from './string'
import * as CryptoJS from 'crypto-js'

export const encryptUsingRSAKey = async (
  value: Record<any, any> | string,
  serverEncryptionKey: string
): Promise<string> => {
  try {
    const encryptionKey = await importRSAEncryptionKey(serverEncryptionKey)
    const enc = new TextEncoder()
    const encoded = enc.encode(JSON.stringify(value))
    const encrypted = await window.crypto.subtle.encrypt(
      {
        name: 'RSA-OAEP',
      },
      encryptionKey,
      encoded
    )
    const uint8 = new Uint8Array(encrypted)
    const base64 = convertTypedArrayToString(uint8)
    return base64
  } catch (error: any) {
    console.log(error)
    throw new Error(error?.message)
  }
}

export const encryptUsingAESKey = (value: any, key: string) => {
  return CryptoJS.AES.encrypt(JSON.stringify(value), key).toString()
}

export const encrypUsingSEAKey = async (data: any, key: ISEAPair) => {
  const content = await SEA.encrypt(data, key)
  return content
}

export const decryptUsingSEAKey = async (data: any, key: ISEAPair) => {
  const content = await SEA.decrypt(data, key)
  return content
}

export const decryptUsingRSAKey = async (
  encrypted: string,
  serverDecryptionKey: string
): Promise<string> => {
  const decryptionKey = await importRSADecryptionKey(serverDecryptionKey) // import key from the key exported format saved as the invokers server decryption keys
  const encryptedTypedArray = convertStringToTypedArray(encrypted)
  // decrypt the data that was encrypted with server encryption key (public key)
  const decrypted = await window.crypto.subtle.decrypt(
    {
      name: 'RSA-OAEP',
    },
    decryptionKey,
    encryptedTypedArray.buffer // data encrypted with server encrytion key
  )
  const dec = new TextDecoder()
  return dec.decode(decrypted)
}

export const decryptUsingAESKey = (value: string, key: string) => {
  return JSON.parse(
    CryptoJS.AES.decrypt(value, key).toString(CryptoJS.enc.Utf8)
  )
}
