import { NOTIFICATION_TYPE, Store } from 'react-notifications-component'

export default function sendNotifcation(
  title: string,
  message: string,
  type: NOTIFICATION_TYPE,
  duration?: number
) {
  Store.addNotification({
    title,
    message,
    type,
    insert: 'top',
    container: 'bottom-left',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: duration ? duration : 5000,
      onScreen: true,
    },
  })
}

export const clearAllNotification = () => Store.removeAllNotifications()
