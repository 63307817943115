/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FileSource, FileTypeEnum } from '../../../types/enum/file.enum'
import {
  IFileChatKeyPair,
  IFileMetaData,
} from '../../../types/interface/file.interface'
import { IServerKey } from '../../../types/interface/invoker.interface'
import encryptFile from './encryptFile'
import getFileExtension from '../getFileExtension'
import { MakeFileFromObject } from '../../makeFileFromObject'
import { createLock } from './createLock'
import { getFileTags } from '../../getApplicableFileTags'

interface EncryptedFileResponse {
  encryptedFile: File
  metadataFile: File
  metadata: IFileMetaData
}

export const createPrivateFile = async (
  file: File,
  serverKeys: IServerKey,
  fileChatKeyPair: IFileChatKeyPair,
  fileSource: FileSource,
  owner: string,
  coverIPFSHash?: string,
  commentsEnabled?: boolean,
  emoji?: string,
  version = 1,
  arweaveHash?: string
): Promise<EncryptedFileResponse> => {
  const fileOriginal = file
  const { decryptionObject: fileKey, encryptedFile } = await encryptFile(
    fileOriginal
  )
  const dataKeyMaterial = {
    fileKey,
    fileChatKeyPair,
  }

  const portalLock = await createLock(
    serverKeys.portalEncryptionKey,
    dataKeyMaterial
  )

  const ownerLock = await createLock(
    serverKeys.ownerEncryptionKey,
    dataKeyMaterial
  )
  const currentTime = Math.floor(Date.now() / 1000)

  // Check if the FILESOURCE is ARWEAVE

  if (fileSource === FileSource.ARWEAVE && !arweaveHash) {
    if (!arweaveHash) throw new Error('Arweave hash not found')
  }

  const metadata: IFileMetaData = {
    name: fileOriginal.name,
    size: fileOriginal.size,
    mimeType: fileOriginal.type,
    extension: getFileExtension(fileOriginal.name) as string,
    fileType: FileTypeEnum.PRIVATE,
    source: fileSource,
    portalLock,
    ownerLock,
    memberLock: null,
    tokenLock: null,
    publicLock: null,
    owner,
    uploadedAt: currentTime,
    coverIPFSHash,
    commentsEnabled,
    emoji,
    version,
    tag: getFileTags(FileTypeEnum.PRIVATE, fileSource),
    arweaveHash,
  }
  const metadataFilename = `${fileOriginal.name}.metadata.json`
  const metadataFile = MakeFileFromObject(metadata, metadataFilename)
  return {
    encryptedFile,
    metadataFile,
    metadata,
  }
}
export const createMemberPrivateFile = async (
  file: File,
  serverKeys: IServerKey,
  fileChatKeyPair: IFileChatKeyPair,
  fileSource: FileSource,
  owner: string,
  coverIPFSHash?: string,
  commentsEnabled?: boolean,
  emoji?: string,
  version = 1,
  arweaveHash?: string
): Promise<EncryptedFileResponse> => {
  const fileOriginal = file
  const { decryptionObject: fileKey, encryptedFile } = await encryptFile(
    fileOriginal
  )
  const dataKeyMaterial = {
    fileKey,
    fileChatKeyPair,
  }
  const memberLock = await createLock(
    serverKeys.memberEncryptionKey,
    dataKeyMaterial
  )
  const portalLock = await createLock(
    serverKeys.portalEncryptionKey,
    dataKeyMaterial
  )
  const ownerLock = await createLock(
    serverKeys.ownerEncryptionKey,
    dataKeyMaterial
  )
  const currentTime = Math.floor(Date.now() / 1000)

  // Check if the FILESOURCE is ARWEAVE

  if (fileSource === FileSource.ARWEAVE && !arweaveHash) {
    throw new Error('Arweave hash not found')
  }

  const metadata: IFileMetaData = {
    name: fileOriginal.name,
    size: fileOriginal.size,
    mimeType: fileOriginal.type,
    extension: getFileExtension(fileOriginal.name) as string,
    version,
    fileType: FileTypeEnum.MEMBERS_PRIVATE,
    source: fileSource,
    portalLock,
    ownerLock,
    memberLock,
    tokenLock: null,
    publicLock: null,
    owner,
    uploadedAt: currentTime,
    coverIPFSHash,
    commentsEnabled,
    emoji,
    tag: getFileTags(FileTypeEnum.MEMBERS_PRIVATE, fileSource),
    arweaveHash,
  }
  const metadataFilename = `${fileOriginal.name}.metadata.json`
  const metadataFile = MakeFileFromObject(metadata, metadataFilename)
  return {
    encryptedFile,
    metadataFile,
    metadata,
  }
}

export const createTokenGatedFile = async (
  file: File,
  gateKey: string,
  serverKeys: IServerKey,
  fileChatKeyPair: IFileChatKeyPair,
  fileSource: FileSource,
  owner: string,
  coverIPFSHash?: string,
  commentsEnabled?: boolean,
  emoji?: string,
  version = 1,
  arweaveHash?: string
): Promise<EncryptedFileResponse> => {
  const { decryptionObject: fileKey, encryptedFile } = await encryptFile(file)
  const dataKeyMaterial = {
    fileKey,
    fileChatKeyPair,
  }
  const portalLock = await createLock(
    serverKeys.portalEncryptionKey,
    dataKeyMaterial
  )
  const ownerLock = await createLock(
    serverKeys.ownerEncryptionKey,
    dataKeyMaterial
  )
  const tokenLock = await createLock(
    gateKey,
    dataKeyMaterial,
    FileTypeEnum.GATED
  )
  const currentTime = Math.floor(Date.now() / 1000)

  // Check if the FILESOURCE is ARWEAVE

  if (fileSource === FileSource.ARWEAVE && !arweaveHash) {
    throw new Error('Arweave hash not found')
  }
  const metadata: IFileMetaData = {
    name: file.name,
    size: file.size,
    mimeType: file.type,
    extension: getFileExtension(file.name) as string,
    version,
    fileType: FileTypeEnum.GATED,
    source: fileSource,
    tokenLock,
    ownerLock,
    portalLock,
    memberLock: null,
    publicLock: null,
    owner,
    uploadedAt: currentTime,
    coverIPFSHash,
    emoji,
    tag: getFileTags(FileTypeEnum.GATED, fileSource),
    arweaveHash,
  }
  const metadataFilename = `${file.name}.metadata.json`
  const metadataFile = MakeFileFromObject(metadata, metadataFilename)
  return {
    encryptedFile,
    metadataFile,
    metadata,
  }
}

export const verifyUserFileAccess = (
  fileMetadata: IFileMetaData,
  serverKeys: IServerKey,
  isMember?: boolean
) => {
  const isPublic = fileMetadata.fileType == FileTypeEnum.PUBLIC
  const isPrivate = fileMetadata.fileType == FileTypeEnum.PRIVATE
  const isMemberPrivate = fileMetadata.fileType == FileTypeEnum.MEMBERS_PRIVATE
  const isTokenGated = fileMetadata.fileType === FileTypeEnum.GATED
  if (isPublic) {
    return true
  }
  if (isMemberPrivate && (isMember || serverKeys.memberDecryptionKey)) {
    return true
  }
  if (isPrivate && serverKeys.portalDecryptionKey) {
    return true
  }
  if (isTokenGated) {
    return true
  }
  return false
}
