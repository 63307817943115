/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { SetStateAction, useEffect, useRef } from 'react'
import cn from 'classnames'
import { getTrimmedName } from '../../utils/string'
import { useParams } from 'react-router-dom'
import { useMediaQuery } from '@mui/material'
import { useContractChainId } from '../../store/contract/hooks'
import { usePrivyHelper } from '../../hooks/usePrivyHelper'
import { DynamicModal } from '../../pages/PublicPortal/components/DynamicModal'
import { SquareArrowOutUpRight } from 'lucide-react'

interface WebPageProofPublishProps {
  proofPublishOpen: boolean
  setProofPublishOpen: React.Dispatch<SetStateAction<boolean>>
  fileData: any
}

const WebPageProofPublish = ({
  proofPublishOpen,
  setProofPublishOpen,
  fileData,
}: WebPageProofPublishProps) => {
  const walletAddress = usePrivyHelper().walletAddress
  const isMediaMax1025px = useMediaQuery('(max-width : 1025px)')
  const trimLength = isMediaMax1025px ? 7 : 15
  const trimLimit = 30
  const contentIPFSHash = fileData?.contentIPFSHash.split('/')[0]
  const { address: contractAddress } = useParams()
  const contractChainId = useContractChainId(contractAddress as string)

  const ref = useRef<HTMLDivElement>(null)

  const handleClickOutside = (event: any) => {
    if (ref && ref.current && !ref.current.contains(event.target)) {
      setProofPublishOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  const getContractLink = (contractAddress: string) => {
    if (contractChainId === 100)
      return `https://gnosisscan.io/address/${contractAddress}`
    if (contractChainId === 11155111)
      return `https://sepolia.etherscan.io/address/${contractAddress}`
  }

  const getIPFSHashLink = (ipfsHash: string) => {
    if (contractChainId === 100)
      return `https://ipfs.fileverse.io/ipfs/${ipfsHash}`
    if (contractChainId === 11155111) return `https://w3s.link/ipfs/${ipfsHash}`
  }

  const links = [
    {
      name: 'Author Address',
      link: getContractLink(walletAddress as string),
      trimmedName: getTrimmedName(
        walletAddress as string,
        trimLength,
        trimLimit
      ),
    },
    {
      name: 'Content Hash (IPFS)',
      link: getIPFSHashLink(contentIPFSHash as string),
      trimmedName: getTrimmedName(
        contentIPFSHash as string,
        trimLength,
        trimLimit
      ),
    },
    {
      name: 'Onchain Record',
      link: getContractLink(contractAddress as string),
      trimmedName: getTrimmedName(
        contractAddress as string,
        trimLength,
        trimLimit
      ),
    },
  ]

  return (
    <DynamicModal
      open={proofPublishOpen}
      onOpenChange={setProofPublishOpen}
      className="w-full max-w-[600px]"
      title="Proof of Publishing"
      content={
        <div
          className={cn(
            {
              'w-full': isMediaMax1025px,
              'w-[523.25px]': !isMediaMax1025px,
            },
            `h-[162px] flex-col justify-start items-start inline-flex`
          )}
        >
          {links.map((link, index) => (
            <div
              key={index}
              className="self-stretch h-[54px] px-5 py-[5px] hover:color-bg-secondary-hover bg-white flex-col justify-center items-start gap-2.5 flex"
            >
              <a
                className="cursor-pointer w-full"
                href={link.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="flex-col justify-start items-start gap-[3px] flex">
                  <div className="justify-start items-center gap-[5px] inline-flex">
                    <div className="text-heading-sm-bold flex gap-2 items-center">
                      {link.name}
                      <SquareArrowOutUpRight size={16} />
                    </div>
                  </div>
                  <div className="text-body-sm">{link.trimmedName}</div>
                </div>
              </a>
            </div>
          ))}
        </div>
      }
    />
  )
}

export default WebPageProofPublish
