import { generateToken } from '../utils'
import axios from 'axios'

interface CreateGateRequest {
  params: string[]
  contractAddress: string
  credentialEditSecret: string
  invoker: string
  includeCollaborators: boolean
  includeMembers?: boolean
  chain: number
}

const createGateApi = async ({
  params,
  contractAddress,
  credentialEditSecret,
  invoker,
  includeCollaborators,
  includeMembers,
  chain,
}: CreateGateRequest) => {
  const token = await generateToken(contractAddress, credentialEditSecret || '')
  const URL = `${process.env.REACT_APP_GATE_URL}/gate/create`
  const requestBody = {
    params,
    includeCollaborators,
    includeMembers,
  }

  return axios.post(URL, requestBody, {
    headers: {
      Authorization: `Bearer ${token}`,
      invoker,
      contract: contractAddress,
      chain,
    },
  })
}
export default createGateApi
