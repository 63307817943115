/* eslint-disable @typescript-eslint/no-explicit-any */
import { uploadFileAPI } from '../../api/fileAPI/uploadFile'
import { getPublicPortalApiCall } from '../../api/portal'
import { defaultNetwork } from '../../config/network-config'
import { PublicPortalLayout } from '../../pages/PublicPortal/interfaces'
import {
  getInvokerStateFromIndexDb,
  unsafe_getIdbContractByAddress,
} from '../../store/middleware/utils'
import { FileTypeEnum } from '../../types/enum/file.enum'
import { IContractMetadata } from '../../types/interface/contract.interface'
import { hasAgent } from '../agentHandler'
import { JSON_CONTENT_TYPE } from '../constants'
import { getContractFile, getReadOnlyContract } from '../contract'
import { createPublicFile } from '../files'
import { getIPFSAsset } from '../getIPFSAsset'
import { MakeFileFromObject } from '../makeFileFromObject'
import {
  addFileTrxCall,
  editFileTrxCall,
  updatePortalMetadataCall,
} from '../transaction'
import { ContractType } from '../transaction/interface'
import { ContractConfigMap } from '../transaction/trxUtils'
import { uploadToIPFS } from '../uploadUtills'

export const PUBLIC_PORTAL_FILE_NAME = 'public_portal_config.json'
export const BASE_PUBLIC_PORTAL_FILE: PublicPortalLayout = {
  coverIPFSUrl: '',
  avatarIPFSUrl: '',
  description: '',
  name: '',
  socials: {
    twitter: '',
    linkedin: '',
    instagram: '',
    facebook: '',
  },
  sections: [],
  layoutSwitchEnabled: true,
  commentsEnabled: true,
} as const

export const PUBLIC_PORTAL_METADATA_FILE_PREFIX =
  'fileverse_public_portal_metadata_file_'

interface BaseArgs {
  address: string
  contractAddress: string
  isSignless: boolean
}

interface CreatePublicPortalFileArgs extends BaseArgs {
  publicPortalData: any
}

interface UpdatePublicPortalFileArgs extends BaseArgs {
  publicPortalData: any
  fileId: string
}

export const generatePublicPortalJSON = (publicPortalJSON?: any) => {
  const fileBlob = new Blob(
    [JSON.stringify(publicPortalJSON || BASE_PUBLIC_PORTAL_FILE)],
    {
      type: JSON_CONTENT_TYPE,
    }
  )

  return new File([fileBlob], PUBLIC_PORTAL_FILE_NAME, {
    type: 'application/json',
    lastModified: Date.now(),
  })
}

export const updatePublicPortalFileCall = async (
  updateArgs: UpdatePublicPortalFileArgs
) => {
  const { address, contractAddress, isSignless, publicPortalData, fileId } =
    updateArgs
  const updatedPublicPortalJSON = generatePublicPortalJSON(publicPortalData)
  const updatedPublicPortalFile = await createPublicFile(
    address,
    updatedPublicPortalJSON
  )

  const { contentIpfsHash, metadataIpfsHash } = await uploadToIPFS({
    contractAddress,
    invoker: address,
    metadataFile: updatedPublicPortalFile.metadataFile,
    contentFile: updatedPublicPortalFile.contentFile,
    fileTags: updatedPublicPortalFile.fileTags,
  })
  const isTrulySignless = (await hasAgent(contractAddress)) && isSignless
  await editFileTrxCall(
    {
      contentIpfsHash,
      contractAddress,
      fileId: Number(fileId),
      metadataIpfsHash: PUBLIC_PORTAL_METADATA_FILE_PREFIX + metadataIpfsHash,
      fileType: FileTypeEnum.PUBLIC,
      gateIpfsHash: '',
      walletAddress: address,
    },
    isTrulySignless
  )
}

export const createAndUploadPublicPortalFile = async (
  createArgs: CreatePublicPortalFileArgs
) => {
  const { address, contractAddress, isSignless, publicPortalData } = createArgs
  const publicPortalJSON = generatePublicPortalJSON(publicPortalData)
  const publicPortalFile = await createPublicFile(address, publicPortalJSON)

  const { contentIpfsHash, metadataIpfsHash } = await uploadToIPFS({
    contractAddress,
    invoker: address,
    metadataFile: publicPortalFile.metadataFile,
    contentFile: publicPortalFile.contentFile,
    fileTags: publicPortalFile.fileTags,
  })
  const isTrulySignless = (await hasAgent(contractAddress)) && isSignless

  const fileId = await addFileTrxCall(
    {
      contractAddress,
      metadataIpfsHash: PUBLIC_PORTAL_METADATA_FILE_PREFIX + metadataIpfsHash,
      contentIpfsHash,
      gateIpfsHash: '',
      fileType: FileTypeEnum.PUBLIC,
      walletAddress: address,
    },
    isTrulySignless
  )

  return {
    fileId,
    contentIpfsHash,
    metadataIpfsHash,
    publicPortalFileMetadata: publicPortalFile.metadata,
  }
}

export const updatePortalMetadata = async (
  updatedMetadata: IContractMetadata,
  contractAddress: string,
  address: string
) => {
  const metadataFile = MakeFileFromObject(updatedMetadata, 'metadata.json')
  const { invokers } = await getInvokerStateFromIndexDb()

  const credential = invokers[address]?.credentials[contractAddress]

  const metadataUploadResponse = await uploadFileAPI({
    credential,
    file: metadataFile,
    name: 'metadata.json',
    contractAddress: contractAddress as string,
    invoker: address,
    chain: defaultNetwork.chainId,
  })

  await updatePortalMetadataCall({
    contractAddress: contractAddress as string,
    walletAddress: address as string,
    metadataIpfsHash: metadataUploadResponse.data.ipfsHash,
  })
}
// TODO : Refactor
export const getPublicPortalLayoutData = async (
  contractAddress: string,
  publicLayoutFileId: string
) => {
  const publicLayoutFileData = await getContractFile(
    Number(publicLayoutFileId),
    contractAddress
  )
  const result = await getIPFSAsset({
    ipfsHash: publicLayoutFileData?.contentIPFSHash.replace(
      PUBLIC_PORTAL_METADATA_FILE_PREFIX,
      ''
    ) as string,
  })

  return result?.data as PublicPortalLayout
}

export const fetchLayoutOnChain = async (contractAddress: string) => {
  const fileId = await getPublicPortalLayoutFileIdOnChain(contractAddress)
  if (!fileId) return
  return await getPublicPortalLayoutData(contractAddress, fileId)
}

export const getPublicPortalLayout = async (
  contractAddress: string,
  publicLayoutFileId?: string
) => {
  try {
    if (publicLayoutFileId)
      return await getPublicPortalLayoutData(
        contractAddress,
        publicLayoutFileId
      )

    const contract = await unsafe_getIdbContractByAddress(contractAddress)
    if (!contract || !contract?.publicLayoutFileId)
      return await fetchLayoutOnChain(contractAddress)

    return await getPublicPortalLayoutData(
      contractAddress,
      contract.publicLayoutFileId
    )
  } catch (err) {
    console.log(err)
  }
}

export const getPublicPortalLayoutFileIdOnChain = async (
  contractAddress: string
) => {
  try {
    const contract = getReadOnlyContract(
      contractAddress,
      ContractConfigMap[ContractType.Portal].abi
    )
    const totalFiles = Number(await contract.getFileCount())
    if (totalFiles === 0) return
    const allPossibleFiles = []
    for (let i = 0; i < totalFiles - 1; i++) {
      const file = await contract.files(i)
      if (
        file.metadataIPFSHash &&
        file.metadataIPFSHash.includes(PUBLIC_PORTAL_METADATA_FILE_PREFIX)
      ) {
        allPossibleFiles.push(i)
      }
    }
    const latestFileId = allPossibleFiles.pop()
    return latestFileId?.toString()
  } catch (err) {
    console.error(err)
  }
}

export const getPublicPortalDataFromApi = async (contractAddress: string) => {
  try {
    const { data } = await getPublicPortalApiCall(contractAddress)
    const portalData = data?.portals?.pop() || {}
    const queue = portalData?.queueJobs || []
    const { fileId, resolvedContent } = portalData
    const queuedFileId = queue?.pop()?.jobData?.publicLayoutFileId
    return {
      layoutFileId: fileId || queuedFileId,
      resolvedContent,
      isIndexing: queue.length > 0,
    }
  } catch (err) {
    console.log(err)
    return {
      isIndexing: false,
    }
  }
}
