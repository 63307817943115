import axios from 'axios'
import { generateToken } from '../utils'

interface Request {
  contractAddress: string
  editSecret: string
  invoker: string
  chain: number
}

export const storageUsageAPI = async (request: Request) => {
  if (!request.editSecret) return null
  const token = await generateToken(
    request.contractAddress,
    request.editSecret || ''
  )
  const res = await axios.get(
    `${process.env.REACT_APP_STORAGE_URL}/limit/use`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        contract: request.contractAddress,
        invoker: request.invoker || '',
        chain: request.chain,
      },
    }
  )
  return res.data
}
