import {
  INotificationContent,
  NotificationAudience,
  postNotification as _postNotification,
} from '../api/notification/postNotification'
import { NotificationType } from '../types/enum/notification'
import { useParams, useSearchParams } from 'react-router-dom'
import { useInvokerCredential } from '../store/invoker/hooks'
import { useMutation } from '@tanstack/react-query'
import { usePrivyHelper } from './usePrivyHelper'

export const usePostNotification = () => {
  const walletAddress = usePrivyHelper().walletAddress
  const { address: contractAddress } = useParams()
  const [urlParams] = useSearchParams()
  const chainId = parseInt(urlParams.get('chainId') || '')
  const credential = useInvokerCredential(
    walletAddress as string,
    contractAddress as string
  )
  const { mutate: postNotification } = useMutation({
    mutationFn: ({
      audience,
      message,
      type,
      content,
    }: {
      audience: NotificationAudience
      message: string
      type: NotificationType
      content: INotificationContent
    }) => {
      const notification = {
        type,
        message,
        audience,
        content,
        portalAddress: contractAddress as string,
      }
      return _postNotification({
        contractAddress: contractAddress as string,
        credentialEditSecret: credential?.editSecret,
        invoker: walletAddress as string,
        chain: chainId,
        notification,
      })
    },
  })

  return postNotification
}
