/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import Spinner from '../Spinner'
import { Tooltip } from '@mui/material'

interface ButtonType {
  title: string
  onClick?: (event?: any) => void
  isDisabled?: boolean
  isLoading?: boolean
  backgroundColorClassName?: string
  cyId?: string
  id?: string
  btnref?: any
  leftIcon?: JSX.Element | string
  rightIcon?: JSX.Element | string
  width?: string
  tooltip?: string
}
const CTAButton = ({
  title,
  isDisabled,
  onClick,
  isLoading,
  btnref,
  cyId,
  backgroundColorClassName,
  id,
  leftIcon,
  rightIcon,
  width,
  tooltip,
}: ButtonType) => {
  return (
    <Tooltip arrow={true} title={tooltip ? tooltip : ''}>
      <button
        ref={btnref}
        id={id}
        data-cy={cyId}
        onClick={(event) => {
          if (onClick && !isLoading) onClick(event)
        }}
        disabled={isDisabled}
        className={`${
          isDisabled
            ? 'opacity-50 bg-gray-400 text-white cursor-not-allowed'
            : `${
                backgroundColorClassName
                  ? backgroundColorClassName
                  : 'bg-white border-black border-2'
              }`
        } ${
          width ? width : ' min-w-32'
        } flex font-extrabold justify-center  items-center box-border px-6 py-2 h-10 rounded-md`}
      >
        {isLoading ? (
          <Spinner stroke="white" />
        ) : (
          <div className="flex gap-2 w-full h-full items-center">
            {typeof leftIcon === 'string' ? (
              <img src={leftIcon} className="w-4" />
            ) : (
              leftIcon
            )}
            <p className="text-center w-full">{title}</p>
            {typeof leftIcon === 'string' ? (
              <img src={leftIcon} className="w-4" />
            ) : (
              rightIcon
            )}
          </div>
        )}
      </button>
    </Tooltip>
  )
}

export default CTAButton
