import React, { SetStateAction, useEffect, useState } from 'react'
import { LucideIcon, TextField } from '@fileverse/ui'
import { DynamicModal } from '../../pages/PublicPortal/components/DynamicModal'
import { Button } from '../../pages/PublicPortal/components/Button'
import { useParams, useSearchParams } from 'react-router-dom'
import { InvalidAddressError } from 'viem'
import { addCollaboratorToStore } from '../../store/contract/reducer'
import { useAppDispatch } from '../../store/hooks'
import {
  CollaboratorAlreadyExistsError,
  RelayerError,
  SwitchNetworkRejected,
} from '../../utils/errors'
import { getValidEvmAddress } from '../../utils/ethUtils'
import sendNotifcation from '../../utils/notification'
import { addCollaboratorCall } from '../../utils/transaction'
import { usePrivyHelper } from '../../hooks/usePrivyHelper'
import { PeopleData } from './PeopleTable'
import copyToClipboard from 'copy-to-clipboard'
import { useTasksContext } from '../../hooks/useTasksContext'
import { TaskID } from '../Tasks/TasksProvider'

export default function InviteCollaborator({
  openInvitePopup,
  setOpenInvitePopup,
  allCollaborators,
  setPeopleData,
  newCollaboratorAddress,
  triggerCollaboration,
  isSafeApp,
}: {
  openInvitePopup: boolean
  setOpenInvitePopup: React.Dispatch<SetStateAction<boolean>>
  allCollaborators: string[]
  setPeopleData: React.Dispatch<SetStateAction<PeopleData[]>>
  newCollaboratorAddress?: string
  triggerCollaboration?: boolean
  isSafeApp: boolean
}) {
  const dispatch = useAppDispatch()
  const contractAddress = useParams().address as string
  const { walletAddress } = usePrivyHelper()
  const invoker = walletAddress as string
  const [searchParams] = useSearchParams()
  const chainId = parseInt(searchParams.get('chainId') || '')
  const taskContext = useTasksContext()

  const [collaboratorAddress, setCollaboratorAddress] = useState<string>('')
  const [invitingCollaborator, setInvitingCollaborator] =
    useState<boolean>(false)
  const [inviteSuccessful, setInviteSuccessful] = useState<boolean>(false)

  const copyPortalURL = `${window.origin}${window.location.pathname}#/${contractAddress}/member?chainId=${chainId}`

  const addCollaborator = async (newCollaboratorAddress?: string) => {
    try {
      setInvitingCollaborator(true)

      const address = newCollaboratorAddress ?? collaboratorAddress
      const validCollaboratorAddress = await getValidEvmAddress(address)

      if (allCollaborators.includes(validCollaboratorAddress))
        throw new CollaboratorAlreadyExistsError('Collaborator already exists')

      await addCollaboratorCall({
        walletAddress: invoker,
        contractAddress,
        collaboratorAddress: validCollaboratorAddress,
      })

      dispatch(
        addCollaboratorToStore({
          contractAddress,
          collaboratorAddress: validCollaboratorAddress,
        })
      )

      if (!isSafeApp) {
        setPeopleData((prev) => {
          return [
            ...prev,
            {
              address: collaboratorAddress,
              role: 'Collaborator',
              joinedOn: Date.now(),
            },
          ]
        })
      }

      setInviteSuccessful(true)
      sendNotifcation(
        'Successfully added collaborator',
        `You have successfully added ${validCollaboratorAddress} as a collaborator of this portal`,
        'success'
      )
      //   toggleNewCollaboratorPopup(true)
    } catch (error) {
      if (error instanceof InvalidAddressError)
        sendNotifcation(
          'Failed to add collaborator',
          'Invalid ens name or address',
          'danger'
        )
      else if (error instanceof CollaboratorAlreadyExistsError)
        sendNotifcation(
          'Failed to add collaborator',
          'Address is already a collaborator',
          'danger'
        )
      else if (error instanceof RelayerError)
        sendNotifcation('Failed to add collaborator', 'Relayer error', 'danger')
      else if (error instanceof SwitchNetworkRejected)
        sendNotifcation(
          'Failed to add collaborator',
          'Please switch to the correct network',
          'danger'
        )
      else
        sendNotifcation(
          'Failed to add collaborator',
          'An unexpected error occurred',
          'danger'
        )
      console.log(error)
    } finally {
      setInvitingCollaborator(false)
      setCollaboratorAddress('')
    }
  }

  const handleCopyButton = () => {
    copyToClipboard(copyPortalURL)
    sendNotifcation('Link copied', '', 'success')
    setTimeout(function () {
      taskContext?.updateTask(
        TaskID.INVITE_PORTAL_COLLABORATOR,
        'Invite a collaborator to a portal'
      )
    }, 1000)
  }

  const handleCloseInvitePopup = (open: boolean) => {
    if (invitingCollaborator) return
    setCollaboratorAddress('')
    setInvitingCollaborator(false)
    setInviteSuccessful(false)
    setOpenInvitePopup(open)
  }

  useEffect(() => {
    if (triggerCollaboration && newCollaboratorAddress) {
      addCollaborator(newCollaboratorAddress)
    }
  }, [triggerCollaboration, newCollaboratorAddress])

  return (
    <DynamicModal
      className="max-w-[600px]"
      open={openInvitePopup}
      onOpenChange={handleCloseInvitePopup}
      title={inviteSuccessful ? 'Collaborator Added' : 'Invite Collaborator'}
      content={
        inviteSuccessful ? (
          <div className="flex flex-col gap-3">
            <p className="text-body-sm">
              Congrats! Collaborator has been added to the portal. Please share
              this link with them for next steps.
            </p>
            <div className="flex gap-4">
              <TextField
                defaultValue={copyPortalURL}
                placeholder="Wallet Address"
                className="h-[40px] min-w-[450px] w-[450px] shadow-none border-[1px]"
              />
              <Button
                size="lg"
                className="min-w-[85px] w-[85px]"
                onClick={handleCopyButton}
              >
                Copy
              </Button>
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-3">
            <div className="flex gap-4 sm:flex-row flex-col">
              <TextField
                value={collaboratorAddress}
                onChange={(e) => setCollaboratorAddress(e.target.value)}
                placeholder="Wallet Address"
                className="h-[40px] md:min-w-[450px] md:w-[450px] min-w-[70vw] w-[70vw] shadow-none border-[1px]"
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    addCollaborator()
                  }
                }}
                disabled={invitingCollaborator}
              />
              <Button
                size="lg"
                className="min-w-[85px] w-[85px]"
                onClick={() => addCollaborator()}
                disabled={invitingCollaborator}
              >
                {invitingCollaborator ? (
                  <LucideIcon
                    name="LoaderCircle"
                    fill="none"
                    stroke="White"
                    className="animate-spin"
                  />
                ) : (
                  'Invite'
                )}
              </Button>
            </div>
            <p className="text-helper-text-sm color-text-secondary pl-1">
              Add the 0x or ENS of your future collaborator
            </p>
          </div>
        )
      }
    />
  )
}
