import axios from 'axios'
import { generateToken } from '../utils'

interface IndexPortalRequestArgs {
  contractAddress: string
  invokerAddress: string
  publicLayoutFileId: string
  chain: number
  editSecret?: string
}
export const indexPortal = async (args: IndexPortalRequestArgs) => {
  if (!args.editSecret) return null

  const body = {
    publicLayoutFileId: args.publicLayoutFileId,
  }
  const token = await generateToken(args.contractAddress, args.editSecret)
  const res = await axios.post(
    `${process.env.REACT_APP_STORAGE_URL}/portal/index`,
    body,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        contract: args.contractAddress,
        invoker: args.invokerAddress,
        chain: args.chain,
      },
    }
  )
  return res
}

export const getPublicPortalApiCall = async (contractAddress: string) => {
  const res = await axios.get(
    `${process.env.REACT_APP_STORAGE_URL}/portal/file`,
    {
      headers: {
        contract: contractAddress,
      },
    }
  )
  return res.data
}
