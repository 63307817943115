import React, { ReactNode, SetStateAction, useState } from 'react'
import cn from 'classnames'

export interface SegmentedControlProps
  extends React.AllHTMLAttributes<HTMLDivElement> {
  options: ReactNode[]
  container: boolean
  currentState?: number
  setCurrentState: React.Dispatch<SetStateAction<number>>
  showActiveState?: boolean
  disabled?: boolean
}

export const SegmentedControl = React.forwardRef<
  HTMLDivElement,
  SegmentedControlProps
>(
  (
    {
      options,
      container,
      setCurrentState,
      currentState,
      showActiveState = true,
      disabled,
      ...props
    },
    ref
  ) => {
    const [selected, setSelected] = useState<number>(currentState as number)

    const handleSelect = (index: number) => {
      !disabled && setSelected(index)
      !disabled && setCurrentState(index)
    }

    return (
      <div
        ref={ref}
        className={cn(
          'h-[52px] p-2 color-bg-default flex justify-center items-center gap-1 rounded-xl',
          {
            'color-border-default border-[0.5px] shadow-elevation-3': container,
          }
        )}
        {...props}
      >
        {options.map((item, index) => (
          <div
            key={index}
            onClick={() => handleSelect(index)}
            className={cn(
              'w-9 h-9 flex justify-center items-center rounded cursor-pointer hover:color-bg-default-hover transition-colors',
              {
                'color-bg-brand hover:color-bg-brand-hover':
                  showActiveState && selected === index,
                'cursor-not-allowed color-bg-disabled opacity-50':
                  disabled && selected !== index,
              }
            )}
          >
            {item}
          </div>
        ))}
      </div>
    )
  }
)

SegmentedControl.displayName = 'SegmentedControl'
