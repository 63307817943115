import React, { useEffect, useState } from 'react'
import Logo from '../../assets/mainLogo.svg'
import invite_icon from '../../assets/invite_icon.svg'
import file_upload from '../../assets/file-upload.svg'
import new_collaborator from '../../assets/new_collaborator.svg'
import plugin_file_edit from '../../assets/plugin_file_edit.svg'
import published_plugin from '../../assets/plugin-upload.svg'
import plugin_file_delete from '../../assets/plugin_file_delete.svg'
import dpage_comment from '../../assets/dpage_comment.svg'
import file_chat from '../../assets/file-chat.svg'

import InviteNotificationSection from './NotificationInvitationSection'
import { INotification } from './NotificationProvider'
import { NotificationType } from '../../types/enum/notification'
import formatTime from '../../utils/formatTimestamp'
import { useNotificationProvider } from '../../hooks/useNotificationProvider'
import { NOTIFICATIONS_THAT_REQUIRE_ACTION } from '../../constants'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getLogoUrl } from '../../utils/getLogoUrl'
import { getAddress } from 'ethers'
import { useSafeAppStatus } from '../../store/common/hooks'
import { isOriginGnosisBlockscout } from '../../utils/isOriginGnosisBlockscout'
export const notificationIcon: Record<NotificationType, string> = {
  [NotificationType.COLLABORATOR_INVITE]: invite_icon,
  [NotificationType.NEW_COLLABORATOR_JOINED]: new_collaborator,
  [NotificationType.NEW_FILE_ADDED]: file_upload,
  // [NotificationType.NEW_PLUGIN_FILE_UPLOAD]: plugin_upload,
  // [NotificationType.LIVE_COLLABORATION_INVITE]: live_collab,
  [NotificationType.EDITED_FILE]: plugin_file_edit,
  [NotificationType.DELETED_FILE]: plugin_file_delete,
  [NotificationType.FILE_MESSAGE]: file_chat,
  [NotificationType.DPAGE_COMMENT]: dpage_comment,
  [NotificationType.DDOC_EDIT]: plugin_file_edit,
  [NotificationType.DDOC_PUBLISH]: published_plugin,
  [NotificationType.DPAGE_PUBLISH]: published_plugin,
  [NotificationType.DPAGE_EDIT]: plugin_file_edit,
  [NotificationType.WHITEBOARD_PUBLISH]: published_plugin,
  [NotificationType.WHITEBOARD_EDIT]: plugin_file_edit,
  [NotificationType.COLLABORATOR_REMOVE]: new_collaborator,
}
const NotificationCard = ({
  type,
  notification,
}: {
  type: NotificationType
  notification: INotification
}) => {
  const provider = useNotificationProvider()
  const [contractLogo, setContractLogo] = useState<string>('')
  const [searchParams] = useSearchParams()
  const chainId = parseInt(searchParams.get('chainId') || '')
  const navigate = useNavigate()
  const isCollaboratorInvite = type === NotificationType.COLLABORATOR_INVITE
  const isFileChat =
    (type === NotificationType.FILE_MESSAGE ||
      type === NotificationType.DPAGE_COMMENT) &&
    notification?.context?.text
  const getLogo = async () => {
    const fileUrl = await getLogoUrl(notification?.portalLogo)
    if (fileUrl) {
      setContractLogo(fileUrl)
    }
  }
  useEffect(() => {
    getLogo()
  }, [])
  const isSafeApp = useSafeAppStatus()
  const navTarget = isSafeApp || isOriginGnosisBlockscout ? '_self' : '_blank'
  const handleClick = () => {
    if (
      NOTIFICATIONS_THAT_REQUIRE_ACTION.includes(notification?.type) &&
      !notification.processed
    ) {
      return
    }
    if (!notification.processed) {
      provider?.processNotification(notification?._id)
    }
    const portalAddress = getAddress(notification.portalAddress)
    if (
      type === NotificationType.DPAGE_PUBLISH ||
      type === NotificationType.DPAGE_COMMENT ||
      type === NotificationType.DPAGE_EDIT ||
      type === NotificationType.NEW_FILE_ADDED ||
      type === NotificationType.EDITED_FILE ||
      type === NotificationType.FILE_MESSAGE ||
      type === NotificationType.WHITEBOARD_EDIT ||
      type === NotificationType.WHITEBOARD_PUBLISH ||
      type === NotificationType.DDOC_EDIT ||
      type === NotificationType.DDOC_PUBLISH
    ) {
      if (notification?.context?.fileId && portalAddress) {
        window.open(
          `${window.origin}${window.location.pathname}#/${portalAddress}/file/${notification.context.fileId}?chainId=${chainId}`,
          navTarget
        )
      }
    } else {
      if (portalAddress) {
        navigate(`/${portalAddress}?chainId=${chainId}`)
        window.open(
          `${window.origin}${window.location.pathname}#/${portalAddress}?chainId=${chainId}`,
          navTarget
        )
      }
    }
  }
  return (
    <div
      onClick={() => handleClick()}
      className={`'bg-white flex min-h-[70px] bg-white relative hover:bg-[#F2F2F2] cursor-pointer z-[100] p-4 gap-2 border-b`}
    >
      {!notification?.processed && (
        <div
          className={` w-[12px] bg-red h-[12px] left-2 top-2 absolute rounded-full`}
        ></div>
      )}
      <div className="relative w-[10%]">
        <img
          src={contractLogo || Logo}
          alt="Fileverse"
          className={`w-10 h-10 object-cover mr-2 rounded-full cursor-pointer`}
        />
        <img
          src={notificationIcon[type]}
          className="w-7 absolute top-0 right-[-13px]"
        />
      </div>
      <div className="flex w-[90%] flex-col gap-1">
        <div className="px-4 flex flex-col gap-2">
          <p className="text-sm  font-medium break-words">
            {notification?.message}
          </p>
        </div>
        <div className="flex flex-col gap-1 justify-end">
          {isFileChat && (
            <div className="w-full flex flex-col gap-2 mt-2">
              <p className=" text-sm font-medium border-l-4 p-2 break-words">
                {`"${notification.context.text}"`}
              </p>
            </div>
          )}
          {isCollaboratorInvite && notification?.action && (
            <InviteNotificationSection data={notification} />
          )}

          <p className=" text-sm font-medium text-[#AAAAAA]">
            {formatTime(notification.blockTimestamp || notification.timeStamp)}
          </p>
        </div>
      </div>
    </div>
  )
}

export default NotificationCard
