import React, { useState } from 'react'
import MainWrapper from '../components/common/MainWrapper'
import sad from '../assets/sad.svg'
import CTAButton from '../components/common/CTAButton'
import ActionButton from '../components/Buttons/ActionButton'
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from '@mui/material'
import cn from 'classnames'
import { captureMessage } from '@sentry/react'

export enum ERROR_TYPE {
  PAGE_NOT_FOUND,
  RUNTIME_ERROR,
}

export default function FallbackPage({ errorType }: { errorType: ERROR_TYPE }) {
  const navigate = useNavigate()
  const isMediaMax530px = useMediaQuery('(max-width : 530px)')
  const [userFeedback, setUserFeedback] = useState<string>('')

  const sendMessage = () => {
    captureMessage(userFeedback)
  }

  const navigateToContracts = () => {
    navigate('/contracts')
  }

  return (
    <MainWrapper noFooter={true}>
      <div className="w-full h-full mt-10 flex flex-col justify-center items-center gap-6">
        <div
          className={cn(
            isMediaMax530px
              ? 'w-[90%] aspect-square min-w-[330px]'
              : 'h-[400px] w-[450px]',
            `flex flex-col justify-center items-center gap-6 rounded-2xl bg-yellow`
          )}
        >
          <img src={sad} alt="" />
          <div className="flex flex-col items-center">
            <p className="text-6xl font-bold w-fit">
              {errorType === ERROR_TYPE.PAGE_NOT_FOUND ? '404' : '500'}
            </p>
            <p className="text-lg">
              {errorType === ERROR_TYPE.PAGE_NOT_FOUND
                ? 'Page not found!'
                : 'Oh, Something went wrong! '}
            </p>
          </div>
          <ActionButton onclick={navigateToContracts} title={'Back to home'} />
        </div>

        <form
          className={cn(
            {
              'w-[90%] aspect-square': isMediaMax530px,
              'w-[450px]': !isMediaMax530px,
            },
            `mx-auto flex flex-col`
          )}
        >
          <label
            htmlFor="message"
            className="block mb-2 text-sm font-medium text-gray-900"
          >
            {`Please describe what you're doing and we'll investigate <3`}
          </label>
          <textarea
            onChange={(e) => setUserFeedback(e.target.value)}
            id="message"
            rows={4}
            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
            placeholder={
              errorType === ERROR_TYPE.PAGE_NOT_FOUND
                ? `Type your message and press "Submit"`
                : 'Anything you’d like to highlight '
            }
          ></textarea>
          <div className=" place-self-end mt-4">
            <CTAButton title="Submit" onClick={sendMessage} />
          </div>
        </form>
      </div>
    </MainWrapper>
  )
}
