/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { JSONTree } from 'react-json-tree'

export default function JSONView({ file }: { file: any }) {
  const theme = {
    base00: '#FFFFFF', // background color
    base0B: '#5D3FD3', // value color
    base0D: '#', // key color
  }
  const sol = JSON.parse(JSON.stringify(file))
  return (
    <div data-cy="json-preview" className="p-4 h-full overflow-auto">
      {file && <JSONTree data={sol} theme={theme} invertTheme={false} />}
    </div>
  )
}
