/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState } from 'react'
import { Socials } from '../components/common/Socials'
import { FAQs } from '../components/common/FAQs'
import Header from '../components/common/Headers/MainHeader'
import Footer from '../components/common/Footer'
import { useNavigate, useSearchParams } from 'react-router-dom'
import SocialButton from '../components/common/SocialButton'
import Twitter from '../assets/TwitterWhite.svg'
import checkWhitelistAPI from '../api/account/whitelist'
import WhiteButton from '../components/common/WhiteButton'
import { usePrivyHelper } from '../hooks/usePrivyHelper'
import { Button } from '@fileverse/ui'
import { useSafeAppStatus } from '../store/common/hooks'

export const Claim = () => {
  const navigate = useNavigate()
  const [notEligible, setNotEligible] = useState<boolean>(false)
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const { walletAddress, loginWithSocials, connectInjectedWallet } =
    usePrivyHelper()
  const [searchParams] = useSearchParams()
  const code = searchParams.get('code') || ''
  const isSafeApp = useSafeAppStatus()
  const address = walletAddress
    ? walletAddress.slice(0, 5) +
      '....' +
      walletAddress.slice(walletAddress.length - 5)
    : ''

  const checkWhitelist = async () => {
    try {
      setIsFetching(true)
      const origin = document.referrer
      const response = await checkWhitelistAPI({
        invoker: walletAddress as string,
        code,
        origin,
      })
      if (response?.data?.whitelisted) {
        let navigateTo = '/create'
        if (code) navigateTo = `/create?code=${code}`
        navigate(navigateTo)
      } else {
        setNotEligible(true)
      }
    } finally {
      setIsFetching(false)
    }
  }
  return (
    <div className="w-full h-full overflow-scroll no-scrollbar flex flex-col gap-8 p-6">
      <Header />
      <div className="hero flex flex-col justify-center items-center">
        <div className="text-center flex flex-col justify-start items-center gap-4">
          <h1 className=" text-4xl font-bold">
            {notEligible
              ? 'Oh no, Anon…'
              : 'Create your ideal collaboration space'}
          </h1>
          <p className="text-xl text-[#777777]">
            {notEligible
              ? `Looks like your address [${address}] is not eligible yet. General access will open soon, follow us on Twitter for more 👀`
              : `See if you're eligible to create a Portal by connecting your wallet`}
          </p>
          {notEligible ? (
            <div className="flex justify-center items-center gap-4 mt-4">
              <WhiteButton
                onClick={() =>
                  window.open('https://beta.fileverse.io/', '_blank')
                }
                title={'Try Fileverse Solo'}
                customCSS="py-2 px-3 h-[48px]"
              />
              <SocialButton
                title={'See Updates'}
                linkTo={'https://twitter.com/fileverse'}
                icon={Twitter}
                iconStyles={'w-5'}
              />
            </div>
          ) : walletAddress ? (
            <Button
              className="rounded-md px-6 py-2 h-10"
              onClick={checkWhitelist}
              isLoading={isFetching}
            >
              Check Eligibility
            </Button>
          ) : (
            <div className="flex gap-4">
              <Button
                className="rounded-md px-6 py-2 h-10"
                onClick={connectInjectedWallet}
              >
                Connect Wallet
              </Button>
              {!isSafeApp && (
                <Button
                  className="rounded-md px-6 py-2 h-10"
                  onClick={loginWithSocials}
                  variant="secondary"
                >
                  Connect with Social
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="faqs flex flex-col justify-center items-center mt-12">
        <h2 className="text-xl font-extrabold"> Frequently Asked Questions</h2>
        <div className="lg:w-[70%] w-full">
          <FAQs />
        </div>
      </div>
      <Socials />
      <Footer />
    </div>
  )
}
