import { InvalidRecoveryJsonError } from '../errors'

// Only check required keys that always present in the recovery file
// agentKey and agentAddress can be validated separately
export const keysInSecretFile = [
  'contractAddress',
  'viewSecret',
  'editSecret',
  'portalEncryptionKey',
  'portalDecryptionKey',
  'memberEncryptionKey',
  'memberDecryptionKey',
  'editDID',
  'viewDID',
  'chainId',
  'ownerDecryptionKey',
  'ownerEncryptionKey',
  'owner',
  'portalGunKey',
]

export const validatePortalKeyParams = (
  keysObject: Record<string, string>,
  invokerAddress: string
) => {
  const keys = Object.keys(keysObject)
  keysInSecretFile.forEach((key) => {
    if (!keys.includes(key) || !keysObject[key])
      throw new InvalidRecoveryJsonError('Recovery file is missing keys')
  })

  if (keysObject['owner'] !== invokerAddress)
    throw new Error(
      'Connected wallet address does not match with Owner address of this Portal'
    )
}

export const hasAgentKeyAndAddress = (keysObject: Record<string, string>) => {
  const keys = Object.keys(keysObject)

  return (
    keys.includes('agentAddress') &&
    keys.includes('agentKey') &&
    keysObject['agentAddress'] &&
    keysObject['agentKey']
  )
}
