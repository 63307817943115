import { SecretFile } from '../types/interface/file.interface'

interface DownloadKeyFileProps {
  secretFileData: SecretFile | undefined
  contractAddress: string
  portalName: string
}

export const downloadKeyFile = ({
  secretFileData,
  contractAddress,
  portalName,
}: DownloadKeyFileProps) => {
  const file =
    'text/json;charset=utf-8,' +
    encodeURIComponent(JSON.stringify(secretFileData))
  const a = document.createElement('a')
  a.href = 'data:' + file
  a.download = `${contractAddress}.${portalName}.keys.json`
  a.click()
  window.history.replaceState({}, document.title)
}
