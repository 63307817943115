/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import Canvas from '../components/ExcalidrawCanvas/Canvas'
import { DocumentPageStatus, FileSource } from '../types/enum/file.enum'
import { useContract } from '../store/contract/hooks'
import { IDraft } from '../types/interface/drafts.interface'
import { useServerKeys } from '../store/invoker/hooks'
import { generateCollabRoomKey, getISEAKeyPair } from '../utils/libCrypto'
import sendNotifcation from '../utils/notification'
import {
  createAndSaveDraftMetadata,
  getDraftMetadata,
} from '../utils/collaboration/utils'
import { ISEAPair } from 'gun'
import isEmpty from 'lodash/isEmpty'
import { useAppDispatch } from '../store/hooks'
import { fetchAndSaveContract } from '../store/contract/thunks'
import WalletDisconnected from '../components/common/WalletDisconnected'
import { checkIfOwnerOrCollaborator } from '../utils/checkIfOwnerOrCollaborator'
import TasksProvider from '../components/Tasks/TasksProvider'
import { captureException } from '@sentry/react'
import { useLocalStorage } from 'usehooks-ts'
import Header from '../components/common/Headers/MainHeader'
import { usePrivyHelper } from '../hooks/usePrivyHelper'
import { AnimatedLoader } from './PublicPortal/components/Loader'

export type ConnectToDocumentProps = {
  onJoinDoc: (username?: string) => void
}

export const CollaborativeWhiteboard = () => {
  const [selectFiles, setSelectFiles] = useState<File[]>([])

  const [whiteboardPreview, setWhiteboardPreview] = useState<
    string | undefined
  >()

  const { address: contractAddress } = useParams()
  const [urlParams, setUrlParam] = useSearchParams()
  const chainId = parseInt(urlParams.get('chainId') || '')
  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const { rtcId } = useParams()
  const walletAddress = usePrivyHelper().walletAddress as string
  const contract = useContract(contractAddress as string)
  const [rtcData, setDraftRTCData] = useState<IDraft>()
  const [username] = useLocalStorage('username', '')

  const serverKeys = useServerKeys(
    walletAddress as string,
    contractAddress as string
  )
  const authKey = getISEAKeyPair(serverKeys.portalGunKey)
  const [pageStatus, setPageStatus] = useState<DocumentPageStatus>(
    DocumentPageStatus.PENDING
  )
  const isCollaborator = checkIfOwnerOrCollaborator(
    contract,
    walletAddress as string,
    serverKeys
  )
  const isLoading = usePrivyHelper().isLoading

  const setupDocument = async () => {
    if (isLoading) return
    try {
      if (!rtcId) {
        if (isCollaborator) {
          navigate(`/${contractAddress}?chainId=${chainId}`)
        } else {
          navigate(`/${contractAddress}/member?chainId=${chainId}`)
        }
        sendNotifcation(
          'Unable to load document',
          'Specify the rtc_id of the document that is to be previewed',
          'danger'
        )
        return
      }
      if (!urlParams.get('key')) {
        const rtcKey = await generateCollabRoomKey()
        urlParams.set('key', rtcKey)
        setUrlParam(urlParams, {
          replace: true,
        })
      }
      if (authKey) {
        const draftMetadata = await getDraftMetadata(
          authKey,
          contractAddress as string,
          rtcId
        )
        if (!draftMetadata || isEmpty(draftMetadata)) {
          const result = await createAndSaveDraftMetadata(
            authKey as ISEAPair,
            urlParams.get('key') as string,
            serverKeys,
            contractAddress as string,
            rtcId,
            walletAddress as string,
            FileSource.EXCALIDRAW
          )
          if (result.isCreated) {
            setDraftRTCData(result.metadata)
            setPageStatus(DocumentPageStatus.READY)
          } else {
            sendNotifcation(
              'Failed to create document',
              result.message,
              'danger',
              10000
            )
            navigate(-1)
          }
        } else {
          setDraftRTCData(draftMetadata as IDraft)
          setPageStatus(DocumentPageStatus.READY)
        }
      } else {
        if ((!walletAddress || !username) && !isLoading) {
          setPageStatus(DocumentPageStatus.DISCONNECTED)
          return
        }
        setPageStatus(DocumentPageStatus.READY)
      }
    } catch (error) {
      captureException(error)
      console.log(error)
    }
  }

  const setUpDocumentPage = async () => {
    setPageStatus(DocumentPageStatus.PENDING)
    if (isEmpty(contract)) {
      setPageStatus(DocumentPageStatus.FETCHING_CONTRACT)

      await dispatch(
        fetchAndSaveContract({
          contractAddress: contractAddress as string,
          invokerAddress: walletAddress,
          chainId,
        })
      ).unwrap()
      setPageStatus(DocumentPageStatus.PENDING)
    }

    await setupDocument()
  }

  useEffect(() => {
    setUpDocumentPage()
  }, [walletAddress, isLoading])

  useEffect(() => {
    if (selectFiles.length > 0) {
      navigate(`/${contractAddress}?chainId=${chainId}`, {
        state: {
          file: selectFiles,
          source: FileSource.EXCALIDRAW,
          whiteboardPreview: whiteboardPreview,
          version: 1,
          rtcId,
          rtcData,
        },
        replace: true,
      })
    }
  }, [selectFiles, whiteboardPreview])
  return (
    <div data-cy="collaborative-whiteboard" className="h-[100vh] w-[100vw]">
      {(pageStatus === DocumentPageStatus.PENDING ||
        pageStatus === DocumentPageStatus.FETCHING_CONTRACT) && (
        <div className="h-full w-full flex flex-col justify-center items-center">
          <AnimatedLoader text="Preparing your Whiteboard..." />
          {pageStatus === DocumentPageStatus.FETCHING_CONTRACT && (
            <p className="text-xl mt-8">Fetching portal details ... </p>
          )}
        </div>
      )}
      {pageStatus === DocumentPageStatus.READY && (
        <TasksProvider>
          <div className="h-full w-full">
            <Canvas
              setSelectFiles={setSelectFiles}
              setWhiteboardPreview={setWhiteboardPreview}
              authKey={authKey as ISEAPair}
              rtcData={rtcData as IDraft}
            />
          </div>
        </TasksProvider>
      )}
      {pageStatus === DocumentPageStatus.DISCONNECTED && (
        <ConnectToDocument
          onJoinDoc={(user) => {
            if (username || user) setPageStatus(DocumentPageStatus.READY)
          }}
        />
      )}
    </div>
  )
}

export const ConnectToDocument = ({ onJoinDoc }: ConnectToDocumentProps) => {
  return (
    <div className="h-[100vh] min-h-fit">
      <Header />
      <div className="h-[90vh] flex flex-col justify-center w-full p-4">
        <WalletDisconnected joinDoc={onJoinDoc} collabDoc />
      </div>
    </div>
  )
}
