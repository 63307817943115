/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'

import arrow from '../../../assets/folderArrowGrey.svg'
import DefaultLogo from '../../../assets/mainLogo.png'
import { useMediaQuery } from '@mui/material'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import {
  useContract,
  useContractMembers,
  useContractMetadata,
} from '../../../store/contract/hooks'
// import { ConnectButton } from '@rainbow-me/rainbowkit'
import {
  ConnectedWalletSideMenu,
  DisconnectedWalletSideMenu,
  SearchFileSideMenu,
} from '../MobileSideMenu'
import { getLogoUrl } from '../../../utils/getLogoUrl'
import { checkIfOwnerOrCollaborator } from '../../../utils/checkIfOwnerOrCollaborator'
import { useServerKeys } from '../../../store/invoker/hooks'
import { usePrivy } from '@privy-io/react-auth'
import { usePrivyHelper } from '../../../hooks/usePrivyHelper'
import { IconButton, LucideIcon } from '@fileverse/ui'
import { Button } from '../../../pages/PublicPortal/components/Button'

export const PublicMobileHeader = ({
  isPortalSectionOpen,
  setPortalSectionVisibility,
}: {
  isPortalSectionOpen: boolean
  setPortalSectionVisibility?: Dispatch<SetStateAction<boolean>>
}) => {
  const { address: contractAddress } = useParams()
  const portalMembers = useContractMembers(contractAddress as string)
  const hamburgerbar: React.MutableRefObject<null> = useRef(null)
  const [isPublicSideMenuOpen, setIsPublicSideMenuOpen] = useState(false)
  const contractMetadata = useContractMetadata(contractAddress as string)
  const [contractLogo, setContractLogo] = useState<string>('')
  const { walletAddress, onDisconnectWallet } = usePrivyHelper()
  const contract = useContract((contractAddress as string) || '')
  const navigate = useNavigate()

  const handleDisconnectWallet = () => {
    onDisconnectWallet()
    navigate('/create')
  }
  const serverKeys = useServerKeys(
    walletAddress as string,
    contractAddress as string
  )
  const isCollaborator = checkIfOwnerOrCollaborator(
    contract,
    walletAddress as string,
    serverKeys
  )
  const getLogo = async () => {
    const fileUrl = await getLogoUrl(contract?.metadata.logo)
    if (fileUrl) {
      setContractLogo(fileUrl)
    }
  }
  useEffect(() => {
    getLogo()
  }, [])
  return (
    <div className="w-full h-[10vh]">
      <div className="h-full w-full max-h-[100px]  flex justify-between items-center py-2 drop-shadow-md">
        {isPortalSectionOpen ? (
          <div
            data-cy="mobile-back-arrow"
            className=""
            onClick={() =>
              setPortalSectionVisibility && setPortalSectionVisibility(false)
            }
          >
            <div
              className=" w-[36px] 
             h-[36px] rounded-[50%] bg-[#DDDDDD] flex 
             items-center justify-center"
            >
              <img
                src={arrow}
                className="cursor-pointer rotate-[176deg] custom-filter-19"
              />
            </div>
          </div>
        ) : (
          <div className="h-full w-full flex items-center">
            <img
              src={contractLogo || DefaultLogo}
              alt="logo"
              className={`w-8 h-8 object-cover mr-2 rounded-full cursor-pointer`}
            />
            <div className="flex flex-col max-w-[8rem]">
              <div
                data-cy="portal-header-name"
                className="text-heading-md color-text-default truncate"
              >
                {contractMetadata?.name}
              </div>
              {contractMetadata && (
                <div className="text-xs text-[#777777]">
                  {portalMembers.length} members
                </div>
              )}
            </div>
          </div>
        )}
        <div className="w-[50%] flex items-center justify-end">
          <div
            data-cy="hamburger-icon"
            ref={hamburgerbar}
            onClick={() => setIsPublicSideMenuOpen(!isPublicSideMenuOpen)}
            id="hamburger-icon"
          >
            <LucideIcon name="Menu" className="w-9 h-9" />
          </div>
        </div>
      </div>
      {walletAddress ? (
        <ConnectedWalletSideMenu
          sideMenu={isPublicSideMenuOpen}
          setSideMenu={setIsPublicSideMenuOpen}
          handleDisconnectWallet={handleDisconnectWallet}
          walletAddress={walletAddress as string}
          isCollaborator={isCollaborator}
        />
      ) : (
        <DisconnectedWalletSideMenu
          sideMenu={isPublicSideMenuOpen}
          setSideMenu={setIsPublicSideMenuOpen}
        />
      )}
    </div>
  )
}
export const PrivateMobileHeader = ({
  handleDisconnectWallet,
}: {
  handleDisconnectWallet: () => void
}) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const chainId = parseInt(searchParams.get('chainId') || '')
  const { connectOrCreateWallet } = usePrivy()
  const { address: contractAddress } = useParams()
  const walletAddress = usePrivyHelper().walletAddress
  const hamburgerbar: React.MutableRefObject<null> = useRef(null)
  const [isPrivateSideMenuOpen, setIsPrivateSideMenuOpen] = useState(false)
  const contractMetadata = useContractMetadata(contractAddress as string)
  const connectButtonRef = useRef<HTMLButtonElement>(null)
  const isMedia640px = useMediaQuery('(max-width : 640px)')
  const [contractLogo, setContractLogo] = useState<string>('')
  const contract = useContract((contractAddress as string) || '')
  const [isSearchFileSideMenuOpen, setIsSearchFileSideMenuOpen] =
    useState(false)

  const getLogo = async () => {
    const fileUrl = await getLogoUrl(contract?.metadata.logo)
    if (fileUrl) {
      setContractLogo(fileUrl)
    }
  }
  useEffect(() => {
    getLogo()
  }, [])
  return (
    <div className="w-full">
      <div className="h-full w-full max-h-[100px] flex justify-between items-center">
        <div className="h-full w-full flex items-center gap-[10px]">
          <img
            src={contractLogo}
            alt="logo"
            className={`w-10 h-10 object-cover rounded cursor-pointer`}
            onClick={() => navigate(`/${contractAddress}?chainId=${chainId}`)}
          />
          <div className="flex flex-col max-w-[10rem]">
            <div
              data-cy="portal-header-name"
              className="text-heading-md color-text-default truncate"
            >
              {contractMetadata?.name}
            </div>
          </div>
        </div>

        <div className="w-[50%] flex items-center justify-end gap-4">
          {(() => {
            if (walletAddress) {
              return (
                <>
                  <IconButton
                    icon="Search"
                    className="w-9 h-9"
                    variant="ghost"
                    onClick={() => setIsSearchFileSideMenuOpen(true)}
                  />
                  <Button
                    data-cy="hamburger-icon"
                    ref={hamburgerbar}
                    onClick={() => {
                      setIsPrivateSideMenuOpen(!isPrivateSideMenuOpen)
                    }}
                    id="hamburger-icon"
                    className="edit-public-portal claim-perks-tour min-w-fit px-0 bg-transparent"
                    variant="ghost"
                  >
                    <LucideIcon name="Menu" className="w-9 h-9" />
                  </Button>
                </>
              )
            }
            return (
              <button
                data-cy="wallet-connect-btn"
                id="wallet-connect-btn"
                ref={connectButtonRef}
                onClick={connectOrCreateWallet}
                type="button"
                className={`bg-black text-white rounded-lg py-2 text-lg ${
                  isMedia640px ? 'px-3 text-sm' : 'px-10'
                }`}
              >
                {'Connect'}
              </button>
            )
          })()}
        </div>
      </div>

      <ConnectedWalletSideMenu
        sideMenu={isPrivateSideMenuOpen}
        setSideMenu={setIsPrivateSideMenuOpen}
        handleDisconnectWallet={handleDisconnectWallet}
        walletAddress={walletAddress as string}
        isCollaborator={true}
      />

      <SearchFileSideMenu
        open={isSearchFileSideMenuOpen}
        onOpenChange={setIsSearchFileSideMenuOpen}
      />
    </div>
  )
}
