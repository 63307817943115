import React, { useRef, useState } from 'react'
import { Tooltip } from '@mui/material'
import { Minus, Plus, ZoomOutIcon } from 'lucide-react'
import cn from 'classnames'
import debounce from 'lodash/debounce'

const ImageViewer = ({ url, fileName }: { url: string; fileName: string }) => {
  const [zoom, setZoom] = useState<number>(1)
  const zoomRef = useRef(zoom)

  const debouncedSetZoom = debounce((zoomLevel) => {
    setZoom(zoomLevel)
  }, 100)

  const zoomIn = () => {
    if (zoomRef.current < 1.5) {
      zoomRef.current += 0.1
      debouncedSetZoom(zoomRef.current)
    }
  }

  const zoomOut = () => {
    if (zoomRef.current > 0.5) {
      zoomRef.current -= 0.1
      debouncedSetZoom(zoomRef.current)
    }
  }

  const resetZoom = () => {
    zoomRef.current = 1
    debouncedSetZoom(zoomRef.current)
  }

  return (
    <div
      data-cy="image-preview"
      className="h-full w-full flex flex-col relative justify-center items-center"
    >
      <div className="h-12 w-fit flex gap-4 justify-center items-center bg-black/70 rounded-full scale-[0.7] lg:scale-90 px-4 my-2 fixed bottom-12 lg:bottom-4 z-10">
        <div className="text-white flex gap-4 items-center">
          <Tooltip title="Zoom out">
            <button
              onClick={zoomOut}
              disabled={zoom <= 0.5}
              className={cn({
                'text-gray-400': zoom <= 0.5,
              })}
            >
              <Minus fontSize={16} />
            </button>
          </Tooltip>

          <Tooltip title="Reset zoom">
            <button onClick={resetZoom}>
              <ZoomOutIcon fontSize={16} />
            </button>
          </Tooltip>

          <Tooltip title="Zoom in">
            <button
              onClick={zoomIn}
              disabled={zoom >= 1.5}
              className={cn({
                'text-gray-400': zoom >= 1.5,
              })}
            >
              <Plus fontSize={16} />
            </button>
          </Tooltip>
        </div>
      </div>
      <div className="w-full h-full p-4 flex justify-center items-center bg-[#323639] bg-opacity-90 overflow-auto">
        <img
          data-cy="image-viewer"
          src={url}
          alt={fileName}
          className="max-h-full max-w-full rounded-md object-contain shadow-md bg-white lg:max-w-2xl"
          style={{
            transform: `scale(${zoom})`,
            transformOrigin: 'center',
            transition: 'transform 0.3s ease-in-out',
          }}
        />
      </div>
    </div>
  )
}

export default ImageViewer
