import React from 'react'
import TasksProvider from '../Tasks/TasksProvider'
import { DashboardLayout } from '../DashboardLayout'
import { TourProvider } from '@reactour/tour'
import dashboardTourSteps from '../../utils/tour/dashboardTourSteps'

export default function PortalDashboard() {
  return (
    <TasksProvider>
      <TourProvider steps={dashboardTourSteps} position={'bottom'}>
        <DashboardLayout />
      </TourProvider>
    </TasksProvider>
  )
}
