/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { SiweMessage } from 'siwe'
import { isSafeApp, safeAppsSdk } from '../safeApp'
import { signMessage } from 'wagmi/actions'
import { config } from '../../config/wagmi-config'

export const requestSignature = async (
  userAddress: string,
  statement: string,
  signMessageAsync?: (args?: any) => Promise<`0x${string}`>,
  nonce?: string
): Promise<{ signature: string; message: string; messageHash: string }> => {
  const _isSafeApp = await isSafeApp()

  if (_isSafeApp) {
    await safeAppsSdk.eth.setSafeSettings([{ offChainSigning: true }])
    const hash = await safeAppsSdk.txs.signMessage(statement)
    const messageIsSigned = await safeAppsSdk.safe.isMessageSigned(
      statement,
      //@ts-ignore
      hash.signature
    )
    if (messageIsSigned) {
      return {
        //@ts-ignore
        signature: hash?.signature,
        message: statement,
        //@ts-ignore
        messageHash: hash?.messageHash,
      }
    } else {
      throw new Error('Invalid signature')
    }
  }
  const siwe: any = {
    domain: window.location.host,
    address: userAddress,
    statement: statement,
    uri: window.location.origin,
    version: '1',
  }
  if (nonce) {
    /**
     * in the case of generating the same signature we use a static nonce and timestamp
     */
    siwe.nonce = nonce
    siwe.issuedAt = '2023-08-24T15:39:21.236Z'
  }
  try {
    const messageObject = new SiweMessage(siwe)
    const preparedMessage = messageObject.prepareMessage()
    const signature =
      typeof signMessageAsync === 'function'
        ? await signMessageAsync({ message: preparedMessage })
        : await signMessage(config, { message: preparedMessage })
    return {
      signature,
      message: preparedMessage,
      messageHash: '',
    }
  } catch (error: any) {
    console.error('error signing message', error)
    throw new Error('user rejected signature')
  }
}
