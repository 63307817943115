import React, { ReactNode } from 'react'
import { NodeViewProps, NodeViewWrapper } from '@tiptap/react'
import { Icon } from '../../common/Icon'
import ArrowOutwardTwoToneIcon from '@mui/icons-material/ArrowOutwardTwoTone'
import { useEditingContext } from '../../../../hooks/useEditingContext'
import cn from 'classnames'
import { useParams } from 'react-router-dom'
import { useContract } from '../../../../store/contract/hooks'
import { checkIfOwnerOrCollaborator } from '../../../../utils/checkIfOwnerOrCollaborator'
import { Tooltip } from '@mui/material'
import { useServerKeys } from '../../../../store/invoker/hooks'
import { usePrivyHelper } from '../../../../hooks/usePrivyHelper'

export const LinkButtonNodeView = ({ node }: NodeViewProps) => {
  const isPreview = useEditingContext()
  const { address: contractAddress } = useParams()
  const walletAddress = usePrivyHelper().walletAddress
  const contract = useContract(contractAddress as string)
  const serverKeys = useServerKeys(
    walletAddress as string,
    contractAddress as string
  )
  const isCollaborator = checkIfOwnerOrCollaborator(
    contract,
    walletAddress as string,
    serverKeys
  )
  const isPublic = node.attrs.data.permission === 'Globe'

  const handleLinkButtonClick = () => {
    const { href } = node.attrs.data

    if (isPublic || isCollaborator) {
      window.open(href, '_blank')
      return
    }
  }

  const tooltipText = () => {
    if (isPublic) {
      return ''
    } else if (node.attrs.data.permission === 'Users') {
      return 'For collaborators only'
    } else if (node.attrs.data.permission === 'Shield') {
      return 'For token-gated users only'
    }
    return 'For members only'
  }

  return (
    <NodeViewWrapper
      className={cn(
        'relative link-button-node-view inline-flex gap-2 items-center rounded border border-transparent bg-trasparent transition-all ease-in-out w-fit px-1.5 cursor-default pointer-events-auto min-w-fit',
        {
          'hover:bg-stone-100 hover:border-stone-400': !isPreview,
        }
      )}
      contentEditable={false}
    >
      <span className="text-lg">
        <ArrowOutwardTwoToneIcon
          fontSize="small"
          style={{
            fill: '#000',
            stroke: '#fff',
            strokeWidth: 1,
          }}
          className="absolute w-4 h-4 top-3 left-4"
        />
        {node.attrs.data.emoji}
      </span>
      <Tooltip title={tooltipText() as ReactNode} placement="bottom">
        <span
          onClick={handleLinkButtonClick}
          className={cn(
            'font-medium text-base underline underline-offset-4 decoration-1 decoration-stone-400 truncate',
            {
              'cursor-not-allowed': !isCollaborator,
              'cursor-pointer': isCollaborator || isPublic,
            }
          )}
        >
          {node.attrs.data.title}
        </span>
      </Tooltip>
      <Icon
        name={node.attrs.data.permission}
        className="text-stone-400 min-w-fit"
      />
    </NodeViewWrapper>
  )
}
