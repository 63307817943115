/* eslint-disable @typescript-eslint/ban-ts-comment */
import { penumbra as penumbraFromLib } from '@transcend-io/penumbra'

export const getPenumbra = async () => {
  const penumbra = self.penumbra || penumbraFromLib
  //@ts-ignore
  if (window.isPenumbraWorkerInitialized) return penumbra

  const penumbraLocation = `${window.origin}${window.location.pathname}dist/worker.penumbra.js`
  await penumbra.setWorkerLocation(penumbraLocation)
  //@ts-ignore
  window.isPenumbraWorkerInitialized = true
  return penumbra
}
