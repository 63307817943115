/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { JsonRpcSigner } from 'ethers'
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useFileData } from '../../store/files/hooks'
import { FileEdit } from './FileEdit'
import { FileSource, FileStatus } from '../../types/enum/file.enum'
import FileView from './FileView'
import {
  IFileChatKeyPair,
  INewFile,
} from '../../types/interface/file.interface'
import { PublicWebPage } from '../../pages/PublicWebPage'
import PublicDDocPage from '../TipTap/PublicDDocPage'
import { checkIfOwnerOrCollaborator } from '../../utils/checkIfOwnerOrCollaborator'
import { useContract, useContractChainId } from '../../store/contract/hooks'
import useQRCode from '../../hooks/useQRCode'
import { QRModal } from '../Popup/QRModal'
import WebPageProofPublish from '../WebPages/WebPageProofPublish'
import { WebPageCommentSection } from '../WebPages/WebPageCommentSection'
import { IChatMessage } from '../../types/interface/message.interface'
import { useGunNodes } from '../../hooks/useGunNode'
import { instantiateSEA } from '../../utils/instantiateGun'
import WhiteboardFileViewer from '../FileViewers/WhiteboardFileViewer'
import useComponentVisibilty from '../../hooks/useVisibility'
import { WebPageMoreMenu } from '../WebPages/WebPageMoreMenu'
import PluginNavbar from '../Navbars/PluginNavbar'
import { DocStatus } from '../ExcalidrawCanvas/SavingStatusUI'
import { useServerKeys } from '../../store/invoker/hooks'
import { usePrivyHelper } from '../../hooks/usePrivyHelper'
import { DeletePopUp } from '../WebPages/DeletePopUp'

const SEA = instantiateSEA()

const FilePageReadyState = ({
  setFileStatus,
  downloadUrl,
  signer,
  setRecallFileData,
  fileChatKey,
  viewTokenGatedFile,
}: {
  setRecallFileData?: Dispatch<SetStateAction<boolean>>
  downloadUrl: string
  fileChatKey: IFileChatKeyPair
  signer?: JsonRpcSigner
  setFileStatus?: Dispatch<SetStateAction<FileStatus>>
  viewTokenGatedFile: (fileData: INewFile) => Promise<void>
}) => {
  const [files, setFiles] = useState<
    Array<File & { previewURL: string; fileName: string }>
  >([])
  const [editFile, setEditFile] = useState<boolean>(false)
  const [canceledEdit, setCanceledEdit] = useState<boolean>(false)
  const { address: contractAddress, fileId } = useParams()
  const contract = useContract(contractAddress as string)
  const navigate = useNavigate()
  const walletAddress = usePrivyHelper().walletAddress
  const chainId = useContractChainId(contractAddress as string)
  const fileData = useFileData(contractAddress as string, fileId)
  const isDpage = fileData?.metadata?.source === FileSource.DPAGE
  const isDDoc = fileData?.metadata?.source === FileSource.COLLAB_DOCUMENT
  const isWhiteboard = fileData?.metadata?.source === FileSource.EXCALIDRAW
  const isArweave = fileData?.metadata?.source === FileSource.ARWEAVE
  const isJsonMimeType = fileData?.metadata?.mimeType === 'application/json'
  const serverKeys = useServerKeys(
    walletAddress as string,
    contractAddress as string
  )
  const isCollaborator = checkIfOwnerOrCollaborator(
    contract,
    walletAddress as string,
    serverKeys
  )
  const fileDisplayName = fileData?.metadata?.name || 'Untitled'
  const [newFileName, setNewFileName] = useState<string>(fileDisplayName)
  const [moreMenu, setMoreMenu] = useState<boolean>(false)
  const commentsMetadata = fileData?.metadata.commentsEnabled
  const commentsStatus =
    commentsMetadata === undefined ? true : commentsMetadata
  const [commentsEnabled, setCommentsEnabled] =
    useState<boolean>(commentsStatus)
  const [proofPublishOpen, setProofPublishOpen] = useState<boolean>(false)
  const [showComments, setShowComments] = useState<boolean>(false)
  const [commentsAvailable, setCommentsAvailable] = useState({
    available: false,
    count: 0,
  })
  const [userMessages, setUserMessages] = useState<Array<IChatMessage>>([])
  const [idList, setIdList] = useState<string[]>([])
  const dropdownRef = useRef<HTMLDivElement>(null)
  const { qrRef, isQrVisible, setIsQrVisible } = useQRCode(false)
  const copyFileURL = `${window.location.href}`

  const {
    isComponentVisible: confirmDeleteModal,
    setIsComponentVisible: setConfirmDeleteModal,
  } = useComponentVisibilty(false)

  const handleBackButtonClick = async () => {
    if (!isCollaborator) {
      navigate(`/${contractAddress}/member?chainId=${chainId}`)
      return
    }

    navigate(`/${contractAddress}/files?chainId=${chainId}`)
  }

  const { getFileChatNode } = useGunNodes()

  useEffect(() => {
    try {
      const chatCollection = getFileChatNode(
        fileChatKey as IFileChatKeyPair,
        fileId as string
      )
      chatCollection.map().on(async (serialzedChatData: string, id: string) => {
        if (!idList.includes(id)) {
          const list = idList
          list.push(id)
          setIdList(list)
          const unserializedChatData = JSON.parse(serialzedChatData)
          const decryptedText = await SEA.decrypt(
            unserializedChatData.text,
            fileChatKey!
          )
          setUserMessages((prev: Array<IChatMessage>) => {
            const chat = [
              ...prev,
              {
                by: unserializedChatData.by,
                text: decryptedText,
                createdAt: unserializedChatData.createdAt,
              },
            ].sort((a, b) => a.createdAt - b.createdAt)
            return chat
          })
          if (unserializedChatData)
            setCommentsAvailable({ ...commentsAvailable, available: true })
        }
      })
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    setCommentsAvailable({ ...commentsAvailable, count: userMessages.length })
  }, [userMessages])

  useEffect(() => {
    setFiles([])
  }, [canceledEdit, editFile])

  if (isDpage && isJsonMimeType)
    return <PublicWebPage downloadUrl={downloadUrl} chatKey={fileChatKey} />

  if (isDDoc && isJsonMimeType)
    return <PublicDDocPage downloadUrl={downloadUrl} chatKey={fileChatKey} />

  if (isWhiteboard && isJsonMimeType) {
    return (
      <WhiteboardFileViewer
        downloadUrl={downloadUrl}
        fileChatKey={fileChatKey}
      />
    )
  }

  return (
    <div className="h-[100vh] bg-white w-[100vw]">
      <div className="h-[68px] relative bg-[#ffffff]">
        {moreMenu && (
          <WebPageMoreMenu
            confirmDeleteModal={confirmDeleteModal}
            downloadUrl={downloadUrl}
            isArweave={isArweave}
            setIsEditing={setEditFile}
            fileDownloadName={fileData?.metadata.name}
            filePreviewPage={!editFile}
            setProofPublishOpen={setProofPublishOpen}
            setIsQrVisible={setIsQrVisible}
            isOwner={isCollaborator}
            isDownloadEnabled={!!downloadUrl}
            setConfirmDeleteModal={setConfirmDeleteModal}
            commentsEnabled={commentsEnabled}
            setCommentsEnabled={setCommentsEnabled}
            pluginName="file"
            moreMenuRef={dropdownRef}
          />
        )}
        <PluginNavbar
          isTitleFieldEnabled={editFile}
          dropdownRef={dropdownRef}
          docStatus={DocStatus.SAVED}
          onLiveCollaborationTrigger={() => null}
          pluginTool={<></>}
          portalName={contract?.metadata.name as string}
          setPluginTitle={setNewFileName}
          pluginTitle={newFileName}
          isPreviewMode={false}
          setPreviewMode={() => null}
          backButtonAction={handleBackButtonClick}
          isUserACollaborator={isCollaborator}
          isEditMode={editFile}
          setEditMode={setEditFile}
          isCommentsVisible={showComments}
          toggleQrModalVisibility={() => setIsQrVisible(!isQrVisible)}
          isPublished={!editFile}
          setIsEditCancelFlag={setCanceledEdit}
          isPublishLoading={false} // fix the name to be isLoading
          isCommentsEnabled={commentsEnabled}
          toggleCommentVisibility={() => setShowComments(!showComments)}
          toggleMoreMenuVisibility={() => setMoreMenu(!moreMenu)}
          commentCount={commentsAvailable.count}
          publishPlugin={() => null}
          createEditedFile={() => {
            return files[0]
          }}
          toggleProvenaceModalVisibility={() =>
            setProofPublishOpen(!proofPublishOpen)
          }
          isCollaborating={false}
          collaborators={null}
          isArweave={isArweave}
          isFile={true}
        />
      </div>

      <WebPageCommentSection
        show={showComments}
        setShow={setShowComments}
        chatKey={fileChatKey}
        fileData={fileData as INewFile}
        setCommentMetadata={(data) =>
          setCommentsAvailable({ ...commentsAvailable, ...data })
        }
        isViewMode={!isCollaborator}
      />
      <div className="w-full h-screen justify-center flex items-center">
        <div className="w-full h-full">
          {editFile ? (
            <FileEdit
              files={files}
              setFiles={setFiles}
              setEditFile={setEditFile}
              contractSigner={signer!}
              fileData={fileData as INewFile}
              downloadUrl={downloadUrl}
              setRecallFileData={setRecallFileData!}
              setFileStatus={setFileStatus!}
              setNewFileName={setNewFileName}
            />
          ) : (
            <FileView
              isEditing={editFile}
              metadata={fileData?.metadata}
              downloadUrl={downloadUrl}
              viewTokenGatedFile={viewTokenGatedFile}
            />
          )}
        </div>
      </div>
      {/* Provenance Modal */}
      <WebPageProofPublish
        fileData={fileData as INewFile}
        proofPublishOpen={proofPublishOpen}
        setProofPublishOpen={setProofPublishOpen}
      />
      {/* QR Modal */}
      <QRModal
        isQrVisible={isQrVisible}
        copyFileURL={copyFileURL}
        qrRef={qrRef}
        setIsQrVisible={setIsQrVisible}
      />
      <DeletePopUp
        isOpen={confirmDeleteModal}
        setIsOpen={setConfirmDeleteModal}
      />
    </div>
  )
}

export default FilePageReadyState
