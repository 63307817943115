/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react'
import AvatarCanvas from '../WebPages/Avatar'
import { useOutsideClick } from '../../hooks/useOutsideClickDetector'
import { getTrimmedName } from '../../utils/string'
import { getAddressName } from '../../utils/getDefaultProfileName'

export const CollaboratorName = ({
  clientId,
  username,
  name,
}: {
  clientId: string
  username: string
  name: string
}) => {
  const [ensName, setEnsName] = useState<string>()

  const getENSName = async () => {
    const resolvedName = await getAddressName(username || name)
    setEnsName(resolvedName)
  }

  useEffect(() => {
    getENSName()
  }, [])

  return (
    <div className="flex justify-center items-center">
      <div className="bg-[#dedede] rounded-full p-2 scale-[0.5] flex">
        <AvatarCanvas inputValue={clientId} canvasId={Math.random()} />
      </div>
      <p>{getTrimmedName(ensName || username || name, 8, 18)}</p>
    </div>
  )
}

export default function CollaboratorsList({
  collaborators,
}: {
  collaborators: any
}) {
  const collaboratorsListRef = useRef<HTMLDivElement>(null)
  const collaboratorsListButtonRef = useRef<HTMLDivElement>(null)
  const [showCollaboratosList, setShowCollaboratorsList] =
    useState<boolean>(false)

  const collaboratorsCount = collaborators.length
  const collaboratorsToDisplay =
    collaboratorsCount > 4 ? collaborators.slice(0, 4) : collaborators

  useOutsideClick([collaboratorsListRef, collaboratorsListButtonRef], () =>
    setShowCollaboratorsList(!showCollaboratosList)
  )
  return (
    <div
      ref={collaboratorsListButtonRef}
      onClick={() => setShowCollaboratorsList(!showCollaboratosList)}
      className="flex relative cursor-pointer"
    >
      {collaboratorsToDisplay.map((item: any) => (
        <div
          key={item.clientId}
          className="bg-[#dedede] rounded-full border-[3px] border-black p-2 scale-[0.6] flex -ml-8"
        >
          <AvatarCanvas inputValue={item.clientId} canvasId={Math.random()} />
        </div>
      ))}
      {collaboratorsCount > 4 && (
        <div className="bg-[#dedede] rounded-full border-[3px] border-black p-2 scale-[0.6] flex justify-center items-center -ml-8">
          <div className="w-[40px] h-[40px] flex justify-center items-center">
            <p className="text-2xl">{`+ ${collaboratorsCount - 4}`}</p>
          </div>
        </div>
      )}
      {showCollaboratosList && (
        <div
          ref={collaboratorsListRef}
          className="absolute w-[225px] h-fit max-h-[300px] bg-white pt-2 flex flex-col items-start top-20 -right-3 font-bold border rounded-[8px] overflow-scroll no-scrollbar text-sm"
        >
          <p className="pl-4">{collaboratorsCount} total viewers</p>
          {collaborators.map((item: any) => (
            <CollaboratorName
              key={item.clientId}
              clientId={item.clientId}
              username={item.username}
              name={item.name}
            />
          ))}
        </div>
      )}
    </div>
  )
}
