import React from 'react'
import { StepContent } from '../StepItem'
import { Onboarding } from '../../../types/enum/onboarding.enum'
import { STEPS_MAP } from '../../../constants'
import { Fade } from '@mui/material'

export const GenerateKeysStep = ({
  name,
  isComplete,
  deployStatus,
}: {
  name: string
  isComplete: boolean
  deployStatus: Onboarding
}) => {
  return (
    <StepContent
      title={name}
      isComplete={isComplete}
      isCurrent={deployStatus === Onboarding.GENERATE}
    >
      <Fade in={true} timeout={1000}>
        <p className="pt-2 pb-4 text-body-sm color-text-secondary">
          {STEPS_MAP[Onboarding.GENERATE]?.description || ''}
        </p>
      </Fade>
    </StepContent>
  )
}
