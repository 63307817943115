/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useCallback,
  useMemo,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  Fragment,
  ChangeEvent,
  forwardRef,
  useLayoutEffect,
} from 'react'
import debounce from 'lodash/debounce'
import { dPage, Group, MenuListProps } from './types'
import { Surface } from '../../common/Surface'
import { DropdownButton } from '../../common/Dropdown'
import { Icon } from '../../common/Icon'
import { useParams } from 'react-router-dom'
import { useFilterFiles } from '../../../../store/files/hooks'
import { FileTypeEnum } from '../../../../types/enum/file.enum'
import { useContractChainId } from '../../../../store/contract/hooks'

interface ItemType {
  fileId: string
  metadata: {
    name: string
    emoji: string
    fileType: number
  }
  action?: () => void
  permission: string
}

export const MenuList = forwardRef((props: MenuListProps, ref) => {
  const { address: contractAddress } = useParams()
  const chainId = useContractChainId(contractAddress as string)
  const webpagesFiles = useFilterFiles(contractAddress as string, 'dPages')

  const createDPages = useCallback(
    (item: ItemType) => ({
      title: item.metadata.name,
      emoji: item.metadata.emoji || '📄',
      permission: item.permission,
      action: function (editor: any) {
        editor
          .chain()
          .focus()
          .setLinkButton({
            title: this.title,
            emoji: this.emoji,
            href: `/#/${contractAddress}/file/${item.fileId}?chainId=${chainId}`,
            permission: this.permission,
          })
          .run()
      },
    }),
    [contractAddress, chainId]
  )

  const createGroup = useCallback(
    (title: string, fileType: FileTypeEnum, permission: string) => {
      const filteredPages = webpagesFiles.filter(
        (item) => item.metadata.fileType === fileType
      )
      return {
        title,
        dPages: filteredPages.map((item: any) =>
          createDPages({
            fileId: item.fileId,
            metadata: {
              name: item.metadata.name,
              emoji: item.metadata.emoji,
              fileType: item.metadata.fileType,
            },
            permission,
          })
        ),
      }
    },
    [webpagesFiles, createDPages]
  )

  const ITEMS: Group[] = useMemo(
    () => [
      createGroup('Public', FileTypeEnum.PUBLIC, 'Globe'),
      createGroup('Gated', FileTypeEnum.GATED, 'Shield'),
      createGroup('Collaborators', FileTypeEnum.PRIVATE, 'Users'),
      createGroup('Members', FileTypeEnum.MEMBERS_PRIVATE, 'EyeOff'),
    ],
    [createGroup]
  )

  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const activeItemRef = useRef<HTMLDivElement>(null)
  const [, setSearchQuery] = useState<string>('')
  const [filteredItems, setFilteredItems] = useState<Group[]>(ITEMS)
  const [selectedGroupIndex, setSelectedGroupIndex] = useState<number>(0)
  const [selectedCommandIndex, setSelectedCommandIndex] = useState<number>(0)

  const selectItem = useCallback(
    (groupIndex: number, commandIndex: number) => {
      const command = filteredItems[groupIndex].dPages[commandIndex]
      props.command(command)
    },
    [props, filteredItems]
  )

  useImperativeHandle(
    ref,
    () => ({
      onKeyDown: ({ event }: { event: KeyboardEvent }) => {
        if (event.key === 'ArrowDown') {
          if (!filteredItems.length) {
            return false
          }

          const dPages = filteredItems[selectedGroupIndex].dPages

          let newCommandIndex = selectedCommandIndex + 1
          let newGroupIndex = selectedGroupIndex

          if (dPages.length - 1 < newCommandIndex) {
            newCommandIndex = 0
            newGroupIndex = selectedGroupIndex + 1
          }

          if (filteredItems.length - 1 < newGroupIndex) {
            newGroupIndex = 0
          }

          setSelectedCommandIndex(newCommandIndex)
          setSelectedGroupIndex(newGroupIndex)

          return true
        }

        if (event.key === 'ArrowUp') {
          if (!filteredItems.length) {
            return false
          }

          let newCommandIndex = selectedCommandIndex - 1
          let newGroupIndex = selectedGroupIndex

          if (newCommandIndex < 0) {
            newGroupIndex = selectedGroupIndex - 1
            newCommandIndex =
              filteredItems[newGroupIndex]?.dPages.length - 1 || 0
          }

          if (newGroupIndex < 0) {
            newGroupIndex = filteredItems.length - 1
            newCommandIndex = filteredItems[newGroupIndex].dPages.length - 1
          }

          setSelectedCommandIndex(newCommandIndex)
          setSelectedGroupIndex(newGroupIndex)

          return true
        }

        // TODO: Not working for now, fix later
        if (event.key === 'Enter') {
          if (
            !filteredItems.length ||
            selectedGroupIndex === -1 ||
            selectedCommandIndex === -1
          ) {
            return false
          }

          selectItem(selectedGroupIndex, selectedCommandIndex)

          return true
        }

        return false
      },
    }),
    [filteredItems, selectedGroupIndex, selectedCommandIndex, selectItem]
  )

  useEffect(() => {
    setSelectedGroupIndex(0)
    setSelectedCommandIndex(0)
  }, [filteredItems])

  useLayoutEffect(() => {
    if (activeItemRef.current && scrollContainerRef.current) {
      const offsetTop = activeItemRef.current.offsetTop
      const offsetHeight = activeItemRef.current.offsetHeight

      scrollContainerRef.current.scrollTop = offsetTop - offsetHeight
    }
  }, [selectedCommandIndex, selectedGroupIndex])

  const createCommandClickHandler = useCallback(
    (groupIndex: number, commandIndex: number) => {
      return () => {
        selectItem(groupIndex, commandIndex)
      }
    },
    [selectItem]
  )

  if (!ITEMS.length) {
    return null
  }

  const debouncedSearch = useCallback(
    debounce((query: string) => {
      setSearchQuery(query)

      const newFilteredItems = ITEMS.map((group) => ({
        ...group,
        dPages: group.dPages.filter((dPage) =>
          dPage.title.toLowerCase().includes(query.toLowerCase())
        ),
      })).filter((group) => group.dPages.length > 0)

      setFilteredItems(newFilteredItems)
    }, 300),
    [ITEMS]
  )

  const handleSearchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      debouncedSearch(event.target.value)
    },
    [debouncedSearch]
  )

  return (
    <Surface
      ref={scrollContainerRef}
      className="text-black max-h-[min(80vh,24rem)] overflow-auto flex-wrap mb-8 p-2"
    >
      {/* Search bar */}
      <div className="flex items-center justify-center w-full h-8 bg-neutral-100 border-[1px] border-[#DDD] rounded px-4 gap-2">
        <Icon name="Search" className="text-neutral-500" />
        <input
          type="text"
          className="w-full h-full bg-transparent outline-none text-neutral-500 text-sm font-medium"
          placeholder="Filter"
          onChange={handleSearchChange}
          onClick={(event) => event.stopPropagation()}
        />
      </div>
      {/* Headline */}
      <div className="text-black text-xs col-[1/-1] mx-1 mt-4 font-medium select-none">
        Select a dPage
      </div>
      <div className="grid grid-cols-1 gap-0.5 mt-2">
        {filteredItems.length > 0 ? (
          filteredItems.map((group, groupIndex: number) => (
            <Fragment key={`${groupIndex}`}>
              {group.dPages.length > 0 && (
                <div
                  className="text-neutral-500 text-[0.65rem] col-[1/-1] mx-2 mt-2 font-semibold tracking-wider select-none uppercase first:mt-0.5"
                  key={`${group.title}`}
                >
                  {group.title}
                </div>
              )}
              {group.dPages.map((dPage: dPage, dPageIndex: number) => (
                <div
                  key={`${dPageIndex}`}
                  ref={
                    selectedGroupIndex === groupIndex &&
                    selectedCommandIndex === dPageIndex
                      ? activeItemRef
                      : null
                  }
                >
                  <DropdownButton
                    isActive={
                      selectedGroupIndex === groupIndex &&
                      selectedCommandIndex === dPageIndex
                    }
                    onClick={createCommandClickHandler(groupIndex, dPageIndex)}
                  >
                    <span>{dPage.emoji}</span>
                    <span>{dPage.title}</span>
                  </DropdownButton>
                </div>
              ))}
            </Fragment>
          ))
        ) : (
          <p className="text-xs text-neutral-500 text-center">
            You need to publish a dPage first!{' '}
          </p>
        )}
      </div>
    </Surface>
  )
})

MenuList.displayName = 'MenuList'

export default MenuList
