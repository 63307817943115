import React from 'react'
import { Tooltip } from '../../pages/PublicPortal/components/Tooltip'
// import { Tooltip } from '@fileverse/ui'

export const withTooltip = (
  WrappedComponent: React.FC,
  tooltipText: string
) => {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component'

  const ComponentWithTooltip = (props: { [key: string]: unknown }) => {
    return (
      <Tooltip text={tooltipText}>
        <WrappedComponent {...props} />
      </Tooltip>
    )
  }

  ComponentWithTooltip.displayName = `withTooltip(${displayName})`
  return ComponentWithTooltip
}
