/* eslint-disable @typescript-eslint/no-explicit-any */
import { waitForTransactionReceipt as waitForTransactionReceiptCore } from '@wagmi/core'
import { config, AllowedChainId } from '../../config/wagmi-config'
import { defaultNetwork } from '../../config/network-config'
import { Interface } from 'ethers'
import { ResolveEventError } from '../errors'
import portalAbi from '../../contract/portalAbi.json'
import registryAbi from '../../contract/registryAbi.json'
import { ContractType } from './interface'
import { validateOrSwitchNetwork } from '../ethUtils'

type ContractConfigMapType = {
  [key in ContractType]: {
    abi: any
  }
}

type AsyncFunctionType<T> = (...args: any[]) => Promise<T>

export const ContractConfigMap: ContractConfigMapType = {
  [ContractType.Portal]: {
    abi: portalAbi,
  },
  [ContractType.Registry]: {
    abi: registryAbi,
  },
}

export const waitForTransactionReceipt = async (
  transactionHash: `0x${string}`,
  pollingInterval = 5000
) =>
  await waitForTransactionReceiptCore(config, {
    chainId: defaultNetwork.chainId as AllowedChainId,
    hash: transactionHash,
    pollingInterval,
  })

export const parseTrxEventLogs = (
  logs: any,
  contractType: ContractType,
  event: string
) => {
  const contractAbi = ContractConfigMap[contractType].abi
  const contractInterface = new Interface(contractAbi)
  for (const log of logs) {
    try {
      const decodedData = contractInterface.parseLog(log)

      if (decodedData?.name === event) return decodedData.args
    } catch (err) {
      console.log(`Looking for ${event} event Logs`)
    }
  }
  throw new ResolveEventError(`${event} event logs not found`)
}

//A higher order function that validates network before executing a transaction
export const onBeforeTrx = <T>(
  func: AsyncFunctionType<T>
): AsyncFunctionType<T> => {
  return async function (...args: any[]): Promise<T> {
    // validate and switch chain if needed
    await validateOrSwitchNetwork(defaultNetwork.chainId as AllowedChainId)
    // execute transaction function
    const result = await func(...args)

    return result
  }
}
